.rewards-tile-wrapper{
  overflow: hidden;

  &.tab-closed{
    height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }


  .swiper-slide {
    width: 73.66666667%;
  }


  div[class*=col-]>.rect-responsive{
    padding-bottom: 136%;
  }

  h4{
    text-align: center;
    font-size: 1.125rem;
    padding-bottom: 0.5rem;
  }

  .detail-info-wrap-pricetag{
    padding-top: 1.9rem;
    padding-bottom: 0.5rem;
  }

  .info-tile{
    border-bottom: 2px solid @light-sand-brown;
    border-right: 2px solid @light-sand-brown;
    border-left: 2px solid @light-sand-brown;
  }

  .row-details{
    padding-top: 2.9rem;
    p{
      font-size: 0.875rem;
      color: @gray-close-dropdown;
      text-align: center;
    }
  }

  .upper-title{
    position: absolute;
    top: 0;
    left: 0;
    background-color: fade(@main-green, 50);
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: @white;
    width: 100%;
    font-size: 1.375rem;
    z-index: 2;
    &.upper-title-silver{
      background-color: fade(@silver-top, 50);
    }
    &.upper-title-gold{
      background-color: fade(@gold-top, 50);
    }
  }
  .down-title{
    position: absolute;
    bottom: 1.35rem;
    left: 0.74375rem;
    color: @white;
    width: 100%;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-family: @font-family-menu-sans-serif;
    font-weight: 900;
    z-index: 2;
  }
  .down-price{
    position: absolute;
    right: 1.5rem;
    color: @white;
    width: 100%;
    font-size: 2.6875rem;
    font-family: @font-family-menu-sans-serif;
    font-weight: 900;
    z-index: 2;
    text-align: right;
    bottom: 0.6rem;
    letter-spacing: 0.23125rem;
  }
}

@media (min-width: @screen-ms-min) {
  .rewards-tile-wrapper {

    .swiper-slide {
      width: 44.66666667%;
    }

  }
}

@media (min-width: @screen-sm-min) {
  .rewards-tile-wrapper {
    .swiper-slide {
      width: 40.66666667%;
      margin-left: 3%;
      &:first-child{
        margin-left: 8.33%;
      }
    }
    .down-title{
      font-size: 1.375rem;
    }
    .down-price{
      font-size: 2.8125rem;
      bottom: 0.47rem;
    }
  }
}

@media (min-width: @screen-md-min) {
  .rewards-tile-wrapper {
    div[class*=col-]>.rect-responsive{
      padding-bottom: 122%;
    }
    .swiper-slide {
      width: 26.33%;
      margin-left: 2.33%;
      &:first-child{
        margin-left: 8.33%;
      }
    }
    .down-title{
      font-size: 1.375rem;
    }
    .down-price{
      font-size: 2.8125rem;
      bottom: 0.47rem;
    }
  }
}

@media (min-width: @screen-lg-min) {
  .rewards-tile-wrapper {
    div[class*=col-] > .rect-responsive {
      padding-bottom: 122%;
    }
    .swiper-slide {
      width: 25.1%;
      margin-left: 1.9%;
      &:first-child {
        margin-left: 11%;
      }
    }
    .upper-title{
      height: 5.8rem;
      font-size: 1.625rem;
    }
    .down-title {
      font-size: 1.5rem;
    }
    .down-price {
      font-size: 3.375rem;
      bottom: 0.17rem;
    }

    .row-details {
      p {
        font-size: 1rem;
      }
    }
  }
}


*[lang="ar"] {
  *[dir="rtl"] {
    .rewards-tile-wrapper{
      .down-price{
        left: 1.5rem;
        right: auto;
        text-align: left;
      }
      .down-title{
        right: 0.74375rem;
        left: auto;
      }
    }

    @media (min-width: @screen-sm-min) {
      .rewards-tile-wrapper {
        .swiper-slide {
          margin-left: 0%;
          margin-right: 3%;
          &:first-child{
            margin-left: 0%;
            margin-right: 8.33%;
          }
          &:last-child{
            margin-left: 3%;
          }
        }
      }
    }

  }
}
