.smart-pay-offer-plan{
  h3{
    font-size: 28px;
    color: @almost-black;
    letter-spacing: 0px;
    margin-bottom: 0.5rem;
    @media (max-width: 768px){
      font-size: 20px;
    }
  }
  h4{
    font-family: @font-family-base;
    color: @steel-grey;
    font-size: 18px;
    margin-bottom: 1rem;
    letter-spacing: 0px;
    @media (max-width: 768px){
      font-size: 14px;
    }
  }
  p{
    font-family: @font-family-base;
    color: @steel-grey;
    font-size: 14px;
    letter-spacing: 0px;
    margin-bottom: 45px;
    @media (max-width: 768px){
      margin-bottom: 20px;
    }
  }
  .smart-pay-offer-table{
    .table{
     thead{
       tr{
         th{
           font-family: @font-family-base;
           font-size: 12px;
           text-transform: uppercase;
           vertical-align: bottom;
           letter-spacing: 2px;
           padding: 10px;
           text-align: left;
           font-weight: normal;
           &:first-child{
             width: 15%;
           }
           &:last-child{
            width: 15%;
            text-align: center;
          }
         }
       }
     }
     tbody{
      tr{
        td{
          font-family: @font-family-base;
          font-size: 16px;
          letter-spacing: 0;
          padding: 10px;
          font-weight: bold;
          @media (max-width: 768px){
            font-size: 14px;
          }
        }
      }
    }
    &.table-striped {
      > tbody > tr {
        &:nth-of-type(odd) {
          background-color: @light-gray-3;
          background-color: #FAFAF5;
        }
        > td {
          border-top:0;
        }
      }
    }
    }
  }
}
.table {
   @media (max-width: 768px) {
     border: 0;
     thead {display:none}
     tbody {
       tr {
         display: block;
       }
       th,td {display: block}
       td {
         text-align: left;
         border-top: 0 !important;
         &:last-child {border-bottom: 0}
         &:before {
           content: attr(data-label);
           float: left;
           width: 100%;
           font-weight: normal;
           ontent: attr(data-label);
           text-transform: uppercase;
           font-size: 12px;
           letter-spacing: 1px;
           color: @almost-black;
         }
       }
     }
   }
  }
  *[lang="ar"] {
    *[dir="rtl"] {
      .smart-pay-offer-plan {
        .smart-pay-offer-table{
          .table {
            thead{
              tr {
                th{
                  text-align: right;
                }
              }
            }
            tbody{
              td{
                text-align: right;
              }
            }
          }
        }
      }
      .all-prices-exclusion  {
        span{
          margin-left: 7px;
          margin-right: 0px;
        }

    }
    }
  }
