.detail-info-wrap-pricetag {
  display: inline-block;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  & > div {
    position: relative;
    display: inline-block;
    color: @main-green;
    font-family: @headings-font-family;
    text-align: left;
  }
  .lower-part,
  .upper-part {
    font-size: 0.875rem;
    line-height: 1.5em;
  }
  .upper-part {
    padding-bottom: 0.4rem;
  }
  .main-part {
    line-height: 1em;
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 0.125rem;
    .price {
      font-size: 2.5rem;
      line-height: 0.8em;
      padding-right: 0.125rem;
      font-family: @headings-font-family;
      display: flex;
      align-items: flex-end;
      font-weight: 400;
      span.pre-price {
        font-size: 0.55em;
        line-height: 0.8em;
      }
    }
    .price-specs {
      font-size: 1rem;
      line-height: 1em;
      font-family: @headings-font-family;
      font-weight: 400;
    }
  }
  .detail-price-black {
    color: @almost-black;
  }
  .detail-price-old {
    color: @cold-gray !important;
    &:after {
      content: " ";
      position: absolute;
      height: 1px;
      width: 100%;
      left: 0;
      top: 50%;
      background-color: @cold-gray;
      transform: rotate(15deg);
    }
  }
  /*

      .detail-price-old,
      .detail-price-new{
        position: relative;
        display: inline-block;
        clear: both;
        color: @main-green;
        font-family: @headings-font-family;
        font-weight: 600;
        text-align: left;
        font-size: 2.5rem;
        line-height: 0.1rem;
        padding-top: .9375rem;
        .price-valute{
          font-size:.875rem;
        }
        .price-timing{
          font-size: 1rem;
          line-height:3.9rem;
        }
      }

      .detail-price-old{
        position: relative;
        display: inline-block;
        color: @cold-gray;
        font-family: @headings-font-family;
        &:after{
          content: " ";
          position: absolute;
          height:1px;
          width:100%;
          left: 0;
          top: 50%;
          background-color: @cold-gray;
          transform: rotate(15deg);
        }
      }
    */
  .detail-price-old + .detail-price-new {
    padding-left: 1.25rem;
  }
  .detail-plus {
    margin: 0;
    padding: 0 0.5rem;
    font-size: 2.5rem;
    line-height: 0.8em;
    font-family: @headings-font-family;
    align-self: flex-end;
  }
}
@media(max-width:@screen-xs-min) {
  .detail-info-wrap-pricetag .main-part .price {
    font-size: 1.875rem;
  }
  .detail-info-wrap-pricetag .main-part .price-specs {
    font-size: 0.75rem;
  }
}
@media(max-width:@screen-sm-min) {
  .detail-info-wrap-pricetag .main-part .price {
    font-size: 1.875rem;
  }
  .detail-info-wrap-pricetag .main-part .price-specs {
    font-size: 0.75rem;
  }
}
@media (min-width: @screen-sm-min) {}
@media (min-width: @screen-md-min) {
  .detail-info-wrap-pricetag {
    .lower-part,
    .upper-part {
      font-size: 1rem;
    }
    .upper-part {
      padding-bottom: 0.3rem;
    }
    .main-part {
      .price {
        font-size: 3.375rem;
      }
    }
    .detail-plus {
      font-size: 3.375rem;
    }
  }
}
@media (min-width: @screen-lg-min) {
  .detail-info-wrap-pricetag {
    .lower-part,
    .upper-part {
      font-size: 1rem;
    }
    .upper-part {
      padding-bottom: 0.3rem;
      font-family: Lato,Roboto,Arial,sans-serif;
    }
    .main-part {
      .price {
        font-size: 3.375rem;
      }
    }
    .detail-plus {
      font-size: 3.375rem;
    }
  }
  /*
    .detail-info-wrap-pricetag{
      .detail-price-old,
      .detail-price-new{
        font-size: 3.375rem;
        line-height: 0.8rem;
      }
    }
    */
}
/*
 * Business Variant
 */
.business {
  .detail-info-wrap-pricetag {
    & > div {
      color: @dark-green;
    }
    .detail-price-black {
      color: @almost-black;
    }
  }
}
/*price strikethrough*/
.strikethorugh {
    font-family: lato;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0;
    color: #848789;
    -ms-flex-align: baseline;
    align-items: baseline;
    font-family: Lato,Roboto,Arial,sans-serif;
    .strikethorugh-was {
        text-decoration: line-through;
        margin-left: .3rem;
        margin-right: .3rem;
    }
    .strikethorugh-off {
        color: #ff8000;
        font-size: 1rem;
        margin-left: .4rem;
        margin-right: .4rem;
        //font-weight: 600;
    }
}
.detail-price-new.strikethrough-content {
   @media (min-width: 993px){
     margin-top: -36px;
   }
   @media (min-width: 768px) and (max-width: 992px){
     margin-top: -35px;
   }
}

/*
 * Arabic
 */
*[lang="ar"] {
  *[dir="rtl"] {
    .detail-info-wrap-pricetag {
      & > div {
        text-align: right;
      }
      .detail-price-old + .detail-price-new {
        padding-right: 1.25rem;
      }
      .detail-price-old {
        &:after {
          transform: rotate(-15deg);
        }
      }
    }
  }
}
