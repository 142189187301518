.table-header{
    background-color: @light-sand-brown;
    color: @almost-black;
    letter-spacing: 0.0625rem;
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
    .row{
        >div{
            text-align: left;
            line-height: 1rem;
            display: flex;
            cursor: pointer;
            &:nth-child(1){
                margin-right: 0;
            }
            svg {
                display: none;
            }
            &.header-selected{
                color: @main-green;
                font-weight: bold;
                svg {
                    display: inline-block;
                    width: 0.5rem;
                    height: 1rem;
                    margin-left: 0.75rem;
                    padding: 0;
                    fill: @main-green;
                    transform: rotate(90deg);
                    use {
                        -moz-transform: rotate(270deg);
                    }
                }
            }
            &.header-selected[data-order="asc"]{
                svg {
                    transform: rotate(-90deg);
                }
            }
        }
    }
}


.business{
    .table-header{
        background-color: @business-table-head;
        color: @white;
        .row{
            >div{
                &.header-selected {
                    color: @white;
                    svg {
                        fill: @white;
                    }
                }
            }
        }
    }
}

// new development Tracking Order
.business{
  .table-headrer-dark {
    background-color: @steel-grey;
    color: @white;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .table-checkbox-round {
    .checkbox {
      padding-top: 0;
    }
    .form .checkbox label:before {
      border-radius: 50%;
    }
  }
  .table-checkbox-round {
    .form {
      .checkbox input[type=checkbox]:checked+label:before {
        background-image: url(../img/forms/checkbox-slim.png);
      }
    }
  }

@media (min-width: @screen-sm-min) {  
}
@media (min-width: 320px) and (max-width: 767px) {
  .hidden7 {
    display: none;
  }
  .last-update {
    border-bottom: 2px solid #d4d4d4;
  }
}


*[lang="ar"] {
*[dir="rtl"] {
    .table-header{
        .row{
            >div{
                text-align: right;
                &:nth-child(1){
                    margin-left: 0;
                    margin-right: auto;
                }
            }
        }
    }
}
} 
}
