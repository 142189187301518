

.selectAccount {
  h5 {
    color: #353733;
  }
  hr {
    margin:10px 0px 65px 0px;
    width: 100%;
    border-top: 2px solid #e2e1da;
  }
  .action-buttons-container {
    a {
      margin: 0px 20px;
      color: @main-green;
    }
  }
}


@media (max-width: 767px) {
  .selectAccount {
      hr {
        margin:30px 0px 30px 0px;
      }
    }
    .action-buttons-container {
      margin-bottom: 20px;
    }
}
