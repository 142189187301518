
.filters.filters-C.order-search-filter {
  margin-bottom: 0;
  .filters-header {
     .controls-buttons {
        .close-btn-wrap {

        }
     }
  }
  .filter-form-order {
    .filter-form-order-margin {
      margin-top: 2rem;
    }
    .order-form-field,
    .order-form-field {
      background: transparent;
      border: 0px;
      border-bottom: solid 1px @gray;
      svg {
        width: 25px;
        height: 25px;
        fill: @dark-green;
      }
      label {
        height: 2.2rem;
        input.border-bottom {
          border: 0px;
          color: @gray;
        }
        .select2-container--default {
          .select2-selection--single {
            &:after {
              height: 1px;
              background: transparent;
            }
            .select2-selection__placeholder {
              color: @gray;
            }
          }
        }
        .select2.select2-container {
          padding: 0 2.5rem .8325rem 0;
          .select2-selection__arrow {
            top: 25px;
            right: 0.85rem;
          }
        }
        .select2.select2-container--open {
           .select2-selection__arrow {
             height: 0;
           }
        }
      }
      .inner-icon {
        right: 0 ;
        top: 3% ;
      }
    }
    .ui-widget.floating-label-input {
      margin-bottom: 10px;
    }
    p {
      color: @gray;
    }
  }
}

.order-search-filter.filters {
    label {
      margin: 0;
      .select2 {
          box-sizing: border-box !important;
        }
      }
      .item-count {
        display: none;
        padding-top: 15px;
      }
  }
//overrite z-index because of menu overlaping
.ui-front {
  z-index: 8;
}

/*B2B Old CSS starts*/
.business-register-form.tooltipped .tooltip .tooltip-inner,
.business-register-form .tooltipped .tooltip .tooltip-inner {
  padding: 1rem;
  background-color: #616b1e;
  width: 360px;
  max-width: 69vw;
  color: #fff;
  text-align: left;
}
.business-register-form.tooltipped .tooltip .tooltip-inner ul,
.business-register-form .tooltipped .tooltip .tooltip-inner ul {
  padding-left: 1rem;
}
.business .main-text.main-text-order-status a,
.business .main-text.main-text-order-status p {
  color: #848789;
}
.business{
  .ets-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .form .checkbox input[type=checkbox]:checked + label:before,
  .form .radio input[type=checkbox]:checked + label:before {
    background-image: url(../img/forms/checkbox.png);
    background-repeat: no-repeat;
    background-position: 50%;
    -webkit-background-size: 80% 80%;
            background-size: 80%;
  }
  .loader-disable-screen {
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
  }
  .loader-disable-screen .loader {
    width: 100%;
    height: 100%;
  }
  .loader-disable-screen .loader svg {
    width: 100%;
  }
  .main-text.main-text-order-status {
    padding-bottom: 0;
  }
  .main-text.main-text-order-status {
    padding-bottom: 1rem;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (orientation: portrait) {
    .page-nav-wrap .page-nav {
      max-height: 399px;
      overflow-y: auto;
    }
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (orientation: landscape) {
    .page-nav-wrap .page-nav {
      max-height: 184px;
      overflow-y: auto;
    }
  }
  .editorial-placement .tiles-box .read-more {
    height: 0;
  }
  .filters.filters-C .filters-header .controls-buttons .close-btn-wrap {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
        -ms-flex-order: 0;
            order: 0;
    
  }
  @media (min-width: 992px) {
    *[lang="ar"] *[dir="rtl"] .cart-items.cart-items-F .cart-item-photo {
      border-width: 1px 1px 1px 0;
    }
    *[lang="ar"] *[dir="rtl"] .cart-items.cart-items-F .cart-item-content {
      border-width: 1px 0 1px 1px;
    }
  }
  *[lang="ar"] *[dir="rtl"] .cart-items .cart-item .main-content {
    padding: 1.25rem 5rem 1.56rem 0;
  }
  *[lang="ar"] *[dir="rtl"] .cart-items .cart-item .price-content {
    padding: 1.25rem 5rem 1.56rem 2rem;
  }
  @media (min-width: 992px) {
    *[lang="ar"] *[dir="rtl"] .cart-items .cart-item price-content {
      padding: 1.25rem 0 1.56rem 5rem;
    }
  }
  .ToLongText {
  	white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
  }
  .showToLongText {
  	word-wrap: break-word !important;
      white-space: inherit !important;

  }

}

.business-register-form .form-title h3 {
  letter-spacing: 0;
  text-transform: uppercase;
}

/*B2B Old CSS ends*/
