/* Bootstrap Columns with same height
 * https://scotch.io/bar-talk/different-tricks-on-how-to-make-bootstrap-columns-all-the-same-height
 */
.row.is-flex {
  display: flex;
  flex-wrap: wrap;
}
.row.is-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
}



/* styles for the grid overlay tests */
#grid-layout {


  &.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    display: none;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    overflow: hidden;

  }
  &.in {
    display: block;
  }

  .container,
  .container-fluid {
    height: 100%;

    .row {
      height: 100%;

      .col-xs-1 {
        height: 100%;
        background-color: rgba(86, 61, 124, .15);
        border: 1px solid @sand-brown;

        .filler {
          height: 100%;
          //background-color: rgba(86, 61, 124, .15);
        }
      }
    }
  }
}

#grid-layout__info {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 370px;
  height: 50px;
  line-height: 50px;
  background-color: #000;
  color: #fff;
  padding: 0 10px;
  text-align: center;
  z-index: 13;
}

@media (max-width: @screen-ms) {
  #grid-layout__info {
    font-size: 14px;
    width: 320px;
  }
}
@media (max-width: @screen-xs) {
  #grid-layout__info {
    font-size: 14px;
    width: 320px;
  }
}


.centerer() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


/*
 * Styles for broken images
 * http://bitsofco.de/styling-broken-images/?mc_cid=af113537e3&mc_eid=a81714f1a7
 */
img {
  /* Same as first example */
  min-height: 30px;
}

img:before {
  content: " ";
  display: block;

  position: absolute;
  top: -10px;
  left: 0;
  height: calc(100% + 10px);
  width: 100%;
  background-color: rgb(230, 230, 230);
  border: 2px dotted rgb(200, 200, 200);
  border-radius: 5px;
}

img:after {
  content: " Broken Image of " attr(alt);
  display: block;
  font-size: 16px;
  font-style: normal;
  font-family: Arial, sans-serif;
  color: rgb(100, 100, 100);
  position: absolute;
  top: 5px;
  left: 0;
  width: 100%;
  text-align: center;
}
.no-padding{padding: 0 !important}
.no-padding-top{padding-top: 0 !important}
.no-space-around{margin-top: 0; margin-right: 0; margin-bottom: 0; margin-left: 0;}
.no-space-top{margin-top: 0 !important}
.margin-top-small{
  margin-top: 1rem !important;
}
.margin-bottom-small{
  margin-bottom: 1rem !important;
}
.margin-left-small{
  margin-left: 1rem !important;
}
.margin-right-small{
  margin-right: 1rem !important;
}
.mb-30 {
    margin-bottom: 30px !important;
}
.bg-lighter{
  background-color: @sand-white;
}

.pt-100{
  padding-top: 100px !important;
}
.pt-40{
  padding-top: 40px !important;
}
.pt-20{
  padding-top: 20px !important;
}

.wst{
  .bg-lighter{
    background-color: #FBFBFB !important;
  }
}
.full-width{
  width: 100%;
}
.responsive-fit{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
