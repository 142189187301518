/*
============================================
01 - xs viewport
01a - .swiper-container
01b - .swiper-pagination-custom
01c - halfscreen
01d - animations
02 - xs viewport only
03 - xs to ms viewport only
04 - ms viewport
05 - sm viewport
06 - md viewport
07 - lg viewport
08 - Arabic overrides
============================================
*/

/* -----------------------------------------
01 - xs viewport
01a - .swiper-container
----------------------------------------- */
.wst{
  .hero-image-section {
    width: 100%;
    .hero-over-title {
      letter-spacing: 0.215em;
    }
    .hero-content {
      font-size: 0.875rem;
      span {
        width: 65%;
        display: block;
        margin: 0 auto;
      }
    }
    .hero-upper-title {
      font-size: 2.25rem;
      font-family: @font-arabic;
      margin-bottom: 0.6875rem;
    }
    .hero-swiper-button-next,
    .hero-swiper-button-prev {
      width: 1rem;
      height: 2rem;
      background: rgba(255, 255, 255, 0);
      margin: 0;
      transform: translate(0, -50%);
      z-index: 2;
      top: 50%;
      position: absolute;
      cursor: pointer;
      svg {
        stroke: @black;
        width: 1rem;
        height: 2rem;
      }
    }
    .hero-swiper-button-next {
      right: 1.25rem;
    }
    .hero-swiper-button-prev {
      left: 1.25rem;
    }
    &.bg-dark {
      .hero-swiper-button-next,
      .hero-swiper-button-prev {
        svg {
          stroke: @white;
        }
      }
    }
    .swiper-slide {
      text-align: center;
      font-size: 1.125rem;
      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
      color: @black;
      background-color: @white;
      > .cover {
        top: 0;
        left: 0;
        z-index: 1;
        height: 500px;
        overflow: hidden;
      }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        > .cover {
          z-index: 0;
        }
      }
      > .container {
        margin: 0;
        z-index: 3;
      }
      > .container-fluid {
        margin: 0;
        width: 100%;
      }
      &.bg-dark {
        color: @white;
      }
      .section-title {
        position: relative;
        padding-top: 0.5rem;
        padding-bottom: 1.5rem;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.2em;
      }
      .price-tag {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.5rem 1.8rem 1rem;
        background-color: rgba(255, 128, 0, 0.8);
        .detail-info-wrap-pricetag > div {
          color: @white;
        }
      }
      h2 {
        .heading-bigger;
        font-size: 2.1875rem;
        margin-top: 0;
      }
      h2:first-child {
        margin-top: 0;
      }
      h2:last-child {
        margin-bottom: 1.625rem;
      }
      p {
        position: relative;
        &.hero-content {
          position: relative;
          font-family: Neotech-medium,GE SS Two,Arial,sans-serif;
          font-weight: 300;
          font-size: 1rem;
          letter-spacing: 0.2rem;
        }
      }
      .bg-filter{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        top: 0;
        left: 0;
        background-color: transparent;
        opacity: 0;
      }
    }
  }
  .swiper-pagination-custom {
    position: absolute;
    bottom: 1.6rem;
    width: 100%;
    color: @white;
    z-index: 100;
    text-align: center;
    letter-spacing: 0.2383333333em;
    font-weight: 400;
    .pagination-wrapper {
      width: auto;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
    }
    a {
      flex-grow: 1;
      border-top: 1px solid @black;
      color: @black;
      text-transform: uppercase;
      padding-top: 0.75rem;
      margin-left: 0.3125rem;
      margin-right: 0.3125rem;
      position: relative;
      animation-duration: 5.5s;
      display: block;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &.active::after,
      &.activedNo::after {
        position: absolute;
        top: -0.25rem;
        left: 0;
        height: 0.1875rem;
        content: " ";
        background-color: @black;
        width: 0;
      }
      &.activedNo::after {
        width: 100%;
      }
      &.active::after {
        -webkit-animation: slideProgress;
        -webkit-animation-fill-mode: forwards;
        -moz-animation: slideProgress;
        -moz-animation-fill-mode: forwards;
        -o-animation: slideProgress;
        -o-animation-fill-mode: forwards;
        animation: slideProgress;
        animation-fill-mode: forwards;
        animation-duration: inherit;
        -webkit-animation-duration: inherit;
      }
    }
    &.bg-dark {
      color: @white;
      a {
        color: @white;
        border-color: @white;
        &.active::after,
        &.activedNo::after {
          background-color: @white;
        }
      }
    }
  }


  /* -----------------------------------------
  01c - halfscreen
  ----------------------------------------- */
  .halfscreen {
    .swiper-pagination-custom {
      display: none;
    }
    &.swiper-container .swiper-slide h2:last-child {
      margin-bottom: 0;
    }
    &.swiper-container .swiper-slide a.btn {
      margin-bottom: 0;
    }
  }


  /* -----------------------------------------
  01d - animations
  ----------------------------------------- */
  @-webkit-keyframes slideProgress {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  @-moz-keyframes slideProgress {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  @-o-keyframes slideProgress {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  @keyframes slideProgress {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }


  /* -----------------------------------------
  02 - xs viewport only
  ----------------------------------------- */
  @media (max-width: @screen-ms-min) {
    .halfscreen {
      .price-tag + div,
      .price-tag + p {
        margin-top: 9rem;
        padding-bottom: 0;
      }
    }
    .swiper-container {
      .swiper-slide {
        .section-title {
          margin-top: 4rem;
        }
      }
    }
  }

  //iPhone
    @media (max-width: 320px) {
      &.freedom{
        .hero-image{
          &.hero-image{
            min-height: 36rem;
          }
        }
      }
    }


  /* -----------------------------------------
  03 - xs to ms viewport only
  ----------------------------------------- */
  @media (max-width: @screen-sm-min) {
    &.freedom{
      .hero-image{
         .swiper-wrapper {
           .swiper-slide{
             h2 {
               color: @almost-black;
               text-align: left;
               letter-spacing: normal;
               top: 50px;
               position: relative;
               margin-bottom: 1.5rem;
               font-size: 1.63rem;
               letter-spacing: 0px;
            }
            p{
              position: relative;
              top: 40px;
              color: #6F7273;
              text-align: left;
              height: 30px;
              font-size: 14;
              line-height: 20px;
            }
            .btn {
               margin-top: 1rem;
               width: 100%;
               color: #fff;
               border-color: initial;
               background-color: #719e19;
               height: 64px;
               line-height: 64px;
               font-size: .88rem
            }

             height: 250px;
             >.container {
                 top: 300px;
                 left:0;
                 position: absolute;
             }
           }
         }
         &.testimonials{
           .hero-swiper-button-prev,.hero-swiper-button-next{
             display: none;
           }
           .swiper-pagination-bullets {
               top: 320px;
               bottom: auto;
               .swiper-pagination-bullet{
                 width: 10px;
                 height: 10px;
                 margin: 0 5px;
                 background-color: #EAEAEA;
                 &.swiper-pagination-bullet-active{
                  border: 1px solid @main-green;
                  background-color: @main-green;
                  opacity: 1;
                 }
               }
           }
         }
      }
    }
    .swiper-container {
      .swiper-slide {
        .price-tag {
          margin-right: 0;
        }
      }
      .hero-upper-title {
        font-size: 1.25rem;
      }
      .hero-swiper-button-next,
      .hero-swiper-button-prev {
        svg {
          stroke-width: 0.25rem;
        }
      }
    }
  }

  /* -----------------------------------------
  04 - ms viewport
  ----------------------------------------- */
  @media (min-width: @screen-ms-min) {
    .swiper-container {
      .swiper-slide {
        h2:last-child {
          margin-bottom: 3rem;
        }
        a.btn {
          margin-top: 0;
          margin-bottom: 1.875rem;
        }
      }
    }
    .halfscreen {
      .swiper-slide {
        a.btn {
          margin-top: 1rem;
        }
      }
    }
  }

  /* -----------------------------------------
  05 - sm viewport
  ----------------------------------------- */
  @media (min-width: @screen-sm-min) {
    .swiper-pagination-custom {
      bottom: 2.7rem;
      .pagination-wrapper {
        font-size: 0.75rem;
      }
    }
    .halfscreen .swiper-pagination-custom {
      display: none;
    }
    .swiper-container {
      .swiper-slide {
        h2 {
          font-size: 3.4375rem;
          font-family: Neotech-medium,GE SS Two,Arial,sans-serif;
          font-weight: 300;

          &.promo-heading {
            margin-top: 3rem;    font-size: 3.2rem;
          }
          .arabicTitle {
            letter-spacing: 0 !important;
            font-size: 2.6rem;
            display: inline-block;
            margin-bottom: 20px;
          }


        }
        h2:last-child {
          margin-bottom: 5.8rem;
        }
        .section-title {
          position: absolute;
          padding-top: 0;
          top: 3.125rem;
        }
        .price-tag {
          padding: 1rem 2rem 1.5rem;
        }
        a.btn {
          margin-bottom: 0;
          margin-top: 1.875rem;
          &.btn-promo {
            line-height: 1.6rem;
            font-size: 1rem;
            padding: 1rem;
            height: 5rem;
            bottom: -6rem;
            font-family: Neotech-medium,GE SS Two,Arial,sans-serif;
          }
        }
      }
      .hero-content {
        font-size: 1rem;
      }
      .hero-upper-title {
        font-size: 1.5rem;
        margin-bottom: 0.8rem;
      }
      .hero-swiper-button-next,
      .hero-swiper-button-prev {
        width: 2.1875rem;
        height: 4.25rem;
        svg {
          width: 2.1875rem;
          height: 4.25rem;
        }
      }
      .hero-swiper-button-next {
        right: 2.25rem;
      }
      .hero-swiper-button-prev {
        left: 2.25rem;
      }
    }
    .hero-images-ver-H {
      margin-top: 0;
      .search-group .form-group input.form-control{
        height: 3.5rem;
        font-size: 1.25rem;
      }
    }


  }


  /* -----------------------------------------
  06 - md viewport
  ----------------------------------------- */
  @media (min-width: @screen-md-min) {
    &.freedom{
      .hero-images-ver-F {
        margin-top: 0;
      }
      .testimonials{
        .swiper-pagination-bullets {
           bottom: 60px;
           .swiper-pagination-bullet {
                width: 12px;
                height: 12px;
                margin: 0 3px;
              &.swiper-pagination-bullet-active{
                border: 1px solid @white;
                background-color: @main-green;
                opacity: 1;
              }
          }
        }
      }
      .swiper-container{
         .swiper-slide {
           h2 {
             font-size: 3.35rem;
           }
           .btn{
             margin-bottom: 2.5rem;
             margin-top: 2.875rem;
             width: 220px;
             height: 64px;
             line-height: 64px;
             font-size: .88rem;
           }
         }
      }
    }
    .hero-image {
      height:500px;
    }
    .swiper-pagination-custom {
      bottom: 4rem;
      .pagination-wrapper {
        font-size: 0.875rem;
      }
    }
    .swiper-container {
      .swiper-slide {
        h2:last-child {
          margin-bottom: 8.4rem;
        }
        a.btn {
          margin-bottom: 3.5rem;
          margin-top: 1.875rem;
          &.btn-promo{
            bottom: 0rem;
          }
        }
        & h2.promo-heading {
          margin-top: 7rem;
        }
      }
    }
    .hero-images-ver-F {
      margin-top: 3.7rem;
    }
    .hero-images-ver-H {
      margin-top: 0;
      .search-group .form-group input.form-control{
        height: 3.5rem;
        font-size: 1.25rem;
      }
    }
  }


  /* -----------------------------------------
  07 - lg viewport
  ----------------------------------------- */
  
  @media (min-width: @screen-lg-min) {
    .swiper-pagination-custom {
      bottom: 3.55rem;
      font-weight: 600;
      .pagination-wrapper {
        font-size: 1rem;
      }
      a {
        padding-top: 0.8rem;
      }
    }
    .swiper-container {
      .swiper-slide {
        h2 {
          font-size: 5rem;
        }
        h2:last-child {
          margin-bottom: 5rem;
        }
      }
      .hero-content {
        font-size: 1.25rem;
      }
      .hero-upper-title {
        font-size: 2.25rem;
      }
      .hero-swiper-button-next,
      .hero-swiper-button-prev {
        width: 2.875rem;
        height: 5.625rem;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 1.875rem;
          height: 3rem;
          stroke-width: 2px;
        }
      }
      .hero-swiper-button-next {
        right: 1.85rem;
      }
      .hero-swiper-button-prev {
        left: 1.85rem;
      }
    }
    .hero-images-ver-H {
      .form .search-group .form-group input.form-control{
        height: 4.938rem;
        font-size: 1.5rem;
      }
    }
  }
  @media (min-width: 1600px) {
    .swiper-container {
      .hero-swiper-button-next {
        right: 4.625rem;
      }
      .hero-swiper-button-prev {
        left: 4.625rem;
      }
    }
    
  }


}


/* -----------------------------------------
08 - Arabic overrides
----------------------------------------- */
*[lang="ar"] {
  *[dir="rtl"] {
    .wst{
      .swiper-container {
        .swiper-slide {
          .price-tag {
            left: 0;
            right: auto;
          }
        }
      }
      .hero-upper-title {
        font-size: 2.25rem;
        font-family: @font-family-base;
      }
      @media (max-width: @screen-sm-min) {
        .hero-upper-title {
          font-size: 1.5rem;
        }
      }
      @media (min-width: @screen-sm-min) {
        .hero-upper-title {
          font-size: 1.5rem;
        }
      }
      @media (min-width: @screen-lg-min) {
        .hero-upper-title {
          font-size: 2.25rem;
        }
        .swiper-container {
          .swiper-slide {
            h2 {
              font-size: 5.625rem;
            }
          }
        }
        .swiper-pagination-custom {
          .pagination-wrapper {
            a {
              span {
                font-size: 1.375rem;
              }
            }
          }
        }
      }
    }
  }
}


// *************************************
// Hero Image Module - customization wst-3.0
// *************************************
.wst{
  .page-nav-wrap {
    &.affix{

      border-bottom: 1px solid #f1f1eb;

    }
    &.affix-top{
      @media (min-width: @screen-md) {
        box-shadow: 0 0px 5px 0 rgba(0,0,0,.25);
      }
      border-bottom: 1px solid #f1f1eb;
      border-top: 1px solid #f1f1eb;
    }
  }
  .hero-image-section {
    min-height: 500px;
    height: 500px;
    &.hero-links-add {
      height: 300px;
      min-height: 350px;
      overflow: hidden !important;
      // margin-bottom: -50px;
      @media (min-width: @screen-md) {
        height: 450px;
        min-height: 450px;
      }
      .hero-links-secton {
        //bottom: 50px;
      }
    }
    div.cover {
      max-height: 200px;
      @media (min-width: @screen-md-min) {
        max-height: inherit;
      }
    }
    .hero-details {
      position: absolute;
      top: 300px;
      height: 186px;
      text-align: left;
      width: 100%;
      left: 0;
      @media (min-width: @screen-md-min) {
        top: auto;
        height: auto;
        display: block;
        width: inherit;
        position: relative;
        left: auto;
      }
      .hero-heading-small {
        font-size: 1rem;
        font-weight: normal;
        font-family: @font-family-base;
        color: @breadcrumb-color;
        line-height: 1rem;
        margin: 0;
        margin-bottom: .4rem;
        text-transform: capitalize;
        letter-spacing: normal;
        @media (min-width: @screen-md-min) {
          margin-bottom: 1rem;
          font-size: 1.5rem;
          font-weight: normal;
          letter-spacing: 1px;
          text-transform: capitalize;
        }
      }
      .hero-heading-big {
        font-size: 1.6rem;
        font-family: @headings-font-family;
        color: @almost-black;
        line-height: 1.1;
        margin: 0;
        margin-bottom: 1rem;
        @media (min-width: @screen-md-min) {
          margin-bottom: 1.5rem;
          font-size: 4rem;
        }
      }
      .hero-description {
        font-size: 18px;
        line-height: 1.5rem;
        color: @almost-black;
        font-family: @font-family-base;
      }
      button.btn,
      a.btn {
        max-width: 100%;
        @media (min-width: @screen-md-min) {
          max-width: 180px;
        }
      }
    }
    .bg-dark {
      .hero-details {
        .hero-heading-small,
        .hero-heading-big,
        .hero-description {
          color: @white;
        }
      }
    }
    .swiper-pagination {
      top: 256px;
      bottom: unset !important;
    }
    &.swiper-container {
      .hero-swiper-button-prev,
      .hero-swiper-button-next {

      }
      .hero-swiper-button-next,
      .hero-swiper-button-prev {
        right: 2rem;
        // width: 48px;
        // height: 48px;
        // background: rgba(0, 0, 0, 0.42);
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        display: none;
        @media (min-width: @screen-md-min) {
          display: flex;
        }
        svg {
          // width: 1rem;
          // height: 1rem;
          // stroke: @white;
        }
      }
      .hero-swiper-button-prev {
        right: auto;
        left: 2rem;
      }
    }
    .swiper-pagination-bullet{
      background: #ccc !important;
      width:10px;
      height: 10px;
    }
    .swiper-pagination-bullet-active {
      background-color: @brand-primary !important;
      border:0 !important;
    }
  }

  // *************************************
  // hero-links-secton - medium option height 363px
  // *************************************

  .hero-image-section {
    &.medium {
      min-height: 324px;
      height: 100%;
      @media (min-width: @screen-md) {
        min-height: 363px;
        height: 363px;
      }
      div.cover {
        max-height: 200px;
        @media (min-width: @screen-md) {
          max-height: 363px;
        }
      }
      swiper-slide>.cover {
        height: 100%;
        @media (min-width: @screen-md) {
          height: 363px;
        }
      }
      @media (min-width: @screen-md) {
        .hero-images-call-to-action-section {
          top: 50px;
        }
        .hero-details + .hero-images-call-to-action-section {
          top: 120px;
        }
      }

      .hero-details {
        @media (max-width: @screen-md) {
          top: 0;
          height: 100%;
          margin-top: 224px;
          position: relative;
        }
      }
      .swiper-slide {
        width: 100%;
        .hero-heading-big {
          @media (max-width: @screen-md) {
            color: @almost-black;
            font-size: 22px;
          }
        }
        .hero-description {
          @media (max-width: @screen-md) {
            font-size: 14px;
            color: #6F7273;
          }
        }
      }
    }
    &.has-action {
      @media (max-width: @screen-md) {
        margin-bottom: 100px;
        div.cover {
          max-height: 324px;
        }
        .hero-images-call-to-action-section {
          top: 240px;
        }
        .hero-details {
          margin-top: 80px;
        }
      }
    }
  }

  // *************************************
  // hero-links-secton
  // *************************************
  .hero-links-secton {
    //display: none;
    @media (min-width: @screen-md) {
      max-width: 486px;
      min-height: 96px;
    }
    ul {
      width: 100%;
      box-shadow: 0 2px 10px 0 hsla(0,0%,67%,.2);
      background-color: @white;
      border-radius: 5px;
      li {
        display: table-cell;
        width: 1%;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 64px;
          span {
            display: none;
          }
          @media (min-width: @screen-md) {
            height: 96px;
            flex-direction: column;
            text-align: center;
            color: @steel-grey;
            font-weight: bold;
            font-size: 10px;
            font-family: @font-family-base;
            text-transform: uppercase;
            span {
              line-height: 1.2;
              margin-top: 10px;
              display: block;
            }
          }
          svg {
            width: 26px;
            height: 26px;
            stroke: @brand-primary;
          }
        }
        &:after {
          border-right: 1px solid @light-sand-brown;
          content: "";
          top: 18px;
          height: 24px;
          position: absolute;
          @media (min-width: @screen-md) {
            top: 24px;
            height: 50px;
          }
        }
        &:first-child {
          &:after {
            display: none;
          }
        }
      }
    }
    #icon--pay {
      height: 32px;
      width: 32px;
      margin-top: -6px;
    }
  }

  //below code is only if there are links on hero image
  .hero-links-add {
    .hero-links-secton {
      position: absolute;
      top: 216px;
      width: 100%;
      z-index: 99;
      display: none;
      left: 0;
      @media (min-width: @screen-md) {
        bottom: 0;
        top: auto;
        right: 0;
        left: auto;
        margin-bottom: 10px;
      }
      ul li {
        &:after {
          display: flex;
        }
        &:first-child {
          &:after {
            display: none;
          }
        }
      }
    }
    .hero-links-menu {
      height: 500px;
      @media (max-width: @screen-md) {
        height: 100%;
      }
      .swiper-slide {
        overflow: hidden;
        width: 100%;
      }
      & ~ .swiper-pagination {
        top: 285px !important;
      }
      & ~ .hero-links-secton {
        display: block;
      }
      .hero-details {
        top: 220px !important;
        @media (min-width: @screen-md) {
          top: auto !important;
        }
      }
    }
  }

  .hero-image-section .bg-dark .hero-details .hero-description,
  .hero-image-section .bg-dark .hero-details .hero-heading-big,
  .hero-image-section .bg-dark .hero-details .hero-heading-small {
    @media (max-width: @screen-md) {
      color: @almost-black;
    }
  }

  .hero-links-add {
    .hero-links-menu {
      ~ .show-hide {
        display: none !important;
        @media (max-width: @screen-md) {
          display: block !important;
        }
      }
    }
    .links-wrapper {
      margin-top: -92px;
      z-index: 99999;
      position: relative;
      .hero-links-secton {
        bottom: 0;
        top: 2.5rem;
        right: 0;
        left: auto;
        margin-bottom: 10px;
        display: block !important;
        @media (max-width: @screen-md) {
          display: none !important;
        }
      }
    }
  }

  // *************************************
  // hero image call-to-action box
  // *************************************
  .hero-images-call-to-action-section {
    text-align: left;
    padding: 0 12px;
    width: 100%;
    .call-to-action-box {
      height: 146px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      background-color: @brand-primary;
      padding: 1.3rem;
      position: relative;
      @media (min-width: @screen-md) {
        max-width: 384px;
        height: 160px;
      }
      .heading {
        font-size: 1.25rem;
        color: @white;
        margin: 0;
        margin-bottom: 12px;
        letter-spacing: 0;
      }
      .description {
        font-size: 12px;
        font-family: "Lato", Roboto, 'GE SS Two', Arial, sans-serif;
        color: #AFCD74;
        line-height: 18px;
        margin-bottom: 1rem;
      }

      .btn-text {
        color: @white;
        font-size: 12px;
        position: absolute;
        bottom: 1.1rem;
        &:before {
          padding: 3px;
          border: solid @white;
          border-width: 0 1px 1px 0;
        }
      }
    }
  }

  //below code is only if there is a img-box with action links on hero image
  .hero-image-add {

    .hero-images-call-to-action-section {
      display: none;
      position: absolute;
      top: 170px;
      right: 0;
      left: .5rem;
      z-index: 9;
      @media (min-width: 320px) {
        left: unset;
      }
      @media (min-width: @screen-md) {
        top: 190px;
      }
      .call-to-action-box {
        @media (min-width: @screen-md) {
          float: right;
          min-width: 384px;
        }
      }
    }
    .swiper-slide {
      .hero-images-call-to-action-section {
        display: block;
      }
      .cover + div {
        @media (min-width: @screen-md) {
          position: relative;
        }
      }
    }
    .hero-details {
      top: 360px;
      @media (min-width: @screen-md) {
        top: auto;
      }
    }

    &.dynamic-content {
      .swiper-slide {
        display: block;
        .cover+.container {
          height: 100%;
          margin: 0 auto;
          display: flex;
          align-items: center;
          .hero-details {
            z-index: 9999;
          }
        }
      }
      .hero-details+.hero-images-call-to-action-section {
        top: 230px;
      }
    }
  }

  .hero-image-add {
    &.has-action {
      .bg-dark {
        .hero-details {
          .hero-heading-big, .hero-description {
            color: @white !important;
          }
        }
      }
    }
  }
}









// arabic language
*[lang="ar"] {
  *[dir="rtl"] {
    .wst{
      .hero-details {
        text-align: right;
      }
      .hero-images-call-to-action-section {
        text-align: right;
        .btn-text {
          right: 2.5rem;
        }
      }

      .hero-image-add {
        .hero-images-call-to-action-section {
          .call-to-action-box {
            @media (min-width: @screen-md) {
              float: left;
            }
          }
        }
      }

      @media (min-width: @screen-md) {
        .hero-links-add .hero-links-secton {
          left: 0;
          right: unset;
        }
      }
    }

  }
}
