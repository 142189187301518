.tile-expansion-section-bugfixing{

  .tile-expansion-section {
    min-height: 140px;
    position: relative;
    background: #f7f8f4;
    overflow: hidden;
    margin-bottom:2rem;
    margin-top: 2rem;
    &:before {
      content: "";
      background: url(../img/wst-icons/background-pattern.svg) no-repeat;
      background-size: contain;
      height: 360px;
      width: 340px;
      position: absolute;
      opacity: .15;
      z-index: 0;
      bottom: 0;
      @media screen and (max-width: @screen-md) {
        height: 145px;
        width: 140px;
      }
    }
    &:after {
      content: "";
      background: url(../img/wst-icons/background-pattern.svg) no-repeat;
      background-size: contain;
      height: 360px;
      position: absolute;
      width: 340px;
      opacity: .1;
      z-index: 0;
      right: 0;
      transform: rotate(180deg);
      top: 0;
      @media screen and (max-width: @screen-md) {
        height: 145px;
        width: 140px;
      }
    }

    .collapsed-content {
      padding: 2.25rem;
      @media screen and (max-width: @screen-md) {
        padding: 1rem;
      }
      .container {
        position: relative;
      }
      z-index: 99;
      h2 {
        font-size: 28px;
        color: @almost-black;
        letter-spacing: 0.5px;
        margin: 2.2rem 0 1.5rem 0;
        @media screen and (max-width: @screen-md) {
          font-size: 20px;
          margin: 1rem 0;
        }
      }
    }

    .view-more {
      z-index: 99;
      position: absolute;
      color: @almost-black !important;
      top: 2.5rem;
      right: 1.5rem;
      &.btn-green:before {
        border-color: @almost-black
      }
    }

    //specific styling for important to know
    .paragraph-styling {
      p {
        font-size: 14px;
        color: #848789;
        line-height: 24px;
        margin-bottom: 1.5rem;
      }
      &.big {
        p {
          font-size: 18px;
          color: #6b6b6b;
        }
      }
    }
  }

  // terms and conditions section
  .terms-and-conditions-section {
    padding-bottom: 3rem;
    &.has-bg {
      background-color: #F7F8F4;
      padding: 3rem 0;
    }
    &.two-col {
      ul.ul-list {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
      }
    }
  }

  // arabic language
  *[lang="ar"] {
    *[dir="rtl"] {
      .tile-expansion-section {
        &:after {
          transform: none;
          left: 0;
          right: auto;
        }
        &:before {
          transform: scaleX(-1);
        }
        .view-more {
          left: 1.5rem;
          right: auto;
        }
      }

    }
  }

  *[lang="ar"] {
    *[dir="rtl"] {
      .tile-expansion-section{
        .collapsed-content {
          h2{
            text-align: right;
          }
        }
      }
    }
  }
}
