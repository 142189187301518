#compare.tab-closed {
  height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.compare-wrapper {
  margin-bottom: 0;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  z-index: 3;
  position: relative;
  .compare-title {
    text-align: center;
    padding-top: 0.437rem;
    padding-bottom: 0.6875rem;
  }
  .packages {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  .package {
    width: 30%;
    min-height: 5.125rem;
    font-size: 0.75rem;
    color: @steel-grey;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    transition: min-height 0.3s ease-in-out;
    &.package-selected {
      min-height: 5.125rem;
    }
  }
  .package + .package {
    &:after {
      width: 1px;
      height: 5.125rem;
      position: absolute;
      top: 50%;
      left: -8.5%;
      transform: translate(-1px, -50%);
      background-color: @sand-white;
      content: " ";
    }
  }
  .remove-package,
  .selected-package {
    position: absolute;
    z-index: 1;
    background-color: @main-green;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: @white;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.875rem;
    svg {
      stroke: @white;
      width: 0.55625rem;
      height: 0.55625rem;
      position: absolute;
      top: 0.55625rem;
      right: 0.55625rem;
      stroke-width: 0;
    }
  }
  .package-selected {
    .selected-package {
      display: flex;
      br {
        display: none;
      }
    }
  }
  .remove-package {
    z-index: 2;
    display: none;
    background-color: fade(@dark-orange,90);
  }
  .package-selected {
    &:hover {
      .remove-package {
        display: flex;
      }
    }
  }
  .compare-controls {
    margin-top: 2rem;
    margin-bottom: 2rem;
    a {
      width: 100%;
    }
  }
}
.compare-wrapper-result {
  display: none;
  color: @almost-black;

  & > .row > .swiper-wrapper {
    & > div {
      border-right: 1px solid @light-gray;
      padding-bottom: 1.3rem;
    }
    & > div:nth-child(3n+0) {
      border-right: none;
    }
  }
  .swiper-compare-container {
    float: left;
    width: 18.5625rem;
  }
  .sw-active {
    .swiper-slide {
      width: 18.5625rem;
    }
  }
  .add-package-to-compare {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid @sand-white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    svg {
      margin-bottom: 1rem;
      width: auto;
      height: 1.4rem;
    }
  }
  .product-details-wrapper {
    text-align: left;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    position: relative;
  }
  .more-details-button {
    padding-top: 2rem;
    cursor: pointer;
    &:hover {
      color: @main-green;
    }
  }
  .more-details {
    display: none;
    padding-bottom: 1rem;
  }
  .close-button {
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-top: 1px solid @light-gray;
    margin-top: 2rem;
    clear: both;
    width: 100%;
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  }
  h4 {
    font-size: 1.125rem;
    padding-bottom: 1.3rem;
  }
  .more-details,
  p {
    font-size: 0.875rem;
  }
}
@media (min-width: @screen-sm-min) {
  .compare-wrapper {
    .compare {
      display: flex;
      align-items: center;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }
    .compare-title {
      padding-top: 0;
      padding-bottom: 0;
      text-align: left;
    }
    .package {
      height: 5.75rem;
      &.package-selected {
        font-size: 1rem;
        br {
          display: block;
        }
      }
    }
    .package + .package {
      &:after {
        height: 5.75rem;
      }
    }
  }
  .compare-wrapper-result {
    & > .row {
      display: flex;
      width: 100%;
      & > div {
        border-bottom: none;
        border-right: 1px solid @light-gray;
      }
      & > div:nth-child(3n+0) {
        border-right: none;
      }
    }
    .product-details-wrapper {
      padding-left: 8%;
      padding-right: 8%;
    }
    .more-details-button {
      padding-top: 4rem;
    }
    .more-details {
      padding-bottom: 2rem;
    }
    .close-button {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
    .swiper-compare-container {
      width: 100%;
    }
  }
}
@media (min-width: @screen-md-min) {
  .compare-wrapper {
    .package {
      height: 7.0625rem;
    }
    .package + .package {
      &:after {
        height: 7.0625rem;
      }
    }
    .remove-package,
    .selected-package {
      font-size: 1rem;
      svg {
        width: 0.725rem;
        height: 0.725rem;
        top: 0.725rem;
        right: 0.725rem;
      }
    }
  }
}
@media (min-width: @screen-lg-min) {
  .compare-wrapper {
    .package {
      height: 9.5625rem;
    }
    .package + .package {
      &:after {
        height: 9.5625rem;
      }
    }
    .remove-package,
    .selected-package {
      font-size: 1.25rem;
      svg {
        width: 0.8375rem;
        height: 0.8375rem;
        top: 0.8375rem;
        right: 0.8375rem;
      }
    }
  }
  .compare-wrapper-result {
    h4 {
      font-size: 1.5rem;
      padding-bottom: 1.3rem;
    }
    .more-details,
    p {
      font-size: 1rem;
    }
  }
}
.business {
  .compare-wrapper {
    background-color: @sand-white;
    .package + .package {
      &:after {
        background-color: @white;
      }
    }
    .selected-package {
      background-color: @dark-green;
    }
  }
  .compare-wrapper-result {
    background-color: @sand-white;
    .row > .swiper-wrapper > div,
    div {
      border-color: @white;
    }
    .add-package-to-compare {
      color: @dark-green;
    }
  }
}
/*
 * RTL support
 */
html[lang="ar"] {
  *[dir="rtl"] {
    .compare-wrapper {
      .package + .package {
        &:after {
          right: -8.5%;
          left: auto;
        }
      }
      .remove-package,
      .selected-package {
        right: 0;
        left: auto;
        svg {
          left: 0.55625rem;
          right: auto;
        }
      }
    }
    .compare-wrapper-result {
      & > .row > .swiper-wrapper {
        & > div {
          border-left: 1px solid @light-gray;
          border-right: none;
        }
        & > div:nth-child(3n+0) {
          border-left: none;
          border-right: auto;
        }
      }
      .swiper-compare-container {
        float: right;
      }
      .add-package-to-compare {
        right: 0;
        left: auto;
      }
      .product-details-wrapper {
        text-align: right;
      }
    }
    @media (min-width: @screen-sm-min) {
      .compare-wrapper {
        .compare-title {
          text-align: right;
        }
      }
      .compare-wrapper-result {
        & > .row {
          & > div {
            border-bottom: none;
            border-left: 1px solid @light-gray;
            border-right: none;
          }
          & > div:nth-child(3n+0) {
            border-left: none;
            border-right: auto;
          }
        }
      }
    }
    @media (min-width: @screen-md-min) {
      .compare-wrapper {
        .remove-package,
        .selected-package {
          svg {
            left: 0.725rem;
            right: auto;
          }
        }
      }
    }
    @media (min-width: @screen-lg-min) {
      .compare-wrapper {
        .remove-package,
        .selected-package {
          svg {
            left: 0.8375rem;
            right: auto;
          }
        }
      }
    }
  }
}
