/* ==========================================================================
   $CLASSIC-DATE-PICKER
   ========================================================================== */
@import "_variables.less";
@import "../variables.less";

.picker {
  z-index: 15;
}

.picker__holder {
  font-family: @font-family-sans-serif;
  color: @dark-sand-brown;
  border: 0;
  border-top: 1px solid #bcbcbc;
  outline: none;

  &:focus {
    outline: none;
    border-top: 1px solid #bcbcbc;
  }

  .picker__header {

    .picker__nav--next:before, .picker__nav--prev:before {
      border-right-color: @dark-sand-brown;
    }

    .picker__month,
    .picker__year {
      font-family: @headings-font-family;
      font-size: 1rem;
      font-style: normal;
      color: @main-green;
    }
  }

  .picker__footer {
    .picker__button--clear, .picker__button--close, .picker__button--today {
      font-family: @headings-font-family;
      color: @main-green;
      background-color: transparent;
      border: 0;
    }

    .picker__button--today:before {
      border-top-color: @main-green;
    }
  }


  .picker__weekday {
    font-family: @headings-font-family;
    color: @main-green;
  }

  .picker__day--outfocus {
    color: #D2D3CE;
  }

  .picker--focused .picker__day--selected, .picker__day--selected, .picker__day--selected:hover {
    background: @main-green;
    color: @white;
  }

  .picker__day--today:before {
    border-top-color: @main-green;
  }

  .picker__day--highlighted {
    border-color: @main-green;
    background: @main-green;
    color: @white;

    &.picker__day--today:before {
      border-top-color: @white;
    }
  }

  .picker__day--infocus:hover,
  .picker__day--outfocus:hover {
    background: @main-green;
    color: @white;
  }
}


/*
 * Business variations
 */
.business {
  .picker__holder {
    .picker__header {
      .picker__month,
      .picker__year {
        font-family: @headings-font-family;
        font-size: 1rem;
        font-style: normal;
        color: @dark-green;
      }
    }

    .picker__footer {
      .picker__button--clear, .picker__button--close, .picker__button--today {
        color: @dark-green;
      }
      .picker__button--today:before {
        border-top-color: @dark-green;
      }
    }

    .picker__weekday {
      color: @dark-green;
    }

    .picker--focused .picker__day--selected, .picker__day--selected, .picker__day--selected:hover {
      background: @dark-green;
    }
    .picker__day--highlighted {
      border-color: @dark-green;
    }

    .picker__day--infocus:hover,
    .picker__day--outfocus:hover {
      background: @dark-green;
    }

    .picker__day--today:before {
      border-top-color: @dark-green;
    }

    .picker__day--highlighted {
      border-color: @dark-green;
      background: @dark-green;
      color: @white;

      &.picker__day--today:before {
        border-top-color: @white;
      }
    }


  }

}
