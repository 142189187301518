.sticky-config-footer-spacer {
  width: 100%;
  background-color: @white;
  padding-top: 2.75rem;
}
.sticky-config-footer-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 4;
  > .container-fluid {
    padding-top: 1rem;
    overflow: hidden;
  }
  .sticky-config-footer {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
    background-color: @dark-gray;
    padding: 0.9375rem 0 2.375rem;
    .selected-option-list {
      display: none;
    }
    .prices {
      .detail-info-wrap-pricetag {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      }
    }
  }
  .prices {
    float: left;
    .detail-info-wrap {
      width: auto;
      //float: left;
      margin-bottom: 1.75rem;
      .detail-price-new {
        color: @white;
      }
    }
    .detail-info-wrap + .detail-info-wrap {
      &:before {
        color: @white;
        content: " + ";
        font-size: 2.5rem;
        line-height: 2.5rem;
        padding-left: .5rem;
        padding-right: .5rem;
        padding-top: 0; // updated from 1 to 0 to align them vertically
      }
      &.mtop0-sm{
        &:before {
              padding-top: 0rem !important;
        }
      }
    }
    .detail-info-wrap + .no-plus {
      &:before {
        display: none;
      }
    }
    .detail-info-options {
      > div {
        color: @dark-gray;
      }
    }
  }
  .buttons-left {
    float: left;
    margin-bottom: 2rem;
    width: 100%;
    &.form {
      margin-bottom: 0;
      padding: 0 0 2.375rem;
      label {
        color: @white;
        padding-left: 1.875rem;
        font-size: 0.75rem;
        a{
          text-decoration: underline;
          color: @white;
        }
        &:before {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
    &.confirm-to-continue{
      input[type=checkbox]:not(:checked) + label{
        color: @pink;
        a{
          text-decoration: underline;
          color: @pink;
        }
        &:before{
          border-color: @pink;
        }
      }
    }
  }
  .buttons-right {
    float: right;
    width: 100%;
  }
  .btn {
    /*margin-left: 2.58rem;*/
    width: 100%;
    &.btn-text-only {
      color: @dark-gray;
    }
    &.pack-next-step {
      color: @white;
      border-color: @white;
      background: transparent;
      &.greenBtn {
        color: @sand-white;
        background-color: @main-green;
        border-color: @main-green;
      }
    }
    &.expand-all {
      margin-top: 2rem;
    }
  }
  .selected-option-list {
    font-size: 1.25rem;
    color: @dark-gray;
    span + span {
      &:before {
        content: " + ";
      }
    }
  }
}
.business {
  .sticky-config-footer-wrapper {
    .sticky-config-footer {
      background-color: @white;
      color: @white;
    }
    .buttons-left.form label {
      color: @almost-black;
      a{
        text-decoration: underline;
        color: @almost-black;
      }
      &:before{
        border-color: @almost-black;
      }
    }
    .form {
      .checkbox.sticky-footer-checkbox {
        margin-right: 20px;
        padding-top: 0;
        label {
          color: @almost-black;
        }
      }
    }
    .buttons-left.confirm-to-continue{
      input[type=checkbox]:not(:checked) + label{
        color: @pink;
        a{
          text-decoration: underline;
          color: @pink;
        }
        &:before{
          border-color: @pink;
        }
      }
    }
    .main-part,
    .selected-option-list,
    .upper-part {
      color: @almost-black;
    }
    .prices {
      .detail-info-wrap + .detail-info-wrap {
        &:before {
          color: @almost-black;
        }
      }
      .detail-info-options {
        > div {
          color: @almost-black;
        }
      }
    }
  }
}
@media(max-width:@screen-xs-min){
  .sticky-config-footer-wrapper {
    .sticky-config-footer {
      .prices .detail-info-wrap-pricetag {
        align-items: flex-start;
        float: left;
      }
      .detail-info-wrap-pricetag .main-part {
        .price {
          font-size: 1.875rem;
        }
        .price-specs {
          font-size: 0.75rem;
        }
      }
    }
  }

}
@media(max-width:@screen-sm-min){
  .sticky-config-footer-wrapper {
    .sticky-config-footer {
      .prices .detail-info-wrap-pricetag {
        //align-items: flex-start;
        float: left;
        &.price-onetime {
          // @media(max-width:580px){
          //   margin-top:0;
          // }
        }
      }
      .detail-info-wrap-pricetag .main-part {
        .price {
          font-size: 1.875rem;
        }
        .price-specs {
          font-size: 0.75rem;
        }
      }
    }
  }

}
@media (min-width: @screen-sm-min) {
  .sticky-config-footer-wrapper {
    .sticky-config-footer {
      padding-bottom: 1.3125rem;
    }
    .buttons-left {
      margin-bottom: 0;
      margin-left: 0.5rem;
      width: auto;
      &.form {
        padding-bottom: 0;
      }
    }
    .buttons-right {
      width: auto;
      .btn-green {
        float: right;
      }
    }
    .buttons-right.col-sm-2 {
      width: 16.66666667%;
      padding-left: 1.125rem;
    }
    .btn {
      width: auto;
      &.expand-all {
        margin-left: 1.25rem;
        width: auto;
        margin-top: 0;
      }
    }
    .flex_align_footer {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }
    .prices {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      .detail-info-wrap {
        margin-bottom: 0;
      }
      .detail-info-wrap-pricetag{
        align-items: flex-end;
      }
    }
  }

}
@media (min-width: @screen-md-min) {
  .sticky-config-footer-wrapper {
    .buttons-right.col-md-2 {
      width: 16.66666667%;
      padding-left: 0;
    }
  }
  .sticky-config-footer-wrapper .buttons-left {
    margin-left: 2.875rem;
  }
}
@media (min-width: @screen-lg-min) {
  .sticky-config-footer-wrapper {
    .sticky-config-footer {
      padding: 1.25rem 0 1.75rem;
    }
    .buttons-left {
      width: auto;
      margin-bottom: 0;
      &.form {
        padding-top: 0.0625rem;
      }
    }
    .buttons-right {
      width: auto;
    }
    .flex_align_footer {
      display: block;
    }
    .prices {
      .detail-info-wrap {
        margin-bottom: 0;
      }
    }
  }
}
/*
 * ARABIC
 */
*[lang="ar"] {
  *[dir="rtl"] {
    .sticky-config-footer-spacer {}
    .sticky-config-footer-wrapper {
      > .container-fluid {}
      .sticky-config-footer {
        .selected-option-list {}
      }
      .prices {
        float: right;
        display: flex;
        .detail-info-wrap {
          float: right;
          .detail-price-new {}
        }
        .detail-info-wrap + .detail-info-wrap {
          &:after {
            display: none;
          }
        }
        .detail-info-options {
          > div {}
        }
      }
      .prices {
        .number-booking {
          text-align: right;
          margin-right: 10px;
        }
      }
      .buttons-left {
        float: right;
        &.form {
          label {
            color: @white;
            padding-right: 1.875rem;
            padding-left: 0;
            font-size: 0.75rem;
            &:before {}
          }
        }
      }
      .buttons-right {
        float: left;
        //width: 100%;
      }
      .btn {
        /*margin-left: 2.58rem;*/
        &.btn-text-only {}
        &.pack-next-step {}
        &.expand-all {}
      }
      .selected-option-list {
        span + span {
          &:before {}
        }
      }
    }
    @media (min-width: @screen-sm-min) {
      .sticky-config-footer-wrapper {
        .sticky-config-footer {}
        .buttons-left {
          &.form {}
        }
        .buttons-right {
          .btn-green {
            float: left;
          }
        }
        .buttons-right.col-sm-2 {
          width: 16.66666667%;
          padding-right: 1.125rem;
          padding-left: 0;
        }
        .btn {
          &.expand-all {
            margin-right: 1.25rem;
            margin-left: 0;
          }
        }
        .flex_align_footer {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: space-between;
        }
        .prices {
          .detail-info-wrap {}
        }
      }
      .sticky-config-footer-wrapper .buttons-left {
        margin-right: 2.875rem;
      }
    }
    @media (min-width: @screen-md-min) {
      .sticky-config-footer-wrapper {
        .buttons-right.col-md-2 {
          width: 16.66666667%;
          padding-left: auto;
          padding-right: 0;
        }
      }
    }
    @media (min-width: @screen-lg-min) {
      .sticky-config-footer-wrapper {
        .sticky-config-footer {
          padding: 1.25rem 0 1.75rem;
        }
        .buttons-left {
          &.form {}
        }
        .buttons-right {}
        .flex_align_footer {
          display: flex;
        }
        .prices {
          .detail-info-wrap {}
        }
      }
    }
    @media (max-width: 767px) {
      // arabic version overlapping fixes
      .sticky-config-footer-wrapper {
        .prices {
          display: block;
          .number-booking {
            text-align: right;
            margin-right: 0;
            font-family: @font-arabic;
          }
        }
      }
    }
  }
}
/* bood your number fix */
@media (max-width: 767px) {
 .mobileFooterTimer .timer-for-mobileView {
        position: absolute;
        width: auto;
 }
 .mobileFooterTimer.prices .detail-info-wrap-pricetag {
     margin-top: 60px;
 }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .term-sm-width {
    margin-left: 1rem;
    label {
       word-wrap: break-word;
       width: 110px;
       font-size: 0.7rem !important;
   }
  }
}
@media (max-width: @screen-sm-min) {
  .sticky-config-footer-wrapper .sticky-config-footer {
      padding: .9375rem 0 1.375rem;
  }
  .sub_menu_mobile .panel-heading {
      padding: 0;
  }
  .sub_menu_mobile .panel {
      margin-bottom: 0;
  }

  [lang=ar] [dir=rtl] .sub_menu_mobile #accordion_menu .panel-title a {
      font-size: 1rem;
      padding-left: 80px;
  }
}
