.plans-table{
  background-color: @white;
  //margin: 0;
  padding: 1rem 0rem;


  .tile-table {
    &.inactive {
      .tile {
        cursor: default;
        .tiles-box {
          &:after,
          .read-more {
            background-color: #d1d2d1!important;
          }
          .detail-price-new {
            color: #d1d2d1;
          }
          .bottom-text {
            color: #909192;
          }
        }
        .tile-card__back {
          .tiles-box {
            border-color: #d1d2d1!important;
          }
          .flip-icon {
            svg {
              fill: #d1d2d1!important;
            }
          }
        }
      }
    }
    display: block;
    box-sizing: border-box;
    float: left;
    width: 33.333333333%;
    /*margin: 0;*/
    margin-bottom: 3rem;
    /*padding: 0 1.875rem 0rem;*/
    background-color: transparent;
    .tile{
      background-color: @white;
      display: block;
      box-sizing: border-box;
      box-shadow: inset 0px 0px 0px 2px @light-sand-brown;
      .tiles-box{
        position: relative;
        .cover{
          height: 12.5rem;
          img{
            opacity: 1;
          }
        }
        h3{
          height: 12.5rem;
          margin: 0;
          padding: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          position: relative;
          color: @white;
          text-transform: unset;
          box-sizing: border-box;
        }
        ul{
          color: @gray;
          margin: 2rem;
          padding: 0;
          li{
          /*  display: block;*/
            margin: 0 18px; /*change from 0*/
            padding: 0;
          /*  list-style: none;*/
            list-style:disc;
            text-align: left;
          //  white-space: nowrap;
          /*  overflow: hidden;*/
            text-overflow: ellipsis;
          }
        }
        p{
          .body-smallest;
          line-height: 1.125rem;
          //margin: 2rem 4.25rem 0;
          margin: 2rem 1rem 0;
          height: 3.75rem;
          text-align: center;
          color: @gray;
          overflow: hidden;
        }
        .read-more{
          background-color: @main-green;
          width: 100%;
          overflow: hidden;
          height: 3.75rem;
          text-align: center;
          text-transform: uppercase;
          color: @white;
          font-size: @font-size-base;
          letter-spacing: 0.1875rem;
          position: absolute;
          z-index: 1;
          bottom: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          .transition();

          span.selected-plan{
            display: none;
          }
        }

        #read-more-triangle-img{
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: -5.5px;
          transform: rotate(180deg);
          fill: @white;
        }
        &:after {
          content: '';
          background-color: @main-green;
          width: 100%;
          height: 0.75rem;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 0;
        }
      }

      &:hover{
        .read-more{
          height: 3.75rem;
        }
      }
    }

    input[type="radio"]:checked + .tile,
    input[type="checkbox"]:checked + .tile{
      .read-more{
        height: 3.75rem;
        span{
          display: none;
        }
        span.selected-plan{
          display: block;
        }
      }
    }


    input[type="checkbox"],
    input[type="radio"]{
      display: none;
    }

  }


  &.plans-table-in-config{
    //padding-top: 2rem;
    .detail-info-wrap-pricetag .main-part{
      color: @almost-black;
    }
    .tile-table .tile .tiles-box{
      &:after{
        height: 0;
      }
      .read-more{
        height: 0;
      }
    }
    input[type="checkbox"]:checked + .tile .tiles-box,
    input[type="radio"]:checked + .tile .tiles-box{
      .read-more{
        height: 3.75rem;
      }
    }
  }

  &.plans-table-A{
    .tile-table{
      .tile{
        position: relative;
        background-color: transparent;
        .flip-icon {
          height: 2.125rem;
          width: 2.125rem;
          margin: 0;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 99;
          svg {
            height: 1.125rem;
            width: 1.125rem;
            fill: @white;
          }
        }
        .tiles-box{
          position: relative;
          background-color: @white;
          border: 1px solid #f1f1eb;
          .cover{
            height: 7.5rem;
            img.cover{
              height: auto !important;
              width: auto !important;
            }
          }
          .tiles-box-title{
            position: relative;
            //width: 100%;
            height: 7.5rem;
            margin: 0 1.625rem;
            padding: 0 0 1.25rem;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            h3,.h3,  h2,.h2{
              display: block;
              height: auto;
              margin: 0;
              padding: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              color: @white;
              font-weight: 900;
              text-align: left;
            }
            h3,.h3{
              font-size: 1.25rem;
              line-height: 1.5rem;
              flex: 1;
              padding-right: 1rem;
            }
            h2,.h2{
              font-size: 3.375rem;
              line-height: 3rem;
            }
          }
          .tiles-box-text{
            height: 10.9rem;
            text-align: left;
            p{
              font-size: 1rem;
              line-height: 1.6rem;
              margin: 1.99rem 1.67rem 0 1.67rem;
              height: auto;
              text-align: left;
              color: @gray;
              overflow: visible;
            }
          }
          .tiles-box-list, ul{
            margin: 0 1.625rem;
            padding: 1.5rem 0;
            height: 18.75rem;
            overflow: hidden;
            //color:#000;
          }
          .tiles-box-line{
            position: relative;
            display: flex;
            height: 2rem;
            margin-bottom: 1rem;
            h6{
              font-size: 1rem;
              line-height: 2rem;
              margin: 0;
              padding: 0;
              text-align: left;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: @steel-grey;
              .tiles-box-number{
                color: @almost-black;
                font-size: 1.5rem;
                line-height: 2rem;
              }
            }
            h6:nth-child(1){
              flex: 1;
              padding-right: 1rem;
            }
            .progress{
              position: absolute;
              width: 100%;
              left: 0;
              bottom: -2px;
              height: 2px;
              border-bottom: solid 1px @cold-gray;
              .progress-bar{
                position: absolute;
                z-index: 1;
                left: 0;
                top: 0;
                height: 2px;
                background-color: @dark-orange;
              }
            }
          }
          p.bottom-text{
            height: 5.75rem;
            overflow: hidden;
            margin: 1rem 1.625rem 0;
          }
        }
        .tile-card__back {
          .flip-icon {
            svg {
              fill: @main-green;
            }
          }
          .tiles-box{
            border: solid 2px @main-green;
            height: 100%;
            max-height: 100%;
            overflow: hidden;
            &:after, .read-more{
              //background-color: @dark-orange !important;
            }
            ul li{
              .body-big;
            }
            p.back-text{
              .body-big;
              margin: 0 1.625rem;
              height: 7.5rem;
              display: flex;
              align-items: flex-end;
              overflow: hidden;
              text-align: left;
              line-height: 1.5;
              padding-top:  1.625rem;
              box-sizing: content-box;
            }
            ul li{
            }
            .detail-price-new{
              //color: @dark-orange;
            }
            p.bottom-text{
              //.body-big;
              //color: @dark-orange;
              //white-space: nowrap;
              //overflow: hidden;
              //text-overflow: ellipsis;
              //letter-spacing: 0.1rem;
              //line-height: 2.25rem;
              //text-transform: uppercase;
            }
            .back-text {
                ul {

                    margin: 0;
                    li {
                        white-space: normal;
                    }
                }
              }
          }
        }
      }
      //
      &.promo{
        .tile{
          .tiles-box{
            &:after, .read-more{
              background-color: @dark-orange !important;
            }
            p, ul li{
              .body-big;
            }
            p.back-text{
              margin: 0 1.625rem;
              height: 7.5rem;
              display: flex;
              align-items: flex-end;
              overflow: hidden;
              text-align: left;
              line-height: 1.5;
              padding-top:  1.625rem;
              box-sizing: content-box;
            }
            .tiles-box-list .tiles-box-line .progress .progress-bar{
              background-color: @main-green;
            }
            .detail-price-new{
              color: @dark-orange;
            }
            p.bottom-text{
              .body-big;
              color: @dark-orange;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              letter-spacing: 0.1rem;
              line-height: 2.25rem;
              text-transform: uppercase;
            }
          }
          .tile-card__back {
            .flip-icon {
              svg {
                fill: @dark-orange !important;
              }
            }
            .tiles-box{
              border-color: @dark-orange !important;
            }
          }
        }
      }
      .tile-one-side .tile .tiles-box{
        p.bottom-text{
          font-size: 0.775rem;
          height: 3.75rem;
          margin: 1.6rem 1.625rem 0;
          line-height: 1.3rem;
        }
      }
    }
  }
  .row-tiles-table{
    position: relative;
    .swiper-container{
      height: auto;
      .swiper-wrapper{
        height: auto;
        .swiper-slide{
          height: auto;
        }
      }
    }
    .table-swiper-button-next,
    .table-swiper-button-prev{
      display: none;
      width: 1.125rem;
      height: 2.25rem;
      position: absolute;
      bottom: 50%;
      //margin-bottom: -1.125rem;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      z-index: 2;
      svg {
        width: 100%;
        height: 100%;
        stroke: @dark-gray;
        stroke-width: 3px;
      }
      &.swiper-button-disabled{
        display: none;
      }
    }
    .table-swiper-button-next {
      position: absolute;
      right: 3%;
    }

    .table-swiper-button-prev {
      position: absolute;
      left: 3%;

    }
    .swiper-scrollbar{
      left: 0;
      border-radius: 0;
      width: 100%;
      display: none;
    }
  }

}




.tile-card__back,
.tile-card__front {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.8s;
  top: 0;
  left: 0;
}
.tile-card__back {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(-180deg);
}
/* click effect */
.tile-card.effect__click.flipped .tile-card__front {
  transform: rotateY(-180deg);
}
.tile-card.effect__click.flipped .tile-card__back {
  transform: rotateY(0);
}

/*
 * Business Variant
 */
.business{
  .plans-table{
    background-color: @light-sand-brown;
    .tile-table{
      .tile{
        box-shadow: none;
        .tiles-box{
          .read-more{
            background-color: @dark-green;
          }
          &:after {
            background-color: @dark-green;
          }
        }
      }
    }
    &.plans-table-A{
      .tile-table{
        .tile{
          .tile-card__back {
            .flip-icon {
              svg {
                fill: @dark-green;
              }
            }
            .tiles-box{
              border-color: @dark-green;
            }
          }
        }
        &.promo{
          .tile{
            .tiles-box{
              .tiles-box-list .tiles-box-line .progress .progress-bar{
                background-color: @dark-green !important;
              }
            }
          }
        }
      }
    }

  }
}

//media
/*
@media (min-width: @screen-md-min) and (max-width: @screen-lg-min) {
  //@media (min-width: @screen-sm-min) and (max-width: @screen-md-min) {
  .plans-table{
    padding: 0 0.5rem 3rem;
    .tile-table{
      padding: 0 0.5rem;
      .tile{
        .tiles-box{
          .cover{
            height: 9.25rem;
          }
          h3{
            height: 9.25rem;
          }
          ul{
            margin: 1rem;
          }
          p{
            margin: 1rem 1.625rem;
            height: auto;
          }
          .read-more{
            position: relative;
            height: 5rem;
          }
          &:after {
            height: 0;
          }
        }
        &:hover{
          .read-more{
            height: 5rem;
          }
        }
      }
    }


  }
}
*/
@media (min-width: @screen-sm-min) and (max-width: @screen-md-min) {
  //@media (min-width: @screen-xs-min) and (max-width: @screen-sm-min) {
  .plans-table{
    padding: 0 3rem 1rem;

    .tile-table{
      width: 50%;
      /* padding: 0 1rem 2rem; */
      .tile{
        .tiles-box{
          .cover{
            height: 11.875rem;
          }
          h3{
            height: 11.875rem;
          }
          ul{
            margin: 1rem;
          }
          p{
            //margin: 1rem 3.75rem;
            margin: 1rem;
            height: auto;
          }
          .read-more{
            position: relative;
            height: 5rem;
          }
          &:after {
            height: 0;
          }
        }
        &:hover{
          .read-more{
            height: 5rem;
          }
        }
      }
    }
    .row-tiles-table{
      .swiper-scrollbar,
      .table-swiper-button-next,
      .table-swiper-button-prev{
        display: block;
      }
    }
  }
}
@media (max-width: @screen-sm-min) {
  //@media (max-width: @screen-xs-min) {
  .plans-table{
    padding: 0;
    .tile-table{
      width: 80%;
      /*  padding: 0 0.5rem 3rem; */
      .tile{
        .tiles-box{
          .cover{
            height: 11.875rem;
          }
          h3{
            height: 11.875rem;
          }
          ul{
            margin: 1rem;
          }
          p{
            //margin: 1rem 3.75rem;
            height: 3.75rem !important;
            margin: 1rem;
            height: auto;
          }
          .read-more{
            position: relative;
            height: 5rem;
          }
          &:after {
            height: 0;
          }
        }
        &:hover{
          .read-more{
            height: 5rem;
          }
        }
      }
    }
  }
  .freedom{
    .plans-table{
       .row-tiles-table{
          .swiper-container{
            left: -3%;
            width: 105%;
          }
       }
    }
  }
}
@media (min-width: @screen-sm-min) {
  .plans-table {
    .row-tiles-table {
      .swiper-scrollbar,
      .table-swiper-button-next,
      .table-swiper-button-prev {
        display: block;
      }
    }
  }
}

@media (min-width: @screen-md-min) and (max-width: @screen-lg-min) {
  .plans-table{
    &.plans-table-A {
      .tile-table .tile {
        .tiles-box {
          .cover {
            height: 5.5rem;
          }
          .tiles-box-title {
            height: 5.5rem;
            margin: 0 1.625rem;
            padding: 0 0 0.75rem;
            h3, .h3 {
              font-size: 1rem;
              line-height: 1.25rem;
            }
            h2, .h2 {
              font-size: 2.5rem;
              line-height: 2.5rem;
            }
          }
          .tiles-box-list {
            margin: 0 1.625rem;
            padding: 1rem 0;
            height: 13.75rem;
          }
          .tiles-box-line {
            height: 1.75rem;
            margin-bottom: 0.5rem;
            h6 {
              font-size: 0.875rem;
              line-height: 1.75rem;
              .tiles-box-number {
                font-size: 1.25rem;
                line-height: 1.75rem;
              }
            }
          }
          p.bottom-text {
            height: 7rem;
            margin: 1rem 1.625rem 0;
          }
        }
        .tile-card__back {
          .tiles-box {
            ul li {
              .body-standard;
            }
            p.back-text {
              .body-standard;
              height: 6.5rem;
            }
            ul {
              margin: 0 1.625rem;
              padding: 1rem 0;
              height: 10rem;
            }
          }
        }
      }
    }
  }
}
@media (max-width: @screen-md-min) {
  .plans-table{
    &.plans-table-A{
      .tile-table .tile{
        .tiles-box{
          .cover{
            height: 7.5rem;
          }
          .tiles-box-title{
            height: 7.5rem;
            margin: 0 1.625rem;
            padding: 0 0 1.25rem;
            h3,.h3{
              font-size: 1.25rem;
              line-height: 1.5rem;
            }
            h2,.h2{
              font-size: 3.375rem;
              line-height: 3rem;
            }
          }
          .tiles-box-list, ul{
            margin: 0 1.625rem;
            padding: 1.5rem 0;
            height: 18.75rem;
          }
          .tiles-box-line{
            height: 2rem;
            margin-bottom: 1rem;
            h6{
              font-size: 1rem;
              line-height: 2rem;
              .tiles-box-number{
                font-size: 1.25rem !important;
                line-height: 2rem;
              }
            }
          }
          p.bottom-text{
            margin: 1rem 1.625rem 0;
          }
        }
        .tile-card__back {
          .tiles-box{
            ul li{
              .body-standard;
            }
            p.back-text{
              .body-standard;
              height: 7.5rem;
            }
          }
        }
      }
    }
  }
}
/*
 * ARABIC
 */
*[lang="ar"] {
  *[dir="rtl"] {
    .plans-table {
      .tile-table{
        .tile{
          .tiles-box{
            ul{
              li{
                text-align: right;
              }
            }
          }
        }
      }

      &.plans-table-A{
        .tile{
          .flip-icon {
            left: 0;
            right: inherit;
          }
          .tiles-box{
            .tiles-box-title{
              h3,.h3,  h2,.h2{
                text-align: right;
              }
              h3,.h3{
                padding-left: 1rem;
                padding-right: 0;
              }
            }
            .tiles-box-line{
              h6{
                text-align: right;
              }
              h6:nth-child(1){
                padding-left: 1rem;
                padding-right: 0;
              }
            }
          }
          .tile-card__back {
            .tiles-box{
              p.back-text{
                text-align: right;
              }
            }
          }
        }
      }

    }
  }
}



.plans-table  {
  &.plans-new {
    .tile-table {
      .tile {
        .tiles-box {
          p {
            text-align: left;
          }
          .tiles-box-title {
            h3 {
              font-weight: normal;
              letter-spacing: 0;
              font-size: 1.5rem;
              line-height: 1.2;
              text-transform: none;
            }
            h2 {
              display: none;
            }
          }
          .tiles-box-line {
            margin-bottom: 1rem !important;
            h6 {
              color: #353738;
              letter-spacing: 0;
              .tiles-box-number {
                font-size: 16px !important;
                line-height: 1.5rem !important;
                color: #848789;
              }
            }
            .progress .progress-bar {
              background-color: @brand-primary;
              height: 3px;
              display: none;
            }
          }
        }
      }
    }
    .detail-info-wrap-pricetag {
      margin: 0 1.625rem;
      justify-content: flex-start;
      .detail-price-new {
        color: #353738;
      }
      .main-part {
        .price {
          font-size: 36px;
        }
        .price-specs {
          font-size: 12px;
        }
      }
      .lower-part {
        font-size: 12px;
      }
    }
    .bottom-text {
      font-size: 14px !important;
    }
    .read-more svg {
      display: none;
    }
  }
  .tile-table{
    .tile{
      .tiles-box{
        .tiles-box-title{
          h3{
            text-transform: none;
          }
        }
        .tiles-box-text{
          p{
            font-size: 1rem;
          }
        }
      }

    }
  }
}
