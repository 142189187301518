.wst{
  .title-wrapper {
    position: relative;
    margin-bottom: 2.125rem;

    // &.has-shadow {
    //   h3 {
    //     &:before {
    //       content: attr(data-shadow);
    //       position: absolute;
    //       opacity: .03;
    //       color: @premium;
    //       font-size: 5rem;
    //       font-weight: 900;
    //       width: 100%;
    //       line-height: .7;
    //       left: 0;
    //       top: 0;
    //       font-family: @font-family-base;
    //     }
    //   }
    // }

    .pre-tite {
      text-transform: capitalize;
      margin-bottom: 1rem;

      p {
        color: @main-green;
        margin: 0;
        font-size: .875rem;
        text-transform: uppercase;
        letter-spacing: 2px;
      }

      span {
        position: absolute;
        right: 0;
        top: 0;

        font-size: .75rem;
        letter-spacing: 1.6px;

        a {
          color: #353738;
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      svg.share-icon {
        width: 12px;
        fill: #719e19;
      }
    }

    .title {
      h3 {
        position: relative;
        color: @premium;
        margin: 0;
        font-size: 2.5rem;
        text-transform: capitalize;
        letter-spacing: 0;
      }
      &.base-style{
        h3 {
          font-family: @font-family-base;
        }
      }
    }
  }
}


*[lang='ar'] *[dir='rtl'] {
  .wst{
    .title-wrapper .pre-tite span {
      left: 0;
      right: auto;
    }
    .default-section-title {
      .learn-more {
        left: 1.5rem;
        right: auto;
      }
    }
  }

}
.wst{
  h3 {
    letter-spacing: normal
  }
  .default-section-title {
    position: relative;
    overflow: hidden;
    color: @premium;
    //font-size: 1.75rem;
    line-height: 2.25rem;
    margin: 0;
    font-family: @headings-font-family;
    letter-spacing: .5px;
    text-transform: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      line-height: 1.25;
      text-transform: unset;
      font-size: 1.75rem;
      margin-bottom: 1.2rem
    }

    .learn-more {
      right: 1.5rem;
    }

    &.with-b-b {
      padding-bottom: 1rem;
      border-bottom: 1px solid #edeee6;
    }
  }
  .action-btn {
    display: flex;
    align-items: center;

    svg {
      width: 50px;
      height: 30px;
    }

    a {
      color: @almost-black;
      text-transform: uppercase;
      //font-weight: 600;
      font-size: .875rem;
      letter-spacing: 2px;
    }
  }
  .addons-heading {
    margin-top: 0;
    letter-spacing: normal;
    color: @almost-black;
    font-size: 1.2rem;
    font-family: @font-family-base;
    font-weight: bold;
    margin-bottom: 10px;

  }
  .page-nav {
    .active a,
    .active a:active,
    .active a:focus,
    .active a:focus,
    .active.highlight a {
      border-bottom: 4px solid @main-green;
    }
  }
}

.default-sub-title p {
    color: #848789;
    font-size: 1.125rem;
    margin: 1rem 0 2.5rem 0;
    line-height: 1.75rem;
    font-family: @font-family-base;
}

.default-title {
  letter-spacing: normal;
  &.h4{

  }
}
.default-text {
    color: @gray-fill;
    font-size: 14px;
    line-height: 19px;
    font-family: @font-family-base;
}



// arabic language

//mobile
@media (max-width: @screen-ms-min) {
  .wst{
    .title-wrapper {
      .title {

        font-size: 1.4rem;

      }
    }

    .default-title {
      font-size: 1.25rem;
    }

    .default-section-title {
      font-size: 1.3rem;
      line-height: 3rem;
      letter-spacing: normal;
      h3{
        width: 100%;
        font-size: 1.25rem
      }
    }

    .default-sub-title {
      margin: .5rem 0 0;
      font-size: .9rem;
      line-height: 1.3rem;
      p {
        margin:0 0 1.2rem 0;
        font-size: .9rem;
        line-height: 1.2rem;
      }
    }
  }

}
