// *************************************
// footer small - links and logo
// *************************************
.footer-links-logo-section {
  background: @gray-light;
  min-height: 100px;

//*****************************
// for wst structure only

  &.container {
    @media (min-width: 1248px) {
      width: 1224px;
    }
    @media (max-width: @screen-sm) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

//******************************
  @media (max-width: @screen-md) {
    margin-bottom: 1.5rem;
  }

  @media (max-width: @screen-ms) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .etisalat-logo {
    display: block;
    text-align: right;

    svg {
      width: 260px;

      @media (min-width: @screen-md) {
        height: 100%;
      }
    }
  }

  .footer-links-nav {
    li {
      display: inline-block;

      @media (max-width: @screen-md) {
        margin-bottom: 1rem;
      }

      a {
        border-right: 1px solid @white-2;
        color: @texts-gray;
        font-size: .85rem;
        line-height: 1.1rem;
        padding-right: 1.5rem;
        margin-right: 1.5rem;
        letter-spacing: 0.56px;

        &:hover {
          text-decoration: underline;
        }
      }

      &:last-child {
        a {
          padding-right: 0;
          margin-right: 0;
          border-right: none;
        }
      }
    }
  }

  .flex-container {
    display: block;

    .footer-links-nav {
      margin-bottom: 2rem;
    }

    .footer-links-nav,
    .etisalat-logo {
      text-align: center;
    }

    .etisalat-logo {

      img,
      svg {
        max-width: 100%
      }
    }

    @media (min-width: @screen-md) {
      display: flex;
      align-items: center;

      .footer-links-nav {
        text-align: left;
        margin-bottom: 1rem;
      }

      .etisalat-logo {
        text-align: right;
      }
    }
  }

  .copyright {
    text-align: center;
    color: #848789;
    font-size: 12px;
    line-height: 1.2;
    font-family: @font-family-base;

    @media (min-width: @screen-md) {
      display: none;
    }

    &.desktop {
      display: none;

      @media (min-width: @screen-md) {
        display: block;
        text-align: left;
      }
    }
  }

  .social-links-section {
    margin-bottom: 1rem;
  }
}

// *************************************
// footer quick links
// *************************************
.quick-links-section {
  background-color: @sand-white-50;
  padding: 3.5rem 0 3rem;
  margin-bottom: 1.5rem;
//*****************************
// for wst structure only
  @media (min-width: 1248px) {
    .container {
      width: 1224px;
    }
  }
  @media (max-width: @screen-sm) {
    .container {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
//*****************************
  @media (max-width: @screen-md) {
    padding: 1.5rem 0;
  }

  @media (max-width: @screen-ms) {
    >.container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .quick-links-head {
    position: relative;
    margin-bottom: 1.6rem;

    .collapsed+svg {
      transform: rotate(0deg);
    }

    svg {
      position: absolute;
      top: 6px;
      right: 0;
      transform: rotate(180deg);
      transition: all .2s ease-in-out;
    }

    .link-title-mob,
    .adf {
      font-family: @font-family-base;
      text-transform: uppercase;
      color: @almost-black;
      display: block;
    }

    .links-title {
      font-size: 1rem;
      font-weight: bold;
      letter-spacing: 2.4px;
      text-transform: uppercase;
    }

    .link-title-mob {
      font-size: 12px;
      letter-spacing: 1.6px;
    }

    svg {
      width: 14px;
      height: 7px;
    }
  }

  .quick-links-body {
    &.collapse {
      @media (min-width: @screen-md) {
        display: block !important;
        height: auto !important;
      }
    }

    .links-item {
      padding-bottom: 1.4rem;

      @media (min-width: @screen-md) {
        padding-bottom: 10px;
      }

      a {
        font-size: 12px;
        color: @almost-black;
        font-family: @font-family-base;

        @media (min-width: @screen-md) {
          font-size: 14px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

// *************************************
// footer social icons
// *************************************
.social-links-section {
  .social-links-head {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: @screen-md) {
      justify-content: flex-start;
    }

    span {
      font-size: 1rem;
      font-weight: 700;
      letter-spacing: 2.4px;
      text-transform: uppercase;
      margin-right: 1.5rem;
      line-height: 1.5rem;
    }
  }

  .social-links-body {
    height: 1.5rem;

    .links-item {
      display: inline-block;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }

      svg,
      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}

*[lang="ar"] {
  *[dir="rtl"] {
    .quick-links-section .quick-links-head svg {
      right: auto;
      left: 0;
    }

    //.social-links-body {
    //  .links-item {
    //    &:last-child {
    //      //margin-right: 1rem;
    //    }
    //  }
    //}
    .footer-links-logo-section {
      .footer-links-nav {
        text-align: right; //for wst implementation
        li a {
          padding-right: 0;
          margin-right: 0;
          padding-left: 1.5rem;
          margin-left: 1.5rem;
          border-right: 0;
          border-left: 1px solid #e5e5e5;
        }
      }

      li:last-child a {
        padding-right: 0;
        margin-right: 0;
        border-left: none;
      }
    }

    .social-links-section {
      .social-links-head {
        span {
          margin-right: 0;
          margin-left: 1.5rem;
        }
      }

      .social-links-body {
        .links-item {
          margin-right: 0;
          margin-left: 1rem;
        }
      }
    }

    .desktop {
      @media (min-width: @screen-md) {
        text-align: right;
      }
    }
  }
}
