.config-accordion-wrapper {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  & + .config-accordion-wrapper {
    padding-top: 0.5rem;
  }
  .config-accordion {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    .transition();
    &:hover {
      cursor: pointer;
    }
    .accordion-title {
      margin-left: 0.9375rem;
      h3 {
        color: @main-green;
        margin: 0;
        font-size: 1.125rem;
      }
      p {
        font-size: 0.75rem;
        color: @almost-black;
        padding-top: 0;
        margin-bottom: 0;
        text-transform: uppercase;
        span + span {
          &:before {
            content: ", ";
          }
        }
      }
    }
    .accordion-status {
      margin: 1.1875rem 0 1.4625rem;
      width: 1.84375rem;
      height: 1.84375rem;
      background-color: @main-green;
      font-size: 1.125rem;
      font-family: @headings-font-family;
      line-height: 3.75rem;
      text-align: center;
      border-radius: 2rem;
      color: @white;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        display: none;
      }
    }
    .detail-info-wrap-pricetag {
      width: auto;
      float: right;
    }
    .accordion-arrow {
      text-align: right;
      svg {
        stroke: @main-green;
        width: 1.25rem;
        height: 0.6875rem;
        stroke-width: 1px;
        fill: @main-green;
        .transition();
        /*transform: translate(0%, 0) rotate(-180deg);
        transform-origin: center center;*/
        use {
          -moz-transform: translate(0%, 0) rotate(0deg);
          -moz-transform-origin: center center;
        }
      }
    }
    .accordion-price {
      visibility: hidden;
      display: flex;
      justify-content: flex-end;
      .detail-info-wrap-pricetag {
        .main-part {
          .price {
            font-size: 1.875rem;
          }
          .price-specs {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
  .config-accordion--verC {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
    padding-top: 1.2rem;
    padding-bottom: 0.9rem;
    margin-bottom: 0.28125rem;
    .text-align-R {
      text-align: right;
    }
    .text-align-L {
      text-align: left;
    }
    .text-align-RM {
      text-align: right;
    }
    span.label_accordion {
      font-size: 0.875rem;
      font-family: @font-family-sans-serif;
      font-weight: 700;
      color: @white;
      text-transform: uppercase;
      width: 100%;
      display: inline-block;
      letter-spacing: 0.05176470588rem;
      .opacity(0.9);
    }
    span.text-label {
      font-size: 0.875rem;
      font-family: @font-family-sans-serif;
      font-weight: 400;
      color: @steel-grey;
      text-transform: uppercase;
      width: 100%;
      display: inline-block;
      letter-spacing: 0.05176470588rem;
      .opacity(0.9);
      margin-bottom: 1.3125rem;
      &.green{
        color: @main-green;
      }
    }
    .text-order-id {
      span.text-label {
        color: @steel-grey;
      }
    }
    .label-price--verC {
      font-size: 0.75rem;
      font-family: @font-family-menu-sans-serif;
      font-weight: 400;
      color: @steel-grey;
      display: inline-block;
      width: 100%;
      margin-top: -0.3125rem;
      letter-spacing: 0.046875rem;
      .opacity(0.9);
      .price--verC {
        font-family: @font-family-menu-sans-serif;
        color: @main-green;
        font-size: 1.25rem;
        font-weight: 400;
        display: inline-block;
        margin-bottom: 0;
        letter-spacing: 0.078125rem;
      }
    }
    .accordion-arrow {
      text-align: center;
      height: 39px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      svg {
        stroke: @steel-grey;
        width: 0.543125rem;
        height: 0.934375rem;
        stroke-width: 12px;
        transform: translate(0%, 0) rotate(-180deg);
        .transition();
      }
    }
  }
  &.current-accordion-wrapper {
    span.label_accordion {
      color: @almost-black;
    }
  }
  &.ver--C.config-accordion-ticked {
    background: @sand-white;
    .config-accordion--verC {
      margin-bottom: 0;
    }
  }
  &.ver--C {
    margin-top: 0.28125rem;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: visible;
  }
  &.config-accordion-ticked {
    .config-accordion--verC {
      box-shadow: none;
      .accordion-arrow {
        text-align: center;
        svg {
          transform: translate(0%, 0) rotate(180deg);
        }
      }
    }
  }
  .config-accordion-content {
    height: auto;
    /*overflow: hidden;*/
    .transition();
  }
  &.config-accordion-ticked {
    .config-accordion {
      background-color: @sand-white;
      box-shadow: none;
      .accordion-status {
        background-color: @white;
        color: @ligther-gray;
      }
      .accordion-title {
        h3,
        p {
          color: @almost-black;
        }
      }
      .detail-info-wrap-pricetag .main-part {
        color: @almost-black;
      }
      .accordion-arrow {
        text-align: right;
        svg {
          stroke: @dark-accordion-gray;
          fill: @dark-accordion-gray;
          transform: translate(0%, 0) rotate(180deg);
          -moz-transform-origin: center center;
          -moz-transform: translate(0%, 0) rotate(180deg);
          use {
            -moz-transform: translate(0%, 0) rotate(180deg);
          }
        }
      }
    }
    .config-accordion-content {
      height: 0;
      overflow: hidden;
    }
  }
  &.config-accordion-disabled-behaviour,
  &.config-accordion-disabled-behaviour.config-accordion-ticked,
  &.config-accordion-disabled.config-accordion-ticked {
    .config-accordion {
      background-color: @cold-gray;
      .accordion-status {
        background-color: @white;
        color: @white;
        span {
          color: @ligther-gray;
        }
        svg {
          display: none;
        }
      }
      .accordion-title{
        h3, p{
          color: @disabled-gray;
        }
      }
      .accordion-arrow {
        svg {
          stroke: @disabled-gray;
          fill: @disabled-gray;
        }
      }
    }
  }
  &.config-accordion-disabled-behaviour.config-accordion-ticked.config-accordion-completed{
    .config-accordion {
      .accordion-status {
        svg {
          display: block;
        }
      }
    }
  }
  &.config-accordion-completed.config-accordion-ticked {
    .config-accordion {
      background-color: @sand-white;
      box-shadow: none;
      .accordion-status {
        background-color: @gray-fill;
        color: @white;
        span {
          display: none;
        }
        svg {
          fill: @white;
          width: 0.755rem;
          height: 0.5875rem;
          display: block;
        }
      }
    }
  }
  .text-status {
    text-align: right;
  }
}
.text-and-table-wrapper + .config-accordion-wrapper {
  padding-top: 0;
}

.business {
  .config-accordion-wrapper {
    background-color: @sand-white;
    .config-accordion {
      background-color: @white;
      .accordion-title {
        h3 {
          color: @dark-green;
        }
        p {
          color: @dark-green;
        }
      }
      .accordion-status {
        background-color: @dark-green;
      }
      .accordion-arrow {
        svg {
          fill: @dark-green;
        }
      }
      .accordion-price {
        .main-part {
          color: @dark-green;
        }
      }
    }
    &.without-checkbox {
      span.text-label {
        margin-top: 1.3125rem;
      }
    }
    &.config-accordion-ticked {
      .config-accordion {
        background-color: @moonlight-sand;
        .accordion-title {
          h3 {
            color: @white;
          }
          p {
            color: @white;
          }
        }
        .accordion-status {
          background-color: @white;
          color: @dark-green;
          svg {
            fill: @dark-green;
          }
        }
        .accordion-price {
          .main-part {
            color: @white;
          }
        }
        .accordion-arrow {
          svg {
            fill: @white;
          }
        }
      }
    }
    &.ver--C.config-accordion-ticked {
      background: @dark-gray;
      span.text-label {
        color: @cold-gray;
      }
      .label-price--verC {
        color: @cold-gray;
        .price--verC {
          color: @cold-gray;
        }
      }
      .accordion-arrow {
        svg {
          stroke: @white;
          transform: translate(0%, 0) rotate(180deg);
        }
      }
    }

    &.config-accordion-disabled-behaviour,
    &.config-accordion-disabled-behaviour.config-accordion-ticked,
    &.config-accordion-disabled.config-accordion-ticked {
      .config-accordion {
        background-color: @cold-gray;
        .accordion-status {
          background-color: @white;
          color: @white;
          span {
            color: @ligther-gray;
          }
          svg {
            display: none;
          }
        }
        .accordion-title{
          h3, p{
            color: @disabled-gray;
          }
        }
        .accordion-arrow {
          svg {
            stroke: @disabled-gray;
            fill: @disabled-gray;
          }
        }
      }
    }
    &.config-accordion-disabled-behaviour.config-accordion-ticked.config-accordion-completed{
      .config-accordion {
        .accordion-status {
          svg {
            display: block;
          }
        }
      }
    }

    .config-accordion--verC {
      span.text-label {
        text-transform: inherit;
      }
      .label-price--verC {
        color: @steel-grey;
        .price--verC {
          color: @dark-green;
        }
      }
    }
  }
}
.activity-log {
  background: @sand-white;
  padding-bottom: 1.5rem;
  .action-buttons-container {
    svg {
      margin-top: 10px;
      height: 16px;
      width: 22px;
    }
    a {
      color: @gray-dark;
      text-decoration: underline;
      font-size: 13px;
    }
  }
}
.last-update {
    padding-bottom: 30px;
    .h5-update {
        margin: 0;
        color: @steel-grey;
    }
    h4 {
        margin-top: 30px;
        color: @almost-black;
    }
    .h3-green {
        color: @dark-green;
    }
    .circle1 {
        background: @sand-white;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 0;
        position: absolute;
        bottom: -55px;
        left: 50%;
        margin-left: -25px;
        outline: none;
        -webkit-transform: translate(0) rotate(-180deg);
        transform: translate(0) rotate(-180deg);

        .svg-arrow-down {
            width: 26px;
            height: 21px;
            stroke-width: 0px;
            margin: 21px 0 0 10px;
        }
    }
    .collapse.in {
        display: inline-block;
        width: 100%;

        .slide-table .text-and-table {
            background: transparent;
            padding: 2rem 0 0;

            table {
                width: 100%;
                border-bottom: 1px solid @cold-gray;

                td {
                    padding: 1rem;
                    text-align: left;
                    color: @gray;
                    font-size: 1rem;
                    border-top: 1px solid @cold-gray;
                    border-bottom: 1px solid @cold-gray;
                }
            }
        }
    }
}
.circle1.collapsed {
    -webkit-transform: translate(0) rotate(-360deg) !important;
    transform: translate(0) rotate(-360deg) !important;
}



.config-accordion-wrapper.current-accordion-wrapper {
    .config-accordion--verC.noBoxShadow {
      box-shadow: none !important;
    }
  }
.cart-order {
   margin-bottom: 0px !important;

    .cart-item {
        background: @sand-white;
        .cart-items-box {
            padding: 27px 0;
        }
        .cart-badge {
            z-index: 1;
            right: 8.33333333% !important;
            top: 27px;
            border-color: transparent #616B1E transparent transparent;
            .icon-wrap1 {
                margin: 27px 0 0 0;
                display: inline-block;

                .icon-badge {
                    top: 13px;
                    margin-right: -6px;
                    right: -82px;
                    width: 35px;
                    height: 35px;
                }
            }
        }
    }
    .cart-item-content {
        background: @white;
    }
}

.order-acco.config-accordion-wrapper {
  background: @white;
  .config-accordion--verC {
    .accordion-arrow {
      svg {
        width: 30px !important;
        height: 30px !important;
        stroke-width: 0 !important;
      }
    }
    span.label_accordion {
      display: none;
    }
  }
}
.config-accordion-wrapper {
  .config-accordion--verC.table-checkbox-round .checkbox,
  .config-accordion--verC.table-checkbox-round .click1 {
    padding-top: 1rem;
  }
}
.order-acco.config-accordion-ticked span.text-label {
    color: @white !important;
}
.order-acco.config-accordion-ticked {
    background: @almost-black !important;
    margin-bottom: 3px;
    &.config-accordion-ticked {
      .config-accordion--verC {
        .accordion-arrow {
          svg {
            transform: translate(0%, 0) rotate(0deg);
          }
        }
      }
    }
    .accordion-arrow {
        svg {
            fill: @white !important;
            width: 30px !important;
            height: 30px !important;
            stroke-width: 0 !important;
        }
    }
}

@media (min-width: 320px) and (max-width: 480px) {
  .last-update {
      .circle1.collapsed {
          bottom: -120px;
      }
  }
}
@media (min-width: 300px) and (max-width: 767px) {
  .business {
    #all-select-items {
      .form {
        .checkbox {
          position: absolute;
          top: 75px;
          left: -10px;
          label {
            &:before {
              width: 25px;
              height: 25px;
            }
          }

        }
      }
    }
    .config-accordion-wrapper.config-accordion-ticked.order-acco {
      .config-accordion--verC  {
        span.label_accordion {
          color: @white;
          display: block;
        }
      }
    }
    .config-accordion-wrapper.current-accordion-wrapper.order-acco {
      .config-accordion--verC  {
        span.label_accordion {
          display: block;
        }
      }
    }
  }
}
@media (min-width: @screen-xs-min) {

  .last-update {
      .circle1.collapsed {
          bottom: -120px;
      }
  }

}
@media (min-width: @screen-sm-min) {
  .config-accordion-wrapper {
    .config-accordion {
      .accordion-title {
        h3 {
          font-size: 1.5rem;
        }
      }
      .accordion-status {
        margin: 1.40625rem 0;
        width: 2rem;
        height: 2rem;
        font-size: 1.5rem;
      }
      .accordion-arrow {
        text-align: right;
        svg {}
      }
      .accordion-price {
        visibility: visible;
      }
    }
    .config-accordion--verC {
      position: relative;
      .text-align-R {
        text-align: left;
      }
      .text-align-L {
        text-align: right;
      }
      .accordion-arrow {
        text-align: center;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        align-content: stretch;
        svg {
          stroke: @almost-black;
          width: 0.653125rem;
          height: 1.181875rem;
          stroke-width: 6px;
          transform: translate(0%, 0) rotate(-180deg);
          .transition();
        }
      }
    }
    &.config-accordion-ticked {
      .config-accordion--verC {
        .accordion-arrow {
          svg {
            transform: translate(0%, 0) rotate(180deg);
          }
        }
      }
    }
    .text-status {
      text-align: left;
    }
    .text-creation-date {
      text-align: right;
    }
  }
  .config-accordion--verC {
          .accordion-arrow {
              svg {
              width: .829375rem !important;
              height: 1.539375rem !important;
              stroke-width: 3px !important;
          }
      }
      span.label_accordion {
        display: none;
      }
  }
  .last-update {
      .circle1.collapsed {
          bottom: -120px;
      }
  }
}
@media (min-width: @screen-md-min) {
  .config-accordion-wrapper {
    .config-accordion {
      .accordion-title {
        h3 {
          font-size: 1.625rem;
        }
      }
      .accordion-status {
        margin: 1.8625rem 0 1.8375rem;
        width: 2.625rem;
        height: 2.625rem;
        font-size: 1.625rem;
      }
      .accordion-arrow {
        text-align: right;
        svg {
          width: 1.525625rem;
          height: 0.829375rem;
        }
      }
      .accordion-price {
        .detail-info-wrap-pricetag {
          .main-part {
            .price {
              font-size: 2.5rem;
            }
            .price-specs {
              font-size: 0.75rem;
            }
          }
        }
      }
    }
    .config-accordion--verC {
      .accordion-arrow {
        svg {
          width: 0.829375rem;
          height: 1.539375rem;
        }
      }
      span.label_accordion {
        //comment below line for the defect on order tracking page labels heading hiding on tablet "L"
        //display: none;
      }
    }
    &.config-accordion-ticked {
      .config-accordion {
        .accordion-status {
          svg {
            width: 0.805625rem;
            height: 0.62875rem;
          }
        }
      }
    }
  }
  .last-update {
      .circle1.collapsed {
          bottom: -55px;
      }
  }
}
// @media (min-width: @screen-lg-min) {
//   .config-accordion-wrapper {
//     .config-accordion {
//       .accordion-title {
//         h3 {
//           font-size: 1.875rem;
//         }
//       }
//       .accordion-status {
//         width: 3.75rem;
//         height: 3.75rem;
//         font-size: 1.875rem;
//         line-height: 3.75rem;
//         border-radius: 2rem;
//       }
//       .accordion-arrow {
//         text-align: right;
//         svg {
//           width: 2.125rem;
//           height: 1.125rem;
//           stroke-width: 1px;
//         }
//       }
//       .accordion-price {
//         .detail-info-wrap-pricetag {
//           padding-left: 1.5rem;
//           .main-part {
//             .price {
//               font-size: 3.375rem;
//             }
//             .price-specs {
//               font-size: 1rem;
//             }
//           }
//         }
//       }
//     }
//     .config-accordion--verC {
//       display: flex;
//       align-items: center;
//       justify-content: flex-start;
//       .text-align-R {
//         text-align: center;
//       }
//       .text-align-L {
//         text-align: right;
//       }
//       span.label_accordion {
//         display: none;
//       }
//       span.text-label {
//         margin-bottom: 0;
//         font-size: 1rem;
//       }
//       .label-price--verC {
//         font-size: 0.875rem;
//       }
//       .accordion-arrow {
//         svg {
//           width: 1rem;
//           height: 1.99625rem;
//           stroke-width: 4px;
//           transform: translate(0%, 0) rotate(-180deg);
//         }
//       }
//     }
//     &.config-accordion-ticked {
//       .config-accordion {
//         .accordion-status {
//           svg {
//             width: 1.1325rem;
//             height: 0.87875rem;
//           }
//         }
//       }
//     }
//     .text-creation-date {
//       text-align: left;
//     }
//   }
//   .business {
//     .config-accordion-wrapper {
//       &.current-accordion-wrapper {
//         .config-accordion--verC {
//           background-color: @business-table-ticked;
//           .label-price--verC {
//             color: @white;
//             .price--verC {
//               color: @white;
//             }
//           }
//           span.text-label {
//             color: @white;
//           }
//           .accordion-arrow {
//             svg {
//               stroke: @white;
//             }
//           }
//         }
//
//       }
//       &.config-accordion-disabled-behaviour,
//       &.config-accordion-disabled-behaviour.config-accordion-ticked,
//       &.config-accordion-disabled.config-accordion-ticked {
//         .config-accordion {
//           background-color: @cold-gray;
//           .accordion-status {
//             background-color: @white;
//             color: @white;
//             span {
//               color: @ligther-gray;
//             }
//             svg {
//               display: none;
//             }
//           }
//           .accordion-title{
//             h3, p{
//               color: @disabled-gray;
//             }
//           }
//           .accordion-arrow {
//             svg {
//               stroke: @disabled-gray;
//               fill: @disabled-gray;
//             }
//           }
//         }
//       }
//       &.config-accordion-disabled-behaviour.config-accordion-ticked.config-accordion-completed{
//         .config-accordion {
//           .accordion-status {
//             svg {
//               display: block;
//             }
//           }
//         }
//       }
//       .config-accordion--verC {
//         box-shadow: none;
//         span.text-label {
//           text-transform: inherit;
//         }
//         .accordion-arrow {
//           svg {
//             width: 1rem;
//             height: 1.99625rem;
//             stroke-width: 4px;
//             transform: translate(0%, 0) rotate(-180deg);
//           }
//         }
//       }
//       &.config-accordion-ticked {
//         .config-accordion--verC {
//           background-color: @steel-grey;
//         }
//         span.text-label {
//           color: @cold-gray;
//         }
//         .label-price--verC {
//           color: @cold-gray;
//           .price--verC {
//             color: @cold-gray;
//           }
//         }
//         .accordion-arrow {
//           svg {
//             stroke: @white;
//             transform: translate(0%, 0) rotate(180deg);
//           }
//         }
//       }
//     }
//   }
// }
@media (min-width: @screen-lg-min) {
  .config-accordion-wrapper {
    .config-accordion {
      .accordion-title {
        h3 {
          font-size: 1.875rem;
        }
      }
      .accordion-status {
        width: 3.75rem;
        height: 3.75rem;
        font-size: 1.875rem;
        line-height: 3.75rem;
        border-radius: 2rem;
      }
      .accordion-arrow {
        text-align: right;
        svg {
          width: 2.125rem;
          height: 1.125rem;
          stroke-width: 1px;
          stroke: #fff;
        }
      }
      .accordion-price {
        .detail-info-wrap-pricetag {
          padding-left: 1.5rem;
          .main-part {
            .price {
              font-size: 3.375rem;
            }
            .price-specs {
              font-size: 1rem;
            }
          }
        }
      }
    }
    .config-accordion--verC {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .text-align-R {
        text-align: center;
      }
      .text-align-L {
        text-align: right;
      }
      span.label_accordion {
        display: none;
      }
      span.text-label {
        margin-bottom: 0;
        font-size: 1rem;
      }
      .label-price--verC {
        font-size: 0.875rem;
      }
      .accordion-arrow {
        svg {
          width: 1rem;
          height: 1.99625rem;
          stroke-width: 4px;
          transform: translate(0%, 0) rotate(-180deg);
        }
      }
    }
    &.config-accordion-ticked {
      .config-accordion {
        .accordion-status {
          svg {
            width: 1.1325rem;
            height: 0.87875rem;
            stroke: #fff;
            fill: #fff;
          }
        }
      }
    }
    .text-creation-date {
      text-align: left;
    }
  }
  .business {
    .config-accordion-wrapper {
      &.current-accordion-wrapper {
        .config-accordion--verC {
          background-color: @business-table-ticked;
          .label-price--verC {
            color: @white;
            .price--verC {
              color: @white;
            }
          }
          span.text-label {
            color: @white;
          }
          .accordion-arrow {
            svg {
              stroke: @white;
            }
          }
        }

      }
      &.config-accordion-disabled-behaviour,
      &.config-accordion-disabled-behaviour.config-accordion-ticked,
      &.config-accordion-disabled.config-accordion-ticked {
        .config-accordion {
          background-color: @cold-gray;
          .accordion-status {
            background-color: @white;
            color: @white;
            span {
              color: @ligther-gray;
            }
            svg {
              display: none;
            }
          }
          .accordion-title{
            h3, p{
              color: @disabled-gray;
            }
          }
          .accordion-arrow {
            svg {
              stroke: @disabled-gray;
              fill: @disabled-gray;
            }
          }
        }
      }
      &.config-accordion-disabled-behaviour.config-accordion-ticked.config-accordion-completed{
        .config-accordion {
          .accordion-status {
            svg {
              display: block;
            }
          }
        }
      }
      .config-accordion--verC {
        box-shadow: none;
        span.text-label {
          text-transform: inherit;
        }
        .accordion-arrow {
          svg {
            width: 1rem;
            height: 1.99625rem;
            stroke-width: 4px;
            transform: translate(0%, 0) rotate(-180deg);
          }
        }
      }
      &.config-accordion-ticked {
        .config-accordion--verC {
          background-color: @steel-grey;
        }
        span.text-label {
          color: @cold-gray;
        }
        .label-price--verC {
          color: @cold-gray;
          .price--verC {
            color: @cold-gray;
          }
        }
        .accordion-arrow {
          svg {
            fill: @white;
            stroke: @white;
            transform: translate(0%, 0) rotate(180deg);
          }
        }
      }
    }
  }
}
@media (max-width: @screen-xs){
  .last-update {
      td {
        font-size: 12px !important;
      }
  }
}
@media (max-width: @screen-sm){
  .last-update {
    h3 {
      font-size: 20px;
    }
    h4 {
        font-size: 14px;
    }
    h5 {
        font-size: 15px;
    }
  }

}
@media (max-width: @screen-md){
  .last-update {
      border-bottom: 2px solid #d4d4d4;
  }
}
*[lang="ar"] {
  *[dir="rtl"] {
    @media (min-width: @screen-sm-min) {
      .config-accordion-wrapper {
        .config-accordion {
          .accordion-arrow {
            text-align: center;
          }
        }
      }
    }
    @media (min-width: @screen-lg-min) {}
  }
}
//checkout pages
.config-accordion-wrapper .config-accordion-content {
  .my-info-container {
    padding: 2rem 0;
    .inner-section {
      padding: 2rem 0;
      .inner-section {
        padding: 2rem 0;
      .accordion-center-msg {
        p {
          margin-bottom: 2rem;
          }
        }
      }
      .form-section {
        margin-bottom: 1rem;
        .checkbox {
          &:only-child {
            padding-top: 0;
          }
        }
      }
      .config-form-container{
        h3{
          margin-bottom: 2rem;
        }
        .form{
          .checkbox{
            margin: 0;
            padding: 0;
          }
        }
      }
    }
    .form{
      .form-section {
        .checkbox {
          &:first-child {
            padding-top: 0;
          }
        }
      }
    }
    .checkoutAsGuest {
      display: none;
      .form-container {
        padding: 0;
      }
    }
  }
}

@media (min-width: 1440px) {
  .business {
    .config-accordion-wrapper {
       .config-accordion--verC {
         padding-bottom: 2.2rem;
       }
    }
    .config-accordion-wrapper.current-accordion-wrapper.order-acco {
      .config-accordion--verC  {
        background-color: @white;
        span.text-label {
          color: @almost-black;
        }
      }
    }
    .config-accordion-wrapper.order-acco.config-accordion-ticked,
    .config-accordion-wrapper.current-accordion-wrapper.order-acco.config-accordion-ticked {
      .config-accordion--verC  {
        background-color: @almost-black;
      }
    }
  }
}

*[lang="ar"] {
  *[dir="rtl"] {
    .config-accordion-wrapper .config-accordion .accordion-title {
      margin-left: 0;
      margin-right: .9375rem;
    }
  }
}
