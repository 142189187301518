//*************************************
//wst util styling
ol, ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

//*************************************

@default-menu-height: 90px;
@compact-menu-height: 56px;

// *************************************
// main menu desktop view
// *************************************
.main-menu-desktop {
  .nav-wrap {
    display: flex;
    align-items: center;
  }
  .navbar-default {
    top: 0;
    position: fixed;
    height: @compact-menu-height;
    width: 100%;
    background-color: @white;
    text-transform: uppercase;
    border: none;
    box-shadow: 0 1px 8px 0 rgba(0,0,0,0.15);
    transition: all .25s ease-in-out;
    z-index: 12;
    @media (min-width: @screen-md) {
      height: @default-menu-height;
      top: 48px;
    }
    .navbar {
      border: 0;
      margin-bottom: 0;
    }
    .logo-container {
      width: 100%;
      display: block;
      @media (min-width: @screen-md) {
        display: flex;
      }
      .navbar-header {
        height: @compact-menu-height;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        float: none;
        transition: all .25s ease-in-out;
        @media (min-width: @screen-md) {
          height: @default-menu-height;
          float: left;
        }
        a {
          width: 86px;
          transition: all .25s ease-in-out;
          margin-bottom: -5px;
          @media (min-width: @screen-md) {
            width: 150px;
            transition: all .25s ease-in-out;
          }
          svg {
            width: 86px;
            @media (min-width: @screen-md) {
              width: 150px;
              transition: all .25s ease-in-out;
            }
          }
        }
      }
    }
    .navbar-links {
      font-family: @headings-font-family;
      .nav.navbar-nav {
        > li {
          > a {
            height: @default-menu-height;
            line-height: @default-menu-height;
            padding: 0;
            padding-right: 3rem;
            transition: all .25s ease-in-out;
            color: @gray-fill;
            cursor: pointer;
            &:hover {
              color: @gray-fill;
            }
          }
          &.active {
            > a {
              color: @brand-primary;
              background-color: transparent;
            }
          }
        }
      }
    }
    .user-loged-in {
      //display: flex;
      font-family: @headings-font-family;
      ul.navbar-right {
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        margin: 0;
        @media (min-width: @screen-md) {
          justify-content: flex-end;
          margin-right: -12px;
        }
        > li {
          > a {
            display: flex;
            justify-content: center;
            align-self: center;
            color: @gray-fill;
            line-height: @default-menu-height;
            transition: all .25s ease-in-out;
            @media (min-width: @screen-md) {
              padding: 0 1.5rem;
            }
          }
          &:last-child {
            > a {
              @media (min-width: @screen-md) {
                padding-right: 12px;
              }
            }
          }
        }
        .search-link {
          a {
            @media (min-width: @screen-md) {
              border-left: 1px solid @sand-white;
            }
            svg {
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }
      }
    }
    &.fixedNav {
      height: @compact-menu-height;
      .navbar-links .nav.navbar-nav li a {
        height: @compact-menu-height;
        line-height: @compact-menu-height;
      }
      .user-loged-in ul.navbar-right>li>a {
        line-height: @compact-menu-height;
      }
      .logo-container .navbar-header {
        height: @compact-menu-height;
        a {
          width: 86px;
          transition: all .25s ease-in-out;
          svg {
            width: 86px;
            transition: all .25s ease-in-out;
          }
        }
      }
    }
  }
  //*****************************
  // for wst structure only
  @media (min-width: 1248px) {
    .container {
      width: 1224px;
    }
  }
  @media (max-width: @screen-sm) {
    .container {
      padding-left: 12px;
      padding-right: 12px;
    }


  }
  //*****************************
}

// *************************************
// main menu dropdown
// *************************************
.sub-account-menu-wrap {
  position: absolute;
  display: none;
  .sub-account-menu {
    background: @white;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
    min-width: 254px;
    border-top: 3px solid @brand-primary;
    padding: 0 1.5rem;
    margin-left: -1.5rem;
    li {
      // padding-bottom: 1rem;
      &:last-child {
        padding-bottom: 0;
      }
      a {
        font-size: .875rem;
        // line-height: 1.3;
        justify-content: left;
        display: block;
        color: @gray-fill;
        height: 48px !important;
        line-height: 48px !important;
        letter-spacing: 0.6px;
        &:hover, &:focus {
          color: @brand-primary;
        }
      }
      &.active {
        a {
          color: @brand-primary;
        }
      }
    }
  }
}

.user-loged-in {
  .sub-account-menu-wrap {
    .sub-account-menu {
      margin-left: 0;
    }
  }
  .logedin-user {
    position: relative;
    margin-right: 1.5rem;
    > a {
      background-color: transparent !important;
      &:after {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        right: -10px;
        border: solid @almost-black;
        border-width: 0 1px 1px 0;
        padding: 5px;
        position: absolute;
        line-height: 1;

        transform: translateY(-80%) rotate(45deg);
        -webkit-transform: translateY(-80%) rotate(45deg);


        //-webkit-transform: translateY(-20%) rotate(-135deg);
        //transform: translateY(-20%) rotate(-135deg);
        top: 50%;

        transition: all .3s ease-in-out;
      }
    }
    &.active {
      > a {
        color: @brand-primary !important;
        &:after {
          border-color: @brand-primary;
          -webkit-transform: translateY(-20%) rotate(-135deg);
          transform: translateY(-20%) rotate(-135deg);
        }
      }
    }
  }
}


// *************************************
// main menu mobile view
// *************************************
.main-menu-mobile {
  display: none;
  top: -100%;
  position: fixed;
  left: 0;
  overflow-y: auto;
  height: 100%;
  z-index: 8;
  transition: all .2s ease-in-out;
  background-color: #000000bd;
  &.mob-visible {
    top: 0;
    padding-top: @compact-menu-height;
    display: block;
    width: 100%;
  }
  &.sticky {
    background: #fff;
    .panel-footer-wraper {
      position: fixed !important;
      width: 100%;
      bottom: 0;
      .panel-title {
        display: flex;
        align-items: center !important;
        justify-content: flex-start !important;;
        flex-direction: row !important;;
        a:first-child {
          padding-right: 20px;
        }
      }
    }
    .visible_menu > .panel-group:first-child {
      margin-bottom: 4rem;
    }
    .secondary-menu {
      .panel-heading {
        .panel-title a {
          font-size: 11px;
          color: @almost-black;
          line-height: 13px;
          letter-spacing: 2px;
          font-family: @font-family-base;
        }
      }
    }
  }
  .panel-group, .menu-account {
    @media (min-width: @screen-sm) {
      width: 768px;
    }
  }
  .badge-top {
    width: 1.31rem;
    height: 1.31rem;
    background-color: #719e19;
    color: #fff;
    border-radius: 50%;
    line-height: 1.31rem;
    position: absolute;
    left: 7px;
    margin-left: .3rem;
    text-align: center;
    padding: 0;
    top: 6px;
    z-index: 99;
    letter-spacing: 0;
    font-weight: 700;
    padding-left: 0 !important;
  }
  .menu-account {
    background-color: @white;
    .menu-account-link {
      display: flex;
      align-items: center;
      font-size: 11px;
      text-transform: uppercase;
      font-family: @font-family-base;
      padding: 1rem 4px;
      color: @almost-black;
      letter-spacing: 2px;
      svg {
        height: 1.25rem;
        width: 1.25rem;
      }
      span {
        padding-left: 1.25rem;
      }

    }
  }

  .panel-group {
    margin-bottom: 0;
    .panel {
      border: 0;
      box-shadow: none;
      background-color: @sand-white-50;
      & +.panel {
        margin-top: 0;
      }
    }
    .panel-heading {
      background-color: @white;
      padding: 0 1rem;
      .panel-title {
        height: 3.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        -ms-flex-pack: center;
        justify-content: center;
        position: relative;
        margin: 0;
        a {
          font-size: .875rem;
          color: @gray-fill;
          text-transform: uppercase;
        }
        .arrow_accordion {
          position: absolute;
          top: 0;
          right: 0;
          height: 3.5rem;
          width: 100%;
          border-right: 0;
          display: flex;
          flex-direction: column;
          -ms-flex-align: start;
          align-items: flex-end;
          justify-content: center;
          background: transparent;
          &:after {
            content: "";
            display: block;
            width: 11px;
            right: 3px;
            border: solid #353738;
            border-width: 0 1px 1px 0;
            padding: 5px;
            position: absolute;
            line-height: 1;
            transform: translateY(-20%) rotate(-135deg);
            -webkit-transform: translateY(-20%) rotate(-135deg);
            top: 50%;
            transition: all .3s ease-in-out;
          }
          //svg {
          //  width: .875rem;
          //  height: .45rem;
          //  transform: rotate(180deg);
          //  transition: all .2s ease-in-out;
          //}
          &.collapsed {
            &:after {
              transform: translateY(-80%) rotate(45deg);
              -webkit-transform: translateY(-80%) rotate(45deg);
            }
            //svg {
            //  transform: rotate(0deg);
            //}
          }

        }
      }
    }
    .panel-collapse {
      .panel-body {
        padding: 0;
        padding-left: 2rem;
        .item_sub-menu {
          display: block;
          text-transform: uppercase;
          font-family: @headings-font-family;
          color: @gray-fill;
          padding: 1.3rem 0;
          font-size: .875rem;
        }
      }
    }
    &.panel-footer-wraper { // for mobile nav responsive top-bar
      //display: flex;
      position: relative;
      background-color: @sand-white-50;
      .panel-body {
        border-top: 0 !important;
      }
      .panel {
        width: 75%;
        .panel-heading {
          background-color: transparent !important;
        }
      }
      .panel-default,
      .panel-collapse {
        a {
          font-size: 11px !important;
          letter-spacing: 2px;
          color: @almost-black !important;
          font-family: @font-family-base;
        }
        .arrow_accordion {
          &:after {
            width: 6px;
            padding: 2.4px;
          }
          &.collapsed {

          }
          //svg {
          //  width: .5rem;
          //  height: .5rem;
          //}
        }
      }
      .panel-languange {
        //width: 25%;
        padding-left: 2rem;
        border-left: 1px solid #F1F1EB;
        font-family: @font-arabic;
        position: absolute;
        top: 0;
        height: 3.5rem;
        right: .875rem;
        a {
          margin-top: 12px;
          display: inline-block;
          font-weight: 300;
          color: @almost-black;
          .lang-change {
            margin-left: .75rem;
            float: right;
            svg{
              width: .5rem;
              height: .3rem;
            }
          }
        }
      }
    }
  }

  .sub_menu_mobile{
    background-color: transparent;
    #accordion_menu {
      .panel-heading{
        .arrow_accordion{
          background: transparent;
          border-left: 0;
          height: 3.5rem;
        }
        .panel-title{
          height: 3.5rem;
          a{
            font-size: .875rem;
          }
        }
      }
    }
  }

}

// *************************************
// hamburger style and animation
// *************************************
.hamburger {
  float: right;
  @media (min-width: @screen-md) {
    float: none;
  }
  .line{
    width: 24px;
    height: 1px;
    background-color: @moonlight-sand;
    display: block;
    margin: 6px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  &:hover{
    cursor: pointer;
  }
}

.hamburger.is-active .line:nth-child(2){
  opacity: 0;
}
.hamburger.is-active .line:nth-child(1){
  -webkit-transform: translateY(7px) rotate(45deg);
  -ms-transform: translateY(7px) rotate(45deg);
  -o-transform: translateY(7px) rotate(45deg);
  transform: translateY(7px) rotate(45deg);
}
.hamburger.is-active .line:nth-child(3){
  -webkit-transform: translateY(-7px) rotate(-45deg);
  -ms-transform: translateY(-7px) rotate(-45deg);
  -o-transform: translateY(-7px) rotate(-45deg);
  transform: translateY(-7px) rotate(-45deg);
}
@keyframes smallbig{
  0%, 100%{
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50%{
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }
}

.freeze {
  overflow: hidden;
}
.main-menu-main-row  {
  &.wst-r-zero {
    @media (max-width: 992px) {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      padding: 0 1rem;
    }
  }
}
.main-menu {
  .navbar {
    .main-menu-main-row {
      &.wst-r-zero {
        .account-link {
          &:after {
              width: 0;
          }
          &.logedin-user {
            margin-right: 1rem;
          }
           &>a {
             @media (min-width: 993px){
               padding-left: 0;
             }
           }
        }
        .search-link {
          a {
             border-left: 0px solid #fff;
             padding: 0 12px;
             @media (max-width: 992px){

               svg {
                 width: 1.5rem !important;
                 height: 1.5rem !important;
               }
             }
             &:after  {
               @media (min-width: 993px){
                 width: 1px;
                 height: 24px;
                 content: " ";
                 position: absolute;
                 left: 0;
                 top: 34px;
                 background-color: #f1f1eb;
               }

              }
          }
        }
      }
    }
  }
}

.main-menu {
  &.main-menu__full {
    .navbar {
      .main-menu-main-row {
        &.wst-r-zero {
          .logo-black {

            svg {
              &.etisalat-logo-img {
                @media (max-width: 992px){
                  margin-top: 0;
                }
              }
            }

          }
        }
      }
    }
  }
  .navbar {
    .main-menu-main-row {
      &.wst-r-zero {
         .logo-black {
           svg {
             &.etisalat-logo-img {
               @media (max-width: 992px){
                 margin-top: 0;
               }
             }
           }
           @media (min-width: 993px){
             padding-left: 1.4rem;
           }
         }
      }
    }
  }
}
.main-menu-mobile {
  &.wst {
    &.sticky {
      &.mob-visible {
        .container {
          &.menu-account {
              @media (max-width: 992px){
                padding: 0 1rem 4rem 1rem;
              }
          }
        }
      }
    }
  }
}
.main-menu .navbar .wst-r-zero .nav>li>a:focus,
.main-menu .navbar .wst-r-zero .nav>li>a:hover,
.main-menu .navbar .wst-r-zero .navbar-nav>.active>a:focus,
.main-menu .navbar .wst-r-zero .navbar-nav>.active>a:hover {
    color: #719e19;
    background-color: transparent;
}
.main-menu .navbar .wst-r-zero .nav>li>a,
.main-menu .navbar .wst-r-zero .navbar-nav>a {
    letter-spacing:0;
    padding:0;
    padding-right: 1.5rem;
    color: #6b6b6b;
}
// .main-menu .navbar .wst-r-zero .logo_center a {
//     padding-left: 1.4rem;
// }
// *************************************
// arabic overides
// *************************************

*[lang="ar"] {
  *[dir="rtl"] {

    .main-menu-mobile {
      width: 100%;
      .panel-group {
        .panel-collapse {
          .panel-body {
            padding-left: unset;
            padding-right: 2rem;
          }
        }
        &.panel-footer-wraper {
          .panel-languange {
            left: .875rem;
            right: unset;
            border-left: 0;
            border-right: 1px solid #e2e1da;
            padding-left: unset;
            padding-right: 2rem;
            .lang-change {
              margin-left: 0;
              margin-right: .75rem;
              float: left;
            }
          }
        }
        .panel-heading .panel-title .arrow_accordion:after {
          right: auto;
          left: 3px;
        }
      }
      .menu-account {
        .menu-account-link span {
          padding-left: unset;
          padding-right: 1.25rem;
        }
      }
      &.sticky  {
        .panel-footer-wraper .panel-title a:first-child {
          padding-right: 0;
          padding-left: 20px;
        }
      }
    }

    .user-loged-in  {
      .logedin-user {
        margin-right: 0;
        margin-left: 1.5rem;
        >a:after {
          left: 0;
          right: auto;
        }
      }
    }
    .sub-account-menu-wrap  {
      .sub-account-menu {
        margin-left: 0;
        margin-right: -1.5rem;
        float: right;
        li{
          width: 100%;
        }
      }
    }
    .user-loged-in .sub-account-menu-wrap .sub-account-menu {
      margin-right: 0;
    }

    .hamburger {
      float: left;
    }
    .main-menu-desktop .navbar-default {
      .user-loged-in ul.navbar-right .search-link a {
        border-left: unset;
        @media (min-width: @screen-md) {
          border-right: 1px solid #f1f1eb;
        }
      }
      .navbar-links .nav.navbar-nav>li>a {
        padding-right: 0;
        padding-left: 3rem;
      }
    }

    .top-nav-section {
      .links svg, #top-nav-cart-img {
        margin-left: 0;
        margin-right: 10px;
      }
    }


    .main-menu-desktop .navbar-default {
      .user-loged-in ul.navbar-right>li:last-child>a {
        @media (min-width: @screen-md) {
          padding-left: 12px;
          padding-right: 1.5rem;
        }
      }
    }

    .sub_menu_mobile{
      #accordion_menu{
        .panel-title{
          .arrow_accordion{
            border-right: 0;
          }
        }
      }
    }
    .main-menu {
      .navbar {
        .main-menu-main-row {
          &.wst-r-zero {
            .search-link {
              a {
                 border-right: 0px solid #fff;
                 &:after  {
                   left: 0;
                   right: 1px;
                  }
              }
            }
            .user-loged-in {
              @media (max-width: 992px) {
                float: right;
              }
            }
            .account-link {
              &>a {
                padding-right: 0;
                padding-left: 2rem;
              }
            }
          }
        }
        .wst-r-zero {
          .logo-black {
            @media (min-width: 993px) {
              padding-left: 0;
              padding-right: 1.4rem;
            }
          }
        }
      }
    }
    .navbar-right {
        float: left !important;
    }
  }
}


//search UI goes here
.search-overlay {
  display: none;
  .search-body {
    height: 100%;
  }
  &.active {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    overflow: auto;
    z-index: 9999;
    display: block;
  }
  .search-close {
    position: absolute;
    top: 1.5rem;
    right: 2.5rem;
    width: 24px;
    height: 24px;
    @media (min-width: @screen-sm) {
      top: 2.5rem;
      right: 2rem;
    }
    @media (min-width: 1440px) {
      top: 3.5rem;
    }
    svg {
      width: 24px;
      height: 24px;
      display: block;
    }
  }
}
