.product-gallery {


  div[class*=col-] {
    .rect-responsive {
      padding-bottom: 125.5%;
    }
  }

  &.product-gallery-low,
  &.product-gallery-lowest{
    div[class*=col-] {
      .rect-responsive {
        padding-bottom: 48%;
      }
    }
  }

  &.product-gallery-A{
    div[class*=col-] {
      .rect-responsive {
        padding-bottom: 66.8%;
      }
    }
  }


  .product-gallery-content{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: @white;
    text-align: center;
    h1, .h1{
      margin-top:0;
      margin-bottom:0;
    }

    &.product-gallery-content--align-bottom{
      align-items: flex-end;
      img{
        margin-bottom: 1.5rem;
      }
    }
  }

  // start version B
  .panel-wrapper{
    height: 67vw;
    display: flex;
    align-items: stretch;
  }

  .panel-slide{
    width: 0;
    .transition();
    position: relative;

    div.cover{
      top: 0;
    }
    img.cover{
      height: 100%;
    }
    .overPanel{
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .transition();
      cursor: pointer;
      svg{
        display: none;
        stroke: @white;
        width: 2.85rem;
        height: 5.625rem;
      }
    }

    &:hover{
      .overPanel {
        opacity: 1;
      }
    }

    &:first-child + .panel-slide,
    &.active-panel + .panel-slide{
      width: 40%;
    }
    &:first-child + .active-panel,
    &.active-panel{
      width: 160%;
      &:hover{
        .overPanel {
          cursor: default;
          opacity: 0;
        }
      }
    }
  }



  .panel-slide:last-child,
  .active-panel + .panel-slide{
    .overPanel{
      svg{
        transform: rotate(180deg);
        -moz-transform: rotate(0deg);
        use {
          -moz-transform-origin: center center;
          -moz-transform: translate(0%, 0) rotate(-180deg);
        }
      }
    }
  }
  // end version B
  &.product-gallery-careers{
    margin-top: 48px;
    margin-bottom: 96px;
    @media (max-width: 767px) {
      margin-top: 0px;
    }
    div[class*=col-] {
      .rect-responsive {
        padding-bottom: 22.6% !important;
      }
    }
    .product-gallery-content{
        height: 22rem;
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
        h1{
          display: block;
          font-size: 54px;
          line-height: 68px;
          margin-bottom: 1.125rem;
        }
        p{
          color: @white;
          display: block;
          font-size: 24px;
          line-height: 1.5625rem;
          font-family: @font-family-base;
          font-weight: normal;
        }
        .btn{
          &.btn-default{
            border-color: @white;
            margin-top: 2rem;
            color: @white;
            min-width: 220px;
            width: 368px;
            &:hover{
              border-color: @main-green;
            }
          }
        }
    
    }
    @media (max-width: 1024px) {
      div[class*=col-] {
        .rect-responsive {
          padding-bottom: 35.6% !important;
        }
      }
    }
    @media (max-width: 768px) {
      div[class*=col-] {
        .rect-responsive {
          padding-bottom: 44.6% !important;
        }
      }
      .product-gallery-content{
        .btn{
          &.btn-default{
            min-width: 220px;
            width: 100%;
            margin-top: 1rem;
          }
        }
    
    }
    }
    @media (max-width: 667px) {
      div[class*=col-] {
        .rect-responsive {
          padding-bottom: 48.6% !important;
        }
      }
      .product-gallery-content{
        height: 17rem;
        h1{
          display: block;
          line-height: 33px;
          font-size: 26px;
          text-align: center;
          font-weight: normal;
          margin-bottom: 1.125rem;
          letter-spacing: 0px;
        }
        p{
          color: @white;
          display: block;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
        }
      }
    }
    @media (max-width: 568px) {
      div[class*=col-] {
        .rect-responsive {
          padding-bottom: 60.6% !important;
        }
      }
    }
    @media (max-width: 475px) {
      div[class*=col-] {
        .rect-responsive {
          padding-bottom: 62.6%!important;
        }
      }
    }
    @media (max-width: 375px) {
      div[class*=col-] {
        .rect-responsive {
          padding-bottom: 70.6%!important;
        }
      }
    }
    @media (max-width: 320px) {
      div[class*=col-] {
        .rect-responsive {
          padding-bottom: 100.6% !important;
        }
      }
    }
  }


}

@media (min-width: @screen-sm-min) {

  .product-gallery {

    &.product-gallery-A div[class*=col-],
    div[class*=col-] {
      .rect-responsive {
        padding-bottom: 44.5%;
      }

      .rect-responsive-large {
        padding-bottom: 59.3%;
      }

      .rect-responsive-small {
        //padding-bottom: 177.8%;
        position: static; //take the height of large
        padding-bottom: 0%;
      }
    }

    &.product-gallery-lowest,
    &.product-gallery-low{
      div[class*=col-] {
        .rect-responsive {
          padding-bottom: 29.6%;
        }
      }
    }


    // start version B
    .panel-wrapper{
      height: 44.5vw;
    }
    .panel-slide{
      &,
      &:first-child + .panel-slide,
      &.active-panel + .panel-slide {
        width: 16.66666667%;
      }
      &:first-child + .active-panel,
      &.active-panel{
        width: 66.66666667%;
      }
    }
    // end version B

  }

}

@media (min-width: @screen-lg-min) {

  .product-gallery {
    // start version B
    .panel-wrapper {
      height: 44.5vw;
    }
    .panel-slide {
      width: 16.66666667%;
      .overPanel {
        background-color: fade(@main-green, 70);
        svg {
          display: block;
          stroke: @white;
        }
      }
      &.active-panel {
        width: 66.66666667%;
      }
    }
    // end version B
  }
}
// new development Tracking Order
.container-fluid2 {
    margin-right: auto;
    margin-left: auto;
    padding-left: 0;
    padding-right: 0;
    margin-top: 140px;
}
.text-and-table {
    > div.table-summary {
        .items {
            > ul {
                li {
                    h3 {
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

.ab-title {
    position: absolute;
    top: 45px;
    width: 100%;
    text-align: center;
    color: #FFF;
}
.search-underline {
    position: relative;
    width: 370px;
    margin: 25px auto 0
}
.cover-bg {
    object-fit: cover;
    width: 100%;
    height: 100%;
    min-height: 214px;
    max-height: 395px;
}
.svg-check {
    width: 21px;
    height: 21px;
    stroke-width: 0px;
    position: absolute;
    left: 6px;
    top: 8px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .relative-wrap .search-underline input {
        padding: 20px 0;
    }

}
.relative-wrap {
    position: relative;
    .hero1 {
        text-align: center;
    }
    .search-underline input {
    width: 100%;
    border: 0;
    outline: none;
    background: transparent;
    color: #FFF;
    border-bottom: 1px solid #FFF;
    font-size: 1rem;
    padding: 5px 0;
    }
    .search-underline ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #FFF;
    }

    .search-underline ::-moz-placeholder {
        /* Firefox 19+ */
        color: #FFF;
    }

    .search-underline :-ms-input-placeholder {
        /* IE 10+ */
        color: #FFF;
    }

    .search-underline :-moz-placeholder {
        /* Firefox 18- */
        color: #FFF;
    }
    .svg-search {
        width: 29px;
        height: 26px;
        stroke-width: 2px;
    }
    .search-icon {
        position: absolute;
        right: 0;
        top: 7px;
    }
}

@media (min-width: 980px) and (max-width: 1279px) {
  .container-fluid2 {
      margin-top: 0px;
  }
}
@media (max-width:@screen-md) {
  .ab-title {
        h1 {
            margin-top: 0;
            padding: 0 40px;
            font-size: 35px;
        }
    }
    .container-fluid2 {
        margin-top: 0px;
    }
}
@media (max-width:@screen-xs) {
  .center-xs-sm {
        text-align: center !important;
    }
    .search-underline {
        position: relative;
        width: 240px !important;
    }
}
