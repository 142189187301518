.reset-password{
    background-color: @sand-white;
    padding-top: 6rem;
    padding-bottom: 6rem;
    text-align: center;

    .reset-alert{
        height: 6rem;
        position: absolute;
        top: -6rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-items: center;
        color:@main-green;
        overflow: hidden;
        p{
            flex: 1;
            font-size: 1.25rem;
            line-height: 1.5rem;
        }
    }
    h3{
        //margin-top: 0;
        //margin-bottom: 1rem;
        text-transform: uppercase;
        &.reset-message{
            margin-top: 5rem;
            margin-bottom: 5rem;
            text-transform: inherit;
            color: @main-green;
            letter-spacing: 0;
        }
    }
    p.system-message{
        margin: 0;
        padding: 0;
        color:@steel-grey;
        font-size: 1.25rem;
        line-height: 1.5rem;
    }

    .form{
        margin-top: 4rem;
        margin-bottom: 0;

        &.form-double >.form-section{
            margin: 0;
            padding: 0;
            &:nth-child(1){
                padding-right: 3.75rem;
                  padding-bottom: 2rem;
            }
            &:nth-child(2){
                padding-left: 3.75rem;
            }
        }

        .form-group{
            &.floating-label-input{
                margin: 0;
                label{
                    color: @main-green;
                }
            }
        }

        .pwd-meter-track{

            width: 100%;
            //border-radius: 4px;
          background-color: transparent;
            overflow: hidden;
            //.transition(0.3s);
            #pwd-meter{
                min-width: 4rem;
                max-width: 100%;
                font-size: 1rem;
                line-height: 2rem;
                text-align: center;
                text-transform: uppercase;
                color: @white;
                //.transition(0.3s);
                &.progress-bar-danger{
                    background-color: @orange;
                    margin-top: 2rem;
                }
                &.progress-bar-success{
                    background-color: @main-green;
                    margin-top: 2rem;
                }
                &.progress-bar-none{
                    background-color: transparent !important;
                    margin-top: 0rem;
                }
            }
        }

        .visualCaptcha{
            margin-top: 3.25rem;
            margin-bottom: 0;
            .visualCaptcha-explanation{
                margin: 0;
                margin-bottom: 1rem;
                color: @almost-black;
                background-color: @light-sand-brown;
            }
        }

        .btn{
            margin: 1rem 0.5rem 0;
        }
        p.registration-problems{
            margin: 0;
            margin-top: 2rem;
            padding: 0;
            a{
                color: @almost-black;
                text-decoration: underline;
            }
        }
    }

}


/*
 * Business Variant
 */
.business{
  .reset-password{
    background-color: @light-gray;
    .form{
        .visualCaptcha{
            .visualCaptcha-explanation{
                color: @white;
                background-color: @premium-gray;
            }
        }
        &#reset-password-form {
            margin-top: 0;
        }
    }
    h3{
      &.reset-message{
        color: @dark-green;
      }
    }


a.resend-token {
    font-size: 16px;
    padding-top: 1rem;
    display: block;
}

  .notification.success-msg {
    font-family: Lato,Roboto,Arial,sans-serif;
    letter-spacing: 0;
    width: 76%;
    margin: auto;
    padding-bottom: 1rem;
    color: #616b1e;
    padding: 0 1rem;
    font-size: 1.25rem;
}

 .form-title {
    padding-bottom: 2rem;
    text-align: center;
    color: #353738;
}


.form-submit {
    height: 11rem;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    margin: 0;
    text-align: center;
}


  }
}

/*
 * @media
 */
@media (min-width: @screen-xs-min){
  .recover-username .form .btn {
      margin: 1rem .5rem 0;
  }
}
@media (min-width: @screen-ms-min){
  .recover-username .form .btn {
      margin: 1rem .5rem 0;
  }
}
@media (max-width: @screen-sm-min){
    .reset-password{
        .form{
            &.form-double >.form-section{
                &:nth-child(1){
                    padding-right: 0;

                }
                &:nth-child(2){
                    padding-left: 0;
                }
            }
        }
    }
    .recover-username .form .btn {
        margin: 1rem .5rem 0;
    }
}
@media (min-width: @screen-md-min){
  .recover-username .form .btn {
      margin: 4rem .5rem 0;
  }
}
@media (min-width: @screen-lg-min){
  .recover-username .form .btn {
      margin: 4rem .5rem 0;
  }
}
