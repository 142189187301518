// *************************************
// Features with tabs
// *************************************
.features-section {
  min-height: 525px;
  max-height: 525px;
  position: relative;
  background: @sand-white-50;
  @media (max-width: @screen-md) {
    min-height: 100%;
    >.container >.row >.col-xs-12 {
      padding-left: 26px;
      padding-right: 26px;
      padding-bottom: 1rem;
      .btn{
        &.btn-default{
          width: 100%;
          font-size: 14px;
          color:@almost-black;
          height: 60px;
          line-height: 60px;
        }
      }
    }

  }
  @media (min-width: @screen-md) {
    //max-height: 420px;
    //min-height: 420px;
    margin-top: 20px;
  }
  // &:before, &:after {
  //   @media (min-width: @screen-md) {
  //     content: "";
  //     background: url(../img/features/background-pattern.svg) no-repeat;
  //     background-size: contain;
  //     height: 420px;
  //     position: absolute;
  //     width: 420px;
  //     opacity: .15;
  //     top: 0;
  //     z-index: 0;
  //   }
  // }
  // &:before {
  //   left: 0;
  // }
  // &:after {
  //   right: 0;
  //   transform: rotate(180deg);
  // }
  .features-icons-links {
    margin-bottom: 0;
    @media (min-width: @screen-md) {
      margin-bottom: 2rem;

    }
  }
  .tab-content{
    .features-icons-links {
      margin-bottom: 0;
      img{
        height: 40px;
      }
      @media (min-width: @screen-md) {
        margin-bottom: 2rem;
        margin-top: 4rem;
        img {
          height: 40px;
        }
      }
    }
  }
  .feature-detail-wrap {
    position: relative;
    padding-top: 4rem;
    @media (max-width: @screen-md) {
      padding-top: 5rem;
    }
    @media (min-width: @screen-md) {
      padding-top: 106px;
    }
    h3 {
      margin: 0;
      font-size: 26px;
      line-height: 33px;
      margin-top: 17px;
      margin-bottom: 1.5rem;
      color:@black;
      @media (min-width: @screen-md) {
        font-size: 40px;
        line-height: 50px;
        margin-top: 3.2rem;
        margin-bottom: 1.5rem;
      }
    }
    .heading-mini {
      margin-top: 1rem;
      display: none;
      @media (min-width: @screen-lg) {
        display: block;
        text-transform: capitalize;
        letter-spacing: .5px;
        font-weight: normal;
      }
    }
  }
  .feature-img-wrap {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-top: 0;
    @media (max-width: @screen-md) {
      height: 300px;
      margin-top: 20px;
    }
    img {
      height: 100%;
    }
  }

  .nav.nav-pills {
    position: absolute;
    z-index: 99;
    top: 1.5rem;
    @media (min-width: @screen-md) {
      top: 80px;
    }
    li {
      a {
        font-size: 16px;
        font-weight: normal;
        color: @almost-black;
        padding: 0 2.1rem 1rem;
        text-transform: uppercase;
        font-family: @headings-font-family;
        letter-spacing: 2px;
        @media (max-width: @screen-md) {
          padding: 1rem;
        }
        &:hover {
          background: transparent;
          color: @brand-primary;
        }
      }
      &.active {
        a {
          //color: @brand-primary;
          background: transparent;
          border-bottom: 4px solid @brand-primary;
        }
      }
    }
  }
  .tab-pane {
    >div {
      > div{
        z-index: 1;
      }
    }
  }
  &.bg-dark {
    background: linear-gradient(45deg,#1d1d1d,#292929);
    color: @white;
    .feature-detail-wrap {
      p, h3 {
        color: @white;
      }
    }
    .nav {
      li a {
        color: @white;
      }
    }
  }
}

.freedom{
  .features-section{
     .nav{
       &.nav-pills{
         z-index: 8;
         @media (max-width: @screen-ms) {
           width: 100%;
           li{
             width: 49%;
             text-align: center;
             a{
               padding: .5rem
             }
           }
         }
       }
     }
  }
}


.feature-outside-wrapper {
  overflow: hidden;
  height: 600px;
  max-height: 600px;
  @media (min-width: @screen-md) {
    // max-height: 460px;
    // height: 460px;
  }
}


.features-icons-links {
  display: flex;
  align-items: center;
  img, a {
    //max-width: 132px;
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

.heading-mini {
  font-size: 1rem;
  color: @breadcrumb-color;
  font-family: @font-family-base;
  text-transform: uppercase;
  line-height: 19px;
  letter-spacing: 2px;
  font-weight: bold;
}



// *************************************
// Features full-bleed
// *************************************
.features-full-bleed-section {
  &.bg-dark {
    color: @white;
    ul li {
      color: @white;
    }
    .action-items .detail-price-new {
      color: @white;
    }
    div.cover {
      .bg-filter {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        top: 0;
        left: 0;
        background-color: transparent;
        opacity: 0;
        display: block !important;
      }
    }
  }
  .media-cta-container {
    padding: 0;
    .media-cta-photo {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .media-cta-wrapper {
    z-index: 999;
    &.media-cta-bleed {
      .media-cta-photo {
        position: static;
        height: 560px;
      }
      >div {
        min-height: 560px;
      }
    }
    .media-cta-text {
      position: absolute;
      padding: 0;
    }
    @media (min-width: @screen-md) {
      .media-cta-caption,
      .media-cta-wrapper>div {
        max-height: 560px;
      }
    }
    @media (min-width: @screen-sm) {
      >div {
        min-height: 560px;
      }
    }
  }
  .media-cta-img {
    position: absolute;
    width: 100%;
  }

  .action-items {
    display: block;
    align-items: center;
    @media (min-width: @screen-sm) {
      display: flex;
    }
    .detail-price-info, .btn {
      flex: 1;
    }
    .detail-price-info {
      padding-left: 0;
    }
    .detail-price-new {
      span {
        font-family: @headings-font-family;
      }
      .price-timing,
      .price-valute {
        font-family: @font-family-base;
        font-size: 14px;
      }
      .bottom-part {
        font-size: 14px;
        margin-top: .5rem;
        line-height: 17px;
        font-family: @font-family-base;
      }
    }
  }
  .body-standard {
    padding: 32px 16px;
    overflow: hidden;
    max-height: 560px;
    @media (min-width: @screen-sm) {
      padding: 92px 12px;
    }
    .recommended {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 4px;
      margin-bottom: 10px;
      margin-top: 0;
      font-family: @font-family-base;
    }
    .title {
      font-size: 32px;
      margin-bottom: 1.125rem;
      @media (min-width: @screen-sm) {
        margin-bottom: 2rem;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1.5rem;
      @media (min-width: @screen-md) {
        margin-bottom: 80px;
      }
      li {
        flex: 1 0 100%;
        font-size: 1rem;
        line-height: 1.2;
        @media (min-width: @screen-sm) {
          font-size: 1.25rem;
          flex: 1 0 50%;
        }
        span {
          font-weight: bold;
        }
      }
    }
    p {
      padding-bottom: 1.125rem;
      font-size: 1rem;
      @media (min-width: @screen-sm) {
        font-size: 1.125rem;
        padding-bottom: 70px;
      }
    }
  }
}


// *************************************
// Features single tile
// *************************************
.features-single-tile {
  max-height: auto;
  min-height: 560px;
  @media (min-width: @screen-md) {
    max-height: 450px;
    min-height: 450px;
  }
  .features-section {
    min-height: 430px;
    max-height: 430px;
    @media (max-width: @screen-md) {
      min-height: 500px;
      max-height: 100%;
      >div {
        >div {
          display: flex;
          flex-direction: column;
          .tile-details {
            order: 1;
          }
          .img-details {
            order: 2;
          }
        }
      }
    }
    &:before,
    &:after {
      background: none;
    }
    .feature-detail-wrap {
      padding-top: 0;
      @media (max-width: @screen-md) {
        margin-top: 1rem;
      }
      h3 {
        margin-top: 0;
        margin-bottom: 2rem;
        color: @almost-black;
      }
      p {
        font-size: 1rem;
        color: @gray;
        line-height: 26px;
        font-family: @font-family-base;
        margin-bottom: 1.5rem;
        @media (min-width: @screen-md) {
          margin-bottom: 0;
        }
      }
    }
    .tile-details {
      display: flex;
      align-items: center;
      height: auto;
      @media (min-width: @screen-md) {
        height: 440px;
        overflow: hidden;
      }
    }
    .feature-img-wrap {
      margin-top: 0;
      height: 340px;
      bottom: 0;
      margin-bottom: 1.5rem;
      @media (min-width: @screen-md) {
        margin-top: 2rem;
        height: 355px;
        margin-bottom: 0;
      }
    }
    .linkable-text {
      margin-bottom: 1.5rem;
      &:after {
        content: "";
        position: absolute;
        line-height: 1;
        -webkit-transform: translateY(-20%) rotate(-135deg);
        top: 10px;
        transition: all .3s ease-in-out;
        transform: translateY(-50%) rotate(-45deg);
        border: solid #719e19;
        border-width: 0 1px 1px 0;
        padding: 3px;
        margin-left: .75rem;
      }
    }
    .features-icons-links {
      @media (max-width: @screen-md) {
        margin-bottom: 1.5rem;
      }
      @media (max-width: 768px) {
        margin-bottom: 1.5rem;
      }
    }
  }
  &.bg-white {
    color: @white;
    .feature-detail-wrap p {
      color: @white;
    }
  }
}

.linkable-text {
  color: @brand-primary;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  font-size: 12px;
  position: relative;
  display: none;
  &:hover {
    text-decoration: underline;
    color: @brand-primary;
  }
  @media (min-width: @screen-md) {
    display: block;
  }
}

// *************************************
// arabic overides
// *************************************
*[lang="ar"] {
  *[dir="rtl"] {
    .features-section{
       .nav{
         &.nav-pills{
           padding-right: 0;
         }
       }
    }
    .features-icons-links a,
    .features-icons-links img {
      margin-left: 1rem;
      margin-right: unset;
    }

    .features-full-bleed-section {
      p, .price-timing, .btn, ul li {
        font-family: @font-arabic;
      }
    }
    .detail-price-info {
      padding-right: 0 !important;
    }
  }
}
