.context-navigation-bugfixing{
  &.context-navigation{
    //margin-bottom: 100px; please use spacer classes
    @media (max-width: @screen-sm-min) {
      margin: 0 0 20px 0;
    }
    &.more-value {
      //margin: 100px 0px; please use spacer classes
      @media (max-width: @screen-sm-min) {
        margin: 0 10px 20px 10px;
        &>.pt-100 {
          padding-top: 40px;
        }
      }
    }
    h3{
      font-size: 32px;
      color: @almost-black;
      font-weight: 400;
      letter-spacing: 0px;
      @media (max-width: @screen-sm-min) {
        font-size: 26px;
        line-height: 33px;
      }
    }
    p{
      color: @steel-grey;
      font-size: 18px;
    }
    .swiper-container {

      &.destroyed {
        @media (max-width: @screen-sm-min) {
          .table-swiper-button-prev,
          .table-swiper-button-next {
            display:none;
          }
          .swiper-wrapper {
            display: block;
            .swiper-slide {
              margin-bottom: 40px;
            }
          }
        }
      }
    }
    &.no-icon {
      padding: 60px 0 80px 0;
      &> h3 {
        margin: 0 0 90px 0;
      }
    }
    &.faq-billing-explainer{
      @media (max-width: 767px) {
        .swiper-wrapper{
          display: block;
          .bring-value-card-wrapper{
            p{
              margin-bottom: 1.5rem;
            }
          }
        }
      }
      .bring-value-card-wrapper{
        p{
          color: @main-green;
        }
      }

    }
    &.no-slide{
      .swiper-container{
        .swiper-slide{
          width: auto !important;
          .bring-value-card{
            max-width: 22.375rem;
            margin: 0px 1rem;
          }
        }
      }

    }
    &.need-help {
      h3 {
        font-size: 34px;
        line-height: 42px;
        margin-bottom: 14px;
        //letter-spacing: 1px;
        @media (max-width: 767px) {
          font-size: 26px;
        }
      }
      p {
        font-size: 16px;
        line-height: 26px;
      }
      .btn {
        &.btn-default {
              max-width: 213px;
              @media (max-width: 992px){
                max-width: 100%;
                border-radius: 4px;
                height: 48px;
                line-height: 48px;
                font-size: 14px;
                letter-spacing: 2.4px;
                font-weight: bold;
                border-width: 1px;
                border-color: #151515;
              }
        }
      }
      ul {
        &.help-links {
          list-style: none;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          padding-top: 4rem;
          @media (max-width: 992px){
            padding-left: 0;
            padding-right: 0;
            width: 100%;
            padding-top: 2.4rem;
          }
          li {
            display: inline-block;
            text-align: center;
            -ms-flex: 1;
            flex: 1;
            display: block;
           @media (max-width: 992px){
              flex: 1 1 auto;
              width: 50%;
              margin-bottom: 1.5rem;
            }
            img {
              width: 60px;
              margin-bottom: 22px;
              @media (max-width: 992px){
                width: 50px;
                margin-bottom: 15px;
               }
            }
            p {
              line-height: 22px;
              font-weight: bold;
              letter-spacing: 2px;
              @media (max-width: 992px){
                font-size: 14px;
                letter-spacing: 1.5px;
              }
            }
          }
        }
      }

    }
  }
  .context-navigation-cards{
    padding-top: 80px;
    padding-bottom: 80px;
    &.context-navigation-swiper2{
      .justify-center{
        justify-content: center;

      }
    }
    .row-tiles-table{
      position: relative;
      .table-swiper-button-next{
        //right: 3rem!important;
        @media (max-width: 768px) {
          right: 1rem!important;
        }
      }
      .table-swiper-button-prev{
        //left: 3rem!important;
        @media (max-width: 768px) {
          left: 1rem!important;
        }
      }
      .table-swiper-button-next,
      .table-swiper-button-prev{
        bottom: 30%;
        width: 1.3rem;
        height: 1.3rem;
        svg{
          stroke: @dark-gray;
        }
      }
    }
  }
  .bring-value-card-wrapper{
    justify-content: center;
    .bring-value-card{
      max-width: 22.375rem;
      .bring-value-card-icon{
        width: 4.4375rem;
        height: 4.4375rem;
        margin: auto;
        margin-bottom: 1.55rem;
        display: block;
        svg{
          width: 100%;
          height: 100%;
        }
      }
        h4{
          font-size: 24px;
          margin-bottom: .65rem;
          margin-top: 0px;
          letter-spacing: 0px;
        }
        p{
          color: @steel-grey;
          font-size: 14px;
          line-height: 1.5;
          margin-bottom: 1rem;
        }
        a{
          font-size: 14px;
        }
    }
  }
  .no-icon.left-align {
    .bring-value-card-wrapper {
      justify-content: left;
      text-align: left;
      padding: 0;
    }
  }

  //mobile
  @media (max-width: @screen-sm-min) {
    .bring-value-card-wrapper{
      justify-content: center;
      .bring-value-card{
        max-width: 100%;
      }
    }
  }
  //mobile
  @media (max-width: 1024px) {
    .context-navigation-cards{
      &.context-navigation-swiper2{
        .justify-center{
          justify-content: start;
          .swiper-slide{
           width: auto;
          }
        }
      }

    }
  }
  .row-tiles-table {
    .table-swiper-button-next,
    .table-swiper-button-prev {
      display: block;
      width: 1.125rem;
      height: 1.75rem;
      position: absolute;
      bottom: 50%;
      cursor: pointer;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      z-index: 2;
      transform: translateY(-50%);
      svg {
        width: 100%;
        height: 100%;
        stroke: #B2B2B2;
        stroke-width: 8px;
      }
    }

    .table-swiper-button-next {
      right: -3rem !important;
    }

    .table-swiper-button-prev {
      left: -3rem !important;
    }

    .swiper-button-disabled {
      display: none;
    }
    .swiper-scrollbar{
      background-color: @sand-white;
      left: 2%;
      bottom: 3px;
      width: 96%;
      .swiper-scrollbar-drag{
        background-color: @main-green
      }
   }
  }
}
// arabic language
*[lang="ar"] {
  *[dir="rtl"] {
    .context-navigation-bugfixing {
      &.context-navigation {
        &.need-help {
          .btn {
            &.btn-default {
                margin-right:0;
                float:right;
            }
          }
        }
      }
    }
  }
}
