.compare-service-container{
  background-color: @sand-white;
}
.compare-service{
    display: flex;
    align-items: stretch;
    text-align: center;
    line-height: 3rem;
    background-color: @white;
    >div{
        padding: 1.25rem 1.75rem;
        &:first-child{
            text-align: left;
        }
        &:nth-child(2){
            box-shadow: inset 10px 0px 10px -10px rgba(0, 0, 0, 0.25);
        }
    }
    &.compare-service-header{
        background-color: @main-green;
        >div{
            display: flex;
            align-items: center;
            .compare-service-title{
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: uppercase;
                color: @white;
                letter-spacing: 0.9px;
                line-height: 1.4;
            }
        }
    }
    ul{
        display: block;
        margin: 0;
        padding: 0;
        li{
            display: block;
            margin: 0;
            padding: 0;
            list-style: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .compare-yes{
                display: block;
                width: 0.8rem;
                height: 3rem;
                margin: 0 auto;
                svg{
                    width: 100%;
                    height: 100%;
                    fill: @main-green;
                }
            }
            .compare-no{
                display: block;
                width: 0.4rem;
                height: 0.4rem;
                margin: 1.3rem auto;
                border-radius: 50%;
                background-color: @lighty-gray;
            }
        }
    }
}
.compare-service-subscribe{
    text-align: center;
    background-color: @white;
    margin-bottom: 2rem;
    button{
        margin: 3rem 0;
        width: 100%;
    }
}


/*
 * Business Variant
 */
.business{
  .compare-service{
    &.compare-service-header{
        background-color: @dark-green;
    }
    ul{
        li{
            .compare-yes{
                svg{
                    fill: @dark-green;
                }
            }
        }
    }
  }
}

/*
 * @media
 */
@media (min-width: @screen-sm-min) {
    .compare-service{
        >div{
            &:nth-child(2){
                box-shadow: none;
            }
        }
        ul{
            li{
                .compare-yes{
                    width: 1rem;
                }
            }
        }
    }
    .compare-service-subscribe{
        button{
            width: auto;
        }
    }
}
