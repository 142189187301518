/*
============================================
01 - chat-box
02 - sm viewport
03 - md viewport
04 - lg viewport
05 - xl viewport
06 - Business Variant
07 - Arabic Variant -  RTL support

============================================

/* -----------------------------------------
01 - chat-box
----------------------------------------- */
.chat-box{
  position: fixed;
  right: 0px;
  bottom: 10px;
  padding: 0.5rem;
  text-align: center;
  z-index: 99;
  display: none;
  width: 400px;
  a{
    color: @white;
    font-size: 1rem;
    text-transform: uppercase;
  }
  .live-chat-box{
    position: absolute;
    right: 0px;
    top: 40%;
    background-color: @white;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
    display: none;
    z-index: 99;
    .chat-box-head{
      background-color: @main-green;
      padding: 1rem;
      text-align: center;
      h4{
        color: @white;
        font-size: 1rem;
        text-transform: uppercase;
        margin: 0px;
      }
      a{
        color: @white;
        &.close-chat{
          float: right;
          width: 16px;
          height: 16px;
          svg{
            fill: @white;
            width: 100%;
          }
        }
        &.hide-chat{
          position: absolute;
          left: 15px;
          width: 25px;
          height: 16px;
          svg{
            fill: @white;
            width: 100%;
          }
        }
      }
    }
    .live-chat-body{
      overflow-y: auto;
      height: 518px;
    }

    .video-chat-loading {
        position: absolute;
        left: 0;
        right: 0;
        top: 52px;
        bottom: 0;
        background: #F7F8F4;
        z-index: 10;
        .video-chat-loading-contaner {
            position: absolute;
            left: 0;
            right: 0;
            top: 35%;
            .video-chat-loading-gif {
              overflow: hidden;
              margin-bottom: 20px;
          }
          .video-chat-loading-text {
              color: #848789;
          }
        }
    }
  }
  &.live-chat-box-open{
    height: 570px;
    top: 20%;
    display: block;
    .live-chat-box{
     display: block;
     top: 0;
     height: 100%;
     width: 100%;
     .chat-form-heading{
      margin-top: 1rem;
      margin-bottom: 1rem;
      h3{
        font-size: 20px;
        letter-spacing: 0px;
        margin-bottom: 5px;
      }
      p{
        line-height: normal;
        letter-spacing: 0px;
        color:#848789;
      }
    }
  }
  &.close-live-chat-box{
   display: none;
 }
 &.hide-chat-box-open{
  height: 52px;
  width: 180px;
  .live-chat-box{
    width: 180px;
    .chat-box-head{
      h4{
        padding-left: 1.6rem;
      }
    }
  }
}
}
}

.video-chat-box {

  .form-container {
    padding: 0px !important;
  }

  .form-section {
    padding-left: 0px !important;
    padding-right: 0px !important;
    .form-group {
      border-left:0px !important;
      border-right:0px !important;
      border-top:0px !important;
      border-bottom:1px solid #bcbcbc;
      margin-bottom: 26px;

      label.error {
          /* bottom: -47px !important; */
          font-size: .75rem !important;
          color: #fb193f !important;
          top: inherit !important;
          bottom: -20px !important;
          margin: 0 !important;
          /* position: relative; */
          float: right;
          padding: 0px !important;
          line-height: inherit !important;
      }
      .select2 {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 24px;
        padding-bottom: 12px;
      }
      .select2-selection--single:after {
        height: 0px !important;
        background-color: #848789 !important;
      }
      .select2-dropdown {
        margin: 0px !important;
      }
      label {
        padding: 0;
        color:#848789 !important;
        letter-spacing: 0;
      }


      input,select,textarea {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
      .alert-label {
        bottom: -1.5rem !important;
        top:inherit !important;
        text-align:left !important;
      }
      .select2-selection__arrow b {
        width: 16px;
        height: 16px;
        background: url(../../assets/img/video-chat/Icon_arrow_down.svg) no-repeat;
        border-color: transparent !important;
        border-width: 0 !important;
        background-size: 16px;
        margin-top: 5px;

      }
    }

  }

}



@media screen and (max-width: 480px){
  .chat-box {
    .live-chat-box {
      .live-chat-body {
        height: 100vh;
      }
    }
  }

  .chat-box.live-chat-box-open{
    right: 0;
    width: 100%;
    height: auto;
    top: 0;
    bottom: 0;
    background: #fff;
  }
}

//arabic version of forms
*[lang="ar"] {
  *[dir="rtl"] {
    .chat-box.live-chat-box-open{
      right: auto;
      left: 0px;
      width: 400px;
      .alert-label {
        text-align: right;
      }
      .select2-selection__arrow {
        left: 2.6625rem;
        right: auto;
      }
      label {
        padding: 0;
        color:#848789 !important;
        letter-spacing: 0;
      }
      .select2-selection__rendered {
        font-size: 1rem !important;
      }
      a.close-chat {
        float: left;
      }
      @media screen and (max-width: 480px){
        right: auto;
        left: 0px;
        width: 100%;
      }
    }
  }
  .chat-box.live-chat-box-open.hide-chat-box-open .live-chat-box{
    right: auto;
    left: 10px;
    @media screen and (max-width: 480px){
      right: auto;
      left: 0px;
    }
  }

}
