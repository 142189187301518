.business{
  .svpn-nav-menu-wrap {
      background: #dddddd;
      .dropdown {
        .dropdown-menu{
        a {
           color: #666;
         }
         a:before {
            left: 14px !important;
          }
         a:hover {
            color: #333;
                border-bottom-color: #666;
          }
        .active{
            a {
               color: #333;
               }
           }
        }
      }

      @media (max-width: 768px){
        .active{
            a {
               color: #fff;
           }
        }
      }
  }
}

#serviceSection{
  .addService {
      float: left;
      width: 8em;
      height: 8em;
      border: dashed 2px #666;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 30px 30px;
      svg {
          fill: black;
          stroke: black;
          width: 100%;
          height: 33px;
          width: 35px;
      }
  }
  .well{
    padding: 4em 5em;
    position: relative;
    overflow: hidden;
    .popover{
      max-width: 330px;
      z-index: 6;
    }

  }
  .blur-bg {
      background: rgba(230,230,230,.8);
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      left: 0;
      filter: blur(20px);
      &.in{
        z-index: 5;
      }
  }
}

/////////
.size(@w, @h) {
  height: @h;
  width: @w;
}
// --



//HO Dashboard topology map - waqas
//map zoom controller
.expanded-colapse-expand {
  display: flex;
  .expanded-window {
    width: 30%;
    background: @white;
    transition: width .5s ease-out;
    position: relative;
    -webkit-box-shadow: -2px 0px 3px 0px rgba(50, 50, 46, 0.25);
    -moz-box-shadow: -2px 0px 3px 0px rgba(50, 50, 46, 0.25);
    box-shadow: -2px 0px 3px 0px rgba(50, 50, 46, 0.25);
    a {
      position: absolute;
      left: -30px;
      top: 50px;
      width: 30px;
      padding: 0;
      height: 85px;
      line-height: 100px;
      border: 0;
      box-shadow: -2px 0 3px 0 rgba(50,50,46,.25);
      z-index: 0;
      &:hover {
        background-color: @brand-primary;
        color: @white;
      }
      &:hover svg{
        fill: @white;
      }
      svg {
        width: 18px;
        height: 18px;
        transform: rotate(90deg);
        fill: @dark-gray;
      }
    }
  }
  .expanded-content {
    width: 70%;
    transition: width .5s ease-out;
  }
}
#map_custom .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
  .gmnoprint {
    left: -25px !important;
    top: -200px !important;
  }
}
.map-popup-container {
  width: 300px;
  box-sizing: border-box;

  .half-container {
    display: flex;
    border-bottom: 1px solid @gray-dark;
    .half-width-col {
      flex: 1;
      .second-half {
        color: @brand-primary;
        font-size: 120%;
        font-weight: bold;
      }
      +.half-width-col {
        margin-left: 10px;
      }
    }
  }
  .full-width-col {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    >div {
      width: 45%;
    }
    .popup-label {
      text-transform: uppercase;
      font-weight: 600;
    }
    .popup-value a{
      color: @brand-primary;
    }
    .popup-link {
      margin-top: 15px;
      text-align: center;
      width: 100%;
        a {
       text-transform: uppercase;
     }
   }
  }
}

.map-wraper {
  position: relative;
  display: block;
  width: 100%;
}
.legends-container {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: hsla(0,0%,100%,.01);
  background: -webkit-gradient(left top,left bottom,color-stop(0,hsla(0,0%,100%,.01)),color-stop(100%,#9a9a9a));
  background: -webkit-linear-gradient(top,hsla(0,0%,100%,.01),#9a9a9a);
  background: -webkit-gradient(linear,left top,left bottom,from(hsla(0,0%,100%,.01)),to(#9a9a9a));
  background: linear-gradient(180deg,hsla(0,0%,100%,.01) 0,#9a9a9a);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#9a9a9a",GradientType=0);
}

//dashboard top header HO-Details
.ho-container {
  box-shadow: 0 0 5px gray;
  background: #ffffff;
  justify-content: space-around;
  padding: 30px;
  box-sizing: border-box;
  text-align: -webkit-auto;
  text-align: inherit;
  margin-bottom: 30px;

  .ho-wraper {
    border-bottom: 1px solid @gray;
    .ho_ {
    margin: 10px 0;
    }
    .ho-left {
      text-align: left;
    }
  }

  .ho-header {
    margin-top: 10px;
    .ho-content {
      .ho-label {
        text-transform: uppercase;
      }
    }
  }
}


.map-header {
  width: 100%;
  background: #ffffff;
  padding: 30px;
  box-sizing: border-box;
  text-align: center;
  .header-container {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding-bottom: 10px;
      label {
        text-transform: uppercase;
        font-size: 13px;
      }
      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
  }

}
.map-buttons-container {
  margin: auto;
  .map-legends-buttons {
    flex-wrap: wrap;
    display: flex;
    .dshbd {
      flex: 1;
      margin: 5px 0;
    }
    .dsh-btn-link {
      .btn-group {
        display: flex;
          a {
          padding: 5px 15px;
          svg {
            width: 20px;
            height: 20px;
          }
          &:first-child {
            background-color: @white;
            border: 0;
          }
        }
      }
    }
  }
}

@media (min-width: @screen-sm-min) {
  .map-header {
    &.map-header-view {
      left: 2%;
      top: 3%;
      width: 65%;
      position: absolute;
    }
    box-shadow: 0 0 5px gray;
    background: #ffffff;
    z-index: 1;
    display: flex;
    justify-content: space-around;
    padding: 30px;
    box-sizing: border-box;
    text-align: -webkit-auto;
    text-align: inherit;

    .header-container {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
      width: 18%;
        label {
          text-transform: uppercase;
        }
    }
  }

  //map zoom controller
  #map_custom .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    .gmnoprint {
      left: -50px !important;
      top: -180px !important;
    }
  }
  .map-buttons-container {
    .map-legends-buttons {
      .dshbd {
        margin: 0;
      }
      .dsh-btn-link {
        .btn-group {
            a {
            svg {
            }
            &:first-child {
            }
          }
        }
      }
    }
  }

  .ho-container {
    box-shadow: 0 0 5px gray;
    background: #ffffff;
    justify-content: space-around;
    padding: 30px;
    box-sizing: border-box;
    text-align: -webkit-auto;
    text-align: inherit;

    .ho-wraper {
      display: flex;
      border-bottom: 1px solid @gray;
      .ho_ {
        flex: 1;
      }
      .ho-left {
        text-align: left;
      }
      .ho-right {
        text-align: right;
      }
    }

    .ho-header {
      display: flex;
      .ho-content {
        width: 32%;
        display: flex;
        .ho-label, .ho-value {
          flex: 50;
        }
        .ho-value {
          color: @gray;
        }
      }
    }
  }
}

.charts-legends {
    ul{
      list-style: none;
      padding-left: 20px;
      margin-bottom: 0px;
      li{padding-bottom: 10px;}
      li:before {
          content: ' ';
          width: 20px;
          height: 20px;
          padding: 10px;
          background-color: white;
          border: solid 2px #fff;
          border-radius: 50%;
          display: inline-block;
          margin-right: 15px;
          vertical-align: bottom;
        }
      li.normal:before{
          border-color: #719e19;
      }
      li.warning:before{
          border-color: #FFCC00;
      }
      li.none:before{
          border-color: #bdc3c7;
      }
      li.error:before{
          border-color: #D0011B;
          background-color: #d0011b;
      }
      li.big:before{
        padding: 13px;
        margin-right: 9px;
      }
    }
}
.charts-legends {
    &.legends-filler {
      font-size: 12px;
      color: #ffffff;
      display: flex;
      flex-wrap: wrap;
      padding: 20px 10px;
      text-shadow: 0 0 3px #000;
      ul{
        list-style: none;
        padding-left: 20px;
        margin-bottom: 0px;
        li{padding-bottom: 10px;}
        li:before {
            content: ' ';
            width: 15px;
            height: 16px;
            padding: 5px;
            background-color: white;
            border: 0;
            border-radius: 50%;
            display: inline-block;
            margin-right: 15px;
            vertical-align: bottom;
            box-shadow: 0 0 2px grey;
          }
        li.normal:before{
            background-color: #719e19;
        }
        li.warning:before{
            background-color: #FFCC00;
        }
        li.none:before{
            background-color: #bdc3c7;
        }
        li.error:before{
            border-color: #D0011B;
            background-color: #d0011b;
        }
        li.big:before{
          padding: 13px;
          margin-right: 9px;
        }
      }
    }
}

.set-size {
  font-size: 10em;
}
.charts-container:after {
  clear: both;
  content: "";
  display: table;
}

@bg: #34495e;
@size: 1em;
@size-small: .8em;
@size-large: 2em;

.pie-wrapper {
  .size(@size, @size);
  float: left;
  margin: 1.5rem;
  position: relative;
  background-color: #f5f5f5;
  border-radius: 100px;
&.progress-small{
  .size(@size-small, @size-small);
    margin: 2.5rem 2.2rem;
  span.label {
      line-height: 2.5em;
  }
}
&[aria-describedby] {
    z-index: 5;
}

  // &:nth-child(3n+1) {
  //   clear: both;
  // }

  .pie {
    .size(100%, 100%);
    clip: rect(0, @size, @size, @size / 2);
    left: 0;
    position: absolute;
    top: 0;
    cursor: pointer;
    .half-circle {
      .size(100%, 100%);
      border: @size / 20 solid #3498db;
      border-radius: 50%;
      clip: rect(0, @size / 2, @size, 0);
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .label {
    @font-size: @size / 4.5;
    @font-size-redo: @size * 5;

    background: @bg;
    border-radius: 50%;
    bottom: @font-size-redo / 10;
    color: #ecf0f1;
    cursor: default;
    display: block;
    font-size: @font-size;
    left: @font-size-redo / 10;
    line-height: @font-size-redo * .65;
    position: absolute;
    right: @font-size-redo / 10;
    text-align: center;
    top: @font-size-redo / 10;

    .smaller {
      color: #bdc3c7;
      font-size: .45em;
      padding-bottom: 20px;
      vertical-align: super;
    }
  }

  .shadow {
    .size(100%, 100%);
    border: @size / 20 solid #bdc3c7;
    border-radius: 50%;
  }


  &.style-2 {
    .label {
      background: none;
      color: #7f8c8d;

      .smaller {
        color: #bdc3c7;
      }
    }
  }
  /*for disply redial progress inside grid control*/
  &.progress-grid{
    .size(@size-large, @size-large);
    transform: rotate(20deg);
    margin:0;
    background-color: #fff;
    .shadow {
      border: @size-large / 10 solid #dcdcdc;

    }
    .half-circle {
      border: @size-large / 10 solid #3498db;

      clip: rect(0, @size-large / 1.5, @size-large, 0);

    }
  }
  &.progress-error {
    .draw-progress(0, #e74c3c);
    .shadow {
        border-color:red;
        background-color: red;
    }
    .label {color:white}

  }

  &.progress-0 {
    .draw-progress(0, #719e19);
  }
  &.progress-1 {
    .draw-progress(1, #719e19);
  }
  &.progress-2 {
    .draw-progress(2, #719e19);
  }
  &.progress-3 {
    .draw-progress(3, #719e19);
  }
  &.progress-4 {
    .draw-progress(4, #719e19);
  }
  &.progress-5 {
    .draw-progress(5, #719e19);
  }
  &.progress-6 {
    .draw-progress(6, #719e19);
  }
  &.progress-7 {
    .draw-progress(7, #719e19);
  }
  &.progress-8 {
    .draw-progress(8, #719e19);
  }
  &.progress-9 {
    .draw-progress(9, #719e19);
  }
  &.progress-10 {
    .draw-progress(10, #719e19);
  }
  &.progress-11 {
    .draw-progress(11, #719e19);
  }
  &.progress-12 {
    .draw-progress(12, #719e19);
  }
  &.progress-13 {
    .draw-progress(13, #719e19);
  }
  &.progress-14 {
    .draw-progress(14, #719e19);
  }
  &.progress-15 {
    .draw-progress(15, #719e19);
  }
  &.progress-16 {
    .draw-progress(16, #719e19);
  }
  &.progress-17 {
    .draw-progress(17, #719e19);
  }
  &.progress-18 {
    .draw-progress(18, #719e19);
  }
  &.progress-19 {
    .draw-progress(19, #719e19);
  }
  &.progress-20 {
    .draw-progress(20, #719e19);
  }
  &.progress-21 {
    .draw-progress(21, #719e19);
  }
  &.progress-22 {
    .draw-progress(22, #719e19);
  }
  &.progress-23 {
    .draw-progress(23, #719e19);
  }
  &.progress-24 {
    .draw-progress(24, #719e19);
  }
  &.progress-25 {
    .draw-progress(25, #719e19);
  }
  &.progress-26 {
    .draw-progress(26, #719e19);
  }
  &.progress-25 {
    .draw-progress(25, #719e19);
  }
  &.progress-26 {
    .draw-progress(26, #719e19);
  }
  &.progress-27 {
    .draw-progress(27, #719e19);
  }
  &.progress-28 {
    .draw-progress(28, #719e19);
  }
  &.progress-29 {
    .draw-progress(29, #719e19);
  }
  &.progress-30 {
    .draw-progress(30, #719e19);
  }
  &.progress-31 {
    .draw-progress(31, #719e19);
  }
  &.progress-32 {
    .draw-progress(32, #719e19);
  }
  &.progress-33 {
    .draw-progress(33, #719e19);
  }
  &.progress-34 {
    .draw-progress(35, #719e19);
  }
  &.progress-35 {
    .draw-progress(35, #719e19);
  }
  &.progress-36 {
    .draw-progress(36, #719e19);
  }
  &.progress-37 {
    .draw-progress(37, #719e19);
  }
  &.progress-38 {
    .draw-progress(38, #719e19);
  }
  &.progress-39 {
    .draw-progress(39, #719e19);
  }
  &.progress-40 {
    .draw-progress(40, #719e19);
  }
  &.progress-41 {
    .draw-progress(41, #719e19);
  }
  &.progress-42 {
    .draw-progress(42, #719e19);
  }
  &.progress-43 {
    .draw-progress(43, #719e19);
  }
  &.progress-44 {
    .draw-progress(44, #719e19);
  }
  &.progress-45 {
    .draw-progress(45, #719e19);
  }
  &.progress-45 {
    .draw-progress(45, #719e19);
  }
  &.progress-46 {
    .draw-progress(46, #719e19);
  }
  &.progress-47 {
    .draw-progress(47, #719e19);
  }
  &.progress-48 {
    .draw-progress(48, #719e19);
  }
  &.progress-49 {
    .draw-progress(49, #719e19);
  }
  &.progress-50 {
    .draw-progress(50, #719e19);
  }
  &.progress-51 {
    .draw-progress(51, #719e19);
  }
  &.progress-52 {
    .draw-progress(52, #719e19);
  }
  &.progress-53 {
    .draw-progress(53, #719e19);
  }
  &.progress-54 {
    .draw-progress(54, #719e19);
  }
  &.progress-55 {
    .draw-progress(55, #719e19);
  }
  &.progress-56 {
    .draw-progress(56, #719e19);
  }
  &.progress-57 {
    .draw-progress(57, #719e19);
  }
  &.progress-58 {
    .draw-progress(58, #719e19);
  }
  &.progress-59 {
    .draw-progress(59, #719e19);
  }


  &.progress-60 {
    .draw-progress(60, #719e19);
  }
  &.progress-61 {
    .draw-progress(61, #719e19);
  }
  &.progress-62 {
    .draw-progress(62, #719e19);
  }
  &.progress-63 {
    .draw-progress(63, #719e19);
  }
  &.progress-64 {
    .draw-progress(64, #719e19);
  }
  &.progress-65 {
    .draw-progress(65, #719e19);
  }
  &.progress-66 {
    .draw-progress(66, #719e19);
  }
  &.progress-67 {
    .draw-progress(67, #719e19);
  }
  &.progress-68 {
    .draw-progress(68, #719e19);
  }
  &.progress-69 {
    .draw-progress(69, #719e19);
  }
  &.progress-70 {
    .draw-progress(70, #719e19);
  }
  &.progress-71 {
    .draw-progress(71, #719e19);
  }
  &.progress-72 {
    .draw-progress(72, #719e19);
  }
  &.progress-73 {
    .draw-progress(73, #719e19);
  }
  &.progress-74 {
    .draw-progress(74, #719e19);
  }
  &.progress-75 {
    .draw-progress(75, #719e19);
  }
  &.progress-76 {
    .draw-progress(76, #719e19);
  }
  &.progress-77 {
    .draw-progress(77, #719e19);
  }
  &.progress-78 {
    .draw-progress(78, #719e19);
  }
  &.progress-79 {
    .draw-progress(79, #719e19);
  }
  &.progress-80 {
    .draw-progress(80, #FFCC00);
  }
  &.progress-81 {
    .draw-progress(81, #FFCC00);
  }
  &.progress-82 {
    .draw-progress(82, #FFCC00);
  }
  &.progress-83 {
    .draw-progress(84, #FFCC00);
  }
  &.progress-85 {
    .draw-progress(85, #FFCC00);
  }
  &.progress-86 {
    .draw-progress(86, #FFCC00);
  }
  &.progress-87 {
    .draw-progress(87, #FFCC00);
  }
  &.progress-88 {
    .draw-progress(88, #FFCC00);
  }
  &.progress-89 {
    .draw-progress(89, #FFCC00);
  }
  &.progress-90 {
    .draw-progress(90, #FFCC00);
  }
  &.progress-91 {
    .draw-progress(91, #FFCC00);
  }
  &.progress-92 {
    .draw-progress(92, #FFCC00);
  }
  &.progress-93 {
    .draw-progress(93, #FFCC00);
  }
  &.progress-94 {
    .draw-progress(94, #FFCC00);
  }

  &.progress-95 {
    .draw-progress(95, #FFCC00);
  }
  &.progress-96 {
    .draw-progress(96, #FFCC00);
  }
  &.progress-97 {
    .draw-progress(97, #FFCC00);
  }
  &.progress-98 {
    .draw-progress(98, #FFCC00);
  }
  &.progress-99 {
    .draw-progress(99, #FFCC00);
  }
  &.progress-100 {
    .draw-progress(100, #e74c3c);
  }


}

.pie-wrapper--solid {
  border-radius: 50%;
  overflow: hidden;

  &:before {
    border-radius: 0 100% 100% 0 / 50%;
    content: '';
    display: block;
    height: 100%;
    margin-left: 50%;
    transform-origin: left;
  }

  .label {
    background: transparent;
  }

  &.progress-65 {
    .draw-progress--solid(65, #e67e22, @bg);
  }

  &.progress-25 {
    .draw-progress--solid(25, #9b59b6, @bg);
  }

  &.progress-88 {
    .draw-progress--solid(88, #3498db, @bg);
  }
}

// --
.draw-progress(@progress, @color) when (@progress <= 50) {
  .pie {
    .right-side {
      display: none;
    }
  }
}

.draw-progress(@progress, @color) when (@progress > 50) {
  .pie {
    clip: rect(auto, auto, auto, auto);

    .right-side {
      transform: rotate(180deg);
    }
  }
}

.draw-progress(@progress, @color) {
  .pie {
    .half-circle {
      border-color: @color;
    }

    .left-side {
      @rotate: @progress * 3.6;
      transform: rotate(~'@{rotate}deg');
    }
  }
}

.draw-progress--solid(@progress, @color, @bg) when (@progress <= 50) {
  &:before {
    background: @bg;
    transform: rotate((100 - (50 - @progress)) / 100 * 360deg * -1);
  }
}

.draw-progress--solid(@progress, @color, @bg) when (@progress > 50) {
  &:before {
    background: @color;
    transform: rotate((100 - @progress) / 100 * 360deg);
  }
}

.draw-progress--solid(@progress, @color, @bg) {
  background: linear-gradient(to right, @color 50%, @bg 50%);
}

.btn.btn-link {

    border: 0;
    text-decoration: underline;
  }

.popover-content{
  .chart-card {
        padding: 2px;
        margin: 2px;
    }
    .btn.btn-default{
      margin-bottom: 5px;
    }
}
