.bein-connect-login {
  .btn.btn-green {
      width: 100%;
      /* padding-top: 0.25rem; */
      /* padding-bottom: 0.25rem; */
      height: 4rem;
  }
  .forgot {
    margin-top: 1.5rem;
    a {
            color: #333;
        padding-right: 5px;
        text-transform: uppercase;
        text-decoration: underline;
        font-size: 0.9rem;
        @media (max-width: 424px) {
          display: block;
          padding: 0;
          text-align: center;
        }
        &:first-child {
            border-right: 1px solid #bcbcbc;
            padding-right: 0.5rem;
            margin-right: 0.5rem;
            @media (min-width: 1025px) {
              padding-right: 1rem;
              margin-right: 1rem;
            }
            @media (max-width: 424px) {
              padding: 0;
              margin:0;
              border: none;
            }
        }
    }
  }
  .form-section {
    &:nth-child(2) {
      .form-group {
        &.floating-label-input {
          margin-bottom: 1.5rem;
          @media (min-width: 992px) {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}
.create-bein-connect-ac {
  .form-section {
    .form-group {
      &.floating-label-input {
        &:first-child {
            margin-bottom: 1rem;
        }
      }
    }
    .checkbox {
        margin: 0.5rem 0 1rem 0;
        label {
            @media (min-width: 992px) {
              padding-left: 2.5rem;
            }
        }
    }
  }
}
.beINconnect {
  a {
    &.greenTxt {
        color: #719e19;
        text-decoration: none;
    }
  }
  .backtomain {
    padding: 1rem;
    @media (min-width: 992px) {
      padding-top: 4rem;
    }
    a {
      text-transform: uppercase;
      text-decoration: underline;
    }
  }
}
