.b2b-form {
    background: linear-gradient(180deg,#fff,#e2e1da);
}
.b2b-form .form-title  {
    border-bottom: solid 2px #ccc;
    margin-bottom: 3.5rem
}
.b2b-form .form-title h3 {
    padding-top: 1rem;
    padding-bottom: 0;
    color: #848789;
    font-size: 1.25rem;
    font-family: Lato,Roboto,Arial,sans-serif;
    letter-spacing: 1px;
}
.b2b-form .floating-label-input {
    border: none;
    background: transparent;
    margin-bottom: 20px;
}
.b2b-form .floating-label-input input {
    padding-left: 0px;
    padding-right: 0px;
    color: #616b1e;
    border: none;
    border-bottom: solid 1px #616b1e;
    background: transparent;
    margin-bottom: 20px;
}
.b2b-form .floating-label-input input:focus {
    border-width:  2px;
}
input, .b2b-form .floating-label-input textarea {
    color: #616b1e;
    padding-top: 10px;
}
.b2b-form .floating-label-input label {
    padding-left: 0px;
    padding-right: 0px;
    color: #616b1e;
    line-height: 24px;
    top: 40px;
}
.b2b-form .floating-label-textarea {
    border: solid 1px #616b1e;
    margin-top: 30px
}
.b2b-form .floating-label-textarea label {
    padding-left: 20px;
    color: #616b1e;
    line-height: 20px;
    top: 20px;
}
.b2b-form .btn {
    background-color: #616b1e !important;
    border-color: #616b1e !important;
}
.b2b-form .checkbox label:before,
.form .radio label:before {
  width: 22px;
  height: 22px;
}

.form .radio .customer  {
  display: block;
  margin-bottom: 1.25rem;
}
.b2b-form .checkbox input[type=radio]:checked~label:before,
.b2b-form .radio input[type=radio]:checked~label:before {
    background-image: url(../img/forms/omnilead/radio-new.png);
    background-size: 100%;
    border: none;
}

.b2b-form .floating-label-input.has-error-fields label,
.b2b-form .floating-label-input.has-error-fields input,

.b2b-form .floating-label-input.had-error-fields .alert-label,
.b2b-form .floating-label-input.has-error-fields .alert-label,
.b2b-form .floating-label-select.had-error-fields .alert-label,
.b2b-form .floating-label-select.has-error-fields .alert-label {
    color: #be1218 !important;
}
.b2b-form .floating-label-input.has-error-fields {
    border-color: #be1218 !important;
}
.b2b-form .floating-label-input.has-error-fields .alert-icon svg {
    fill: #be1218 !important;
    top: 2rem;
    right: 0;
}
.b2b-form .floating-label-textarea.has-error-fields .alert-icon svg {
    right: 1rem;
}
.b2b-form .floating-label-input.has-error-fields .alert-label {
    top: auto;
    bottom: -20px;
    text-align: left;
}
.b2b-form .form-submitted.error {
    height: 4rem;
    background-color: #be1218;
    display:none;
    margin: 0;
}
.b2b-form .form-submitted.error h3 {
    padding-top: 0;
    color: #fff;
    font-size: 18px;
    padding-bottom: 0;
}
.b2b-form .form-submitted.error svg {
    margin-top: 0
}
.b2b-form .backToPage {
    font-size: 16px;
    color: #252525 !important;
    letter-spacing: 1px;
    text-transform: uppercase;
}





*[lang="ar"] {
  *[dir="rtl"] {

    .b2b-form {
        .floating-label-input {
          label {
            padding-right: 0 !important;
          }
          &.floating-label-textarea {
            label {
              padding-right: 1rem !important;
            }
          }
        }
    }

  }
}
