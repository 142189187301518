.recover-username{
    background-color: @sand-white;
    padding-top: 6rem;
    padding-bottom: 4rem;
    text-align: center;

    h3{
        //margin-top: 0;
        //margin-bottom: 3rem;
        text-transform: uppercase;
    }

    .form{
        margin-top: 0;
        margin-bottom: 0;

        .floating-label-input{
            margin: 0;
            label{
                color: @main-green;
            }
        }

        &.form-double >.form-section{
            margin: 0;
            padding: 0;
            &:nth-child(1){
                padding-right: 3.75rem;
                //padding-bottom: 2rem;
            }
            &:nth-child(2){
                padding-left: 3.75rem;
            }
            .floating-label-input{
                margin-bottom: 2rem;
            }
        }

        .visualCaptcha{
            margin-top: 3.25rem;
            margin-bottom: 0;
            .visualCaptcha-explanation{
                margin: 0;
                margin-bottom: 1rem;
                color: @almost-black;
                background-color: @light-sand-brown;
            }
        }

        .btn{
            margin: 4rem 0.5rem 0;
        }
        p.registration-problems{
            margin: 0;
            margin-top: 2rem;
            padding: 0;
            a{
                color: @almost-black;
                text-decoration: underline;
            }
        }
    }

    &.new-customer{
        background-color: @light-sand-brown;
        padding-top: 4rem;
        p{
            font-size: 1.25rem;
            line-height: 1.5rem;
        }
    }
}


/*
 * Business Variant
 */
.business{
  .recover-username{
    background-color: @light-gray;
    .form{
        .visualCaptcha{
            .visualCaptcha-explanation{
                color: @white;
                background-color: @premium-gray;
            }
        }
        p.registration-problems{
            display: none;
        }
    }
    &.new-customer{
        background-color: @premium-gray;
        p{
            //display: none;
        }
    }
  }
}

/*
 * @media
 */
@media (max-width: @screen-sm-min){
    .recover-username{
        .form{
            &.form-double >.form-section{
                &:nth-child(1){
                    padding-right: 0;
                    padding-bottom: 0rem;
                }
                &:nth-child(2){
                    padding-left: 0;
                }
            }
        }
    }
}
