
//search-business-people-start
.search-business-people {
      padding-bottom: 3rem;
  .tab-box-wrap {
    padding-top: 3.2rem;
    label {
     height: 4.3125rem;
     position: relative;
   }
  }

  .search-button {
    float: right;
  }
  .tab-box-slide {
    margin: 3rem 0;
  }

  .tab-box-wrap label.checkedClass, .tab-box-wrap label.checkedClass:hover, .tab-box-wrap label:hover {
    background-color: #719e19;
    color: #fff;
    transition: all all .2s ease-in-out ease-in-out;
    transition: all .2s ease-in-out;
  }
  .tabs-container {
    @media (min-width: 768px){
      max-width: 350px;
      margin: 0 auto;
    }

    .tabs-center {
      width: 100%;
      h5 {
        width: 100%;
        margin: 0;
        text-align: center;
      }
    }
  }
  .tab-box-wrap .smartphone-label, .tab-box-wrap input[type=radio], .tab-box-wrap input[type=radio]+div {
      display: none;
    }
  .tab-box-wrap input[type=radio]:checked+div {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
  }

  //
  // Input groups
  // --------------------------------------------------

// Base styles
// -------------------------
  .input-group {
    position: relative; // For dropdowns
    display: table;
    border-collapse: separate; // prevent input groups from inheriting border styles from table cells when placed within a table

    // Undo padding and float of grid classes
    &[class*="col-"] {
      float: none;
      padding-left: 0;
      padding-right: 0;
    }

    .form-control {
      // Ensure that the input is always above the *appended* addon button for
      // proper border colors.
      position: relative;
      z-index: 2;

      // IE9 fubars the placeholder attribute in text inputs and the arrows on
      // select elements in input groups. To fix it, we float the input. Details:
      // https://github.com/twbs/bootstrap/issues/11561#issuecomment-28936855
      float: left;

      width: 100%;
      margin-bottom: 0;

      &:focus {
        z-index: 3;
      }
    }
  }

  // Sizing options
  //
  // Remix the default form control sizing classes into new ones for easier
  // manipulation.

  .input-group-lg > .form-control,
  .input-group-lg > .input-group-addon,
  .input-group-lg > .input-group-btn > .btn {
    .input-lg();
  }
  .input-group-sm > .form-control,
  .input-group-sm > .input-group-addon,
  .input-group-sm > .input-group-btn > .btn {
    .input-sm();
  }


  // Display as table-cell
  // -------------------------
  .input-group-addon,
  .input-group-btn,
  .input-group .form-control {
    display: table-cell;
    text-overflow: ellipsis;

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
  }
  // Addon and addon wrapper for buttons
  .input-group-addon,
  .input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle; // Match the inputs
  }

  // Text input groups
  // -------------------------
  .input-group-addon {
    padding: @padding-base-vertical @padding-base-horizontal;
    font-size: @font-size-base;
    font-weight: normal;
    line-height: 1;
    color: @brand-primary;
    text-align: center;
    background-color: @input-group-addon-bg;
    border: 1px solid @input-group-addon-border-color;
    border-radius: @input-border-radius;

    // Sizing
    &.input-sm {
      padding: @padding-small-vertical @padding-small-horizontal;
      font-size: @font-size-small;
      border-radius: @input-border-radius-small;
    }
    &.input-lg {
      padding: @padding-large-vertical @padding-large-horizontal;
      font-size: @font-size-large;
      border-radius: @input-border-radius-large;
    }

    // Nuke default margins from checkboxes and radios to vertically center within.
    input[type="radio"],
    input[type="checkbox"] {
      margin-top: 0;
    }
  }

  // Reset rounded corners
  .input-group .form-control:first-child,
  .input-group-addon:first-child,
  .input-group-btn:first-child > .btn,
  .input-group-btn:first-child > .btn-group > .btn,
  .input-group-btn:first-child > .dropdown-toggle,
  .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
    .border-right-radius(0);
  }
  .input-group-addon:first-child {
    border-right: 0;
  }
  .input-group .form-control:last-child,
  .input-group-addon:last-child,
  .input-group-btn:last-child > .btn,
  .input-group-btn:last-child > .btn-group > .btn,
  .input-group-btn:last-child > .dropdown-toggle,
  .input-group-btn:first-child > .btn:not(:first-child),
  .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
    .border-left-radius(0);
  }
  .input-group-addon:last-child {
    border-left: 0;
  }

// Button input groups
// -------------------------
  .input-group-btn {
    position: relative;
    // Jankily prevent input button groups from wrapping with `white-space` and
    // `font-size` in combination with `inline-block` on buttons.
    font-size: 0;
    white-space: nowrap;

    // Negative margin for spacing, position for bringing hovered/focused/actived
    // element above the siblings.
    > .btn {
      position: relative;
      + .btn {
        margin-left: -1px;
      }
      // Bring the "active" button to the front
      &:hover,
      &:focus,
      &:active {
        z-index: 2;
      }
    }

    // Negative margin to only have a 1px border between the two
    &:first-child {
      > .btn,
      > .btn-group {
        margin-right: -1px;
      }
    }
    &:last-child {
      > .btn,
      > .btn-group {
        z-index: 2;
        margin-left: -1px;
      }
    }
  }

  @media (max-width: 767px){
    .input-group button {
      margin-top: 1rem;
    }
    .input-group-addon {
      display: none;
    }
  }

  @media (min-width: 767px){
    .input-group.input-group-lg {
      input {
        height: 48px;
      }
    }
  }
  @media (min-width: 1440px){
    .input-group.input-group-lg {
      input {
        height: 68px !important;
      }
    }
  }
  //integration fixing
  @media (max-width: 767px){
            .tab-box-wrap label {
                width: 50%;
                float: left;
                height: 3rem;

            }
            .tab-box-text .input-group.input-group-lg {
                width: 100%;

            }
            .tab-box-slide {
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
            }
            .search-form {
                width: 100%;
            }
            .input-group button {
                margin-top: 1.5rem;
                width: 100%;
            }
            .input-group.search-button {
                width: 100%;
            }
  }
  @media (min-width: 768px) and (max-width: 992px){
    .input-group.search-button button {
        padding-right:1rem;
        padding-left: 1rem;
    }
    .tab-box-wrap .tab-box-label label {
        min-width: 170px;
    }
    .search-business-people .tabs-container {
        max-width: 380px;
    }
  }
}

// custom Panels for search page
.user-table-wrap {
  .panel-default {
    margin-bottom: 2rem;
    border-bottom: 1px solid #ccc;
    &:last-child {
      border-bottom: 0;
    }
  .panel-heading {
    color: #000;
    //background-color:#f2f2f2;
    border-color: #ccc;
    > h5 {
      margin: 5px 0;
    }
  }
  .panel-body {
    border-top-color: #ccc;
      p {
        margin-bottom: 2rem;
        color: #808080;
      }
    }
  }
}

//form-dropdown-margins start
.form-dropdown-margins {
  .form {
    margin-bottom: 0;
    padding-bottom: 0;

    .form-group {
      margin-bottom: 0;
    }
  }
}

//search ar
*[lang="ar"] {
  *[dir="rtl"] {
    .search-business-people {
      .tab-box-text {
        .col-sm-10 {
          float: right;
        }
        .col-sm-2 {
          float: left;
          text-align: left;
          .search-button {
            width: 100%;
          }
        }
      }
      .input-group-addon:first-child {
        border-right: 1px solid #ccc;
      }
    }
  }
}
//search-business-people-ends
