// *[lang="ar"] {
  *[dir="rtl"] {

    * {
      font-family: @font-arabic !important;
      font-weight: 300;
      letter-spacing: 0 !important;
    }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      font-family: @font-arabic !important;
      font-weight: 300;
      letter-spacing: 0 !important;
    }

    input[type="text"]{
      font-family: @font-input;
    }

  }

  *[dir="ltr"] {
    font-family: @font-family-base;
  }

  h1[dir="ltr"], h2[dir="ltr"], h3[dir="ltr"], h4[dir="ltr"], h5[dir="ltr"], h6[dir="ltr"],
  .h1[dir="ltr"], .h2[dir="ltr"], .h3[dir="ltr"], .h4[dir="ltr"], .h5[dir="ltr"], .h6[dir="ltr"] {
    font-family: @headings-font-family;
  }
// }

// ref: https://css-tricks.com/almanac/properties/d/direction/
*[dir="ltr"] {
  direction: ltr;
  unicode-bidi: embed;
}

*[dir="rtl"] {
  direction: rtl;
  unicode-bidi: embed;
}

bdo[dir="ltr"] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

bdo[dir="rtl"] {
  direction: rtl;
  unicode-bidi: bidi-override;
}
