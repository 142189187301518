.wst{
  .media-cta-wrapper {
    .media-cta-photo {
      height: 250px;

    }
  }

  .benefits-section {
    .title-wrapper {
      margin-bottom: 2.5rem;
      @media (max-width: 768px){
        margin-bottom: 1.6rem;
      }
      .title {
        h3 {
          font-size: 1.75rem;
          width: 55%;
          z-index: 1;
          margin-bottom: .75rem;
          font-weight: 400;
          letter-spacing: .5px;
          line-height: 1.15;
          position: relative;
          @media (max-width: 768px){
            font-size: 1.25rem;
            padding-right: 26px;
          }
          @media (max-width: 475px){
            padding-right: 0px;
          }

          &:before {
            content: attr(data-shadow);
            font-size: 8.75rem;
            color: @white;
            top: -15px;
            opacity: 1;
            z-index: -1;
            position: absolute;
          }
        }
      }
    }

    .media-cta-container {
      background-color: @white;
      border-radius: 4px;

      div.cover {
        border-radius: 4px 0 0 4px;
      }
    }

    .benefits-mobile {
      position: relative;
      display: block;
      overflow: hidden;
      line-height: 2.5;
      font-size: 14px;
      color: @main-green;
      font-weight: 900;
      padding: 14px 0;

      .icon {
        height: 40px;
        width: 35px;
        margin-right: 10px;

        circle,
        path,
        polygon,
        polyline {
          stroke: @main-green;
          stroke-width: 2px;
        }
      }

      .view-more {
        &:before {
          right: 3px;
          border: solid @main-green;
          border-width: 0 1px 1px 0;
          padding: 5px;
          content: "";
          position: absolute;
          line-height: 1;
          top: 50%;
          transform: translateY(-50%) rotate(-135deg);
          -webkit-transform: translateY(-50%) rotate(-135deg);
          transition: all .3s ease-in-out;

        }
      }

      &.collapsed {
        color: @gray-fill;
        font-weight: 500;

        .icon {
          circle,
          path,
          polygon,
          polyline {
            stroke: @gray-fill;
            stroke-width: 1px;
          }
        }

        .view-more {
          &:before {
            transform: translateY(-50%) rotate(45deg);
            -webkit-transform: translateY(-50%) rotate(45deg);
            border: solid @gray-fill;
            border-width: 0 1px 1px 0;
          }
        }
      }
    }

    .tab-pane {
      overflow: hidden;
      @media (max-width: 768px){
        overflow: visible;
      }
    }

    .features-wrapper {
      .features-list {
        z-index: 1;
        position: relative;
        border: 0;

        li {
          padding: 1.125rem 0;
          color: @gray-fill;
          display: block;
          float: none;

          > a {
            display: flex;
            display: -webkit-flex;
            border: 0;
            color: @gray-fill;
            background-color: transparent;
            padding: 0;

            p {
              font-family: @font-family-base;
              font-size: 1.125rem;
              line-height: 1.125rem;
              text-align: center;
              margin: auto 0;
              @media (max-width: 768px){
                font-family: @font-family-base;
                font-size: 16px;
                line-height: 20px;
              }

            }

            .icon {
              float: left;
              height: 30px;
              width: 32px;
              margin-top: -5px;
              margin-right: 30px;
              fill: inherit;
            }
          }

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            padding-bottom: 0;
          }

          &.active {
            color: @main-green;
            font-weight: 900;

            > a p {
              &:after{
                content: "";
                border: solid #719e19;
                border-width: 0 2px 2px 0;
                position: absolute;
                right: -20px;
                left:inherit;
                padding: 5px;
                top: 6px;
                transform: rotate(320deg);
              }
              color: @main-green;

              .icon {
                color: @main-green;
                font-weight: 900;

                circle,
                path,
                polygon,
                polyline {
                  stroke: @main-green;
                  stroke-width: 2px;
                }
              }
            }

          }
        }
      }
    }

    .img-with-disc {
      background: @white;
      box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
      margin-top: 0;
      @media (max-width: 768px){
        margin-top: 0;
      }
      .title-wrapper {
        margin-bottom: 1rem;
        @media (max-width: 768px){
          margin-bottom: 1rem;
        }

        .title {
          h3 {
            width: 100%;
            font-size: 1.625rem;
            line-height: 1.15;
          }
        }
      }
      .media{
        display: flex;
        @media (max-width: 768px){
          // flex-direction: column;
        }
      }
      .media-left {
        padding-right: 1.5rem;

        .media-object {
          height: 428px;
        }
      }

      .media-body {
        padding: 2.75rem 1.5rem 2.75rem 0;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width:100%;
        .btn-apple-shop{
          width: 260px;
          @media (max-width: 768px){
            width: 100%;
          }
        }
        .fixed-at-bottom{
          margin-top: auto;
        }
        .discription {
          max-height: 11.75em;
          overflow: hidden;
          position: relative;

          p,
          p.default-text {
            margin-bottom: 1.875rem;
            @media (max-width: 768px){
              margin-bottom: 1rem;
            }
            &:last-child {
              margin: 0;
            }
          }
        }
        @media (max-width: 768px){
          padding: 1rem !important;
        }
      }
    }
  }
  .overview-section {
    .default-section-title{
      .expandTab{
        display: none;
        svg{
          width: 20px;
          transition: transform .3s;
          transform: rotate(-180deg);
          top: 20px;
          position: relative;
        }
        @media (max-width: 768px){
          display: block;
        }
      }
    }
    .overview-img {
      img{
        width: 90%;
        object-fit: cover;
        max-height: 550px;
        @media (max-width: 768px){
          width: 100%;
        }
      }
    }
    .overview-wrapper {

      position: relative;
      overflow: hidden;

      .mobile-features {
        //margin-top: 3.5625rem;
        min-height: 515px !important;

        &.collapse {
          display: block;
          overflow: hidden;
          height: 580px !important;
          position: relative;

          &.in {
            height: auto !important;
          }
        }

        .mobile-feature {
          // padding-bottom: .75rem;
          border-bottom: 1px solid @light-sand-brown;
          margin: .75rem 0;
          &:first-child{
            margin-top: 0;
            @media (max-width: 768px){
              margin-top: 1.5rem;
            }
          }
          &:last-child {
            border: 0;
          }
          .media {
              display: flex;
          }
          .media-body {
              padding-right: 2rem;
              .default-title{
                font-size: 18px;
                line-height: 18px;
                margin-bottom: 1rem;
                color: @almost-black;
                font-weight: normal;
              }
              .btn-text-bugfixing{
                margin-bottom: 15px;
                display: inline-block;
              }
          }
          .media-right {
            .media-img {
              width: 10rem;
              height: 10rem;

              img.media-object {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .specification-section {
    .specification-box {
      .specification-list {
        margin-top: 2.625rem;
        min-height: 226px !important;

        &.collapse {
          display: block;
          overflow: hidden;
          // height: 226px !important;
          position: relative;

          &.in {
            height: auto !important;

            .table {
              td,
              th {
                white-space: normal;
              }
            }
          }
        }

        .table {
          table-layout: fixed;
          width: 100%;
          font-size: 1rem;
          margin: 0;

           > tbody,
           > thead,
          > tfoot {
            td,
            th {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 50%;
            }

            td {
              font-weight: 700;
              color: @almost-black
            }
          }
        }
      }
    }
  }
  .media-news {
    .news-item {
      border-bottom: solid 1px @light-sand-brown;

      .btn-text:before {
        right: auto;
      }
    }
  }

  .media-cta-wrapper{
    .h4, h4{

    }
  }
}







// arabic language
*[lang="ar"] {
  *[dir="rtl"] {
    .wst{
      .overview-section {
        .overview-wrapper {
          .mobile-features {
            .mobile-feature {
              .media-body {
                padding-right: 0;
                padding-left: 2rem;
              }
            }
          }
        }
      }
      .benefits-section {
        .features-wrapper {
          .features-list {
            padding-right: 0;
            padding-left: 40px;
             li {
               &.active>a:after {
                 left: 30%;
                 right: auto;
                 transform: rotate(130deg);
               }
             }
            .icon {
              margin-right: 0;
              margin-left: 30px;
            }
          }
        }

        .img-with-disc {
          .media-left {
            padding-right: 0;
            padding-left: 1.5rem;
          }

          .media-body {
            padding: 2.75rem 0 2.75rem 1.5rem;

            .fixed-at-bottom {
              position: static;
              line-height: 64px;
              bottom: 2.75rem;
              right: 20px;
            }
          }
        }
      }
    }

  }

  .wst .benefits-section .features-wrapper .features-list li.active>a p:after {
    content: "";
    border: solid #719e19;
    border-width: 0 1px 1px 0;
    position: absolute;
    left: -20px;
    right:inherit;
    padding: 5px;
    top: 6px;
    transform: rotate(860deg);
  }
}

*[lang='ar'] *[dir='rtl'] .benefits-section.benefits-section-mobile .img-with-disc .media-body {
  padding: 2.75rem 1.5rem;
}

*[lang='ar'] *[dir='rtl'] .benefits-section .img-with-disc .media-body .fixed-at-bottom {
  position: absolute;
  bottom: 2.75rem;
  right: 0;
  left: auto;
  line-height: initial;
}

*[lang='ar'] *[dir='rtl'] .benefits-section.benefits-section-mobile .img-with-disc .media-body .fixed-at-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  left: auto;
  line-height: initial;
}
@media only screen and (min-width: 768px) {
  *[lang="ar"] {
    *[dir="rtl"] {
      .media-news {
        .news-para {
          padding-right: 2em;
        }
      }
    }
  }

  .media-news {
    .news-para {
      text-indent: -2em;
      padding-left: 2em;
    }
  }

}
@media only screen and (max-width: @screen-ms-min) {
  .benefits-section {
    .title-wrapper {
      .title {
        h3 {

          width: 100%;

          &:before {
            font-size: 5.75rem;

          }
        }
      }
    }

    .features-wrapper {
      display: none;
    }

    .img-with-disc {
      .title-wrapper {
        margin-bottom: 1.125rem;

        .media-body {
          .discription {
            p.default-text {
              margin-bottom: .875rem;
            }

            p {
              margin-bottom: .875rem;
            }
          }
        }
      }

      .media-body,
      .media-left,
      .media-right {
        display: block;
      }

      .media-body {
        padding: 0 15px 88px;
        width: auto;
      }

      .media-left,
      .media-right {
        > a {
          display: block;
          height: 250px;
          overflow: hidden;
        }

      }
    }
  }

}
.wst{
  .media-cta-wrapper{
    .h4, h4{
      letter-spacing: normal;

    }
  }
}
// mobile version
@media (max-width: @screen-sm) {
  .wst{
    .media{
      flex-direction: column;
        .media-left{
          padding-right: 0 !important;
        }
        .media-body{
          padding: 1rem 0 !important;
        }
    }
    .media-cta-wrapper{
      .h4, h4{
        font-weight: bold;
      }
    }
  }
  .benefits-section {
    .title-wrapper {
      .title {
        h3 {
          font-size: 1.5rem;

          &:before {
            font-size: 4.75rem;
          }
        }
      }
    }
  }

  .benefits-section {
    .img-with-disc {
      overflow: hidden;

      .media-body {
        padding: 15px 15px 88px;

        .fixed-at-bottom {
          left: 0;
        }

        .title-wrapper {
          .title {
            h3 {
              font-size: 18px !important;
              letter-spacing: normal;
              font-weight: bold;
              line-height: 18px;
              font-family: @font-family-base;
              padding-top: 0;
            }
          }
        }
      }
    }

    .media {
      .media-left {
        padding-right: 0;
        padding-left: 0;

        .media-object {
          height: 540px;
          object-fit: fill;
          width: 100%;
        }
      }
    }
  }

  .wst{
    .overview-section{
      .default-section-title{
        h3{
          width: 90%;
        }
        .expandTab{
          &.collapsed{
            svg{
              transform: rotate(0deg);
            }
          }
        }
      }
      .overview-wrapper{
        .mobile-features{
          .media{
            flex-direction: column-reverse;
          }
          &.collapse{
            height: 0!important;
            min-height: 0!important;
          }
        }
      }
    }
  }
}

*[lang='ar'] *[dir='rtl'] .benefits-section.benefits-section-mobile .media .media-left {
  padding-right: 0;
  padding-left: 0;
}
@media (min-width : @screen-sm) and (max-width : @screen-md-min) {
  .benefits-section {
    .img-with-disc {
      .title-wrapper {
        margin-bottom: 1.125rem;

        .media-body {
          .discription {
            p,
            p.default-text {
              margin-bottom: .875rem;
            }
          }
        }
      }

      .media {
        .media-left {
          padding-right: 0;

          .media-object {
            height: 540px;
            object-fit: fill;
            width: 100%;
          }
        }
      }

      .media-body,
      .media-left,
      .media-right {
        display: block;
      }

      .media-body {
        padding: 15px 15px 88px;
        width: auto;

        .fixed-at-bottom {
          left: 0;
        }
      }

      .media-left,
      .media-right {
        > a {
          display: block;
          height: 250px;
          overflow: hidden;
        }

      }
    }
  }
}
@media only screen and (max-width: @screen-md-min) {
  .benefits-section {
    .title-wrapper {
      .title {
        h3 {
          width: 100% !important;

          &:before {
            font-size: 4.75rem;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .overview-section,
  .specification-section {
    .default-section-title {
      .btn-text.view-more {
        position: static;
      }
    }
  }

  .ptb-50 {
    padding: 20px;
  }

  .col-two {
    -webkit-column-count: auto;
    /* Chrome, Safari, Opera */
    -moz-column-count: auto;
    /* Firefox */
    column-count: auto;
  }
  .wst{
    .ptb-50 {
      padding: 20px 0;
    }


    .nav-tabs{
      >li{
        &.active{
          >a{
            color:@almost-black
          }
        }

      }
    }
  }
}

// *************************************
// media-cta custom styling
// *************************************

.media-cta-section,
.cta-benefits {
  background-color: hsla(0,0%,95%,.33);
  padding: 40px 0;
  @media (min-width: @screen-md) {
    padding: 4rem 0;
  }
  &.two-col {
    div.cover {
      position: relative;
    }
    .cta-heading {
      text-align: center;
    }
    .media-cta-wrapper {
      margin-bottom: 1.5rem;
      > div {
        @media (min-width: @screen-md) {
          max-height: 428px;
          min-height: 428px;
        }
      }

      .media-cta-photo {
        @media (min-width: @screen-md) {
          height: 428px;
        }
      }
    }
    .learn-more-link {
      @media (min-width: @screen-md) {
        bottom: 40px;
      }
    }
  }

  &.no-bg {
    background-color: transparent;
  }

  .cta-heading {
    font-size: 2rem;
    margin-bottom: 42px;
    line-height: 40px;
    color: @almost-black;
    margin-top: 0;
    @media (max-width: 767px) {
      font-size: 1.5rem;
      text-align: center;
    }
  }

  .media-cta-container {
    background: @white;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
    border-radius: 4px;
    //@media (max-width: 767px) {
    //  margin-left: 1rem;
    //  margin-right: 1rem;
    //}
  }

  .media-cta-wrapper {
    p {
      color: #767676;
      font-size: 14px;
      line-height: 19px;
      font-family: @font-family-base;
    }

    h4 {
      @media (max-width: 767px) {
        font-size: 18px;
        font-weight: bold;
        color: @premium;
        font-family: @font-family-base;
      }
    }

    .media-cta-photo {
      border-radius: 4px 4px 0 0;
      padding: 0;
      @media (min-width: @screen-md) {
        border-radius: 4px 0 0 4px;
        padding-right: 12px;
      }
    }

    .body-standard {
      padding: 1rem;
      padding-bottom: 4rem;
      h4 {
        margin-bottom: 1.4rem;
        font-family: @headings-font-family;
        color: @almost-black;
        @media (max-width: @screen-md) {
          margin-bottom: 1rem;
          font-family: @font-family-base;
          font-weight: 700;
          font-size: 18px;
        }
      }
      @media (min-width: @screen-md) {
        padding: 1.5rem 4rem 2.5rem 12px;
      }

    }

    &.col-reverse {
      .media-cta-photo {
        border-radius: 4px 4px 0 0;
        @media (min-width: @screen-md) {
          border-radius: 0 4px 4px 0;
          padding-left: 12px;
          padding-right: 0;
        }
      }
      .body-standard {
        @media (min-width: @screen-md) {
          padding-left: 1.5rem;
        }
      }
      .learn-more-link {
        left: 1.5rem;
      }
    }
  }
}

.cta-benefits-section {
  padding: 4rem 0;
  @media (max-width: @screen-md) {
    padding: 1rem 0;
  }
  .tab-pane {
    box-shadow: none !important;
  }
  &.benefits-section .title-wrapper .title h3:before {
    display: none;
  }
  .title-wrapper .title h3 {
    width: 65%;
    font-size: 2rem;
    @media (max-width: @screen-md) {
      font-size: 20px;
    }
  }
  .media-cta-container {
    margin: 0 !important;
  }
  .title-wrapper {
    @media (max-width: @screen-md) {
      margin-bottom: 0;
    }

  }
  .features-wrapper .features-list li.active {
    font-weight: 700 !important;
  }
}
.cta-benefits {
  background-color: transparent;
  padding: 0;
}
.media-cta-wrapper {
  .learn-more-link {
    position: absolute;
    left: 12px;
    bottom: 1.25rem;
    @media (max-width: @screen-md) {
      bottom: 2rem;
      left: 1rem;
    }
    a {
      font-size: 14px;
      &:before {
        padding: 3.5px;
        right: -20px;
      }
      @media (max-width: @screen-md) {
        font-size: 12px;
        &:before {
          padding: 2.85px;
        }
      }
    }
  }
}


*[lang="ar"] {
  *[dir="rtl"] {
    .media-cta-section {
      .learn-more-link {
        left: 0;
        right: 12px;
      }
    }

    .media-cta-section .media-cta-wrapper {
      .media-cta-photo {
        @media (min-width: @screen-md) {
          padding-left: 12px;
          padding-right: 0;
        }
      }
      .body-standard {
        padding: 1.5rem 12px 1rem 4rem;
      }

      &.col-reverse {
        .media-cta-photo {
          @media (min-width: @screen-md) {
            padding-left: 0;
            padding-right: 12px;
          }
        }
        .body-standard {
          padding-left: 0;
          padding-right: 1.5rem;
        }
        .learn-more-link {
          left: 0;
          right: 1.5rem;
        }
      }


    }
  }
}


/*
============================================
01 - xs viewport
01a - .media-cta-wrapper
02 - sm viewport
03 - md viewport
04 - lg viewport
05 - Arabic overrides
06 - Business overrides
07 - background colors
============================================
*/
/* -----------------------------------------
01 - mobile view port
01a - .media-cta-wrapper
----------------------------------------- */

.media-cta-wrapper {
  position: relative;
  flex-wrap: wrap;
  display: flex;

  & > div {
    min-height: 250px;
    &.text-left{
      text-align: left;
      padding: 15px 10px;
    }
  }

  &.media-cta-bleed {
    .label-tag {
      position: absolute;
      top: 0;
      left: 0;
      right: auto;
      color: @white;
      padding: 1.25rem;
      font-size: 1rem;
      z-index: 4;
      min-height: 0;
      height: auto;
      font-family: @headings-font-family;

      .detail-price-new {
        color: @white;

        .main-part {
          .price {
            font-size: 1.875rem;
          }
        }
      }
    }

    &.col-reverse {
      .label-tag {
        right: 0;
        left: auto;
      }

      .media-cta-photo {
        .cover {
          left: auto;
          right: 0;
        }

        .simple-image {
          > img {
            left: inherit;
            right: 0;
          }
        }
      }
    }

    .media-cta-photo {
      position: relative;
    }
  }

  .media-cta-caption {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-height: none;

    .btn {
      // max-width: 100%;
    }

    > div {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-top: 2rem;
      padding-bottom: 2.5rem;

      > div {
        display: flex;
        flex-direction: column;

        &:nth-child(2) {
          flex-direction: row;
          align-items: flex-end;
          justify-content: flex-end;

          .btn {
            //margin-top: 2rem;
            margin-left: 0;
            margin-right: 0;
            float: none;
          }

          .detail-price-new {
            top: -0.3125rem;

            .price {
              &[dir="ltr"] {
                font-family: @headings-font-family;
              }
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  .media-cta-text {
    display: flex;
    align-items: center;
    position: relative;
    height: auto;
    padding-top: 1.875rem;
    background-color: transparent;
    padding-bottom: 1.875rem;

    .h2,
    .h3,
    .h4,
    h2,
    h3,
    h4 {
      margin: 0 0 1.875rem;
    }
  }

  .media-cta-photo {
    background-color: @white;
    //height: 64vw;
    overflow: hidden;

    > .simple-image {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;

      > img {
        max-width: 100% !important;
        max-height: 90% !important;
        height: auto !important;
        width: auto !important;
        margin: 0 !important;
        padding: 0 !important;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      > img {
        left: 0;
        transform: translate(0, -50%);
      }

      > img.cover-left {
        left: auto;
        right: 0;
      }
    }

    > .cover {
      display: block;
      position: relative;
      top: 0;
      left: 0;

      img.cover {
        max-height: inherit !important;
        max-width: inherit !important;
        padding: 0 !important;
      }
    }

    &.rect-responsive {
      //display: block;
    }

    img {
      //max-height: 90%;
      //max-width: 90%;
    }

    .video-container {
      position: absolute;
    }

    .video-container {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }

    video {
      position: absolute;
      z-index: 0;
      left: 0;
    }

    video.fillIt,
    video.fillItW {
      width: 100%;
      height: auto;
    }

    video.fillItH {
      height: 100%;
      width: auto;
    }

    .video-controls {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        height: 3.75rem;
        width: 3.75rem;
      }
    }
  }

  .h3,
  h3 {
    font-size: 1.25rem;
    margin-top: 0;
    margin-bottom: 1.7rem;
    //padding-top: 3.25rem;
    padding-top: 0;
    //text-align: left;
  }

  .h4,
  h4 {
    //margin-top: 2rem;
    margin-top: 0;
    margin-bottom: 1.7rem;
    padding-top: 0;

    //text-align: left;
    @media (max-width: 768px){
      letter-spacing: normal;
      font-size: 1.15rem;
      margin-bottom: 1.2rem;
    }
  }

  .btn {
    margin-top: 1.2rem;
    margin-left: auto;
    margin-right: auto;
  }

  .form-submit .btn {
    margin-top: 0;
    padding: 0;
  }

  .media-cta-caption {
    > div {
      > div {
        &:nth-child(2) {
          .btn {
            //margin-top: 2rem;
            margin-left: 0;
            //margin-right: auto;
            margin-right: 0;
          }
        }
      }
    }
  }

  .btn-accordion {
    padding-left: 0;
    border: none;
    text-align: left;
    background-color: transparent;

    svg {
      height: 11px;
      width: 7px;
      margin-left: 23px;
      transform: rotate(90deg);
      transform-origin: center center;
      overflow: visible;
      .transition();

      use {
        -moz-transform: rotate(270deg);
      }
    }

    &:active,
    &:focus,
    &:hover {
      border: none;
      background-color: transparent;
      color: @custom-gray;
    }
  }

  .media-cta-caption {
    > div {
      //text-align: left;
      p.media-cta-icon {
        padding-left: 2.5rem;
        position: relative;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 1rem;
        padding-bottom: 0;
        min-height: 1.375rem;

        &:last-child {
          padding-bottom: 1rem;
        }

        svg {
          position: absolute;
          left: 0;
          fill: @almost-black;
          width: 1.375rem;
          height: 1.375rem;
        }
      }
    }
  }

  .more-details-triangle {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0%) rotate(180deg);
    width: 40px;
    height: 16px;
    fill: @light-gray;
    z-index: 1;
    display: none;
  }

  .media-cta-details {
    background-color: @white;
    max-height: none;
    padding: 0;
    display: block;
    flex-basis: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
    min-height: 0;
    transition: all .8s ease-in-out;

    &.slideDown {
      opacity: 1;
      transition: all .8s ease;
      min-height: auto;
    }

    > .row {
      padding-top: 2.175rem;
      padding-bottom: 1.9rem;
    }

    .detailsBox-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      > div {
        flex-basis: 100%;
      }
    }

    .h5,
    h5 {
      color: @main-green;
      margin-bottom: .2rem;
      margin-top: 1.325rem;
    }

    p,
    ul {
      color: @almost-black;
      font-size: .875rem;
    }

    .close-btn-wrap {
      .btn {
        margin-top: 2.7rem;
      }
    }
  }

  &.media-cta-open {
    .more-details-triangle {
      display: block;
    }

    .btn-accordion {
      svg {
        transform: rotate(-90deg);
      }
    }
  }

  .detail-price-info {
    display: inline-flex;
    padding-left: 3.125rem;
  }

  .detail-price-new {
    font-size: 2.5rem;
    position: relative;
    //display: inline-block;
    font-family: @headings-font-family;
    //font-weight: 600;
    line-height: .1rem;
    text-align: left;
    //padding-top: 0.9375rem;
    //padding-bottom: 1rem;
    color: @almost-black;

    .price-valute {
      font-size: .875rem;
      display: inline-block;
      line-height: .65rem;
    }

    .price-timing {
      font-size: 1rem;
      line-height: 3rem;
    }
  }

  .btn-arrow-only {
    display: block;
    //float: right;
    padding-top: 1.6rem;

    svg {
      stroke: @almost-black;
      transform: rotate(180deg);
      width: 1.5625rem;
      height: .625rem;

      use {
        -moz-transform: translate(0px) rotate(-180deg);
      }
    }
  }

  .form-wrapper {
    padding-top: 3.625rem;
  }

  .media-cta-text {
    h2 {
      //text-align: left;
    }
  }

  p,
  ul {
    color: @warm-gray;
    margin-bottom: 0;
    //text-align: left;
    @media (max-width: 768px){
      line-height: 20px;
    }
  }

  ul {
    padding-left: 0;
    font-size: .875rem;
    list-style-position: inside;

    &.list {
      list-style: initial;
      padding-left: 1.2rem;
    }
  }

  p + p {
    margin-top: 1.2rem;
  }

  li+li {
    margin-top: .55rem;
  }

}

.col-two {
  -webkit-column-count: 2;
  /* Chrome, Safari, Opera */
  -moz-column-count: 2;
  /* Firefox */
  column-count: 2;
}
/* style media-cta-full-bleed with <img> tag */

.media-bg-cover {
  width: 100%;
  overflow: hidden;
  min-height: 465px;
  position: relative;

  img + .media-cta-bleed {
    min-height: 465px;
  }

  .body-standard {
    color: @white;

    p,
    ul li {
      color: @white;
    }
  }

  img {
    min-width: 100%;
    position: absolute;
    min-height: 465px;
    height: 100%;
  }
}

/* -----------------------------------------
02 - sm viewport
----------------------------------------- */
@media (min-width: @screen-sm-min) {
  .media-cta-section {
    &.two-col {
      .media-cta-wrapper {
        .body-standard {
          padding: 42px 24px 0 12px;
          //text-align: left;
        }
      }
    }
  }
  .tab-pane {
    .media-cta-wrapper {
      .body-standard {
       // padding: 36px 42px;
        //text-align: left;
        //.learn-more-link {
        //  left: auto;
        //  bottom: 0;
        //}
      }
    }
  }
  .media-cta-wrapper {
    &.col-reverse {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    }

    & > div {
      //height: 250px;
      min-height: 250px;
    }

    .media-cta-caption {
      height: auto;
      min-height: auto;
    }

    .body-standard {
      padding: 1.5rem;
      //text-align: left;
    }

    .h3,
    .h4,
    h3,
    h4 {
      font-size: 1.5rem;
      margin-top: 0;
      margin-bottom: 2.1rem;
      //padding-top: 2rem;
      //text-align: left;
    }

    p,
    ul {
      font-size: .875rem;
    }

    .detail-price-info {
      padding-left: 2.84375rem;
    }

    .detail-price-new {
      font-size: 2.5rem;
      //padding-top: 0.5rem;
      .price-valute {
        display: inline-block;
        line-height: .65rem;
      }
    }

    .btn-accordion {
      padding-top: 1rem;
      padding-right: .25rem;
    }

    .media-cta-details {
      .detailsBox-container {
        > div {
          flex-basis: 45%;
        }
      }
    }

  }

  .media-cta-wrapper .detail-price-new .price-timing {
    line-height: 3rem;
  }
}
/* -----------------------------------------
03 - md viewport
----------------------------------------- */
@media (min-width: @screen-md-min) {
  .media-cta-wrapper {
    &.media-cta-bleed {
      .label-tag {
        padding: 2.1875rem;
        font-size: 1.5625rem;

        .detail-price-new {
          .main-part {
            .price {
              font-size: 2.5rem;
            }
          }
        }
      }
    }

    &.col-reverse {
      .media-cta-caption {
        margin-right: 8.33333333%;
      }
    }

    .form-wrapper {
      padding-top: 1rem;
    }

    .floating-label-select {
      margin-bottom: 1.5rem;
    }

    & > div,
    .media-cta-caption {
      //max-height: 250px;
    }

    //.media-cta-caption {
    //  max-height: 37vw;
    //  height: 29rem;
    //  min-height: 29rem;
    //}
    .detail-price-info {
      flex: 1;
    }

    p {
      padding-bottom: 1.4rem;
    }

    p + p {
      margin-top: 0;
    }

    .h3,
    .h4,
    h3,
    h4 {}

    .h4,
    h4 {
      padding-bottom: 0;
    }

    .h3 {
      padding-bottom: .5rem;
    }

    .custom-sm-layout {
      display: inherit;

      .btn {
        float: none;
      }
    }

    .btn {
      //margin-top: 2.9375rem;
    }

    .media-cta-details {
      .detailsBox-container {
        > div {
          flex-basis: 25%;
        }
      }
    }
  }
}
/* -----------------------------------------
04 - lg viewport
----------------------------------------- */
@media (min-width: @screen-lg-min) {
  .media-cta-wrapper {
    & > div.media-cta-caption,
    &.media-cta-w & > div {
      max-height: 250px;
    }

    &.cta-big-varint {
      .media-cta-caption,
      .media-cta-photo {
        //min-height: 250px; this will hide cta image on big desktip
        height: auto;
        .cover{
          position: absolute;
        }
      }
    }

    p {
      margin-bottom: .6rem;
      font-size: 1rem;
    }

    .h3,
    .h4,
    h3,
    h4 {
      font-size: 26px;
      margin-bottom: 1.7rem;
      color: @almost-black;
    }

    .h3,
    .h4,
    h4 {
      margin-top: 0;
    }

    .btn {
      //margin-top: 2.9375rem;
    }

    .detail-price-new {
      font-size: 3.125rem;
    }

    .media-cta-details {
      padding-top: 0;
      padding-bottom: 0;

      &.slideDown {
        height: auto;
        transition: all .8s ease-in-out;
        min-height: 250px;
      }

      p {
        font-size: 1rem;
      }

      .close-btn-wrap {
        .btn {
          margin-top: 1.1rem;
        }
      }
    }

    .btn-arrow-only {
      //padding-top: 3.4rem;
    }

    &.media-cta-bleed {
      .media-cta-photo {
        position: static;
      }

      &.media-cta-text-wh {
        .media-cta-text {
          .body-standard {
            color: @white;

            p {
              color: @white;
            }

            ul {
              color: @white;
            }

            a {
              color: @white;
              text-decoration: underline;

              &.btn {
                color: @white;
                border-color: @white;
                text-decoration: none;
              }
            }

            .detail-price-new {
              color: @white;
            }
          }
        }
      }

      .label-tag {
        .detail-price-new {
          .main-part {
            .price {
              font-size: 3.375rem;
            }
          }
        }
      }
    }
  }

  .media-cta-wrapper-custom {
    & > div {
      height: 40rem;
    }
  }

  .media-cta-wrapper .detail-price-new .price-timing {
    line-height: 3.4rem;
  }
}
/* -----------------------------------------
05 - Arabic styles
----------------------------------------- */
*[lang="ar"] {
  *[dir="rtl"] {
    .benefits-section .media-cta-container div.cover {
      border-radius: 0 4px 4px 0;
    }

    .pull-right-ar {
      float: right !important;
    }

    .pull-left-ar {
      float: left !important;
    }

    .text-right {
      text-align: left;
    }

    .text-left {
      text-align: right;
    }

    .media-cta-wrapper {
      .btn-arrow-only svg {
        transform: rotate(0deg);
      }

      .body-standard {
        //text-align: right;
      }

      .custom-sm-layout {
        text-align: right;
      }

      .media-cta-caption {
        > div {
          > div {
            &:nth-child(2) {
              .btn {
                margin-left: 0;
                margin-right: 0;

                svg {
                  margin-left: .25rem;
                }
              }
            }
          }
        }
      }

      .h2,
      .h3,
      .h4,
      h2,
      h3,
      h4 {
        //text-align: right;
      }

      p,
      ul {
        //text-align: right;
      }

      ul {
        padding-right: 0;
        padding-left: auto;
      }

      .btn-accordion {
        padding-right: 0;

        svg {
          margin-right: 23px;
        }
      }

      .media-cta-details {
        p {
          //text-align: right;
        }
      }

      .detail-price-info {
        padding-left: 0;
        padding-right: 3.125rem;

        .detail-price-new {
          text-align: right;
        }
      }

      .media-cta-caption {
        > div {
          p.media-cta-icon {
            padding-left: 0;
            padding-right: 2.5rem;

            svg {
              left: inherit;
              right: 0;
            }
          }
        }
      }

      .media-cta-text {
        h2 {
          //text-align: right;
        }
      }

      &.media-cta-bleed {
        .label-tag {
          right: 0;
          left: auto;
          font-family: @font-arabic;

          .detail-info-wrap-pricetag .main-part .price {
            font-family: @font-arabic;
          }
        }

        &.col-reverse {
          .label-tag {
            left: 0;
            right: auto;
          }
        }
      }
    }
    @media (min-width: @screen-sm-min) {
      .custom-sm-layout {
        text-align: right;
      }

      .detail-price-info {
        padding-right: 2.84375rem;
      }
    }
  }
}
/* -----------------------------------------
06 - Business overrides
----------------------------------------- */
.business {
  .media-cta-container {
    background-color: @light-sand-brown;
  }

  .media-cta-wrapper {
    .media-cta-caption {
      .btn.btn-default {
        color: inherit;
        background-color: inherit;
        border-color: inherit;

        &:hover:not(.btn-accordion) {
          color: @white;
          background-color: @dark-green;
          border-color: @dark-green;
        }
      }
    }
  }
}
/* -----------------------------------------
07 - background colors
----------------------------------------- */
.media-cta-container {
  background-color: @sand-white;

  &.media-cta-container-wh {
    background-color: @white;
  }

  &.media-cta-container-gr {
    background-color: @light-sand-brown;
  }
}

.benefits-section-mobile .panel-heading {
  position: relative;
  display: block;
  overflow: hidden;
  line-height: 2.5;
  font-size: 14px;
  color: #6b6b6b;
  font-weight: 900;
  padding: 14px 0;
  background: transparent;
  border: 0;
  box-shadow: none;
}

.benefits-section-mobile .panel-heading a {
  display: block;
  vertical-align: middle;
  overflow: hidden;
  line-height: 2;

  span.pull-left{
    display: inline-block;
    float: none!important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
  }
}

.benefits-section-mobile .panel-group .panel {
  border: 0;
  background: transparent;
  box-shadow: none;
}

.benefits-section-mobile .panel-heading .icon {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  float: left;
}

*[lang='ar'] *[dir='rtl'] .benefits-section-mobile .panel-heading .icon {
  margin-right: auto;
  margin-left: 10px;
}

.benefits-section-mobile .panel-heading .icon circle,
.benefits-section-mobile .panel-heading .icon path,
.benefits-section-mobile .panel-heading .icon polygon,
.benefits-section-mobile .panel-heading .icon polyline {
  stroke: #719e19;
  stroke-width: 2px;
}

.benefits-section-mobile .panel-open .panel-heading .view-more:before {
  border: solid #719e19;
  border-width: 0 1px 1px 0;
  -o-transform: translateY(-50%) rotate(-135deg);
  transform: translateY(-50%) rotate(-135deg);
  -webkit-transform: translateY(-50%) rotate(-135deg);
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.benefits-section-mobile .panel-open .panel-heading {

  color: #719e19;
  font-weight: 500;
}

.benefits-section-mobile .panel-heading .icon circle,
.benefits-section-mobile .panel-heading .icon path,
.benefits-section-mobile .panel-heading .icon polygon,
.benefits-section-mobile .panel-heading .icon polyline {
  stroke: #6b6b6b;
  stroke-width: 1px;
}

.benefits-section-mobile .panel-heading .pull-left+.pull-left {
  margin-top: 5px;
}

.benefits-section-mobile .panel-heading .view-more:before {
  -o-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  -webkit-transform: translateY(-50%) rotate(45deg);
  border: solid #6b6b6b;
  border-width: 0 1px 1px 0;
  content: '';
  padding: 5px;
  right: 3px;
  position: absolute;
  line-height: 1;
  top: 50%;
}

*[lang='ar'] *[dir='rtl'] .benefits-section-mobile .panel-heading .view-more:before {
  right: auto;
  left: 3px;
}

.benefits-section .tab-pane {
  overflow: hidden;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
}

.benefits-section .features-wrapper .features-list {
  z-index: 1;
  position: relative;
  border: 0;
}

.benefits-section .features-wrapper .features-list li {
  padding: 1.125rem 0;
  color: #6b6b6b;
  display: block;
  float: none;
}

.benefits-section .features-wrapper .features-list li > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  border: 0;
  color: #6b6b6b;
  background-color: transparent;
  padding: 0;
}

.benefits-section .features-wrapper .features-list li > a p {

  font-size: 1.125rem;
  line-height: 1.125rem;
  text-align: center;
  margin: auto 0;
}

.benefits-section .features-wrapper .features-list li > a .icon {
  float: left;
  height: 30px;
  width: 32px;
  margin-top: -5px;
  margin-right: 30px;
  fill: inherit;
}

.benefits-section .features-wrapper .features-list li:first-child {
  padding-top: 0;
}

.benefits-section .features-wrapper .features-list li:last-child {
  padding-bottom: 0;
}

.benefits-section .features-wrapper .features-list li.active {
  color: #719e19;
  font-weight: 900;
}

.benefits-section .features-wrapper .features-list li.active svg {}

.benefits-section .features-wrapper .features-list li.active > a {
  color: #719e19;
}

.benefits-section .features-wrapper .features-list li.active > a p {
  color: #719e19;
  position: relative;
}

.benefits-section .features-wrapper .features-list li.active > a .icon {
  color: #719e19;
  font-weight: 900;
}

.benefits-section .features-wrapper .features-list li.active > a .icon circle,
.benefits-section .features-wrapper .features-list li.active > a .icon path,
.benefits-section .features-wrapper .features-list li.active > a .icon polygon,
.benefits-section .features-wrapper .features-list li.active > a .icon polyline {
  stroke: #719e19;
  stroke-width: 2px;
}

.benefits-section .img-with-disc {
  background: #fff;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  margin-top: 1.25rem;
}

.benefits-section .img-with-disc .title-wrapper {
  margin-bottom: 2.125rem;
}

.benefits-section .img-with-disc .title-wrapper .title h3 {
  width: 100%;
  font-size: 1.625rem;
  line-height: 1.15;
}

.benefits-section .img-with-disc .media-left {
  padding-right: 1.5rem;
}

.benefits-section .img-with-disc .media-left .media-object {
  height: 428px;
}

.benefits-section .img-with-disc .media-body {
  padding: 2.75rem 1.5rem 2.75rem 0;
  position: relative;
}

.benefits-section .img-with-disc .media-body .discription {
  overflow: hidden;
  position: relative;
}

.benefits-section.benefits-section-mobile .img-with-disc .media-body .discription p {
  margin-bottom: 1.875rem;
  color: #6b6b6b;
  font-size: .875rem;
  line-height: 1.2rem;
  font-family: @font-family-base;
}

.benefits-section.benefits-section-mobile .img-with-disc .media-body .discription p:last-child {
  margin: 0;
}

.benefits-section.benefits-section-mobile .img-with-disc .media-body {
  padding: 2.75rem 1.5rem;
  position: relative;
}

#benefitsdesktop,
#benefitsmobile {
  display: none;
}
@media only screen and (min-width: 769px) {
  #benefitsdesktop {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  #benefitsmobile {
    display: block !important;
  }

  .benefits-section-mobile .media-left,
  .benefits-section-mobile .media-right {
    display: block;
    width: auto;
    height: 200px;
    overflow: hidden;
  }

  .benefits-section-mobile .media-body {
    display: block;
    width: auto;
  }

  .benefits-section {
    .tab-pane {
      &.collapsing,
      &.in {
        display: block;
      }

    }
  }
}

.benefits-section-mobile .fixed-at-bottom {
  bottom: 0;
}

.benefits-section-mobile .panel-body {
  padding: 0;
}

.benefits-section-mobile .panel-group .panel-heading+.panel-collapse > .list-group,
.benefits-section-mobile .panel-group .panel-heading+.panel-collapse > .panel-body {
  border: 0;
}

// read more over popover
.popover-content {
  padding: 0;
  p {
    padding-bottom: 0;
  }

  .popover-dots {
    background: transparent;
    border: 0;
  }
}
.popover {
  &.in {
    padding: 14px;
  }
}
