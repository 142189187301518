// .form .radio input {
//   position: absolute;
// }
//
// #recover-captcha {
//   margin: 0;
// }
//Hover Login CSS Start
.main-menu-selfcare {
  @media (min-width: @screen-sm-min) {
    padding-bottom: 0;
  }
  .menu-order {
    &.login-menu {
      .sub-account-menu-wrap {
        .login-order {
          .forgot2.forget2-menu {
            margin:9px 0 0;
          }
        }
        .form-section {
          width: 100%;
        }
        .one-time-pswd {
          .form-section{
             margin-bottom: 1rem;
             .alert_wrapper.login-error {
                  margin: 0;
                  line-height: 1rem;
                  height: 1rem;
                  text-align: left;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  font-size: 14px;
                  text-transform: initial;
                  font-weight: 400;
                  margin-top: 7px;
              }
          }
        }
      }
      .login-order {
        //width: 480px;
        @media (min-width: @screen-sm-min) {
          width: 100%;
        }
        @media (min-width: @screen-md-min) {
          width: 768px;
        }
        // @media (min-width: 1199px) {
        //   width: 480px;
        // }
        .padd-1 {
          &.col-lg-6 {
            @media (min-width: 1280px) {
              width: 50%;
            }
          }
        }
        h5 {
          letter-spacing: normal;
          margin-bottom: 0px;
          font-size: 1.25rem;
          @media (min-width: @screen-md-min) {

          }
          @media (min-width: 1199px) {

          }
        }
        a {
          text-decoration: none;
          color: @almost-black;
          &:hover {
            color:#fff !important;
          }
          @media (max-width: 1199px) {
            letter-spacing: 1px;
          }
        }
        .forget1 {
          a {
            text-align: left;
          }
          &.text-right {
            a {
              text-align: right;
            }
          }
        }
        .forget2 {
          border-bottom: 0px solid #909192;
          margin: 26px 0;
          &.forget2-menu {
            margin: 26px 0px 0px;
            border-top: 0px solid #d1d2d1;
            padding-bottom: 27px;
            width: 100%;
          }
        }
        p.body-standard {
          font-size: 1rem;
          margin-top: 16px;
          margin-bottom: 8.65rem;
          line-height: 22px;
          @media (max-width: 1199px) {

          }
          @media (max-width: 992px) {

          }
          @media (max-width: 767px) {

          }
        }
        .btn.btn-default {
          min-width: 250px;
          font-size: 0.9rem;
          height: 4rem;
        }
        .forms-default-settings {
          .form.modified-form {
            .form-group {
              .horizontal-checkboxes.form-section {
                margin-top: 5px!important;
                margin-bottom: 25px;
              }
            }
          }
        }
      }
      &.main-menu {
        .navbar {
          .navbar-header {
            @media (min-width: 1280px){
              -webkit-box-pack: inherit;
              -ms-flex-pack: inherit;
              justify-content: left;
            }
            .navbar-right {
              .account-link {
                position: relative;
                padding: 0px;
                &:hover {
                  background: none;
                  >a {
                    color: @main-green;
                    &:hover {
                      color: @main-green;
                    }
                  }
                  .sub-account-menu-wrap {
                    @media (min-width: @screen-sm-min){
                      /*display: block;*/
                    }
                  }
                }
                >a {
                  &:hover {
                    color: @main-green;
                  }
                }
                &.account-link-after {
                  > a {
                    padding: 0 1rem;
                    border-right: 0;
                    position: relative;
                    &:after {
                      content: "";
                      height: 39px;
                      position: absolute;
                      right: 0;
                      width: 1px;
                      background: @light-gray-2;
                      top: 28px;
                    }
                    p#accountName {
                      margin:0;
                    }
                  }
                }
              }
              .sub-account-menu-wrap {
                @media (min-width: @screen-sm-min){
                  display: none;
                }
              }
            }
            &.menu-right {
              justify-content: right;
            }
          }
        }
      }
    }
  }
}
//Login Page CSS Start
// .login-page-section {
//   background: @light-sand-brown;
//   .login-order {
//     box-shadow: 0 0 0;
//     .padd-1 {
//       padding-left: 3.5rem;
//       padding-right: 3.5rem;
//       @media (max-width: 1199px) {
//         padding-left: 35px;
//         padding-right: 35px;
//       }
//       @media (max-width: 767px) {
//         padding-left: 25px;
//         padding-right: 25px;
//         padding-top: 25px;
//       }
//     }
//     h5 {
//       letter-spacing: normal;
//       margin-bottom: 0px;
//       font-size: 1.62rem;
//       @media (max-width: 992px) {
//         font-size: 1.12rem;
//         margin-top: 10px;
//       }
//       @media (max-width: 767px) {
//         margin-top: 0;
//       }
//     }
//     a {
//       text-decoration: none;
//       letter-spacing: 2px;
//       &:hover {
//         color: #719e19;
//         text-decoration: underline;
//       }
//     }
//     .form.contact-form.modified-form {
//       padding-bottom: 0;
//       @media (max-width: 767px) {
//         padding-bottom: 20px;
//       }
//       .form-section {
//         width: 100%;
//       }
//     }
//     .forget2 {
//       border-bottom: 0px solid #909192;
//
//       &.forget2-page {
//         margin: 25px 0 0;
//         padding-bottom: 50px;
//         @media (max-width: 1199px) {
//
//         }
//         @media (max-width: 992px) {
//           padding-bottom: 46px;
//           margin-top: 55px;
//         }
//         @media (max-width: 767px) {
//           padding-bottom: 30px;
//           margin-top: 25px;
//         }
//       }
//     }
//     p.body-standard {
//       font-size: 1rem;
//       margin-bottom: 7.3rem;
//       min-height: 86px;
//       line-height: 22px;
//       margin-top: 1rem;
//       @media (max-width: 767px) {
//         margin-bottom: 14px;
//         min-height: 10px;
//         font-size: 1rem;
//         margin-top: 10px;
//       }
//     }
//     .btn.btn-default {
//       min-width: 250px;
//       font-size: 0.9rem;
//       height: 4rem;
//       @media (max-width: 992px) {
//         width: 100%;
//       }
//     }
//     .radio {
//       label {
//         &.floating-label {
//           color: @gray-fill;
//         }
//       }
//     }
//     @media (max-width: 992px) {
//       .forget1 {
//         width: 100%;
//         text-align: center;
//         margin: 20px 0 0;
//         &.forget1-pass {
//           margin: 10px 0 0;
//         }
//       }
//       .forget2 {
//         width: 100%;
//         text-align: center;
//       }
//     }
//     @media (max-width: 767px) {
//       .forget1 {
//         width: 100%;
//         text-align: center;
//         margin: 20px 0 0;
//       }
//       .forget2 {
//         margin: 18px 0 0;
//       }
//     }
//     .forms-default-settings {
//       .form.modified-form {
//         .form-group {
//           .horizontal-checkboxes.form-section {
//             margin-top: 5px!important;
//             margin-bottom: 25px;
//           }
//         }
//       }
//     }
//   }
//   .login-blocked {
//     h5 {
//       margin-bottom: 25px !important;
//     }
//     a.btn {
//       &:hover {
//         color: @white;
//       }
//     }
//     &>div.text-center {
//       min-height: 238px;
//       @media (max-width: 767px) {
//         min-height: 62px;
//       }
//     }
//   }
//   &.login-blocked-page-section {
//     .login-order {
//       p {
//         &.body-standard {
//           margin-bottom: 9rem;
//           @media (max-width: 788px) {
//             margin-bottom: 9rem;
//           }
//           @media (max-width: 767px) {
//             margin-bottom: 14px;
//           }
//         }
//       }
//       .forget2 {
//         &.forget2-page {
//           margin-bottom: 24px !important;
//           padding-bottom: 20px !important;
//           margin-top: 24px !important;
//         }
//       }
//     }
//   }
// }
// .gray-bg-light {
//   background: @sand-white;
//   .padd-1 {
//     padding-top: 30px;
//   }
//   .box-border {
//     border-left: 1px solid @light-sand-brown;
//     @media (max-width: 767px) {
//       border-left: 0;
//       border-top: 1px solid @light-sand-brown;
//     }
//   }
// }
//Login Blocked CSS Start
// .login-blocked {
//   @media (max-width: 767px) {
//     padding-bottom: 15px;
//   }
//   .locked-cross {
//     padding: 20px 0 10px;
//     text-align: center;
//     .icon-error-login {
//       img {
//         max-width: 162px;
//       }
//     }
//   }
//   p {
//     margin-bottom: 2rem;
//     color: @steel-grey;
//     line-height: 19px;
//     &.blocked-p2 {
//       height: 38px;
//     }
//   }
//   p.text-danger {
//     color: @red-dark;
//     margin-bottom: 8px;
//     font-family: Neotech,Roboto,Arial,sans-serif;
//     font-size: 1rem;
//     line-height: 1.25rem;
//     margin-top: 1.5rem;
//   }
//   .btn.btn-danger {
//     background: @red-dark;
//     border: 0;
//     box-shadow: 0 0 0;
//     font-size: 0.9rem;
//     height: 4rem;
//     line-height: 4.2rem;
//     &:hover {
//       color:#fff !important;
//     }
//
//     @media (max-width: 992px) {
//       width: 100%;
//     }
//   }
// }
//Login Touch Devices CSS Start
div.sub_menu_mobile {
  .arrow_accordion {
    svg {
      fill: @main-green;
    }
  }
  .menu_account {
   .space_white_menu {
     @media (max-width: 992px) {
       padding-top: 3.25rem;
     }
   }
   .bg_white_menu {
    .link-black_menu {
      //margin-left: 6.4rem;
      margin-left: 14.333333%;
      margin-bottom: 2rem;
      @media (min-width: @screen-sm-min) {
        margin-bottom: 2rem;
      }
      &:last-child {
        margin-bottom: 2rem;
      }
      @media (max-width: 992px) {
        margin-left: 8.333333%;
      }
      &.link-has-menu {
        position: relative;
        color: @main-green;
        .svg-ico {
          display: none;
          &.svg-ico-active {
            display: inline-block;
          }
        }
        .arrow_accordion {
          position: absolute;
          right: 35px;
          width: 23px;
          height: 13px;
          margin-top: -7px;
          @media (max-width: 767px) {
            right: 25px;
          }
          svg {
            width: 23px;
            height: 13px;
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
            transition: all .2s ease-in-out;
            fill: @main-green;
          }
        }
        &.collapsed {
          color: @black;
          .svg-ico {
            display: inline-block;
            &.svg-ico-active {
              display: none;
            }
          }
          .arrow_accordion {
            svg {
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
              fill: @main-green;
            }
          }
        }
      }
    }
  }
}
}

#login-menu-inner {
  border-top: 1px solid @light-sand-brown;
  border-bottom: 1px solid @light-sand-brown;
  margin-bottom: 20px;
  margin-top: 0;
  .login-sub {
    &.login-sub-mob {
      &.new-login-sub-mob {
        .padd-1 {
          border-bottom: 1px solid #E2E1DA;
          padding-top: 40px !important;
          padding-bottom: 40px !important;
          &.gray-bg-light {
            border-bottom: 0px;
          }
        }
      }
      width: 100%;
      @media (max-width: 992px) {
        width: 100%;
      }
      .padd-1 {
        padding: 3rem 6rem 0;
        height: auto;
        @media (max-width: 992px) {
          padding: 1.8rem 2rem 0 4rem;
        }
        @media (max-width: 767px) {
          padding: 1.8rem 2rem 0;
        }
        &.login-1 {
          padding-bottom: 25px;
        }
        &.login-2 {
          border-top: 1px solid @light-sand-brown;
          @media (min-width: @screen-md-min) {
            padding-bottom: 2rem !important;
          }
          @media (max-width: 992px) {
            border-top: 0;
            padding: 1.8rem 3rem 0 !important;
            padding-top: 1.8rem !important;
          }
          @media (max-width: 767px) {
            border-top: 0;
            padding: 1.8rem 2rem !important;
            padding-top: 1.8rem !important;
          }
        }

      }
      background: @sand-white;
      .login-order {
        box-shadow: 0 0 0;
        h5 {
          font-size: 1.12rem;
          margin-top: 15px;
          @media (min-width: @screen-md-min) {
            font-size: 1.3rem;
            text-align: left;
          }
        }
        p.body-standard {
          margin-bottom: 25px;
          font-size: 1rem;
          @media (max-width: 992px) {
            margin-bottom: 6.3rem;
            min-height: 100px;
            line-height: 18px;
            margin-top: 15px;
          }
          @media (max-width: 767px) {
            margin-bottom: 25px;
            min-height: 10px;
            line-height: 22px;
            font-size: 1rem;
          }
          @media (min-width: @screen-md-min) {
            text-align: left;
            margin-top: 16px;
          }
        }
        .forget1 {
          @media (max-width: 441px) {
            width: 100%;
            text-align: center;
            margin: 7px 0px 7px 0px;
          }
          @media (min-width: @screen-md-min) {
            padding: 0 0px 0 20px;
          }
          &.text-right {
            @media (min-width: @screen-md-min) {
              padding: 0 20px 0 0px;
            }
          }

          a {
            text-decoration:  none;
          }
          &:first-child {
            margin: 26px 0 0;
          }
        }
        .forget2 {
          margin: 26px 0px 0px;
          border-top: 0px solid #d1d2d1;
          padding-bottom: 27px;
          width: 100%;
          margin-bottom: 0;
          border-bottom: 0;
          @media (max-width: 992px) {
            margin: 57px 0px 0px;
            padding-bottom: 5px;
          }
          @media (max-width: 767px){
            margin: 20px 0px 0px;
          }
          a {
            text-decoration:  none;
          }
        }
        .box-border {
          border-left: 1px solid @light-sand-brown;
          @media (max-width: 767px) {
            border-left: 0;
            border-top: 1px solid @light-sand-brown;
          }
        }
        .btn.btn-default {
          font-size: 0.9rem;
          height: 4rem;
          @media (max-width: 1024px) {
            font-size: 0.8rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }
        }
        .radio {
          label {
            &.floating-label {
              color: @gray-fill;
            }
          }
        }
        .login-blocked {
          h5 {
            margin-bottom: 25px !important;
          }
        }
      }
    }
    .form {
      .form-section {
        width: 100%;
      }
    }
  }
}
.main-menu {
  &.main-menu__fixed {
    .navbar {
      .hamburger_icon {
        width: @menu-height-slim;
      }
    }
  }
  .navbar {
    .hamburger_icon {
      width: @menu-height-phone-default;
      @media (min-width: @screen-sm-min) {
        width: @menu-height-default;
      }
      .close-menu-cross {
        display: none;
      }
      &.open {
        .close-menu-cross {
          display: block;
        }
        > span {
          display: none !important;
        }
      }
    }
  }
}

.sub_menu_mobile {
  background: rgba(0,0,0,0.3);
  &.sub_menu_mobile_selfcare {
   .top-nav {
     position: relative;
   }
 }
}
.login-order {
  #login-form {
    .form {
      .floating-label-input.has-error-fields {
        .alert-label.has-error {
          padding-top: 5px;
          height: auto;
        }
      }
    }
    // .icon-error {
      //   display: none;
      // }
    }
  }
  #store_locator_mobile_link {
    &.sub_menu_mobile {
      &.sub_menu_mobile_new-1 {
        .menu_account {

          .bg_white_menu {
            .link-black_menu {
              margin-left: 30px;
              font-size: 12px;
              line-height: 15px;
              @media (min-width: @screen-sm-min) {
                margin-left: 96px;
                font-size: 16px;
                line-height: 19px;
              }
              color: @almost-black;
              &.link-has-menu {
                color: @main-green;
                &.collapsed {
                  color: @almost-black;
                }
              }

              span {
                //color: @almost-black;
              }
              > svg {
                margin-right: 22px;
                width: 22px;
                @media (min-width: @screen-sm-min) {
                  width: 28px;
                }
              }
              .arrow_accordion {
                svg {
                  width: 21px;
                  height: 10px;
                }
              }
            }
          }
        }
        #login-menu-inner {
          .login-sub {
            &.login-sub-mob {
              ul {
                &.menu-inner-list {
                  list-style: none;
                  padding: 0;
                  margin:0;
                  background: @sand-white-50;
                  //border-top:  1px solid @sand-white;
                  li {
                    border-top:  1px solid @light-sand-brown;
                    &:first-child {
                      border-top: 0;
                    }
                    a {
                      font-size: 12px;
                      line-height: 15px;
                      display: block;
                      color: #353738;
                      text-transform: uppercase;
                      padding: 24px 0 24px 80px;
                      @media (min-width: @screen-sm-min) {
                        padding-left: 160px;
                        font-size: 16px;
                        line-height: 19px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        #accordion_menu {
          .panel-body {
            .col-sm-offset-3 {
              margin-left: 0;
            }
          }
          .panel-title {
            a {
              font-size: 20px;
              line-height: 24px;
              @media (min-width: @screen-sm-min) {
                font-size: 24px;
                line-height: 30px;
              }
            }
            padding-left: 30px;
            @media (min-width: @screen-sm-min) {
              padding-left: 96px;
            }
          }
          .sub_menu_accordion {
            .panel-title {
              padding-left: 50px;
              @media (min-width: @screen-sm-min) {
                padding-left: 120px;
              }
              a {
                font-size: 18px;
                line-height: 22px;
                @media (min-width: @screen-sm-min) {
                  font-size: 22px;
                  line-height: 28px;
                }
              }
            }
          }
        }
      }
    }
  }
  .top-nav {
    &.top-nav-new {
      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }
  #store_locator_mobile_link {
    &.sub_menu_mobile {
      &.sub_menu_mobile_new {
        .menu_account {
          .bg_white_menu {
            .link-black_menu {
              margin-left: 24px !important;
            }
          }
        }
        #accordion_menu {
          .panel-title {
            height: 56px;
            padding: 0 0 0 24px;
            @media (min-width: @screen-sm-min) {
              height: 68px;
            }
            > a {
              font-size: 16px;
              line-height: 20px;
              display: block;
              color: @almost-black;
              text-transform: uppercase;
            }
            .arrow_accordion {
              &.collapsed {
                height: 55px;
                width: 56px;
                @media (min-width: @screen-sm-min) {
                  height: 67px;
                  width: 68px;
                }
              }
              height: 56px;
              width: 56px;
              @media (min-width: @screen-sm-min) {
                height: 68px;
                width: 68px;
              }
            }
          }
          .panel-body {
            .sub_menu_accordion {
              .panel-title {
                height: 67px;
                a {
                  margin-left: 18px;
                  font-size: 16px;
                  line-height: 20px;
                  display: block;
                  color: @almost-black;
                  text-transform: uppercase;
                  @media (min-width: @screen-sm-min) {
                    margin-left: 24px;
                  }
                }
                .arrow_accordion {
                  height: 67px;
                  @media (min-width: @screen-sm-min) {
                    height: 67px;
                  }
                }
              }
            }
            .col-sm-offset-4 {
              margin-left: 66px;
              @media (min-width: @screen-sm-min) {
                margin-left: 72px;
              }
            }
          }
          .sub_menu_accordion {
            .item_sub-menu {
              font-size: 16px;
              line-height: 20px;
              display: block;
              color: @almost-black;
              padding: 12px 0;
            }
          }
        }
      }
    }
  }
  // mobile  menu login checkbox fix as per VD
#login-menu-inner .login-sub.login-sub-mob .login-order .radio label:before {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-top: -12px;
}

#login-menu-inner .login-sub.login-sub-mob .login-order .radio input[type=checkbox]:checked+label:before {
    background-image: url(../img/self-care-profile/icon_checkbox_radio_selected_dark_64.svg);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 28px 28px;
    border: 1px solid #719e19;
}
@media (min-width: 768px) and (max-width: 992px) {

  // tablet portrait
  #login-menu-inner .login-sub.login-sub-mob .padd-1.height-more {
      padding: 1.8rem 4rem 0 2rem;
      border-bottom: 0;
  }
  #login-menu-inner .login-sub.login-sub-mob.new-login-sub-mob .padd-1.loginSection {
      border-right: 1px solid #e2e1da;
      border-bottom: 0px solid #e2e1da;
  }
  #login-menu-inner .login-sub.login-sub-mob .padd-1.height-more p.body-standard {
      min-height: 9.5rem;
  }
  #login-menu-inner .login-sub.login-sub-mob .padd-1.height-more .forget2 {
      margin-top: 1.5rem;
  }
  //
}
.forms-default-settings .consumer-register-form .form.modified-form .form-section.horizontal-checkboxes .checkbox input[type=checkbox]+label:before, .forms-default-settings .consumer-register-form .form.modified-form .form-section.horizontal-checkboxes .radio input[type=checkbox]+label:before {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-top: -12px;
}
.forms-default-settings .consumer-register-form .form.modified-form .form-section.horizontal-checkboxes .checkbox input[type=checkbox]:checked+label:before, .forms-default-settings .consumer-register-form .form.modified-form .form-section.horizontal-checkboxes .radio input[type=checkbox]:checked+label:before {
    background-image: url(http://uat.etisalat.ae/b2c/assets/img/self-care-profile/icon_checkbox_radio_selected_dark_64.svg);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 28px 28px;
    border: 1px solid #719e19;
}
.login-sub .login-order.updated-login-dropdown .floating-label-input {
    padding-bottom: 0;
}
.forms-default-settings .consumer-register-form .form.modified-form .form-section .floating-label-input input {

    height: 70px;
    color: #848789;
    transition: .2s cubic-bezier(.4,0,.2,1);
    padding-bottom: 1px!important;
}
.forms-default-settings .consumer-register-form .form.modified-form .form-section.horizontal-checkboxes .radio:first-child {
    margin-top: 0;
    padding-top: 0;
    padding-left: 0;
}
.main-menu-selfcare .menu-order.login-menu .login-order.updated-login-dropdown .forget1 a,
.main-menu-selfcare .menu-order.login-menu .login-order.updated-login-dropdown .forget1 a:hover,
.main-menu-selfcare .menu-order.login-menu .login-order.updated-login-dropdown .send-again:hover {
    color: #353738!important;
    letter-spacing: 0;
    font-size: 13px;
}
  *[lang="ar"] {
    *[dir="rtl"] {
      @media (min-width: 768px) {
        .navbar-collapse {
          .navbar-left {
            float: right !important;
            li {
              float: right;
            }
          }
        }
      }
      .login-page-section,
      .login-order {
        a {
          font-family: Neotech-medium,GE SS Two,Arial,sans-serif !important;
        }
        .padd-1 {
          float: right;
        }
      }
      .main-menu-selfcare {
        .menu-order {
          &.login-menu {
            &.main-menu {
              .navbar {
                .main-menu-main-row {
                  .navbar-nav {
                    > li {
                      &:first-child {
                        a {
                          margin-left: 0;
                          margin-right: 0;
                        }
                      }
                    }
                  }
                }
                .navbar-header {
                  &.text-right {
                    max-width: 240px;
                  }
                  .navbar-right {
                    .account-link {
                      > a {
                        border-left: 1px solid #d8d8d8;
                        border-right: 0;
                        //font-size: 1.25rem;
                        padding: 0 20px;
                      }
                      .sub-account-menu-wrap {
                        right: auto!important;
                        left: 0!important;
                        .login-order {
                          right: auto;
                          left: 0;
                        }
                        &.sub-account-menu-wrap-list {
                          // right: 0 !important;
                          // left: auto !important;
                          .sub-account-menu {
                            padding-right: 0px;
                            li {
                              a {

                              }
                            }
                          }
                        }
                      }
                      &.account-link-after {
                       &>a {
                        border-left: 0;
                        &:after {
                          left: 0;
                          right: auto;
                        }
                      }
                    }
                  }
                  .search-link {
                   > a {
                     padding: 29px 20px;
                   }
                 }
               }
             }
           }
           &.main-menu__fixed {
             .navbar {
              	.navbar-header {
              		.navbar-right {
              		  .search-link {
              		   > a {
              			 padding: 10px 20px;
              		   }
              		 }
              	  }
              	}
              }
           }
         }

       }
       &.login-menu {
        .login-order {

        }
      }
    }
  }
  .login-page-section {
    .col-md-offset-1 {
      @media (min-width: @screen-md-min) {
        margin-left: 0;
        margin-right: 8.33333333%;
      }
    }
    .login-order {
      .box-border {
        border-left: 0;
        border-right: 1px solid @light-sand-brown;
      }
      .forget1 {
        float: right;
      }
    }
  }
  div.sub_menu_mobile {
   .menu_account {
     .bg_white_menu {
       .link-black_menu {
         &.link-has-menu {
           .arrow_accordion {
             position: absolute;
             left: 35px;
             right: auto;
             @media (max-width: 767px) {
               left: 25px;
               right: auto;
             }
           }
         }
       }
     }
   }
 }
 #login-menu-inner {
  .col-md-offset-1 {
    @media (min-width: @screen-md-min) {
      margin-left: 0;
      margin-right: 8.33333333%;
    }
  }
  .login-sub {
    &.login-sub-mob {
      .login-order {
        h5 {
          text-align: right;
        }
        .forget1 {
          float: right;
          @media (min-width: @screen-md-min) {
            padding: 0 20px 0 0px;
          }
          &.text-right {
            @media (min-width: @screen-md-min) {
              padding: 0 0px 0 20px;
            }
          }
        }
        .box-border {
          border-left: 0;
          border-right: 1px solid @light-sand-brown;
        }
      }
      &.new-login-sub-mob {
        .login-order {
          h5 {
            text-align: center;
          }
        }
      }
    }
  }
}
#store_locator_mobile_link {
  &.sub_menu_mobile {
    &.sub_menu_mobile_new {
      .menu_account {
        .bg_white_menu {
          .link-black_menu {
            margin-left: 0 !important;
            margin-right: 24px !important;
          }
        }
      }
      #accordion_menu {
        .panel-title {
          padding: 0 24px 0 0;
          border-bottom: 1px solid @light-sand-brown;
        }
        .panel-body {
          .sub_menu_accordion {
            .panel-title {
              a {
                margin-left: 0px;
                margin-right: 18px;
                @media (min-width: @screen-sm-min) {
                  margin-left: 0px;
                  margin-right: 24px;
                }
              }

            }
          }
          .col-sm-offset-4 {
            margin-left: 0px;
            margin-right: 66px;
            @media (min-width: @screen-sm-min) {
              margin-right: 72px;
              margin-left: 0px;
            }
          }
        }

      }
    }
  }
}
#store_locator_mobile_link {
  &.sub_menu_mobile {
    &.sub_menu_mobile_new-1 {
      .menu_account {

        .bg_white_menu {
          .link-black_menu {
            margin-right: 30px;
            margin-left: 0px;
            //font-size: 12px;
            //  line-height: 15px;
            @media (min-width: @screen-sm-min) {
              margin-right: 96px;
              margin-left: 0px;
              //font-size: 16px;
              //  line-height: 19px;
            }
            span {

            }
            > svg {
              margin-right: 0px;
              margin-left: 22px;
              @media (min-width: @screen-sm-min) {

              }
            }
            .arrow_accordion {
              svg {

              }
            }
          }
        }
      }
      #login-menu-inner {
        .login-sub {
          &.login-sub-mob {
            ul {
              &.menu-inner-list {
                li {
                  &:first-child {
                  }
                  a {
                    //font-size: 12px;
                    //  line-height: 15px;
                    text-transform: uppercase;
                    padding: 24px 80px 24px 0px;
                    @media (min-width: @screen-sm-min) {
                      padding-right: 160px;
                      padding-left: 0px;
                      //font-size: 16px;
                      //line-height: 19px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      #accordion_menu {
        .panel-body {
          .col-sm-offset-3 {
            margin-left: 0;
            margin-right: 0;
          }
        }
        .panel-title {
          a {
            //font-size: 20px;
            //line-height: 24px;
            @media (min-width: @screen-sm-min) {
              //  font-size: 24px;
              //  line-height: 30px;
            }
          }
          padding-right: 30px;
          padding-left: 0px;
          @media (min-width: @screen-sm-min) {
            padding-right: 96px;
            padding-left: 0px;
          }
        }
        .sub_menu_accordion {
          .panel-title {
            padding-right: 50px;
            padding-left: 0px;
            @media (min-width: @screen-sm-min) {
              padding-right: 120px;
              padding-left: 0px;
            }
            a {
              //font-size: 18px;
              //line-height: 22px;
              @media (min-width: @screen-sm-min) {
                //font-size: 22px;
                //line-height: 28px;
              }
            }
          }
        }
      }
    }
  }
}
#login-menu-inner {
  .login-sub {
    &.login-sub-mob {
      .consumer-register-form {
        .form {
          &.modified-form {
            .row {
              >.form-section {
                width: 100%;
              }
            }
          }
        }
      }
      .login-order {
        p {
          &.body-standard {
            @media (min-width: @screen-md-min) {
              text-align: right;
            }
          }
        }
      }
    }
  }
}
.form .select2.select2-container .select2-selection--single .select2-selection__rendered, .box-newsletters p, .newsletters-wrapper .box-newsletters h4 {
  font-family: Neotech-medium, GE SS Two, Arial, sans-serif !important;
}
.login-blocked p.blocked-p2 {
  @media (max-width: 788px) {
    height: 57px;
  }
}
  .main-menu-selfcare .menu-order.login-menu .sub-account-menu-wrap .form-section .alert_wrapper.login-error {
    text-align: right;
  }
}
}

.or-seprator {
  float: left;
  width: 100%;
  clear: both;
  text-align: center;
  position: relative;
  margin-top: 10px;
  margin-bottom: 35px;
  span {
    border: 1px solid #333;
    width: 100%;
    height: 1px;
    display: block;
    background: #333;
    position: relative;
  }
  label {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 30px;
    background: #fff;
    top: -16px;
    z-index: 9;
    font-size: 20px;
    text-align: center;
    padding: 0px 5px;
    box-sizing: border-box;
    @media (max-width: @screen-sm-min) {
      top: -10px;
    }
  }
}

.new-login-sub-mob {
  .or-seprator {
    label {
      background: #f1f1eb;
    }
  }
  .login-order {
    .btn.btn-default, .btn.btn-danger {
      @media (max-width: 414px) {
        font-size: .6rem !important;
      }
    }
  }
  .consumer-register-form {
    .form {
      padding-bottom: 0px;
    }
  }
  .login-blocked {
    .locked-cross {
      @media (max-width: 1024px) {
        margin-top: 30px;
      }
    }
  }
}

.mbimp30 {
  margin-bottom: 30px !important;
}

.login-order {
  .height-more.height-gray {
    height: 589px;
  }
}

.mb10 {
  margin-bottom: 10px !important;
}

.otp-options {
  .label {
    font-weight: normal;
    color: #848789;
    margin-top: 40px;
    width: 100%;
    text-align: left;
    display: block;
  }
  .radio {
    padding-top: 0px;
  }
  .code-sent {
    font-weight: normal;
    color: #848789;
    margin-top: 10px;
    margin-bottom: 0px;
    width: 100%;
    text-align: left;
    b {
      color: #000;
      font-weight: normal;
      @media (max-width: 992px) {
        display: inline-block;
      }
    }
  }
  .send-again {
    text-decoration: none !important;
    color: #719e19 !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 40px !important;
    display: inline-block !important;
    padding-left: 0px !important;
    float: left;
  }
}

.clr-white {
  color: #fff !important;
  text-decoration: none !important;
}

*[lang="ar"] {
  *[dir="rtl"] {
    .otp-options {
      .label {
        text-align: right;
      }
      .code-sent {
        text-align: right;
      }
      .send-again {
        float: right;
        padding-right: 0px;
      }
    }
    .login-order.updated-login-dropdown {
      .floating-label-input {
        input {
          padding-right: 0px !important;
        }
        label {
          padding-right: 0px !important;
        }
        &.has-error-fields {
          .alert-icon {
            svg {
                left: 0px;
                right: auto !important;
            }
          }
          .alert-label {
            text-align: right;
          }
        }
      }
    }
    @media (min-width: 768px) and (max-width: 992px) {
      #login-menu-inner .login-sub.login-sub-mob .padd-1 {
          padding: 1.8rem 4rem 0 2rem;
          &.height-more {
              padding: 1.8rem 2rem 0 4rem;
          }
          &.loginSection {
              border-right: 0 solid #e2e1da;
              border-left: 1px solid #e2e1da;
          }
      }
    }
  }
}
