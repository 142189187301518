.emirati-plan-tiles{
  
  margin-top: 2rem;
  .row{
    margin-left: -12px;
    margin-right: -12px;
  }
  .swiper-slide{
    padding-left: 12px;
    padding-right: 12px;
    &.col-md-4{
      width: 33.9%;
      @media (max-width: 767px) {
        width: 100%;
        padding: 0px;
      }
    }
    h2{
      font-size: 28px;
      letter-spacing: 0px;
    }
  }
  &.tile-boxes-section {
    position: relative;
    .swiper-scrollbar {
      bottom: 0 !important;
    }
  }
  .breadcrumb-result{
    color: @steel-grey;
    letter-spacing: 0.25rem;
    font-size: 14px;
    font-family: lato;
    b{
      color: @almost-black;
    }
  }
  .tile-box-tile {
    min-height: 280px;
    max-height: 280px;
    overflow: hidden;
    background: @white;
    border-radius: 4px;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
    position: relative;
    width: 100%;
    margin-bottom: 2rem;
    @media (min-width: @screen-md) {
      min-height: 440px;
      max-height: 440px;
    }
    .img-cover {
      height: 130px;
      position: relative;
      overflow: hidden;
      border-radius: 4px 4px 0 0;
      @media (min-width: @screen-md) {
        height: 225px;
      }
    }
    .tile-box-body {
      text-align: left;
      padding: 1.5rem;
      h5{
        color: @steel-grey;
        letter-spacing: 0.35rem;
        font-size: 12px;
        text-transform: uppercase;
        margin-top: 0px;
      }
      h2 {
        font-size: 18px;
        line-height: 24px;
        color: @almost-black;
        margin: 0;
        margin-bottom: .5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (min-width: @screen-md) {
          font-size: 28px;
          line-height: 35px;
          margin-bottom: 1rem;
        }
      }
      p {
        color: @steel-grey;
        font-size: 14px;
        line-height: 20px;
        min-height: 85px;
        @media (max-width: 767px) {
          min-height: 40px;
        }
        @media (min-width: @screen-md) {
          font-size: 1rem;
          line-height: 22px;
        }
      }
    }
    .btn-text {
      position: absolute;
      bottom: 1.5rem;
      left: 1.5rem;
      letter-spacing: 2px;
      font-size: 12px;
      @media (min-width: @screen-md) {
        font-size: 14px;
      }
    }
  }
  .tile-box-tile-cards{
      .swiper-wrapper{
      display: block;
      @media (max-width: 767px) {
        margin: 0;
        .tile-box-tile{
          min-height: 300px;
          max-height: 300px;
        }
        .swiper-slide{
          padding: 0px;
        }
      }
    }
  }
  
  *[lang="ar"] {
    *[dir="rtl"] {
      .tile-box-tile {
        .tile-box-body {
          text-align: right;
        }
        .btn-text {
          left: auto;
          right: 1.5rem;
        }
      }
    }
  }
  .swiper-wrapper{
    @media (max-width: 767px) {
      display: block;
      margin: 0px !important;
    }
  }
}


