.payment-gateway-container {
  padding: 4rem 0;
  background-color: @sand-white;
  h3 {
    text-align: center;
    letter-spacing: 0;
    margin-bottom: 2rem;
  }
  .section {
    margin: 2rem 0;
    .section-title {
      width: 100%;
      border-bottom: 2px solid @white;
      text-align: center;
      h4 {
        color: @main-green;
        text-transform: uppercase;
      }
    }
    .section-body {
      padding-bottom: 2rem;
      padding-top: 1rem;
      .body-fields {
        margin: 1rem 0;
        font-size: 1.5rem;
        display: flex;

        .field-title {
          text-align: right;
          color: @almost-black;
          padding-right: 1rem;
          &.with-image{
            padding-right: 4rem;
          }
        }
        .field-value {
          color: @steel-grey;
          text-align: left;
          padding-left: 1rem;
          &.with-image{
            padding-left: 4rem;
            color: @almost-black;
            text-align: left;
          }
        }
      }
    }
  }
}
@media (min-width: @screen-xs-min) {

}
@media (min-width: @screen-sm-min) {}
@media (min-width: @screen-md-min) {}
@media (min-width: @screen-lg-min) {}
