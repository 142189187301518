.editorial-block{
	.editorial-block-img{
		img{
			width: 100%;
			margin-bottom: 0.8rem;
		}
		h5{
			font-size: 20px;
			color: @steel-grey;
			letter-spacing: 0px;
			margin-bottom: 0px;
			font-weight: bold;
		}
		span{
			font-size: 20px;
			color: @steel-grey;
			margin-bottom: 0.5rem;
			letter-spacing: 0px;
		}
	}
	.editorial-block-content{
		h1{
			font-size: 48px;
			color: @almost-black;
			font-weight: 500;
			letter-spacing: 0px;
			margin-bottom: 2rem;
			font-weight: bold;
		}
		p{
			font-size: 20px;
			line-height: 28px;
			color: @steel-grey;
			font-family: lato;
			margin-bottom: 2rem;
		}
	}
}
@media (max-width: 768px) {
	.editorial-block{
		.editorial-block-img {
			h5{
				text-align: center;
				font-size: 16px;
				line-height: 22px;
				
			}
			span{
				text-align: center;
				display: block;
				font-size: 16px;
				line-height: 22px;
			}
		}
	}
	.editorial-block{
		.editorial-block-content{
			h1{
				font-size: 20px;
				line-height: 32px;
				margin-bottom: 1rem;
				font-weight: 600;
				margin-top: 2rem;
				br{
					display: none;
				}
			}
			p{
				margin-bottom: 1rem;
				font-size: 14px;
				line-height: 20px;
			}
		}
	}
}