/*
============================================

01 - sm viewport
02 - md viewport
03 - lg viewport
04 - Arabic overrides
05 - Business overrides
============================================
*/


.row-add-ons-grid {
  position: relative;
  text-align: center;
  .btn {
    margin: 3.75rem auto 2.125rem;
  }
  > .container-fluid {
    background-color: @sand-white;
    padding-bottom: 1.0rem;
    .swiper-container-multirow>.swiper-wrapper{
      display: flex;
      text-align: center;flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }
  .single-grid{
    overflow: hidden;
    display: inline-block;
    text-align: center;
  }
  .single-grid-container {
    padding: 0 0 1rem;
    position: relative;
    .card__back,
    .card__front {
      top: 0;
      left: 0;
    }
    .card__back,
    .card__front {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: transform 0.8s;
    }
    .card__back {
      background-color: #1e1e1e;
      transform: rotateY(-180deg);
    }
    /* click effect */
    .card.effect__click.flipped .card__front {
      transform: rotateY(-180deg);
    }
    .card.effect__click.flipped .card__back {
      transform: rotateY(0);
    }
    .single-grid .card__front {
      background-color: @white;
      cursor: pointer;
      text-align: center;
      padding: 2.5rem;
      min-height: 25rem;
      .flip-icon {
        height: 39px;
        width: 39px;
        padding: 11px;
        position: absolute;
        display: block;
        right: 0;
        top: 0;
        svg {
          color: #719e19;
          height: 20px;
          position: relative;
          width: 20px;
        }
      }
      &.content {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      h4 {
        font-size: 1rem;
        position: relative;
        width: inherit;
        padding-bottom: 1em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      h4:after {
        content: " ";
        width: 10%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 45%;
        background-color: @main-green;
      }
      p {
        font-size: 0.875rem;
        padding-right: 10%;
        padding-left: 10%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        min-height: 40px;
      }
      .detail-info-wrap {
        margin-top: 2.25rem;
        margin-bottom: 0.25rem;
      }
    }
    .single-grid .card__back {
      background-color: @main-green;
      text-align: left;
      padding: 2.5rem;
      position: absolute;
      cursor: pointer;
      width: 100%;
      color: @white;
      min-height: 25rem;
      .flip-icon {
        height: 39px;
        width: 39px;
        padding: 11px;
        position: absolute;
        display: block;
        right: 0;
        top: 0;
        svg {
          color: #719e19;
          height: 20px;
          position: relative;
          width: 20px;
        }
      }
      .backside-desc {
        p {
          font-size: 0.875rem;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          min-height: 40px;
          color: @white;
        }
      }
      .backside-list {
        font-size: 0.875rem;
        min-height: 110px;
        max-height: 110px;
        overflow: hidden;
        color: @white;
      }
      .backside-btn {
        margin-top: 1.25rem;
        a {
          color: @white;
          float: right;
          font-size: 0.75rem;
          text-decoration: underline;
        }
      }
    }
  }
  &.row-add-ons-grid-C {
    > .container-fluid {
    }
    .single-grid-container {
      .single-grid .card__front {
        h4:after {
          background-color: @main-green;
        }
      }
      .single-grid .card__back {
        background-color: @main-green;
      }
    }
  }
  @media (max-width: 767px) {
    .swiper-wrapper{
      width: 100% !important;
      display: block;
      .swiper-slide{
        width: 100% !important;
        margin-top: 20px;
        height: auto !important;
        margin-right: 0px !important;
        .add-ons-grid-box{
          &.content{
            p{
              display: block;
              text-align: center;
            }
          }
        }
      }
    }
    
  }
}
.add-ons-grid-box {
  >a {
    color: inherit;;
  }
}

/* -----------------------------------------
01 - sm viewport
----------------------------------------- */
@media (max-width: @screen-sm-min) {
  .row-add-ons-grid {
    .single-grid-container {
      padding-left: 1rem;
      padding-right: 1rem;
      .single-grid {
        .card__back {
          .backside-btn {
            a {
              margin-top: 0;

            }
          }
        }
      }
    }
  }
}
/* -----------------------------------------
02 - md viewport
----------------------------------------- */
@media (min-width: @screen-md-min) {
  .row-add-ons-grid {
    .single-grid-container {
      .single-grid .card__front {
        h4 {
          font-size: 1.25rem;
        }
        p {
          font-size: 1rem;
        }
      }
      .single-grid .card__back {
        .backside-desc {
          p {
            //font-size: 1rem;
          }
        }
        .backside-list {
          //font-size: 1rem;
        }
        .backside-btn {
          a {
            //font-size: 0.875rem;
          }
        }
      }
    }
  }
}
/* -----------------------------------------
03 - lg viewport
----------------------------------------- */
@media (max-width: @screen-lg-min) {
  .row-add-ons-grid {
    .single-grid-container {
      .single-grid {
        .card__back {
          .backside-btn {
            a {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}





/* -----------------------------------------
05 - Business overrides
----------------------------------------- */
.business {
  .row-add-ons-grid {
    > .container-fluid {
      background-color: @sand-white;
    }
    .single-grid-container {
      .single-grid .card__front {
        h4:after {
          background-color: @dark-green;
        }
      }
      .single-grid .card__back {
        background-color: @dark-green;
      }
    }
  }
}


/* -----------------------------------------
04 - Arabic overrides
----------------------------------------- */

*[lang="ar"] {
  *[dir="rtl"] {
    .row-add-ons-grid {
      .single-grid-container {
        .single-grid {
          .card__back {
            text-align: right;
            .backside-btn{
               a{
                 float: left;
               }
            }
          }
        }
      }
    }
  }
}
