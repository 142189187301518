.product-grid{
  border-top: 1px solid @light-gray;
  display: flex;
  flex-wrap: wrap;

  &>div{
    border-bottom: 1px solid @light-gray;
  }

  input{
    display: none;
  }
}
.product-load-more{
  padding-top: 2rem;
  padding-bottom: 2rem;
  .btn{
    font-size: 0.875rem;
    height: 3.3rem;
    line-height: 3.1rem;
  }
}
@media (min-width: @screen-sm-min) {
  .product-grid {
    & > div {
      border-right: 1px solid @light-gray;
    }
    & > div:nth-child(2n+0) {
      border-right: none;
    }
    &.product-grid-C > div:nth-child(2n+0) {
      border-right: 1px solid @light-gray;
    }
    &.product-grid-C > div:nth-child(3n+0) {
      border-right: none;
    }
    .add-to-cart-button-wrap{
      .ripple-effect{
        width: auto;
      }
    }
  }
  .product-load-more{
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

}

@media (min-width: @screen-md-min) {
  .product-grid {
    & > div:nth-child(2n+0) {
      border-right: 1px solid @light-gray;
    }
    &.product-grid-small > div:nth-child(2n+0),
    & > div:nth-child(3n+0) {
      border-right: none;
    }
    &.product-grid-small > div:nth-child(1n+0) {
      border-right: 1px solid @light-gray;
    }
  }
  .product-load-more{
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@media (min-width: @screen-lg-min) {
  .product-grid {
    .add-to-cart-button-wrap {
      .ripple-effect {
        padding: 0 6.4375rem;
      }
    }
  }
}
.business{
  .product-grid-dark {
    background-color: @sand-white;
    & > div,
    & > div:nth-child(2n+0),
    &.product-grid-C > div:nth-child(2n+0),
    &.product-grid-small > div:nth-child(2n+0),
    &.product-grid-small > div:nth-child(1n+0) {
      border-color: @white;
    }
  }
  .product-grid-dark + .product-load-more{
    background-color: @sand-white;
  }
}