/*
============================================
01 - cross nav A
02 - cross nav E
03 - cross nav F
04 - sm viewport
05 - md viewport
06 - lg viewport
07 - Business Variant
08 - Arabic overrides
============================================



/* -----------------------------------------
01 - cross nav A
----------------------------------------- */
.text-and-table {
  text-align: left;
  color: @dark-gray;
  padding: 3rem 0;
  background-color: @light-sand-brown;
  padding-bottom: .5rem;


  &.bg-white-filler {
    background-color: @white;
  }

  button.close {
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;

    svg {
      width: 0.89375rem;
      height: 0.89375rem;
    }
  }

  > div {
    &.text-and-table-text {
      p {
        //font-size: 0.75rem;
        //line-height: 1.6;
        color: @gray;
      }

      .content {
        position: relative;
        margin: 0;
        padding: 0 0 1rem;

        > img {
          display: block;
          width: auto;
          max-width: 100%;
          height: 15rem;
          margin: 0 auto;
        }

        h5 {
          display: block;
          position: relative;
          top: 0;
          width: 100%;
          margin: 0;
          padding: 0 1rem;
          line-height: 3rem;
          margin-bottom: 12rem;
          background-color: @main-green;
          color: @white;
          text-transform: uppercase;
        }

        .cover {
          height: 15rem;

          img {
            opacity: 1;
          }
        }
      }

      h2 {
        text-align: center;
      }
    }

    &.text-and-table-table {
      padding: 2rem 0;
      font-size: 1.2rem;
      line-height: 2.5;

      > .row {
        border-top: 2px @white solid;

        > div {
          padding: 0 1rem;

          &:first-child {
            text-transform: uppercase;
          }

          &:last-child {
            color: @steel-grey;
          }
        }

        &:last-child {
          border-bottom: 2px @white solid;
        }

        &.table-title {
          border-top: none;
          margin-top: -2rem;
          text-align: center;

          h5 {
            margin-top: 0;
            color: @main-green;
            width: auto;
            display: inline-block;
            //text-transform: none;
          }
        }

        &.table-specs {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;

          ul {
            margin: 0;
            padding: 0 0 0 1rem;
            display: flex;
            flex-wrap: wrap;

            li {
              color: @almost-black;
              margin: 0;
              padding: 0 2rem 0 0;
              text-transform: none;
              flex: 1 0 100%;
            }
          }
        }

        &.table-detail {
          margin-bottom: -2rem;
          padding-top: 2rem;
          border-bottom: none;

          .detail-price,
          .detail-price-new {
            margin: 0 0.5rem;
          }

          .detail-plus,
          .detail-price {
            color: @dark-gray;
          }

          .btn {
            margin: 0 0.5rem;
            font-size: 0.625rem;
            padding: 0 1.375rem;
          }
        }
      }

      .detail-info-wrap-pricetag {
        justify-content: flex-end;
      }
    }

    &.table-summary {
      .items {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 2.2rem;

        > ul {
          margin: 0;
          padding: 1rem 0;
          list-style: none;
          border-bottom: solid 1px @sand-white;
          flex: 0 0 100%;
          display: none;
          @media (min-width: @screen-md-min) {
            flex: 1 1 33.333%;
            display: block;
          }
          @media (min-width: @screen-lg-min) {
            //flex: 0 0 20%;
          }

          li {
            color: @steel-grey;
            font-size: 0.875rem;
            line-height: 1.563rem;
            padding-right: 1rem;

            h3 {
              font-family: inherit;
              font-size: 0.875rem;
              line-height: 1.563rem;
              color: @almost-black;
              padding: 0;
              margin: 0 0 0.3rem;
              font-weight: 400;
            }
          }

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            display: block;
          }
        }

        &.oneRow {
          > ul {
            @media (min-width: @screen-md-min) {
              flex: 1;
            }
          }
        }
      }

      .summary-link {
        float: none;
        padding-top: 2rem;
        padding-right: 2rem;

        svg {
          width: 1.25rem;
          height: 1.25rem;
          stroke: @main-green;
        }

        a {
          font-size: 0.75rem;
          line-height: 1.25rem;
          letter-spacing: 2px;
          text-transform: uppercase;
          text-decoration: underline;
          margin: 0 0.5rem;
        }

        &.almost-black {
          svg {
            stroke: @almost-black;
          }

          a {
            color: @almost-black;
          }
        }
      }

      .row.submit {
        padding: 2rem 0;
      }

      .row.expand {
        display: block;
        padding: 3rem 1rem 0;
        @media (min-width: @screen-md-min) {
          display: none;
        }

        a.togglecontents {
          color: @black;
          font-size: 0.75rem;
          letter-spacing: 2px;

          svg {
            fill: @black;
            margin-left: 5px;
            transform: rotate(0deg);
            width: 12px;
            height: 7px;
          }
        }
      }

      &.expanded {
        .items {
          > ul {
            display: block;
          }
        }

        .row.expand {
          a.togglecontents {
            svg {
              transform: rotate(180deg);

              use {
                -moz-transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
  }

  &.text-and-table-white {
    background-color: @white;

    button.close {
      position: relative;
      z-index: 1;
      right: 15px;
      bottom: -10px;
      outline: none;
    }

    > div {
      &.text-and-table-text {}

      &.text-and-table-table {
        > .row {
          border-color: @light-sand-brown;

          .btn:hover {
            background-color: @dark-orange !important;
            border-color: @dark-orange !important;
          }
        }
      }
    }
  }

  .text-and-table-F {
    // Baseline styles
    .table {
      width: 100%;
      max-width: 100%;
      margin-bottom: @line-height-computed;
      font-size: 1.25rem;
      // Cells
       > tfoot,
       > thead,
      > tbody {
        > tr {
           > th,
          > td {
            padding: @table-cell-padding;
            line-height: @line-height-base;
            vertical-align: top;
            border-bottom: 2px solid @sand-white;
            color: @steel-grey;
            padding-left: 1rem;
            max-width: 300px;
          }
        }
      }
      // Bottom align for column headings
      > thead > tr > th {
        vertical-align: bottom;
        border-bottom: 2px solid @sand-white;
        color: @dark-gray;
      }
      // Remove top border from thead by default
       > caption + thead,
       > thead:first-child,
      > colgroup + thead {
        > tr:first-child {
           > th,
          > td {
            border-top: 0;
            text-align: left;
          }
        }
      }
      // Account for multiple tbody instances
      > tbody + tbody {
        border-top: 2px solid @table-border-color;
      }
    }
    @media (max-width: @screen-sm-min) {
      .column-to-row-tables table,
      .column-to-row-tables tbody,
      .column-to-row-tables td,
      .column-to-row-tables th,
      .column-to-row-tables thead,
      .column-to-row-tables tr {
        display: block;
      }

      .table {
        width: 100%;
        max-width: 100%;
        margin-bottom: @line-height-computed;
        font-size: 1rem;
        // Cells
         > tfoot,
         > thead,
        > tbody {
          > tr {
             > th,
            > td {
              border-bottom: 0;
              max-width: 100%;
            }
          }
        }
        // Bottom align for column headings
        /* Hide table headers (but not display: none;, for accessibility) */
        > thead > tr > th {
          position: absolute;
          top: -9999px;
          left: -9999px;
        }
      }

      .column-to-row-tables tr {
        border-bottom: 2px solid @sand-white;
        font-size: .875rem;
      }

      .column-to-row-tables td {
        /* Behave like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50% !important;
        white-space: normal;
        text-align: left;
      }

      .column-to-row-tables td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        left: 1rem;
        width: 45%;
        padding-right: 1rem;
        //white-space: nowrap;
        text-align: left;
        font-weight: bold;
        line-height: 1;
      }
      /*
      Label the data
      */
      .column-to-row-tables td:before {
        content: attr(data-title);
      }
    }
  }
  &.appTable {
    >div {
      &.table-summary {
         .items {
           &>ul {
             width:100px;
             flex: 1 1 33.333%;
             @media (max-width: 560px) {
               flex: 1 1 50%;
             }
              & .app-img-responsive {
                max-height: 36px;
                &.icon {
                  max-height:100px;
                }
              }
              &:nth-child(1) {
                  flex: auto; max-width: 150px;
              }
              &:nth-child(3) {
                  flex: auto;
              }
              h4 {
                font-family: inherit;
                font-size: 0.875rem;
                line-height: 1.563rem;
                color: @almost-black;
                    padding: 0 0.5rem;
                margin: 0 0 0.3rem;
                font-weight: 400;
              }

           }
         }
      }
    }
  }
  
  h2{
    text-align: left;
    margin-bottom: 2rem;
    letter-spacing: 0px;
  }
  .table{
    thead{
      tr{
        @media (max-width: 768px) {
          border-color: @sand-white;
        }
        th{
          border-color: @sand-white;
          font-size: 1.2rem;
        }
      }
    }
    tbody{
      tr{
        @media (max-width: 768px) {
          border-color: @sand-white;
        }
        td{
          border-color: @sand-white;
          font-size: 1.1rem;
        }
      }
    }
  }
  .vat-text{
    padding-bottom: 1rem;
    span{
      color: @steel-grey;
      font-size: 0.8rem;
    }
  }
}

/* -----------------------------------------
02 - cross nav E
----------------------------------------- */
.text-and-table-text-row {
  h2 {
    color: @almost-black;
  }
}

/* -----------------------------------------
03 - cross nav F
----------------------------------------- */
.config-accordion-content {
  .text-and-table-text h2 {
    border-top: 1px solid @sand-white;
    font-size: 1.45rem;
    padding: 2.375rem 0;
    margin: 0;
  }
}

/* -----------------------------------------
04 - sm viewport
----------------------------------------- */
 @media (min-width: @screen-sm-min) {
   .text-and-table {
     > div {
       &.text-and-table-text {}

       &.text-and-table-table {
         > .row {
           &.table-title {
             text-align: left;
           }
         }
       }
     }

     h2 {
       text-align: left;
     }
   }
 }
 /* -----------------------------------------
 05 - md viewport
 ----------------------------------------- */
 @media (min-width: @screen-md-min) {
   .text-and-table {
     > div {
       &.text-and-table-text {
         .content {
           padding-bottom: 0;
         }
       }

       &.text-and-table-table {
         > .row {
           > div {
             &:first-child {
               text-transform: none;
             }
             &.text-uppercase {
                text-transform: uppercase;
             }
           }

           &.table-detail {
             padding-top: 1rem;

             .single-price {
               justify-content: flex-end;
             }
           }

           &.table-specs {
             ul {
               li {
                 flex: 1 0 50%;
               }
             }
           }
         }
       }

       &.table-summary {
         .summary-link {
           float: left;
         }
       }
     }

     h2 {
       font-size: 2.5rem;
       text-align: center;
     }
   }

   .text-and-table-text-row {
     h2 {
       font-size: 2.5rem;
       text-align: center;
       margin-bottom: 2.5rem;
       margin-top: 2.5rem;
     }
   }
 }
 /* -----------------------------------------
 06 - lg viewport
 ----------------------------------------- */
 @media (min-width: @screen-lg-min) {
   .text-and-table {
     > div {
       &.text-and-table-text {
         .content {
           > img {
             height: 18rem;
           }

           h5 {
             line-height: 2rem;
             margin-bottom: 15rem;
             text-transform: uppercase;
           }

           .cover {
             height: 18rem;
           }
         }
       }

       &.text-and-table-table {
         font-size: 1.25rem;
         line-height: 2.4;

         > .row {
           > div {
             &:last-child {
               color: @steel-grey;
               padding-right: 0;
             }
           }
         }
       }
     }
   }
 }
 /* -----------------------------------------
 07 - Business Variant
 ----------------------------------------- */
 .business {
  .text-and-table-text-row {
    background-color: @sand-white;
  }

  .text-and-table {
    &.bg-white-filler {
      background-color: @sand-white;
    }

    > div {
      &.text-and-table-text {
        .content {
          h5 {
            background-color: @dark-green;
          }
        }
      }

      &.text-and-table-table {
        > .row {
          &.table-title {
            h5 {
              color: @dark-green;
            }
          }
        }
      }

      &.table-summary {
        .summary-link {
          svg {
            stroke: @dark-green;
          }
        }
      }
    }

    &.text-and-table-white {
      background-color: @light-sand-brown;

      button.close {
        position: relative;
        z-index: 1;
        right: 15px;
        bottom: -10px;
        outline: none;
      }

      > div {
        &.text-and-table-text {}

        &.text-and-table-table {
          > .row {
            border-color: @white;

            .btn:hover {
              background-color: @dark-orange !important;
              border-color: @dark-orange !important;
            }
          }
        }
      }
    }
  }
}

.order-table {
    background: @sand-white;
}

.order-table-white {
    > div.table-summary {
        .items {
            padding-bottom: 0;
        }
    }
}
.text-and-table.order-table.order-billing-info>div.table-summary {
  .items {
      flex-wrap: nowrap;
  }
  .action-buttons-container {
    svg {

      display: inline-block;
      margin: 0px 5px -7px 0px;
      fill: @dark-gray;
      text-decoration: underline;
    }
    a {
      color: @dark-gray;
    }
  }
}
.order-table-white {
    padding-top: 1.5rem;
    > div.table-summary {
        .items {
            > ul {
                flex: 1 !important;
                text-align: left;
                display: block !important;
                border-bottom: 0;
            }
        }
    }
}
.order-table-white>div.table-summary .items>ul {
    -webkit-box-flex: 1!important;
    -ms-flex: 1!important;
    flex: 1!important;
    text-align: left;
    display: block!important;
    border-bottom: 0;
}

@media (max-width:@screen-xs) {
    .order-table-white {
        > div.table-summary {
            .items {
                > ul {
                    flex: none !important;
                    width: 100%;
                    border-bottom: 1px solid #f1f1eb !important;
                }
            }
        }
    }
}

@media (max-width:@screen-md) {
    .order-billing-info {
      > div.table-summary {
          .items {
            flex-wrap: wrap !important;
              > ul {
                  flex: none !important;
                  display: block;
                  width: 100%;
                  border-bottom: 1px solid @gray !important;
              }
          }
      }
    }
}
/* -----------------------------------------
08 - Arabic overrides
----------------------------------------- */
*[lang="ar"] {
  *[dir="rtl"] {
    .text-and-table {
      text-align: right;

      > div {
        &.table-summary {
          .row.expand {
            a.togglecontents {
              svg {
                margin-left: 0;
                margin-right: 5px;
              }
            }
          }
          .items>ul li{
            padding-right: 0;
            padding-left:1rem;
          }
        }


        &.text-and-table-text {
          p {}

          .content {
            > img {}

            h5 {}

            .cover {
              img {}
            }
          }
        }

        &.text-and-table-table {
          > .row {
            > div {
              &:first-child {}

              &:last-child {}
            }

            &:last-child {}

            &.table-title {
              text-align: center;

              h5 {}
            }

            &.table-specs {
              ul {
                padding-right: 1rem;
                padding-left: 0;

                li {}
              }
            }

            &.table-detail {
              .detail-price,
              .detail-price-new {}

              .detail-plus,
              .detail-price {}

              .btn {}
            }
          }
        }
      }

      &.text-and-table-white {
        background-color: @white;

        > div {
          &.text-and-table-text {}

          &.text-and-table-table {
            > .row {
              .btn:hover {}
            }
          }
        }
      }

      .text-and-table-F {
        // Baseline styles
        width: 100%;

        .table {
          // Cells
           > tfoot,
           > thead,
          > tbody {
            > tr {
               > th,
              > td {
                text-align: right;
                padding-left: 0;
                padding-right: 1rem;
              }
            }
          }
          // Bottom align for column headings
          > thead > tr > th {}
          // Remove top border from thead by default
           > caption + thead,
           > thead:first-child,
          > colgroup + thead {
            > tr:first-child {
               > th,
              > td {
                border-top: 0;
                text-align: right;
              }
            }
          }
          // Account for multiple tbody instances
          > tbody + tbody {
            border-top: 2px solid @table-border-color;
          }
        }
        @media (max-width: @screen-sm-min) {
          .column-to-row-tables td {
            /* Behave like a "row" */
            border: none;
            border-bottom: 0 solid #eee;
            position: relative;
            padding-right: 50% !important;
            padding-left: 0 !important;
            white-space: normal;
            text-align: right;
          }
          .column-to-row-tables td:before {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            left: auto;
            right: 1rem;
            width: 45%;
            padding-right: 0;
            padding-left: 1rem;
            //white-space: nowrap;
            text-align: right;
            font-weight: 300;
            line-height: 1;
          }
          /*
          Label the data
          */
          .column-to-row-tables td:before {
            content: attr(data-title);
          }
        }
      }

      &.table-summary {
        .summary-link {
          padding-left: 2rem;
          padding-right: 0;
        }
      }
      //ddd
      &.appTable {
        display: block;
      }
    }
    @media (min-width: @screen-xs-min) {
      .text-and-table {
        > div {
          &.text-and-table-text {}

          &.text-and-table-table {
            > .row {
              &.table-title {
                text-align: center;
              }
            }
          }
        }
      }

      h2 {
        text-align: center;
      }
    }
    @media (min-width: @screen-sm-min) {
      .text-and-table {
        > div {
          &.text-and-table-text {}

          &.text-and-table-table {
            > .row {
              display: flex;

              &.table-title {
                text-align: right;
              }
            }
          }
        }
      }
    }
    @media (min-width: @screen-md-min) {
      .text-and-table {
        display: flex;

        > div {
          &.text-and-table-text {
            .content {
              padding-bottom: 0;
            }
          }

          &.text-and-table-table {
            > .row {
              > div {
                &:first-child {
                  text-transform: none;
                }
              }

              &.table-detail {
                padding-top: 1rem;
                display: flex;
              }
            }
          }

          &.table-summary {
            .summary-link {
              float: right;
            }
          }
        }
      }

      h2 {
        font-size: 2.5rem;
        text-align: center;
      }
    }
    @media (min-width: @screen-lg-min) {
      .text-and-table {
        display: flex;

        > div {
          &.text-and-table-text {
            .content {
              > img {
                height: 18rem;
              }

              h5 {
                line-height: 3rem;
                margin-bottom: 15rem;
              }

              .cover {
                height: 18rem;
              }
            }
          }

          &.text-and-table-table {
            font-size: 1.25rem;
            line-height: 2.4;

            > .row {
              display: flex;

              > div {
                &:last-child {
                  color: @dark-gray;
                }
              }

              &.table-detail {
                display: flex;
              }
            }
          }
        }
      }
    }
  }
}
