
.config-upselling-noBg {
  background: transparent;
  margin-top: 0px;
    .selectTemplate {

        .swiper-wrapper {
            display: block;
            .tile-table.swiper-slide {
              margin-bottom:  4%;
            }
            .tile-table.swiper-slide:nth-child(3) {
              margin-right:  0;
            }
        }
    }
    div.cover {
      position: relative;
    }
    .row.marginBottom {
      margin-bottom: 35px;
    }
    .detail-info-wrap-pricetag {
      p {
        font-size: 1.15rem;
      }
    }
    .swiper-slide-action {
      background: @white;
    }
    .nav .dropdown  {
      padding-top: 0;
    }
    .nav .dropdown.open a  {
      background-color: @sand-white-50;
    }
    .dropdown-toggle > svg {
      width: 28px;
      height: 28px;
      padding-top: 10px;
    }
    p.templateTitle .btn {
      width: 100%;
      margin-bottom: 0;
      margin-top: 1rem;
    }

  .nav {
    .dropdown {
      ul.dropdown-menu {
        padding: 0px;
        border: 0;
        box-shadow: -1px 12px 10px rgba(0,0,0,.175);
          li {
            padding-top: 0;
            a {
              padding: 10px 20px;
              svg {
                width: 18px;
                height: 18px;
                margin-right: 10px;
              }
            }
          }
        }
      }
  }

}

.config-upselling-noBg .tile-table input[type=radio]:checked + .tile .card__front {
  border-color: @main-green;
}

.config-upselling-noBg .tile-table .tile .card__back,
.config-upselling-noBg .tile-table .tile .card__front {
  padding-bottom: 8.5rem;
  }

.config-upselling-noBg .tile-table .tile .tile-tick {
  margin-top: 0.85rem;
}

.selectTemplate .tile-table .tile .tiles-box .cover {
  height: 13rem;
  max-width: 100%;
    &:hover {

      &::before {
        background: @black;
        opacity: 0.7;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        z-index: 9;
        position: absolute;
      }
      &::after {
        content: url(../img/svg-icons/zoom-icon.svg);
        display: block;
        width: 80px;
        margin: 0 auto;
        z-index: 10;
        position: absolute;
        top: 30%;
        right:0;
        bottom: 0;
        left: 0;
        text-align: center;
      }
    }

}
.createTemplate {
  background: #fff;
  padding: 35px;
  .create-template-wrap {
    border: dashed 2px #e2e1da;
    border-radius: 5px;
  }
  .addTemplate {
    padding: 85px 0px;
    svg {
      width: 80px;
    }
    h4 {
      font-family: Lato,Roboto,Arial,sans-serif;
      font-size: 20px;
    }
  }
}

@media only screen and (min-width : 768px) and (max-width : 980px) {
  .config-upselling .tile-table {
    width: 22.75rem;
  }
}

@media (max-width: 767px) {
  .config-upselling-noBg {
      .selectTemplate {
        .swiper-wrapper {
          display: flex;
          margin-bottom: 20px;
        }
        .tile-table .tile .tiles-box .cover {
            height: 10rem;
        }
        .createTemplate {
          padding: 25px;
          .addTemplate {
              padding: 68px 0;
          }
        }
      }
      .navbar-nav {
          margin: 0px 0;
          width: 25%;
          float: right;
      }
      .navbar-nav .open .dropdown-menu {
        position: absolute;
        left: -97px;
      }

    }
}
