.ipad-tiles-box {
  overflow: hidden;
  .tiles-box-wrap {
    overflow: visible;
    padding-bottom: 0;
    div[class*=col-]>.rect-responsive {
      padding-bottom: 268px;
    }
    .tiles-box h4:after {
      display: none;
    }
    .tile-with-bkg .tiles-box:after {
      background-color: transparent;
    }
    .tiles-box h4 {
      margin-top: 5rem;
      margin-bottom: 0;
      padding-bottom: 10px;
      font-size: 2.5rem;
      text-transform: none;
      height: 4.5rem;
    }
    .tile-with-bkg div.cover:after, .tile-with-bkg:hover div.cover:after {
      background-color: rgba(0,0,0,.2);
    }
    &:after {
      content: " ";
      position: absolute;
      width: 20%;
      height: 100%;
      right: 0;
      top: 0;
      display: block;
      background: linear-gradient(to left, rgba(241, 241, 235,1) 0%,rgba(241, 241, 235,0) 100%);
      z-index: 10;
    }
  }
  .row > .col-xs-12 {
    margin-left: 2rem;
  }
  .detail-price-new * {
    color: white;
  }
  .price-tag {
    padding: 1.125rem .875rem .875rem;
    right: 0;
  }
}
[lang="ar"] [dir="rtl"] {
  .ipad-tiles-box .row>.col-xs-12 {
    margin-left: -2rem;
  }
  .tiles-box-wrap:after {
    right: auto;
    left: 2rem;
    background: linear-gradient(to right, rgba(241, 241, 235,1) 0%,rgba(241, 241, 235,0) 100%);
  }
}