.cross-promo-background {
  @media (max-width: @screen-md) {
    padding-left: 8px;
    padding-right: 8px;
  }

	.cross-promo-bg-tile {
		margin-bottom: 30px;
		position: relative;
		div.cover {
			position: relative;
			top: 0;
	    left: 0;
	    z-index: 1;
	    height: 250px;
	    overflow: hidden;
	    border-radius: 5px;
	    box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
      @media (max-width: 768px) {
        height: 148px;
      }
		}
		.cta-content-wrap {
            background: linear-gradient(0deg, #000000 0%, rgba(0,0,0,0) 100%);
			position: absolute;
			top: 0;
			max-width: 100%;
			height: 100%;
			width: 100%;
			z-index: 2;
			border-radius: 5px;
			//background: rgba(0,0,0,0.1);
			padding: 24px;
            @media (max-width: 768px) {
              padding: 19px;
            }
			.cont {
				position: absolute;
    		    bottom: 24px;
                @media (max-width: 768px) {
                  bottom: 18px;
                }
    		h4 {
    			color: @white;
    			line-height: 26px;
              font-size: 26px;
              margin: 0;
              margin-bottom: 1rem;
              @media (min-width: @screen-md) {
                  letter-spacing: 1.6px;
                }
              @media (max-width: @screen-md) {
                font-size: 20px;
              }
    		}
    		.btn-text {
    			color: #FFFFFF;
			    letter-spacing: 2px;
			    line-height: 20px;
          @media (max-width: @screen-md) {
            letter-spacing: 0;
            text-transform: capitalize;
          }
			    &:before {
			    	border-color: #fff;
    				padding: 4px;
            @media (max-width: 768px) {
              padding: 3px;
              margin-top: 1px;
              border-width: 0 2px 2px 0;
            }
			    }
    		}
			}
		}
	}
  &.large-tile {
    .cross-promo-bg-tile {
      .cta-content-wrap {
        .cont {
            h4 {
            @media (min-width: @screen-lg-min) {
              font-size: 36px;
              letter-spacing: 3px;
              margin-bottom: 25px;
            }
          }
        }
      }
    }
  }
}

.cross-promo-plain {
  border-radius: 8px;
  position: relative;
  z-index: 1;
  /*background: linear-gradient(-92.14deg,#222222 41%,#3B1E54 110%);*/
  background: linear-gradient(-92.14deg,#222 41%,#491873 110%);
  color: #fff;
  padding-left: 12px;
  padding-right: 12px;
  @media (max-width: @screen-md) {
    background: linear-gradient(-177.14deg,#222 41%,#491873 110%);
    padding-left: 8px;
    padding-right: 8px;
    >.container {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &.full-bleed-wrapper {
    padding: 0;
    max-height: 440px;
    height: 440px;
    overflow: hidden;
    &:after {
      z-index: 99;
      @media (max-width: @screen-sm) {
        width: 120px;
      }
    }
    @media (max-width: @screen-sm) {
      max-height: 450px;
      height: 450px;
    }
    .content-section {
      position: absolute !important;
      display: block !important;
      z-index: 99;
      @media (max-width: @screen-sm) {
        margin-top: 152px;
      }
    }
  }
  &:after,
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background: url("../img/bg-cards/plan-cards-illustration.svg") 100% 0 no-repeat;
    background-size: contain;
    opacity: .7;
  }
  &:after {
    content: "";
    right: auto;
    left: 0;
    transform: rotate(180deg);
  }
  .content-section {
    padding: 2.25rem;
    float: left;
    width: 100%;
    color: #fff;
    img {
      max-width: 100%;
    }
    .lef-col {
      &>span {
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 4px;
        line-height: 12px;
        width: 100%;
        display: inline-block;
        margin-bottom: 15px;
      }
    }
    h3 {
      margin-top: 0px;
      line-height: 38px;
    }
    ul {
      list-style: disc;
      padding-left: 20px;
      li {
        margin-bottom: 15px;
        @media (max-width: 768px) {
          margin-bottom: 0;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
    p,ul {
      margin-top: 10px;
      padding-right: 50px;
      @media (max-width: 768px) {
        margin-top: 0;
        padding-right: 0px;
      }
    }
    a {
      color: #fff;
      &.btn-text {
        margin-top: 20px;
        display: inline-block;
        margin-bottom: 5px;
        letter-spacing: 3px;
        @media (max-width: 768px) {
          letter-spacing: 0px;
          text-transform: unset;
        }
        .more {
          display: block !important;
        }
        &:before {
          border-color: #fff;
          right: -30px;
          @media (max-width: 768px) {
            right: -15px;
            padding: 3px;
            margin-top: 1px;
            border-width: 0 2px 2px 0;
          }
        }
      }
    }
  }
  &.promo-big {
    border-radius: 0;
    .content-section {
      padding: 5rem 0rem;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        padding: 16px 30px;
        display: flex;
        flex-direction: column;
        .lef-col {
          order: 2;
          margin: 20px 0px;
        }
        .right-col {
          order: 1;
        }
      }
      h3 {
        font-size: 32px;
        margin-bottom: 35px;
        @media (max-width: 768px) {
          font-size: 26px;
          line-height: 38px;
          margin-bottom: 15px;
        }
      }
      a {
        &.btn-text {
          @media (max-width: 768px) {
            font-size: 16px;
            font-weight: bold;
            line-height: 19px;
            float: right;
            margin-right: 17px;
          }
        }
      }
      .lbl {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 4px;
        line-height: 12px;
        display: inline-block;
        margin-bottom: 10px;
        @media (max-width: 768px) {
          color: #909090;
          font-size: 10px;
          letter-spacing: 3.33px;
          margin-bottom: 0;
        }
      }
    }
    &:before {
      background-color: rgba(0,0,0,.5);
      width: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      content: "";
      z-index: 1;
      display: block;

    }
    &:after {
      background: url(../img/bg-cards/plan-cards-illustration_flip.svg) left top no-repeat;
      transform: none;
      background-size: contain;
    }
  }
  &.promo-small {
    float: left;
    width: 100%;
    background: none;
    border-radius: none;
    //@media (max-width: 768px) {
    //  padding:0px 12px;
    //}
    &:after,
    &:before {
      display:none;
    }
    .content-section {
      float: left;
      width: 100%;
      display: flex;
      align-items: center;
      background: linear-gradient(-92.14deg,#222 41%,#491873 110%);
      border-radius: 8px;
      position: relative;
      h3 {
        font-size: 24px;
        margin-bottom: 15px;
        margin-top: 10px;
        @media (max-width: 768px) {
          font-size: 22px;
          margin-top: 0;
          margin-bottom: 10px;
        }
      }
      P {
        @media (max-width: 768px) {
          margin-top: 0;
          margin-bottom: 10px;
          font-size: 14px;
          line-height: 22px;
          padding-right: 0px;
        }
      }
      .right-col {
        a {
          background: transparent;
          border: 1px solid #fff;
          letter-spacing: 3px;
          width: 282px;
          float: right;
          margin-right: 40px;
          @media (max-width: 768px) {
            width:100%;
            margin-top:15px;
            margin-right:0;
            margin-left:0;
            letter-spacing: 2.4px;
          }
        }
      }
      @media (max-width: 768px) {
        display: block;
        padding: 17px;
        .right-col,
        .lef-col {
          padding: 0px;
        }
      }
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        background: url(../img/bg-cards/plan-cards-illustration_flip.svg) left 0 no-repeat;
        background-size: contain;
        opacity: .7;
      }
      &:after {
        content: "";
        right: auto;
        left: 0;
        transform: rotate(180deg);
      }
    }
  }
  &.promo-small-02 {
    float: left;
    width: 100%;
    background: none;
    border-radius: 0;
    @media (max-width: 768px) {
      min-height: 148px;
      /*display: flex;
      align-items: center;*/
    }
    &:before,
    &:after {
      display: none;
    }
    .content-section {
      padding: 2rem 1.85rem;
      background: linear-gradient(-31.66deg, #070707 -42%, #199E94 75%);
      border-radius: 8px;
      position: relative;
      min-height: 146px;
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        padding: 2rem 1rem;
        margin-bottom: 20px;
      }
      h4 {
        margin-bottom: 15px;
        margin-top: 0px;
        @media (min-width: @screen-lg-min) {
          font-size: 24px;
        }
        @media (max-width: 768px) {
          margin-bottom: 5px;
        }
      }
      a {
        &.btn-text {
          margin: 0;
        }
      }
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        background: url(../img/bg-cards/plan-cards-illustration.svg) right 0 no-repeat;
        background-size: contain;
        opacity: .7;
      }
       &:after {
        transform: none;
      }
    }
  }
}
.btn.btn-transparent {
  background: transparent;
  border: 1px solid #fff;
  &:hover {
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
  }
}
.no-padding-row {
  .row {
    &>div {
      &:first-child {
        padding-left:0px;
      }
      &:last-child {
        padding-right:0px;
      }
      @media (max-width: 768px) {
        &:first-child {
          padding-left:12px;
          padding-right:12px;
        }
        &:last-child {
          padding-left:12px;
          padding-right:12px;
        }
      }
    }
  }
}
.small-pedding {
  @media (max-width: 768px) {
    padding:0px 12px;
  }
}
.freedom{
  .cross-promo-plain{
     .content-section{
        .btn-text{
          text-transform: uppercase;
          letter-spacing: 2px;
          font-size:.75rem !important;
          position: relative;
          float: left !important;
          &:after{
            content: "";
            position: absolute;
            line-height: 1;
            top: 10px;
            right: -22px;
            transform: translateY(-50%) rotate(-45deg);
            border: solid #ffffff;
            border-width: 0 1px 1px 0;
            padding: 3px;
            margin-left: .75rem;
            @media (max-width: 768px) {
              right: -12px;
            }
          }
        }
     }
  }
}


// arabic language
*[lang="ar"] {
  *[dir="rtl"] {
    .no-padding-row {
      .row {
        &>div {
          &:first-child {
            padding-right:0px;
            padding-left: 12px;
          }
          &:last-child {
            padding-left:0px;
            padding-right: 12px;
          }
        }
      }
    }
    .cross-promo-plain {
      background: linear-gradient(92.14deg,#222 41%,#491873 110%);
      .content-section {
        p,ul {
          padding-right: 0px;
          padding-left: 50px;
        }
        ul {
          padding-right: 20px;
        }
        a {
          &.btn-text {
            &:before {
              border-color: #fff;
              right: auto;
              left: -30px;
            }
          }
        }
      }
      &.promo-big {
        &:after {
          background: url(../img/bg-cards/plan-cards-illustration.svg) right 0 no-repeat;
          background-size: contain;
        }
      }
      &.promo-small {
        background:none;
        .content-section {
          background: linear-gradient(92.14deg,#222 41%,#491873 110%);
          .right-col {
            a {
              float: left;
              margin-left: 40px;
              margin-right: 0;
            }
          }
          &:before,
          &:after {
            background: url(../img/bg-cards/plan-cards-illustration.svg) right 0 no-repeat;
            background-size: contain;
          }
        }
      }
      &.promo-small-02 {
        background: none;
        .content-section {
          background: linear-gradient(31.66deg, #070707 -42%, #199E94 75%);
          &:after {
            display: none;
          }
          &:before {
            background: url(../img/bg-cards/plan-cards-illustration_flip.svg) left 0 no-repeat;
            background-size: contain;
          }
        }
      }
    }
    .freedom{
      .cross-promo-plain {
        .content-section {
          .btn-text {           
           float: right!important;
           &:after{
            left: -30px;
            right: auto;
            transform: rotate(130deg);
            top: 5px;
           }
         }
        }
      }
    }

  }
}
