.btn {
    &.btn-ai-support {
        position: fixed;
        bottom: 20px;
        right: 80px;
        left:inherit;
        background: @white;
        display: block;
        text-decoration: none;
        border-radius: 50px;
        transition: all .3s ease;
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.4);
        z-index: 1;
        opacity: 1;
        border: 0px;
        color: @main-green;
        text-transform: unset;
        letter-spacing: 0px;
        font-weight: 600;
        height: 3rem;
        line-height: 2.8rem;
        padding: 0px 20px 0px 20px;
        font-size: 14px;
        border: solid 1px @white;
        z-index: 999;

        @media (max-width: 767px) {
          width: 175px;
          right: 0 !important;
          left:0 !important;
          margin: auto;
        }

        &:hover {
            background-color: @white;
            color: @main-green;
            opacity: 1;
        }
        &:focus {
            background-color: @white;
            border-color: @main-green;
            color: @main-green;
            opacity: 1;
        }
    }

    .btn-icon {
        padding: 0;
        padding-right: 0.5rem;
        width: 33px;
        display: inline-block;

        img {
            min-height: auto;
        }
    }
}
body{
    &.carrier{
        .btn {
            &.btn-ai-support {
                display: none !important;
            }
            }  
    }
}
.ai-chat-wrapper {
    background-color: #FBFBFB;
    min-height: 75%;
    width: 400px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    border-radius: 10px;
    display: block;
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: none;
    z-index: 999;

    .ai-chat-header {
        background-color: @white;
        padding: 10px;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 5px rgba(0, 0, 0, .075);
        height: 60px;
        border-radius: 10px 10px 0px 0px;

        .hide-ai-support {
            border: none;
            background-color: transparent;
            width: 25px;
            height: 25px;
            position: absolute;
            right: 10px;
            top: 15px;
            outline: none;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    .ai-chat-footer {
        background-color: @white;
        padding: 10px;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 5px rgba(0, 0, 0, .075);
        height: 60px;
        border-radius: 10px 10px 0px 0px;
        position: absolute;
        bottom: 0;
        left: 0;

        .hide-ai-support {
            border: none;
            background-color: transparent;
            width: 25px;
            height: 25px;
            position: absolute;
            right: 10px;
            top: 0;
            outline: none;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    @media (max-width: 767px) {
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        width: 100%;
        border-radius: 0px;
        .ai-chat-header {
            border-radius: 0px;
        }
    }
}
iframe{
    background-color: transparent;
}
*[lang="ar"] {
    *[dir="rtl"] {
        .btn {
            &.btn-ai-support {
                bottom: 20px;
                right: inherit;
                left: 80px;
                .btn-icon {
                    padding-right: 0;
                    padding-left: 0.5rem;
                }
            }
        }
        .ai-chat-wrapper {
            right: auto;
            left: 20px !important;
            .ai-chat-header {
                .hide-ai-support {
                    right: auto;
                    left: 10px;
                    top: 15px;
                }
            }
        }
    }
}

.changePostionAI {
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0;
  overflow: hidden;
  border-radius: 10px;
  @media (max-width: 767px) {
    position: fixed;
    border-radius: 0px;
  }

}
