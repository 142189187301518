@business-register-form: 1.5rem;
.business-register-form {
  padding: 0;
  .form-container {
    padding-bottom: 4rem;
    padding-top: 4rem;
  }
  .form-title {
    padding-bottom: 2rem;
    text-align: center;
    color: @almost-black;
    h3 {
      letter-spacing: 0;
      text-transform: uppercase;
    }
    h4 {
      letter-spacing: 0;

    }
    h5 {
      color:@steel-grey;
      letter-spacing: 0;
      padding-bottom: 1rem;
      &.notification {
        padding-bottom: 0;
        margin: 0;
        &:last-child {
          padding-bottom: 1rem;
        }
      }
      &.success-msg {
        color: @dark-green;
      }
      &.error-text {
        color: #fb193f;
      }
    }
    a.btn {
      text-decoration: none;
    }
  }
  .form {
    padding-bottom: 3rem;
    .row {
      .form-section {
        margin: 0;
        padding: 0;
      }
      > .form-section {
        margin: 0;
        padding: 0;
        &:nth-child(1) {
        }
        &:nth-child(2) {
        }
        &.single-section {
          padding: 0;
        }
        .floating-label-input {
          .tooltipped .tooltip.right {
            margin-left: 0.5rem;
          }
          label {}
          span {
            &.outside-icon {
              width: 2rem;
              height: 2rem;
              position: absolute;
              top: 1.7rem;
              right: 4%;
              align-items: center;
              text-align: center;
              z-index: 2;
              //background-color: @white;
            }
            svg {
              height: 24px;
              width: 24px;
              fill: @dark-green;
            }
          }
          &.has-error-fields {
            span.inner-icon,
            span.outside-icon {
              svg {
                fill: @pink;
                path{
                  fill: @pink;
                }
              }
            }
          }
        }
        .forgot-label {
          bottom: -2rem;
        }
      }
    }
    .btn-inner-icon {
      padding-right: 0.5rem;
      /* line-height: 30px; */
      position: relative;
      top: 5px;
      svg {
        fill: @almost-black;
      }
    }
  }
}
@media (min-width: @screen-xs-min) {
  .business-register-form {
    .form {
      .row {
        > .form-section {
          margin: 0;
          padding: 0;
          &:nth-child(1) {
            padding-right: 0;
          }
          &:nth-child(2) {
            padding-left: 0;
          }
          &.single-section {
            padding: 0;
          }
        }
      }
    }
  }
}
@media (min-width: @screen-sm-min) {
  .business-register-form {
    .form {
      .row {
        > .form-section {
          margin: 0;
          padding: 0;
          &:nth-child(1) {
            padding-right: @business-register-form*2.5;
          }
          &:nth-child(2) {
            padding-left: @business-register-form*2.5;
          }
          &.single-section {
            padding: 0;
          }
        }
      }
    }
  }
}
@media (min-width: @screen-md-min) {
  .business-register-form {
    .form {
      .row {
        > .form-section {
          margin: 0;
          padding: 0;
          &:nth-child(1) {
            padding-right: @business-register-form*2.5;
          }
          &:nth-child(2) {
            padding-left: @business-register-form*2.5;
          }
          &.single-section {
            padding: 0;
          }
        }
      }
    }
  }
}

@media (min-width: @screen-lg-min) {
  .business-register-form {
    .form {
      .row {
        > .form-section {
          margin: 0;
          padding: 0;
          &:nth-child(1) {
            padding-right: @business-register-form*2.5;
          }
          &:nth-child(2) {
            padding-left: @business-register-form*2.5;
          }
          &.single-section {
            padding: 0;
          }
        }
      }
    }
  }
}

*[lang="ar"] {
  *[dir="rtl"] {
    .business-register-form .form .btn-inner-icon{
      float: left;
    }
  }
}
