.fifa_home {
	&.with_elife {
		.main-text h1 {
			padding-top: 3.5rem;
			@media (max-width: 767px) {
				padding-top: 1.6rem;
			}
		}
		.media-cta-wrapper {
			.media-cta-caption>div {
				padding-top: 4rem;
			}
		}
	}
	.media-cta-wrapper {
		.grey_wrapper_for_media_image {
			background: #F1F1EB;
		  width: 50%;
		  padding: 60px;
		  @media (max-width: 768px) {
    		width: 100%;
  			padding: 40px;
    	}
		  .media-cta-photo {
		  	height: 100%;
	    	width: 100%;
		  }
		}
		h3 {
			letter-spacing: 0px;
		}
	}
	.swiper-container {
		.swiper-slide {
			.section-title {
				font-size: 19px;
			}
			a {
				&.btn-text-only {
					margin-left: 6px;
					text-decoration: none;
				}
				&.btn-default {
					border-width: 1px;
					padding: 0 4.5rem;
				}
			}
			h2 {
				font-size: 3.5rem;
				@media (max-width: 736px) {
		  		font-size: 1.8rem;
		  		br {
		  			display: none;
		  		}
		  	}
			}
		}
	}
	.main-text {
		.h2, h1, h2 {
			text-transform: capitalize;
    	font-size: 2.2rem;
    	padding-top: 4.7rem;
    	@media (max-width: 1024px) {
    		font-size: 1.4rem;
    		padding-top: 3.5rem;
    	}
    	@media (max-width: 736px) {
    		font-size: 1rem;
		    padding-top: 2rem;
		    padding-bottom: 1.7rem;
		    line-height: 22px;
    	}
    	br {
    		@media (max-width: 736px) {
    		  display: none;
    	  }
    	}
	  }
	}
	.icon-text-block {
		.main-text {
		.h2, h1, h2 {
				font-size: 2rem;
		    padding-bottom: 4rem;
		    padding-top: 5rem;
		    @media (max-width: 1024px) {
	    		font-size: 1.4rem;
	    		padding-bottom: 3rem;
   				padding-top: 4rem;
	    	}
	    	@media (max-width: 736px) {
		  		text-align: left;
		  	}
		  }
		}
	}
	.media-cta-caption > div > div {
		p {
			padding-right: 10%;
		}
	}
	.see_schedule {
		color: #3D3F3F;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: 1rem;
		@media (max-width: 768px) {
  		text-align: right;
    	text-decoration: underline;
    	padding-top: 20px;
  	}
  	@media (max-width: 667px) {
  		text-align: left;
  	}
	}
	.channels {
		display: inline-block;
    clear: both;
    width: 100%;
    color: #7a7a7a;
    b {
    	color: #353738;
    }
	}
	.icon-text {
    text-align: center;
    padding-bottom: 50px;
    @media (max-width: 736px) {
	  	text-align: left;
	  	padding-bottom: 35px;
	  }
		.icon_wrap {
			svg {
				padding-bottom: 15px;
				box-sizing: content-box;
				border-bottom: 1px solid #719e19;
				@media (max-width: 736px) {
			  	float: left;
			    margin-right: 25px;
			    border-bottom: 0px;
			  }
			}
		}
		h4 {
			color: #353733;
	    text-transform: uppercase;
	    font-size: 1.3rem;
	    padding-top: 5px;
	    @media (max-width: 1024px) {
    		font-size: 1rem;
    	}
    	@media (max-width: 736px) {
		  	margin-top:0px;
		  }
		}
		p {
			font-size: 1rem;
			padding-top: 15px;
			padding: 0 20%;
			@media (max-width: 736px) {
				padding-left: 71px;
    		padding-right: 15%;
			}
		}
	}
	.pkges-details-2-table-txt {
		.table-responsive {
			table.table-with-borders {
				tr td:first-child {
					padding-left: 10px;
				}
			} 
		}
	}
	.team-group-tables {
		.main-text {
			h2 {
				font-size: 2rem;
		    padding-bottom: 4rem;
    		padding-top: 3.5rem;
    		letter-spacing: 2px;
			}
		}
	}
	.teams-group {
		padding-right: 20px;
    padding-bottom: 20px;
    &:nth-child(4n) {
    	padding-right: 0px;
    }
    @media (max-width: 768px) {
	  	 &:nth-child(4n) {
	    	padding-right: 20px;
	    }
	    &:nth-child(3n) {
	    	padding-right: 0px;
	    }
	  }
	  @media (max-width: 736px) {
	  	 &:nth-child(4n) {
	    	padding-right: 0px;
	    }
	    &:nth-child(3n) {
	    	padding-right: 0px;
	    }
	    padding-right: 0px;
	  }
    ul {
    	border: 1px solid #b2b2b2;
	    box-sizing: border-box;
	    margin-bottom: 0;
	    background: #fff;
	    float: left;
	    @media (max-width: 736px) {
	    	width:100%;
	  	}
	    li {
	    	padding-top: 0;
		    text-align: left;
		    padding: 3px 0;
		    border-bottom: 2px solid #e2e1da;
		    float: left;
		    width: 100%;
		    background: #fff;
	    	&:first-child {
	    		padding: 0px;
	    		border-bottom: 0px;
	    	}
		    &:last-child {
		    	border:0px;
		    }
		    h4 {
		    	margin: 0px;
			    padding: 0px;
			    background: #E2E1DA;
			    padding: 10px 20px;
			    color: #353738;
			    font-size: 1.2rem;
    			letter-spacing: 0px;
		    }
		    img {
		    	padding-left: 5px;
    			padding-right: 25px;
		    }
	    }
    }
	}
	.table_head {
		background-color: #E2E1DA;
		text-align: center;
		width: 100%;
		clear: both;
		float: left;
		padding-bottom: 0px;
    padding: 10px 0px;
    margin-bottom: 30px;
		h4 {
			text-transform: uppercase;
			font-size: 16px;
			letter-spacing: 0px;
			color: #353738;
			text-align: center;
			margin: 0px;
		}
	}
	.team-group-tables {
		table {
			margin-bottom: 50px;
			width: 100%;
			tr {
				td {
					text-align: left;
			    border-bottom: 1px solid #E2E1DA;
			    padding: 6px 0px;
			    padding-left: 30px;
				}
				th {
					text-align: left;
			    border-bottom: 1px solid #E2E1DA;
			    padding: 6px 0px;
			    padding-left: 30px;
			    text-transform: uppercase;
			    font-weight: normal;
			    color: #353738;
				}
			}
		}
		.border-white {
			table {
				tr {
					td, th {
						border-bottom: 1px solid #fff;
					}
				}
			}
		}
	}
	.mb10 {
		margin-bottom: 10px !important;
	}
}

.localtion-tables {
	.main-text {
		&.bg-white {
			padding-bottom: 0px;
			h2 {
				padding-bottom: 3.8rem;
				padding-top: 3.5rem;
			}
		}
	}
	.fifa-location-container {
	  max-width: 100%;
	  margin-bottom: 40px;
	  @media (min-width: @screen-sm-min) {
	    max-width: 47%;
	    float: left;
	    margin-bottom: 60px;
	    &:nth-child(odd) {
	      margin-right: 3%;
	    }
	    &:nth-child(even) {
	      margin-left: 3%;
	    }
	  }
	  .map-container {
	    min-height: 138px;
	    position: relative;
	    svg {
	    	position: absolute;
		    right: 11px;
		    top: 13px;
	    }
	    img {
	    	width: 100%;
	    	height: auto;
	    }
	  }
	  .location-container {
	    span {
	      text-transform: uppercase;
				margin: 1.3rem 0;
				display: block;
				font-size: 14px;
				margin-bottom: 10px;
	    }
	  }

	  .table-container {
	    h3 {
	      letter-spacing: 0;
	      margin-bottom: 10px;
	    }
	    table {
	    	margin-bottom: 0px !important;
	      th, td {
	        padding: .6rem 1.4rem !important;
	      }
	      tbody td {
	        color: @gray !important;
	      }
	      thead th {
	        text-align: left;
	        text-transform: uppercase;
	        font-weight: normal;
	        margin: .5rem 0;
	        display: block;
	        padding-bottom: 0px !important;
	      }
	    }

	  }
	  .pkges-details-2-table-txt>div:first-child {
	  	padding-right: 0px;
	  }
	}
}

.less-padded {
	.media-cta-wrapper.col-reverse {
		.media-cta-caption {
			margin: 0;
			padding: 0 5rem;
			@media (min-width: 992px) {
				margin: 0;
				padding: 0 5rem;
			}
			@media (max-width: 1024px) {
				padding: 0 3rem;
			}
			@media (max-width: 768px) {
				margin: auto;
				padding: 0;
			}
		}
	}
}

@media (max-width: 767px) {
	.fifa_home {
		.pkges-details-2-table-txt .table-responsive table.table-with-borders tr td {
	    float: left;
	    width: auto;
	    display: block;
		}
	}
}
@media (max-width: 736px) {
	.fifa_home {
		.team-group-tables {
			table {
				tbody tr {
					display: block!important;
	    		overflow: hidden;
					td {
				    float: left;
				    width: 100%;
				    display: block;
				    &:before {
					    content: attr(data-label);
					    float: left;
					    font-weight: 700;
					    font-size: 14px;
					    line-height: 17px;
					    color: #353738;
					    font-weight: 400;
					    text-transform: uppercase;
					    width: 50%;
					    margin-right: 10px;
					  }
				  }
				  &:nth-child(2n) {
				    background: #f7f8f4;
				    overflow: hidden;
					}
				}
				thead {
				    visibility: hidden;
				    height: 1px;
				    position: absolute;
				    overflow: hidden;
				    width: 100%;
				}
			}
		}
		&.with_elife {
			.team-group-tables .main-text h2 {
				font-size: 1rem;
		    padding-bottom: 2.5rem;
		    padding-top: 2.5rem;
		    line-height: 28px;
			}
	    .localtion-tables .main-text.bg-white h2 {
		    padding-bottom: 2.5rem;
		    padding-top: 2.5rem;
			}
		}
		.showmore {
			float: left;
	    padding: 0px;
	    border: 0px;
	    height: auto;
	    line-height: normal;
	    padding-right: 20px;
	    font-size: 14px;
	    background-image: url(../img/main-text/icon_arrow_right_dark_48@3x_Down.png);
	    background-size: 14px;
	    background-repeat: no-repeat;
	    background-position: right;
	    margin-bottom: 50px;
	    outline: none;
	    background-color: transparent !important;
	    color: #333 !important;
		}
		.hero-image {
			/*padding-top: 61px;
    	box-sizing: content-box;*/
    	min-height: 400px;
		}
	}
}

*[lang="ar"] {
  *[dir="rtl"] {
  	.fifa_home {
  		.media-cta-caption > div > div {
  			p {
				  padding-left: 10%;
				  padding-right: 0;
				}
  		}
  		.teams-group {
  			ul {
	  			padding: 0px !important;
	  			li {
	  				text-align: right;
	  				img {
					    padding-left: 25px;
					    padding-right: 5px;
						}
	  			}
	  		}
  		}
  		.group_head {
  			text-align: right;
  		}
  	}
  	.fifa-location-container {
  		.table-container {
  			table {
  				thead th {
  					text-align: right;
  				}
  			}
  		}
  	}
  	.fifa_home .team-group-tables table tr td, .fifa_home .team-group-tables table tr th {
  		text-align: right;
	    padding-right: 30px;
	    padding-left: 0px;
  	}
  }
}