
#notifyForm{
  ~ .form-submitted{
    margin: 0;
  }
  .config-expantion-wrapper{
    .container-fluid {
      margin-bottom: 0;
    }
  }
}

.config-expantion-wrapper {

  //display: none;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  color: @almost-black;

  height: 0;
  display: block;
  margin-top: 2rem;
  transition: height 0.3s ease-in-out;
  overflow: hidden;

  &.config-expantion-wrapper-visible{
    position: static;
    height: auto;
    margin-top: 0;
  }

  > .container-fluid {
    padding-bottom: 1.5rem;
    margin-bottom: 2rem;
  }

  > .container-fluid > .config-expantion-wrapper,
  > .config-expantion-wrapper{
    position: static;
    margin-bottom:0;
  }


  &.config-expantion-sand {
    > .container-fluid {
      background-color: @light-sand-brown;
      padding-bottom: 2rem;
      &.single-multiple-line {
        margin-bottom: 0;
        background-color: @sand-white;
      }

      &.config-section-title-wrapper--no-space-bottom{
        padding-bottom:0;
        margin-bottom:0;
        &.single-multiple-line-padding {
          padding-top: 0;
        }
        h4{
          margin-bottom: 0;
        }
      }

    }
  }

  &.form-block form{
    display: block;
  }

  .space-top{
    padding-top: 3rem;
  }

  &.config-expantion-text-and-form {
    .container-fluid {
      .form-section {
        .tooltipped {
          .tooltip {
            .tooltip-inner {
              padding: 1rem;
              background-color: #719e19;
              max-width: 69vw;
              width: 360px;
              color: #fff;
              text-align: left;
            }
          }
        }
        span {
          &.outside-icon {
            width: 2rem;
            height: 2rem;
            position: absolute;
            top: 1.7rem;
            right: 4%;
            align-items: center;
            text-align: center;
            z-index: 2;
            cursor: auto;
            //background-color: @white;
          }
          svg {
            height: 24px;
            width: 24px;
            fill: @main-green;
          }
        }
      }
    }
    > .container-fluid {
      > .row {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        &.filters{
          display: block;
          .model_phone{
            h5{
              color: @black;
              margin: 0;
            }
            p{
              font-size: 0.75rem;
              margin-top: 0.125rem;
              margin-bottom: 0;
              width: 100%;
            }
          }
          .total_etipoints{
            text-align: right;
            h5{
              margin: 0;
              color: @main-green;
            }
            p{
              color: @almost-black;
              font-size: 1rem;
              font-family: @font-family-menu-sans-serif;
              margin-top: -0.125rem;
              margin-bottom: 0;
              width: 100%;
            }
          }
          .slider{
            margin-top: 2.3333333333rem;
            margin-bottom: 3.125rem;
            &#ex1Slider, &#ex2Slider {
              .tooltip {
                margin-top: -24px;
              }
            }
          }
        }
      }
    }

    h4 {
      margin: 2rem 0;
      font-size: 1rem;
    }

    h5 {
          margin: 2rem 0 2rem;
    }

    p{
      font-size: 0.875rem;
      color: @steel-grey;
      margin-bottom: 2.3125rem;
      a.underline {color: #353738;}
    }

    a.underlinedButton {
      color: @almost-black;
      text-decoration: underline;
      display: inline-block;
      padding-top: 0.5rem;
      font-size: 0.875rem;
      &:hover {
        text-decoration: none;
      }
    }

    .buttons-and-price {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      .detail-info-wrap{
        width: auto;
        margin-bottom: 2.3125rem;
        margin-top: -2.25rem;
        @media (max-width: 480) {
            margin-bottom: 1.6rem !important; 
        }
      }
      .btn {
        margin-left: 1rem;
        justify-content: center;
        display: flex;
        width: 100%;
      }
    }

    .form-section {
      .row{
        display: flex;
        justify-content: flex-start;

      }
      & .disp-block {
        display:block;
      }
      .btn {
        width:100%;
        &.mLtRt {
              margin: 0 1rem;
          }
      }
      .btn-refresh{
        width: 4.875rem;
        height: 4.282rem;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .floating-label-input-no-margin {
        margin-bottom: 0;
      }
    }

    .detail-price-new {
      .main-part {
        color: @almost-black;
      }
    }
    .spinbox{
      margin: 1.75rem 0 0;
    }
  }

  .col-auto{
    width: auto;
  }
}

.chooseNumbertab {
  position: relative;
  left: auto;
  top: auto;
  height: auto;
  & a.underline {color:#353738;}
  &.mZero {
    margin:0px;
  }
  & .mZero {
    margin:0px;
  }

}
.config-expantion-wrapper.config-expantion-text-and-form .form.multi-row-form{
  margin-bottom: 0;
  margin-top: 3rem;
  .form-submit{
    text-align: left;
    margin-top: 3rem;
    height: auto;
    button {
      margin: 0;
    }
  }
  .checkbox{
    padding: 0;
    margin: 0;

  }
}
.business{
  .config-expantion-wrapper{
    /*
   display: none;
   width: 100%;
   position: absolute;
   left: 0;
   top: 0;
   padding-top: 2rem;
   padding-bottom: 1.5rem;


   &.config-expantion-sand {
     > .container-fluid {
       background-color: @premium-gray;
     }
   }

   &.config-expantion-text-and-form {
     p, h5, h4{
       color: @white;
     }
     a.underlinedButton {
       color: @white;
     }
     .detail-price-new {
       .main-part {
         color: @white;
       }
     }
   }
   */
  }
}
[dir=rtl] {
  .luckyNumberTab, .col-xs-9.col-md-6, .col-xs-3.col-md-3.col-auto {
      float: right;
  }
  .commonClass {
    .form {
  		.form-section {
  			.col-xs-12 {
  				>.col-xs-1.col-auto, .col-sm-7.col-md-3, .col-xs-9.col-sm-5.col-md-5  {
  					float: right;
  				}

  				>.expantion-form-submit .btn {
  					width: 100%;
  				}
  			}
  		}
  	}
  }
}
@media (max-width: @screen-sm-min) {
  .config-tile-wrapper {
    .form-section {
      .floating-label-input-no-margin {
        height: 4.282rem;
      }
      .row-my-number {
        .btn {
          height: 4.282rem;
        }
      }
    }
  }
  .config-expantion-wrapper{
    .form-section {
      .refresh-icon{
        stroke: @white;
        width: 2.875rem;
      }
    }
  }

}

@media (min-width: @screen-sm-min) {
  .config-tile-wrapper {
    .form-section {
      .floating-label-input-no-margin {
        margin-left: 1.5rem;
        height: 4.282rem;
      }
      .row-my-number {
        .btn {
          height: 4.282rem;
        }
      }
    }
  }
  .config-expantion-wrapper {
    &.config-expantion-text-and-form {
      h4 {
        margin: 2rem 0;
        font-size: 1.25rem;
      }
      p{
        font-size: 1rem;
        line-height: 1.375rem;
      }
      .underlinedButton{
        font-size: 1rem ;
      }
      .buttons-and-price {
        flex-direction: row;
        margin-top: -3.125rem;
        .detail-info-wrap{
          margin-top: 0;
          margin-bottom: 0;
        }
        .btn {
          width: auto;
        }
      }
      .spinbox{
        margin: 0;
      }
      .filters{
        .floating-label-input-no-margin{
          margin-left: 0;
        }
      }
      .form {
        &.multi-row-form{
          margin-bottom: 0;
          .form-section {
            .floating-label-input{
              margin-bottom: 3rem ;
            }
          }
        }
      }

    }
    .form-section {
      .btn {
        width:auto;
      }
      .floating-label-input-no-margin {
        margin-bottom: 0;
        margin-left: 1.5rem;
      }
      .btn {
        /*margin-top: 1px;*/
        height: 4.282rem;
      }
      .refresh-icon{
        stroke: @white;
        width: 2.875rem;
      }
    }
    > .container-fluid {
      margin-bottom: 3rem;

      & .mZero {
        margin:0px;
      }
    }
  }
.business-register-form .multi-row-form{
  padding-bottom: 0;
}
.fullWidth-sm {
  .row.row-my-number {
       flex-direction: row !important;
  }
}


}
@media (min-width: @screen-md-min) {
  .config-expantion-wrapper {
    &.config-expantion-text-and-form {
      .container-fluid {
        .row {
          flex-direction: row;
          &.filters{
            p{
              width:58%;
              margin-bottom: 2rem;
            }
            .total_etipoints{
              float: right;
            }
            #ex1Slider,#ex2Slider{
              margin-top: 1.3125rem;
            }
          }
        }
        .floating-label-select{
          min-width: 19rem;
        }
      }
      form {
        display:block;
      }
      p{
        margin-bottom: 1rem;
      }
    }
  }
  .chooseNumbertab {
    &.smallMargin {
        margin: .5rem 0;
    }
  }
}

// .config-expantion-wrapper.config-expantion-text-and-form .form-section .btn-refresh

@media (min-width: @screen-lg-min) {
  .config-tile-wrapper {
    .form-section {
      .floating-label-input-no-margin {
        margin-left: 1.625rem;
        height: 5.1rem;
      }
      .row-my-number {
        .btn {
          height: 5.1rem;
        }
      }
    }
  }
  .config-expantion-wrapper {
    width: 100%;
    &.config-expantion-text-and-form {
      .container-fluid {
        .row {
          &.filters{
            padding-top: 2rem;
            p{
              width:50%;
              margin-bottom: 3.3125rem;
            }
            .total_etipoints{
              text-align: left;
              padding-left: 0.9375rem;
            }
          }
        }
      }
      h4 {
        margin: 2rem 0 1.6875rem 0;
        font-size: 1.5rem;
      }
      p{
        margin-bottom: 1rem;
      }
      .spinbox{
        //margin: ;
      }

      .form-section {
        .btn, .btn-refresh {
          height: 5.1rem;
        }
      }

    }
    .form-section {
      .floating-label-input-no-margin {
        margin-left: 1.625rem;
      }
    }
    > .container-fluid {
      margin-bottom: 2rem;
    }
  }
}

@media (max-width: @screen-lg-min) {
  .config-expantion-wrapper{
  &.config-expantion-text-and-form {
    .container-fluid {
      .form-section {
        span {
          &.outside-icon {
            width: 2rem;
            height: 2rem;
            position: absolute;
            top: 1.45825rem;
            right: 4%;
            align-items: center;
            text-align: center;
            z-index: 2;
            cursor: auto;
          }
          svg {
            height: 1.25rem;
            width: 1.25rem;
          }
        }
      }
    }
  }
}
}
@media (min-width: @screen-xl-min) {
  .config-expantion-wrapper {
    .container-fluid {
      float: left;
      width: 100%;
    }
  }
}

@media (max-width: @screen-ms-max) {
  .config-expantion-wrapper {
    .form-section{
      .btn {
        height: 4.264rem;
      }
    }
  }
}

*[lang="ar"] {
  *[dir="rtl"] {
    .config-expantion-wrapper.config-expantion-text-and-form .buttons-and-price .btn{
      margin-right: 1rem;
      margin-left: auto;
    }
    .config-expantion-wrapper.config-expantion-text-and-form .form-section .btn-refresh{
      width: 4.875rem;
    }
    @media (min-width: @screen-sm-min) {
      .config-expantion-wrapper {
        .form-section {
          .btn {
            width: auto;
          }
          .floating-label-input-no-margin {
            margin-bottom: 0;
            margin-right: 1.5rem;
            margin-left: 0;
          }
        }
      }
    }

    @media (min-width: @screen-lg-min) {
      .config-expantion-wrapper {
        .form-section {
          .floating-label-input-no-margin {
            margin-right: 1.625rem;
          }
        }
      }
    }
  /*  & .iconSearchNumber {
      -webkit-transform:scaleX(-1);
      transform:scaleX(-1);

    }*/
  }
}
.form {
  .has-error-fields {
    .select2.select2-container .select2-selection--single .select2-selection__rendered {
        color: #fb193f;
    }
  }
}
@media (max-width: @screen-sm-min) {
  .config-expantion-wrapper.config-expantion-text-and-form .form-section .row {
      flex-direction: column;
  }
}
.msg-to-book-number.number-booked {
    padding: 1rem 0 !important;
}


//book your number refresh button width auto
.commonClass {
  .btn.btn-refresh {
    width: auto !important;
  }
  .row {
    h4 {
      margin-bottom: 1rem;
    }
    h5 {
      margin: 0.5rem 0 !important;
    }
  }
}

@media (max-width: 430px) {
  .config-expantion-wrapper.config-expantion-text-and-form>.container-fluid>.row {display: block;}
}
.search-your-number .form-section .form-group.floating-label-input {
    margin-bottom: 0;
}
