
.menu-order {
    .navbar {
        .navbar-right {
            .sub-account-menu-wrap {
                position: absolute !important;
                right: 0 !important;
                left: auto !important;
                width: auto !important;
                top: 94px;
            }
        }
    }

}
.menu-order.menu__collapse {
    .navbar {
        .navbar-right {
            .sub-account-menu-wrap {
                top: 50px;
            }
        }
    }
}
.menu-order {
    .navbar {
        .navbar-header.text-right {
            height: auto;
            max-width: 214px;
            float: right;
        }
    }
}
.menu-order {
    .navbar {
        .navbar-header.text-right {
            .navbar-right {
                .account-link>a {
                    text-align: center;
                    border-right: 1px solid #d8d8d8;
                }
            }
        }
    }
}
.menu-order {
    .navbar {
        .navbar-header.text-right .navbar-right .account-link:hover>a, .navbar-header.text-right .navbar-right .account-link:focus>a {
            color: @white;
        }
    }
}
.menu-order {
    .navbar {
        .navbar-header.text-right .navbar-right .account-link:hover, .navbar-header.text-right .navbar-right .account-link:focus {
            background: @dark-green;
        }
    }
}
.menu-order {
    .navbar {
        .navbar-header.text-right {
            .navbar-right {
                .account-link {
                    padding: 35px 0;
                }
            }
        }
    }
}
.menu-order {
    .navbar {
        .account-link:after {
            background-color: transparent !important;
        }
    }
}
.menu-order {
    .navbar {
        .navbar-header.text-right {
            .navbar-right {
                .search-link>a {
                    padding: 29px 2rem;
                }
            }
        }
    }
}
.forget1 a, .forget2 a {
    letter-spacing: 2px;
}
.menu-order.menu__collapse {
    .navbar {
        .navbar-header.text-right {
            .navbar-right {
                .account-link {
                    padding: 13px 0;
                }
            }
        }
    }
}
.menu-order.menu__collapse {
    .navbar {
        .navbar-header.text-right {
            .navbar-right {
                .search-link>a {
                    padding: 7px 2rem;
                }
            }
        }
    }
}
.sub_menu_mobile {
    .login-order {
        width: auto;
    }
}
.sub_menu_mobile .login-order {
    .checkbox {
        label {
            line-height: 16px;
        }
    }
}
.sub_menu_mobile .form .checkbox input, .sub_menu_mobile .form .radio input {
    visibility: visible;
    z-index: 999;
    opacity: 0;
    left: 18px;
    top: 24px;
    width: 30px;
    height: 30px;
}

.ui-menu {
    background: #FFF;
    border-radius: 0px;
    font-size: 1em;
    color: #333333;
    .ui-menu-item {
    margin: 0;
    cursor: pointer;
        .ui-menu-item-wrapper{
            position: relative;
            padding: 3px 1em 3px .4em;
        }
    }
}
.ui-menu.source:hover {
    background: #454545;
}

.navbar-right {
  .sub-account-menu-wrap {
    .login-order {
      background: @white;
      right: 0px;
      position: absolute;
    }
  }
}

@media (min-width: 1280px) {

  .main-menu .navbar .navbar-header {
  //justify-content: center; IE alignment fix
  }
  .js-fixed-nav {
    // margin-top: 0px;
  }
  .menu-order {

  }
  .sub_menu_mobile.visible_menu {
    display: block;
  }
}
@media (min-width: 1600px) {
  .sub-account-menu-wrap {
    .container {
      width: 1280px;
    }
  }
}
