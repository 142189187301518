@top-nav-height: 48px;

.top-nav {
  background-color: @sand-white;
  text-transform: uppercase;
  border: 0;
  margin-bottom: 0;
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100%;
  .transition(0.3s);
  min-height: @top-nav-height;

  &.fixedNav {
    position: fixed;
    top: -1px;
    left: 0;
    width: 100%;
    z-index: 9000;
    .transition(0.3s);
  }

  .top-nav-main-row {
    display: flex;
    justify-content: space-between;
  }

  .top-nav-channel {
    display: flex;
    justify-content: flex-start;
  }

  .top-nav-language {
    display: flex;
    justify-content: flex-end;
  }

  a {
    display: inline-block;
    width: 100%;
    height: @top-nav-height;
    font-size: @font-size-top-nav;
    letter-spacing: @kerning-top-nav;
    color: @almost-black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .text-right a {
    justify-content: flex-end;
  }

  .text-center a {
    justify-content: center;
  }

  .active > a,
  .active > a:focus,
  .active > a:hover,
  a:focus,
  a:hover,
  a:hover {
    background: fade(@sandy-green, 40%);
    color: @almost-black;
    .transition(0.3s);
  }

  #top-nav-store-img {
    width: 14px;
    height: 18px;
    margin-left: 10px;
  }
  #top-nav-email-img {
    width: 18px;
    max-width: 18px;
    height: 18px;
    margin-left: 9px;
  }
  #top-nav-cart-img {
    width: 24px;
    height: 21px;
    margin-left: 10px;
  }

  #top-nav-arrow-img {
    transform: rotate(90deg);
    width: 5px;
    height: 9px;
    margin-left: 10px;
    position: absolute;
    top: 43%;
    right:10%;
  }

  span.language-switch-content {
    font-size: 1rem;
    font-weight: 100;
    font-family: GE SS Two,Arial,sans-serif;
    letter-spacing: 0;
  }

  span.badge-top {
    width: 1.31rem;
    height: 1.31rem;
    background-color: @main-green;
    color: @white;
    border-radius: 50%;
    line-height: 1.31rem;
    position: absolute;
    top: 0;
    left: 50%;
    letter-spacing: 2px;
    margin-left: 0.3rem;
    text-align: center;
  }

  .align-cart{
    a > div:first-child{
      margin-right:-10px;
      span{
        float:right;
      }
    }
    a > div:nth-child(2){
      padding-top: 5px;
    }

  }
}

//quibk basket css start

.quickBasket {
    background: #fff;
    position: absolute;
    top: 48px;
    width: 360px;
    height: 490px;
    padding: 1.5rem;
    overflow: hidden;
    border: 1px solid @cold-gray;
    text-align: left;
    overflow: hidden;
    -webkit-box-shadow: 0px 4px 13px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 4px 13px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 4px 13px 0px rgba(0,0,0,0.25);
    transform: translateY(0);
    transition: transform 0.3s ease-in-out;
    -webkit-animation-delay: 0.7s;
    -moz-animation-delay: 0.7s;
    animation-delay: 0.7s;
    //display: none;
    right:0;
    &.mobile-view {
      width: 100%;
      height: 100%;
      bottom: 0px;
      top: 0px;
      left: 0px;
      right: 0px;
      z-index: 99999;
      background: @white;
      .quickbasket-cross {
        position: fixed;
        background: #353738;
        top: 0;
        left: 0;
        right: 0;
        height: 40px;
      }
      .close {
        position: relative;
        float: right;
        top: 10px;
        right: 24px;
        svg {
          width: 16px;
          height: 16px;
          fill: @white;
        }
      }
      .yourCartTotal {
        margin-top: 2rem;
      }
      .yourCartProds {
        height: 80%;
        @media (min-width: 481px) and (max-width: 767px){
          height: 64%;
        }
      }
    }
    .red{
      color: @red;
    }
    & .prodImg img {
        width: 56px;
        height: 56px;
        border: 1px solid @cold-gray;
        overflow: hidden;
        float: left;
    }
    & .prodText {
      float:left;
    }
    .yourCartTotal {
          padding-bottom: 1rem;
          .yourCart {
              color: @premium-gray;
          }
          .total {
              text-align: right;
              color: @black;
          }

      }
      .yourCartProds {
        overflow: hidden;
        overflow-y: auto;
        height: 359px;
        >.row {
          padding-bottom: 1rem;
        }
        .prodText {
          h2,  p {
              font-size: 0.8rem;
              padding: 0 10px 0 10px;
              text-transform: none;
              letter-spacing: 0;
              margin: 0;
              color: @lighter-gray;
              line-height: 15px;
          }
          h2 {
              color: @black;
              line-height: 1.8em;
          }
        }
      }
  .qbFooter{
    border-top: 1px solid #d1d2d1;
    height: 64px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background: @white;
    a {
      line-height: 64px;
      height: 64px;
      text-align: center;
    }
  }
}
.top-nav-language {
  .align-cart:hover {
    .quickBasket {
    //  display: block;
    }
  }
}

//***********************
//wst top nav styling

.top-nav-section {
  height: 48px;
  #top-nav-cart-img, .links svg {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    transform: translateZ(0);
  }
  .links {
    li {
      display: inline-block;
      a {
        padding: 0 1rem;
        svg {
          stroke: @almost-black;
        }
      }
    }
  }
  .top-nav-left {
    .links {
      a {
        font-weight: 800;
        height: 47px;
      }
      .active>a,
      .active>a:hover,
      a:hover {
        background: transparent;
        border-bottom: 2px solid @brand-primary;
        color: @brand-primary;
        height: 47px;
      }
    }
  }
  .top-nav-right {
    text-align: right;
    .links {
      li {
        a {
          padding: 0 1.5rem;
          position: relative;
        }
        &:last-child {
          a {
            padding-right: 0;
          }
        }
      }

      .badge-top {
        top: 6px;
        z-index: 99;
        letter-spacing: 0;
        font-weight: bold;
      }

      .language-switch-content + svg {
        width: 8px;
        height: 5px;
      }
    }
  }
  // there is no hover and active state in design for these links
  .active>a,
  .active>a:focus,
  .active>a:hover,
  a:focus,
  a:hover {
    background: transparent;
  }
  //*****************************
  // for wst structure only
  @media (min-width: 1248px) {
    .container {
      width: 1224px;
    }
  }

  @media (max-width: @screen-sm) {
    .container {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  //*****************************
}
//***********************

/*
 * RTL support
 */
html[lang="ar"] {
  *[dir="rtl"] {
    .top-nav {
      span.language-switch-content {
        //font-size: 0.6875rem;
        //font-family: @font-family-base;
      //  letter-spacing: 2px !important;
      }

      a {
        font-size: 1.0rem;
      }

      span.badge-top {
        right: 50%;
        left: auto;
        margin-right: 0.15rem;
        margin-left: 0;
        top: 0.25rem;
      }
    }

    #top-nav-store-img {
      margin-right: 10px;
    }

    #top-nav-cart-img {
      margin-right: 10px;
      //margin-left:0;
    }
    #top-nav-email-img {
      margin-right: 9px;
      margin-left: 0;

      @media (max-width: @screen-ms-max){
        margin-left: 1.0625rem;
        margin-right: 0;
      }
    }
    #top-nav-arrow-img {
      width: 5px;
      height: 9px;
      margin-left: 10px;
      transform: rotate(90deg);
      position: absolute;
      right: 15%;
      left: auto;
      top: 40%;
      display:none;
    }

    .align-cart{
      a > div:first-child{
        span{
          float:left;
        }
      }
      a > div:nth-child(2){
        padding-top: 10px;
      }

    }

    .quickBasket {
      right:auto;
      left:0;
        .prodImg img {
            float: right;
        }
        .prodText {
          float:right;
        }
        .yourCartTotal {
              .total {
                  text-align: left;
              }
              .yourCart {
                 float: right;
                 text-align: right;
             }
          }
          .yourCartProds {
            >.row {
            }
            .prodText {
              h2,  p {
                  text-align: right;
              }
            }
          }
    }
    .top-nav-section {
      .top-nav-right {
        .links li:last-child a {
          padding-left: 0;
          padding-right: 1.5rem;

        }
      }
      [class*='col-']{
        float: right;
      }

      .top-nav-right{
        text-align:left;
      }
    }

  }
}
