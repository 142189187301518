@tooltip-arrow-width: 1rem;
@zindex-tooltip: 9;
@border-radius-base: 0;
@tooltip-max-width: 360px;
@tooltip-min-width: 217px;
@tooltip-color: @white;
@tooltip-arrow-color: @main-green;
@font-size-small: 0.875rem;

.business-register-form {
    &.tooltipped, .tooltipped {
      .tooltip {
        .tooltip-inner {
          padding: 1rem;
          background-color: @dark-green;
          width: @tooltip-max-width;
          max-width: 69vw;
          color: @tooltip-color;
          text-align: left;
          ul {
            padding-left: 1rem;
          }
        }
        &.top .tooltip-arrow {
          border-top-color: @dark-green;
        }
        &.top-left .tooltip-arrow {
          border-top-color: @dark-green;
        }
        &.top-right .tooltip-arrow {
          border-top-color: @dark-green;
        }
        &.right .tooltip-arrow {
          border-right-color: @dark-green;
        }
        &.left .tooltip-arrow {
          border-left-color: @dark-green;
        }
        &.bottom .tooltip-arrow {
          border-bottom-color: @dark-green;
        }
        &.bottom-left .tooltip-arrow {
          border-bottom-color: @dark-green;
        }
        &.bottom-right .tooltip-arrow {
          border-bottom-color: @dark-green;
        }
      }
    }
}
.consumer-register-form {

    &.tooltipped, .tooltipped {
      .tooltip {
        .tooltip-inner {
          padding: 1rem;
          background-color: @main-green;
          max-width: 69vw;
          width: @tooltip-max-width;
          color: @tooltip-color;
          text-align: left;
          ul {
            padding-left: 1rem;
          }

        }
        .tooltip-arrow {
        }
      }
    }
}

.business-register-form, .consumer-register-form{
  .has-error-fields{
    &.tooltipped, .tooltipped {
      .tooltip {
        .tooltip-inner {
          background-color: @pink;
        }
        &.top .tooltip-arrow {
          border-top-color: @pink;
        }
        &.top-left .tooltip-arrow {
          border-top-color: @pink;
        }
        &.top-right .tooltip-arrow {
          border-top-color: @pink;
        }
        &.right .tooltip-arrow {
          border-right-color: @pink;
        }
        &.left .tooltip-arrow {
          border-left-color: @pink;
        }
        &.bottom .tooltip-arrow {
          border-bottom-color: @pink;
        }
        &.bottom-left .tooltip-arrow {
          border-bottom-color: @pink;
        }
        &.bottom-right .tooltip-arrow {
          border-bottom-color: @pink;
        }
      }
    }

  }
  svg{
    fill:@pink;
  }
}
