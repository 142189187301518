.action-bar-wrapper {
  > .row {
    margin-top: 3rem;
    border-top: 2px solid @texts-gray;
  }
  .action-bar {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    font-family: @font-family-menu-sans-serif;
    font-size: 1rem;
    a {
      position: relative;
      height: 3.8rem;
      line-height: 3.8rem;
      flex-grow: 1;
      text-align: center;
      color: @dark-gray;
      flex-basis: 0;
      border-bottom: 2px solid @texts-gray;
      display:inline-block;
      &:hover {
        color: @main-green;
      }
    }
    a.active {
      background-color: @main-green;
      color: @white;
      border-bottom: @main-green;
      svg {
        fill: @white;
        transform: rotate(270deg);
      }
    }
    svg {
      position: absolute;
      height: 0.875rem;
      width: 0.4rem;
      transform: rotate(90deg);
      right: 1.75rem;
      top: 50%;
      margin-top: -0.4rem;
      .transition();
    }
  }
  .active-center,
  .active-first,
  .active-last {
    border-bottom: none;
    border-top-color: @main-green;
    a {
      display: none;
      &.active {
        display: block;
      }
    }
    .action-bar {
      border-bottom: none;
    }
  }
  .active-first {
    .action-bar {
      border-left-color: @main-green;
    }
  }
  .active-last {
    .action-bar {
      border-right-color: @main-green;
    }
  }
}

.action-bar-container.tab-closed{
  display: none;
}
.action-bar-container.action-bar-container-active{
  display: block;
}

@media all and (-ms-high-contrast:none) and (max-width: @screen-sm-min){
  .business .action-bar-wrapper,
  .action-bar-wrapper {
    display: block !important;
    .action-bar {
      display: block !important;
      a{
        display: block;
      }
    }
  }
}

@media (min-width: @screen-sm-min) {
  .action-bar-wrapper {
    > .row {
      border-top: none;
    }
    .action-bar {
      flex-direction: row;
      align-items: stretch;
      justify-content: center;
      border-top: 2px solid @texts-gray;
      border-left: 2px solid @texts-gray;
      border-right: 2px solid @texts-gray;
      border-bottom: 2px solid @texts-gray;
      font-family: @font-family-menu-sans-serif;
      font-size: 0.875rem;
      svg {
        display: none;
      }
      a {
        height: 3.8rem;
        line-height: 3.8rem;
        position: relative;
        flex-grow: 1;
        text-align: center;
        color: @dark-gray;
        flex-basis: 0;
        border-bottom: none;
        & + a {
          border-left: 2px solid @texts-gray;
        }
        &:hover {
          color: @main-green;
        }
      }
      a.active {
        color: @main-green;
        background-color: @white;
        border-left-color: @main-green;
        border-bottom: none;
        &:before {
          content: " ";
          position: absolute;
          top: -2px;
          left: -2px;
          right: -2px;
          background-color: @main-green;
          height: 2px;
        }
        &:after {
          content: " ";
          position: absolute;
          bottom: -2px;
          left: 0;
          right: 0;
          background-color: @white;
          height: 2px;
        }
        & + a {
          border-left: 2px solid @main-green;
        }
      }
    }
    .active-center,
    .active-first,
    .active-last {
      border-bottom: 2px solid @main-green;
      a {
        display: block;
      }
    }
  }
}
@media (min-width: @screen-md-min) {
  .action-bar-wrapper {
    .action-bar {
      font-size: 1rem;
    }
  }
}
@media (min-width: @screen-lg-min) {
  .action-bar-wrapper {
    > .row {
      margin-top: 4rem;
    }
    .action-bar {
      font-size: 1.1875rem;
      a {
        line-height: 6.77rem;
        height: 6.77rem;
        display: inline-block;
      }
    }
  }
}
.business {
  .action-bar-wrapper {
    background-color: @sand-white;
    > .row {
      border-top: 2px solid @texts-gray;
    }
    .action-bar {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      font-family: @font-family-menu-sans-serif;
      font-size: 1rem;
      a {
        position: relative;
        height: 3.8rem;
        line-height: 3.8rem;
        flex-grow: 1;
        text-align: center;
        color: @dark-gray;
        flex-basis: 0;
        border-bottom: 2px solid @texts-gray;
        &:hover {
          color: @dark-green;
        }
      }
      a.active {
        background-color: @dark-green;
        color: @white;
        border-bottom: @dark-green;
        svg {
          fill: @white;
          transform: rotate(270deg);
        }
      }
      svg {
        position: absolute;
        height: 0.875rem;
        width: 0.4rem;
        transform: rotate(90deg);
        right: 1.75rem;
        top: 50%;
        margin-top: -0.4rem;
        .transition();
      }
    }
    .active-center,
    .active-first,
    .active-last {
      border-bottom: none;
      border-top-color: @dark-green;
      a {
        display: none;
        &.active {
          display: block;
        }
      }
      .action-bar {
        border-bottom: none;
      }
    }
    .active-first {
      .action-bar {
        border-left-color: @dark-green;
      }
    }
    .active-last {
      .action-bar {
        border-right-color: @dark-green;
      }
    }
  }
  @media (min-width: @screen-sm-min) {
    .action-bar-wrapper {
      > .row {
        border-top: none;
      }
      .action-bar {
        flex-direction: row;
        align-items: stretch;
        justify-content: center;
        border-top: 2px solid @texts-gray;
        border-left: 2px solid @texts-gray;
        border-right: 2px solid @texts-gray;
        border-bottom: 2px solid @texts-gray;
        font-family: @font-family-menu-sans-serif;
        font-size: 0.875rem;
        svg {
          display: none;
        }
        a {
          height: 3.8rem;
          line-height: 3.8rem;
          position: relative;
          flex-grow: 1;
          text-align: center;
          color: @dark-gray;
          flex-basis: 0;
          border-bottom: none;
          & + a {
            border-left: 2px solid @texts-gray;
          }
          &:hover {
            color: @dark-green;
          }
        }
        a.active {
          color: @dark-green;
          background-color: @sand-white;
          border-left-color: @dark-green;
          border-bottom: none;
          &:before {
            content: " ";
            position: absolute;
            top: -2px;
            left: -2px;
            right: -2px;
            background-color: @dark-green;
            height: 2px;
          }
          &:after {
            content: " ";
            position: absolute;
            bottom: -2px;
            left: 0;
            right: 0;
            background-color: @sand-white;
            height: 2px;
          }
          & + a {
            border-left: 2px solid @dark-green;
          }
        }
      }
      .active-center,
      .active-first,
      .active-last {
        border-bottom: 2px solid @dark-green;
        a {
          display: block;
        }
      }
    }
  }
  @media (min-width: @screen-md-min) {
    .action-bar-wrapper {
      .action-bar {
        font-size: 1rem;
      }
    }
  }
  @media (min-width: @screen-lg-min) {
    .action-bar-wrapper {
      > .row {
        margin-top: 4rem;
      }
      .action-bar {
        font-size: 1.1875rem;
        a {
          line-height: 6.77rem;
          height: 6.77rem;
        }
      }
    }
  }
}


.business{
  .tab-bg-sandy{
    background-color: @sand-white;
  }
}
html[lang="ar"] {
  *[dir="rtl"] {
    .action-bar-wrapper {
      .active-first {
        .action-bar {
          border-right-color: @main-green;
        }
      }
      .active-last {
        .action-bar {
          border-left-color: @main-green;
        }
      }
    }
    @media (min-width: @screen-sm-min) {
      .action-bar-wrapper {
        .action-bar {
          a.active {
            border-right-color: @main-green;
            & + a {
              border-right: 2px solid @main-green;
            }
          }
        }
      }
    }
    .business {
      .action-bar-wrapper {
        .active-first {
          .action-bar {
            border-right-color: @dark-green;
          }
        }
        .active-last {
          .action-bar {
            border-left-color: @dark-green;
          }
        }
      }
      @media (min-width: @screen-sm-min) {
        .action-bar-wrapper {
          .action-bar {
            a.active {
              border-right-color: @dark-green;
              & + a {
                border-right: 2px solid @dark-green;
              }
            }
          }
        }
      }
    }
    .action-bar-wrapper {
      .action-bar {
        a+a {
          border-left: none;
          border-right: 2px solid @texts-gray;
        }
        svg {
          left: 1.75rem;
          right: auto;
        }
      }
    }
  }
}
