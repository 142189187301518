.config-section-wrap{
  width:100%;
  background-color: @sand-white-50;
  font-family: @font-family-menu-sans-serif;

  .dropdown-toggle,
  .config-section-cta a,
  .config-section a,
  .config-section-main-label{
    font-size: 0.875rem;
    line-height: 1.5rem;
    padding-top: 0.625rem;
    padding-bottom: 0.8125rem;
  }

  .config-section-main-label-top{
    display: block;
    z-index: 2;
    line-height: 2.25rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: normal;
    color: @lighty-gray;
    background-color: @sand-white-50;
  }



  .config-section a,
  .dropdown-toggle{
    padding-top: .72rem;
  }

  .dropdown-toggle{

    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    text-transform: uppercase;
    border-bottom: 1px solid @sand-white;

    .current-dropdown-label{
      padding-left: 2rem;
      color: @almost-black;
      font-size: 1.25rem;
    }
    #dropdown-menu-arrow-img{
      width: 1.2rem;
      height: 0.8rem;
      margin-right: 1.35rem;
      stroke: @main-green;
      stroke-width: .125rem;
    }
  }
  .dropdown.open{
    #dropdown-menu-arrow-img{
      transform: rotate(180deg);
    }
  }


  .config-section-cta a{
    color: @sand-white-50;
    background-color: @main-green;
    display: block;
    text-align: center;
    font-style: 1rem;
    letter-spacing: 0.125rem;
  }
  .config-section-main-label{
    color: @lighty-gray;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
    &.do-not-uppercase{
      text-transform: none;
    }
  }

  .dropdown-menu{
    box-shadow: none;
    // top: 200%;
    width: 100%;
    margin: 0;
    padding: 0;

    li{
      width:100%;
    }
  }

  .config-section{
    background-color: @white;
    justify-content: flex-start;
    text-transform: uppercase;
    border: none;
    padding-top: 1rem;
    li{
      margin-bottom: 0;
    }
    a{
      display: inline-block;
      width: 100%;
      border: none;
      margin: 0;
      color: @almost-black;
      padding-left: 2rem;
      padding-right: 1rem;
      font-size: 1.25rem;
    }
    .highlight a{
      color: @main-green;
    }
    .active.highlight a,
    .active a,
    .active a:active,
    .active a:focus{
      color: @main-green;
      &:hover{
        color: @white;
      }
      &:before{
        position: absolute;
        content: " ";
        width: 0.25rem;
        height: 1.25rem;
        background-color: @main-green;
        left: 0;
        top: 0.9rem;
      }

    }
    .active a,
    .active a:hover,
    .active a:active,
    .active a:focus,
    a:hover,
    a:active,
    a:focus{
      border: none;
    }



    .dropdown-close-button{
      a{
        color: @dark-gray;
        text-align: right;
        font-size: 0.875rem;
        padding-top: 0.9375rem;
        padding-bottom: 0.5rem;
        text-transform: uppercase;
        font-family: @font-family-sans-serif;
        font-weight: normal;
      }
      #close-menu-arrow-img{
        width: 1.2rem;
        height: 0.8rem;
        margin-left: 1rem;
        margin-right: 0.35rem;
        stroke: @main-green;
        stroke-width: .125rem;
        transform: rotate(180deg);
      }
      border-top: 1px solid @sand-white;
      margin-top: 2.4375rem;
    }
  }
  &.affix{
    left: 0;
    width: 100%;
    top: 50px;
    z-index: 6;
    border-bottom: 1px solid @sand-white;
  }
}


.business{
  .config-section-wrap{
    .dropdown-toggle{
      #dropdown-menu-arrow-img{
        stroke: @dark-green;
      }
    }
    .config-section-cta a{
      background-color: @dark-green;
    }

    .config-section{
      .highlight a{
        color: @dark-green;
      }
      .active.highlight a,
      .active a,
      .active a:active,
      .active a:focus{
        color: @dark-green;
        &:hover{
          color: @white;
        }
        &:before{
          background-color: @dark-green;
        }
      }
      .dropdown-close-button{
        #close-menu-arrow-img{
          stroke: @dark-green;
        }
      }
    }
  }
}


@media (min-width: @screen-sm-min) {
  .config-section-wrap {

    .dropdown{
      width:100%;
    }
    .dropdown-toggle {
      display: none;
    }
    .config-section-cta a,
    .config-section a,
    .config-section-main-label{
      font-size: 0.875rem;
      line-height: 1.125rem;
      padding-top: 1.5rem;
      padding-bottom: 1.4rem;
    }
    .config-section-menu-wrap{
      display: flex;
      justify-content: center;
    }
    .config-section-menu-row{
      display: flex;
      background-color: @sand-white-50;
    }
    .dropdown-menu{
      position: static;
      margin:0;
      padding:0;
      width:auto;
      top: 0;
      li{
        width: auto;
      }
    }
    .config-section {
      background: @sand-white-50;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      padding-top: 0;
      a{
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 1.125rem;
        border-bottom: 3px solid transparent;
        background: transparent;
      }
      a:focus{
        background: transparent;
      }
      li.active>a:focus,
      li.active>a{
        border-bottom: 3px solid @main-green;
        background: transparent;
      }
      li.active>a:hover,
      a:hover{
        background-color: @main-green;
        border-bottom: 3px solid @main-green;
        color: @white;
      }
      .active a:hover,
      .active a:focus,
      .active a{
        color: @almost-black;
        &:after {
          display: none;
        }
        &:before{
          display: none
        }
      }
      li:first-child{
        margin-left: -1rem;
      }
    }
  }

  .business {
    .config-section-wrap {
      .config-section {
        li.active>a:focus,
        li.active>a{
          border-color: @dark-green;
        }
        li.active>a:hover,
        a:hover{
          background-color: @dark-green;
          border-color: @dark-green;
        }
      }
    }
  }
  [dir=rtl] .config-section-wrap .config-section li:first-child {
      margin-left: 0rem;
      margin-right: -1rem;
  }
}
@media (min-width: @screen-md-min) {
  .config-section-wrap {
    .config-section {
      a{
        font-size: 1.25rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
      }
      li,
      li:first-child {
        margin-left: 0rem;
        margin-right: 0rem;
      }
    }
  }
}
@media (min-width: @screen-lg-min) {
  .config-section-wrap {

    .config-section-cta a,
    .config-section a,
    .config-section-main-label{
      font-size: 1.5rem;
      line-height: 1.5rem;
      padding-top: 1.375rem;
      padding-bottom: 1.125rem;
    }
    .config-section {
      width: 100%;
      justify-content: flex-start;
      a{
        font-size: 1.5rem;
      }
      li,
      li:first-child{
        margin-right: 0rem;
        margin-left: 0rem;
      }
    }

    &.affix{
      border-bottom-width: 1.5rem;
    }
  }
}
@media (min-width: @screen-xl-min) {

}


*[lang="ar"] {
  *[dir="rtl"] {
    .config-section-wrap{
      .dropdown-toggle{
        .current-dropdown-label{
          padding-right: 2rem;
          padding-left: 0;
        }
        #dropdown-menu-arrow-img{
          margin-left: 1.35rem;
          margin-right: 0;
        }
      }
      .dropdown-menu{
        li{
          text-align: right;
        }
      }
      .config-section{
        a{
          padding-right: 2rem;
          padding-left: 1rem;
        }
        .active.highlight a,
        .active a,
        .active a:active,
        .active a:focus{
          color: @main-green;
          &:hover{
            color: @white;
          }
          &:before{
            position: absolute;
            content: " ";
            width: 0.25rem;
            height: 1.25rem;
            background-color: @main-green;
            left: auto;
            right: 0;
            top: 0.9rem;
          }
        }
        .dropdown-close-button{
          a{
            text-align: left;
          }
        }
      }
    }
    @media (min-width: @screen-sm-min) {
      .config-section-wrap{
      }
    }
    @media (min-width: @screen-lg-min) {

    }
  }
}
