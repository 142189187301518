
.createTemplateWrap {
    padding-bottom: 0;
    h5 {
      color: @black;
      font-size: 1.2rem;
    }
    hr {
      margin:10px 0px 65px 0px;
      width: 100%;
      border-top: solid 2px @cold-gray;
    }
    ul {
      margin-top: 15px;
      li {
        line-height: 16px;
        padding-top: 0.2rem;
        &::before {
          content: '-';
          padding-right: 15px;
        }
      }
    }
    .btn {
      margin-top: 0;
    }
    .btn.uploadLogo {
      background-color: @white;
      height: 100%;
      line-height: 25px;
      padding: 15px;
      border-color: @gray;
      position: relative;
      .borderedFrame {
        border: dashed 2px @cold-gray;
        padding-top:  60px;
        width: 190px;
        height: 190px;
        border-radius: 5px;
        font-size: 1.2rem;
        text-align: center;
      }
      .borderedFrame.imageUploaded {
        border: none;
        padding: 0px 0px;
        border-radius: 0px;
        text-align: center;
        width: 190px;
        height: 190px;
        overflow: hidden;
        img {
          max-width: 100%;
        }
        .deleteBackground {
          position: absolute;
          top: 0px;
          right: 0px;
          z-index: 6;
          background: #fff;
          padding: 5px;
          svg {
            width: 22px;
            height: 22px;
          }
        }
      }
    }
    .form-title {
      margin-bottom: 30px;
    }
    .form {
      .inputfile+label {
        max-width: 90%;
      }
    }
}


@media (max-width: 767px) {
  .config-upselling-noBg {
      hr {
        margin:30px 0px 30px 0px;
      }
      .action-buttons-container {
        margin-bottom: 20px;
      }
    }
    .createTemplateWrap {
      .btn {
        padding: 0 5rem;
      }
    }
}
