/* -----------------------------------------
Moved from #03
----------------------------------------- */
.hero-image-search {
  min-height: 31.25rem;

  .hero-images-ver-H {

    //dropdown menu with input text box design
    .form {

      .all-cat {
        max-height: 4.938rem;
        float: left;
        max-width: 33%;
        .form-section {
          margin-bottom: 0;
        }
      }
      .category-dropdown {

        .floating-label-select {
          margin-bottom: 0;
        }
        .hero-image-custom {
          height: 100%;
        }
        //select2
        .selection {
          max-width: 100%;
        }
        .select2.select2-container {
          overflow: hidden;
          padding-left: 1rem;
          padding-right: 1rem;
          .select2-selection--single {
            .select2-selection__rendered {
              span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }

        @media (min-width: @screen-ms-min) {
          .select2.select2-container {
            height: 3rem;
            padding-left: 1rem;
          }

          .select2-container.select2-container--open {
            .select2-dropdown {
              top: -19px;
              padding: 0.5rem 0;
            }
            .select2-results__option {
              padding-left: 1rem;
            }
          }
        }
        @media (min-width: @screen-sm-min) {
          .select2.select2-container {
            height: 3.5rem;
            padding-left: 1rem;
            font-size: 0.875rem;
            .select2-selection--single {
              .select2-selection__rendered {
                font-size: 0.875rem;
              }
            }
          }

          .select2-container.select2-container--open {
            .select2-dropdown {
              top: -19px;
              padding: 0.5rem 0;
            }
            .select2-results__option {
              padding-left: 1rem;
              font-size: 0.875rem;
            }
          }
        }
        @media (min-width: @screen-md-min) {
          .select2.select2-container {
            height: 3.5rem;
            //padding-left: 2rem;
            font-size: 0.875rem;
            .select2-selection--single {
              .select2-selection__rendered {
                font-size: 0.875rem;
              }
            }
          }

          .select2-container.select2-container--open {
            .select2-dropdown {
              top: -19px;
              padding: 0.5rem 0;
            }
            .select2-results__option {
              padding-left: 2rem;
              font-size: 0.875rem;
            }
          }
        }
        @media (min-width: @screen-lg-min) {
          .select2.select2-container {
            height: 4.938rem;
            padding-left: 1rem;
            font-size: 1rem;
            .select2-selection--single {
              .select2-selection__rendered {
                font-size: 1rem;
              }
            }
          }

          .select2-container.select2-container--open {
            .select2-dropdown {
              top: -16px;
              padding: 1rem 0;
            }
            .select2-results__option {
              padding-left: 1rem;
              font-size: 1rem;
            }
          }
        }
        //select2
      }
    }
    //option 1

    .form .search-group .form-group.input-group {
      &.help-box {
        .all-cat {
          background-color: @white;
          //flex: 1 3 25%;

        }
        .search-style {
          //flex: 3 1 75%;
          span {
            height: 100%;
            top: 0;
          }
          .btn {
            height: 100%;
            &.search-btn {
              svg {
                height: 19px;
                width: 25px;

              }
            }
          }
        }
      }
    }
    .form {
      .search-group {
        .form-group {
          input {
            &.form-control {

              border: 0;
            }
          }

          .close {
            svg {
              fill: @texts-gray;
            }
          }
        }
      }
    }
  }
}

.search-results.support-search-result {
  background-color: @sand-white;
  .page-nav-wrap {
    .page-nav {

      li.highlight {
        a {
          color: @almost-black;
          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 4px;
            left: 0px;
            bottom: 0px;
            background-color: @main-green;
          }

        }
      }
    }
  }
  .search-results-items {
    padding: 2.5rem 0;
    .search-results-item {
      background-color: @white;
      padding: 0.9375rem;
      margin-bottom: 1.875rem;
      &:last-child {
        margin-bottom: 0;
      }
      @media (min-width: @screen-sm-min) {
        padding: 0.9375rem 1.875rem;
      }
      @media (min-width: @screen-md-min) {
        padding: 0.9375rem 1.25rem;
      }

      @media (min-width: @screen-lg-min) {
        padding: 1.875rem 3.75rem;
      }
      a {
        font-size: 1.25rem;
        color: @almost-black;
        padding: 0px;
        margin: 0 0 0.3125rem 0;
        @media (min-width: @screen-lg-min) {
          margin: 0 0 0.9375rem 0;
        }
        &:hover {
          color: @main-green;
        }
      }
      p {
        font-size: 0.8125rem;
        color: @gray-fill;
        padding: 0;
        margin: 0;
      }
    }

    .loadmore {
      padding: 3.75rem 0 1.25rem 0;
      .loader {
        display: none;
        margin: auto;
        height: 68px;
        > div {
          top: 50%;
          margin-top: -24px;
          left: 50%;
          margin-left: -24px;
        }
      }
    }

    &.loading {
      .loadmore {
        a {
          display: none;
        }
        .loader {
          display: block;
        }
      }

    }
  }
}
