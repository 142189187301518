@select2-size: 1.5rem;
@select2-line: 2.625rem;
@select2-padding: 0.5rem;

#help-me.tab-closed {
  height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.help-me .main-text {
  background-color: @white;
}

.help-me {
  color: @dark-gray;
  text-align: center;
  padding-top: 0.625rem;

  label {
    display: block;
    margin: 0 0 @select2-line/2;
    padding: 0;
    font-family: @font-family-menu-sans-serif;
    font-size: @select2-size;
    letter-spacing: 0.125rem;
    line-height: @select2-line;
    font-weight: normal;

    &:hover {
      background: inherit;
    }

    &.invisible {
      visibility: hidden;
      height: 0;
    }
  }

  .help-me-console {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 8rem;
    position: relative;

    .btn {
      margin: 1rem;
    }

    p {
      letter-spacing: 0.2rem;
      text-transform: uppercase;
      position: absolute;
      width: 100%;
      left: 0;
      top: 50%;
      margin: 0;
      margin-top: 2rem;
    }
  }

  //select2
  .select2.select2-container {
    box-sizing: content-box;
    margin: 0;
    padding-left: @select2-padding;
    padding-right: @select2-padding*3;
    position: relative;
    top: -3px;
    text-align: left;
    background-color: transparent;
    .select2-selection--single {
      border: 0;
      border-radius: 0;
      outline: none;
      height: @select2-line;
      background-color: transparent;
      &:after {
        content: '';
        background-color: @main-green;
        width: 100%;
        height: 0.125rem;
        position: absolute;
        left: 0;
        bottom: -0.125rem;
      }
      &:focus {
        outline: 0;
      }
      .select2-selection__rendered {
        color: @main-green;
        font-family: @font-family-menu-sans-serif;
        font-size: @select2-size;
        letter-spacing: 0.125rem;
        line-height: @select2-line;
        font-weight: normal;
        background-color: transparent;
        height: @select2-line;
        padding: 0;
        border: 0;
        //text-overflow: clip;
      }
    }
    .select2-selection__arrow {
      top: @select2-line*0.75;
      right: @select2-padding*2;
      height: 0;
      width: 0;
      b {
        border-color: @main-green transparent transparent;
        border-width: @select2-size*0.25 @select2-size*0.25 0;
      }
    }
    &.select2-container--open .select2-selection--single .select2-selection__arrow {
      b {
        border-color: transparent transparent @main-green;
        border-width: 0 @select2-size*0.25 @select2-size*0.25;
      }
    }
  }

  .select2-dropdown {
    text-align: left;
    background-color: @sand-white-50;
    border: 0;
    border-radius: 0;
    left: 0;
    margin-left: @select2-padding*4;
    //top: -@select2-line;
    //top: 0.125rem;
    box-shadow: 0 0.0625rem 0.25rem 0 rgba(1, 1, 1, 0.3);
  }
  .select2-results__options {
    max-height: @select2-line*5 !important;
  }
  .select2-results__option {
    color: @dark-gray;
    font-family: @font-family-menu-sans-serif;
    font-size: @select2-size;
    letter-spacing: 0.125rem;
    line-height: @select2-line*1.5;
    font-weight: normal;
    box-sizing: content-box;
    margin: 0;
    padding: 0 @select2-padding*6;
    //padding-left: @select2-padding;
    //overflow: hidden;
    //text-overflow: ellipsis;
    white-space: nowrap;
    //max-width: 100%;
    .transition(@menu-transition-duration, ease-in-out);
    &:first-of-type {
      padding-top: @select2-line/4;
    }
    &:last-of-type {
      padding-bottom: @select2-line/4;
    }
  }
  .select2-results__option--highlighted {
    background-color: transparent !important;
    color: @main-green !important;
  }
  .select2-results__option[aria-selected=true] {
    background-color: transparent !important;
    //background-color: @light-sand-brown !important;
    color: @main-green !important;
    text-decoration: underline;
  }
  .select2-container--focus {
    background-color: transparent !important;
  }
  .select2-dropdown--above {
  }
  //select2

}

#help-me-form {
  margin-bottom: 5rem;
}

/*
 * Business Variant
 */
.business {
  .help-me {
    background-color: @light-gray;
  }
}

/*
 * @media
 */
@media (min-width: @screen-md-min) and (max-width: @screen-lg-min) {
  @select2-size: 1.25rem;
  @select2-line: 2.25rem;
  .help-me {
    label {
      margin: 0 0 @select2-line/2;
      font-size: @select2-size;
      line-height: @select2-line;
    }
    //select2
    .select2.select2-container {
      .select2-selection--single {
        height: @select2-line;
        .select2-selection__rendered {
          font-size: @select2-size;
          line-height: @select2-line;
          height: @select2-line;
        }
      }
      .select2-selection__arrow {
        top: @select2-line*0.75;
        b {
          border-width: @select2-size*0.25 @select2-size*0.25 0;
        }
      }
      &.select2-container--open .select2-selection--single .select2-selection__arrow {
        b {
          border-width: 0 @select2-size*0.25 @select2-size*0.25;
        }
      }
    }
    .select2-results__options {
      max-height: @select2-line*5 !important;
    }
    .select2-results__option {
      font-size: @select2-size;
      line-height: @select2-line*1.5;
      &:first-of-type {
        padding-top: @select2-line/4;
      }
      &:last-of-type {
        padding-bottom: @select2-line/4;
      }
    }
    //select2
  }
}

@media (min-width: @screen-sm-min) and (max-width: @screen-md-min) {
  @select2-size: 1.125rem;
  @select2-line: 2.125rem;
  .help-me {
    label {
      margin: 0 0 @select2-line/2;
      font-size: @select2-size;
      line-height: @select2-line;
    }
    //select2
    .select2.select2-container {
      .select2-selection--single {
        height: @select2-line;
        .select2-selection__rendered {
          font-size: @select2-size;
          line-height: @select2-line;
          height: @select2-line;
        }
      }
      .select2-selection__arrow {
        top: @select2-line*0.75;
        b {
          border-width: @select2-size*0.25 @select2-size*0.25 0;
        }
      }
      &.select2-container--open .select2-selection--single .select2-selection__arrow {
        b {
          border-width: 0 @select2-size*0.25 @select2-size*0.25;
        }
      }
    }
    .select2-results__options {
      max-height: @select2-line*5 !important;
    }
    .select2-results__option {
      font-size: @select2-size;
      line-height: @select2-line*1.5;
      &:first-of-type {
        padding-top: @select2-line/4;
      }
      &:last-of-type {
        padding-bottom: @select2-line/4;
      }
    }
    //select2
  }
}

@media (max-width: @screen-sm-min) {
  @select2-size: 1rem;
  @select2-line: 2rem;
  .help-me {
    label {
      display: inline;
      margin: 0 0 @select2-line/2;
      font-size: @select2-size;
      line-height: @select2-line;
    }
    //select2
    .select2.select2-container {
      .select2-selection--single {
        height: @select2-line;
        .select2-selection__rendered {
          font-size: @select2-size;
          line-height: @select2-line;
          height: @select2-line;
        }
      }
      .select2-selection__arrow {
        top: @select2-line*0.75;
        b {
          border-width: @select2-size*0.25 @select2-size*0.25 0;
        }
      }
      &.select2-container--open .select2-selection--single .select2-selection__arrow {
        b {
          border-width: 0 @select2-size*0.25 @select2-size*0.25;
        }
      }
    }
    .select2-dropdown {
      //margin-left: 0;
    }
    .select2-results__options {
      max-height: @select2-line*5 !important;
    }
    .select2-results__option {
      font-size: @select2-size;
      line-height: @select2-line*1.5;
      &:first-of-type {
        padding-top: @select2-line/4;
      }
      &:last-of-type {
        padding-bottom: @select2-line/4;
      }
    }
    //select2
  }
}

/*
 * RTL support
 */
html[lang="ar"] {
  *[dir="rtl"] {

    .help-me {
      font-family: @font-arabic;
      label {
        font-family: @font-arabic;
        font-size: 1.25rem;
      }

      .help-me-console {
        p {
          right: 0;
          left: auto;
        }
      }

      //select2
      .select2.select2-container {
        padding-left: @select2-padding*3;
        padding-right: @select2-padding;
        text-align: right;
        background-color: transparent;
        .select2-selection--single {
          font-size: 1.25rem;
          &:after {
            left: auto;
            right: 0;
          }
          &:focus {
          }
          .select2-selection__rendered {
            font-family: @font-arabic;
            font-size: 1.25rem;
          }
        }
        .select2-selection__arrow {
          left: @select2-padding*2;
          right: auto;

        }

      }
      .select2-dropdown {
        text-align: right;
        left: auto;
        right: 0;
        margin-right: @select2-padding*4;
        margin-left: inherit;
        font-size: 1.25rem;
      }
      .select2-results__options {
        font-size: 1.25rem;
      }
      .select2-results__option {
        font-family: @font-arabic;
        font-size: 1.25rem;

      }
      //select2

    }
  }
}
