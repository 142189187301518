/*
============================================
01 - editorial-placement
02 - xs viewport
03 - sm viewport
04 - md viewport
05 - lg viewport
06 - xl viewport
07 - Business overrides
08 - Arabic overrides
============================================



/* -----------------------------------------
01 - editorial-placement
----------------------------------------- */
.editorial-placement {
  text-align: center;
  .placement-caption{
        .placement-caption-container{
            &.rect-responsive{
              padding-bottom: 77.333%;
              }
        }

  }
  .placement-photo {
    height: 64vw;
    overflow: hidden;
    position: relative;

    div.cover:after {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0;
      .transition();
    }

    #read-more-triangle-placementphoto {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -14px;
      margin-right: -9px;
      transform: rotate(270deg);
      z-index: 2;
      fill: @white;
    }
  }


  .tiles-box {
    background-color: @white;
    padding-bottom: 3.75rem;
    .transition();

    &.content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-align: left;
      padding: 10px 2rem;

       > .hidden-sm,
      > p {
        width: 100%;
      }
    }

    h4 {
      position: relative;
      margin: 0 0 1.1875rem;
      color: @almost-black;
      text-transform: uppercase;
    }

    p,
    ul {
      color: @steel-grey;
    }

    h4,
    p,
    ul {
      //TODO: to be changed with design team
      display: block;
      padding-left: 2%;
      padding-right: 2%;
    }

    ul {
      padding-top: 1rem;

      li {
        text-align: left;
        padding-bottom: 1rem;
      }
    }

    .read-more {
      background-color: @main-green;
      width: 100%;
      overflow: hidden;
      height: 3.75rem;
      text-align: center;
      text-transform: uppercase;
      color: @white;
      font-size: @font-size-base;
      letter-spacing: 0.1875rem;
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .transition();
    }

    #read-more-triangle-img {
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -5.5px;
      transform: rotate(180deg);
      fill: @white;
    }
  }
  //------------------
  .tiles-box {
        //padding-bottom:0rem;
    .tiles-icon{
      svg {
        width: 45px;
        height: 45px;
        display: inherit;
      }
    }

    .tiles-icon {
      border-bottom: 1px solid @main-green;
      padding-bottom: 0.6875rem;
    }

    h4 {
      margin: 1.3625rem 0 1.3rem;
    }

    .read-more {
      //height:0;
    }

    p,
    ul {
      font-size: 0.875rem;
    }

    .detail-info-wrap {
      margin-top: 1.0625rem;
      justify-content: left;
    }
  }
  //------------------
  .placement-box-wrap:hover {
    .placement-photo div.cover:after {
      opacity: 0.1;
    }
  }

  .placement-box-wrap-reverse {
    flex-direction: row-reverse;
  }

  .price-tag {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
    padding: 0.625rem 1.25rem;

    .detail-info-wrap-pricetag > div {
      color: @white;
    }
  }
  @media (max-width: 767px) {
    .placement-caption{
      .placement-caption-container{
        &.rect-responsive{
          padding-bottom: 60%;
        }
        .tiles-box{
          &.content{
            justify-content: center;
            align-items: flex-start;
            text-align: left;
            padding:0 1.5rem;
            h4{
              font-size: 1.5rem;
              padding: 0;
            }
            p{
              font-size: 1.1rem;
              margin-bottom: 1rem;
              padding: 0;
            }
            .btn{
              &.btn-default{
              }
            }
            .tiles-icon{
            }
          }
          .read-more{
            width: auto;
            min-width: 10rem;
            position: static;
            letter-spacing: normal;
            margin-top: 1rem;
          }
          .detail-info-wrap{
            .detail-info-wrap-pricetag{
              justify-content: left;
            }
          }
        }
      }
    }
  }
  @media (max-width: 568px) {
    .placement-caption{
      .placement-caption-container{
        &.rect-responsive{
          padding-bottom: 100%;
          min-height: 25rem;
        }
      }
    }
  }
}
/* -----------------------------------------
02 - xs viewport
----------------------------------------- */
@media (max-width: @screen-xs) {
  .editorial-placement {
    .placement-caption{
      .placement-caption-container{
          &.rect-responsive{
            padding-bottom: 85.333%;
          }
        }
      }

    .tiles-box {
      h4 {
        margin: 1.3625rem 0 1.5rem;
        font-size: 1rem;
      }

      p,
      ul {
        font-size: 0.875rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  }
}
@media (min-width: @screen-xs-min) {
  .editorial-placement {
    .placement-caption{
          .placement-caption-container{
              &.rect-responsive{
                padding-bottom: 77.333%;
              }
            }
          }

    .tiles-box {
      h4 {
        margin: 1.3625rem 0 1.5rem;
        font-size: 1rem;
      }

      p,
      ul {
        font-size: 0.875rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  }
}
/* -----------------------------------------
03 - sm viewport
----------------------------------------- */
@media (min-width: @screen-sm-min) {
  .business .editorial-placement, .editorial-placement {
    .placement-caption{
      .placement-caption-container{
          &.rect-responsive{
            padding-bottom: 140.085%; //102.085%
          }
        }
      }
      &.caption-large-8col{
        .placement-caption{
          .placement-caption-container{
              &.rect-responsive{
                padding-bottom: 70%;
              }
            }
          }
        }


    .placement-box-wrap {
      display: flex;
    }

    .placement-photo {
      height: auto;
    }

    .tiles-box {
      padding-bottom: 0;
      .tiles-icon{
        svg {
          width: 45px;
          height: 45px;
        display: inherit;
        }
      }

      .tiles-icon {
        border-bottom: 1px solid @main-green;
        padding-bottom: 0.6875rem;
      }

      h4 {
        margin: 1.3625rem 0 1.5rem;
        font-size: 1rem;
      }

      .read-more {
        height: 0;
      }

      p,
      ul {
        font-size: 0.875rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }

      .detail-info-wrap {
        margin-top: 1.0625rem;
        padding-left: 0.5rem;
      }
    }

    .placement-box-wrap:hover {
      .tiles-box {
        padding-bottom: 3.75rem;
      }

      .read-more {
        height: 3.75rem;
      }
    }

    .price-tag {
      right: 3.125rem;
    }
  }

  .editorial-placement-double {
    display: flex;
  }

  .editorial-placement-triple {
    > div {
      .tiles-box {
        border-top: solid 1px @table-border-color;
        border-bottom: solid 1px @table-border-color;
      }

      &:nth-child(2) {
        .tiles-box {
          border-left: solid 1px @table-border-color;
          border-right: solid 1px @table-border-color;
        }
      }
    }
  }
}
/* -----------------------------------------
04 - md viewport
----------------------------------------- */
@media (min-width: @screen-md-min) {
  //readmore button css for b2c cms business user .business .editorial-placement,
  .business .editorial-placement, .editorial-placement {
    .placement-caption{
      .placement-caption-container{
          &.rect-responsive{
            padding-bottom: 102.085%;
          }
        }
      }
      &.caption-large-8col{
        .placement-caption{
          .placement-caption-container{
              &.rect-responsive{
                padding-bottom: 51%;
              }
            }
          }
        }

    .tiles-box {
      .tiles-icon{
        svg {
          width: 50px;
          height: 50px;
      }
    }

      .tiles-icon {
        padding-bottom: 0.9375rem;
      }

      h4 {
        margin: 1.3125rem 0 1.375rem;
      }

      h4,
      p {
        padding-left: 5%;
        padding-right: 5%;
      }

      p {
        font-size: 0.875rem;
      }

      .detail-info-wrap {
        margin-top: 1.0625rem;
      }
    }

    .placement-box-wrap:hover {
      .tiles-box {
        padding-bottom: 5rem;
      }

      .read-more {
        height: 5rem;
      }
    }

    .price-tag {
      padding: 1.375rem 2.75rem;
      right: 3.125rem;
    }
  }
}
/* -----------------------------------------
05 - lg viewport
----------------------------------------- */
@media (min-width: @screen-lg-min) {
  .business .editorial-placement, .editorial-placement {

    .placement-caption{
      .placement-caption-container{
          &.rect-responsive{
            padding-bottom: 97.03%;
          }
        }
    }
    &.caption-large-8col{
      .placement-caption{
        .placement-caption-container{
            &.rect-responsive{
              padding-bottom: 48.5%;
            }
          }
      }
    }

    .tiles-box {
      .tiles-icon {
        margin-top: 2rem;
        width: auto !important;
        svg{
          width: 60px;
          height: 60px;
        }
      }

      h4 {
        font-size: 1.25rem;
      }

      h4,
      p {
        padding-left: 0;
        padding-right: 0;
        display: inline-block;
      }

      p,
      ul {
        font-size: 1rem;
      }

      .detail-info-wrap {
        margin-top: 2rem;
        margin-bottom: 3rem;
      }

      div {
        width: auto;
        //display: inline-block;
      }
    }
    /* @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .tiles-box div {
        width: 100%;
        display: inline-block;
      }
    }*/
    .placement-box-wrap:hover {
      .tiles-box {
        padding-bottom: 7.5rem;
      }

      .read-more {
        height: 7.5rem;
      }
    }
  }
}
/* -----------------------------------------
08 - Arabic overrides
----------------------------------------- */

*[lang="ar"] {
  *[dir="rtl"] {
    .editorial-placement{
      .placement-caption{
        .placement-caption-container{
          .tiles-box{
            &.content{
              text-align: right;
            }
          }
        }
      }
      @media (max-width: 767px) {
        .placement-caption{
          .placement-caption-container{
            .tiles-box{
              &.content{
                text-align: right;
              }
            }
          }
        }
      }
    }
    
    .price-tag {
      left: 0;
      right: auto;
    }

    .editorial-placement .tiles-box ul li {
      text-align: right;
    }
    .editorial-placement .placement-photo #read-more-triangle-placementphoto {
      transform: rotate(90deg);
      margin-left: -9px;
      right: auto;
      left: 0;
      -ms-transform: rotate(-90deg);
    }

    @media (min-width: @screen-sm-min) {
      .editorial-placement {
        .price-tag {
          padding: 1.375rem 2.75rem;
          left: 3.125rem;
          right: auto;
        }
      }
    }
    @media (min-width: @screen-md-min) {
      .editorial-placement {
        .price-tag {
          padding: 1.375rem 2.75rem;
          left: 3.125rem;
          right: auto;
        }
      }
    }
    @media (min-width: @screen-lg-min) {
      .editorial-placement .price-tag {
        left: 3.125rem;
        right: auto;
      }

      .tiles-box {
        h4 {
          font-size: 1.5rem;
        }

        p,
        ul {
          font-size: 1rem;
        }

        .read-more {
          font-size: @font-size-arabic-base;
        }
      }
    }
  }
}

/* -----------------------------------------
07 - Business overrides
----------------------------------------- */
.business {
  .tiles-box {
    .read-more {
      background-color: @dark-green;
    }
  }

  .editorial-placement {
    .tiles-icon {
      border-bottom: 1px solid @dark-green;
    }
  }
}
