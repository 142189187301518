.login {
  background-color: @sand-white;
  padding-top: 6rem;
  padding-bottom: 4rem;
  text-align: center;

  h3 {
    text-transform: uppercase;
    padding-top:0;
    margin-top:0;
    letter-spacing: 0;
  }
  h4 {
    margin-top: 5rem;
    margin-bottom: 5rem;
    color: @main-green;
    letter-spacing: 0;
    text-transform: uppercase;
  }

  .has-divider .divider{
    width: 100%;
    height: 1px;
    background: #d1d2d1;
    position: relative;
    /* top: 20%; */
    /* left: 50%; */
    margin: 1rem auto;
    z-index: 1;
    display: inline-block;
  }

  .form {
    margin-top: 0;
    margin-bottom: 0;

    &.form-double > .form-section {
      margin: 0;
      padding: 0;
      &:nth-child(1) {
        padding-right: 3.75rem;
      }
      &:nth-child(2) {
        padding-left: 3.75rem;
      }
    }

    .floating-label-input {
      margin: 0;
      label {
        color: @main-green;
      }
    }
    .checkbox {
      float: left;
      clear: both;
      padding: 0;
      padding-top: 1rem;
    }

    .visualCaptcha {
      margin-top: 3.25rem;
      margin-bottom: 0;
      .visualCaptcha-explanation {
        margin: 0;
        margin-bottom: 1rem;
        color: @almost-black;
        background-color: @light-sand-brown;
      }
    }

    .btn,
    .main-text .btn {
      margin: 0;
      width: 100% !important;
    }
    .loader {
      display: none;
      height: 0;
      top: -3rem;
      left: 50%;
      margin-left: 5rem;
      &.login-loader{
        width: auto;
        height: 0;
        top: 0;
        left: 0;
        margin: 1rem auto;
      }
    }

    p.registration-problems {
      margin: 0;
      margin-top: 2rem;
      padding: 0;
      a {
        color: @almost-black;
        text-decoration: underline;
      }
    }
  }

  &.new-customer {
    background-color: @light-sand-brown;
    padding-top: 4rem;
    p {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }

  .forgot {
    font-size:.8rem;
    padding-top:20px;
    a {
      color:@custom-gray;
      padding-right:5px;
      text-decoration: underline;

      &:last-child {
        padding-left:5px;
        padding-right:0;
      }
    }

  }

}

/*
 * Business Variant
 */
.business {
  .login {
    background-color: @light-gray;

    h4 {
      color: @dark-green;
    }

    .form {
      .visualCaptcha {
        .visualCaptcha-explanation {
          color: @white;
          background-color: @premium-gray;
        }
      }
      p.registration-problems {
        display: none;
      }
    }
    &.new-customer {
      background-color: @premium-gray;
      p {
        //display: none;
      }
    }
    &.bg-white{
      background-color: @sand-white;
    }
    &.bg-white-50{
      background-color: @sand-white-50;
    }
  }
}

#account-locked {
  h4 {
    color: #fb193f !important;
  }
}
.relative {
  position: relative;
}

.login-order {
    letter-spacing: initial;
    text-transform: none;
    box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2);
    .padd-1 {
        padding: 0 35px;
    }
    .signIn {
      position: relative;
    }
    .floating-label-input {
        border: none;
        background-color: transparent;
        background-color: transparent;
        border-bottom: 1px solid #575757;
        padding-left: 0 !important;
        padding-bottom: .7rem;
        box-sizing: border-box;
        outline: 0;
        box-shadow: none;
        color: #848789;
        transition: .2s cubic-bezier(.4,0,.2,1);
    }
    .floating-label-input input {
        padding-left: 0 !important;
    }
    .floating-label-input label {
        padding: 0;
        letter-spacing: 0;
        color: #848789;
        font-size: 20px;
        top: 16px;
    }
    .checkbox label {
        padding-left: 2rem;
        text-align: left;
        font-size: 14px;
    }
    .green1-border {
      border-color: #616b1e!important;
    }
    .green1-bg {
        background: #616b1e!important;
    }
    .gray-bg3 {
        background: @light-sand-brown;
    }
    .pb20 {
      padding-bottom: 20px;
    }
    .pt20 {
        padding-top: 20px;
    }
    .w100 {
        width: 100%;
    }
    .b2b-green {
        color: @dark-green !important;
        border-color: @dark-green !important;
    }
    .gray-bg3 {
        background: @light-sand-brown;
    }
    .gray-login {
        color: @steel-grey !important;
    }
    .height-gray {
        height: 440px;
    }
    .border-top1 {
        border-top: 1px solid @gray;
    }
    .mb30 {
        margin-bottom: 30px;
    }
    .mt30 {
        margin-top: 30px;
    }
    .forget1 {
        width: 50%;
        float: left;
        margin: 26px 0;
        a {
            color: #353738;
            font-size: 14px;
            text-transform: uppercase;
            text-decoration: underline;
            font-family: Lato,Roboto,Arial,sans-serif !important;
            padding: 0 !important;
        }
    }
    .forget2 {
        border-bottom: 1px solid @gray;
        a {
            color: #353738;
            font-size: 14px;
            text-transform: uppercase;
            text-decoration: underline;
            padding: 0 10px;
            font-family: Lato,Roboto,Arial,sans-serif !important;
        }
    }
    h5{
        margin-bottom: 0 !important;
        letter-spacing: normal;
    }
    .help-me {
        padding: 0 !important;
        background: transparent;
    }
    .form-section {
        padding: 0 !important;
        margin-bottom: 10px;
        .floating-label-input {
            margin: 0 !important;
        }
        .alert-icon{
            svg{
                top: 2.5rem !important;
                right: 0.125rem !important;
            }
        }
        .alert-label{
            top: 4.9rem !important;
            text-align: left !important;
        }
    }
}
/*
 * @media
 */
@media (max-width: @screen-sm-min) {
  .login {
    .form {
      .visualCaptcha {
        margin-top: 5rem;
      }
      &.form-double > .form-section {
        &:nth-child(1) {
          padding-right: 0;
          padding-bottom: 2rem;
        }
        &:nth-child(2) {
          padding-left: 0;
        }
      }
    }
  }
.login-order {
    .form {
      padding-bottom: 5rem;
    }
   .forget1 {
     a {
       letter-spacing: 0;
     }
   }
   .height-gray {
    height: auto;
    padding: 2px 35px 40px!important;
  }
}
}

@media (min-width: @screen-md-min) {

  .login {
    .has-divider {
      position:relative;
      .divider {
        width:1px;
        height:65%;
        background:@cold-gray;
        position:absolute;
        top:20%;
        left:50%;
      }
    }
    .main-text.text-left {
      ul {
        padding-left:16px;
      }

      .btn.btn-default {
        width:100%;
        text-align:center;
      }

    }
    .body-big{
      ul{
        font-size: 1.25rem;
      }
    }
    h3{
      min-height: 80px;
    }
  }
/**/

.login {
	h3 {
		min-height: 6rem;
	}
	.has-divider {
		.col-md-6 {
			min-height: 36rem;
			form {
				min-height: 26rem;
			}
			.body-big {
				min-height: 23.5rem;
        @media (min-width: @screen-lg-min) {
          min-height: 25.3rem;
        }
        @media (min-width: 1441px) {
          min-height: 26.95rem;
        }
			}
			.form {
				.form-section.submitBtn {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
				}
			}
		}
	}
	.main-text.text-left {
		.btn.btn-default {
			position: absolute;
			left: 0;
			bottom: 1.9rem;
		}
	}
}

}
.mozBtn {
  -moz-transform: translate(0px, 12px);
}
