@business-register-form: 1.5rem;
.business-register-form {
  padding: 0;
  .form-container {
    padding-bottom: 4rem;
    padding-top: 4rem;
  }
  .form-title {
    padding-bottom: 2rem;
    text-align: center;
    color: @almost-black;
    h3 {
      letter-spacing: 0;
    }
    h5 {
      color: @steel-grey;
      letter-spacing: 0;
      padding-bottom: 1rem;
      &.notification {
        padding-bottom: 0;
        margin: 0;
        &:last-child {
          padding-bottom: 1rem;
        }
      }
      &.success-msg {
        color: @dark-green;
      }
    }
  }
  .form {
    padding-bottom: 3rem;
    .row {
      .form-section {
        margin: 0;
        padding: 0;
      }
    }
    > .form-section {
      margin: 0;
      padding: 0;
      &:nth-child(1) {}
      &:nth-child(2) {}
      &.single-section {
        padding: 0;
      }
      .floating-label-input {
        .tooltipped .tooltip.right {
          margin-left: 0.5rem;
        }
        label {}
        span {
          &.outside-icon {
            width: 2rem;
            height: 2rem;
            position: absolute;
            top: 1.7rem;
            right: 4%;
            align-items: center;
            text-align: center;
            z-index: 2;
            //background-color: @white;
          }
          svg {
            height: 24px;
            width: 24px;
            fill: @dark-green;
          }
        }
        &.has-error-fields {
          span.outside-icon {
            svg {
              fill: @pink;
            }
          }
        }
      }
      .floating-label-select label {
        &.floating-label {
          color: @steel-grey;
        }
      }
      a.forgot-login {
        float: left;
        color: @almost-black;
        text-decoration: underline;
      }
      .checkbox,
      .radio {
        color: @almost-black;
        &:nth-child(1) {
          margin-top: 0;
          padding-top: 0;
        }
        @media (min-width: @screen-md-min) and (min-width: @screen-xs-min) {
          padding-top: 16px;
          padding-bottom: 8px;
          line-height: 16px;
          input {
            //display: none;
            &[type="radio"] {
              height: 100%;
              width: 100%;
              z-index: 10;
              margin-left: 0;
              display: block;
              margin: 0 auto;
              cursor: pointer;
              opacity: 0;
              + label {
                font-size: 1.25rem;
              }
              + label:before {
                border-radius: 50%;
                height: 25px;
                width: 25px;
              }
              &:checked + label:before {
                background-size: 68%;
              }
            }
            &[type="checkbox"] {
              + label:before {
                height: 25px;
                width: 25px;
              }
              &:checked + label:before {
                background-size: 68%;
              }
            }
          }
        }
      }
      .form-group {
        &.radio {
          margin-bottom: 3rem;
        }
      }
    }
    .business-register-form-submitting {
      position: relative;
      p {
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        margin: 2rem 0 0;
      }
    }
    a {
      color: @almost-black;
      text-decoration: underline;
    }
    .business-register-form-submit,
    .business-register-form-submitting {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: @business-register-form*8;
      margin: 0;
      padding: 0;
      text-align: center;
    }
    .form-submit {
      height: 8rem;
      padding: 0;
      text-align: center;
      button {
        text-transform: uppercase;
        margin: 0.5rem;
      }
    }
  }
  .no-padding {
    padding: 0 !important;
  }
  .in-between {
    padding: 8%;
    padding-top: 0;
    margin-top: 0;
  }
  .pwd-meter-track {

    width: 100%;
    background-color: transparent;
    overflow: hidden;
    #pwd-meter {
      min-width: 4rem;
      max-width: 100%;
      font-size: 1rem;
      line-height: 2rem;
      text-align: center;
      text-transform: uppercase;
      color: @white;
      //.transition(0.3s);
      &.progress-bar-danger {
        background-color: @orange;
        margin-bottom: 2rem;
      }
      &.progress-bar-success {
        background-color: @main-green;
        margin-bottom: 2rem;
      }
      &.progress-bar-none {
        background-color: transparent !important;
        margin-bottom: 0rem;
      }
    }
  }
  .accessibility-description,
  .status.valid,
  .visualCaptcha-explanation {
    background-color: @premium-gray;
    color: @white;
  }
}
@media (min-width: @screen-xs-min) {
  .business-register-form {
    .form {
      > .form-section {
        margin: 0;
        padding: 0;
        &:nth-child(1) {
          padding-right: 0;
        }
        &:nth-child(2) {
          padding-left: 0;
        }
        &.single-section {
          padding: 0;
        }
      }
    }
    .form-submit {
      height: 10rem;
      button {
        margin: 0.5rem;
      }
    }
    .in-between {
      padding: 8%;
      padding-top: 0;
      margin-top: 0;
    }
  }
}
@media (min-width: @screen-ms-min) {
  .business-register-form {
    .form {
      .form-submit {
        height: 10rem;
        button {
          margin: 0.5rem;
        }
      }
    }
    .in-between {
      padding: 8%;
      padding-top: 0;
      margin-top: 0;
    }
  }
}
@media (min-width: @screen-sm-min) {
  .business-register-form {
    .form {
      > .form-section {
        margin: 0;
        padding: 0;
        &:nth-child(1) {
          padding-right: @business-register-form*2.5;
        }
        &:nth-child(2) {
          padding-left: @business-register-form*2.5;
        }
        &.single-section {
          padding: 0;
        }
      }
      .form-submit {
        height: 10rem;
        button {
          margin: 0.5rem;
        }
      }
    }
    .in-between {
      padding: 8%;
      margin-top: 1rem;
    }
  }
}
@media (min-width: @screen-md-min) {
  .business-register-form {
    .form {
      > .form-section {
        margin: 0;
        padding: 0;
        &:nth-child(1) {
          padding-right: @business-register-form*2.5;
        }
        &:nth-child(2) {
          padding-left: @business-register-form*2.5;
        }
        &.single-section {
          padding: 0;
        }
      }
      .form-submit button {
        margin: auto 0.5rem;
      }
    }
  }
}
@media (min-width: @screen-lg-min) {
  .business-register-form {
    .form {
      > .form-section {
        margin: 0;
        padding: 0;
        &:nth-child(1) {
          padding-right: @business-register-form*2.5;
        }
        &:nth-child(2) {
          padding-left: @business-register-form*2.5;
        }
        &.single-section {
          padding: 0;
        }
      }
      .form-submit button {
        margin: auto 0.5rem;
      }
    }
  }
}

.chat-withus {
  .form {
    .floating-label-input {
      textarea {
        color: #616b1e;
      }
    }
  }
}
/*
 * Arabic
 */
*[lang="ar"] {
  *[dir="rtl"] {
    .consumer-register-form .form .row > .form-section.last {
      padding-right: 3.75rem;
      padding-left: 0;
    }
    .business-register-form .form,
    .consumer-register-form .form,
    .login .form,
    .recover-username .form,
    .reset-password .form {
      > .row {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
      }
      > .form-section {
        display: block;
      }
      > .form-submit.form-section{
        display:flex;
      }
      .form-section {
        &:nth-child(1) {
          padding-left: 0;
          padding-right: 0;
        }
        &:nth-child(2) {
          padding-right: 0;
          padding-left: 0;
        }

      }
      @media (min-width: @screen-xs-min) {
        .form-section {
          &:nth-child(1) {
            padding-left: 0;
            padding-right: 0;
            &.single-section {
              padding-right: 0;
              padding-left: 0;
            }
            &.pull-right-ar { padding-left: 3.75rem;}
          }
          &:nth-child(2) {
            padding-right: 0;
            padding-left: 0;
            &.pull-left-ar { padding-right: 3.75rem;}
          }
        }
        > .form-section.single-section {
          padding-right: 0;
          padding-left: 0;
        }
      }
      @media (min-width: @screen-sm-min) {
      .row {
          .form-section {
            &:nth-child(1) {
              padding-left: 3.75rem;
              padding-right: 0;
              float: right;
              &.body-small {}
            }
            &:nth-child(2) {
              padding-right: 3.75rem;
              padding-left: 0;
              float: right;
              .single-section {
                padding:0;
              }
            }
            &loginNameField:nth-child(2) {
              padding-right: 0;
              float: noen;
            }
          }
        }
      }
      @media (min-width: @screen-md-min) {}
      @media (min-width: @screen-lg-min) {}
    }
    .business-register-form .form > .form-section.single-section {
      padding-right: 0;
      padding-left: 0;
    }
    .login .form .checkbox {
      float: right;
    }
    .login .form,
    .recover-username .form,
    .reset-password .form {
      .radio label {
        font-weight: 100;
        text-align: right;
      }
    }
    .padding-0 {
      padding: 0 !important;
    }

  }
}
