.steps-tracker{
    padding-top: 2.5rem;
    padding-bottom: 4rem;
    .row{
        >div{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .step-node{
                position: relative;
                .step-point{
                    display: block;
                    width: 1.625rem;
                    height: 1.625rem;
                    margin: 0;
                    padding: 0;
                    border: 2px solid @main-green;
                    border-radius: 50%;
                    &.step-done{
                        &:after {
                            content: '';
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            width: 0.75rem;
                            height: 0.75rem;
                            margin: 0;
                            padding: 0;
                            border-radius: 50%;
                            transform: translate(-50%, -50%);
                            background-color: @main-green;
                        }
                    }
                }
                h6{
                    display: block;
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    font-size: 0.875rem;
                    line-height: 1.625rem;
                    height: 1.625rem;
                    margin: 0 0 -1.625rem;
                    text-align: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    transform: translate(-50%, 0);
                    width: 20vw;
                }
            }
            .step-line{
                flex: 1;
                height: 1px;
                background-color: @main-green;
            }
        }
    }
}
.steps-tracker-B{
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: @light-gray;
    .row{
        >div{
            text-align: center;
            h6{
                display: inline-block;
                font-size: 0.875rem;
                line-height: 0.75rem;
                margin: 0 0.75rem;
                margin-left: 0;
                padding: 0;
                color: @main-green;
            }
            .step-point{
                display: inline-block;
                width: 0.75rem;
                height: 0.75rem;
                margin: 0 0.25rem;
                padding: 0;
                border: 2px solid @main-green;
                border-radius: 50%;
                background-color: #fff;
                &.step-done{
                    background-color: @main-green;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
}

.twoLine.steps-tracker .row > div .step-node h6 {
    bottom: 0;
    display: block;
    height: 2.8rem;
    left: 50%;
    line-height: 1.2rem;
    margin: 0 0 -3rem;
    overflow: hidden;
    position: absolute;
    text-align: center;
    text-overflow: ellipsis;
    transform: translate(-50%);
    white-space: nowrap;
}
.business {
    .steps-tracker{
        .row{
            >div{
                .step-node{
                    .step-point{
                        border-color: @dark-green;
                        &.step-done{
                            &:after {
                                background-color: @dark-green;
                            }
                        }
                    }
                    .step-checked {
                        background: @dark-green;
                    }
                }
                .step-line{
                    background-color: @dark-green;
                }
            }
        }
    }
    .steps-tracker-B{
        background-color: @premium-gray;
        .row{
            >div{
                h6{
                    color: @white;
                }
                .step-point{
                    border-color: @white;
                    background-color: @white;
                    &.step-done{
                        background-color: @dark-green;
                    }
                }
            }
        }
    }
    .order-tracking-steps {
      .step-point.step-checked {
        svg {
          fill: #fff;
          height: 17px;
          text-align: center;
          width: 25px;
          margin-top: 5px;
        }
      }
    }
}


@media (min-width: @screen-sm-min) {
    .steps-tracker{
        .row{
            >div{
                .step-node{
                    h6{
                        width: auto;
                    }
                }
            }
        }
    }
    .steps-tracker-B{
        .row{
            >div{
                text-align: right;
            }
        }
    }
}
@media (min-width: @screen-md-min) {
    .steps-tracker{
        .row{
            >div{
                .step-node{
                    .step-point{
                        width: 1.875rem;
                        height: 1.875rem;
                        &.step-done{
                            &:after {
                                width: 0.875rem;
                                height: 0.875rem;
                            }
                        }
                    }
                    h6{
                        font-size: 1rem;
                    }
                }
            }
        }
    }
    .steps-tracker-B{
        .row{
            >div{
                h6{
                    font-size: 1rem;
                    line-height: 0.875rem;
                }
                .step-point{
                    width: 0.875rem;
                    height: 0.875rem;
                }
            }
        }
    } 
}


*[lang="ar"] {
    *[dir="rtl"] {
        .steps-tracker-B{
            .row{
                >div{
                    h6{
                        margin-left: 0.75rem;
                        margin-right: 0;
                    }
                    .step-point{
                        &:last-child{
                            margin-left: 0;
                            margin-right: 0.25rem;
                        }
                    }
                }
            }
        }

        @media (min-width: @screen-sm-min) {
            .steps-tracker-B{
                .row{
                    >div{
                        text-align: left;
                    }
                }
            }
        }
    }
}