/*
============================================
01 - cross nav
02 - ms viewport
03 - sm viewport
04 - md viewport
05 - lg viewport
06 - Arabic overrides
============================================



/* -----------------------------------------
01 - cross nav
----------------------------------------- */
.cross-nav-wrapper {
  background-color: @sand-white;
  overflow: hidden;
  .cross-nav-module {
    height: 26.625rem;
    margin: 1rem 0;
    background-color: @white;
     & > div,
    .cross-nav-module--extraheight {
      width: 100%;
      overflow: hidden;
      position: relative;
      height: 100%;
    }
    .cross-nav-caption {
      position: absolute;
      top: 1rem;
      left: 1rem;
      color: @gray-close-dropdown;
      z-index: 3;
      margin-top: 0;
    }
    .cross-nav-image {
      height: 60%;
      width: 100%;
      overflow: hidden;
      position: relative;
    }
    .cross-nav-text {
      padding: 1rem 2rem;
      width: 100%;
      overflow: hidden;
      position: relative;
    }
    h5 {
      margin-top: 0;
      color: @almost-black;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    p {
      color: @gray;
      height: 6rem;
      line-height: 1rem;
      overflow: hidden;
      //text-overflow: ellipsis;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &:hover {
      .cross-nav-image div.cover:after {
        position: absolute;
        z-index: 4;
        background-color: @main-green;
        opacity: 0.7;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: " ";
      }
    }
    .price-tag {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 4;
      padding: 0.85rem 1.25rem;
      .detail-info-wrap-pricetag > div {
        color: @white;
        .upper-part {
          padding-bottom: 0.25rem;
        }
      }
      .price {
        font-size: 1.75rem;
      }
      .price-specs,
      .upper-part {
        font-size: 0.518755rem;
        letter-spacing: 0.0625rem;
      }
      .price-specs {
        line-height: 0.6875rem;
      }
    }
  }
  .cross-nav-module--widthA,
  .cross-nav-module--widthB,
  .cross-nav-module--widthC,
  .cross-nav-module--widthD,
  .cross-nav-module--widthE {
    width: 100%;
  }
  @media (max-width: 767px) {
    .swiper-wrapper{
      padding: 1rem;
      width: auto;
      display: block;
      .swiper-slide{
        width: 100%;
        .cross-nav-text{
            justify-content: center;
            align-items: flex-start;
            text-align: left;
            padding: 1rem;
            height: 12rem;
            display: flex;
            flex-direction: column;
            h5{
              font-size: 1.3rem;
              white-space: normal;
            }
            p{
              height: auto;
              font-size: 1rem;
              line-height: 1.3;
            }
        }
      }
    }
  }
  @media (max-width: 568px) {
    .swiper-wrapper{
      .swiper-slide{
        .rect-responsive{
          padding-bottom: 60%;
        }
      }
    }
  }
  @media (max-width: 320px) {
    .swiper-wrapper{
      .swiper-slide{
        .rect-responsive{
          padding-bottom: 80%;
        }
      }
    }
  }
  &.cross-nav-wrapper-career{
      @media (max-width: 768px) {
        .cross-nav-module{
          height: auto;
        }
        .swiper-wrapper{
        padding: 0px;
        .swiper-slide{
          .cross-nav-text {
            height: 9rem;
            h5{
              font-size: 16px;
              line-height: 22px;
            }
            p{
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
        .big-tiles-img-box{
          height: 120px;
          img{
            height: 120px;
            object-fit: cover;
          }
        }
      }
     }
  }
  
}

/* -----------------------------------------
02 - ms viewport
----------------------------------------- */
@media (min-width: @screen-ms-min) {
  .cross-nav-wrapper {
    .cross-nav-module,
    .cross-nav-module--widthA,
    .cross-nav-module--widthB,
    .cross-nav-module--widthC,
    .cross-nav-module--widthD,
    .cross-nav-module--widthE {
      width: 40%;
    }
    .cross-nav-module--widthB {
      width: 80%;
    }
    .cross-nav-module {
      height: 30.2rem;
      p {
        height: 5rem;
      }
    }
  }
}

/* -----------------------------------------
03 - sm viewport
----------------------------------------- */
@media (min-width: @screen-sm-min) {
  .cross-nav-wrapper {
    .cross-nav-module-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .cross-nav-module {
      height: 30.1875rem;
      margin: 1.5rem 0;
      .cross-nav-image {
        height: 66.4%;
      }
      .cross-nav-caption {
        top: 1.4rem;
        left: 2rem;
      }
      .cross-nav-text {
        padding: 1.5rem 1.75rem;
        p {
          font-size: 0.75rem;
        }
      }
      .price-tag {
        right: 1.75rem;
      }
      p {
        //height: 2.9rem;
        height: 3rem;
      }
    }
    .cross-nav-module--widthA,
    .cross-nav-module--widthD,
    .cross-nav-module--widthE {
      width: 47%;
    }
    .cross-nav-module--widthD,
    .cross-nav-module--widthE {
      flex-grow: 1;
    }
    .cross-nav-module--widthD:nth-child(2),
    .cross-nav-module--widthD:nth-child(3) {
      order: -1;
    }
    .cross-nav-module--widthA + .cross-nav-module--widthA,
    .cross-nav-module--widthD + .cross-nav-module--widthE,
    .cross-nav-module--widthD:nth-child(2n+0) + .cross-nav-module--widthD {
      margin-left: 6%;
    }
    .cross-nav-module--widthB,
    .cross-nav-module--widthC {
      width: 100%;
    }
  }
}

/* -----------------------------------------
04 - md viewport
----------------------------------------- */
@media (min-width: @screen-md-min) {
  .cross-nav-wrapper {
    padding-top: 0;
    .cross-nav-module-wrapper {
      padding-top: 0;
    }
    .cross-nav-module {
      height: 18.89375rem;
      margin: 1.8rem 0.8rem;
      margin-top: 0;
      .cross-nav-image {
        height: 62%;
      }
      .cross-nav-caption {
        top: 1rem;
        left: 1rem;
      }
      .cross-nav-text {
        padding: 1rem;
      }
      p {
        //height: 2.9rem;
        height: 3rem;
      }
      .cross-nav-module--extraheight {
        height: 208.7%;
        background-color: @white;
      }
      &.cross-nav-module--widthE {
        height: 18.95rem;
        margin-bottom: 0;
        .cross-nav-image {
          height: 81.9%;
        }
      }
    }
    .cross-nav-module--widthA {
      width: 22.22%;
    }
    .cross-nav-module--widthA + .cross-nav-module--widthA {
      margin-left: 0.875rem;
    }
    .cross-nav-module--widthB {
      width: 47.22%;
    }
    .cross-nav-module--widthD,
    .cross-nav-module--widthE {
      flex-grow: 0;
    }
    .cross-nav-module--widthC,
    .cross-nav-module--widthD,
    .cross-nav-module--widthD + .cross-nav-module--widthE,
    .cross-nav-module--widthD:nth-child(2n+0) + .cross-nav-module--widthD,
    .cross-nav-module--widthE {
      margin-left: 0;
      margin-right: 0;
    }
    .cross-nav-module--widthC:first-child {
      margin-right: 5%;
    }
    .cross-nav-module--widthC {
      width: 65%;
    }
    .cross-nav-module--widthD,
    .cross-nav-module--widthE {
      width: 30%;
    }
    .cross-nav-module--widthD:nth-child(2),
    .cross-nav-module--widthD:nth-child(3) {
      order: inherit;
    }
  }
}

/* -----------------------------------------
05 - lg viewport
----------------------------------------- */
@media (min-width: @screen-lg-min) {
  .cross-nav-wrapper {
    padding-top: 0;
    padding-bottom: 1.5rem;
    .cross-nav-module-wrapper {
      padding-top: 0;
    }
    .cross-nav-module {
      height: 25rem;
      margin: 3.875rem 1.25rem;
      margin-top: 0;
      .cross-nav-image {
        height: 64.9%;
      }
      .cross-nav-caption {
        top: 1.4rem;
        left: 2rem;
      }
      .cross-nav-text {
        padding: 2rem;
        p {
          font-size: 0.875rem;
        }
      }
      p {
        //height: 3.0625rem;
        height: 3rem;
      }
      &.cross-nav-module--widthE {
        height: 25.75rem;
        margin-bottom: 0;
        .cross-nav-image {
          height: 83.88%;
        }
      }
      .price-tag {
        right: 1.75rem;
        padding: 0.81rem 1.5rem;
      }
    }
    .cross-nav-module-wrapper--verB {
      .cross-nav-module {
        margin-left: 0;
        margin-right: 0;
      }
      .cross-nav-module--widthC:first-child {
        margin-right: 5%;
      }
    }
    .cross-nav-module--widthA {
      width: 22.22%;
    }
    .cross-nav-module--widthB {
      width: 47.22%;
    }
    &.cross-nav-module-wrapper--verC {
      padding-top: 0;
      padding-bottom: 3rem;
      .cross-nav-module-wrapper {
        padding-top: 0;
        padding-bottom: 0;
      }
      .cross-nav-module {
        margin-top: 0;
        margin-bottom: 4.25rem;
      }
    }
  }
}

/* -----------------------------------------
06 - Arabic overrides
----------------------------------------- */
*[lang="ar"] {
  *[dir="rtl"] {
    .cross-nav-wrapper {
      .cross-nav-module {
        .price-tag {
          left: 0;
          right: auto;
        }
      }
    }
    @media (min-width: @screen-sm-min) {
      .cross-nav-wrapper {
        .cross-nav-module {
          .price-tag {
            left: 1.75rem;
            right: auto;
          }
        }
      }
      .cross-nav-module--widthA + .cross-nav-module--widthA,
      .cross-nav-module--widthD + .cross-nav-module--widthE,
      .cross-nav-module--widthD:nth-child(2n+0) + .cross-nav-module--widthD {
      }
    }
    @media (min-width: @screen-lg-min) {
      .cross-nav-wrapper {
        .cross-nav-module {
          .price-tag {
            left: 1.75rem;
            right: auto;
          }
        }
      }
      .cross-nav-module--widthC:first-child {
        margin-left: 5%;
        margin-right: 0;
      }
    }
  }
}
