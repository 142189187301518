
.bg-cards {
  position: relative;

  .device-card,
  .device-card-selection,
  .device-multi-card-selection,
  .device-single-card-selection,
  .plan-card,
  .plan-multi-card-selection,
  .plan-single-card-selection,
  .product-grid,
  .product-home-card {
    &.tile-table {
      width: 100%;
      padding: 10px;
      &.product-grid {
        border: 0;
      }

      .tile-card {
        width: 100%;
        cursor: pointer;
        position: relative;
        -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
        // border-radius: 10px;
        overflow: hidden;
        //margin-bottom: 10px;

        .tile {
          position: relative;
          background-color: #fff;
          text-align: left;
        }

        .tiles-box {
          position: relative;
        }

        .tiles-box-title {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-pack: end;
          -webkit-justify-content: flex-end;
          -ms-flex-pack: end;
          justify-content: flex-end;
          padding: 1.5em;

          .catagory {
            text-transform: capitalize;
            font-size: 1.25rem;
            letter-spacing: 1.25px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          h2 {
            font-family:@font-family-base;
            margin: 0;
            font-size: 1.25rem;
            text-transform: capitalize;
            max-height: 2.6em;
            overflow: hidden;
            letter-spacing: 1px !important;
            line-height: 1.5rem;
            color:@almost-black;
          }

          ul.colorList {
            padding: 0;

            li {
              display: inline-block;
              width: 16px;
              height: 16px;
              text-indent: -999999px;
              border-radius: 10px;
              margin-right: 5px;
              list-style: none;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }




        .special-offer {
          position: absolute;
          bottom: 0;
          font-size: .75rem;
          font-family: Neotech-medium, "GE SS Two", Arial, sans-serif;
          background-color: #F5A623;
          letter-spacing: .25px;
          color: @white;
          line-height: .75rem;
          font-weight: 500;
          left: .875rem;
          padding: 7px 10px;
          border-radius: 10px 0 10px 0;
        }

        .smile-points {
          padding: 1em 1.5rem;
          font-size: .9em;
          background: #f7f8f4;
          color: @main-green;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .tiles-box-list {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          padding: 1.5rem 1.5rem 1rem;
          justify-content:flex-end;



          .bottom-text {
            color: #6F7273;
            font-size: .9em;
            margin-bottom: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 28px;
            padding-top: 5px;
          }
        }

        .detail-info-wrap-pricetag {
          -webkit-box-pack: start;
           	  -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
        }

        .detail-price-new {
          .price {
            font-size: 2.25em;
          }

          .from {
            color: #6f7273;
            position: absolute;
            top: -20px;
          }
        }

        .read-more {
          text-align: center;
          background-color: @main-green;
          text-transform: uppercase;
          color: @white;
          font-size: 1em;
          letter-spacing: 4px;
          padding: 1.2em 1em;
        }

        small {
          font-size: .875rem;
          font-family: Lato;
        }
      }
    }

    &.offer {
      .tile-card {
        small {
          // color: @dark-orange;
        }

        .price {
          // color: @dark-orange;
        }

        .smile-points {
          // color: @dark-orange;
        }

        .read-more {
          // background-color: @dark-orange;
        }

        .before-price-container {
          // background-color: @dark-orange;
          // letter-spacing: .5px;
          // color: @white;
          // margin-right: 4px;
          // border-radius: 20px;
          // padding: 2px 7px;
          // font-size: .75rem;
          text-align: center;
          display: block;


          .before-price {
            text-decoration: line-through;
          }
        }

        .bottom-text {
          padding-top: 5px;
        }
      }
    }
  }

  .plan-card,
  .plan-multi-card-selection,
  .plan-single-card-selection {
    &.tile-table {
      .tile-card {
        .tiles-box-title {
          height: 10em;
          max-height: 146px;
          position: relative;
          color: #fff;
          width: 101%;
          padding-right: 1.75em;
          .background-gradient-and-image(@main-green,'../img/bg-cards/plan-cards-illustration.svg' ,  top , right , 45deg, #353738 0%, @main-green 100%);
        }

        .tiles-box-list {
          height: 302px;
          border-left: 1px solid @white;
          border-right: 1px solid @white;
        }

        .smile-points {
          border-left: 1px solid rgb(247, 248, 244);
          border-right: 1px solid rgb(247, 248, 244);
        }

        &:hover {
          .smile-points,
          .tiles-box-list {
            border-left: 1px solid @main-green;
            border-right: 1px solid @main-green;
            transition: all .5s ease-in-out;
          }
        }
      }
    }
    &.offer {
      .tile-card {
        .tiles-box-title {
          .background-gradient-and-image(#9E5419,"../img/bg-cards/plan-cards-illustration.svg" ,  top , right , 45deg, #3a4035 0%, #9E5419 100% );
        }

        &:hover {
          .smile-points,
          .tiles-box-list {
            border-left: 1px solid @dark-orange;
            border-right: 1px solid @dark-orange;
            transition: all .5s ease-in-out;
          }
        }
      }
    }
  }

.premium{
  .plan-card{
    .tile-card {
      .tiles-box-title {
        .background-gradient-and-image(@main-green,'../img/bg-cards/plan-cards-illustration.svg' ,  top , right , 45deg, #61199e 0%, #3d3434 100%);
      }
    }
  }
}
  .device-card,
  .device-multi-card-selection,
  .device-single-card-selection {
    &.tile-table {
      .tile-card {
        .product {
          height: 450px;
          position: relative;
          text-align: center;
          background: #fff;
          overflow: hidden;
          // border-radius: 10px 10px 0 0;
          border-top: 1px solid @white;
          .product-img {
            max-height: 100%;
          }

        }

        .tiles-box-list,
        .product {
          border-left: 1px solid @white;
          border-right: 1px solid @white;
        }

        .smile-points {
          border-left: 1px solid rgb(247, 248, 244);
          border-right: 1px solid rgb(247, 248, 244);
        }

        .tiles-box-title {
          height: 160px;
          -webkit-box-pack: start;
          -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
          .catagory {
            // letter-spacing: 1px;
            color: rgb(132, 135, 137);
            font-weight: 400;
            margin-bottom: 10px;
          }
        }

        .tiles-box-list {
          height: 210px;
          padding-top: 0;
          border-bottom: 1px solid @white;
          .btn{
            margin-top:1rem;
            margin-bottom: 0;
            height: 64px;
            font-size: 14px;
            line-height: 65px;
          }
        }

        &:hover {
          .smile-points,
          .tiles-box-list,

          .product  {
            border-left: 1px solid @main-green;
            border-right: 1px solid @main-green;
            transition: all .5s ease-in-out;
          }
          .product {
            border-top: 1px solid @main-green;
          }
          .tiles-box-list{
            border-bottom: 1px solid @main-green;
          }
        }
      }
    }
    &.offer {
      .tile-card {
        &:hover {
          .smile-points,
          .tiles-box-list,
          .product {
            border-left: 1px solid @dark-orange;
            border-right: 1px solid @dark-orange;
            transition: all .5s ease-in-out;
          }
          .product {
            border-top: 1px solid @dark-orange;
          }
          .tiles-box-list{
            border-bottom: 1px solid @dark-orange;
          }
        }
      }
    }
  }

  .plan-multi-card-selection,
  .device-multi-card-selection,
  .plan-single-card-selection,
  .device-single-card-selection{
    &.tile-table {
      input[type="checkbox"],
      input[type="radio"] {
        visibility: hidden;
        position: absolute;
        left: 0;
        right: 0
      }
      .tile {
        label{
          border-radius: 50%;
          cursor: pointer;
          bottom: 85px;
          position: absolute;
          right: 20px;
          z-index: 1;
          height: 30px;
          width: 30px;
          border: 1px solid #B5B5B5;
          box-shadow: inset 0 2px 5px 0 rgba(0,0,0,0.1);
        }
      }
      input[type="checkbox"]:checked,
      input[type="radio"]:checked {
        + .tile {
          .product,
          .smile-points,
          .tiles-box-list {
            border-left: 1px solid @main-green;
            border-right: 1px solid @main-green;
            transition: all .5s ease-in-out;
          }

          .product {
            border-top: 1px solid @main-green;
          }

          .smile-points {
            border-bottom: 1px solid @main-green;
          }
        }
      }

      input[type="checkbox"]:checked + .tile label,
      input[type="radio"]:checked + .tile label  {
        &:after {
          content: "";
          position: absolute;
          opacity: 0;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
        }
      }

      input[type="checkbox"]:checked + .tile label {
        background-color: @main-green;
        border-color: @main-green;
        box-shadow: 0 2px 5px 0 rgba(143,163,105,0.5);
      }

      input[type="radio"]:checked + .tile label {
        border: 1px solid @main-green;
        box-shadow: inset 0 2px 5px 0 rgba(143,163,105,0.5);
      }

      input[type="checkbox"]:checked + .tile label {
        &:after {
          border: 1px solid #fff;
          border-top: none;
          border-right: none;
          height: 6px;
          transform: translate(-50%,-50%) rotate(-45deg);
          width: 12px;
          top: 45%;
        }
      }
      input[type="radio"]:checked + .tile label {
        &:after {
          height: 11.25px;
          width: 11.25px;
          background-color: @main-green;
          box-shadow: inset 0 2px 5px 0 rgba(0,0,0,0.1);
          border-radius: 50%;
        }
      }

      input[type="checkbox"]:checked + .tile  label:after,
      input[type="radio"]:checked + .tile  label:after {
        opacity: 1;
      }

      .tile-card {
        .read-more {
          display: none
        }
        .smile-points {
          border: 1px solid rgb(247, 248, 244);
          border-top: 0;
          border-radius: 0 0 10px 10px;
        }

        &:hover {
          .smile-points{
            border-bottom: 1px solid @main-green;
          }
        }
      }
    }
  }

  .plan-multi-card-selection,
  .plan-single-card-selection {
    &.tile-table {
      input[type="checkbox"]:checked,
      input[type="radio"]:checked {
        + .tile {
          .tiles-box-title {
            border-left:0 !important;
            border-right:0 !important;
            transition: all .5s ease-in-out;
          }
        }
      }
    }
  }

  .device-multi-card-selection,
  .device-single-card-selection {
    &.tile-table {
      input[type="checkbox"]:checked ,
      input[type="radio"]:checked{
        + .tile {
          .tiles-box-title {
            border-left: 1px solid @main-green;
            border-right: 1px solid @main-green;
            transition: all .5s ease-in-out;
          }
        }
      }
    }
  }

  .product-home-card {
    &.tile-table {
      .tile-card {
        .product {
          height: 225px;
          position: relative;
          text-align: center;
          background: #fff;
          overflow: hidden;
          &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            z-index: 1;
            height: 100%;
            width: 100%;
            .background-gradient-and-image(@main-green,'' ,  top , left , 135deg, rgba(142, 142, 142, 0.3) 0%, rgba(142, 142, 142, 0.3) 100%);
          }

          .product-img {
            width: 100%;
            z-index: 0;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
          }

          .tiles-box-title {
            position: absolute;
            bottom: 0;
            z-index: 2;
            text-align: left;

            .catagory {
              font-weight: bold;
              letter-spacing: 3px;
              line-height: 12px;
              font-family: Lato;
              color: @white;
              opacity: 1;
            }

            h2 {
              color: @white;
              z-index: 2;
            }
          }
        }

        .tiles-box-list {
          height: 316px;
          border-left: 1px solid @white;
          border-right: 1px solid @white;

          li.view-channel-list {
            color: @main-green;
            font-family: Lato;
            font-size: .85em;

            svg {
              stroke: @main-green;
              height: 24px;
              width: 24px;
              vertical-align: middle;
            }
          }
        }

        .smile-points {
          border-left: 1px solid rgb(247, 248, 244);
          border-right: 1px solid rgb(247, 248, 244);
        }

        &:hover {
          .smile-points,
          .tiles-box-list {
            border-left: 1px solid @main-green;
            border-right: 1px solid @main-green;
            transition: all .5s ease-in-out;
          }
        }
      }
    }
  }

  .product-grid {
    &.tile-table {
      .tile-card {
        .tiles-box-title {
          height: 10em;
          max-height: 146px;
          position: relative;
          color: #fff;
          width: 101%;
          .background-gradient-and-image(@main-green,'../img/bg-cards/plan-cards-illustration.svg' ,  top , right , 45deg, #353738 0%, #157D75 100%);
        }

        .tiles-box-list {
          height: 119px;
          justify-content: flex-end;
          border-left: 1px solid @white;
          border-right: 1px solid @white;
        }

        &:hover {
          .tiles-box-list {
            border-left: 1px solid @main-green;
            border-right: 1px solid @main-green;
            transition: all .5s ease-in-out;
          }
        }
      }
    }
  }
//compare grid
  &.compareGrid {
    .product-grid {
      &.tile-table {
        .tile-card {
              margin-bottom: 0;
          .tiles-box-title {
            height: 10em;
            max-height: 146px;
            position: relative;
            color: #fff;
            width: 100%;
            .background-gradient-and-image(@main-green,'../img/bg-cards/plan-cards-illustration.svg' ,  top , right , 45deg, #61199E 0%, #3D3434 100%);
          }

          .tiles-box-list {
            height: 75px;
            padding-top: 0;
            padding-bottom: 0;
            justify-content: flex-end;
            border-left: 1px solid @white;
            border-right: 1px solid @white;
            @media (min-width: 993px) {
                  padding: 0 1rem;
            }
            .detail-info-wrap {
                &.detail-info-wrap-pricetag {
                    .detail-price-new {
                        .main-part {
                          .price {
                            color: @main-green;

                            font-size: 1.5rem;
                            line-height: 1.5rem;
                            @media (min-width: 1439px) {
                              font-size: 2rem;
                              line-height: 2rem;
                            }
                          }
                          small {
                            text-transform: lowercase;
                          }
                        }
                    }
                }
            }
            .bottom-text {
              text-transform: initial;
              letter-spacing:0;
              line-height: 17px;
            }

          }

          &:hover {
            .tiles-box-list {
              border-left: 1px solid @main-green;
              border-right: 1px solid @main-green;
              transition: all .5s ease-in-out;
            }
          }
        }
      }
    }
  }


//end
  .row-tiles-table {
    .table-swiper-button-next,
    .table-swiper-button-prev {
      display: block;
      width: 1.125rem;
      height: 1.75rem;
      position: absolute;
      bottom: 50%;
      cursor: pointer;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      z-index: 2;
      transform: translateY(-50%);
      svg {
        width: 100%;
        height: 100%;
        stroke: #B2B2B2;
        stroke-width: 8px;
      }
    }

    .table-swiper-button-next {
      right: -3rem !important;
    }

    .table-swiper-button-prev {
      left: -3rem !important;
    }

    .swiper-button-disabled {
      display: none;
    }
    .swiper-scrollbar{
      background-color: @sand-white;
      left: 2%;
      bottom: 3px;
      width: 96%;
      .swiper-scrollbar-drag{
        background-color: @main-green
      }
   }
  }

  // &.related-products{
  //   .table-swiper-button-next {
  //     right: -3% !important;
  //   }
  //   .table-swiper-button-prev {
  //     left: -3% !important;
  //   }
  // }

  .swiper-slide {
    background: transparent;
  }
  &.destroyed {
    .swiper-slide {
      width: 300px;
    }
    .table-swiper-button-prev,
    .table-swiper-button-next {
      display: none;
    }
  }
  &.related-products{
    .swiper-container{
      @media (max-width: 768px) {
        left: -5%;
        width: 105%;
        .swiper-slide{
          width:95% !important;
        }
      }
    }
    .tileboxCarousal{
      .swiper-wrapper{
        @media (min-width: 993px) {
          flex-wrap: wrap;
        }

      }
    }
    .swiper-wrapper{
      .tile-card{
        .tiles-box-title{
          height: 110px !important;
          padding: 1em;
          padding-bottom: 0;
        }
        .product{
          height: 445px;
          @media (max-width: 768px) {
            height: 370px;
          }
          img{
            width: 155px;
            @media (max-width: 768px) {
              width: 120px;
            }
          }
        }
        .tiles-box-list{
          height: 115px !important;
          position: relative;
          padding-left: 20%;
          //align-items: center;
          .special-offer{
            top: -5px;
            left: 19%;
            bottom: auto;
            width: 62%;
            text-align: center;
            text-transform: uppercase;
          }
          .detail-info-wrap-pricetag{
            //justify-content: center;
          }
        }
      }

    }
  }
}

.frequently-bought{
  img{
    width: 100%;
  }
  .catagory{
    text-transform: uppercase;
    font-size: .8em;
    letter-spacing: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #848789;
    font-weight: 400;
    margin-bottom: 10px;
  }
  h2{
    font-family: Neotech-medium,GE SS Two,Arial,sans-serif;
    font-weight: 300;
    margin: 0;
    font-size: 1.75em;
    text-transform: capitalize;
    max-height: 2.6em;
    overflow: hidden;
    letter-spacing: 0;
    margin-bottom: 10px;
  }
  .bottom-text{
    color: @steel-grey;
  }
  .tiles-box-list{
    &:after{
      content:"";
      position: absolute;
      width: 130px;
      height: 130px;
      right: -60px;
      bottom: 100px;
      background: url('../img/buy-get/icons/svg/icon-big-plus.svg');
    }
  }
}

.wst{
  &.freedom{
    .bg-lighter{
      background-color: @sand-white;
    }
    .main-text{
      background-color: @sand-white;
      h2 {
          font-size: 3.5rem;
          letter-spacing: -1px;
      }
      .body-big {
        p {
            font-size: 1.25rem;
            letter-spacing: .4px;
        }
      }

    }
    .filters{
      padding-top: 2rem;
    }
    .product-detail-text{
      &.related-products{
        h2{
          font-size: 2rem;
          letter-spacing: 3.93px;
          margin-top: 0;
          margin-bottom: 1.5rem;
          color:@almost-black;
          line-height: 40px;
          @media (max-width: @screen-md) {
            font-size: 1.38rem;
            letter-spacing: normal;
            margin-bottom: 1rem;
            margin-top: 1.5rem;
            line-height: 28px;
          }
        }
        .btn{
          &.btn-default{
            height: 64px;
            line-height: 65px;
            font-size: .88rem;
            color:@almost-black;
            @media (max-width: @screen-md) {
              height: 60px;
              line-height: 60px;
            }
            &:hover{
              color:#fff;
            }
          }
        }
        p{
          color: #9B9B9B;
          color: #6F7273;
          margin-bottom: 2.4rem;
          @media (max-width: @screen-md) {
            margin-bottom: 1rem;
            font-size: .85rem;
            line-height:20px;
          }
        }
        .special-offer{
          font-size: .75rem;
          font-family: Neotech-medium,GE SS Two,Arial,sans-serif;
          background-color: #f5a623;
          letter-spacing: .25px;
          color: #fff;
          line-height: .75rem;
          font-weight: 500;
          padding: 7px 10px;
          border-radius: 10px 0 10px 0;
          width: 165px;
          text-align: center;
          text-transform: uppercase;
          margin-bottom: 1.5rem;
          @media (max-width: @screen-md) {
            margin-bottom: 1rem;
          }

        }
         .table-swiper-button-prev,.table-swiper-button-next {
           width: 44px;
           height: 44px;
           border: 2px solid #979797;
           float: left;
           cursor: pointer;
           text-align: center;
           line-height: 50px;
           z-index: 5;
           &.swiper-button-disabled{
             border-color: #cacaca;
             z-index: -1;
             position: relative;
             svg{
              stroke: #cacaca;
             }
           }
           svg{
             stroke: @almost-black;
             width: 1.1rem;
             height: 1.3rem;
             stroke: #353738;
             stroke-width: 5px;
           }
        }
        .table-swiper-button-next {
          margin-left: -2px;
        }

      }
    }
    .device{
      .swiper-wrapper {
        .tile-card {
          .product {
            img{
              width: 90%;
              max-width: 200px;
              top: 60px;
              position: relative;
            }
          }
          .detail-price-new {
            .from{
              font-size: 1rem;
              color: #719E19;
            }
            .price{
              font-size: 3.4rem;
            }
          }
          .tiles-box-list {
            height: 160px !important;
            .special-offer{
              left: 0;
              padding: 10px 50px;
              width: 100%;
              line-height: 18px;
              font-size: 14px;
            }
          }
          small{
            font-size: 1rem;
            line-height: 1rem;
          }
        }
      }
    }
  }
  .bg-cards{
    &.product-grids{
      .device-card{
        //height: 120px;
        .tile-card{
           .tiles-box-title{
             height: 120px;
             padding-bottom: 0;
             h2{
               color: @almost-black;
               font-size: 1.25rem;
               letter-spacing: 1.25px;
             }
           }
           .tiles-box-list{
             position: relative;
             height: 120px;
             //align-items: center;
             padding-left: 20%;
             .detail-info-wrap-pricetag{
               //justify-content: center;
             }
            .special-offer{
              top:-5px;
              left: 19%;
              bottom: auto;
              width: 62%;
              text-align: center;
              text-transform: uppercase;
            }
           }
           .product{
             height: 410px;
           }
        }
      }
    }
  }
}
.plan-cards{
  .row-tiles-table{
    .table-swiper-button-next,.table-swiper-button-prev {
      display: block;
      width: 1.125rem;
      height: 2.25rem;
      position: absolute;
      bottom: 50%;
      cursor: pointer;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      z-index: 2;
    }

  }

}

// mobile version
@media (max-width: 992px) {
  .bg-cards {
    .device-card,
    .plan-card,
    .product-grid,
    .product-home-card,
    .device-card-selection,
    .plan-multi-card-selection,
    .plan-single-card-selection,
    .device-multi-card-selection,
    .device-single-card-selection   {
      &.tile-table {
        .tile-card {
          //margin-bottom: 22px;
          .smile-points{
            border-radius: 0 0 10px 10px;
            border-bottom: 1px solid rgb(247, 248, 244);
          }
          &:hover {
            .smile-points{
              border-bottom: 1px solid @main-green;
            }
          }
          .read-more {
            display: none;
          }
        }
      }
      &.offer {
        .tile-card {
          &:hover {
            .smile-points{
              border-bottom: 1px solid @dark-orange;
            }
          }
        }
      }
    }

    .product-grid{
      &.tile-table {
        .tile-card {

          .tiles-box-list{
            border-radius: 0 0 10px 10px;
            border-bottom: 1px solid rgb(247, 248, 244);
          }
          &:hover {
            .tiles-box-list{
              border-bottom: 1px solid @main-green;
            }
          }

        }
      }
    }

    .row-tiles-table {

      .table-swiper-button-next {
        right: 1rem !important;
      }

      .table-swiper-button-prev {
        left: 1rem !important;
      }
    }
  }

}

@media (max-width: 768px) {
  .wst{
    &.freedom{
      .main-text{
        text-align: left;
        padding-bottom: 5px;
        background-color: #f7f8f4;
        h2{
          letter-spacing: normal;
          padding-bottom: .6rem;
          font-size: 1.6rem;
          text-transform: capitalize !important;
        }
        .body-big {
          p {
            font-size: 1rem;
            letter-spacing: normal;
          }
        }
      }
      .filters{
        padding-top: 0rem;
        .filter-compare-sort-section{
          padding: 0 1.5rem 0.8rem;
          .compare-icon, .filter-icon, .reseta, .sort-icon{
            a{
              justify-content: center;
            }
          }
          .filter-icon{
            border-right: 1px solid #ccc;
          }
        }
        .mobileViewActive{
          .filter-compare-sort-section{
            .filter-icon{
              border-right:0;
            }
          }
        }
      }
    }

    .bg-cards{
      &.product-grids{
        .device-card{
          &.tile-table{
            padding:10px;
          }
          .tiles-box-title{
            .catagory{
              margin-bottom: 5px !important;
            }
          }
          .tiles-box-list{
            height: 120px !important;
            padding: .5rem !important;
            .special-offer{
              // width: 70% !important;
              // left: 15% !important;
            }
          }
          .product{
            height: 370px !important;
            .tiles-box-title{
              padding-bottom: 0 !important;
              height: 90px !important;
            }
          }
        }
        .btn{
          &.btn-default{
            height: 64px;
            font-size: 14px;
          }
        }
      }
    }
  }
  
  .bg-cards {


    .row-tiles-table {

      .table-swiper-button-next {
        right: 1rem !important;
      }

      .table-swiper-button-prev {
        left: 1rem !important;
      }
    }

  }

  .frequently-bought{
    .tiles-box-list{
      margin-bottom: 50px;
      &:after{
        content:"";
        position: absolute;
        background: url('../img/buy-get/icons/svg/icon-big-plus.svg');
        width: 60px;
        height: 60px;
        right: 160px;
        bottom: -60px;
        background-position: top center;
      }
    }
  }
  .wst{
    .default-section-title{
      h3{

      }
    }

    .bg-cards{
      .device-card{
          &.tile-table{
            .tile-card{
              .tiles-box-title{
                padding: .5rem 1rem;
                height: 130px;
                h2{
                  font-size: 1.25rem;
                  letter-spacing: 1.25px !important;
                }
                .catagory{
                  font-size: 1.25rem;
                  letter-spacing: 1.25px;
                }
              }
              .tiles-box-list{
                height: 200px;
                padding: 1rem;
                padding-left:19% !important;
                    align-items: flex-start;
                .detail-info-wrap-pricetag{
                      justify-content: flex-start;
                }
                .bottom-text{

                }
              }
            }
          }
      }
    }

  }

}
@media (max-width: 767px) {
  .wst{
    .bg-cards{
      &.product-grids{
        .device-card{
          &.tile-table{
            padding:10px 0;
          }
        }
      }
}
  }
}
table {
    //compare grid table
    &.table-striped {
      &.compareTableGrid {
        thead {
          tr {
            th {
              width: 25%;
              padding-right: 0;
              padding-left: 1.5625rem;
              &:last-child {
                padding-right: 0;
                padding-left: 1.5625rem;
              }
            }
          }
        }
        tbody {
          tr {
            @media (max-width: 992px){
                margin-bottom: 0.75rem;
            }
            td {
              padding: 1.3rem 1rem;
              vertical-align: middle;
              @media (min-width: 992px){
                text-align: center;
              }
              @media (max-width: 992px){
                font-size: 0.875rem;
                padding: 0.5rem;
                vertical-align: middle;
              }
              .number {
                font-size: 1.375rem;
                @media (max-width: 992px){
                  font-size: 0.875rem;
                }
              }
              label {
                font-size: 1rem;
                font-weight: normal;
                margin: 0;
                @media (max-width: 992px){
                  font-size: 0.75rem;
                  margin: 0;
                  font-weight: 900;
                  color: #353738;
                  font-family: Lato,Roboto,Arial,sans-serif;
                  border-bottom: 1px solid #9e9e9e;
                  display: block;
                  padding-bottom: 0.5rem;
                  text-transform: uppercase;
                }
              }
              &:first-child {
                padding: 1.3rem 1rem;
                text-align: left;
                @media (max-width: 992px){
                  padding: 0.625rem;
                }
              }
              &:last-child {
                padding: 1.3rem 1rem;
                @media (max-width: 992px){
                  padding: 0.625rem;
                }
              }
              &:before {
                @media (max-width: 992px){
                  content: attr(data-label);
                  display: block;
                  font-size: 0.875rem;
                  letter-spacing: 0;
                  font-family: Lato,Roboto,Arial,sans-serif;
                  color: @almost-black;
                  text-transform: initial;
                  vertical-align: middle;
                  line-height: 1.5rem;
                  text-align: left;
                  width: 50%;
                  float: left;
                  font-size: 1rem;
                  font-weight: 400;
                  margin: 0;
                  color: #9e9e9e;
                }
              }
            }
          }
        }
        tfoot {
          tr {
            td {
              padding-top: 0;
              padding-bottom: 0;
              padding-right: 0;
              padding-left: 1.5625rem;
              .read-more {
                text-align: center;
                background-color: #719e19;
                text-transform: uppercase;
                color: #fff;
                font-size: 1em;
                letter-spacing: 4px;
                padding: 1.2em 1em;
                border-radius: 0px 0px 10px 10px;
                cursor: pointer;
                &.rounded {
                  border-radius:10px;
                }
              }
              &:last-child{
                padding-top: 0;
                padding-bottom: 0;
                padding-right: 0;
                padding-left: 1.5625rem;
                @media (max-width: 992px){
                  padding-left: 0;
                  padding-right: 0;
                  padding-top: 1rem;
                }
              }
            }
          }
        }
      }
    }

}

.swatch {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: bottom;
  margin-right: 5px;
  &.silver {
    background-color: #D8D8D8;
  }
  &.gold {
    background-color: #D4AF37;
  }
}


.compareForm {
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
      position: relative;
      padding-left: 48px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
      .strong {
        font-weight:900;
        font-size: 0.875rem;
        line-height:0.875rem;
        font-family: Neotech,Roboto,Arial,sans-serif;
      }
      .subDetail {
        font-size: 12px;
        line-height: 12px;
        color: #6F7273;
    }
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 32px;
      height: 32px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: @main-green;
      position: absolute;
      top: 10px;
      left: 10px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
  }
  [type=radio]:checked+label:before {
      border-color: @main-green;
      -webkit-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.5);
      box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.25);
      }
  [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);
      box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);

  }

}


// arabic language
*[lang="ar"] {
  *[dir="rtl"] {
    .bg-cards {
      .device-card,
      .plan-card,
      .product-grid,
      .product-home-card,
      .device-card-selection,
      .plan-multi-card-selection,
      .plan-single-card-selection,
      .device-multi-card-selection,
      .device-single-card-selection {
        &.tile-table {
          .tile-card {
            margin-bottom: 22px;
            .tiles-box-title {
              text-align: center;

              h2 {
                text-align: center;
              }
            }

            .tiles-box-list {
              text-align: right;
            }

            .smile-points {
              text-align: right;
            }

          }
        }
      }

      .device-card,
      .plan-card{
        .tile-card {
          .special-offer {
            right: 19%;
            left: auto;
          }
          .before-price-container{
            margin-left: 4px;
            margin-right: 0;
            float: right;
            width: 100%;
          }
        }
      }

      .plan-card,
      .plan-multi-card-selection,
      .plan-single-card-selection {
        &.tile-table {
          .tiles-box-title {
            .background-gradient-and-image(@main-green,"../img/bg-cards/plan-cards-illustration_flip.svg",top, left , 280deg, #353738 0%, @main-green 100% );
          }
        }
        &.offer {
          .tile-card {
            .tiles-box-title {
              .background-gradient-and-image(#9E5419,"../img/bg-cards/plan-cards-illustration_flip.svg" ,  top , left , 280deg, #3a4035 0%, #9E5419 100% );
            }
          }
        }
      }
      .premium{
        .plan-card,
        .plan-multi-card-selection,
        .plan-single-card-selection {
          &.tile-table {
            .tiles-box-title {
              .background-gradient-and-image(@main-green,'../img/bg-cards/plan-cards-illustration.svg' ,  top , right , 45deg, #61199e 0%, #3d3434 100%);
            }
          }
        }
      }
      .product-grid {
        &.tile-table {
          .tiles-box-title {
            .background-gradient-and-image(@main-green,'../img/bg-cards/plan-cards-illustration_flip.svg' ,  top , left , 45deg, #353738 0%, #157D75 100%);
          }
        }
      }

      .plan-multi-card-selection,
      .plan-single-card-selection,
      .device-multi-card-selection,
      .device-single-card-selection{
        &.tile-table {
          .tile-card {
            label {
              left: 20px;
              right: auto;
            }
          }
        }
      }
        //compare grid
      &.compareGrid {
          .product-grid {
            &.tile-table {
              .tile-card {
      			  .tiles-box-title {
      				.background-gradient-and-image(@main-green,'../img/bg-cards/plan-cards-illustration.svg' ,  top , right , 45deg, #61199E 0%, #3D3434 100%);
                .detail-info-wrap {
                  &.detail-info-wrap-pricetag {
                      .detail-price-new {
                          .main-part {
                            .price {
                                margin-right: 0;
                                margin-left: 4px;
                            }
                          }
                      }
                  }
                }
      			  }
      		  }
      		}
      	}
      }
    }
    table {
        //compare grid table
        &.table-striped {
          &.compareTableGrid {
            thead {
              tr {
                th {
                  padding-right: 1.5625rem;
                  padding-left: 0;
                  &:last-child {
                    padding-right: 1.5625rem;
                    padding-left: 0;

                  }
                }
              }
            }
             tbody {
               tr {
                 td {
                   text-align: center;

                   &:first-child {
                     text-align: right;
                   }
                   &:last-child {
                     @media (max-width: 992px) {
                        padding-left: 0.5rem;
                        padding-right: 0.5rem;
                      }
                   }
                   &:before {
                     @media (max-width: 992px) {
                      text-align: right;
                      float: right;
                    }
                  }
                  label {
                    @media (max-width: 992px) {
                     text-align: right;
                   }
                  }
                }
               }
             }
            tfoot {
                  @media (max-width: 992px){
                  }
              tr {
                td {
                  padding-right: 1.5625rem;
                  padding-left: 0;
                  &:last-child{
                    padding-right: 1.5625rem;
                    padding-left: 0;
                  }
                }
              }
            }
          }
        }

    }
    .swatch {
        margin-right: 0;
        margin-left: 5px;
    }
    .compareForm {
      [type="radio"]:checked,
      [type="radio"]:not(:checked) {
        left: auto;
        right: -9999px;
      }
    }

    .wst{
      &.freedom{
        .main-text{
          h2 {
            text-align: center !important;
          }
        }
        .product-detail-text{
          &.related-products{
             h2{
               text-align: right;
             }
             .btn{
               &.btn-default{
                 margin-right: 0;
               }
             }
          }
        }
        .bg-cards{
          &.related-products {
            .swiper-wrapper {
              .tile-card {
                .tiles-box-list {
                    padding-left: 2%;
                    padding-right: 20%;
                }
              }
            }
            &.device{
              .swiper-wrapper {
                .tile-card {
                  .tiles-box-list {
                    .special-offer {
                        right: 0;
                    }
                  }
                }
              }
            }
          }
        }

        @media (max-width: 768px) {
          .main-text{
            h2 {
              text-align: right !important;
            }
          }
          .tiles-box-list{

            .special-offer{

              right: 19% !important;
            }
          }

          .bg-cards{
             .device-card{
               .tile-card{
                  .tiles-box-list {
                    padding-right: 19%!important;
                    padding-left: .5rem !important;
                  }
               }
             }

          }


       }
     }
    }
  }
}

ul.featureList {
  padding: 0;
  -webkit-box-flex: 2;
-webkit-flex: 2 1 auto;
-ms-flex: 2 1 auto;
  li {
    font-size: 1.1rem;
    line-height: 1;
    padding-bottom: 1.125rem;
    list-style: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color:@almost-black;

    &.silver-number {
      font-size: inherit;
      color: #7E7F7F;

      &::before {
        content: "";
        width: 12px;
        height: 12px;
        background-color: @bland;
        display: inline-block;
        border-radius: 50px;
        margin-right: 5px;
      }
    }
    &.platinum-number {
      &::before {
        background-color: #595783;
      }
    }
  }
}
