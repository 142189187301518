.btn {
  height: @buttons-height-tablet-and-below;
  font-size: @buttons-typo-tablet-and-below;
  line-height: @buttons-line-height-tablet-and-below;
  letter-spacing: @buttons-kerning-tablet-and-below;
}
//hero-image btns
.hero-image .btn.btn-default{
  background-color: fade(@white, 0);
  border-color: @almost-black;
  color: @almost-black;
}
.hero-image .bg-dark .btn.btn-default{
  color: @white;
  border-color: @white;
  background-color: fade(@almost-black, 0);
}

//main-text business btn
.business .main-text .btn.btn-default{
  color: @white;
  border-color: @white;
  background-color: fade(@almost-black, 0);
}
@media (min-width: @screen-lg-min) {
  .btn {
    height: @buttons-height-desktop;
    font-size: @buttons-typo-desktop;
    line-height: @buttons-line-height-desktop;
    letter-spacing: @buttons-kerning-desktop;
  }
}
.btn-sm {
  height: @buttons-height-tablet-and-below;
  font-size: @buttons-typo-tablet-and-below;
  line-height: @buttons-line-height-tablet-and-below;
  letter-spacing: @buttons-kerning-tablet-and-below;
}
@ripple-ind-effect-darken-factor: 10%;
.bg-dark .btn,
.btn {
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  color: @custom-gray;
  background-color: fade(@white, 0);
  border-width: 2px;
  border-color: @black;
  padding: 0 2rem;
  justify-content: center;
  // BTN DEFAULT
  &.btn-default {
    &.focus,
    &:focus {
      outline: 0;
      color: @custom-gray;
      background-color: fade(@white, 0);
      border-color: @black;
    }
    &.active,
    &:active,
    &:hover,
    .open > .dropdown-toggle& {
      .box-shadow(none) !important;
      color: @sand-white;
      background-color: @main-green;
      border-color: @main-green;
    }
    .ink {
      background-color: darken(@main-green, @ripple-ind-effect-darken-factor);
      border-color: darken(@main-green, (@ripple-ind-effect-darken-factor/2));
    }
  }
  // BTN DEFAULT
  &.btn-green {
    color: @sand-white;
    background-color: @main-green;
    border-color: @main-green;
    &.focus,
    &:focus {
      outline: 0;
      color: @custom-gray;
      background-color: fade(@white, 0);
      border-color: @black;
    }
    &.active,
    &:active,
    &:hover,
    .open > .dropdown-toggle& {
      .box-shadow(none) !important;
      color: @sand-white;
      background-color: @main-green;
      border-color: @main-green;
    }
    .ink {
      background-color: darken(@main-green, @ripple-ind-effect-darken-factor);
      border-color: darken(@main-green, (@ripple-ind-effect-darken-factor/2));
    }
  }
  // BTN RED
  &.btn-red {
    color: @white;
    background-color: #be1218;
    border-color: #be1218;
    &.focus,
    &:focus {
      outline: 0;
      color: @custom-gray;
      background-color: fade(@white, 0);
      border-color: @black;
    }
    &.active,
    &:active,
    &:hover,
    .open > .dropdown-toggle& {
      .box-shadow(none) !important;
      color: @sand-white;
      background-color: #a60b11;
      border-color: #a60b11;
    }
    .ink {
      background-color: #a60b11);
      border-color: #a60b11;
    }
  }
  // BTN PRIMARY
  &.btn-primary {
    color: @sandy-brown;
    background-color: fade(@white, 0);
    border-color: @sandy-brown;
    &.focus,
    &:focus {
      outline: 0;
      color: @sandy-brown;
      background-color: fade(@white, 0);
      border-color: @sandy-brown;
    }
    &.active,
    &:active,
    &:hover {
      color: @sand-white;
      background-color: @sandy-brown;
      border-color: @sandy-brown;
    }
    .ink {
      background-color: darken(@sandy-brown, @ripple-ind-effect-darken-factor);
      border-color: darken(@sandy-brown, (@ripple-ind-effect-darken-factor/2));
    }
  }
  &.btn-text-only {
    color: @sand-white;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
    &.active,
    &:active,
    &:hover {
      text-decoration: none;
    }
    &.darkFont{
      color:@custom-gray;
    }
  }
  // disabled style is the same for all styles
  &.disabled,
  &.disabled:hover,
  &[disabled],
  &[disabled]:hover {
    color: @steel-grey;
    background-color: @cold-gray;
    border-color: @cold-gray;
  }
}

.business {
  .bg-white {
    .btn {
      &.btn-default {
        background-color: transparent;
        border-color: @almost-black;
        color: @almost-black;
      }
    }
  }
  .btn {
    &.btn-default {
      color: @almost-black;
      background-color: fade(@sand-white, 0);
      border-color: @almost-black;
      &.active,
      &:active,
      &:hover,
      .open > .dropdown-toggle& {
        color: @sand-white;
        background-color: @dark-green;
        border-color: @dark-green;
      }
      .ink {
        background-color: darken(@main-green, @ripple-ind-effect-darken-factor);
        border-color: darken(@main-green, (@ripple-ind-effect-darken-factor/2));
      }
    }
    &.btn-primary {
      color: @bland;
      background-color: fade(@black, 0);
      border-color: @bland;
      &.active,
      &:active,
      &:hover,
      .open > .dropdown-toggle& {
        color: @sand-white;
        background-color: @bland;
        border-color: @bland;
      }
      .ink {
        background-color: darken(@bland, @ripple-ind-effect-darken-factor);
        border: darken(@bland, (@ripple-ind-effect-darken-factor/2));
      }
    }
    &.btn-green {
      color: @sand-white;
      background-color: @dark-green;
      border-color: @dark-green;
      &.focus,
      &:focus {
        outline: 0;
        color: @custom-gray;
        background-color: fade(@white, 0);
        border-color: @black;
      }
      &.active,
      &:active,
      &:hover,
      .open > .dropdown-toggle& {
        .box-shadow(none) !important;
        color: @sand-white;
        background-color: @dark-green;
        border-color: @dark-green;
      }
      .ink {
        background-color: darken(@dark-green, @ripple-ind-effect-darken-factor);
        border-color: darken(@dark-green, (@ripple-ind-effect-darken-factor/2));
      }
    }
    // disabled style is the same for all styles
    &.disabled,
    &.disabled:hover,
    &[disabled],
    &[disabled]:hover {
      color: @steel-grey;
      background-color: @cold-gray;
      border-color: @cold-gray;
    }
  }
}

.bg-dark
.business {
  .btn {
    &.btn-default {
      color: @white;
      background-color: fade(@almost-black, 0);
      border-color: @white;
    }
  }
}

@media (min-width: @screen-lg-min) {
  .btn {
    padding: 0 2.8125rem;
  }
  .btn-icon {
    padding: 0 1rem;
  }
}
/* -- Ripple effect --------------------------------
Rif: http://codepen.io/zavoloklom/pen/Gubja       */
.ripple-effect {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
.ink {
  display: block;
  position: absolute;
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  background: #fff;
  border: 2em solid #fff;
  opacity: 1;
}
// animation effect
.ink.animate {
  animation: ripple 0.5s linear;
}
@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}
@-webkit-keyframes ripple {
  100% {
    opacity: 0;
    -webkit-transform: scale(2.5);
    transform: scale(2.5);
  }
}
@-moz-keyframes ripple {
  100% {
    opacity: 0;
    -moz-transform: scale(2.5);
    transform: scale(2.5);
  }
}
@-ms-keyframes ripple {
  100% {
    opacity: 0;
    -ms-transform: scale(2.5);
    transform: scale(2.5);
  }
}

@-o-keyframes ripple {
  100% {
    opacity: 0;
    -o-transform: scale(2.5);
    transform: scale(2.5);
  }
}
