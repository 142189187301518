.page-nav-wrap.withBoxShadow.affix-top {
  box-shadow: 0 0 5px 0 rgba(0,0,0,.25) !important;
  border-bottom: 0px !important;
  margin-bottom: 5px;
  @media (max-width: 768px){
    box-shadow: 0 0 6px 0 rgba(0,0,0,.25)!important;
    border-bottom: 0!important;
    margin-bottom: 5px;
    padding-bottom: 12px;
    border-top: 0px !important;
  }
  li {
    a {
      @media (max-width: 768px){
        font-size: 12px !important;
      }
    }
  }

}

.page-nav-wrap.withBoxShadow.affix {
  box-shadow: 0 0 5px 0 rgba(0,0,0,.25)  !important;
  border-bottom: 0px !important;
  margin-bottom: 0px;
  @media (max-width: 768px){
    box-shadow: 0 0 6px 0 rgba(0,0,0,.25)!important;
    border-bottom: 0!important;
    margin-bottom: 5px;
    padding-bottom: 15px;
    border-top: 0px !important;
  }
  li {
    a {
      @media (max-width: 768px){
        font-size: 12px !important;
      }
    }
  }
}

.wst{
  .main-text{
    h2{
      text-transform:capitalize;
    }
  }
}
.device-offer-plan-without {
  @media (max-width: 768px){
    margin-bottom: 0 !important;
  }
}
.device-offer-plan{
  padding: 1.4rem 0;
  @media (max-width: 1024px){
    padding: 0rem;
  }
  @media (max-width: 768px){
    margin-bottom: 60px;
  }
  .device-offer-plan-img{
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1024px){
      margin-bottom: 1rem;
      overflow: hidden;

    }
    img{
      //max-width: 100%;
    }
  }
  .device-offer-plan-body{
    h4{
      font-size: 26px;
      color: @almost-black;
      letter-spacing: 0px;
      margin-bottom: 1.5rem;
      @media (max-width: 1024px){
        font-size: 20px;
      }
    }
    ul{
      font-size: 14px;
      color: @steel-grey;
      padding-left: 15px;
      font-family: @font-family-base;
    }
    .device-offer-plan-price{
      margin-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 767px){
        display: block;
        margin-bottom: 1rem;
      }
      .detail-info-wrap-pricetag{
        justify-content: flex-start;
        font-family: @font-family-base;
        .detail-price-new{
          font-family: @font-family-base;
          &:first-child{
            margin-right: 2rem;
            &::before{
              display: none;
            }
          }
          &::before{
            content: "";
            position: absolute;
            left: -24%;
            right: 0;
            top: auto;
            bottom: auto;
            height: 75px;
            width: 1px;
            margin: 0px 1rem;
            background-color: @cold-gray;
            display: block;
          }
          .price-contract{
            font-size: 14px;
            color: @steel-grey;
            font-family: @font-family-base;
          }
          .main-part{
            .price{
              font-size: 32px;
              font-family: @font-family-menu-sans-serif;
              @media (max-width: 1024px){
                font-size: 28px;
              }
            }
            .price-specs{
              font-size: 14px;
              font-family: @font-family-base;
            }
          }
          .price-offer{
            display: block;
            margin-top: 5px;
            color: @steel-grey;
            font-family: @font-family-base;
            font-size: 14px;
          }

        }
      }
      button{
        height: 60px;
        width: 100%;

        @media (max-width: 767px){
          width: 100%;
          margin-top: 1.2rem;
        }
      }
    }
  }
}

*[lang="ar"] {
  *[dir="rtl"] {

    .device-offer-plan {
      .device-offer-plan-body {
        .device-offer-plan-price {
          .detail-info-wrap-pricetag {
            .detail-price-new{
              &:first-child{
                margin-right: 0rem;
                margin-left: 2rem;
              }
              &:before{
                left: auto;
                right: -26%;
              }
            }
          }
        }
      }
      .device-offer-plan-body{
         ul{
           padding-left: 0;
           padding-right: 15px;
         }
      }
    }
  }
}
