.modal-backdrop {}
.modal {
  .modal-content {
    box-shadow: none;
    border: none;
    background: @white;
    .modal-header {
      border-bottom: 0;
      .close {
        margin-right: 1rem;
        right: 0;
        position: absolute;
      }
    }
    .modal-body {
      border: 0;
      padding: 3rem 3rem 0;
      h4 {
        letter-spacing: 0;
        margin-bottom: 0;
      }
    }
    .modal-footer {
      border-top: 0;
      padding: 3rem 3rem 4rem;
    }
    .form .floating-label-input,
    .form .floating-label-select{
      margin-bottom: 1rem;
      margin-top: 2rem;
    }
    .form .floating-label-input + h4,
    .form .floating-label-select + h4{
      margin-top: 2rem;
    }

    .form-label-option{
      position: absolute;
      left: 0;
      bottom: -2rem;
      color: @almost-black;
      text-decoration: underline;
    }
  }
}
.business {
  .fade {
    &.in {
      // background: rgba(0, 0, 0, 0.6); to fix svpn ho popover /*   z-index: 1;*/
    }
  }
  .popup-container {
    background: @white;
  }
  .icon-popup{
    max-width: 70%;
    box-shadow: 0 0 2rem rgba(0,0,0,0.25);
    margin-top: 12rem;
    .popup-close {
      width: 100%;
      height: 64px;
      position: absolute;
      z-index: 1;
      svg {
        width: 28px;
        height: 28px;
        margin: 20px;
        margin: 2rem 3rem;
        text-align: center;
      }
    }
    .btn-yellow{
      width: 100%;
      background-color: @main-green;
      border: none;
      color: @white;
    }
    .popup-content {
        padding: 3rem;
        .form {
          .floating-label-input {
            margin: 0;
          }
          .form-control:focus {
            border-color: transparent;
          }
        }
        p {
          margin-bottom: 2rem;
        }
    }
  }
  .btnYellow {
    background-color: #FFCC00 !important;
    color: #353738 !important;
  }
  .popup-footer {
    .btn.ripple-effect {
      border: none;
    }
    .btn.btn-ghost {
      border-top: solid 2px @cold-gray;
    }
  }
  @media (min-width: @screen-md-min) {
    .modal-dialog {
      width: 40%;
      margin: 5% auto;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    .modal-dialog {
      width: 90%;
      margin: 5% auto;
    }
  }
}
@media (min-width: @screen-sm-min) {
  .modal-content {
    box-shadow: none;
  }
}
@media (min-width: @screen-md-min) {
  .modal-dialog {
    width: 50%;
    margin: 5% auto;
  }
}
@media (min-width: @screen-lg-min) {}
