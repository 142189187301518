.config-upselling {
  background-color: @white;
  padding: 0;
  margin-top: 2rem;
  .tile-table {
    display: block;
    box-sizing: border-box;
    float: left;
    width: 17.6rem;
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    margin: 0;
    background-color: transparent;
    .tile {
      background-color: @white;
      display: block;
      box-sizing: border-box;
      box-shadow: inset 0 0 0 2px @light-sand-brown;
      .tiles-box {
        position: relative;
        div.cover {
          background-color: @black;
        }
        .cover {
          height: 7.6rem;
          img {
            opacity: 1;
          }
        }
        h3,
        h5 {
          height: 5.2rem;
          margin: 0;
          padding: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          position: relative;
          color: @white;
          text-transform: uppercase;
          box-sizing: border-box;
        }
        h3 {
          padding: 0;
          font-size: 1.625rem;
          margin-bottom: 2.2rem;
        }
        h5 {
          height: 2rem;
          padding-top: 1.4rem;
          font-family: @font-family-sans-serif;
        }
        .tile-description {
          color: @breadcrumb-color;
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }
      .price-and-tick {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 3;
      }
      // CARD EFFECT
      .flip-icon {
        height: 1.25rem;
        width: 1.25rem;
        padding: 0;
        position: absolute;
        display: block;
        right: 0.8125rem;
        top: 0.8125rem;
        opacity: 0.8;
        z-index: 4;
        svg {
          color: #719e19;
          height: 1.25rem;
          fill: @white;
          position: relative;
          width: 1.25rem;
          .transition();
        }
      }
      &.card {
        position: relative;
        display: flex;
        align-items: stretch;
        justify-content: center;
        .product-image {
          margin-left: 9%;
          margin-right: 9%;
          width: 82%;
        }
        div[class*=col-] > .rect-responsive {
          padding-bottom: 132%;
        }
        .detail-info-wrap-pricetag {
          padding-bottom: 1.4rem;
        }
        &.product-grid-single-small {
          .product-image {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
          }
          div[class*=col-] > .rect-responsive {
            padding-bottom: 76%;
          }
          .detail-info-wrap-pricetag {
            padding-top: 3.3rem;
          }
          .card__back {
            .product-info {
              padding-bottom: 1rem;
            }
            .backside-list {
              line-height: 1.625rem;
              a.viewMore {
                padding-top: 0.5rem;
              }
            }
          }
        }
      }
      /* click effect */
      &.card.effect__click.flipped .card__front {
        transform: rotateY(-180deg);
      }
      &.card.effect__click.flipped .card__back {
        transform: rotateY(0);
      }
      .card__back,
      .card__front {
        background-color: @white;
        cursor: pointer;
        text-align: center;
        padding: 0 0 5.6rem;
        position: relative;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        border: 0.125rem solid @sand-white;
        .transition();
        .btn {
          width: 100%;
        }
      }
      .card__back {
        transform: rotateY(-180deg);
        height: 100%;
        position: absolute;
        padding: 1.75rem 1.6rem 1.9rem;
        bottom: 0;
        right: 0;
        .side-content {
          min-height: 100%;
          overflow: hidden;
        }
        .backside-list {
          font-size: 0.875rem;
          line-height: 1.37rem;
          text-align: left;
          p,
          ul {
            color: @breadcrumb-color;
          }
          ul {
            float: left;
            padding-left: 1.15rem;
          }
          a.viewMore {
            text-decoration: underline;
            color: @almost-black;
            width: 100%;
            display: inline-block;
            text-align: center;
            padding-top: 1.3rem;
          }
        }
      }
      &.flipped {
        .card__front .btn {
          opacity: 0;
        }
        .flip-icon {
          svg {
            fill: @main-green;
          }
        }
        .card__back {
          border-color: @main-green;
        }
        &.product-info-with-tooltip {
          .card__back {
            border-color: @dark-orange;
          }
          .flip-icon svg {
            fill: @dark-orange;
          }
          .btn-green {
            border-color: @dark-orange;
            background-color: @dark-orange;
          }
        }
        .detail-info-wrap-pricetag {
          .main-part {
            color: @main-green;
          }
        }
      }
      // END CARD
      .tile-tick {
        margin-top: -0.1875rem;
        svg {
          width: 2.5rem;
          height: 2.5rem;
          fill: @white;
          border: 1px solid @cold-gray;
          background-color: #F7F7F4;
          border-radius: 100%;
        }
      }
      &.tiles-box-awards {
        .tile-description {
          color: @dark-orange;
        }
        .tile-tick {
          svg {
            fill: @white;
            border: 1px solid @cold-gray;
            background-color: #F7F7F4;
            border-radius: 100%;
          }
        }
        .detail-info-wrap-pricetag {
          .main-part {
            color: @dark-orange;
          }
        }
        &.flipped {
          .flip-icon {
            svg {
              fill: @dark-orange;
            }
          }
          .card__back {
            border-color: @dark-orange;
          }
        }
      }
    }

    input[type="checkbox"],
    input[type="radio"] {
      display: none;
    }
    .btn {
      .remove {
        display: none;
      }
      .standard {
        display: block;
      }
    }
    input[type="checkbox"]:checked + .tile,
    input[type="radio"]:checked + .tile {
      .tiles-box {
        div.cover {
         /* background-color: @main-green; */
        }
      }
      .tile-tick {
        opacity: 1;
      }
      .btn {
        background-color: @dark-orange;
        color: @white;
        border-color: @dark-orange;
        .remove {
          display: block;
        }
        .standard {
          display: none;
        }
      }
      .tile-tick{
        svg {
          fill: @main-green;
          border: 0 none;
          border-radius: 50%;
          background: transparent;
        }
      }
    }
    input[type="checkbox"]:checked + .tiles-box-awards ,
    input[type="radio"]:checked + .tiles-box-awards  {
        .tile-tick {
          svg {
            fill: @dark-orange;
            border-radius: 50%;
            border: 0;
            background: transparent;
          }
        }
    }
  }
  .detail-info-wrap-pricetag {
    .main-part {
      color: @almost-black;
      .price-specs {
        font-size: 0.875rem;
      }
    }
  }
  .btn {
    width: 55%;
  }
}
@media (min-width: @screen-sm-min) {
  .config-upselling {
    padding: 0;
    .tile-table {
      width: 18.125rem;
      padding-left: 2rem;
      padding-right: 0;
      .tile {
        .tiles-box {
          .cover {
            height: 7.6rem;
          }
          .section-title {
            top: 0;
          }
          h3 {
            margin-bottom: 2rem;
          }
          .tile-description {
            font-size: 0.875rem;
          }
        }
        .card__back,
        .card__front {
          padding-bottom: 7.6rem;
        }
      }
    }
    .tile-table:first-child {
    //  margin-left: 2rem;
    }
    .tile-table:last-child {
      //margin-right: 4rem;
    }
  }
}
@media (min-width: @screen-md-min) {
  .config-upselling {
    padding: 0;
    .swiper-wrapper {
      justify-content: flex-start;
    }
    .tile-table {
      width: 30.3333%;
      padding-left: 0;
      padding-right: 0;
      margin-right: 4%;
      .tile {
        .tiles-box {
          .cover {
            height: 7.6rem;
          }
          .section-title {
            top: 0;
          }
          h3 {
            margin-bottom: 2rem;
          }
          .tile-description {
            font-size: 0.875rem;
          }
        }
      }
    }
    .tile-table:first-child {
      margin-left: 0;
      padding-left: 0;
    }
    .tile-table:last-child {
      margin-right: 0;
      padding-right: 0;
    }
  }
}
@media (min-width: @screen-lg-min) {
  .config-upselling {
    padding: 0;
    .tile-table {
      .tile {
        .tiles-box {
          .cover {
            height: 10.7rem;
          }
          h5 {
            top: 0.3rem;
            height: 4rem;
          }
          h3 {
            font-size: 1.875rem;
            top: 0.1rem;
            margin-bottom: 4.2rem;
          }
        }
        .tile-description {
          font-size: 1rem;
        }
        .card__back,
        .card__front {
          padding-bottom: 9.1rem;
        }
        &.card {
          .detail-info-wrap-pricetag {
            padding-bottom: 2.6rem;
          }
        }
        .tile-tick {
          margin-top: -0.575rem;
          svg {
            width: 3.75rem;
            height: 3.75rem;
          }
        }
      }
    }
    .tile-table .tile .tiles-box .cover img {
      opacity: 0.6;
    }
    .tile-table:first-child {
      padding-left: 0;
    }
    .tile-table:last-child {
      padding-right: 0;
    }
  }
}
*[lang="ar"] {
  *[dir="rtl"] {
    .config-upselling {
      .tile-table {
        float: right;
        .tile {
          box-shadow: inset 0 2px 0 0 @light-sand-brown;
          .tiles-box {
            h5,
            h5 {
              font-family: @font-arabic;
            }
          }
          .price-and-tick {
            left: auto;
            right: 0;
          }
          // CARD EFFECT
          .card__back,
          .card__front {
            left: auto;
            right: 0;
          }
          .card__back {
            right: auto;
            left: 0;
            .backside-list {
              text-align: right;
              ul {
                float: right;
                padding-right: 1.15rem;
                padding-left: auto;
              }
            }
          }
        }
      }
    }
    @media (min-width: @screen-md-min) {
      .config-upselling {
        padding: 0;
        .swiper-wrapper {
          justify-content: flex-start;
        }
        .tile-table {
          margin-left: 4%;
          margin-right: 0;
        }
        .tile-table:first-child {
          margin-right: 0;
          padding-left: 0;
        }
        .tile-table:last-child {
          margin-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}
