.checkbox-radio-button {
	label {
	  float: left;
    display: inline-block;
    min-width: 180px;
    font-weight: 400;
    text-shadow: none;
    padding: 22px 14px;
    border: 1px solid;
    font-family: Lato,Roboto,Arial,sans-serif;
    text-transform: uppercase;
    font-size: 14px;	
    letter-spacing: 2.4px;	
    line-height: 18px;	
    text-align: center;
    margin-right: 25px;
    margin-bottom: 45px;
    color: #333;
    border-color: #000;
    background-color: transparent;
	  -webkit-transition: all 0.1s ease-in-out;
	  -moz-transition:    all 0.1s ease-in-out;
	  -ms-transition:     all 0.1s ease-in-out;
	  -o-transition:      all 0.1s ease-in-out;
	  transition:         all 0.1s ease-in-out;
	  @media (max-width: 1024px) {
			padding: 15px 10px;
			margin-right: 15px;
      margin-bottom: 20px;
      min-width: 130px;
		}
		@media (max-width: 375px) {
			min-width: 105px;
			font-size: 13px;
		}
	  &:hover {
		  cursor: pointer;
		}
		&.no-margin-right {
			margin-right: 0px;
		}
	}
	input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
    &:checked + label {
		  color: #fff;
	    background-color: #719e19;
	    border-color: #719e19;
		}
	}
}

.offers-prices {
  border-top: 1px solid #E2E1DA;
  padding: 55px 0px;
  h2 {
		color: #353738;	
		font-size: 40px;	
		line-height: 50px;	
		text-align: left;
    letter-spacing: 0;
    margin: 0px 0px 50px 0px;
    @media (max-width: 1024px) {
			font-size: 32px;
		}
		@media (max-width: 568px) {
			margin: 0 0 20px;
		}
    small {
	    color: #353738;
	    font-family: Lato,Roboto,Arial,sans-serif;
	    font-size: 16px;
	    line-height: 22px;
	    margin-left: 30px;
	    @media (max-width: 1024px) {
				margin-left: 15px;
    		font-size: 14px;
			}
			@media (max-width: 568px) {
				display: block;
    		margin-left: 0px;
			}
    }
	}
	.offers-action {
		&>div {
			&:last-child {
				label {
					margin-right:0px;
				}
			}
		}
	}
	.last {
		.checkbox-radio-button {
			label {
				margin-bottom: 0px;
			}
		}
	}
	.prices-and-offers {
		padding: 70px 0px;
	}
	.label {
		color: #848789;	
		font-size: 20px;	
		line-height: 28px;
		padding-top: 15px;
		font-weight: normal;
		@media (max-width: 1024px) {
			font-size: 16px;
	    padding-top: 9px;
		}
	}
	.offers-action-header {
		.checkbox-radio-button {
			label {
				min-width: 249px;
				margin-bottom: 0px;
				@media (max-width: 660px) {
					width: 100%;
			    margin-right: 0px;
			    margin-bottom: 20px;
				}
			}
		}
	}
	.capacity {
		.checkbox-radio-button {
			label {
				min-width: 112px;
			}
		}
	}
	.color {
		.checkbox-radio-button {
			input {
		    &:checked + label {
		    	&.grey {
		    		background-color: #848789;
		    		border-color: #848789;
		    	}
		    	&.silver {
		    		background-color: #dadada;
		    		border-color: #dadada;
		    	}
		    }
		  }
		}
	}
	.item-filters {
		.label-wrap {
			max-width: 134px;
			@media (max-width: 1024px) {
				max-width: 100px;
			}
		}
	}
	.item-detail {
		@media (max-width: 992px) {
			display:none;
		}
		ul {
			margin: 0px;
			color: #848789;	
			font-size: 16px;	
			line-height: 22px;
			line-height: 37px;
    	padding-left: 0px;
		}
	}
	.offers-price-footer {
		margin-top: 85px;
		.total-price {
			color: #353738;	
			font-family: Neotech,Roboto,Arial,sans-serif;
			padding-top: 45px;	
			@media (max-width: 1024px) {
				padding-top: 10px;
			}
			@media (min-width: 737px) {
				width: auto;
				margin: 0;
			}
			span {
				font-size: 54px;	
				letter-spacing: 1px;	
				line-height: 68px;
				@media (max-width: 992px) {
					font-size: 38px;
					line-height: 50px;
				}
			}
			label {
				font-size: 16px;	
				letter-spacing: 1px;	
				line-height: 20px;
				margin-left: 8px;
				font-family: Neotech,Roboto,Arial,sans-serif;
				font-weight: normal;
				@media (max-width: 992px) {
					font-size: 12px;
			    line-height: 14px;
			    margin-bottom: 0px;
				}
			}
			p {
				font-size: 16px;	
				letter-spacing: 1px;	
				line-height: 20px;
				margin: 0px;
				font-family: Neotech,Roboto,Arial,sans-serif;
				@media (max-width: 992px) {
					font-size: 12px;
			    line-height: 14px;
			    margin-bottom: 0px;
				}
				&.permotion {
					color: #FF8000;	
					padding-top: 3px;
				}
			}
		}
		img {
			max-height: 200px;
			width: auto;
			@media (max-width: 1024px) {
				max-height: 120px;
			}
			@media (max-width: 992px) {
				max-height: 100px;
		    max-width: 100%;
		    height: auto;
			}
		}
		&.smartpay-offer-footer {
			.total-price {
				@media (max-width: 1024px) {
					padding-top: 0px;
				}
				@media (max-width: 736px) {
					clear: both;
    			padding-left: 8%;
				}
				@media (max-width: 568px) {
					padding-left: 4%;
				}
				@media (max-width: 414px) {
					padding-left: 0;
				}
			}
			margin: 85px 0px;
			display: flex;
			@media (max-width: 736px) {
		    display: block;
	    }
			.selected-image {
				float: left;
				@media (max-width: 736px) {
			    /*width: 100%;*/
		    }
		    @media (min-width: 737px) {
					width: auto;
					margin: 0;
				}
			}
			.icons {
				float: left;
		    align-self: center;
		    padding: 0px 65px;
		    @media (max-width: 992px) {
		    	padding: 0 40px;
		    }
		    @media (max-width: 736px) {
		    	padding: 35px;
			    /*width: 100%;*/
			    box-sizing: border-box
		    }
		    @media (min-width: 737px) {
					width: auto;
					margin: 0;
				}
		    img {
		    	min-height: auto;
		    }
			}
			.text-center-mobile {
				@media (max-width: 736px) {
					text-align: center !important;
				}
			}
		}
		&.standalone-offer-footer {
			display: flex;
			@media (max-width: 736px) {
				display: block;
			}
			.total-price {
				float: left;
				align-self: center;
				padding-top: 0px;
				@media (max-width: 736px) {
					width:100%;
				}
			}
			.selected-image {
				float: left;
				@media (max-width: 736px) {
					width:100%;
				}
			}
			.icons {
				float: left;
		    align-self: center;
		    padding: 0px 65px;
		    @media (max-width: 736px) {
					padding: 35px 0px;
				}
			}
		}
	}
	.smartpay-offers {
		.ipad-filteres {
			.checkbox-radio-button {
				label {
			    min-width: 112px;
			    @media (max-width: 1024px) {
			    	min-width: 90px;
			    }
			    @media (max-width: 375px) {
						min-width: 75px;
				    margin-right: 10px;
				    font-size: 13px;
					}
			    &.wifiCellular {
			    	min-width: 248px;
			    	@media (max-width: 1024px) {
			    		min-width: 190px;
			    	}
			    	@media (max-width: 375px) {
			    		min-width: 168px;
    					margin-right: 0px
			    	}
			    }
			  }
			}
			.color {
				.checkbox-radio-button {
					label {
				    min-width: 180px;
				    @media (max-width: 1024px) {
				    	min-width: 130px;
				    }
				    @media (max-width: 375px) {
							min-width: 120px;
						}
				  }
				}
			}
		}
		.iphone-filter-smartpay {
			padding-left: 45px;
      border-left: 2px solid #F0F0EC;
      @media (max-width: 1024px) {
      	padding-left: 26px;
      }
      @media (max-width: 992px) {
  	    padding-left: 0px;
		    padding-top: 25px;
		    margin-top: 40px;
		    border-top: 2px solid #f0f0ec;
		    border-left: 0px;
      }
		}
		.item-detail {
			&:first-child {
				padding-right:45px;
			}
			&:last-child {
				padding-left:45px;
			}
		}
	}
}

/*
 * ARABIC
 */
*[lang="ar"] {
  *[dir="rtl"] {
  	.checkbox-radio-button {
  		label {
  			float: right;
  			margin-left: 25px;
  			margin-right: 0px !important;
  			@media (max-width: 1024px) {
  				margin-left: 15px;
  		  }
  			&.no-margin-right {
  				margin-left: 0px;
  				margin-right: 0px;
  			}
  		}
  	}
  	.offers-prices {
  		h2 {
  			text-align: right;
  			span {
  				float: right;
  			}
  			small {
  				margin-right: 30px;
  				margin-left: 0px;
  				@media (max-width: 568px) {
  					float:right;
  					margin-right: 0px;
  				}
  			}
  		}
  		.offers-action>div:last-child label {
  			margin-left: 0px;
  			margin-right: 0px;
  		}
  		.item-filters {
  			.row {
  				&> div {
  					float: right !important;
  				}
  			}
  		}
  		.item-detail {
  			ul {
  				padding-right: 0px;
  				padding-left: 0px;
  			}
  		}
  		.smartpay-offers {
  			.iphone-filter-smartpay {
					padding-right: 45px;
					border-right: 2px solid #f0f0ec;
					padding-left: 0px;
					border-left: 0px;
					@media (max-width: 992px) {
						padding-right: 0px;
						border-right: 0px;
					}
  			}
  			.item-detail {
  				&:first-child {
  					padding-left: 45px;
  					padding-right: 0px;
  				}
  				&:last-child {
  					padding-right: 45px;
  					padding-left: 0px;
  				}
  			}
  		}
  		.offers-price-footer {
  			&.smartpay-offer-footer {
  				.total-price {
  					@media (max-width: 736px) {
		    			padding-right: 8%;
		    			padding-left: 0;
						}
						@media (max-width: 568px) {
							padding-right: 4%;
							padding-left: 0;
						}
						@media (max-width: 414px) {
							padding-left: 0;
							padding-right: 0;
						}
  				}
  			}
  		}
  	}
  }
}