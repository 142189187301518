/*
============================================
01 - main text
02 - ms viewport
03 - sm viewport
04 - md viewport
05 - lg viewport
06 - xl viewport
07 - Business overrides
08 - Arabic overrides
09 - support page - contact us form
============================================



/* -----------------------------------------
01 - main-text
----------------------------------------- */

.main-text {
  text-align: center;
  color: @steel-grey;
  background-color: @sand-white;
  /* background options */
    padding-bottom: 1.5rem;
    h2{
      padding-top: 2rem;
      letter-spacing: 0;
      text-transform: unset;
      padding-bottom: 0.5rem;
    }
    .body-big{
      p{
        font-size: 1.1rem;
      }
    }
  &.bg-sand-white {
    background-color: @sand-white;
  }

  &.bg-light-sand-brown {
    background-color: @light-sand-brown;
  }

  &.bg-white {
    background-color: @white;
  }

  &.bg-green {
    background-color: @main-green;

    .btn,
    a,
    h1,
    h2,
    h3,
    p {
      color: @white;
    }

    .btn {
      border-color: @white;
    }

    hr {
      border-top: 1px solid @white;
    }
  }

  &.bg-dark,
  &.bg-dark-sand-brown,
  &.bg-photo {
    background-color: @dark-sand-brown;

    .btn,
    a,
    h1,
    h2,
    h3 {
      color: @white;
    }

    li,
    p {
      color: @cold-gray;
    }

    .btn {
      border-color: @white;
    }

    hr {
      border-top: 1px solid @white;
    }
  }

  & hr {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border: 0;
    width: 1.75rem;
    border-top: 1px solid @main-green;
  }

  & ul {
    list-style-type: none;
    padding-left: 0;

    li {
      padding-top: 0.5rem;
    }
  }

  &.text-right {
    .btn,
    a,
    h1,
    h2,
    h3,
    p,
    ul li {
      text-align: right;
    }
  }

  &.text-left {
    .btn,
    a,
    h1,
    h2,
    h3,
    p,
    ul li {
      text-align: left;
    }

    & ul {
      list-style-type: disc;
      padding-left: 40px;
    }
  }

  &.text-center {
    .btn,
    a,
    h1,
    h2,
    h3,
    p,
    ul li {
      text-align: left;
    }
  }

  a {
    color: @custom-gray;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &.btn {
      text-decoration: none;
    }
  }
  .h2,
  h1 {
    font-size: 1.875rem;
  }
  .h2,
  h1,
  h2,
  h3 {
    color: @premium;
    text-transform: uppercase;
    padding: 2.03rem 0 1.2rem;
    margin: 0;

    &.do-not-uppercase {
      text-transform: none;
      &.paddSmaller {
        padding: 0.3rem 0;
      }
    }
    &.uppercase {
      text-transform: uppercase !important;
    }
  }

  .h3,
  h3 {
    padding-top: 2.8rem;
    font-size: 1.5rem;
    padding-bottom: 0.5rem;
  }

  p {
    padding-bottom: 1rem;
    margin-bottom: 0;
    font-size: 0.875rem;
  }

  p.small-p,
  ul {
    font-size: 0.75rem;
    line-height: 1.2rem;
  }

  .prices-container {
    margin-bottom: 2rem;

    .detail-info-wrap {
      margin-bottom: 1rem;
    }
  }

  .ico-status {
    width: 100%;
    text-align: center;

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .ico-status {
    fill: @main-green;
  }

  .btn {
    margin-bottom: 1.69rem;
  }
  &.main-text-H  {
    padding-top: 1rem;
    & h2 {
      @media (min-width: 768px) {
        padding-top: 0;
      }
    }
  }
  &.main-text-expo-2020{
    h2{
      color: @almost-black;
      font-size: 2.5rem;
      letter-spacing: 0;
      padding-bottom: 0rem;
      text-transform: none;
    }
    h4{
      color: @almost-black;
      font-size: 1.625rem;
      letter-spacing: 0;
    }
  }
  &.main-text-region-expo-2020{
    h3{
      font-size: 1.875rem;
    }
    p{
      font-size: 1rem;
    }
  }

  &.main-text-careers{
    padding-bottom: 20px;
    @media (max-width: 767px) {
      padding-bottom: 0px;
    }
    h2{
      color: @almost-black;
      letter-spacing: 0px;
      line-height: 40px;
      font-weight: normal;
      font-size: 32px;
      text-transform: unset;
      padding-top: 65px;
      padding-bottom: 45px;
      letter-spacing: 5px;
      text-transform: uppercase;
    }
    p{
      color: @steel-grey;
      font-size: 20px;
      padding-bottom: 30px;
      line-height: 30px;
      font-weight: normal;
    }
    @media (max-width: 767px) {
      h2{
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 3px;
        text-align: center;
        padding-top: 48px;
        padding-bottom: 30px;
      }
      p{
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        padding-bottom: 20px;
      }
      .btn{
        &.btn-default{
          width: 100%;
          margin-bottom: 5rem;
        }
      }
    }

  }
}

&.main-text-order-status {
  .ico-status {
    padding-top: 1.6875rem;
  }

  p {
    font-size: 0.875rem;
    padding-bottom: 1.23rem;
  }

  p.order-id {
    padding-top: 1.5rem;
    color: @almost-black;
    line-height: 1.5rem;
    padding-bottom: 0.65rem;

    span {
      color: @main-green;
      font-weight: bold;
    }

    span.error {
      color: @pink;
    }
  }

  .btn {
    color: @almost-black;
    margin-bottom: 0.15rem;
  }

  .h2,
  h1,
  h2 {
    padding-top: 0.9rem;
    padding-bottom: 1.15rem;
  }
}

&.bg-photo {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  min-height: 16.88rem;
  flex-direction: row;

  .btn {
    margin-top: 2rem;
  }

  .cover {
    z-index: 0;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: @black;
      content: " ";
      z-index: 2;
      opacity: 0.15;
    }
  }

  :nth-child(2) {
    align-self: center;
  }
}

&.two-col-layout {
  ul {
    text-align: left;
    list-style-type: none;
    padding: 0;
  }
}
/* -----------------------------------------
02 - ms viewport
----------------------------------------- */
@media (min-width: @screen-ms-min) {
  .main-text {
    p.small-p,
    ul {
      font-size: 0.75rem;
      line-height: 1.2rem;
    }

    .h3,
    h3 {
      padding-top: 2.8rem;
      font-size: 1.125rem;
      padding-bottom: 0.82rem;
    }

    &.two-col-layout {
      padding-top: 2.6rem;
      padding-bottom: 0.6rem;
      display: flex;
      flex-wrap: wrap;

      .h2,
      h1,
      h2 {
        margin-top: 0;
        text-align: left;
        padding-top: 0;
        padding-bottom: 0;
      }

      p {
        font-size: 0.75rem;
        text-align: left;
        line-height: 1.2rem;
      }
    }

    &.main-text-order-status {
      .ico-status {
        padding-top: 1.6875rem;
      }

      p.order-id {
        padding-top: 2.3rem;
        padding-bottom: 0.85rem;
      }

      .h2,
      h1,
      h2 {
        font-size: 1.5rem;
      }
    }
  }
}

@media (max-width: @screen-sm-min) {
  .wst{
    .main-text {
      p{
        font-size: .9rem;
        line-height: 1.2rem;
      }
      ul {
          li{
            list-style-position: inside;
          }
          font-size: .9rem;
          line-height: 1.2rem;
          padding-left: 0px!important;
      }
    }
  }
}

/* -----------------------------------------
03 - sm viewport
----------------------------------------- */
@media (min-width: @screen-sm-min) {
  .main-text {
    padding-bottom: 2.05rem;

    h1 + div,
    h2 + div {
      padding-bottom: 1.2rem;
    }

    .h2,
    h1,
    h2 {
      padding-top: 3.95rem;
      padding-bottom: 1.43rem;
      font-size: 2.5rem;
    }

    p {
      padding-bottom: 0.5rem;
    }

    .h3,
    h3 {
      font-size: 2.5rem;
      padding-bottom: 0.5rem;
      padding-top: 2.4rem;
    }

    p.small-p,
    ul {
      font-size: 0.875rem;
      line-height: 1.4rem;
    }

    .btn {
      margin-bottom: 0;
      margin-top: 2.2rem;
      &.alignLeft {
        margin: 0 1rem;
        height: 4.25rem;
      }
    }

    .prices-container {
      margin-top: 1rem;
      margin-bottom: 0;

      .detail-info-wrap {
        margin-bottom: 1rem;
      }
    }

    &.two-col-layout {
      padding-top: 2.1rem;
      padding-bottom: 2.1rem;

      .h2,
      h1,
      h2 {
        font-size: 1.875rem;
      }

      p {
        font-size: 0.875rem;
        line-height: 1.4rem;
      }
    }

    &.main-text-order-status {
      .ico-status {
        padding-top: 3rem;

        svg {
          width: 2.8125rem;
          height: 2.8125rem;
        }
      }

      p {
        font-size: 1rem;
      }

      p:last-child {
        padding-bottom: 0.3rem;
      }

      p.order-id {
        padding-top: 1.5rem;
        padding-bottom: 0.85rem;
        line-height: 1.8rem;
      }

      .h2,
      h1,
      h2 {
        font-size: 2.5rem;
        padding-top: 1.1rem;
        padding-bottom: 1rem;
      }

      .btn {
        margin-top: 0;
      }
    }
  }
}
/* -----------------------------------------
04 - md viewport
----------------------------------------- */
.wst {
  .main-text {
    background-color: #FBFBFB;
  }
}
@media (min-width: @screen-md-min) {
.wst{
  .main-text {
    h2{
      padding-top: 3.8rem;
      font-size: 2.4rem;
      padding-bottom: 1.9rem;
    }
    .body-big{
      p {
          font-size: 1.25rem;
      }
    }

    ul {
        font-size: 1.25rem;
        line-height: 2.4rem;
        padding-left: 25px!important;
    }
  }
}

  .main-text {
    p {
      font-size: 0.9875rem;
    }

    .h2,
    h1,
    h2 {
      font-size: 3.125rem;
      padding-top: 4rem;
      padding-bottom: 1.4rem;
    }

    .h3,
    h3 {
      font-size: 1.625rem;
    }

    &.bg-photo {
      min-height: 18.75rem;
    }

    .prices-container {
      margin-top: 2;

      .detail-info-wrap {
        margin-bottom: 1rem;
      }
    }

    &.two-col-layout {
      padding-top: 2.1rem;
      padding-bottom: 2.6rem;

      .h2,
      h1,
      h2 {
        font-size: 2.5rem;
      }

      p {
        font-size: 1rem;
        line-height: 1.625rem;
      }
    }
  }
}
/* -----------------------------------------
05 - lg viewport
----------------------------------------- */
@media (min-width: @screen-lg-min) {
  .main-text {
    padding-bottom: 3.13rem;

    h1+div,
    h2+div {
      padding-bottom: 1.2rem;
    }

    .h2,
    h1,
    h2 {
      padding-top: 4.03rem;
      padding-bottom: 1.72rem;
      font-size: 3.375rem;
    }

    p {
      padding-bottom: 0.9rem;
      font-size: 1.25rem;
    }

    h3 {
      font-size: 2rem;
      padding-top: 5rem;
      letter-spacing: 1px;
    }

    p.small-p,
    ul {
      font-size: 1rem;
    }

    .btn {
      margin-bottom: 0.7rem;
      margin-top: 2.15rem;
      & + .btn{
        margin-top: 0.373rem;
      }
    }

    .prices-container {
      margin-top: 2rem;

      .detail-info-wrap {
        margin-bottom: 1rem;
      }
    }

    &.two-col-layout {
      padding-top: 3.5rem;
      padding-bottom: 3.7rem;

      .h2,
      h1,
      h2 {
        font-size: 3.375rem;
      }

      p {
        font-size: 1.25rem;
        line-height: 2rem;
      }
    }

    &.main-text-order-status {
      .ico-status {
        padding-top: 4rem;
        padding-bottom: 0.3rem;

        svg {
          width: 3.75rem;
          height: 3.75rem;
        }
      }

      p {
        font-size: 1.25rem;
        line-height: 2rem;
      }

      p.order-id {
        padding-top: 2.9rem;
        padding-bottom: 0.9rem;
        line-height: 1.9rem;
      }

      .h2,
      h1,
      h2 {
        font-size: 3.375rem;
        padding-top: 1.1rem;
        padding-bottom: 1.7rem;
      }

      .btn {
        margin-top: 0;
      }
    }

    &.bg-photo {
      min-height: 23.75rem;
    }
  }
}
/* -----------------------------------------
06 - xl viewport
----------------------------------------- */
@media (min-width: @screen-xl-min) {}
/*
 * Business Variant
 */
/* -----------------------------------------
07 - Business overrides
----------------------------------------- */
.business {
  .main-text {
    background-color: @dark-sand-brown;
    color: @white;

    // Fixing Production issue background color
    &.bg-sand-white{
      background: @sand-white !important;
      color: @steel-grey !important;
      h2 {
        color: @premium !important;
      }
    }
    &.bg-light-sand-brown {
      background-color: @light-sand-brown !important;
      color: @steel-grey !important;
      h2 {
        color: @premium !important;
      }
    }
    &.bg-white {
      background-color: @white !important;
      color: @steel-grey !important;
      h2 {
        color: @premium !important;
      }
    }
    // Fixing Production issue background color

    .h2,
    h1,
    h2 {
      color: @white;
    }

    &.new-gray-div {
      background-color: #e2e2da;
      h2 {
        color: @almost-black;
      }

      a,
      p {
        color: @steel-grey;
      }

      p.order-id {
        color: @almost-black;

        span {
          color: @dark-green;
        }

        span.error {
          color: @pink;
        }
      }
      .btn-default {
        background-color: #e2e2da;
        border-color: #363639;
        color: #363639;
      }
    }

    &.bg-photo {
      background-color: rgba(80, 91, 77, 0.8);
    }

    & hr {
      border-top: 1px solid @dark-green;
    }

    &.cross-nav-text {
      background-color: @light-sand-brown;
      color: @gray-close-dropdown;

      .h2,
      h1,
      h2 {
        color: @almost-black;
      }
    }

    .ico-status {
      fill: @dark-green;
    }


    &.bg-lighty {
      background-color: @light-sand-brown;

      h2 {
        color: @almost-black;
      }

      a,
      p {
        color: @steel-grey;
      }

      p.order-id {
        color: @almost-black;

        span {
          color: @dark-green;
        }

        span.error {
          color: @pink;
        }
      }
    }

    &.bg-transparent {
      background-color: transparent;

      ul{
        color: @steel-grey;
      }
      a, p, h2 {
        color: @almost-black;
        span {
          color: @dark-green;
        }

        span.error {
          color: @pink;
        }
      }
    }

    &.main-text-order-status {
      background-color: @sand-white;

      h2 {
        color: @almost-black;
      }

      a,
      p {

      }

      p.order-id {
        color: @almost-black;

        span {
          color: @dark-green;
        }

        span.error {
          color: @pink;
        }
      }
    }
  }
  .order-detail-heading {
    h2 {
      padding-top: 2.5rem;
      padding-bottom: 0;
    }
    svg {
      height: 24px;
      width: 24px;
      text-align: left;
      margin-top: 3.2rem;
      fill: #fff;
      transform: translate(0) rotate(90deg);
    }
    .delete-order {
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
  .main-text.bg-photo.all-orders {
    min-height: 10rem;
  }
  .order-detail-loader {
    background: @sand-white;
    svg {
      width: 40px;
      height: 40px;
    }
  }

  #back-to-top {
    position: fixed;
    bottom: 20%;
    right: 40px;
    z-index: 9999;
    width: 50px;
    height: 50px;
    padding: 20px;
    line-height: 30px;
    background: @dark-green;
    color: @white;
    cursor: pointer;
    border: 0;
    border-radius: 50%;
    text-decoration: none;
    box-shadow: 0px 2px 8px @gray-dark;
    transition: opacity 0.2s ease-out;
    opacity: 0;
    svg {
      width: 22px;
      height: 22px;
      fill: @white;
    }
  }
  #back-to-top.show {
    opacity: 1;
  }

}
@media (max-width: @screen-sm-min) {
  .business {
    .order-detail-heading {
      h2 {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
      }
      svg {
        margin-top: 2.8rem;
        padding-right: 5px;
      }
    }
  }
}
.main-text {
  &.beINconnect {
    @media (max-width: 767px) {
      padding-bottom: 2rem;
    }
    h2 {
     font-size: 3rem;
     letter-spacing: 0;
   }
   p {
    font-size: 1.1rem;
  }
}
}
/*
08 - Arabic overrides
*/
*[lang="ar"] {
  *[dir="rtl"] {
    .flexisble-ar{display:flex}
    .main-text {
      text-align: center;

      &.text-right {
        .btn,
        a,
        h1,
        h2,
        h3,
        p {
          text-align: center;
        }
      }

      &.text-left {
        & .body-big{
          text-align: center;
        }
        .btn,
        a,
        h1,
        h2,
        h3,
        p {
          text-align: center;
        }
      }

      &.two-col-layout {
        ul {
          text-align: center;
        }
      }
      &.bg-photo{
        flex-direction: row-reverse;
      }
      &.main-text-H  {
        @media (min-width: 768px) {
          .col-sm-ar-offset-0.col-sm-ar-offset-right-1 {
            margin-right: 8.33333333%;
          }
          .col-sm-ar-offset-1.col-sm-ar-offset-right-0 {
            margin-left: 8.33333333%;
            margin-right: 0;
          }
        }
      }

    }
    @media (min-width: @screen-ms-min) {
      .main-text {
        &.two-col-layout {
          .h2,
          h1,
          h2 {
            text-align: center;
          }

          p {
            text-align: center;
          }
        }
      }
    }
    @media (min-width: @screen-sm-min) {
      .main-text {
        &.two-col-layout {
          .h2,
          h1,
          h2 {
            text-align: right;
          }

          p {
            text-align: center;
          }
        }
      }
    }
    @media (min-width: @screen-md-min) {}
    @media (min-width: @screen-lg-min) {}
  }
}
/* -----------------------------------------
09 - support page - contact us form
----------------------------------------- */
.contact-us-form {
  &.open-form {
    .acc-btn {
      display: none;
    }
  }

  .contact-us-form-container {
    display: none;
    width: 100%;
  }
}
.text-HO-summary {
  text-align: left;
  background-color: @light-sand-brown;
  color: @almost-black;
  padding-bottom: 20px;
  h3 {
    font-size: 2rem;
  }
}
.business .main-text.ho-success-white {
  background-color: @white;
  color: @almost-black;
  h2 {
    color: @almost-black;
    font-size: 2rem;
    padding-top: 1.4rem;
  }
  img {
    max-width: 50%;
  }
}



.bandwidthText {
  p {
    margin-bottom: 30px;
    font-size: 1.5rem;
    color: @gray;
  }
}
//mobile storelocator message b2c cms 23nov2017
.storelocatorMob .main-text {
  padding-top: 1.5rem;
}

/*Mobile Registration Renewal*/
.main-text-mobile-registraion{
  padding-top: 2rem;
  .etisalat-logo-img {
    width: 100px;
    height: 40px;
  }
  h3{
    padding-top: 0px;
  }
  p{
    font-size: 1.4rem;
  }
  .main-text-order-status{
    padding: 1rem 0px 2rem 0px;
    h3{
      text-transform: capitalize;
      padding-top: 1rem;
    }
    p{
      font-size: 0.98rem;
      padding-bottom: 2rem;
      a{
        color: @main-green;
        word-break: break-all;
      }
    }
  }
  .ico-status{
    width: 120px;
    margin: auto;
    img{
      width: 100%;
    }
  }
}
/*Emirates Uplaod and Scan Mobile Registration Renewal*/
.scan-upload-emirates-id{
  .consumer-register-form {
    .form-container{
      padding-bottom: 0px;
      padding-top: 0px;
      .form-section{
        .checkbox{
          label{
            a{
              color: @main-green;
            }
          }
        }
      }
      .consumer-register-form-submit{
       &.form-submit{
        button{
          height: 4rem;
        }
      }
    }
  }
}
.form .inputfile-label{
  margin: 0.5rem;
}
h3{
  text-transform: capitalize;
  letter-spacing: 0px;
}
.eid-upload {
  text-align: left;
  @media (max-width: 767px) {
    text-align: center;
    margin-top: 2rem;
  }
  h5{
    text-align: left;
    margin-bottom: 0.5rem;
    font-family: @font-family-base;
    @media (max-width: 767px) {
    text-align: center !important;
  }
  }
  & .inputfile {
    width: .1px;
    height: .1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    margin: 0px;
  }
  .eidFront{
    margin-right: 1rem;

  }

  h5 {
    text-align: left;
    margin: 0 0 4px 0;
    font-size: 1rem;
    line-height: 19px;
    font-weight: 600;
    color: #353733;
    font-family: Lato,Roboto,Arial,sans-serif;
    letter-spacing: 0;
    text-transform: capitalize;
  }
  span{
    margin-bottom: 15px;
    display: block;
  }
  .eidFront, .eidBack {
    label {
      overflow: hidden;
      position: relative;
      border-radius: 4px;
      cursor: pointer;
      margin-bottom: 10px;
      border:solid 1px #fff;
      text-align: center;
    }
    .ImageUploadHover {
     display:none;
   }
   &:hover {
    .ImageUploadHover {
     display:block;position:absolute; background:rgba(0,0,0,0.7);width:100%; height:100%;
     .cameraIcon {
       position: absolute;
       left: 0;
       right: 0;
       top: 0;
       bottom: 0;
       margin: auto;
       width: 68px;
       height: 48px;
       opacity: 0.6;
       width: 100%;
       img {
         min-height: 24px;
         width: 28px;
         margin-bottom: 5px;
       }
       h6 {
         color: #fff;
         font-size: 0.625rem;
         text-align: center;
         text-transform: uppercase;
         margin: 2px 0 8px 0;
         font-family: Lato,Roboto,Arial,sans-serif;
       }
     }
   }
 }
}
& .inputfile-label.uploaded {
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  //margin: auto;
  text-align: left;
  font-size: 14px;
  line-height: 17px;
  color: @steel-grey;
  margin: 0px;
  margin-bottom: 20px;
}
.eidFront-container {
  #frontEid {
    width: 250px;
    height: 150px;
    object-fit: cover;
  }
}
.eidBack-container {
  #backEid {
    width:250px;
    height: 150px;
    object-fit: cover;
  }
  @media (max-width: 767px) {
    margin-top: 1rem;
  }
}
}
}
.footer.footer-scan-id{
  margin-top: 0px;
}
/*08 - Arabic overrides*/
*[lang="ar"] {
  *[dir="rtl"] {
    .scan-upload-emirates-id{
      .eid-upload {
        h5 {
          text-align: right;
          @media (max-width: 767px) {
            text-align: center;
          }
        }
        span{
          text-align: right;
          @media (max-width: 767px) {
            text-align: center;
          }
        }
        .eidFront{
          margin-left: 1rem;
          margin-right: 0px;
          text-align: right;
          @media (max-width: 767px) {
            text-align: center;
          }
        }
        .eidBack{
          margin-left: 1rem;
          margin-right: 0px;
          text-align: right;
          @media (max-width: 767px) {
            text-align: center;
          }
        }
        & .inputfile-label.uploaded {
          text-align: right;
          @media (max-width: 767px) {
            text-align: center;
          }
        }
      }
    }

    .wst .main-text {
      .body-big p,
      h2 {
        text-align: right;
      }
      ul {
        padding-right: 25px;
        @media (max-width: 768px) {
          padding-right: 0 !important;
        }
        li {
          text-align: right;
        }
      }
    }

  }
}
