.progressbar-wrapper {
  .progress {
    width: 100%;
    display: block;
    border: none;
    background: @white;
    height: 30px;
    margin-bottom: 10px;
    .progress-bar {
      height: 100%;
      text-align: center;
      background: @dark-green;
      color: #fff;
      width: 0%;
    }
  }
}
.config-accordion-ticked{
  .bandwidthText{
    color:@white;
  }  
}
