
/* ----------------- tables ----------------- */
.table-bugfixing{
 .table {
    font-size: .875rem;
    border: 0;

    caption {
      color: @almost-black;
      font-family: Lato;
      font-size: .75rem;
      font-weight: 900;
      letter-spacing: 2px;
      line-height: .75rem;
      padding: 1rem 0;
      text-transform: uppercase;
    }

     > tfoot,
     > thead,
    > tbody {
      > tr {
         > th,
        > td {
          padding: @table-cell-padding;
          line-height: @line-height-base;
          vertical-align: middle;
          padding: .625rem 1rem;
          font-weight: 400;
          color: @steel-grey;
          border-top: 1px solid @light-sand-brown;
          word-break: break-word;

          .price {
            color: @almost-black;
            font-weight: 700;
            margin-right: 4px;
          }

          &.price-col {
            text-align: right;
          }

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }

         > th.order-shipped,
        > td.order-shipped {
          padding: 0 0 0 12% !important;
        }

        > th {
          font-weight: 400;
          color: @steel-grey
        }
      }

      > tr.discount {
        color: #F5A623;
      }
    }

    &.table-without-title {
       > tfoot,
       > thead,
      > tbody {
        > tr {
           > th,
          > td {
            border-top: 0;
            border-bottom: 1px solid @light-sand-brown;
          }

        }
      }
    }

    &.table-with-action {
       > tfoot,
       > thead,
      > tbody {
        > tr {
          &:first-child {
             > th,
            > td {
              border-top: 0;
            }
          }
        }
      }
    }

    &.table-striped {
      > tbody > tr:nth-of-type(odd) {
        background-color: #FAFAF5;
      }
      // Cells
       > tfoot,
       > thead,
      > tbody {
        > tr {
           > th,
          > td {
            border: 0;

            &:first-child {
              border-radius: 4px 0 0 4px;
              padding: .625rem 1rem;
            }

            &:last-child {
              border-radius: 0 4px 4px 0;
              padding: .625rem 1rem;
            }
          }
        }
      }
    }

    .discount {
      .price,
      td,
      th {
        color: @dark-orange !important;
      }
    }
  }

  /* ----------------- tables for WST variations ----------------- */
  .table-default-section {
    .table-striped {
      tbody>tr>td {
        color: @almost-black !important;
        @media screen and (max-width: @screen-md) {
          font-size: 14px;
        }
      }
      &.two-col {
        thead {
          tr th {
            @media screen and (min-width: @screen-md) {
              width: 50%;
            }
          }
        }
      }
      &.three-col {
        thead {
          tr th {
            @media screen and (min-width: @screen-md) {
              width: 33.33%;
            }
          }
        }
        tbody {
          tr{
            td {
              position: relative;
              &:first-child {
                padding-left: 3.5rem;
                &:before {
                  @media screen and (max-width: @screen-md) {
                    margin-left: -40px;
                  }
                }
              }
              svg {
                width: 24px;
                height: 24px;
                position: absolute;
                left: 1rem;
                top: .5rem;
                @media screen and (max-width: @screen-md) {
                  top: 2.5rem;
                }
              }

            }
          }
        }
      }
      &.four-col {
        thead {
          tr th {
            @media screen and (min-width: @screen-md) {
              width: 25%;
            }
          }
        }
      }
    }

    .table {
      thead {
        th {
          font-size: 12px;
          letter-spacing: 2px;
          font-family: @font-family-base;
          color: @almost-black;
          text-transform: uppercase;
          vertical-align: bottom;
          line-height: 16px;
          padding-bottom: 16px !important;
        }
      }

      tbody {
        > tr {
          > td {
            font-weight: bold;
            color: #848789;
            font-family: @font-family-base;
            line-height: 22px;
            font-size: 1rem;
            b {
              color: @almost-black;
            }
            .text {
              font-size: 14px;
              color: #6B6B6B;
              opacity: .9;
            }
          }
        }
      }
      @media screen and (max-width: @screen-md) {
        tbody,
        thead {
          td,
          tr {
            display: block;

          }
        }

        thead {
          display: none;
        }

        tbody {
          tr {
            td {
              &:before {
                content: attr(data-label);
                display: block;
                font-size: .75rem;
                letter-spacing: 2px;
                font-family: @font-family-base;
                color: @almost-black;
                text-transform: uppercase;
                vertical-align: bottom;
                line-height: 2rem;
                font-weight: normal;
              }
            }
          }
        }
      }

      &.table-no-border {
        thead {
          tr {
            @media (max-width: 992px) {
              display: table-row;
            }
          }

          th {
            font-weight: 900;
            color: @almost-black;
            font-size: .75rem;
            border: none;
            text-transform: uppercase;
            letter-spacing: 2px;
            padding: 0;
            padding-bottom: 34px !important;
            @media (max-width: 992px) {
              display: table-cell;
              padding-bottom: 26px !important;
            }
          }
          @media (max-width: 992px) {
            display: table-header-group;
          }
        }

        tbody {
          tr {
            @media (max-width: 992px) {
              display: table-row;
            }
          }

          td {
            border: none;
            font-size: 1rem;
            line-height: 1.375rem;
            font-weight: 900;
            padding: 0;
            padding-bottom: 20px;
            &:nth-child(1) {
              padding-left : 1rem;
            }

            & label {
              border: none;
              font-size: 1rem;
              line-height: 1.375rem;
              font-weight: 400;
              color: @steel-grey;
              margin-bottom: 0;
              @media (max-width: 992px) {
                font-size: 14px;
              }
            }
            @media (max-width: 992px) {
              display: table-cell;
              font-size: 14px;
            }
            &:last-child {
              color: @almost-black;
            }
          }
        }
      }
      &.table-row-border {
        thead {
          tr {
            @media (max-width: 992px) {
              display: table-row;
            }
          }

          th {
            font-weight: 900;
            color: @almost-black;
            font-size: .75rem;
            border: none;
            text-transform: uppercase;
            letter-spacing: 2px;
            padding-bottom: 34px !important;
            padding-top: 0;
            padding-left: 0;
            @media (max-width: 992px) {
              display: table-cell;
              padding-bottom: 26px !important;
            }
          }
          @media (max-width: 992px) {
            display: table-header-group;
          }
        }

        tbody {
          tr {
            border-bottom: 2px solid #D2D2D2;
            @media (max-width: 992px) {
              display: table-row;
            }
            td {
              padding-top: 20px;
            }
            &:first-child {
              td {
                padding-top: 0;
              }
            }

          }

          td {
            border: none;
            font-size: 1rem;
            line-height: 1.375rem;
            font-weight: 900;
            vertical-align: middle;
            padding: 0;
            & label {
              border: none;
              font-size: 1rem;
              line-height: 1.375rem;
              font-weight: 400;
            }
            @media (max-width: 992px) {
              display: table-cell;
              vertical-align: top;
              font-size: 14px;
            }
            &:last-child {
              color: @almost-black;
            }
          }
        }
      }

      ul {
        &.tableListItem {
          li {
            border: none;
            font-size: 1rem;
            line-height: 1.375rem;
            font-weight: 400;
            color: @steel-grey;
            padding: 0;
            padding-bottom: 20px;
            @media (max-width: 992px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .table-default-section .table.table-row-border tbody td {
    &:nth-child(1) {
      padding-left : 1rem;
    }
  }
  // arabic language
  *[lang="ar"] {
    *[dir="rtl"] {
      .table-default-section {
        .table {
          thead {
            th {
              padding-left: 1rem;
              padding-right: 0;
            }
          }

          tbody {
            td {
              &:last-child {
                padding-left: 1rem;
                padding-right: 1rem;
              }
            }
          }
        }
      }
    }
  }

  .charges-table-section {
    margin-bottom: 2rem;

    table {
      @media screen and (min-width: 768px) {
        border-spacing: 1rem 0;
        border-collapse: separate;
      }

      tbody,
      thead {
        th{
          @media screen and (min-width: 768px) {
            padding: 0 1rem !important;
            text-align: left;
          }
        }
        td,
        th {
          font-size: 20px;
          color: @almost-black;
          font-family: @font-family-base;
          vertical-align: bottom;
          padding: 0;
          line-height: 48px;
          border: none;
          text-align: left;
          width: 50%;
          @media screen and (min-width: 768px) {
            border-bottom: 2px solid #E5E5E5;
          }
          @media screen and (max-width: 767px) {
            font-size: 14px;
            vertical-align: top;
            line-height: 28px;
            font-weight: 700;
            height: 48px;
            padding: .75rem 0;
          }
        }
      }

      tbody {
        tr{
          &:last-child{
            @media screen and (max-width: 767px) {
              td {
                min-width: 100%;
                color: @steel-grey;
                font-weight: normal;
              }
            }
          }

        }

        td {
          color: @steel-grey;
          @media screen and (min-width: 768px) {
            padding: 0 1rem !important;
          }
          &:first-child {
          }
          @media screen and (max-width: 767px) {
            &:first-child {
              min-width: 100px;
            }

            &:last-child {
              color: @almost-black;
              font-weight: bold;
            }
          }
        }
      }

      &.equal-column {
        border-spacing: 0;
        margin: 0;

        tbody {
          tr {
            }

          td {
            width: 50%;
            border-bottom: 1px solid #e5e5e5;
            padding-left: 0;
            @media (min-width: 993px) {
              padding-left: 3rem;
            }

            &:first-child {
              min-width: 20px;
              width: 50%;
              @media (min-width: 993px) {
                padding-left: 3rem;
              }
            }
          }
        }
      }
    }

  }

  .heading-medium-neotech {
    color: @almost-black;
    font-size: 20px;
    line-height: 28px;
    margin: 0 0 1rem;

    &.big {
      font-size: 30px;
    }
    @media screen and (max-width: @screen-md) {
      font-size: 16px;
    }
  }


  @media (min-width: 1200px) {
    .table > tbody > tr > td,
    .table > thead > tr > td,
    .table > tfoot > tr > td {
      word-break: keep-all;

    }
  }
  *[lang="ar"] {
    *[dir="rtl"] {
      .charges-table-section table tbody td,
      .charges-table-section table tbody th,
      .charges-table-section table thead td,
      .charges-table-section table thead th{
        text-align: right;
      }
    }
  }
}
