.new-in-store-wrapper {
  background-color: @light-gray;
  .swiper-new-in-store-carousel-wrapper {
    display: flex;
    justify-content: flex-end;
    .rect-responsive {
      background-color: @white;
    }
    .swiper-wrapper {
      filter: blur(0);
      padding-left: 3rem;
    }
    .new-in-store-carousel-margin {
      width: 100%;
    }
    .new-in-store-carousel-arrows {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-left: 0.125rem @light-gray solid;
      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        flex-grow: 1;
        &:first-child {
          border-bottom: 0.125rem @light-gray solid;
        }
      }
      svg {
        width: 2.75rem;
        height: 0.875rem;
        stroke: @texts-gray;
      }
      .new-in-store-carousel-swiper-button-next {
        svg {
          transform: rotate(180deg);
          use{
            -moz-transform: rotate(180deg);
          }
        }
      }
      .swiper-button-disabled {
        svg {
          stroke: @light-sand-brown;
        }
      }
    }
    .swiper-slide {
      width: 33.33%;
    }
  }
  .h4 {
    font-size: 1rem;
  }
  .swiper-scrollbar-wrapper {
    display: none;
  }
  .btn-for-mobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}
@media (max-width: @screen-lg-min) {
  .new-in-store-wrapper {
    padding-bottom: 1.8rem;
    .new-in-store-heading {
      padding-top: 4rem;
      padding-bottom: 2.2rem;
    }
    .swiper-new-in-store-carousel-wrapper {
      .swiper-slide {
        width: 69%;
        margin-right: 4%;
        .rect-responsive {
          overflow: visible;
          padding-bottom: 123%;
          div.cover {
            width: auto;
            height: auto;
            top: 1.4rem;
            bottom: 1.4rem;
            left: 1.1rem;
            right: 1.1rem;
            overflow: hidden;
            .transition();
          }
          .new-in-store-info {
            bottom: 1.4rem;
            position: absolute;
            left: 1.1rem;
            right: 1.1rem;
            background: fade(@main-green, 70);
            color: @white;
          }
        }
      }
    }
    .h4 {
      padding: 0.05rem 0;
    }
    .brand-label {
      position: absolute;
      bottom: 100%;
      left: 1.1rem;
      right: 1.1rem;
      background-color: fade(@main-green, 70);
      color: @white;
      padding: 0.8rem 0;
      font-size: 0.875rem;
    }
    .btn {
      display: none;
    }
  }
}

@media (min-width: @screen-xs-min){
  .new-in-store-wrapper .swiper-new-in-store-carousel-wrapper .swiper-wrapper{
    padding-left: 3rem;
  }
}
@media (min-width: @screen-sm-min){
  .new-in-store-wrapper .swiper-new-in-store-carousel-wrapper .swiper-slide .new-in-store-info .h4{
    font-size: 1rem;
  }
  .new-in-store-wrapper .swiper-new-in-store-carousel-wrapper .swiper-wrapper{
    padding-left: 3rem;
  }
}
@media (min-width: @screen-md-min){
  .new-in-store-wrapper .swiper-new-in-store-carousel-wrapper .swiper-slide .new-in-store-info .h4{
    font-size: 1.25rem;
  }
}

@media (min-width: @screen-sm-min) and (max-width: @screen-lg-min) {
  .new-in-store-wrapper {
    padding-bottom: 1.8rem;
    .swiper-new-in-store-carousel-wrapper {
      .swiper-slide {
        width: 33.33%;
        margin-right: 2.2%;
        .rect-responsive {
          div.cover {
            top: 1.4rem;
            bottom: 1.4rem;
            left: 1.1rem;
            right: 1.1rem;
          }
        }
      }
    }
    .h4 {

      padding: 0.9rem 0;
    }
    .brand-label {
      left: 1.1rem;
      right: 1.1rem;
      padding: 0.8rem 0;
    }
  }
  *[lang="ar"] {
    *[dir="rtl"] {
      .new-in-store-wrapper {
        .swiper-new-in-store-carousel-wrapper {
          .swiper-slide {
            margin-left: 2.2%;
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media (min-width: @screen-md-min) and (max-width: @screen-lg-min) {
  .new-in-store-wrapper {
    padding-bottom: 3.8rem;
    .swiper-new-in-store-carousel-wrapper {
      .swiper-slide {
        width: 34.2%;
        margin-right: 2.2%;
        .rect-responsive {
          padding-bottom: 118.3%;
          div.cover {
            top: 1.8rem;
            left: 1.5rem;
            right: 1.5rem;
            bottom: 1.8rem;
          }
          .new-in-store-info {
            left: 1.5rem;
            right: 1.5rem;
            bottom: 1.8rem;
          }
        }
      }
    }
    .h4 {
      padding: 0.9rem 0;
    }
    .brand-label {
      left: 1.5rem;
      right: 1.5rem;
      padding: 1rem 0;
      font-size: 1rem;
    }
  }
}
@media (min-width: @screen-lg-min) {
  .new-in-store-wrapper {
    padding-bottom: 2.9rem;
    .new-in-store-heading {
      padding-top: 4rem;
      padding-bottom: 2.2rem;
    }
    .swiper-new-in-store-carousel-wrapper {
      justify-content: center;
      & > div {
        background-color: @white;
      }
      .new-in-store-carousel-margin {
        padding-left: 0.81rem;
        padding-right: 0.81rem;
      }
      .swiper-slide {
        width: 33.33%;
        margin-right: 0;
        .new-in-store-info {
          background-color: fade(@main-green, 70);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          color: @white;
          display: flex;
          position: absolute;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          opacity: 0;
          .transition();
          .h4 {
            margin-top: 3.35rem;
            margin-bottom: 4.1rem;
            font-size:  1.5rem;
          }
          &:hover {
            opacity: 1;
          }
        }
        .rect-responsive {
          padding-bottom: 124.3%;
          margin-bottom: 0;
          .content {
            width: auto;
            height: auto;
            top: 2rem;
            bottom: 2rem;
            left: 0.81rem;
            right: 0.81rem;
            overflow: hidden;
            .transition();
          }
        }
      }
    }
    .swiper-scrollbar-wrapper {
      display: block;
      margin-bottom: 2.8rem;
      height: 0.25rem;
      .new-in-store-carousel-swiper-scrollbar {
        height: 0.25rem;
        .swiper-scrollbar-drag {
          background-color: @main-green;
        }
      }
    }
    .btn-for-mobile {
      display: none;
    }
  }
  .new-in-store-wrapper .swiper-new-in-store-carousel-wrapper .swiper-wrapper{
    padding-left: 0rem;
  }
  }
/*
 * ARABIC
 */
*[lang="ar"] {
  *[dir="rtl"] {
    .new-in-store-wrapper {
      .swiper-new-in-store-carousel-wrapper {
        .new-in-store-carousel-arrows {
          border-left: none;
          border-right: 0.125rem @light-gray solid;
          .new-in-store-carousel-swiper-button-next {
            svg {
              // transform: rotate(180deg);
              transform: scale(-1);
              use {
                -moz-transform: scale(-1);
              }
            }
          }
        }
        .swiper-wrapper {
        padding-left: 0;
        padding-right: 3rem;
    }
      }
    }
    @media (min-width: @screen-xs-min){
      .new-in-store-wrapper .swiper-new-in-store-carousel-wrapper .swiper-wrapper{
        padding-left: 0rem;
        padding-right: 3rem;
      }
    }
    @media (min-width: @screen-sm-min) {
      .new-in-store-wrapper .swiper-new-in-store-carousel-wrapper .swiper-wrapper{
        padding-left: 0rem;
        padding-right: 3rem;
      }
    }
    @media (min-width: @screen-lg-min) {
      .new-in-store-wrapper .swiper-new-in-store-carousel-wrapper .swiper-wrapper{
        padding-left: 0rem;
        padding-right: 0rem;
      }
    }
  }
}
