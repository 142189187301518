.col-offset-right(@i, @type) when (@i >= 0) {

  .col-@{type}-offset-right-@{i} {
    margin-right: percentage((@i / @grid-columns));
  }
  .col-offset-right(@i - 1, @type);

}


.col-offset-ar(@i, @type) when (@i >= 0) {

  .col-@{type}-ar-offset-@{i} {
    margin-right: percentage((@i / @grid-columns));
  }
  .col-@{type}-ar-offset-right-@{i} {
    //margin-left: percentage((@i / @grid-columns));
    margin-left:0;
  }
  .col-offset-ar(@i - 1, @type);

}


.col-offset-right(@grid-columns, xs);

@media (min-width: @screen-ms-min) {
  .col-offset-right(@grid-columns, ms);
}

@media (min-width: @screen-sm-min) {
  .col-offset-right(@grid-columns, sm);
}

@media (min-width: @screen-md-min) {
  .col-offset-right(@grid-columns, md);
}

@media (min-width: @screen-lg-min) {
  .col-offset-right(@grid-columns, lg);
}

@media (min-width: @screen-xl-min) {
  .col-offset-right(@grid-columns, xl);
}

/*
 * ARABIC
 */
// *[lang="ar"] {
*[dir="rtl"] {

  .col-offset-right(@grid-columns, xs-ar);
  .col-offset-ar(@grid-columns, xs);

  @media (min-width: @screen-ms-min) {
    .col-offset-right(@grid-columns, ms-ar);
    .col-offset-ar(@grid-columns, ms);
  }

  @media (min-width: @screen-sm-min) {
    .col-offset-right(@grid-columns, sm-ar);
    .col-offset-ar(@grid-columns, sm);
  }

  @media (min-width: @screen-md-min) {
    .col-offset-right(@grid-columns, md-ar);
    .col-offset-ar(@grid-columns, md);
  }

  @media (min-width: @screen-lg-min) {
    .col-offset-right(@grid-columns, lg-ar);
    .col-offset-ar(@grid-columns, lg);
  }

  @media (min-width: @screen-xl-min) {
    .col-offset-right(@grid-columns, xl-ar);
    .col-offset-ar(@grid-columns, xl);
  }
 .col-ar-right {
   float:right;
 }
 .col-offset-right(@i, @type) when (@i >= 0) {

   /*test */
  //  .col-@{type}-ar-offset-@{i}.col-@{type}-ar-offset-right-@{i}{
  //    margin-left:percentage((@i / @grid-columns));
  //  }
 }
 .col-md-ar-offset-right-1.col-md-ar-offset-right-2{
   margin-left:16.66666667%;
 }
 .login {
   .col-md-ar-offset-1.col-md-ar-offset-right-2 {
       @media (min-width: @screen-md-min) {
         margin-left: 16.66666667% !important;
         margin-right: 8.333333%;
       }
   }
   .col-md-ar-offset-2.col-md-ar-offset-right-1 {
      @media (min-width: @screen-md-min) {
        margin-left: 8.333333% !important;
      }
    }
 }

}

// }
