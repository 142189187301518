
html {
  font-size: @font-size-base;
}

@import "./bootstrap/type.less";





//XS
.body-big p, .body-big{
  font-size: @body-big-xs;
  line-height: (@line-height-base)em;
}

.body-standard p, .body-standard{
  font-size: @body-standard-xs;
  line-height: (@line-height-base)em;
}

.body-small p, .body-small{
  font-size: @body-small-xs;
  line-height: (@line-height-base)em;
}

.body-smallest p, .body-smallest{
  font-size: @body-smallest-xs;
  line-height: (@line-height-base)em;
}


@kerning-h1: @kerning-h1-xs;
@kerning-h2: @kerning-h2-xs;
@kerning-h3: @kerning-h3-xs;
@kerning-h4: @kerning-h4-xs;
@kerning-h5: @kerning-h5-xs;
@kerning-h6: @kerning-h5-xs;


h1, .h1, .heading-bigger { letter-spacing: @kerning-h1;}
h2, .h2, .heading-big { letter-spacing: @kerning-h2;}
h3, .h3 { letter-spacing: @kerning-h3;}
h4, .h4 { letter-spacing: @kerning-h4;}
h5, .h5 { letter-spacing: @kerning-h5;}
h6, .h6 { letter-spacing: @kerning-h6; }

//MS
@media (min-width: @screen-ms-min) {

  @font-size-h1: @font-size-h1-ms;
  @font-size-h2: @font-size-h2-ms;
  @font-size-h3: @font-size-h3-ms;
  @font-size-h4: @font-size-h4-ms;
  @font-size-h5: @font-size-h5-ms;
  @font-size-h6: @font-size-h5-ms;

  @kerning-h1: @kerning-h1-ms;
  @kerning-h2: @kerning-h2-ms;
  @kerning-h3: @kerning-h3-ms;
  @kerning-h4: @kerning-h4-ms;
  @kerning-h5: @kerning-h5-ms;
  @kerning-h6: @kerning-h5-ms;

  h1, .h1, .heading-bigger { font-size: @font-size-h1; letter-spacing: @kerning-h1; }
  h2, .h2, .heading-big { font-size: @font-size-h2; letter-spacing: @kerning-h2; }
  h3, .h3 { font-size: @font-size-h3; letter-spacing: @kerning-h3; }
  h4, .h4 { font-size: @font-size-h4; letter-spacing: @kerning-h4; }
  h5, .h5 { font-size: @font-size-h5; letter-spacing: @kerning-h5; }
  h6, .h6 { font-size: @font-size-h6; letter-spacing: @kerning-h6; }


  .body-big p,
  .body-big{
    font-size: @body-big-ms;
  }

  .body-standard p,
  .body-standard{
    font-size: @body-standard-ms;
  }

  .body-small p,
  .body-small{
    font-size: @body-small-ms;
  }

  .body-smallest p,
  .body-smallest{
    font-size: @body-smallest-ms;
  }


}


//SM
@media (min-width: @screen-sm-min) {

  @font-size-h1: @font-size-h1-sm;
  @font-size-h2: @font-size-h2-sm;
  @font-size-h3: @font-size-h3-sm;
  @font-size-h4: @font-size-h4-sm;
  @font-size-h5: @font-size-h5-sm;
  @font-size-h6: @font-size-h5-sm;

  @kerning-h1: @kerning-h1-sm;
  @kerning-h2: @kerning-h2-sm;
  @kerning-h3: @kerning-h3-sm;
  @kerning-h4: @kerning-h4-sm;
  @kerning-h5: @kerning-h5-sm;
  @kerning-h6: @kerning-h5-sm;

  h1, .h1, .heading-bigger { font-size: @font-size-h1; letter-spacing: @kerning-h1; }
  h2, .h2, .heading-big { font-size: @font-size-h2; letter-spacing: @kerning-h2;}
  h3, .h3 { font-size: @font-size-h3; letter-spacing: @kerning-h3; }
  h4, .h4 { font-size: @font-size-h4; letter-spacing: @kerning-h4; }
  h5, .h5 { font-size: @font-size-h5; letter-spacing: @kerning-h5; }
  h6, .h6 { font-size: @font-size-h6; letter-spacing: @kerning-h6; }

  .body-big p,
  .body-big{
    font-size: @body-big-sm;
  }

  .body-standard p,
  .body-standard{
    font-size: @body-standard-sm;
  }

  .body-small p,
  .body-small{
    font-size: @body-small-sm;
  }

  .body-smallest p,
  .body-smallest{
    font-size: @body-smallest-sm;
  }

}

//MD
@media (min-width: @screen-md-min) {

  @font-size-h1: @font-size-h1-md;
  @font-size-h2: @font-size-h2-md;
  @font-size-h3: @font-size-h3-md;
  @font-size-h4: @font-size-h4-md;
  @font-size-h5: @font-size-h5-md;
  @font-size-h6: @font-size-h5-md;

  @kerning-h1: @kerning-h1-md;
  @kerning-h2: @kerning-h2-md;
  @kerning-h3: @kerning-h3-md;
  @kerning-h4: @kerning-h4-md;
  @kerning-h5: @kerning-h5-md;
  @kerning-h6: @kerning-h5-md;

  h1, .h1, .heading-bigger { font-size: @font-size-h1; letter-spacing: @kerning-h1; }
  h2, .h2, .heading-big { font-size: @font-size-h2; letter-spacing: @kerning-h2;}
  h3, .h3 { font-size: @font-size-h3; letter-spacing: @kerning-h3; }
  h4, .h4 { font-size: @font-size-h4; letter-spacing: @kerning-h4; }
  h5, .h5 { font-size: @font-size-h5; letter-spacing: @kerning-h5; }
  h6, .h6 { font-size: @font-size-h6; letter-spacing: @kerning-h6; }

  .body-big p,
  .body-big{
    font-size: @body-big-md;
  }

  .body-standard p,
  .body-standard{
    font-size: @body-standard-md;
  }

  .body-small p,
  .body-small{
    font-size: @body-small-md;
  }

  .body-smallest p,
  .body-smallest{
    font-size: @body-smallest-md;
  }

}

//LG
@media (min-width: @screen-lg-min) {

  @font-size-h1: @font-size-h1-lg;
  @font-size-h2: @font-size-h2-lg;
  @font-size-h3: @font-size-h3-lg;
  @font-size-h4: @font-size-h4-lg;
  @font-size-h5: @font-size-h5-lg;
  @font-size-h6: @font-size-h5-lg;

  @kerning-h1: @kerning-h1-lg;
  @kerning-h2: @kerning-h2-lg;
  @kerning-h3: @kerning-h3-lg;
  @kerning-h4: @kerning-h4-lg;
  @kerning-h5: @kerning-h5-lg;
  @kerning-h6: @kerning-h5-lg;

  h1, .h1, .heading-bigger { font-size: @font-size-h1; letter-spacing: @kerning-h1; }
  h2, .h2, .heading-big { font-size: @font-size-h2; letter-spacing: @kerning-h2;}
  h3, .h3 { font-size: @font-size-h3; letter-spacing: @kerning-h3; }
  h4, .h4 { font-size: @font-size-h4; letter-spacing: @kerning-h4; }
  h5, .h5 { font-size: @font-size-h5; letter-spacing: @kerning-h5; }
  h6, .h6 { font-size: @font-size-h6; letter-spacing: @kerning-h6; }

  .body-big p,
  .body-big{
    font-size: @body-big-lg;
  }

  .body-standard p,
  .body-standard{
    font-size: @body-standard-lg;
  }

  .body-small p,
  .body-small{
    font-size: @body-small-lg;
  }

  .body-smallest p,
  .body-smallest{
    font-size: @body-smallest-lg;
  }

}

//XL
@media (min-width: @screen-xl-min) {

  @font-size-h1: @font-size-h1-xl;
  @font-size-h2: @font-size-h2-xl;
  @font-size-h3: @font-size-h3-xl;
  @font-size-h4: @font-size-h4-xl;
  @font-size-h5: @font-size-h5-xl;
  @font-size-h6: @font-size-h5-xl;

  @kerning-h1: @kerning-h1-xl;
  @kerning-h2: @kerning-h2-xl;
  @kerning-h3: @kerning-h3-xl;
  @kerning-h4: @kerning-h4-xl;
  @kerning-h5: @kerning-h5-xl;
  @kerning-h6: @kerning-h5-xl;

  h1, .h1, .heading-bigger { font-size: @font-size-h1; letter-spacing: @kerning-h1; }
  h2, .h2, .heading-big { font-size: @font-size-h2; letter-spacing: @kerning-h2;}
  h3, .h3 { font-size: @font-size-h3; letter-spacing: @kerning-h3; }
  h4, .h4 { font-size: @font-size-h4; letter-spacing: @kerning-h4; }
  h5, .h5 { font-size: @font-size-h5; letter-spacing: @kerning-h5; }
  h6, .h6 { font-size: @font-size-h6; letter-spacing: @kerning-h6; }

  .body-big p,
  .body-big{
    font-size: @body-big-xl;
  }

  .body-standard p,
  .body-standard{
    font-size: @body-standard-xl;
  }

  .body-small p,
  .body-small{
    font-size: @body-small-xl;
  }

  .body-smallest p,
  .body-smallest{
    font-size: @body-smallest-xl;
  }

}
