.panel-dashboard{
  padding: 30px;

  label{

  }
  .summary-item{
    text-transform: uppercase;
  }
  .summary-value{
    color:#719E19
  }
}
.chart-card {
  canvas {
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
        }
        .chart-holder{
            position: relative;
        }
        .chart-title-val{
          position: absolute;
          left: 0;
          top: 40%;
          font-family: sans-serif;
          width: 100%;
          text-align: center;
        }
        background-color: #fff;
        padding: 20px;
        margin: 20px;
        .card-header {
          font-family: Neotech,Roboto,Arial,sans-serif;
          border-bottom: 1px solid #e2e2e2;
          padding-bottom: 10px;
        }

    .card-title {
      &.pull-right{
        text-align: right;
      }

      .card-title-head {
          font-size: 1em;
          font-weight: 600;
          color: #616b1e;
      }
      span {
          color: #666;
          font-size: small;
      }
    }
}

.svpn {
  .panel-wrapper{
    padding:1em;
  }

  .panel {
      margin-bottom: 20px;
      background-color: #fff;
      border: 1px solid transparent;
          border-top-color: transparent;
          border-right-color: transparent;
          border-bottom-color: transparent;
          border-left-color: transparent;
  }

  .panel-default > .panel-heading {
      color: #333;
      background-color: #e0e0e0;
      border-color: #ddd;
  }
  .panel-heading {
      padding: 10px 30px;
      border-bottom: 1px solid transparent;
          border-bottom-color: transparent;
  }
  .panel-body {
      padding: 15px;
  }
  .tunnel-bandwidth-allocation {
    .chart-card {
      margin: 0;
    }
  }

}

@media only screen and (max-width : 425px) {
  .svpn {
    .panel-dashboard {
         padding: 20px;
     }
  }
 }

/*--------swiper Ho styling----------*/
.hexagon {
     width: 300px;
     height: 173.21px;
     background-color: lightgreen;
     margin: 86.60px 0;
      border-left: 3px dotted #f00;;
     border-right:3px dotted #f00;
     box-shadow: 0 0 20px rgba(0,0,0,0.6);
}

.hexagon:before,
.hexagon:after {
     content: "";
     position: absolute;
     z-index: 1;
     width: 212.13px;
     height: 212.13px;
     -webkit-transform: scaleY(0.5774) rotate(-45deg);
     -ms-transform: scaleY(0.5774) rotate(-45deg);
     transform: scaleY(0.5774) rotate(-45deg);
     background-color: inherit;
     left: 40.9340px;
     box-shadow: 0 0 20px rgba(0,0,0,0.6);
}

.hexagon:before {
     top: -106.0660px;
     border-top: 3px dotted #f00;
     border-right:3px dotted #f00;
}

.hexagon:after {
     bottom: -106.0660px;
     border-bottom: 3px dotted #f00;
     border-left: 3px dotted #f00;
}

/*cover up extra shadows*/
.hexagon span {
     display: block;
     position: absolute;
     top:1.7320508075688772px;
     left: 0;
     width:294px;
     height:169.7410px;
     z-index: 2;
     background: inherit;
}

.ho-swiper-wapper{
    display: flex;
    padding: 0 20px;
    height: 22rem;
    .swiper-content svg{width:100%;margin:0 auto; height: 100%}
    .swiper-content{display: inline-block;    position: relative;  height: 100%; cursor: pointer;
      .size-small{
        font-size: smaller;
      }
    }
    .slide-text {
        position: absolute;
        top: 0;
        width: 100%;
        text-align: center;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .active{
      .slide-text {
          color:#fff;
      }
    }

    .add-ho{
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis:20%;
        display: flex;
        align-items: center;
        .icon-plus svg{ width:33px; height:35px; }
        .wrapper{display: flex; height: 100%; align-items: center;}
    }
    .ho-slider{
        flex-grow: 1;
        flex-basis:54%;
        -ms-flex-preferred-size:80%;
        position: relative;
    }
    .swiper-container .swiper-slide{background-color: transparent}
    .swiper-button-prev.swiper-button-disabled,.swiper-button-next.swiper-button-disabled{display: none}
    // .swiper-button-prev:before {
    //     content: '';
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     z-index: 0;
    //     background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
    //     background-size: 27px 44px;
    //     background-repeat: no-repeat;
    //
    // }
    //
    // .swiper-button-next:before {
    //     content: '';
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     z-index: 0;
    //     background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23007aff'/%3E%3C/svg%3E");
    //     background-size: 27px 44px;
    //     background-repeat: no-repeat;
    //
    // }

    .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
        background: linear-gradient(to left, rgba(255, 255, 255, 0),#f1f1eb);
        width: 100px;
        height: 80%;
        top: 15%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        left: 0;
        z-index: 1;
    }

    .swiper-button-next, .swiper-container-rtl .swiper-button-prev{
      background: linear-gradient(90deg,hsla(0, 0%, 100%, 0),#f1f1eb);
      width: 100px;
      height: 70%;
      top: 15%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      right: 0;
      z-index: 1;
    }
    .swiper-button-next svg, .swiper-container-rtl .swiper-button-prev svg,
    .swiper-button-prev svg, .swiper-container-rtl .swiper-button-next svg{
      stroke: #b3b0ab;
      width: 50px;
      height: 50px;
      stroke-width: 2px;
    }
    .tooltip{
      .tooltip-inner { background-color:#719e19; }
      .tooltip-arrow { border-right-color:#719e19; }

      .chart-card {margin:0;}
    }

    .popover {
      min-width:400px;
      .popover-content .chart-card {
          padding: 0;
          margin: 0;
      }
      .btn-link, .btn-link:active, .btn-link:focus, .btn-link:hover {
          border-color: transparent;
          border: none;
      }
    }

    .swiper-container .swiper-slide a.btn-link {
        margin-bottom: 1rem;
        margin-top: 1rem;
        line-height: 2rem;
        height: 2rem;
        border: none;
    }

    .btn-arrow-only svg {
        stroke: #719e19;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        width: 2rem;
        height: 1rem;
    }

    .active{
      .btn-arrow-only svg {
            stroke: #fff;
        }
    }

    .btn-arrow-only {
        padding: 5px;
    }
    .swiper-slide svg polygon {
        fill: #fdfdfd;
    }
    .swiper-slide.active svg polygon {
        fill: #616b1e;
    }
    //tablet
    @media only screen and (max-width : 768px) {
      display: block;
      height: 100%;

      .add-ho{width: 20%;    margin: 15px auto 0 ;}
      // .swiper-container{bottom: -11px;}
    }
    @media only screen and (max-width : 1024px) {
      .swiper-button-next{
        width: 50px;
        height: 90%;
        top:10%;
        svg{
          width: 20px;
        }
      }
      .swiper-button-prev{
        width: 50px;
        height: 100%;
        top:0;
        svg{
          width: 20px;
        }
      }
    }
    //mobile
    @media only screen and (max-width : 425px) {

        .swiper-content {
            width: 80%;
            height: 80%;
            margin: 0 auto 10px;
        }
        .add-ho{
          .swiper-content .slide-text > div {display: none}
        }
        .swiper-button-next{
          width: 50px;
          svg{
            width: 20px;
          }
        }
        .swiper-button-prev{
          width: 50px;
          svg{
            width: 20px;
          }
        }
        .swiper-container {
            // bottom: -4px;
            // height: 18rem;
        }
    }
}
.swiper-slide.active:after {
    content: '';
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 20px solid #ddd;
    position: absolute;
    bottom: 0;
    -ms-transform:translate(-9.5vw, 0px);
}
#serviceSection{background-color: #ddd}

/*-------svpn dashboard panel-----------*/
/*-manual add on merge -*/
.panel-default>.panel-heading.filters-wrapper {
    background-color: #f0f0f0;
}
.svpn-filters{
  // nav{line-height: 0}
  // .pagination>li>a, .pagination>li>span{
  //   padding: 6px 20px;
  // }
  .btn-group{
    display: flex;
    align-items: baseline;
    width:40%;
    min-width: 355px;
  }
  .btn-group :first-child {
    flex:1 1 0;
  }
  .svpn-filters :last-child {
    flex: 1 1 0;
    text-align: right;
}
  .btn-group label.btn.btn-primary.active {
      color: #ffffff;
      background-color: #616b1e;
      border-color: #616b1e;
  }
  .btn-group label.btn.btn-primary {
      color: #666;
      background-color: #fff;
      font-size: initial;
      padding: 0 20px 0;
      height: 4em;
      line-height: 4em;
      border-width: 1px;
  }

}
[data-toggle="buttons"] > .btn input[type="checkbox"], [data-toggle="buttons"] > .btn input[type="radio"], [data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"], [data-toggle="buttons"] > .btn-group > .btn input[type="radio"]{
  position: absolute;
  clip: rect(0,0,0,0);
  pointer-events: none;

}
/*-manual add on merge -*/

//less added by waqas for dashboard charts page
.panel-dashboard {
  padding: 30px;
  .summary-item {
    text-transform: uppercase;
    label {
      min-height: 3rem;
    }
  }
  .summary-value {
    color: @gray;
  }

  .btn-width-wrap .btn{
    width: 100%;
  }
  .panel-wrapper {
    .data-table-wrap .table-responsive{
      border: 0;
    }
  }

  .display  tbody tr:nth-child(1) {
    height: auto;
    display: table-row;
  }
  .display  tbody tr:nth-child(2) {
    height: auto;
    display: table-row;
  }
  .display  tbody tr:nth-child(3) {
    height: auto;
    display: table-row;
  }

  .display  tbody tr{
    height: 0;
    display: table-column;
  }
  .display.view-All {
    overflow: auto;
    display: block;
    max-height: 380px;
  }
  .display.view-All tbody tr {
    height: auto;
    display: table-row;
  }
}
/*site info*/
.site-info {
  .chart-card {
    margin:0;
    background-color: transparent;
  }
}
/*linear progress bars*/
.linear-progress-wrapper{

  &.flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .linear-progress {
          width: 46%;
          padding: 0;
          box-sizing: border-box;
          margin: 10px 0;
          color: #666;
      }
  }

  .meter {
  	height: 5px;  /* Can be anything */
  	position: relative;
  	background: #ccc;
  	-moz-border-radius: 5px;
  	-webkit-border-radius: 5px;
  	border-radius: 5px;
  	padding: 0;
    animation-name: linear-progress-anim;
    animation-duration: 2s;
  	// box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);

    > span {
      display: block;
      height: 100%;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      background-color: #719e19;
      transition: width 1s ease;
      // background-image: linear-gradient(
      //   center bottom,
      //   rgb(43,194,83) 37%,
      //   rgb(84,240,84) 69%
      // );
      // box-shadow:
      //   inset 0 2px 9px  rgba(255,255,255,0.3),
      //   inset 0 -2px 6px rgba(0,0,0,0.4);
      position: relative;
      overflow: hidden;
    }

    &.orange > span {
      background-color: #fc0;
      //background-image: linear-gradient(to bottom, #f1a165, #f36d0a);
    }

    &.red > span {
      background-color: #f42323;
      //background-image: linear-gradient(to bottom, #f0a3a3, #f42323);
    }
  }
}
@keyframes linear-progress-anim {
    from {width: 0% ;}
    to {width: 100%;}
}
/*responsive*/
@media (max-width: @screen-xs) {
  .svpn-filters{
    .btn-group {
      width: 100%;
      max-width: 355px;
      min-width: 300px
    }
    .btn-group label.btn.btn-primary {
        padding: 10px;
        height: 3em;
        line-height: 2em;
    }
  }
  .chart-card{margin:0; padding:0}
}
@media (max-width: 320px) {
  .svpn-filters{
    .btn-group {
      width: 100%;
      max-width: 300px;
      min-width: 0;
    }
    .btn-group label.btn.btn-primary {
        padding: 5px;
        height: 2em;
        line-height: 1em;
    }
  }
}

@media (min-width: @screen-sm-min) {

  .panel-dashboard {
    .summary-item {
      label {
        min-height: 1rem;
      }
    }
    .btn-width-wrap .btn{
      width: auto;
    }
  }
  .chart-card {
    .chart-title-val {
      top: 40%;
    }
  }
}

@media (min-width: @screen-md-min) {
  .chart-card {
    .card-title {
      text-align: left;
      &.pull-right {
        text-align: right;
      }
    }
  }
}
.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group {
    margin-left: -1px;
}
