/*
*   CSS file with Bootstrap grid classes for screens bigger than 1600px. Just add this file after the Bootstrap CSS file and you will be able to juse col-ml, col-ml-push, hidden-ml, etc.
*
*   Author: Marc van Nieuwenhuijzen
*   Company: WebVakman
*   Site: WebVakman.nl
*   https://github.com/marcvannieuwenhuijzen/BootstrapXL
*/
@media (min-width: @screen-lg-min) and (max-width: @screen-lg-max) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-ml,
.visible-ml-block,
.visible-ml-inline,
.visible-ml-inline-block {
  display: none !important;
}
@media (min-width: @screen-ml-min) {
  .container {
    
  }
  /*
  .container-fluid {
    max-width: @container-lg;
  }
*/
  .col-ml-1,
  .col-ml-10,
  .col-ml-11,
  .col-ml-12,
  .col-ml-2,
  .col-ml-3,
  .col-ml-4,
  .col-ml-5,
  .col-ml-6,
  .col-ml-7,
  .col-ml-8,
  .col-ml-9 {
    float: left;
  }

  .col-ml-12 {
    width: 100%;
  }

  .col-ml-11 {
    width: 91.66666667%;
  }

  .col-ml-10 {
    width: 83.33333333%;
  }

  .col-ml-9 {
    width: 75%;
  }

  .col-ml-8 {
    width: 66.66666667%;
  }

  .col-ml-7 {
    width: 58.33333333%;
  }

  .col-ml-6 {
    width: 50%;
  }

  .col-ml-5 {
    width: 41.66666667%;
  }

  .col-ml-4 {
    width: 33.33333333%;
  }

  .col-ml-3 {
    width: 25%;
  }

  .col-ml-2 {
    width: 16.66666667%;
  }

  .col-ml-1 {
    width: 8.33333333%;
  }

  .col-ml-pull-12 {
    right: 100%;
  }

  .col-ml-pull-11 {
    right: 91.66666667%;
  }

  .col-ml-pull-10 {
    right: 83.33333333%;
  }

  .col-ml-pull-9 {
    right: 75%;
  }

  .col-ml-pull-8 {
    right: 66.66666667%;
  }

  .col-ml-pull-7 {
    right: 58.33333333%;
  }

  .col-ml-pull-6 {
    right: 50%;
  }

  .col-ml-pull-5 {
    right: 41.66666667%;
  }

  .col-ml-pull-4 {
    right: 33.33333333%;
  }

  .col-ml-pull-3 {
    right: 25%;
  }

  .col-ml-pull-2 {
    right: 16.66666667%;
  }

  .col-ml-pull-1 {
    right: 8.33333333%;
  }

  .col-ml-pull-0 {
    right: auto;
  }

  .col-ml-push-12 {
    left: 100%;
  }

  .col-ml-push-11 {
    left: 91.66666667%;
  }

  .col-ml-push-10 {
    left: 83.33333333%;
  }

  .col-ml-push-9 {
    left: 75%;
  }

  .col-ml-push-8 {
    left: 66.66666667%;
  }

  .col-ml-push-7 {
    left: 58.33333333%;
  }

  .col-ml-push-6 {
    left: 50%;
  }

  .col-ml-push-5 {
    left: 41.66666667%;
  }

  .col-ml-push-4 {
    left: 33.33333333%;
  }

  .col-ml-push-3 {
    left: 25%;
  }

  .col-ml-push-2 {
    left: 16.66666667%;
  }

  .col-ml-push-1 {
    left: 8.33333333%;
  }

  .col-ml-push-0 {
    left: auto;
  }

  .col-ml-offset-12 {
    margin-left: 100%;
  }

  .col-ml-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-ml-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-ml-offset-9 {
    margin-left: 75%;
  }

  .col-ml-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-ml-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-ml-offset-6 {
    margin-left: 50%;
  }

  .col-ml-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-ml-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-ml-offset-3 {
    margin-left: 25%;
  }

  .col-ml-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-ml-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-ml-offset-0 {
    margin-left: 0;
  }

  .visible-ml {
    display: block !important;
  }

  table.visible-ml {
    display: table;
  }

  tr.visible-ml {
    display: table-row !important;
  }

  td.visible-ml,
  th.visible-ml {
    display: table-cell !important;
  }

  .visible-ml-block {
    display: block !important;
  }

  .visible-ml-inline {
    display: inline !important;
  }

  .visible-ml-inline-block {
    display: inline-block !important;
  }

  .hidden-ml {
    display: none !important;
  }

  .visible-lg,
  .visible-lg-block,
  .visible-lg-inline,
  .visible-lg-inline-block {
    display: none !important;
  }
}
