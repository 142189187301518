.search-selfcare {
  &.search-container>.row>div .search-header>a.search-close {
    right: 11px;
  }
  &.search-container>.row>div .search-header>a.search-close {
    z-index: 0;
  }
  #search-form {
    input[name=search] {
      font-size: 1.625rem;
      font-weight: normal;
    }
    input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: @cold-gray;
    }
    input::-moz-placeholder { /* Firefox 19+ */
      color: @cold-gray;
    }
    input:-ms-input-placeholder { /* IE 10+ */
      color: @cold-gray;
    }
    input:-moz-placeholder { /* Firefox 18- */
      color: @cold-gray;
    }
    svg {
      height: 32px;	
      width: 32px;
      margin-top: 20px;
      margin-right: 18px;
    }
  }
  &.search-container>.row>div .search-header>a svg {
    width: 21px;
    height: 21px;
  }
  [type="reset"] {
    display: none !important;
  }
  .search-header {
    border-bottom: 1px solid @lighten-gray;
  }
}
.search-autocomplete { 
  &.ui-widget.ui-widget-content{
    border:none;
  }
  width: 100%;
  left: 0 !important;
  top: 144px !important;
  padding-left: 8.3333%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  position: absolute;
  z-index: 9999 !important;
  border-top: 1px solid @gray-border;
  .ui-menu-item {
    margin-bottom: .6rem;
    color: @almost-black;
    font-size: 1rem;
    line-height: 1.25rem;
    .ui-state-active{
      color: @main-green;
      background-color: transparent;
      border-color: transparent;
    }
  }
  &.ui-menu .ui-menu-item:focus, &.ui-menu .ui-menu-item:hover {
    color: @main-green;
    background-color: transparent;
  }
}

.selfcare-search-results {
  &.search-results {
    background-color: @white;
  }
  .swiper-container {
    overflow: visible;
  }
  .swiper-slide {
    width: 180px !important;
    height: 114px !important;
  }
  &.search-results .search-results-items {
    padding: 0;
    padding-top: 2.5rem;
  }
  &.search-results .tiles-menu {
    overflow: hidden;
  }
  &.search-results .tiles-menu .row .swiper-wrapper .menu-tile {
    margin-bottom: 0;
  }
  &.search-results .tiles-menu .row {
    padding: 1.6rem 0;
  }
  &.search-results .tiles-menu .row .swiper-wrapper .menu-tile a {
    font-weight: normal;
    z-index: 2;
  }
  .swiper-container .swiper-slide div.cover {
    &:after {
      content: none;
    }
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(17, 17 ,17 ,0.4);
      z-index: 1;
    }
  }
  &.search-results .search-results-items .search-results-item {
    padding-left: 0;
    border-bottom: 1px solid @gray-border;
    padding-top: 0;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    padding-right: 0;
    > div {
      display: inline-block;
      width: 100%;
      padding-right: 2rem;
      a {
        font-family: @headings-font-family;
      }
      p {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
    img {
      width: 80px;
      height: 80px;
      display: inline-block;
      vertical-align: top;
      margin-right: 1rem;
      object-fit: cover;
      + div {
        width: ~"calc(100% - 100px - 1rem)";
      }
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .history-panel {
    background-color: @sand-white-50;
    padding: 1rem 2rem;
    margin-bottom: 2rem;
    .key-list {
      li {
        padding-left: 1.5rem;
        position: relative;
        &:before {
          content: '';
          display: block;
          position: absolute;
          background-image: url('../img/search/search.svg');
          background-repeat: no-repeat;
          background-color: transparent;
          background-size: 100%;
          width: 16px;
          height: 16px;
          left: 0;
          top: 4px;
        }
      }
    }
    h4 {
      color: @steel-grey;
      text-transform: uppercase;
      font-size: 1rem;
    }
    ul {
      padding-left: 0;
    }
    li {
      list-style: none;
      margin-bottom: .5rem;
    }
    hr {
      border-color: @light-sand-brown;
    }
    a {
      color: @almost-black;
      font-size: 1rem;
      font-family: @headings-font-family;
      line-height: 1.25rem;
      &:hover {
        color: @main-green;
      }
    }
  }
  .top-results {
    background-color: @white;
    .top-result-found{
      color: @gray-fill;
    }
    .top-result-container {
      display: flex;
      justify-content: space-between;
    }
    .top-result-item {
      width: 30%;
      border: 1px solid @gray-border;
      min-height: 240px;
      .top-result-item-img{
        height: 140px;
        overflow: hidden;
        img{
          object-fit: cover;
        }
      }
    }
    img {
      width: 100%;
    }
    h3, h4 {
      color: @almost-black;
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    h4 {
      cursor: pointer;
      &:hover {
        color: @main-green;
      }
    }
    .desc {
      padding: .5rem 1rem;
    }
  }
  .pagination {
    padding-left: 0;
    margin-top: -1rem;
    overflow: hidden;
    display: inline-flex;
    width: 480px;
    height: 48px;
    flex-wrap: wrap;
    flex-basis: 48px;
    vertical-align: top;
    li {
      width: 48px;
      height: 48px;
      list-style: none;
      &:hover {
        background-color: #F5F4F5;
      }
    }
    a {
      color: @steel-grey;
      text-align: center;
      display: inline-block;
      width: 100%;
      padding: 10px 0;
      font-family: @headings-font-family;
    }
    .active {
      a {
        color: @main-green;
      }
    }
  }
  .page-nav-wrap {
    padding: 1.5rem 0;
    .btn {
      margin: 1.5rem 0;
      border-width: 1px;
    }
    .page-nav-menu-row {
      display: block;
    }
  }
  .page-nav-main-label {
    position: relative;
    color: @almost-black;
    > div {
      display: inline-block;
      font-size: 0.875rem;
    }
    > span {
      cursor: pointer;
      font-family: @font-family-base;
    }
    .expand-arrow {
      display: inline-block;
      width: 11px;
      height: 7px;
      position: relative;
      right: 0;
      margin-bottom: 2px;
      background-image: url('../img/search/expand_arrow.svg');
      background-repeat: no-repeat;
      cursor: pointer;
      margin-left: 0.5rem;
      transition: transform .5s;
    }
    .separator {
      position: relative;
      display: inline-block;
      top: 15px;
      width: 1px;
      height: 35px;
      background-color: @texts-gray;
      margin: 0 1.1rem;      
    }
    &.expanded {
      .expand-arrow {
        transform: rotate(180deg);
      }
      + .filters-selected {
        display: none;
      }
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .search-results-filter-action{
        position: relative;
        top: -32px;
      }
      .search-results-sort-action{
        .sort-by{
          position: relative;
          top: -32px;
        }
      }
    }
    
  }
  .search-filters {
    box-shadow: 0 5px 10px #ddd;
    border-bottom: 1px solid #cdcdcd;
    p {
      margin-top: 1rem;
      margin-bottom: 1.6875rem;
      font-size: 1.125rem;
    }
  }
  .filter-item {
    input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
      width: 1.35rem;
      height: 1.1rem;
      cursor: pointer;
    }
    div {
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      background-image: url('../img/search/unchecked.svg');
      background-repeat: no-repeat;
      top: 0;
      left: 0;
      cursor: pointer;
    }
    input:checked + label + div {
      background-image: url('../img/search/checked.svg');
    }
    label {
      position: relative;
      display: inline-block;
      font-weight: normal;
      color: @gray-fill;
      font-size: 1rem;
      margin-bottom: 1.6875rem;
      margin-left: 2rem;
      cursor: pointer;
      span{
        font-size: 0.875rem;
        color: @lighter-gray;
        display: block;
      } 
    }
  }
  .filters-selected {
    a, p, .search-filter-items {
      display: inline-block;
    }
    p {
      margin-right: 1rem;
      color: @steel-grey;
    }
  }
  .consumer-register-form {
    display: inline-block;
    min-width: 300px;
    height: 120px;
    margin-top: -60px;
    margin-left: 1rem;
  }
  .filter-item-block {
    background: @main-green;
    padding: 0.3125rem 1.0625rem;
    border-radius: 2rem;
    font-size: 0.875rem;
    color: @white;
    font-family: @headings-font-family;
    margin-right: 1rem;
    margin-bottom: 0.875rem;
    display: inline-block;
    svg {
      stroke: @white;
      width: 0.759375rem;
      height: 0.759375rem;
      margin-left: 0.625rem;
    }
  }
  .select2-selection__rendered,
  .select2-results__option {
    font-size: 1rem !important;
    text-transform: none !important;
    letter-spacing: 0.5px;
  }
  .select2-results__options {
    max-height: 18rem !important;
  }
  .select2-results li:first-child {
    border-bottom: 1px solid @lighten-gray !important;
  }
  .clear-all-filters {
    font-family: @font-family-base;
    display: inline;
    margin-left: 20px;
    cursor: pointer;
  }
  .consumer-register-form {
    height: 86px;
    margin-top: 0;
  }
  .consumer-register-form .form.modified-form .form-section .floating-label-select .select2-container {
    padding-top: 0;
  }
  .consumer-register-form .form.modified-form .form-section .floating-label-select .select2-container .select2-selection__arrow,
  .consumer-register-form .form.modified-form .form-section .floating-label-select .select2-container.select2-container--default.select2-container--open .select2-selection__arrow {
    top: 1.56875rem;
  }
  .page-nav-wrap .page-nav-main-label,
  .consumer-register-form .form {
    padding-bottom: 0;
    padding-top: 0;
  }
  .expand-collapse {
    cursor: pointer;
    margin-left: 7px;
    font-family: lato;
    &.collapsed{
      display: none;
    }
  }
  .page-nav-main-label{
    &.expanded{
      .expand-collapse {
        display: none;
        &.collapsed{
          display: inline-block;
        }
      }
    }
  }
  .arw-left, .arw-right {
    display: inline-flex;
    width: 48px;
    height: 48px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 69%;
    background-position: 50%;
    margin-bottom: 2rem;
    margin-top: -1rem;
    vertical-align: top;
  }
  .arw-left {
    background-image: url('../img/search/prev.svg');
  }
  .arw-right {
    background-image: url('../img/search/next.svg');
  }
}

.search-error {
  background-color: @white;
  height: 100vh;
  h4, p {
    text-align: center;
  }
  h4 {
    font-size: 1.25rem;
  }
  p {
    margin-bottom: 3rem;
    color: @gray-fill;
  }
  .swiper-container {
    height: 130px;
  }
  .error {
    color: @pink-dark;
  }
  img {
    width: 120%;
  }
  .suggestion-item {
    position: relative;
    padding: 1rem;
    height: 130px;
    overflow: hidden;
    cursor: pointer;
    h4 {
      position: absolute;
      margin: auto;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      color: white;
      width: 80%;
      font-size: 1rem;
    }
  }
}


// RESPONSIVE

@media @desktop-large {
  .uppercase { 
    text-transform: uppercase;
  }
}

@media @desktop-small {
  .search-selfcare {
    &.search-container {
      >.row>div form .form-section .form-group {
        margin-top: 1.75rem;
        margin-bottom: 1.75rem;
      }
      #search-form svg {
        margin-top: 5px;
      }
    }
  }
  .search-autocomplete {
    top: 96px !important;
  }
  .uppercase { 
    text-transform: uppercase;
  }
}

@media @tablet {
  .search-selfcare {
    #search-form svg{
      margin-top: 5px;
    }
    padding-left: 13.3333%;
    &.search-container>.row>div form .form-section .form-group {
      margin-top: 1.75rem;
      margin-bottom: 1.75rem;
    }
  }
  .selfcare-search-results .history-panel section {
    padding: .5rem 1.5rem;
    &:last-child {
      border-left: 1px solid @light-sand-brown;
    }
  }
  .selfcare-search-results .history-panel {
    padding: 0;
  }
  .search-error {
    padding-top: 3rem;
    .suggestion-item {
      padding: .75rem;
      height: 110px;
      h4 {
        font-size: .875rem;
      }
    }
  }
}

@media screen and (max-width: @screen-sm-max) {
  .search-selfcare {
    #search-form input[name=search] {
      font-size: 1.25rem;
    }
    #search-form svg {
      width: 24px;
      height: 24px;
      margin-top: 10px;
    }
  }
  .search-autocomplete {
    top: 96px !important;
  }
  .selfcare-search-results .pagination {
    margin-bottom: 2rem;
  }
}

@media @mobile {
  .search-selfcare {
    &.search-container>.row>div form .form-section .form-group {
      margin-top: 1.1rem;
      margin-bottom: 1.1rem;
    }
    #search-form svg {
      margin-top: 0;
    }
    &.search-container>.row>div .search-header>a svg {
      width: 16px;
    }
    #search-form input[name=search] {
      font-size: 1.125rem;
    }
  }
  .selfcare-search-results {
    .pagination {
      width: 192px;
    }
    .top-result-container {
      flex-wrap: wrap;
    }
    .top-results .top-result-item {
      width: 100%;
      margin-bottom: 1rem;
    }
    .page-nav-wrap .btn {
      width: 100%;
    }
    .pagination {
      text-align: center;
    }
    .consumer-register-form {
      min-width: 200px;
    }
    .search-filters {
      margin-top: -2rem;
    }
    .clear-all-filters {
      display: block;
      margin-left: 0 !important;
      margin-top: 20px;
    }
    .search-filters {
      p {
        margin-top: 4rem;
      }
    }
  }
  .search-autocomplete {
    top: 60px !important;
  }
  .page-nav-main-label {
    display: flex;
    flex-direction: column-reverse;
  }
  .search-error {
    .suggestion-item {
      padding: .5rem;
      height: 84px;
      h4 {
        font-size: .875rem;
      }
    }
  }
}
@media screen and (max-width: 374px) {
  .selfcare-search-results .consumer-register-form{
    margin-left: 0px;
  }
}

/* -----------------------------------------
05 - Arabic styles
----------------------------------------- */
*[lang="ar"] {
  *[dir="rtl"] {
    .modified-form-R0 .form.modified-form .form-section .floating-label-select .select2-container .select2-selection__arrow{
      right: auto;
      left: .9995rem;
    }
    .ui-menu .ui-menu-item .ui-menu-item-wrapper{
      strong{
        font-weight: 600;
      }
    }
    .search-selfcare {

      #search-form {
        svg {
          margin-right: 0px;
          margin-left: 18px;
        }
      }
    }
    .search-autocomplete{
      padding-left: 0px;
      padding-right: 8.3333%;
    }
    .selfcare-search-results {
      .filters-selected {
        p{
         margin-right: 0;
         margin-left: 1rem; 
       }
     }
     .filter-item {
      div{
        left: auto;
        right: 0;
      }
      label{
        margin-left: 0;
        margin-right: 2rem;
      }
      input{
        left: auto;
        right: 0;
      }
    }
    .filter-item-block svg{
      margin-left: 0;
      margin-right: .625rem;
    }
    .consumer-register-form{
      margin-left: 0;
      margin-right: 1rem;
    }
    .expand-collapse{
      display: inline-block;
      margin-right: 0.2rem;
      margin-left: 0px;
      &.collapsed {
        display: none;
      }
    }
    .page-nav-main-label {
      &.expanded{
        .expand-collapse{
          display: none;
          &.collapsed {
            display: inline-block;
          }
        }
      }
      .expand-arrow{
        margin-left: 0;
        margin-right: .5rem;
      }
    }
  }
  .selfcare-search-results .history-panel ul{
    padding-right: 0px;
  }
  .selfcare-search-results .history-panel .key-list li{
    padding-left: 0;
    padding-right: 1.5rem;
    }
  .selfcare-search-results .history-panel .key-list li:before{
    left: auto;
    right: 0px;
  }
}
}