.checkout-header{
    .row{
        display: flex;
        flex-direction: column-reverse;
        >div{
            margin-bottom: 1.25rem;
            &:nth-child(1){
                text-align: center;
                //display: flex;
                //justify-content: center;
                a{
                    margin: 0;
                    padding: 0;
                    color: @dark-gray;
                    //border-bottom: solid 1px @dark-gray;
                    text-decoration: underline;
                }
            }
            &:nth-child(2){
                text-align: center;
                .etisalat-logo-img{
                    width: 4.75rem;
                    height: 1.25rem;
                    margin: 0;
                    margin-top: 1.375rem;
                    margin-bottom: 0.75rem;
                }
                h4{
                    margin: 0;
                    padding: 0;
                }
            }
            &:nth-child(3){
                display: none;
            }
        }
    }
}



@media (min-width: @screen-sm-min) {
    .checkout-header{
        .row{
            flex-direction: row;
            align-items: flex-end;
            >div{
                &:nth-child(1){
                    margin-right: 0;
                    text-align: left;
                    //justify-content: flex-start;
                }
                &:nth-child(3){
                    display: block;
                }
            }
        }
    }
    *[lang="ar"] {
    *[dir="rtl"] {
        .checkout-header{
            .row{
                >div{
                    &:nth-child(1){
                        margin-left: 0;
                        margin-right: auto;
                        text-align: right;
                        //justify-content: flex-end;
                    }
                }
            }
        }
    }
    }
}
.sticky-HO-header {
  position: fixed;
  top: 0px;
  width: 100%;
  background: @white;
  z-index: 99;
  max-height: 256px;
}
.sticky-HO-header + div {
  margin-top: 250px;
  &.outBox {
    margin-top: 100px;
  }
}
