.breadcrumb-wrapper{
  background-color: @warm-background;
  .breadcrumb {
    list-style: none;
    text-transform: uppercase;
    margin: 0;
    padding:0.375rem 0;
    > li {
      display: inline-block;
      letter-spacing: .0875rem;
      a{
        color: @breadcrumb-color;
        &:hover{
          color: @main-green;
          text-decoration: underline;
        }
      }
      &:after {
        content: "@{breadcrumb-separator}\00a0"; // Unicode space added since inline-block means non-collapsing white-space
        padding: 0 .3125rem;
        color: @breadcrumb-color;
      }
    }
    > li:last-child:after  {
      display: none;
    }
    > .active {
      color: @breadcrumb-active-color;
      font-weight:600;
    }
  }
}

/*
 * Business Variant
 */
.business {
  .breadcrumb-wrapper{
    background-color: @dark-gray;
    .breadcrumb {
      > li {
        a{
          color: @white;
          &:hover{
            color: @white;
          }
        }
        &:after {
          color: @white;
        }
      }
      > .active {
        color: @white;
      }
    }
  }
}
