//
// Typo
// --------------------------------------------------
@font-face {
  font-family: Neotech;
  font-weight: 300;
  src: url('../fonts/neotech-light.woff') format('woff');
}

@font-face {
  font-family: Neotech;
  font-weight: 400;
  src: url('../fonts/neotech-regular.woff') format('woff');
}

@font-face {
  font-family: Neotech-medium;
  font-weight: 400;
  src: url('../fonts/neotech-regular.woff') format('woff');
}

@font-face {
  font-family: Neotech;
  font-weight: 900;
  src: url('../fonts/neotech-medium.woff') format('woff');
}
@font-face {
    font-family: 'GE SS Two';
    font-weight: 400;
    font-style: normal;
    src: url('../fonts/ge_ss_two_medium.ttf') format('truetype');
}
@font-face {
  font-family: 'GE SS Two';
    font-weight: 700;
    font-style: normal;
    src: url('../fonts/ge_ss_two_bold.ttf') format('truetype');
}
@font-face {
  font-family: 'GE SS Two';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/ge_ss_two_light.ttf') format('truetype')
}


@font-fallback-headings: Roboto, Arial, sans-serif;
@font-fallback-body: Roboto, Arial, sans-serif;
@font-arabic:  'Neotech-medium', 'GE SS Two', Arial,sans-serif;
@font-input: "Lato", Roboto, 'GE SS Two', Arial,sans-serif;

@font-family-sans-serif: "Lato", Roboto, Arial, sans-serif;
@font-family-serif: Georgia, "Times New Roman", Times, serif;
@font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
@font-family-base: @font-family-sans-serif;
@font-family-menu-sans-serif: Neotech, Roboto, Arial, sans-serif;

@font-size-base: 16px; // custom Etisalat
@font-size-arabic-base: 1.5rem; // custom Etisalat Arabic
@font-size-large: ceil((@font-size-base * 1.00)); // ~16px    // custom Etisalat
@font-size-small: ceil((@font-size-base * 0.875)); // ~14px   // custom Etisalat

@line-height-base: 1.6;
@line-height-computed: floor((@font-size-base * @line-height-base));

@headings-font-family: Neotech, Roboto, Arial, sans-serif;
@headings-font-weight: normal;
@headings-line-height: 1.3;
@headings-color: inherit;

*{
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//
// menu
// --------------------------------------------------

@font-size-top-nav: 0.6875rem; //~11px
@kerning-top-nav: 0.1818181818em; //~2
@font-size-main-menu: 1rem; //~14px
@kerning-main-menu: 0.2142857143em; //~3

//
// body
// --------------------------------------------------

@body-big-xs: 1rem; //16px
@body-standard-xs: 0.875rem; //14px
@body-small-xs: 0.75rem; //12px
@body-smallest-xs: 0.75rem; //12px

@body-big-ms: 1rem; //16px
@body-standard-ms: 0.875rem; //14px
@body-small-ms: 0.75rem; //12px
@body-smallest-ms: 0.75rem; //12px

@body-big-sm: 1rem; //20px
@body-standard-sm: 1rem; //16px
@body-small-sm: 0.875rem; //14px
@body-smallest-sm: 0.75rem; //12px

@body-big-md: 1rem; //16px
@body-standard-md: 0.875rem; //14px
@body-small-md: 0.75rem; //12px
@body-smallest-md: 0.75rem; //12px

@body-big-lg: 1.25rem; //20px
@body-standard-lg: 1rem; //16px
@body-small-lg: 0.875rem; //14px
@body-smallest-lg: 0.75rem; //12px

@body-big-xl: 1.25rem; //20px
@body-standard-xl: 1rem; //16px
@body-small-xl: 0.875rem; //14px
@body-smallest-xl: 0.75rem; //12px

//
// headings
// --------------------------------------------------

@font-size-h1-xs: 2.1875rem; // ~35px
@kerning-h1-xs: 0.0857em; // 3
@font-size-h2-xs: 1.625rem; // ~26px
@kerning-h2-xs: 0.1538em; // 4
@font-size-h3-xs: 1.5rem; // ~24px
@kerning-h3-xs: 0.08333em; // 2
@font-size-h4-xs: 1.25rem; // ~20px
@kerning-h4-xs: 0.1em; // 2
@font-size-h5-xs: 1rem; // ~16px
@kerning-h5-xs: 0.0625em; // 1

@font-size-h1-ms: 2.1875rem; // ~35px
@kerning-h1-ms: 0.0857em; // 3
@font-size-h2-ms: 1.625rem; // ~26px
@kerning-h2-ms: 0.1538em; // 4
@font-size-h3-ms: 1.5rem; // ~24px
@kerning-h3-ms: 0.08333em; // 2
@font-size-h4-ms: 1.25rem; // ~20px
@kerning-h4-ms: 0.1em; // 2
@font-size-h5-ms: 1rem; // ~16px
@kerning-h5-ms: 0.0625em; // 1

@font-size-h1-sm: 3.4375rem; // ~55px
@kerning-h1-sm: 0.0909em; // 5
@font-size-h2-sm: 2.5rem; // ~40px
@kerning-h2-sm: 0.175em; // 7
@font-size-h3-sm: 1.625rem; // ~26px
@kerning-h3-sm: 0.1538em; // 4
@font-size-h4-sm: 1.25rem; // ~20px
@kerning-h4-sm: 0.1em; // 2
@font-size-h5-sm: 1rem; // ~16px
@kerning-h5-sm: 0.0625em; // 1

@font-size-h1-md: 3.4375rem; // ~55px
@kerning-h1-md: 0.0909em; // 5
@font-size-h2-md: 2.5rem; // ~40px
@kerning-h2-md: 0.175em; // 7
@font-size-h3-md: 1.625rem; // ~26px
@kerning-h3-md: 0.1538em; // 4
@font-size-h4-md: 1.25rem; // ~20px
@kerning-h4-md: 0.1em; // 2
@font-size-h5-md: 1rem; // ~16px
@kerning-h5-md: 0.0625em; // 1

@font-size-h1-lg: 5rem; // ~80px
@kerning-h1-lg: 0.0625em; // 5
@font-size-h2-lg: 3.375rem; // ~54px
@kerning-h2-lg: 0.1481em; // 8
@font-size-h3-lg: 1.875rem; // ~30px
@kerning-h3-lg: 0.1666em; // 5
@font-size-h4-lg: 1.5rem; // ~24px
@kerning-h4-lg: 0.0833em; // 2
@font-size-h5-lg: 1.25rem; // ~20px
@kerning-h5-lg: 0.1em; // 2

@font-size-h1-xl: 5rem; // ~80px
@kerning-h1-xl: 0.0625em; // 5
@font-size-h2-xl: 3.375rem; // ~54px
@kerning-h2-xl: 0.1481em; // 8
@font-size-h3-xl: 1.875rem; // ~30px
@kerning-h3-xl: 0.1666em; // 5
@font-size-h4-xl: 1.5rem; // ~24px
@kerning-h4-xl: 0.0833em; // 2
@font-size-h5-xl: 1.25rem; // ~20px
@kerning-h5-xl: 0.1em; // 2

@font-size-h1: @font-size-h1-xs;
@font-size-h2: @font-size-h2-xs;
@font-size-h3: @font-size-h3-xs;
@font-size-h4: @font-size-h4-xs;
@font-size-h5: @font-size-h5-xs;
@font-size-h6: @font-size-h5-xs;

//
// buttons
// --------------------------------------------------

@buttons-typo-desktop: 0.875rem; // ~14px
@buttons-height-desktop: 4.25rem; // ~68px (64px + 4 border)
@buttons-line-height-desktop: 4rem; // ~64px
@buttons-kerning-desktop: 0.1428571429em; // 2

@buttons-typo-tablet-and-below: 0.75rem; // ~12px
@buttons-height-tablet-and-below: 3rem; // ~48px (44px + 4 border)
@buttons-line-height-tablet-and-below: 2.75rem; // ~44px
@buttons-kerning-tablet-and-below: 0.1428571429em; // 2



// remove the click delay on mobile brosers
// see: https://gist.github.com/gajus/bbf06ea2e37047b01e70
body {
  touch-action: manipulation;
}
