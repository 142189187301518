/*
============================================
01 - context nav
02 - ms viewport
03 - ms viewport
04 - md viewport
05 - lg viewport
06 - xl viewport
07 - Business Variant

============================================

/* -----------------------------------------
01 - context nav
----------------------------------------- */
.row-context-nav {
  position: relative;
  > .container-fluid {
    background-color: @sand-white;
  }

  .context-nav-swiper-button-next,
  .context-nav-swiper-button-prev {
    display: none;
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 50%;
    background-color: @sandy-white;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    svg {
      width: 26px;
      height: 10px;
    }
  }
  .context-nav-swiper-button-next {
    right: 0;
    svg {
      transform: rotate(180deg);
    }
  }
  .context-nav-swiper-button-prev {
    left: 0;
  }
  @media (max-width: 767px) {
    padding: 0rem 1rem;
    background-color: @sand-white;
    .context-nav-box-wrap{
      padding-bottom: 0px;
      overflow: unset;
      .swiper-wrapper{
        display: block;
        transform: none !important;
        transition-property: none !important;
        -webkit-overflow-scrolling: touch !important;
        transition-duration: unset !important;
        .rect-responsive{
          padding-bottom: 25%;
        }
        .swiper-slide{
            width: 100%;
            -webkit-overflow-scrolling: touch !important;
            margin-bottom: 1rem;
            .context-nav-box{
              &.conent{
                &.tile-anchor{
                  display: flex !important;
                  flex-direction: column !important;
                  justify-content: center !important;
                }
              }
              a{
                display: flex;
                align-items:center;
                text-align: left;
                width: 100%;
                padding: 0px;
                flex-direction: row;
                .icon-area{
                  text-align: center;
                  margin-left: 1rem;
                  margin-right: 1rem;
                  width: 4rem;

                }
                h5{
                      height: auto;
                      padding-top: 0;
                      margin-top: 0;
                      margin-bottom: 0px;
                      width: 100%;
                      text-align: left;
                  &::after{
                    display: none;
                  }
                }
              }
            }
        }
      }
    }
  }
}
.context-nav-box-wrap {
  text-align: center;
  padding: 0 0 2rem;
  overflow: hidden;
  .swiper-wrapper {
    -webkit-filter: blur(0);
    .rect-responsive {
      padding-bottom: 65%;
    }
    .swiper-slide {
      width: 66.66666667%;
    }
  }
  .context-nav-box {
    background-color: @white;
    .transition(0.2s);
    &.content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &:after {
      content: " ";
      background-color: @main-green;
      width: 100%;
      height: 6.57%;
      position: absolute;
      left: 0;
      bottom: 0;
    }
    a h5 {
      color: @almost-black;
    }
    h5 {
      position: relative;
      padding-top: 1em;
      margin-top: 0.5rem;
    }
    h5:after {
      content: " ";
      width: 2.5rem;
      height: 1px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      background-color: @main-green;
    }
    &.hover,
    &:hover {
      cursor: pointer;
      background-color: @main-green;
      a h5 {
        color: @white;
      }
      h5:after {
        background-color: @white;
      }
      .icon-area {
        img,
        object,
        svg {
          .main-stroke {
            stroke: @white;
          }
        }
      }
    }
    h5 {
      height: 3.75rem;
      padding-left: 5%;
      padding-right: 5%;
    }
    .icon-area {
      img,
      object,
      svg {
        height: 3.375rem;
        width: auto;
        .main-stroke {
          stroke: @almost-black;
        }
      }
    }
  }
  .swiper-pagination-bullets {
    display: block;
    height: 32px;
    .swiper-pagination-bullet {
      margin: 29px 5px 0;
      height: 3px;
      width: 20px;
      border-radius: 0;
      &.swiper-pagination-bullet-active {
        background-color: @dark-gray;
      }
    }
  }
}
/* -----------------------------------------
02 - ms viewport
----------------------------------------- */
@media (min-width: @screen-ms-min) {
  .context-nav-box-wrap {
    .swiper-wrapper .rect-responsive {
      padding-bottom: 65%;
    }
    .context-nav-box {
      .icon-area {
        svg {
          height: 3.375rem;
        }
      }
    }
  }
  .swiper-with-2-slides {
    .context-nav-box-wrap {
      .rect-responsive {
        padding-bottom: 44%;
      }
    }
  }
}
/* -----------------------------------------
03 - ms viewport
----------------------------------------- */
@media (min-width: @screen-sm-min) {
  .context-nav-box-wrap {
    padding: 0 0 2.25rem;
    .swiper-pagination-bullets {
      display: none;
      height: 50px;
      .swiper-pagination-bullet {
        margin: 47px 5px 0;
        width: 40px;
      }
    }
    .context-nav-box {
      &.content {}
      .icon-area {
        svg {
          height: 2.4rem;
        }
      }
    }
    .swiper-wrapper .rect-responsive {
      padding-bottom: 100%;
    }
  }
  .swiper-with-2-slides {

    .context-nav-box-wrap {
      .rect-responsive {
        padding-bottom: 44%;
      }
    }
    & .subTitleContextNav {
      & .rect-responsive {
        padding-bottom: 64%;
      }
    }
  }
  .more-than-three {
    .swiper-pagination-bullets {
      display: block;
    }
    .context-nav-swiper-button-next,
    .context-nav-swiper-button-prev {
      display: flex;
    }
  }
}
/* -----------------------------------------
04 - md viewport
----------------------------------------- */
@media (min-width: @screen-md-min) {
  .context-nav-box-wrap {
    .swiper-wrapper .rect-responsive {
      padding-bottom: 65%;
    }
    .context-nav-box {
      .icon-area {
        svg {
          height: 3.375rem;
        }
      }
    }
  }
  .swiper-with-2-slides {
    .context-nav-box-wrap {
      .rect-responsive {
        padding-bottom: 44%;
      }
    }
  }
}
/* -----------------------------------------
05 - lg viewport
----------------------------------------- */
@media (min-width: @screen-lg-min) {
  .context-nav-box-wrap {
    padding: 0 0 3.125rem;
    .context-nav-box h5 {
      height: 4.5rem;
    }
    & .subTitleContextNav{
      & .swiper-wrapper {
        & .rect-responsive {
            padding-bottom: 70%;

          }
      }
    }
  }
  .more-than-three {
    .context-nav-swiper-button-next,
    .context-nav-swiper-button-prev {
      height: 70px;
      width: 70px;
      margin-top: -20px;
      svg {
        width: 37px;
        height: 15px;
      }
    }
  }
  .swiper-with-2-slides {
    .context-nav-box-wrap {
      .rect-responsive {
        padding-bottom: 44%;
      }
    }
  }
}
@media (max-width: @screen-ms-max) {
  & .subTitleContextNav{
    & .swiper-wrapper {
      & .rect-responsive {
          padding-bottom: 85%;

        }
    }
  }
}

/* -----------------------------------------
06 - xl viewport
----------------------------------------- */
@media (min-width: @screen-xl-min) {
  .context-nav-box-wrap {
    .swiper-wrapper .rect-responsive {
      padding-bottom: 65%;
    }
    .context-nav-box {
      .icon-area {
        svg {
          height: 3.375rem;
        }
      }
    }
  }
  .swiper-with-2-slides {
    .context-nav-box-wrap {
      .rect-responsive {
        padding-bottom: 44%;
      }
    }
  }
}
/* -----------------------------------------
07 - Business Variant
----------------------------------------- */
.business {
  .row-context-nav > .container-fluid {
    background-color: @moonlight-sand;
    .context-nav-box {
      background-color: @dark-gray;
      .icon-area {
        img,
        object,
        svg {
          .main-stroke {
            stroke: @white;
          }
        }
      }
      a h5 {
        color: @white;
      }
      h5:after {
        //background-color: @dark-sand-brown;
        background-color: @dark-green;
      }
      &:after {
        //background-color: @dark-sand-brown;
        background-color: @dark-green;
      }
      &.hover,
      &:hover {
        cursor: pointer;
        background-color: @dark-green;
        a h5 {
          color: @white;
        }
        h5:after {
          background-color: @white;
        }
      }
    }
    & .subTitleContextNav{
      & .swiper-wrapper {
        & .rect-responsive {
            /*  padding-bottom: 85%;*/
              & .context-nav-box {
                & h5 {
                  height: 2.75rem;
                }
                & h6 {
                  color: @white;
                  font-family: Lato,Roboto,Arial,sans-serif;
                  font-size: 1rem;
                }
              }
          }
      }
    }
  }
}

/*
 * Arabic
 */
 *[lang="ar"] {
  *[dir="rtl"] {
    .row-context-nav{
        @media (max-width: 767px) {
        .context-nav-box-wrap{
          .swiper-wrapper{
            .swiper-slide{
                .context-nav-box{
                  a{
                    text-align: right;
                    .icon-area{
                      margin-right: 1rem;
                      margin-left: 0px;
                    }
                    h5{
                        text-align: right;
                    }
                  }
                }
            }
          }
        }
      }
    }
  }
  }



