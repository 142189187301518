#store-locator-map {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: auto;
  transition: height 0.3s ease-in-out;
  // overwrite google style for infowindow
  .gm-style-iw {
    top: 0 !important;
    left: 0 !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
    margin-top: 1rem;
    background: white;
  }

  .error {
    display: block;
    position: relative;
    width: 70%;
    margin: 2rem auto;
    padding: 2rem;
    background-color: @white;
    font-family: @headings-font-family;
    text-align: center;
    color: @main-green;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
  /*/
  / custom infowindow .more-details-box {
    background: @white;
    overflow: hidden;
    padding-left: 2rem;
    padding-top: 2rem;

    .heading {
      width: 100%;
      height: 158px;
    }

    .box-content {
      padding: 1.125rem 2rem 1.625rem 0;

      h3 {
        margin: 0;
        font-size: 1.43rem;
        text-align: left;
        color: @main-green;
      }

      h4 {
        margin: 0;
        font-size: 1rem;
        line-height: 1.6rem;
        text-align: left;
        color: @main-green;
        text-transform: uppercase;
        border-bottom: 1px solid #979797;
        margin-bottom: 14px;
      }

      p.address {
        color: @steel-grey;
      }

      p.description {
        display: block;
        max-height: 75px;
        margin: 25px 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p.phone {
        img {
          height: 20px;
          width: 20px;
          margin-right: 1rem;
        }
      }

      p.actions {
        text-align: center;
      }

      p.actions {
        > button {
          font-size: 12px;
          line-height: 1rem;
          padding: 1rem;
          height: 3rem;
        }
      }
    }
  }

  */ .more-details-box {
    background: @white;
    top: 5%;
    left: 48%;
    overflow: hidden;

    .close-btn {
      fill: white;
      position: absolute;
      top: 10px;
      z-index: 99999900;
      right: 10px;
      height: 28px;
      width: 28px;
    }

    .box-section {
      .dynamic-value {
        color: @steel-grey;
      }

      p {
        letter-spacing: 0.6px;
      }

      .header-name {
        margin-bottom: 1.5rem;
        color: @main-green;
        letter-spacing: 0;

        h4 {
          font-size: 1.375rem;
          margin: 0;
        }

        h5 {
          font-size: 1rem;
          margin: 0;
        }
      }

      .box-section-body {
        p {
          margin-bottom: 1.375rem;
        }

        ul {
          li {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
          }
        }
      }

      .box-section-footer {
        .contact-info-no {
          svg {
            height: 20px;
            width: 20px;
            float: left;
            margin-right: 6%;
          }
        }

        .btn-footer-container {
          text-align: center;
          margin: 1.625rem auto;
        }
      }

      .border-bottom {
        border-bottom: 1px solid #d8d8d8;
        padding-bottom: 10px;
        margin-bottom: 1.375rem;
      }

      button {}
    }
  }
}
// Customize the border color for search select2

#store-locator-form {
  .select2-selection__rendered {
    > div {
      align-items: center;
      display: flex;
    }
  }

  .category-dropdown {
    .select2-results__option--highlighted,
    .select2-results__option[aria-selected=true] {
      overflow: visible;
      position: relative;

      > div {
        &:before {
          content: ' ';
          position: absolute;
          top: 0;
          left: -4px;
          height: 100%;
          border-left: 4px solid @main-green;
          padding-left: 1rem;
        }

        &.store-basket:before {
          border-color: @orange;
        }

        &.store-bag:before {
          border-color: @main-green;
        }

        &.store-payment:before {
          border-color: @yellow;
        }

        &.store-wifi:before {
          border-color: @pink;
        }

        &.store-business:before {
          border-color: @dark-green;
        }
      }
    }

    .select2-results__option {
      svg {
        vertical-align: top;
      }
    }
  }
}
@media (min-width: @screen-md-min) {
  #store-locator-form {
    .form.custom-dd .category-dropdown .select2-container.select2-container--open {
      .select2-results__option--highlighted,
      .select2-results__option[aria-selected=true] {
        > div {
          padding-left: 2rem;
        }
      }
    }
  }
}
@media (min-width: @screen-lg-min) {
  #store-locator-map {
    //height: 790px;
  }
}
// temp fix for select too big on load
@media (min-width: 1441px) {
  select#mapFilter {
    height: 64px;
  }
}
@media (max-width: 1440px) {
  select#mapFilter {
    height: 48px;
  }
}
// dropdown menu with input text box design
// start common style for search - check

.search-group {
  select {
    width: 100%;
  }

  .search-btn {
    background-color: rgba(132, 135, 137, 0.1);
    border: 0;
  }

  .store-locator-style {
    #pac-input {
      padding-right: 18%;
      border: 0;
    }
    display: block;

    input {
      &:active:after {
        height: 20px;
        content: "CHECK";
      }
    }

    & > span {
      display: none;
      position: absolute;
      right: 0;
      top: 0;

      a {
        color: @dark-gray;
      }

      &.clear-check-seach-btn {
        svg {
          height: 15px;
          width: 15px;
          margin-top: 1.5rem;
          fill: @texts-gray;
        }
      }
    }
  }
}
@media (min-width: @screen-xs) {
  .search-group {
    select {}

    .search-btn {}

    .store-locator-style {
      #pac-input {
        padding-right: 28%;
      }

      input {}

      span {
        .btn {
          padding: 0 1rem;
        }

        a {
          color: @dark-gray;
          line-height: 3rem;
        }

        &.clear-check-seach-btn {
          svg {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
@media (min-width: @screen-ms-min) {
  .search-group {
    select {}

    .search-btn {}

    .store-locator-style {
      #pac-input {
        padding-right: 20%;
      }

      input {}

      span {
        .btn {
          padding: 0 1rem;
        }

        a {
          color: @dark-gray;
          line-height: 3rem;
        }

        &.clear-check-seach-btn {
          svg {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
@media (min-width: @screen-sm-min) {
  .search-group {
    select {}

    .search-btn {}

    .store-locator-style {
      #pac-input {
        padding-right: 20%;
      }

      input {}

      span {
        .btn {
          padding: 0 1rem;
        }

        a {
          color: @dark-gray;
          line-height: 3rem;
        }

        &.clear-check-seach-btn {
          svg {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
@media (min-width: @screen-md-min) {
  .search-group {
    select {}

    .search-btn {}

    .store-locator-style {
      #pac-input {
        padding-right: 20%;
      }

      input {}

      span {
        .btn {
          padding: 0 1rem;
        }

        a {
          color: @dark-gray;
          line-height: 3rem;
        }

        &.clear-check-seach-btn {
          svg {
            margin-top: 1rem;
          }
        }
      }
    }
  }

  .store-locator-wrap .tiles-content .store-locator-header .search-group .search-btn {
    height: 3rem;
  }
}
@media (min-width: @screen-lg-min) {
  .search-group {
    select {}

    .search-btn {}

    .store-locator-style {
      #pac-input {
        padding-right: 18%;
      }

      input {}

      span {
        right: 0;
        top: 0;

        .btn {
          padding: 0 1.5rem;
        }

        a {
          color: @dark-gray;
          line-height: 4rem;
        }

        &.clear-check-seach-btn {
          svg {
            margin-top: 1.5rem;
          }
        }
      }
    }
  }
}
// end common style for search - check

.store-locator-wrap {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  .search-style {
    display: block;
  }

  .tiles-content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;

    &:after {
      content: " ";
      background-color: fade(@main-green, 80);
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
    }

    div.cover {
      left: 0;
      z-index: 1;
      top: 0;
    }

    .store-locator-header {
      z-index: 3;
      position: relative;
      width: 100%;

      h2 {
        color: @white;
        text-transform: uppercase;
        text-align: center;
        white-space: nowrap;
        overflow: visible;
      }

      .search-group {
        margin: 0 0 1rem;

        select {
          width: 100%;
        }

        .close-btn {
          svg {
            width: 1rem;
            height: 1rem;
          }
        }

        .search-btn {
          width: 100%;
          height: auto;

          svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }

      .my-current-location {
        display: flex;
        align-items: center;
        padding-left: 2%;
        margin-top: 1rem;

        svg {
          height: 18px;
          width: 14px;
          margin-right: 5px;
        }

        a {
          color: @white;
          letter-spacing: 1.1px;
          text-decoration: underline;
        }
      }
    }
  }

  .map-container {
    position: relative;

    .background-map {
      position: relative;
      transition: height 0.3s ease-in-out;

      &.closed {
        min-height: 150px;
      }

      &.opened {
        min-height: 340px;
      }

      .map-filter {
        position: relative;
        display: block;

        .map-filter-topnav {
          background-color: @white;
          text-transform: uppercase;
          border: none;
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .25);
          margin: 0;
          position: relative;
          display: flex;
          z-index: 2;
          // z-index: 999;
          .map-filter-list {
            &.topnav-swiper-container.swiper-container-horizontal {
              width: 100%;
            }

            .nav {
              padding-left: 0;
              width: 100%;
              //display: -webkit-box;
            }

            ul {
              float: left;
              margin: 0;
              list-style: none;

              li {
                float: left;
                letter-spacing: 1.6px;

                &.swiper-slide {
                  width: auto;
                }

                &.active {
                  border: 0;
                  border-bottom: 4px;
                  border-style: solid;
                  font-weight: 900;

                  &.green-b {
                    border-color: @main-green;
                  }

                  &.dark-gray {
                    border-color: @dark-gray;
                  }

                  &.orange-b {
                    border-color: @dark-orange;
                  }

                  &.sandy-green-b {
                    border-color: @sandy-green;
                  }

                  &.purple-b {
                    border-color: @pink;
                  }

                  &.light-green-b {
                    border-color: @light-green;
                  }

                  &.dark-green-b {
                    border-color: @dark-green;
                  }

                  &.yellow-b {
                    border-color: @yellow;
                  }
                }

                a {
                  font-family: @font-family-menu-sans-serif;
                  color: #353738;
                  text-transform: uppercase;
                }
              }
            }
          }
        }

        .result-main-container {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          align-items: stretch;
          min-height: 12rem;

          .sidebar-result-container {
            z-index: 1;
            flex: 1 2 25%;
            overflow: hidden;
            background-color: @white;

            .result-slide {
              width: 100%;

              .result-item-container {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                background-color: @white;
                flex-direction: column;
                border-bottom: 1px solid #d8d8d8;
                transition: all 0.2s ease;

                &.open-detail {
                  top: 0;
                  left: 0;
                  z-index: 15;
                  position: absolute;
                  transition: all 0.2s ease;

                  .desc-short {
                    border-left-width: 5px;
                    border-left-style: solid;
                    transition: all 0.2s ease;

                    &.store-basket {
                      border-color: @orange;
                    }

                    &.store-bag {
                      border-color: @main-green;
                    }

                    &.store-machine {
                      border-color: @yellow;
                    }

                    &.store-wifi {
                      border-color: @pink;
                    }

                    &.store-business {
                      border-color: @dark-green;
                    }
                  }
                }

                .desc-short {
                  cursor: pointer;
                  padding: 1.85rem;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  padding-right: 4%;
                  padding-left: 9%;
                  border-left: 0 solid @main-green;
                  transition: all 0.2s ease-in-out;

                  .item-icon {
                    position: relative;

                    img {
                      width: 2rem;
                      height: 2rem;
                    }
                  }

                  .toggle {
                      position:relative;
                      width:44px;

                      input[type="checkbox"] {
                          opacity:0;
                      }
                      input[type="checkbox"]:checked + label:before {
                          background:@main-green;
                          .transition();
                      }
                      input[type="checkbox"]:checked + label:after {
                          .transition();
                          left:35%;
                      }
                      label {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 50px;
                        height: 100%;

                        &:after,
                        &:before {
                          content: '';
                          display: block;
                          position:absolute;
                          left:0;
                          top:0;
                          cursor:pointer;
                          .transition();
                        }

                        &:before {
                          width: 100%;
                          height: 18px;
                          background: @sand-grey;
                          border-radius: 9px;
                        }

                        &:after {
                          width: 28px;
                          height: 28px;
                          top:-5px;
                          border-radius: 14px;
                          background:@white;
                          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
                        }
                      }
                  }

                  h4 {
                    font-size: 1.375rem;
                    letter-spacing: 0;
                    color: @almost-black;
                    margin-bottom: 5px;
                  }

                  p {
                    color: @steel-grey;
                  }

                  .item-title {
                    padding-left: 0.5rem;
                  }
                }

                .desc-long {
                  width: 100%;
                  position: absolute;
                  top: 82px;
                  left: 0;
                  background-color: @sand-white-50;
                  z-index: 14;
                  padding: 1.75rem 1.25rem;
                  display: none;

                  .long-details-container {
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    flex-direction: column;

                    .details-item {
                      display: flex;
                      flex-direction: row;
                      padding-bottom: 1.875rem;
                      align-items: flex-start;

                      .details-icon {
                        width: 10%;
                        justify-content: flex-start;
                        display: flex;
                        //padding-top: 2%;
                        svg {
                          height: 20px;
                          width: 20px;
                        }
                      }

                      .details-desc {
                        width: 90%;
                        font-size: 16px;
                        line-height: 1.6;
                        letter-spacing: 0.6px;
                        color: @almost-black;

                        .dynamic-value {
                          color: @steel-grey;
                        }

                        a {
                          color: @main-green;
                          text-decoration: underline;
                        }

                        .status {
                          color: @main-green;
                          font-weight: bold;
                          text-transform: uppercase;
                        }
                      }
                    }

                    .close-btn {
                      text-align: right;
                      font-size: 0.875rem;
                      text-transform: uppercase;

                      a {
                        color: @almost-black;

                        svg {
                          height: 11px;
                          width: 7px;
                          margin-left: 23px;
                          transform: rotate(-90deg);
                          transform-origin: center center;
                          overflow: visible;
                          fill: @texts-gray;
                        }
                      }
                    }
                  }
                }

                &.net-2g {
                    .desc-short {
                            .toggle {
                                input[type="checkbox"]:checked + label:before {
                                background:@gold-top;
                            }
                        }
                    }
                }
                &.net-3g {
                    .desc-short {
                            .toggle {
                                input[type="checkbox"]:checked + label:before {
                                background:@light-green;
                            }
                        }
                    }
                }
                &.net-4g {
                    .desc-short {
                            .toggle {
                                input[type="checkbox"]:checked + label:before {
                                background:@main-green;
                            }
                        }
                    }
                }
              }


            }

            .result-pager {
              display: flex;
              justify-content: space-between;
              align-content: center;
              padding: 1rem 2.25rem;
              box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, .25);
              box-shadow: 1px -1px 6px 0 rgba(0, 0, 0, .25);
              z-index: 9;
              position: relative;
              background: @white;

              p {
                color: @gray;
                letter-spacing: 1.2px;
                position: relative;
              }

              .pager {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 12.5px;
              }

              .pager-arrows {
                position: relative;
                height: inherit;
                width: 25%;
                color: @gray;
                letter-spacing: 1.2px;
                position: relative;
                display: flex;
                justify-content: space-around;
                align-items: center;
              }
            }
          }

          .main-map-result-container {
            position: relative;
            flex: 2 1 75%;
            overflow: hidden;
          }
        }

        .swiper-slide {
          position: static;
        }
      }
    }
  }

  .switch-store-locator {
    background-color: @white;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 0;
    margin: 0;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, .25);
    z-index: 999;
    position: relative;
    margin: 0;

    .switcher {
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      color: @dark-gray;
      border-bottom: 4px solid @white;
      align-content: center;

      a {}

      svg {
        height: 20px;
        width: 16px;
        margin-right: 1rem;
        stroke: @main-green;
      }

      &.active,
      &:active,
      &:checked,
      &:hover {
        color: @main-green;
        border-bottom-color: @main-green;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, .25);
      }
    }
  }
}
/* Arrows */
.swiper-container-map {
  .swiper-wrapper {}

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    width: 35px;
    height: 12px;
    margin-top: -4px;
    z-index: 6;
    cursor: pointer;
    background-size: 30px 20px;
    background-position: 50%;
    background-repeat: no-repeat;

    &.swiper-button-disabled {
      opacity: 0.35;
      cursor: auto;
      pointer-events: none;
    }
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    .encoded-svg-background(  "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z' fill='#353738'/></svg>");
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    .encoded-svg-background(  "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z' fill='#353738'/></svg>");
  }
  /* Pagination Styles */
  .swiper-pagination {
    position: absolute;
    text-align: center;
    -webkit-transition: 300ms;
    -moz-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 10;

    &.swiper-pagination-hidden {
      opacity: 0;
    }
  }
  /* Common Styles */
  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    color: @gray;
    letter-spacing: 1.2px;
    position: relative;
    padding: 0;
    margin: 0 10px;
    width: auto;
    padding: 0.5rem 0 0;
  }
}

.store-locator-wrap {
  .store-locator-header {
    h2 {
      font-size: 1.625rem;
      letter-spacing: 2.2px;
      margin-bottom: 0;
    }

    .my-current-location {
      a {
        font-size: 0.875rem;
      }
    }
  }

  .map-container {
    .background-map {
      .map-filter {
        .map-filter-topnav {
          .map-filter-list {
            ul {
              li {
                padding-top: 0.325rem;
                padding-bottom: 0.325rem;
                margin: 0 0.3%;

                a {
                  font-size: 0.6875rem;
                }
              }
            }
          }
        }

        .result-main-container {
          .sidebar-result-container {
            max-height: 26rem;
            flex: 0 0 100%;
            position: relative;

            .result-slide {
              height: 26rem;

              .result-item-container {
                max-height: 83px;

                .desc-short {
                  .item-title {
                    padding-left: 0;
                  }
                  padding: 0.5rem 1.875rem;

                  .item-icon {
                    height: 30px;
                    width: 30px;
                    margin-right: 0.625rem;
                  }

                  h4 {
                    font-size: 1rem;
                    margin-bottom: 2px;
                  }

                  p {
                    font-size: 0.8125rem;
                  }
                }

                .desc-long {
                  .long-details-container {
                    .details-item {
                      .details-desc {
                        font-size: 0.875rem;
                      }

                      .details-icon {
                        width: 18%;
                      }
                    }

                    .close-btn {
                      text-align: center;
                    }
                  }
                }
              }
            }

            .result-pager {
              padding: 1.25rem;

              p {
                letter-spacing: 0;
                font-size: 0.85rem;
              }
            }
          }

          .main-map-result-container {
            flex: 0 0 100%;
            position: inherit;

            iframe {
              min-height: 342px;
            }

            #store-locator-map {
              //min-height: 342px;
            }
          }

          .more-details-box {
            top: 10%;
            left: 36%;
            width: 100%;

            .box-container {
              display: flex;
              flex-wrap: wrap;
            }

            .box-section {
              padding: 1.125rem 1.125rem 0;

              p {
                letter-spacing: 0.6px;
                font-size: 0.75rem;
              }

              .header-name {
                margin-bottom: 1rem;
                letter-spacing: 0;

                h4 {
                  font-size: 1.125rem;
                  letter-spacing: 0;
                }

                h5 {
                  font-size: 0.875rem;
                  padding-top: 4%;
                }
              }

              .box-section-body {
                ul {
                  font-size: 0.75rem;
                  padding-left: 8%;
                }
              }

              .box-section-footer {
                .contact-info-no {
                  margin-top: 14%;
                }

                .btn-footer-container {}
              }

              .border-bottom {}

              button {
                font-size: 0.625rem;
                line-height: 0.25rem;
                padding: 0.25rem 0.5rem;
                height: 2.5rem;
              }
            }
          }
        }
      }
    }
  }
}
/* Arrows */
.swiper-container-map {
  .swiper-button-next,
  .swiper-button-prev {
    top: 45%;
    width: 15px;
    height: 12px;
    margin-top: -2px;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    letter-spacing: 0;
    font-size: 0.85rem;
  }
}
/* map-topnav */
.map-filter-list {
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    width: 27px;
    height: 100%;
    margin-top: -26px;
    z-index: 10;
    cursor: pointer;
    background-size: 16px 37px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: #fff;
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    .encoded-svg-background(  "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z' fill='#353738'/></svg>");
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    .encoded-svg-background(  "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z' fill='#353738'/></svg>");
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 0;
    padding: 0 3rem 0 0;
    border-right: 1px solid @light-sand-brown;
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    padding: 0;
    right: 0;
    padding-left: 3rem;
    border-left: 1px solid @light-sand-brown;
  }

  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    opacity: 0;
  }
}
@media (min-width: @screen-xs) {
  .store-locator-wrap {
    .store-locator-header {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;

      h2 {
        font-size: 1.625rem;
        letter-spacing: 2.2px;
        margin-bottom: 0;
      }

      .my-current-location {
        a {
          font-size: 0.875rem;
        }
      }
    }

    .map-container {
      .background-map {
        .map-filter {
          .map-filter-topnav {
            .map-filter-list {
              ul {
                li {
                  padding-top: 0.325rem;
                  padding-bottom: 0.325rem;
                  margin: 0 0.3%;

                  a {
                    font-size: 0.6875rem;
                  }
                }
              }
            }
          }

          .result-main-container {
            .sidebar-result-container {
              max-height: 26rem;
              flex: 0 0 100%;

              .result-slide {
                height: 26rem;

                .result-item-container {
                  max-height: 83px;

                  .desc-short {
                    .item-title {
                      padding-left: 0;
                    }
                    padding: 0.5rem 1.875rem;

                    .item-icon {
                      height: 30px;
                      width: 30px;
                      margin-right: 0.625rem;
                    }

                    h4 {
                      font-size: 1rem;
                      margin-bottom: 2px;
                    }

                    p {
                      font-size: 0.8125rem;
                    }
                  }

                  .desc-long {
                    top: 82px;

                    .long-details-container {
                      .details-item {
                        .details-desc {
                          font-size: 0.875rem;
                        }

                        .details-icon {
                          width: 18%;
                        }
                      }

                      .close-btn {
                        text-align: center;
                      }
                    }
                  }
                }
              }

              .result-pager {
                padding: 1.25rem;

                p {
                  letter-spacing: 0;
                  font-size: 0.85rem;
                }
              }
            }

            .main-map-result-container {
              flex: 0 0 100%;
              position: inherit;

              iframe {
                min-height: 342px;
              }

              #store-locator-map {
                //min-height: 342px;
              }
            }

            .more-details-box {
              top: 10%;
              left: 36%;
              width: 100%;

              .box-container {
                display: flex;
                flex-wrap: wrap;
              }

              .box-section {
                padding: 1.125rem 1.125rem 0;

                p {
                  letter-spacing: 0.6px;
                  font-size: 0.75rem;
                }

                .header-name {
                  margin-bottom: 1rem;
                  letter-spacing: 0;

                  h4 {
                    font-size: 1.125rem;
                    letter-spacing: 0;
                  }

                  h5 {
                    font-size: 0.875rem;
                    padding-top: 4%;
                  }
                }

                .box-section-body {
                  ul {
                    font-size: 0.75rem;
                    padding-left: 8%;
                  }
                }

                .box-section-footer {
                  .contact-info-no {
                    margin-top: 14%;
                  }

                  .btn-footer-container {}
                }

                .border-bottom {}

                button {
                  font-size: 0.625rem;
                  line-height: 0.25rem;
                  padding: 0.25rem 0.5rem;
                  height: 2.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
  /* Arrows */
  .swiper-container-map {
    .swiper-button-next,
    .swiper-button-prev {
      top: 45%;
      width: 15px;
      height: 12px;
      margin-top: -2px;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      letter-spacing: 0;
      font-size: 0.85rem;
    }
  }
  /* map-topnav */
  .map-filter-list {
    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      top: 50%;
      width: 27px;
      height: 100%;
      margin-top: -26px;
      z-index: 10;
      cursor: pointer;
      background-size: 16px 37px;
      background-position: 50%;
      background-repeat: no-repeat;
      background-color: #fff;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      .encoded-svg-background(  "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z' fill='#353738'/></svg>");
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      .encoded-svg-background(  "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z' fill='#353738'/></svg>");
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: 0;
      padding: 0 3rem 0 0;
      border-right: 1px solid @light-sand-brown;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      padding: 0;
      right: 0;
      padding-left: 3rem;
      border-left: 1px solid @light-sand-brown;
    }

    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled {
      opacity: 0;
    }
  }
}
@media (min-width: @screen-ms) {
  .store-locator-wrap {
    .store-locator-header {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;

      h2 {
        margin-bottom: 0;
        font-size: 1.625rem;
        letter-spacing: 2.2px;
      }

      .my-current-location {
        a {
          font-size: 0.875rem;
        }
      }
    }

    .map-container {
      .background-map {
        .map-filter {
          .map-filter-topnav {
            .map-filter-list {
              overflow: hidden;

              ul {
                li {
                  padding-top: 0.325rem;
                  padding-bottom: 0.325rem;
                  margin: 0 0.3%;

                  a {
                    font-size: 0.6875rem;
                  }
                }
              }
            }
          }

          .result-main-container {
            .sidebar-result-container {
              min-height: 31.7rem;
              //flex: 1 2 42.5%;
              flex: 0 0 100%;
              background-color: @white;

              .result-slide {
                background-color: @white;
                min-height: 28.7rem;
                align-content: flex-start;

                .result-item-container {
                  max-height: 92px;

                  .desc-short {
                    .item-title {
                      padding-left: 0;
                    }
                    padding: 1.2rem;

                    .item-icon {
                      height: 30px;
                      width: 30px;
                      margin-right: 0.625rem;
                    }

                    h4 {
                      font-size: 1rem;
                      margin-bottom: 2px;
                    }

                    p {
                      font-size: 0.8125rem;
                    }
                  }

                  .desc-long {
                    .long-details-container {
                      .details-item {
                        .details-desc {
                          font-size: 0.875rem;
                        }

                        .details-icon {
                          width: 18%;
                        }
                      }

                      .close-btn {
                        text-align: center;
                      }
                    }
                  }
                }
              }

              .result-pager {
                padding: 0.25rem 1.25rem;

                p {
                  letter-spacing: 0;
                  font-size: 0.85rem;
                }
              }
            }

            .gm-style-iw {
              top: 0 !important;
              left: 0 !important;
              width: 469px !important;
              max-width: 469px !important;
              min-width: 469px !important;
              overflow: visible;

              > div {
                overflow: visible;
                width: 469px !important;
                max-width: 469px !important;
                min-width: 469px !important;
              }
            }

            .more-details-box {
              top: 10%;
              left: 36%;
              width: 469px;

              .box-section {
                padding: 1.125rem 1.125rem 0;

                p {
                  letter-spacing: 0.6px;
                  font-size: 0.75rem;
                }

                .header-name {
                  margin-bottom: 1rem;
                  letter-spacing: 0;

                  h4 {
                    font-size: 1.125rem;
                    letter-spacing: 0;
                  }

                  h5 {
                    font-size: 0.875rem;
                    padding-top: 4%;
                  }
                }

                .box-section-body {
                  ul {
                    font-size: 0.75rem;
                    padding-left: 8%;
                  }
                }

                .box-section-footer {
                  .contact-info-no {
                    margin-top: 14%;
                  }

                  .btn-footer-container {}
                }

                .border-bottom {}

                button {
                  font-size: 0.625rem;
                  line-height: 0.25rem;
                  padding: 0.25rem 0.5rem;
                  height: 2.5rem;
                }
              }
            }

            .main-map-result-container {
              flex: 2 1 75%;

              iframe {
                min-height: 600px;
              }
            }
          }
        }
      }
    }
  }
  /* Arrows */
  .swiper-container-map {
    .swiper-button-next,
    .swiper-button-prev {
      top: 45%;
      width: 15px;
      height: 12px;
      margin-top: -2px;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      letter-spacing: 0;
      font-size: 0.85rem;
    }
  }
  /* map-topnav */
  .map-filter-list {
    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      top: 50%;
      width: 27px;
      height: 99%;
      margin-top: -25px;
      z-index: 10;
      cursor: pointer;
      background-size: 16px 37px;
      background-position: 50%;
      background-repeat: no-repeat;
      background-color: #fff;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      .encoded-svg-background(  "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z' fill='#353738'/></svg>");
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      .encoded-svg-background(  "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z' fill='#353738'/></svg>");
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: 0;
      padding: 0 3rem 0 0;
      border-right: 1px solid @light-sand-brown;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      padding: 0;
      right: 46px;
      padding-left: 3rem;
      border-left: 1px solid @light-sand-brown;
    }

    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled {
      opacity: 0;
    }
  }
}
@media (min-width: @screen-sm-min) {
  .store-locator-wrap {
    .store-locator-header {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;

      h2 {
        font-size: 1.875rem;
        letter-spacing: 3.3px;
        margin-bottom: 0;
      }

      .my-current-location {
        a {
          font-size: 0.875rem;
        }
      }
    }

    .map-container {
      .background-map {
        .map-filter {
          .map-filter-topnav {
            .map-filter-list {
              overflow: hidden;

              ul {
                li {
                  padding-top: 0.325rem;
                  padding-bottom: 0.325rem;
                  margin: 0 0.3%;

                  a {
                    font-size: 0.6875rem;
                  }
                }
              }
            }
          }

          .result-main-container {
            .sidebar-result-container {
              min-height: 28.7rem;
              flex: 1 2 42.5%;

              .result-slide {
                height: 33.7rem;
                align-content: flex-start;

                .result-item-container {
                  max-height: 92px;

                  .desc-short {
                    .item-title {
                      padding-left: 0;
                    }
                    padding: 1.2rem;

                    .item-icon {
                      height: 30px;
                      width: 30px;
                      margin-right: 0.625rem;
                    }

                    h4 {
                      font-size: 1rem;
                      margin-bottom: 2px;
                    }

                    p {
                      font-size: 0.8125rem;
                    }
                  }

                  .desc-long {
                    .long-details-container {
                      .details-item {
                        .details-desc {
                          font-size: 0.875rem;
                        }

                        .details-icon {
                          width: 18%;
                        }
                      }

                      .close-btn {
                        text-align: center;
                      }
                    }
                  }
                }
              }

              .result-pager {
                padding: 0.25rem 1.25rem;

                p {
                  letter-spacing: 0;
                  font-size: 0.85rem;
                }
              }
            }

            .gm-style-iw {
              top: 0 !important;
              left: 0 !important;
              width: 469px !important;
              max-width: 469px !important;
              min-width: 469px !important;
              overflow: visible;

              > div {
                overflow: visible;
                width: 469px !important;
                max-width: 469px !important;
                min-width: 469px !important;
              }
            }

            .more-details-box {
              top: 10%;
              left: 36%;
              width: 469px;

              .box-section {
                padding: 1.125rem 1.125rem 0;

                p {
                  letter-spacing: 0.6px;
                  font-size: 0.75rem;
                }

                .header-name {
                  margin-bottom: 1rem;
                  letter-spacing: 0;

                  h4 {
                    font-size: 1.125rem;
                    letter-spacing: 0;
                  }

                  h5 {
                    font-size: 0.875rem;
                    padding-top: 4%;
                  }
                }

                .box-section-body {
                  ul {
                    font-size: 0.75rem;
                    padding-left: 8%;
                  }
                }

                .box-section-footer {
                  .contact-info-no {
                    margin-top: 14%;
                  }

                  .btn-footer-container {}
                }

                .border-bottom {}

                button {
                  font-size: 0.625rem;
                  line-height: 0.25rem;
                  padding: 0.25rem 0.5rem;
                  height: 2.5rem;
                }
              }
            }

            .main-map-result-container {
              flex: 2 1 75%;

              iframe {
                min-height: 600px;
              }
            }
          }
        }
      }
    }
  }
  /* Arrows */
  .swiper-container-map {
    .swiper-button-next,
    .swiper-button-prev {
      top: 45%;
      width: 15px;
      height: 12px;
      margin-top: -2px;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      letter-spacing: 0;
      font-size: 0.85rem;
    }
  }
  /* map-topnav */
  .map-filter-list {
    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      top: 50%;
      width: 27px;
      height: 99%;
      margin-top: -25px;
      z-index: 10;
      cursor: pointer;
      background-size: 16px 37px;
      background-position: 50%;
      background-repeat: no-repeat;
      background-color: #fff;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      .encoded-svg-background(  "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z' fill='#353738'/></svg>");
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      .encoded-svg-background(  "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'><path d='M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z' fill='#353738'/></svg>");
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: 0;
      padding: 0 3rem 0 0;
      border-right: 1px solid @light-sand-brown;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      padding: 0;
      right: 0;
      padding-left: 3rem;
      border-left: 1px solid @light-sand-brown;
    }

    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled {
      opacity: 0;
    }
  }
}
@media (min-width: @screen-md-min) {
  .store-locator-wrap {
    .store-locator-header {
      padding-top: 3.625rem;
      padding-bottom: 3.625rem;

      h2 {
        font-size: 2.5rem;
        letter-spacing: 3.3px;
        margin-bottom: auto;
      }

      .my-current-location {
        a {
          font-size: 0.875rem;
        }
      }
    }

    .map-container {
      .background-map {
        .map-filter {
          .map-filter-topnav {
            .map-filter-list {
              ul {
                li {
                  padding-top: 0.325rem;
                  padding-bottom: 0.325rem;
                  margin: 0 0.3%;

                  a {
                    font-size: 0.6875rem;
                  }
                }
              }
            }
          }

          .result-main-container {
            .sidebar-result-container {
              min-height: 36.56rem;
              flex: 1 2 25%;

              .result-slide {
                height: 33.19rem;
                align-content: flex-start;

                .result-item-container {
                  max-height: 105px;

                  .desc-short {
                    .item-title {
                      padding-left: 0.5rem;
                    }
                    padding: 1.2rem;

                    .item-icon {
                      height: 30px;
                      width: 30px;
                      margin-right: 0.625rem;
                    }

                    h4 {
                      font-size: 1rem;
                      margin-bottom: 2px;
                    }

                    p {
                      font-size: 0.8125rem;
                    }
                  }

                  .desc-long {
                    top: 104px;

                    .long-details-container {
                      .details-item {
                        .details-desc {
                          font-size: 0.875rem;
                        }

                        .details-icon {
                          width: 18%;
                        }
                      }

                      .close-btn {
                        text-align: center;
                      }
                    }
                  }
                }
              }

              .result-pager {
                padding: 0.25rem 1.25rem;

                p {
                  letter-spacing: 0;
                  font-size: 0.85rem;
                }
              }
            }

            .gm-style-iw {
              top: 0 !important;
              left: 0 !important;
              width: 511px !important;
              max-width: 511px !important;
              min-width: 511px !important;
              overflow: visible;

              > div {
                overflow: visible;
                width: 511px !important;
                max-width: 511px !important;
                min-width: 511px !important;
              }
            }

            .more-details-box {
              top: 10%;
              left: 38%;
              width: 511px;

              .box-section {
                padding: 1.125rem 0 0 1rem;

                p {
                  letter-spacing: 0.6px;
                  font-size: 0.875rem;
                }

                .header-name {
                  margin-bottom: 1rem;
                  letter-spacing: 0;

                  h4 {
                    font-size: 1.25rem;
                    letter-spacing: 0;
                  }

                  h5 {
                    font-size: 0.875rem;
                    padding-top: 4%;
                  }
                }

                .box-section-body {
                  ul {
                    font-size: 0.875rem;
                    padding-left: 8%;
                  }
                }

                .box-section-footer {
                  .contact-info-no {
                    margin-top: 14%;
                  }

                  .btn-footer-container {}
                }

                .border-bottom {}

                button {
                  font-size: 0.625rem;
                  line-height: 0.5rem;
                  padding: 0.5rem 1rem;
                  letter-spacing: 1px;
                }
              }
            }

            .main-map-result-container {
              flex: 2 1 75%;

              iframe {
                min-height: 700px;
              }
            }
          }
        }
      }
    }
  }
  /* Arrows */
  .swiper-container-map {
    .swiper-button-next,
    .swiper-button-prev {
      top: 45%;
      width: 15px;
      height: 12px;
      margin-top: -2px;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      letter-spacing: 0;
      font-size: 0.85rem;
    }
  }
}
@media (min-width: @screen-lg-min) {
  .store-locator-wrap {
    .store-locator-header {
      padding-top: 3.625rem;
      padding-bottom: 3.625rem;

      h2 {
        letter-spacing: 8px;
        font-size: 3.375rem;
        margin-bottom: auto;
      }

      .my-current-location a {
        font-size: 1rem;
      }
    }

    .map-container {
      .background-map {
        &.closed {
          height: 200px;
        }

        &.opened {
          height: 800px;
        }

        .map-filter {
          .map-filter-topnav {
            .map-filter-list {
              ul {
                li {
                  padding-top: 0.625rem;
                  padding-bottom: 0.625rem;
                  margin: 0 0.5%;

                  a {
                    font-size: 1rem;
                  }
                }
              }
            }
          }

          .result-main-container {
            .sidebar-result-container {
              max-height: 49.38rem;
              flex: 1 2 25%;

              .result-slide {
                height: 43.75rem;
                align-content: flex-start;

                .result-item-container {
                  max-height: 8.75rem;

                  .desc-short {
                    padding: 1.85rem;

                    .item-title {
                      padding-left: 0.5rem;
                    }

                    .item-icon {
                      margin-right: 1.25rem;
                      height: 44px;
                      width: 44px;

                      img {
                        height: 44px;
                        width: 44px;
                      }
                    }

                    h4 {
                      font-size: 1.375rem;
                    }

                    p {
                      font-size: 1rem;
                    }
                  }

                  .desc-long {
                    top: 139px;

                    .long-details-container {
                      .details-item {
                        .details-desc {
                          font-size: 1rem;
                        }

                        .details-icon {
                          width: 10%;
                        }
                      }

                      .close-btn {
                        text-align: right;
                      }
                    }
                  }
                }
              }

              .result-pager {
                padding: 1.25rem;

                p {
                  letter-spacing: 1.2px;
                  font-size: 1rem;
                }
              }
            }

            .gm-style-iw {
              top: 0 !important;
              left: 0 !important;
              width: 434px !important;
              max-width: 434px !important;
              min-width: 434px !important;
              overflow: visible;

              > div {
                width: 434px !important;
                max-width: 434px !important;
                min-width: 434px !important;
                overflow: visible;
              }
            }

            .more-details-box {
              top: 5%;
              left: 48%;
              overflow: hidden;
              width: 434px;

              .box-section {
                padding: 1.125rem 2.375rem 0;

                p {
                  font-size: 1rem;
                  letter-spacing: 0.6px;
                }

                .header-name {
                  margin-bottom: 1.5rem;
                  letter-spacing: 0;

                  h4 {
                    font-size: 1.375rem;
                    margin: 0;
                  }

                  h5 {
                    font-size: 1rem;
                    padding-top: 0;
                  }
                }

                .box-section-body {
                  ul {
                    font-size: 1rem;
                  }
                }

                .box-section-footer {
                  .contact-info-no {
                    margin-top: 0;
                  }
                }

                button {
                  font-size: 12px;
                  padding: 1rem;
                  line-height: 1rem;
                  padding: 1rem;
                  height: 3rem;
                }
              }
            }

            .main-map-result-container {
              flex: 2 1 75%;

              iframe {
                min-height: 795px;
              }
            }
          }
        }
      }
    }
  }
  /* Arrows */
  .swiper-container-map {
    .swiper-button-next,
    .swiper-button-prev {
      top: 50%;
      width: 35px;
      height: 12px;
      margin-top: -4px;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      letter-spacing: 1.2px;
      font-size: 1rem;
    }
  }
}

.business {
  .store-locator-wrap .tiles-content:after {
    background-color: rgba(52, 60, 58, 0.7);
  }
}
/*
 * RTL support
 */
html[lang="ar"] {
  *[dir="rtl"] {
    .search-group {
      .input-group.form-group {
        display: flex;
        align-items: inherit;
      }

      .store-locator-style {
        #pac-input {
          padding-right: 0;
          padding-left: 18%;
        }

        span {
          left: 0;
          right: auto;
        }
      }
    }

    #store-locator-map .more-details-box .box-section .box-section-footer .contact-info-no svg {
      float: right;
      margin-left: 6%;
      margin-right: auto;
    }
    @media (min-width: @screen-xs) and (min-width: @screen-ms-max) {
      .search-group {
        .store-locator-style {
          #pac-input {
            padding-right: 1rem;
            padding-left: 28%;
          }
        }
      }
    }
    @media (min-width: @screen-ms) {
      .store-locator-wrap .store-locator-header {}
      @media (min-width: @screen-sm-min) {
        .search-group {
          .store-locator-style {
            #pac-input {
              padding-left: 20%;
              padding-right: 1rem;
            }
          }
        }
      }
      @media (min-width: @screen-md-min) {
        .search-group {
          .store-locator-style {
            #pac-input {
              padding-left: 20%;
              padding-right: 1rem;
            }
          }
        }

        .store-locator-wrap .tiles-content .store-locator-header .search-group .search-btn {}
      }
      @media (min-width: @screen-lg-min) {
        .search-group {
          .store-locator-style {
            #pac-input {
              padding-left: 18%;
              padding-right: 1rem;
            }
          }
        }
      }
      // end common style for search - check
    }

    .store-locator-wrap {
      .tiles-content {
        &:after {
          left: auto;
          right: 0;
        }

        div.cover {
          left: auto;
          right: 0;
        }

        .store-locator-header {
          .my-current-location {
            padding-right: 2%;
            padding-left: auto;

            svg {
              margin-left: 5px;
              margin-right: 0;
            }

            a {}
          }
        }
      }

      .map-container {
        .background-map {
          font-family: @font-arabic;

          .map-filter {
            .map-filter-topnav {
              .map-filter-list {
                .nav {
                  padding-right: 0;
                  padding-left: auto;
                }

                ul {
                  float: right;

                  li {
                    float: right;

                    a {
                      font-family: @font-arabic;
                    }
                  }
                }
              }
            }

            .result-main-container {
              .sidebar-result-container {
                .result-slide {
                  .result-item-container {
                    &.open-detail {
                      left: auto;
                      right: 0;

                      .desc-short {
                        border-right-width: 5px;
                        border-right-style: solid;
                        border-left: 0;
                      }
                    }

                    .desc-short {
                      padding-left: 4%;
                      border-right-width: 0;

                      .item-title {
                        padding-right: 0.5rem;
                        padding-left: auto;
                      }
                    }

                    .desc-long {
                      .long-details-container {
                        .close-btn {
                          text-align: left;

                          a {
                            svg {
                              margin-right: 23px;
                              margin-left: auto;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .more-details-box {
                left: auto;
                right: 36%;

                .box-section {
                  padding: 1.125rem 1.125rem 0;
                }
              }
            }
          }
        }
      }
    }
    /* Arrows */
    @media (min-width: @screen-xs) {
      .store-locator-wrap {
        .map-container {
          .background-map {
            .map-filter {
              .result-main-container {
                .sidebar-result-container {
                  .result-slide {
                    .result-item-container {
                      .desc-short {
                        .item-title {
                          padding-right: 0;
                          padding-left: auto;
                        }

                        .item-icon {
                          margin-left: 0.625rem;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      /* Arrows */
      .swiper-container-map {
        .swiper-button-next,
        .swiper-button-prev {
          top: 45%;
          width: 35px;
          height: 12px;
          margin-top: -4px;
          /* right: 0; */
          transform: rotate(0deg);
        }
      }
    }
    @media (min-width: @screen-ms-min) {
      .store-locator-wrap {
        .map-container {
          .background-map {
            .map-filter {
              .result-main-container {
                .sidebar-result-container {
                  .result-slide {
                    .result-item-container {
                      .desc-short {
                        .item-title {
                          padding-left: auto;
                          padding-right: 0;
                        }

                        .item-icon {
                          margin-left: 0.625rem;
                          margin-right: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      /* Arrows */
      .swiper-container-map {
        .swiper-button-next,
        .swiper-button-prev {
          top: 45%;
          width: 15px;
          height: 12px;
          margin-top: -2px;
          transform: rotate(0deg);
        }

        .swiper-container-horizontal > .swiper-pagination-bullets,
        .swiper-pagination-custom,
        .swiper-pagination-fraction {}
      }
    }
    @media (min-width: @screen-sm-min) {
      .store-locator-wrap {
        .store-locator-header {
          h2 {}

          .my-current-location {
            a {}
          }
        }

        .map-container {
          .background-map {
            .map-filter {
              .map-filter-topnav {
                .map-filter-list {
                  ul {
                    li {
                      a {}
                    }
                  }
                }
              }

              .result-main-container {
                .sidebar-result-container {
                  .result-slide {
                    .result-item-container {
                      .desc-short {
                        .item-title {
                          padding-left: auto;
                          padding-right: 0;
                        }

                        .item-icon {
                          margin-left: 0.625rem;
                          margin-right: 0;
                        }
                      }

                      .desc-long {
                        .long-details-container {
                          .details-item {
                            .details-desc {}

                            .details-icon {}
                          }

                          .close-btn {}
                        }
                      }
                    }
                  }

                  .result-pager {
                    p {}
                  }
                }

                .main-map-result-container {
                  iframe {}
                }
              }
            }
          }
        }
      }
      /* Arrows */
      .swiper-container-map {
        .swiper-button-next,
        .swiper-button-prev {
          top: 45%;
          width: 15px;
          height: 12px;
          margin-top: -2px;
          transform: rotate(0deg);
        }

        .swiper-container-horizontal > .swiper-pagination-bullets,
        .swiper-pagination-custom,
        .swiper-pagination-fraction {}
      }
    }
    @media (min-width: @screen-md-min) {
      .store-locator-wrap {
        .store-locator-header {
          h2 {}

          .my-current-location {
            a {}
          }
        }

        .map-container {
          .background-map {
            .map-filter {
              .map-filter-topnav {
                .map-filter-list {
                  ul {
                    li {
                      a {}
                    }
                  }
                }
              }

              .result-main-container {
                .sidebar-result-container {
                  .result-slide {
                    .result-item-container {
                      .desc-short {
                        .item-title {
                          padding-right: 0.5rem;
                          padding-left: auto;
                        }

                        .item-icon {
                          margin-left: 0.625rem;
                          margin-right: 0;
                        }
                      }

                      .desc-long {
                        .long-details-container {
                          .details-item {
                            .details-desc {}

                            .details-icon {}
                          }

                          .close-btn {}
                        }
                      }
                    }
                  }

                  .result-pager {
                    p {}
                  }
                }

                .main-map-result-container {
                  iframe {}
                }
              }
            }
          }
        }
      }
      /* Arrows */
      .swiper-container-map {
        .swiper-button-next,
        .swiper-button-prev {
          margin-top: -5px;
          transform: rotate(0deg);
        }

        .swiper-container-horizontal > .swiper-pagination-bullets,
        .swiper-pagination-custom,
        .swiper-pagination-fraction {}
      }
    }
    @media (min-width: @screen-lg-min) {
      .store-locator-wrap {
        .map-container {
          .background-map {
            .map-filter {
              .map-filter-topnav {
                .map-filter-list {
                  ul {
                    li {
                      a {}
                    }
                  }
                }
              }

              .result-main-container {
                .sidebar-result-container {
                  .result-slide {
                    .result-item-container {
                      .desc-short {
                        padding: 1.85rem;

                        .item-title {
                          padding-right: 0.5rem;
                          padding-left: auto;
                        }

                        .item-icon {
                          height: 45px;
                          width: 45px;
                          margin-left: 1.25rem;
                          margin-right: 0;
                        }
                      }

                      .desc-long {
                        .long-details-container {
                          .close-btn {
                            text-align: left;
                          }
                        }
                      }
                    }
                  }

                  .result-pager {
                    padding: 1.25rem;

                    p {
                      letter-spacing: 1.2px;
                      font-size: 1rem;
                    }
                  }
                }

                .main-map-result-container {
                  flex: 2 1 75%;

                  iframe {
                    min-height: 795px;
                  }
                }
              }
            }
          }
        }
      }
      /* Arrows */
      .swiper-container-map {
        .swiper-button-next,
        .swiper-button-prev {
          top: 45%;
          width: 35px;
          height: 12px;
          margin-top: -4px;
          transform: rotate(0deg);
        }

        .swiper-container-horizontal > .swiper-pagination-bullets,
        .swiper-pagination-custom,
        .swiper-pagination-fraction {
          letter-spacing: 1.2px;
          font-size: 1rem;
        }
      }
    }

    .business {
      .store-locator-wrap .tiles-content:after {
        background-color: rgba(52, 60, 58, 0.7);
      }
    }

    .map-filter-list .swiper-button-prev,
    .map-filter-list .swiper-container-rtl .swiper-button-next {
      left: 0;
      right: auto;
    }

    .store-locator-wrap .switch-store-locator .switcher svg {
      margin-left: 1rem;
      margin-right: 0;
    }
  }
}
/* Hack for firefox only for store locator left panel */
@-moz-document url-prefix() {
 .store-locator-wrap .map-container .background-map .map-filter .result-main-container .sidebar-result-container .result-slide .result-item-container .desc-short{
  max-width: 400px;
 }
}


//arrow issue on store locator for ie only
.swiper-slide.yellow-b.active::after, .swiper-slide.active:after {
  -ms-transform: translateX(0vw) !important;
}
.gm-ui-hover-effect {
  top: 12px !important;
  right: 52px !important;
}

@media (max-width: 768px) {
  .box-container {
    h4 {
      font-size: 1rem !important;
    }
    .dynamic-value, .box-section-footer, .box-section-body {
      display: none !important;
    }
  }
}
