.page-nav-wrap {
  width: 100%;
  background-color: @white;
  font-family: @font-family-menu-sans-serif;
  .dropdown-toggle,
  .page-nav a,
  .page-nav-cta a,
  .page-nav-main-label {
    font-size: 0.875rem;
    line-height: 1.5rem;
    padding-top: 0.625rem;
    padding-bottom: 0.8125rem;
  }

  .page-nav-main-label-top {
    display: block;
    z-index: 2;
    line-height: 2.25rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: normal;
    color: @lighty-gray;
    background-color: @sand-white-50;
  }

  .dropdown-toggle,
  .page-nav a {
    padding-top: 0.72rem;
  }

  .dropdown-toggle {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    text-transform: uppercase;
    border-bottom: 1px solid @sand-white;

    .current-dropdown-label {
      padding-left: 2rem;
      color: @almost-black;
    }

    #dropdown-menu-arrow-img {
      width: 1.2rem;
      height: 0.8rem;
      margin-right: 2rem;
      fill: @main-green;
      stroke-width: 0.125rem;
    }
    //.page-nav-main-label{
    //  display: none;
    //  font-size: 1rem;
    //  padding-top:.8rem;
    //  line-height:1.6rem;
    //}
  }

  .dropdown.open {
    #dropdown-menu-arrow-img {
      transform: rotate(180deg);
    }
    //.page-nav-main-label{
    //  position: absolute;
    //  top: 100%;
    //  left: 0;
    //  width:100%;
    //  text-align: center;
    //  background-color: @light-gray;
    //  color: @lighty-gray;
    //  display: block;
    //  z-index:2;
    //  text-transform: uppercase;
    //  font-weight: normal;
    //}
  }

  .page-nav-cta a {
    color: @white;
    background-color: @main-green;
    display: block;
    text-align: center;
    font-style: 1rem;
    letter-spacing: 0.125rem;
  }

  .page-nav-main-label {
    color: @lighty-gray;
    letter-spacing: 0.125rem;
    text-transform: uppercase;

    &.do-not-uppercase {
      text-transform: none;
    }
  }

  .dropdown-menu {
    box-shadow: none;
    // top: 200%;
    width: 100%;
    margin: 0;
    padding: 0;

    li {
      width: 100%;
    }
  }

  .page-nav {
    background-color: @white;
    justify-content: flex-start;
    text-transform: uppercase;
    border: none;
    padding-top: 1rem;

    li {
      margin-bottom: 0;
    }

    a {
      display: inline-block;
      width: 100%;
      border: none;
      margin: 0;
      color: @almost-black;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .highlight a {
      //color: @main-green;
      border-bottom: 4px solid @main-green;
    }

    .active a,
    .active a:active,
    .active a:focus,
    .active a:focus,
    .active.highlight a {
      //color: @main-green;
      border-bottom: 0px solid @main-green;

      &:before {
        position: absolute;
        content: " ";
        width: 4px;
        height: 1.5rem;
        background-color: @main-green;
        left: 0;
        top: 0.75rem;
      }
    }


    .active a:focus,
    .active a:hover,
    a:focus,
    a:hover {
      border: none;
      //color: @main-green;
      border-bottom: 0px solid @main-green;
    }
    .active a{
      border-bottom: 0px solid @main-green;
      border: 0;
    }
    .dropdown-close-button {
      a {
        color: @dark-gray;
        text-align: right;
        font-size: 0.875rem;
        padding-top: 0.9375rem;
        padding-bottom: 0.5rem;
        text-transform: uppercase;
        font-family: @font-family-sans-serif;
        font-weight: normal;
        border-top: 1px solid #f1f1eb;
      }

      #close-menu-arrow-img {
        width: 1.2rem;
        height: 0.8rem;
        margin-left: 1rem;
        margin-right: 1rem;
        fill: @main-green;
        stroke-width: 0.125rem;
        transform: rotate(180deg);
      }
      border-bottom: 4px solid @gray-close-dropdown;
    }
  }

  &.affix {
    left: 0;
    width: 100%;
    top: 50px;
    z-index: 6;
    /*border-bottom: 1.5rem solid @sand-white;*/
    .row.page-nav-menu-row {
      border-bottom: 1px solid @sand-white;
    }
  }
  @media (max-width: 768px) {
    .page-nav-main-label-top{
      padding-top: 0.5rem;
    }
    .page-nav-menu-row{
      .dropdown{
        .page-nav{
          overflow-x: auto;
          overflow-y: hidden;
          display: flex;
          justify-content: space-between;
          padding-top: 0px;
          float: none;
          padding: 0 .5rem;
          z-index: 7;
          li{
            a{
              padding-left: 0.8rem;
              padding-right: 0.8rem;
            }
            &.active{
              a{
                border: none;
                border-bottom: solid 4px @main-green;
                white-space: nowrap;
                &::before{
                  display:none;
                }
              }
            }
            a{
              white-space: nowrap;
            }
          }
        }
      }
    }

  }
  .page-nav-menu-wrap {
  @media (max-width: 767px) {
    height: 48px;
    .dropdown{
      .dropdown-toggle{
        display: none;
      }
      .dropdown-menu{
        .dropdown-close-button{
          display: none !important;
        }
      }
    }
  }
  }
}
.wst{
  .page-nav-wrap{
    .page-nav{
      li{
        &:first-child{
          a{
            margin-left: 0;
          }
        }
        a{
          font-size: 1.25rem;
          text-transform: capitalize;
          margin:0 1rem;
          text-align: center;
        }
      }
    }
  }
}


.business {
  .page-nav-wrap {
    &.affix {
      left: 0;
      width: 100%;
      z-index: 6;
      /*border-bottom: 1.5rem solid @dark-sand-brown;*/
      .row.page-nav-menu-row {
        border-bottom: 0rem solid @dark-sand-brown;
      }
    }

    .page-nav-main-label-top {
      color: @steel-grey;
      background-color: @darkest-night;
    }

    .dropdown-toggle {
      border-bottom-color: @steel-grey;
      background: @dark-gray;

      .current-dropdown-label {
        color: @white;
      }

      #dropdown-menu-arrow-img {
        fill: @light-sand-brown;
      }
    }

    .page-nav {
      background-color: @dark-gray;

      a {
        color: @white;

        &:hover {
          background: none;
        }

        &:focus {
          background: none;
        }
      }

      .dropdown-close-button {
        border-top: 1px solid @steel-grey;

        #close-menu-arrow-img {
          fill: @light-sand-brown;
        }
      }
    }
  }

  .page-nav-wrap .page-nav .active a,
  .page-nav-wrap .page-nav .active a:active,
  .page-nav-wrap .page-nav .active a:focus,
  .page-nav-wrap .page-nav .active.highlight a,
  .page-nav-wrap .page-nav .highlight a {
    color: @light-sand-brown;
    background-color: inherit;

    &:before {
      position: absolute;
      content: " ";
      width: 4px;
      height: 1.5rem;
      background-color: @light-sand-brown;
      left: 0;
      top: 0.75rem;
    }
  }
}
@media (max-width: @screen-sm-min) {
  .page-nav-wrap .page-nav a {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .page-nav-wrap .page-nav .active a:active,
  .page-nav-wrap .page-nav a:active {
    //color: @main-green;
    border-bottom: 4px solid @main-green;

    &:before {
      display: none;
      position: absolute;
      content: " ";
      width: 4px;
      height: 1.5rem;
      background-color: @main-green;
      left: 0;
      top: 0.75rem;
    }
  }

  .business {
    .page-nav-wrap .page-nav .active a:active,
    .page-nav-wrap .page-nav a:active {
      color: @light-sand-brown;

      &:before {
        position: absolute;
        content: " ";
        width: 4px;
        height: 1.5rem;
        background-color: @light-sand-brown;
        left: 0;
        top: 0.75rem;
      }
    }
  }

  .page-nav-wrap .page-nav a:hover {
    border-bottom: 0 solid #e2e1da;
  }
}
@media (min-width: @screen-sm-min) {
  .page-nav-wrap .page-nav .active a:active,
  .page-nav-wrap .page-nav a:active {
    border-left: 0;

    &:before {
      position: absolute;
      content: " ";
      width: 0;
      height: 0;
      background-color: none;
      left: 0;
      top: 0;
    }
  }

  .page-nav-wrap {
    .dropdown {
      width: 100%;
    }

    .dropdown-toggle {
      display: none;
    }

    .page-nav a,
    .page-nav-cta a,
    .page-nav-main-label {
      font-size: 0.875rem;
      line-height: 1.125rem;
      padding-top: 1.5rem;
      padding-bottom: 1.4rem;
    }

    .page-nav-menu-wrap {
      display: flex;
      justify-content: center;
    //  margin:auto;
    }

    .page-nav-menu-row {
      display: flex;
    }

    .dropdown-menu {
      position: static;
      margin: 0;
      padding: 0;
      width: auto;
      top: 0;

      li {
        width: auto;
      }
    }

    .page-nav {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-top: 0;
    //  @media (max-width: 1023px){ for all layout fix comment media query
            z-index: 2; // this media query and z-index for mobile overlaping solution on b2c book your number site
    //  }
      a {
        padding-left: 1rem;
        padding-right: 1rem;
        border-bottom: 4px solid transparent;
        &.greenText {
          color:@main-green;
          &:hover {
            color:@white
          }
        }

      }

      .active a:hover,  a:hover{
        //background-color: @main-green;
        color: @almost-black;//@white;
        border-bottom: 4px solid @main-green;
      }



      .active a,
      .active a:focus,
      .active a:hover {
        color: @almost-black;
        border-bottom: 4px solid @main-green;

        &:before {
          display: none;
        }
      }
      a:hover {
        //background-color: @main-green;
        color: @white;//@white;
        border-bottom: 4px solid @main-green;
        background-color: @main-green;
      }
      li:first-child {
        /*margin-left: -1rem;*/
      }
    }
  }

  .business {
    .page-nav-wrap .page-nav .active a:active,
    .page-nav-wrap .page-nav a:active {
      border-left: 0;
      color: @light-sand-brown;
    }

    .page-nav a:active,
    .page-nav-cta a:active {
      border-bottom: 4px solid @light-sand-brown;
      color: @light-sand-brown;
    }
  }

  .business .page-nav-wrap .page-nav a:hover {
    border-bottom: 4px solid @light-sand-brown;
  }
}
@media (min-width: @screen-md-min) {
  .page-nav-wrap .page-nav .active a:active,
  .page-nav-wrap .page-nav a:active {
    border-left: 0;
  }
.page-nav-wrap .page-nav{
  justify-content: left;
}
  .business {
    .page-nav-wrap .page-nav .active a:active,
    .page-nav-wrap .page-nav a:active {
      border-left: 0;
      color: @light-sand-brown;
      border-bottom-color: #e2e1da;
    }
  }

  .business .page-nav-wrap .page-nav a:hover {
    border-bottom: 4px solid @light-sand-brown;
  }
}
@media (min-width: @screen-lg-min) {
  .business {
    .page-nav a:active,
    .page-nav-cta a:active {
      border-bottom: 4px solid @light-sand-brown;
      //padding-bottom: 0.9rem;
      color: @light-sand-brown;
    }
  }

  .page-nav-wrap {
    .page-nav a,
    .page-nav-cta a,
    .page-nav-main-label {
      font-size: 1rem;
      line-height: 1.5rem;
      padding-top: 1.375rem;
      padding-bottom: 1.125rem;
    }

    .page-nav a:active,
    .page-nav-cta a:active {
      border-bottom: 4px solid @main-green;
      //padding-bottom: 0.9rem;
    }

    .page-nav {
      //width: 100%;
      width: auto;
      justify-content: left;

      li,
      li:first-child {
        margin-right: 0.5rem;
        margin-left:  0.5rem;
      }

      li {
        a {
          padding-left: 1rem;
          padding-right: 1rem;
          border-bottom: 4px solid transparent;
        }
      }
    }

    &.affix {
      top: 96px;
      &.vatOn{
          top: 127px;
      }
    }
  }

  .business .page-nav-wrap .page-nav a:hover {
    border-bottom: 4px solid @light-sand-brown;
  }
}
@media (min-width: @screen-xl-min) {}
@media (min-width: @screen-sm-min) {
  .business {
    .page-nav-wrap {
      & > .container-fluid {
        background-color: @moonlight-sand;
      }

      .dropdown-toggle {
        .page-nav-main-label {
          color: @white;
        }
      }

      .page-nav {
        background-color: transparent;

        a {
          background-color: transparent;
          color: @white;
        }

        .active a:hover,
        a:hover {
          background-color: transparent;
          color: @white;
        }

        .active a,
        .active a:focus,
        .active a:hover {}
      }
    }
  }
}



@media (max-width: @screen-md-min) {
  .page-nav-wrap.affix {
    top: 50px;
    &.vatOn{
        top: 96px;
    }
  }

  .wst{
    .page-nav-wrap{
      .page-nav-menu-row{
          .dropdown{
            .page-nav{
              top: 10px;
              li{
                a{
                  font-size: 1rem;
                  text-transform:uppercase;
                  font-family: @font-family-base;
                  margin: 0 5px;
                  text-align: center;
                }
              }
            }
          }
      }
    }
  }
}
@media (min-width: @screen-ml-min) {
  .page-nav-wrap.affix {
    top: 96px;
  }
}
@media (min-width: @screen-lg-min) {
  .page-nav-wrap.affix {
    top: 96px;
    &.vatOn{
        top: 127px;
    }
  }
}
@media (width: 1024px) {
  .page-nav-wrap.affix {
    &.vatOn{
        top: 96px;
    }
  }
}
@media (min-width: 664px) and (max-width: 767px) {
  .page-nav-wrap.affix {
    &.vatOn{
        top: 112px;
    }
  }
}
@media (min-width: 461px) and (max-width: 663px) {
  .page-nav-wrap.affix {
    &.vatOn{
        top: 137px;
    }
  }
}
@media (max-width: 460px) {
  .page-nav-wrap.affix {
    &.vatOn{
        top: 159px;
    }
  }
}

html[lang="ar"] {
  *[dir="rtl"] {
    .page-nav-wrap .page-nav {
        justify-content: right;
        float: right;
      .active a,
      .active a:active,
      .active a:focus,
      .active a:focus,
      .active.highlight a,
      a:active {
        &:before {
          right: 0;
          left: auto;
        }
      }
    }

    .business {
      @media (max-width: @screen-sm-min) {
        .page-nav-wrap .page-nav .active a:active,
        .page-nav-wrap .page-nav a,
        .page-nav-wrap .page-nav a:active {
          color: @light-sand-brown;

          &:before {
            position: absolute;
            content: " ";
            width: 4px;
            height: 1.5rem;
            background-color: @light-sand-brown;
            right: 0;
            top: 0.75rem;
            left: auto;
          }
        }
      }
      @media (max-width: @screen-sm-min) {
        .page-nav-wrap .page-nav .active a:active,
        .page-nav-wrap .page-nav a:active {
          color: @light-sand-brown;

          &:before {
            position: absolute;
            content: " ";
            width: 4px;
            height: 1.5rem;
            background-color: @light-sand-brown;
            right: 0;
            top: 0.75rem;
            left: auto;
          }
        }
      }
      @media (min-width: @screen-sm-min) {
        .page-nav-wrap .page-nav .active a:active,
        .page-nav-wrap .page-nav a:active {
          border-left: 0;
          color: @light-sand-brown;

          &:before {
            position: absolute;
            content: " ";
            width: 0;
            height: 0;
            background-color: none;
            right: 0;
            top: 0;
          }
        }

        .page-nav-wrap .page-nav .active a:active,
        .page-nav-wrap .page-nav a:active {
          border-left: 0;
          border-right: 0;
          color: @light-sand-brown;
        }
      }
      @media (min-width: @screen-md-min) {
        .page-nav-wrap .page-nav .active a:active,
        .page-nav-wrap .page-nav a:active {
          border-left: 0;
          border-right: 0;
          color: @light-sand-brown;
          border-bottom-color: #e2e1da;
        }
      }
    }

    .page-nav-wrap {
      .dropdown-toggle {
        .current-dropdown-label {
          padding-right: 2rem;
        }

        #dropdown-menu-arrow-img {
          margin-left: 2rem;
        }
      }

      .page-nav {
        text-align: right;

        .dropdown-close-button {
          a {
            text-align: left;
            font-family: @font-arabic;
          }

          #close-menu-arrow-img {
            margin-left: 0;
          }
        }
      }
      @media (max-width: @screen-sm-min) {
        .page-nav-wrap .page-nav .active a:active,
        .page-nav-wrap .page-nav a:active {
          color: @main-green;

          &:before {
            position: absolute;
            content: " ";
            width: 4px;
            height: 1.5rem;
            background-color: @main-green;
            right: 0;
            top: 0.75rem;
            left: auto;
          }
        }
      }
      @media (min-width: @screen-sm-min) {
        .page-nav-wrap .page-nav .active a:active,
        .page-nav-wrap .page-nav a:active {
          border-left: 0;

          &:before {
            position: absolute;
            content: " ";
            width: 0;
            height: 0;
            background-color: none;
            left: 0;
            top: 0;
          }
        }

        .page-nav-wrap .page-nav .active a:active,
        .page-nav-wrap .page-nav a:active {
          border-left: 0;
          border-right: 0;
        }
      }
      @media (min-width: @screen-md-min) {
        .page-nav-wrap .page-nav .active a:active,
        .page-nav-wrap .page-nav a:active {
          border-left: 0;
          border-right: 0;
        }
      }
    }
    .smart-app-banner {
      .modal-dialog {
        .add-payment-card {
          .icon-popup__header {
            font-family: Neotech-medium, GE SS Two, Arial, sans-serif;
          }
        }
        .add-payment-card-btns {
          .btn {
            span {
              font-family: "Lato", Roboto, Arial, sans-serif !important;
              letter-spacing: 3px !important;
              color: #fff;
            }
          }
          span {
            font-family: Neotech-medium, GE SS Two, Arial, sans-serif;
            a {
              font-family: Neotech-medium, GE SS Two, Arial, sans-serif;
            }
          }
        }
      }
      .forms-default-settings .consumer-register-form .form.modified-form .form-section.inline-checkboxes-wrap .checkbox-agree.checkbox-inline .floating-label {
        font-family: Neotech-medium, GE SS Two, Arial, sans-serif;
        font-weight: 100;
      }
    }
  }
}
// iphone android page-nav portrait view
@media only screen
and (min-device-width : 320px)
and (max-device-width : 667px)
and (orientation : portrait) {
  .page-nav-wrap{
     .page-nav {
       max-height: 399px;
       overflow-y: auto;
     }
  }
}
// iphone android page-nav landscape view
@media only screen
and (min-device-width : 320px)
and (max-device-width : 667px)
and (orientation : landscape) {
  .page-nav-wrap{
     .page-nav {
       max-height: 184px;
       overflow-y: auto;
     }
  }
}

.smart-app-banner {
  &.modal {
    overflow: auto;
    background: rgba(0,0,0,0.6);
    // Ipad portrait view
    @media only screen
    and (min-device-width : 767px)
    and (max-device-width : 1024px)
    and (orientation : portrait) {
      text-align: center;
      padding: 0!important;
      &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -4px;
      }
    }
    // Ipad landscape view
    @media only screen
    and (min-device-width : 767px)
    and (max-device-width : 1024px)
    and (orientation : landscape) {
      text-align: center;
      padding: 0!important;
      &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -4px;
      }
    }
  }
  z-index: 99999;
  .forms-default-settings {
    .consumer-register-form {
      .form.modified-form {
        .form-section {
          &.inline-checkboxes-wrap {
            opacity: 1 !important;
            margin-top: 5px !important;
            padding-right: 0px !important;
            .checkbox-agree {
              &.checkbox-inline {
                margin-left: 0px !important;
                text-align: center;
                .floating-label {
                  z-index: 2;
                  color: #848789;
                  display: inline-block !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .modal-dialog {
    position: relative;
    z-index: 999999;

    // Ipad portrait view
    @media only screen
    and (min-device-width : 767px)
    and (max-device-width : 1024px)
    and (orientation : portrait) {
      display: inline-block;
      text-align: left;
      vertical-align: middle;
    }
    // Ipad landscape view
    @media only screen
    and (min-device-width : 767px)
    and (max-device-width : 1024px)
    and (orientation : landscape) {
      display: inline-block;
      text-align: left;
      vertical-align: middle;
    }
    @media (max-width: 736px) {
      margin: 0 auto;
      height: 100%;
    }
    .modal-content {
      @media (max-width: 736px) {
        height: 100%;
      }
    }
    .add-payment-card {
      background: #f1f1eb;
      padding: 0 20px;
      padding-top: 20px;
      padding-bottom: 0px;
      text-align: center;
      .icon-popup__header {
        color: #353733;
        font-size: 22px;
        text-transform: unset;
        letter-spacing: 0px;
      }
      img {
        max-width: 69%;
        height: auto;
        margin-top: 10px;
      }
    }
    .add-payment-card-btns {
      float: left;
      width: 100%;
      border: none;
      padding: 20px 10%;
      background: #fff;
      .btn {
        width: 100% !important;
        font-size: 0.9rem !important;
        margin-bottom: 10px;
        padding: 0 !important;
      }
      span {
        color: #848789;
        a {
          padding-left: 5px;
          text-decoration: underline;
        }
      }
    }
  }
  .popup-header {
    text-align: center;
    padding: 13px 0px;
    svg {
      width: 100px;
      height: 25px;
    }
  }
  .form .checkbox input[type=checkbox]:checked+label:before,
  .form .checkbox label:before {
    background-size: 58%;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    top: 35%;
    left: -35px;
  }
  .form .checkbox input[type=checkbox]:checked+label:before {
    background-image: url(http://qa-dx.etisalat.ae/en/system/assets/img/forms/icon_checkbox.svg);
    background-size: 100%;
    border: none;
  }
  .form .checkbox label {
    padding-left: 0px;
  }
}
