
.updateAppointment  {
  margin-bottom: 4rem;
  .form-title {
    h3 {
      font-family: Lato,Roboto,Arial,sans-serif;
      letter-spacing: 0;
      color: @dark-gray;
      font-size: 18px;
      border-bottom: solid 2px #719e19;
      display: inline-block;
      padding-bottom:3px;
    }
  }

  .appointment-info {
    background: #f0f1eb;
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
    margin-top: .75rem;
    h3 {
      font-family: Lato,Roboto,Arial,sans-serif;
      letter-spacing: 0;
      font-size: 20px;
      font-weight: 700;
      color: @dark-gray;
      margin: 0;
    }
    p {
      color: @dark-gray;
      margin-bottom: 0;
    }
  }

  .form {
    .form-section {
      >label {
        margin-bottom: 18px;
        font-family: Lato,Roboto,Arial,sans-serif;
        font-weight: 700;
      }
      .floating-label-input,
      .floating-label-select {
        border-radius: 5px;
        margin-bottom: .5rem;
        input[type=text] {
          padding: .8325rem 1.66625rem;
          color: @dark-gray;
        }
      }
      p {
        font-size: 13px;
      }
    }

    .floating-label-input {
      &.floating-label-datepicker {
        input {
    			&.valid {
    				background-image:none;
            ~label {
              top: .78125rem  !important;
              font-size:0.75rem  !important;
            }
    			}
          &.picker__input--active {
            ~label {
              top: .78125rem  !important;
              font-size:0.75rem  !important;
            }
          }
      	}
      	.inner-icon {
      		svg {
      			stroke: @almost-black;
      			*{
      				stroke: @almost-black;
      			}
      		}
      	}
    	.picker{
        z-index: 8;
        .picker__footer {
          display: none;
        }
    		.picker__holder{
    			background: @white;
    			border-top: none;
          min-width: 290px;
    			.picker__wrap{
    				.picker__header{
    					min-height: 50px;
    					margin-top: .25em;
    					border-bottom: solid 1px @sand-white;
    					.picker__nav--next{
    								&:hover {
    										cursor: pointer;
    										color: #000;
    										background:none;
    								}
    								&:before{
    									border-left:0px;
    										width: 24px;
    										height: 24px;
    										background: url(../img/forms/icon-outline-dropdown-up-darkgray.png) no-repeat;
    										border-color: transparent;
    										border-width: 0;
    										background-size:24px;
                        transform: rotate(90deg);
    										*{
    											fill:@dark-gray;
    										}
    								}
    					}
    					.picker__nav--prev{
    								&:hover {
    										cursor: pointer;
    										color: @dark-gray;
    										background:none;
    								}
    								&:before{
    										border-left:0px;
    										width: 24px;
    										height: 24px;
    										background: url(../img/forms/icon-outline-dropdown-down-darkgray.png) no-repeat;
    										border-color: transparent;
    										border-width: 0;
    										background-size:24px;
                        transform: rotate(90deg);
    										*{
    											fill:@dark-gray;
    										}
    								}
    					}
    					select{
    						float: right;
    						border: none;
    						font-size: 14px;
    						-webkit-appearance: none;
    				    -moz-appearance: none;
    				    appearance: none;
    				    cursor:pointer;
    						background: url('../img/forms/icon-outline-dropdown-down-darkgray.png') no-repeat;
    						border-color: transparent;
    						background-color: transparent;
    						background-position: right center !important;
    						background-size:16px;
                border-bottom: solid 1px @almost-black;
    						&:after{
    							width: 16px;
    							height: 16px;
    							position: absolute;
    							right: 0px;
    							top: 0px;
    							border-width: 0;

    						}
    						&.picker__select--year{
    							margin-top: 0px;
                  width: 25%;
    						}
    						&.picker__select--month{
    							width: 40%;
    						}
    					}
    				}
    			}
    			.picker__day{
    				  padding: .4em 0;
    				  font-weight: 400;
    				&:hover {
    			    color: @almost-black;
    					background: @sand-white-50;
    			  }
    			}
    			.picker__weekday{
    				font-family: Lato,Roboto,Arial,sans-serif;
    				font-size: 14px;
    				color: @almost-black;
    				text-transform: uppercase;
    			}
    			.picker__day--highlighted{
    				&:before{
    					display: none;
    				}
    				&:hover {
    			    background: @dark-green;
    			    color: @white;
    					border-color: @dark-green;
    			  }
    			}
    			.picker__day--disabled{
    				background: @sand-white-50;
    				color: @almost-black;
    				border-color: @white;
    				&:hover {
    					background: @sand-white-50;
    					color: @almost-black;
    					border-color: @white;
    			  }
    			}
    		}
        .picker__header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .picker__select--year, .picker__select--month {
            margin: 0 10px !important;
            font-size: 14px;
            option {
              max-height: 200px;
              overflow: auto;
            }
          }
          .picker__select--year {
            order: 2;
            }
            .picker__nav--prev, .picker__nav--next {
              display: block;
              padding: 0;
              width: 24px;
              height: 24px;
              top: 12px;
            }
            .picker__nav--prev {
              left: -7px;
            }
            .picker__nav--next {
              right: -7px;
            }
          }
    	  }
      }
    }
    .inner-icon {
      top: 25%;
    }
    span {
       svg {
        height: 23px;
         width: 23px;
         fill: @dark-gray;
       }
    }

    .checkbox-inside-form {
      border: solid 1px #bcbcbc;
      margin: 0;
      padding: 1.5rem;
      span {
        &.unavalable {
          color: @red;
          float: right;
          padding-right: 2rem;
          font-size: 12px;
        }
      }
      input[type=radio]{
        display: none;
      }
      &:nth-of-type(even) {
        background: #f7f7f7;
      }
      &:nth-child(2) {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border: solid 1px #bcbcbc;
        border-bottom: 0;
      }
      &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border: solid 1px #bcbcbc;
        border-top: 0;
      }
      label {
        padding-left: 0;
        font-family: Lato,Roboto,Arial,sans-serif;
        &:before {
          right: 0;
          left: auto;
        }
      }
    }

    .btn {
      border-radius: 5px;
    }

  }

  .appointmentSuccessWrap {
    display: none;
    margin-top: 5rem;
    .appointmentSuccess {
      background: #def0d8;
      border-radius: 5px;
      padding: 2rem;
      p {
        color: #346234;
        margin: 0;
        strong {
          font-weight: bold;
        }
      }
    }
  }


}

//arabic version of forms
*[lang="ar"] {
  *[dir="rtl"] {

    .updateAppointment  {
      .appointment-info,
      .form-title {
        h3 {
          font-family: Neotech-medium,GE SS Two,Arial,sans-serif;
        }
      }
      .form {
        label {
          font-family: Neotech-medium,GE SS Two,Arial,sans-serif;
        }
        .checkbox-inside-form {
          span {
            &.unavalable {
              float: left;
              padding-left: 2rem;
            }
          }
          label {
            padding-right: 0;
            font-family: Neotech-medium,GE SS Two,Arial,sans-serif;
            &:before {
              left: 0;
              right: auto;
            }
          }
        }
      }
    }

  }
}
