.cart-items-accordion{
  .cart-items-accordion-title{
    cursor: pointer;
    display: flex;
    align-items: stretch;
    div {
      display: flex;
      align-items: center;
    }
    h3{
      margin: 1.5rem 0;
      font-size: 1.45rem;
    }
    .open{
      display: none;
    }
    .cart-items-accordion-status{
      justify-content: flex-end;
      font-size: 0.8125rem;
    }
  }
  .cart-items-accordion-title-open{
    .open{
      display: block;
    }
    .close{
      display: none;
    }
  }
  .cart-items-accordion-contents{
    margin-top: 1rem;
  }
  .bordered{
    border-bottom: 1px solid @sand-white;
    margin-bottom: 1rem;
  }
}

.business{
  .cart-items-accordion{
    .cart-items-accordion-title {
      color: @almost-black;
    }
    .bordered{
      border-color: @cold-gray;
    }
  }
}


.cart-items{
  //background-color: @sand-white;

  @media (max-width: @screen-xs-min) {
    .main-part .price {
      font-size: 2rem;
    }
  }

  &.bg-white{
    background-color: @white;
  }

  &:last-child{
    margin-bottom: 2.188rem;
  }

  .large-content{
    width:100%;
    margin-bottom: 3rem;
    margin-top: 1rem;
  }
  .large-content > div{
    width:50%;
    float: left;
  }

  .cart-badge{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6.125rem 6.125rem 0;
    border-color: transparent @main-green transparent transparent;
    position: absolute;
    top: -1px;
    right: -1px;
  }
  .icon-badge{
    width: 1.625rem;
    height: 1.625rem;
    position: absolute;
    top: 0.8125rem;
    right: 0.8125rem;
    &.shipping-icon{
      width: 2.188em;
      height: 1.875em;
    }
  }

  .cart-item {
    h2 {
      font-size: 1.5rem;
      color: @almost-black;
      margin: 0;
      padding: 0;
      text-align: inherit;
    }
    h3 {
      color: @main-green;
      font-size: 1rem;
      margin: 0 0 2rem 0;
      padding: 0;
    }
    p {
      color: @steel-grey;
      font-size: 1rem;
      margin: 0;
      padding: 0;
      span{
        color: @main-green;
      }
    }
  }

  &.cart-items-F{

    .cart-item {
      position: relative;
    }

    .cart-badge{
      right: 8.33333333%;
      margin: 1px 1px 0 0;
    }
    .icon-badge{
      right: 8.33333333%;
      margin-right: 0.8125rem;
    }

    &:hover .cart-item-photo :before{
      // content: " ";
      // position: absolute;
      // background-color: @main-green;
      // opacity: 0.8;
      // top: 0;
      // height: 100%;
      // left: 0;
      // width: 100%;
      // z-index: 2;
    }

    .main-content{
      padding: 1.25rem 0rem 1.56rem 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    .price-content{
      padding: 1.25rem 5rem 1.56rem 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      min-height: 100%;
      position: relative;
      .detail-info-wrap-pricetag{
        width: auto;
      }
      .other-info{
        text-align: left;
        color: @steel-grey;
        span{
          color: @almost-black;
        }
        a{
          color: @main-green;
          text-decoration: underline;
        }
      }
    }
    .cart-item-photo{
      border-style: solid;
      border-width: 1px 1px 0px 1px;
      border-color: @cold-gray;
      min-height: 14.31rem;
    }
    .cart-item-content{
      border-style: solid;
      border-width: 0px 1px 1px 1px;
      border-color: @cold-gray;
      position: static;
      > .row{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
      }
    }
  }

  &.cart-items-A {
    .cart-item {
      display: block;
      position: relative;
      margin-top: .65rem;
      border: none;
      background-color: @white;
      overflow: hidden;
      max-height: 100rem;
      opacity: 1;
      .row-spinbox {
        flex-wrap: wrap;
        border: solid 1px @cold-gray;
        a.btn-default {
          padding-left: 0;
          padding-top: 1.8rem;
          border: none;
          text-align: left;
          background-color: transparent;
          &:hover{
            color: @almost-black;
          }
          svg {
            height: 11px;
            width: 7px;
            margin-left: 23px;
            transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
            -webkit-transform-origin: center center;
            transform-origin: center center;
            overflow: visible;
            transition: all .3s ease-in-out;
            -webkit-transition: all .3s ease-in-out;
          }
        }
        > .col-sm-6 {
          flex: 1;
        }
        &.collapsed {
          a.btn-default {
            svg {
              transform: rotate(90deg);
              -webkit-transform: rotate(90deg);
              -moz-transform: rotate(90deg);
            }
          }
          .details-container {
            display: none;
          }
        }
      }
      button.close {
        z-index: 1;
        position: absolute;
        width: 1.125rem;
        height: 1.125rem;
        display: block;
        right: 15px;
        top: 15px;
        border: none;
        background-color: transparent;
        padding: 0;
        svg {
          fill: @white;
          width: 1.125rem;
          height: 1.125rem;
          @media (min-width: @screen-md-min) {
            fill: @texts-gray;
          }
        }
      }

      > div {
        //  Row
        display: flex;
        flex-direction: column;
        @media (min-width: @screen-md-min) {
          flex-direction: row;
        }

        > div {
          //  Columns
          &:nth-child(1) {
            //  Column-Cover
            min-height: 175px;
            @media (min-width: @screen-sm-min) {
              min-height: 190px;
              max-height: 370px;
            }
          }
          &:nth-child(2) {
            //  Column-Content
            > div {
              //  Row-Contens
              display: flex;
              flex-direction: column;
              @media (min-width: @screen-sm-min) {
                flex-direction: row;
              }
              @media (max-width: @screen-sm-min) {
                display: block;
              }
              > div {
                &:nth-child(1) {
                  //  Column-Text
                  flex: 1 0 50%;
                  padding: 2.5rem 3.125rem;
                  @media (max-width: @screen-sm-min) {
                    padding: 2.5rem 1.5rem;
                  }
                  h2 {
                    font-size: 1.25rem;
                    color: @almost-black;
                    margin: 0 0 0.625rem 0;
                    padding: 0;
                    text-align: inherit;
                  }
                  h3 {
                    color: @main-green;
                    font-size: 1rem;
                    margin: 0 0 0.625rem 0;
                    padding: 0;
                  }
                  p {
                    color: @steel-grey;
                    font-size: 1rem;
                    margin: 0 0 0.625rem 0;
                    padding: 0;
                  }
                }
                &:nth-child(2) {
                  //  Column-Price
                  padding: 2.5rem 3.125rem;
                  @media (max-width: @screen-sm-min) {
                    padding: 2.5rem 1.5rem;
                  }
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-end;
                  align-items: flex-start;
                  @media (min-width: @screen-sm-min) {
                    align-items: flex-end;
                  }
                  > div {
                    display: flex;
                    justify-content: flex-end;
                    .spinbox {
                      margin-bottom: 1.875rem;
                      input {
                        border-top: solid 1px @cold-gray;
                        border-bottom: solid 1px @cold-gray;
                      }
                    }

                    .detail-info-wrap-pricetag {
                      width: auto;
                    }
                  }
                }
                &:nth-child(3) {
                  padding: 0 3.125rem 2.5rem 3.125rem;
                }
              }

            }

          }
        }
      }

      &.cart-item-closed {
        .transition();
        max-height: 0;
        opacity: 0;
        margin: 0;
      }
    }
  }

  & + .cart-items{
    .cart-item {
      margin-top: 2.188rem;
    }
  }

  .accordion-center-msg{
    padding-top: 4rem;
    p{
      color:@warm-gray;
      margin-bottom: 2rem;
    }
  }

}

.cart-order {
  &:hover .cart-item-photo :before{
    position: static;
    background-color: transparent !important;
  }
  .cart-list {
      position: absolute;
      bottom: 20px;
      left: 0;

      h3 {
          color: black !important;
          margin: 6px 0 !important;
          font-family: lato;
      }
  }
  .main-content {
    justify-content: normal !important;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */

  .cart-items{
    &.cart-items-F{
      .price-content{
        height: 100% !important;
      }
    }
  }
}

@media (min-width: @screen-sm-min) {
  .cart-items{
    &.cart-items-F {
      .cart-badge{
        right: -1px;
        margin: 0;
      }
      .icon-badge{
        margin-right:0;
        right: 0.8125rem;
      }
      .cart-item-content {
        > .row {
          flex-direction: row;
          align-items: stretch;
        }
      }
      .price-content {
        padding: 1.25rem 5rem 1.56rem 0%;
        align-items: flex-end;
        .other-info {
          text-align: right;
        }
      }
    }
  }
}

@media (min-width: @screen-md-min) {
  .cart-items.cart-items-F .cart-badge {
      right: -1px;
      margin: 0;
  }
  .cart-items.cart-items-F .icon-badge {
      margin-right: 0;
      right: 0.8125rem;
  }
  .cart-items {
    &.cart-items-F {
      .cart-badge{
        right: -1px;
        margin: 0;
      }
      .icon-badge{
        margin-right:0;
        right: 0.8125rem;
      }
      .main-content {
        padding: 1.25rem 0rem 1.56rem 3rem;
      }
      .cart-items-box {
        display: flex;
        align-items: stretch;
        position: relative;
      }
      .cart-item-photo{
        border-width: 1px 0px 1px 1px;
      }
      .cart-item-content{
        position: relative;
        border-width: 1px 1px 1px 0;
      }
      .bg-white{
        background-color: @white;

      }
    }
  }
}

@media (max-width: @screen-sm) {
  .cart-order {
    .cart-list {
      position: static !important;
      padding-bottom: 2rem;
    }
  }.cart-item-content {
    .main-content {
      padding-bottom: 0px;
    }
  }
  .cart-items.cart-items-F {
    .main-content {
      padding-bottom:  0px !important;
    }
  }

}

body.business{
  .cart-items{
    background-color: @sand-white;
    &.cart-items-F {
      .other-info{
        a{
          color: @dark-green;
        }
      }
    }
    .cart-badge {
      border-color: transparent @dark-green transparent transparent;
      &.yellow{border-color: transparent @yellow transparent transparent;}
      &.red{border-color: transparent @red transparent transparent;}
    }
    .cart-item{
      border: none;
      p{
        span{
          color: @dark-green;
        }
      }
      > div{
        > div{
          background-color: @white;
          &:nth-child(2){
            > div{
              > div{
                &:nth-child(1){
                  h3{
                    color: @dark-green;
                  }
                }
              }
            }
          }
        }
      }
      .headOffice{
        &.overlay {
            position: absolute;
            bottom: 0;
            background: rgba(242,28,68,0.8);
            z-index: 1;
            width: 100%;
            color: #fff;
            padding: 15px;
            font-family: Neotech,Roboto,Arial,sans-serif;
            font-weight: 400;
            line-height: 1.3;
            text-align: center;
              .main-part {
                  -webkit-box-align: end;
                  -ms-flex-align: end;
                  align-items: flex-end;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                    .price-specs {
                      font-size: 1rem;
                      line-height: 1em;
                      font-family: Neotech,Roboto,Arial,sans-serif;
                      font-weight: 400;
                      -webkit-box-align: end;
                      -ms-flex-align: end;
                      align-items: flex-end;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                  }
                  .price {
                      font-size: 2.2rem;
                      line-height: .8em;
                      padding-right: .125rem;
                      font-family: Neotech,Roboto,Arial,sans-serif;
                      font-weight: 400;
                  }
              }
        }
      }
    }
    .row-spinbox {
      flex-wrap: wrap;
      border: solid 1px @cold-gray;
      a.btn-default {
        padding-left: 0;
        padding-top: 1.8rem;
        border: none;
        text-align: left;
        background-color: transparent;
        &:hover{
          color: @almost-black;
        }
        svg {
          height: 11px;
          width: 7px;
          margin-left: 23px;
          transform: rotate(-90deg);
          -webkit-transform: rotate(-90deg);
          -webkit-transform-origin: center center;
          transform-origin: center center;
          overflow: visible;
          transition: all .3s ease-in-out;
          -webkit-transition: all .3s ease-in-out;
        }
      }
      > .col-sm-6 {
        flex: 1;
      }
      &.collapsed {
        a.btn-default {
          svg {
            transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
          }
        }
        .details-container {
          display: none;
        }
      }
    }
  }
}

*[lang="ar"] {
  *[dir="rtl"] {
    .cart-items{
      &.cart-items-F{
        & .main-content {
            padding: 1.25rem 3rem 1.56rem 0;
        }
        & .cart-item-photo {
          @media (max-width: @screen-ms-max) {
            margin-left: 8.33333333%;
          }
        }
        & .cart-item-content {
            border-width: 1px 0px 1px 1px;
            @media (max-width: 992px) {
              margin-left: 8.33333333%;
              border-width: 1px 1px 1px 1px;
            }
        }
        & .price-content {
            padding: 1.25rem 0 1.56rem 5rem;
            @media (max-width: @screen-ms-max) {
              padding: 1.25rem 1.25rem 1.56rem 0;
            }
        }
      }
      .cart-item{
        button.close{
          right: inherit;
          left: 15px;
        }
      }
    }
  }
}

.cart-items-box.cart-delete-icon {
  .cart-items .cart-item {
    position: absolute;
    width: 100%;
    height: 50px;
  }
}

.product-cart-img {
  img {
    min-width: unset;
    min-height: unset;
    height: 100%;
  }
}
