.ipad-media-cta {
  padding: 0 2rem;
  background-color: @sand-white;
  .media-cta-container.media-cta-container-wh {
    background-color: #EFE6D9;
  }
  img {
    width: 100%;
  }
  .media-cta-wrapper .media-cta-photo {
    background-color: transparent;
  }
  h2 {
    font-size: 1.875rem !important;
    letter-spacing: 4px !important;
  }
  .media-cta-wrapper .media-cta-caption>div {
    flex-direction: column !important;
  }
  .media-cta-wrapper>div, .media-cta-wrapper .media-cta-photo {
    height: 22rem;
    min-height: 0;
  }
  .media-cta-wrapper .media-cta-caption>div>div:nth-child(2) {
    justify-content: flex-start;
  }
  .media-cta-wrapper .media-cta-caption>div>div:nth-child(2) .detail-price-new {
    top: 0;
  }
  .media-cta-wrapper .detail-price-new .price-timing {
    font-size: 1rem;
    line-height: 2rem;
  }
  .price-valute {
    line-height: 1.1rem;
    display: inline-block;
    margin-left: .5rem;
  }
  .detail-price-new span {
    font-family: @headings-font-family;
  }
  .media-cta-caption p {
    font-size: 1rem
  }
  .btn {
    height: 4rem;
    line-height: 4rem;
    border-width: 1px;
  }
  .detail-price-info {
    padding-left: 1.5rem;
  }
}
.ipad-media-cta-01 {
  background-color: @sand-white;
  min-height: 500px;
  > div {
    width: ~"calc(50% - 48px)";
    margin-left: 2rem;
    min-height: 500px;
    height: 500px;
    float: left;
    position: relative;
  }
  .img {
    background-color: @white;
    img {
      width: 100%;
      height: 80%;
      margin: 10% 0;
      object-fit: contain;
    }
  }
  .desc {
    h2, li {
      color: @white;
      z-index: 10;
    }
    h2 {
      margin-bottom: 1.625rem;
    }
    li {
      font-size: 1rem;
      line-height: 1.625rem;
      height: 40px;
    }
    ul {
      padding-left: 1.5rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &.white {
      background-color: @white;
      height: 592px;
      h2 {
        color: @almost-black;
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
      }
      li {
        color: @steel-grey;
        font-size: 1.25rem;
      }
    }
    .desc-small, .img-small {
      height: 280px;
      background-color: @light-sand-brown;
    }
    .desc-small {
      margin-bottom: 2rem;
      h3, p {
        width: 80%;
        padding: 0 1.5rem;
      }
      h3 {
        color: @almost-black;
        font-size: 1.5rem;
      }
      p {
        color: @steel-grey;
        font-size: 1.25rem;
        margin-bottom: 2rem;
      }
    }
    .img-small {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .overlay-black {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,.5);
  }
  .text {
    position: absolute;
    top: 0;
    left: 1.5rem;
  }
}
.ipad-media-cta-02 {
  height: 592px;
}