.ipad-hero-image-00 {
  padding: 0 2rem;
  background-color: @sand-white;
  .hero-image {
    height: 29rem;
    max-height: 39rem;
  }
  .swiper-container .swiper-slide .section-title {
    top: -1.5rem;
  }
  .hero-images-ver-F {
    margin-top: 10%;
  }
  .swiper-container .swiper-slide .price-tag {
    margin-right: 32px;
  }
}
.ipad-hero-image-01 {
  .hero-image {
    height: 39rem;
  }
  .image, img {
    height: 100%;
  }
  img {
    width: 100%;
    object-fit: contain;
  }
}

.ipad-hero-image {
  background-color: @sand-white;
  padding: 32px 32px 0;
  .overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, .2);
  }
  img {
    width: 100%;
  }
  .text-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .ipad-text {
    position: relative;
    display: inline-block;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
  }
  h2, p {
    color: @white;
  }
  h2 {
    font-size: 1.875rem;
    letter-spacing: 3.75px;
    line-height: 2.375rem;
    text-transform: uppercase;
    margin-top: .625rem;
  }
  svg {
    display: inline-block;
    padding-bottom: 1.25rem;
    border-bottom: 1px solid #fff;
  }
}
[lang="ar"] [dir="rtl"] {
  .ipad-text {
    left: auto;
    right: 50%;
    transform: translate(50%,-50%);
  }
}
