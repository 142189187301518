.heading-center {
  margin-bottom: 34px;
  text-align: center;
  @media (min-width: @screen-md-min) {
    margin-bottom: 50px;
  }
  @media (min-width: @screen-lg-min) {
    margin-bottom: 56px;
  }
  h2 {
    margin: 0;
    padding: 0;
    color: @almost-black;
    line-height: 30px;
    font-size: 24px;
    letter-spacing: 0;
    @media (min-width: @screen-sm-min) {
      line-height: 33px;
      font-size: 26px;
    }
    @media (min-width: @screen-md-min) {
      line-height: 38px;
      font-size: 30px;
    }
    @media (min-width: @screen-lg-min) {
      line-height: 44px;
      font-size: 35px;
    }
  }
  h4 {
    margin: 0;
    padding: 0;
    color: @gray-fill;
    line-height: 19px;
    font-size: 16px;
    font-family: Lato,Roboto,Arial,sans-serif;
    letter-spacing: 0;
    text-transform: uppercase;
    margin-bottom: 6px;
    @media (min-width: @screen-md-min) {
      line-height: 18px;
      font-size: 22px;
    }
    @media (min-width: @screen-lg-min) {
      line-height: 20px;
      font-size: 24px;
      margin-bottom: 8px;
    }
  }
}
.added-services-page {
  padding: 56px 0 10px;
}

.tile-card-container {
  margin: 0 -24px;
  .col-margin-set {
    padding: 0 24px;
  }
  .tile-card-single {
    .tile-card {
      input[type=radio] {
        display: none;
      }
      .tile {
        display: block;
        box-sizing: border-box;
        box-shadow: inset 0 0 0 2px @light-sand-brown;
        position: relative;
        .tile-card__front {
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            transition: transform 0.8s;
            top: 0;
            left: 0;
          }
          .flip-icon {
            height: 2.125rem;
            width: 2.125rem;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 99;
            svg {
              height: 1.125rem;
              width: 1.125rem;
              fill: @white;
            }
          }
          .tiles-box {
            position: relative;
            background-color: @white;
            &:after {
              background-color: @main-green !important;
            }
            .cover {
              height: 80px !important;
              img {
                height: auto !important;
                width: auto !important;
              }
            }
            .tiles-box-title {
              position: relative;
              //width: 100%;
              height: 80px;
              padding: 0 0 11px;
              margin: 0 1.625rem;
              display: flex;
              flex-direction: row;
              align-items: flex-end;
              h3,.h3,  h2,.h2{
                display: block;
                height: auto;
                margin: 0;
                padding: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                color: @white;
                font-weight: 900;
                text-align: left;
              }
              h3,.h3{
                font-size: 17px;
                line-height: 22px;
                letter-spacing: 0;
                font-weight: normal;
                flex: 1;
                padding-right: 1rem;
              }
              h2,.h2{
                font-size: 39px;
                font-weight: 500;
                letter-spacing: 3px;
                line-height: 48px;
                position: relative;
                top: 4px;
              }
            }
            .tiles-box-list {
              margin: 0 24px;
              padding: 24px 0;
              height: auto;
              @media (min-width: @screen-sm-min) {
                height: 14.75rem;
              }
              @media (min-width: @screen-md-min) {
                height: 18.75rem;
              }
              .tiles-box-line {
                height: 1.9rem;
                margin-bottom: .5rem;
                position: relative;
                display: flex;
                h6 {
                  &:first-child {
                    flex: 1;
                    padding-right: 1rem;
                  }
                  color: @steel-grey;
                  font-size: 16px;
                  line-height: 32px;
                  margin: 0;
                  padding: 0;
                  text-align: left;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  span {
                    &.tiles-box-number {
                      font-size: 24px;
                      line-height: 32px;
                      color: @almost-black;
                    }
                  }
                }
                .progress {
                  height: 3px;
                  position: absolute;
                  width: 100%;
                  left: 0;
                  bottom: -2px;
                  border-bottom: 1px solid @cold-gray;
                  .progress-bar {
                    background-color: @main-green;
                    height: 3px;
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    top: 0;
                    .sr-only {

                    }
                  }
                }
              }
            }
            .detail-info-wrap {
              transition: all .3s ease-in-out;
              .detail-price-new {
                .top-card-txt {
                   color: @main-green;
                   font-size: 14px;
                   line-height: 18px;
                   letter-spacing: 0;
                   position: relative;
                   top: 4px;
                }
                .main-part {
                  .price {
                    color: @main-green;
                    font-size: 35px;
                    line-height: 44px;
                    letter-spacing: 0;
                  }
                  .price-specs {
                    color: @main-green;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: 0;
                    position: relative;
                    top: -4px;
                  }
                }
              }
            }
            .bottom-text {
              font-size: 14px;
              line-height: 17px;
              text-align: center;
              color: @gray;
              transition: all .3s ease-in-out;
              letter-spacing: 0;
              white-space: pre-wrap;
              text-transform: none;
              margin: 0 24px;
            }
            .read-more {
              background-color: @main-green !important;
              width: 100%;
              overflow: hidden;
              height: 0;
              text-align: center;
              text-transform: uppercase;
              color: @white;
              font-size: @font-size-base;
              letter-spacing: 0.1875rem;
              position: absolute;
              z-index: 1;
              bottom: 0;
              left: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              .transition();
              &:after {
                 content: '';
                 background-color: @main-green;
                 width: 100%;
                 height: 0.75rem;
                 position: absolute;
                 left: 0;
                 bottom: 0;
                 z-index: 0;
               }
              svg {
                &#read-more-triangle-img{
                  position: absolute;
                  top: 0;
                  left: 50%;
                  margin-left: -5.5px;
                  transform: rotate(180deg);
                  fill: @white;
                }
              }
              span {
                font-size: 14px;
                letter-spacing: 2px;
                line-height: 17px;
                text-align: center;
                &.selected-plan {
                  display: none;
                }
              }
            }
            &:hover {
              .read-more {
                height: 3.75rem !important;
              }
              .detail-info-wrap-pricetag {
                @media (min-width: @screen-md-min) {
                  position: relative;
                  top: -40px;
                }
              }
              .bottom-text {
                @media (min-width: @screen-md-min) {
                  position: relative;
                  top: -40px;
                }
              }
            }
          }
      //  }
        .tile-card__back {
          .flip-icon {
            svg {

            }
          }
          .tiles-box {
            &.content {
              p {
                margin: 0;
                padding: 0;
                font-size: 16px;
                line-height: 22px;
                color: @steel-grey;
                &.back-text {

                }
              }
              ul {
                li {
                  font-size: 16px;
                  line-height: 19px;
                  color: @steel-grey;
                }
              }
            }
          }
        }
      }
    }
  }
}
.pakages-extras {
  &.added-services-page {
    .plans-table {
      .row-tiles-table {
        .swiper-container {
          .swiper-wrapper {
            margin: 0px !important;
            @media (min-width: @screen-sm-min) {
              margin: 0 -16px !important;
            }
            @media (min-width: @screen-md-min) {
              margin: 0 -20px !important;
            }
            @media (min-width: @screen-lg-min) {
              margin: 0 -24px !important;
            }
            display: block;
            overflow: hidden;
            .tile-table {
              &.promo {
                padding: 0px;
                margin-bottom: 24px;
                width: 100%;
                @media (min-width: @screen-sm-min) {
                  padding: 0 16px;
                  margin-bottom: 32px;
                  width: 50%;
                }
                @media (min-width: @screen-md-min) {
                  padding: 0 20px;
                  margin-bottom: 40px;
                  width: 33.333333333%;
                }
                @media (min-width: @screen-lg-min) {
                  padding: 0 24px;
                  margin-bottom: 48px;
                }
                .tile-card {
                  .tile {
                    .tiles-box {
                      .cover {
                        height: 100px !important;
                        //position: relative;
                        &:before {
                          content: "";
                          position: absolute;
                          background: rgba(0,0,0,0.5);
                          left: 0;
                          top: 0;
                          width: 100%;
                          height: 100%;
                          z-index: 1;
                        }
                        img {
                          filter:blur(10px);
                        }
                      }
                      .tiles-box-title {
                        height: 100px !important;
                        z-index: 3;
                      }
                    }
                  }
                  .tile-card__back {
                    z-index: 2;
                    .tiles-box {
                      .bottom-text {
                        margin: 0 21px;
                      }
                      ol {
                        height: 11.7rem;
                        @media (min-width: @screen-md-min) {
                          height: 17.1rem;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
/*pakages-details*/
.pakages-details {
  padding: 38px 0;
  @media (min-width: @screen-sm-min) {
    padding: 56px 0 80px;
  }
  .pkges-details-txt {
    p {
      font-size: 16px;
      line-height: 22px;
      margin: 0 0 32px;
      color: @gray-fill;
    }
  }
}
.price-info-box {
  display: inline-block;
  text-align: center;
  margin-bottom: 16px;
  @media (min-width: @screen-sm-min) {
    text-align: left;
    margin-bottom: 0px;
  }
  .top-txt-small{
    font-size: 14px;
    line-height: 18px;
    color: @almost-black;
    font-family: Neotech,Roboto,Arial,sans-serif;
  }
  h3 {
    &.pkg-price-1 {
      display: inline-block;
      font-size: 35px;
      line-height: 44px;
      margin: 0;
      color: @almost-black;
      letter-spacing: 0;
    }
  }
  .pkg-price-2 {
    display: inline-block;
    font-family: Neotech,Roboto,Arial,sans-serif;
    span {
      display: block;
      font-size: 14px;
      line-height: 18px;
      color: @almost-black;
      &.pkg-price-sp-1 {

      }
      &.pkg-price-sp-2 {

      }
    }
  }
}
.points-bar {
  margin: 0 0 58px;
  &.filters {
    #ex2Slider {
      .tooltip {
        .tooltip-inner {
          font-size: 20px;
          line-height: 25px;
          color: @almost-black;
        }
      }
    }
    padding-top: 0;
    .points-bar-col-1 {
      h4 {
        padding-top: 16px;
        margin-bottom: 16px;
        @media (min-width: @screen-sm-min) {
          padding-top: 26px;
          margin-bottom: 0px;
        }
      }
    }
    .points-bar-col-3 {
      padding: 16px 0 0 0px;
      @media (min-width: @screen-sm-min) {
        padding: 16px 0 0 32px;
      }
      h4 {
        &.color-green {
          margin-bottom: 3px;
        }
      }
    }
    h4 {
      font-size: 16px;
      line-height: 20px;
      color: @almost-black;
      letter-spacing: 0;
      margin: 0;
      &.color-green {
        color: @main-green;
      }
    }
  }
}
.spinbox-row {
  padding: 32px 0 0;
  .spinbox-small {
    display: block;
    padding: 8px 0 0;
    font-size: 14px;
    line-height: 17px;
    color: @steel-grey;
    @media (max-width: 767px) {
      text-align: center;
    }
  }
  .spinbox {
    height: auto;
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
    input {
      width: 64px;
      height: 64px;
      color: @almost-black;
      font-size: 20px;
      border-top: 1px solid @main-green;
      border-bottom: 1px solid @main-green;
    }
    .spinminus,
    .spinplus {
      width: 50px;
      height: 64px;
      svg {
        width: 14px;
        height: 14px;
        padding: 1.55rem 1.1rem;
      }
    }
  }
}
/*added-bill-payment-section*/
.heading-with-buttons-small {
  margin: 0 0 32px;
  h2 {
    margin: 0 0 8px;
    font-size: 26px;
    line-height: 33px;
    color: @almost-black;
    letter-spacing: 0;
  }
  p {
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    color: @almost-black;
  }
}
.info-txt-gray {
  color: @steel-grey !important;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
}
.info-txt-green {
  color: @main-green !important;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.added-bill-payment-section {
  padding: 40px 0;
  @media (min-width: @screen-sm-min) {
    padding: 80px 0;
  }
  #addresses {
    @media (max-width: 992px) {
      padding-left: 0;
      padding-right: 0;
    }
    .view-address-row {
      &.row-mob {
        @media (max-width: 767px) {
          overflow: auto;
        }
        .row-mob-scroll {
          @media (max-width: 767px) {
            overflow: scroll;
            width: 550px;
            margin: 0 -15px;
            padding-bottom: 50px;
          }
        }
      }
      .view-addresses-cards {
        .view-card-box {
          width: 248px;
          @media (max-width: 767px) {
            padding: 0 15px;
          }
          @media (min-width: @screen-sm-min) {
            //width: 42.3333%;
            width:33.3333%;
          }
          @media (min-width: @screen-md-min) {
            //width: 33.3333%;
            width: 25%;
          }
          @media (min-width: @screen-lg-min) {
            //width: 23.3333%;
          }
          &.address-open {
            .view-card-txt {
              h3 {
                color: @white !important;
              }
            }
          }
          &.card-amount-bill {
            .view-card {
              border: 1px solid @main-green;
              &.address-open {

              }
              &:after {
                display: none;
              }
              .circle-check-radio {
                input[type=checkbox],
                input[type=radio   ] {
                  + label {
                    &:before {
                      border: 1px solid @main-green;
                    }
                  }
                }
              }
            }
          }
          &.card-pay-etisalat {
            .view-card {
              /*background: @light-gray-3;*/
              &:hover {
                border-color: transparent;
              }
              &:after {
                border-top-color: @almost-black;
              }
              .view-card-txt {
                h3 {
                  color: @cold-gray;
                }
                .circle-check-radio {
                  input[type=checkbox],
                  input[type=radio   ] {
                    + label {
                      &:before {
                        border: 1px solid @cold-gray;
                      }
                    }
                  }
                }
              }
            }
            &.address-open {
              .view-card {
                background: @almost-black;
                .info-txt-green {
                  display: none !important;
                }
              }
            }
          }
          .view-card {
            position: relative;
            margin-bottom: 0px;
            height: 112px;
            @media (min-width: @screen-md-min) {
              margin-bottom: 0px;
            }
            .info-txt-gray {
              position: absolute;
              top: 120px;
              @media (max-width: 767px) {
                //bottom: -42px;
              }
            }
            .info-txt-green {
              position: absolute;
              top: 120px;
              @media (max-width: 767px) {
                //bottom: -42px;
              }
            }
            &:after {
              left: 24px;
            }
            .view-card-txt {
              width: 100%;
              height: 110px;
              padding: 16px 25px 16px 16px;
              display: block;
              @media (max-width: 767px) {
                padding: 16px;
              }
              h3 {
                color: @almost-black;
                letter-spacing: 0;
                font-family: Lato,Roboto,Arial,sans-serif;
                text-transform: none;
                font-size: 18px;
                line-height: 22px;
                @media (min-width: @screen-md-min) {
                  font-size: 20px;
                  line-height: 24px;
                }
              }
              .circle-check-radio {
                input[type=checkbox],
                input[type=radio   ] {
                  + label {
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    padding: 0;
                    &:before {
                      height: 42px;
                      width: 42px;
                      position: absolute;
                      right: 20px;
                      bottom: 20px;
                      left: auto;
                    }
                  }
                  &:checked {
                    +label {
                      &:before {
                        background-image: url(../img/self-care-profile/icon_check_circle_white_64.svg);
                        background-size: 42px 42px;
                        border: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .address-edit-form-box {
    &.address-pay-form-box {
      padding: 40px 0 !important;
      @media (min-width: @screen-md-min) {
        padding: 80px 0 !important;
      }
    }
    .card-edit-inner-heading {
      margin: 0 0 32px;
      h4 {
        margin: 0;
        font-size: 18px;
        line-height: 23px;
        color: @almost-black;
        letter-spacing: 0;
      }
    }
    .points-pay-box {
      p {
        margin: 0px;
        font-size: 16px;
        line-height: 19px;
        color: @gray-fill;
        @media (min-width: @screen-sm-min) {
          margin: 0 0 28px;
        }
      }
    }
    .pay-balance-with-box {
      h4 {
        margin: 0 0 24px;
        font-size: 18px;
        line-height: 23px;
        color: @almost-black;
        letter-spacing: 0;
      }
    }
  }
}
.inner-payment-cards {
  margin: 0;
  @media (min-width: @screen-md-min) {
    margin: 0 -16px;
  }
  .pay-col {
    padding: 0;
    margin-bottom: 16px;
    @media (min-width: @screen-md-min) {
      padding: 0 16px;
      margin-bottom: 0px;
    }
    .paymnet-card-chk {
      background: @white;
      opacity: 0.6;
      position: relative;
      padding: 25px 16px 16px;
      .pay-card-icon {
        margin: 0 0 15px;
        svg {

        }
      }
      .pay-card-num {
        font-size: 16px;
        line-height: 19px;
        color: @cold-gray;
        margin-bottom: 4px;
      }
      .pay-card-name {
        font-size: 16px;
        line-height: 19px;
        color: @cold-gray;
      }
      &.pay-card-active {
        background: @main-green;
        opacity: 1;
        .pay-card-icon {
          svg {
            fill: @white;
          }
        }
        .pay-card-num,
        .pay-card-name {
          color: @white;
        }
      }
      .circle-check-radio {
        input[type=checkbox],
        input[type=radio   ] {
          + label {
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            padding: 0;
            &:before {
              height: 35px;
              width: 35px;
              position: absolute;
              right: 18px;
              bottom: 18px;
              left: auto;
            }
          }
          &:checked {
            +label {
              &:before {
                background-image: url(../img/self-care-profile/icon_check_circle_white_64.svg);
                background-size: 35px 35px;
                border: 0;
              }
            }
          }
        }
      }
    }
  }
}
.add-payment-footer {
  background: @dark-gray;
  padding: 22px 0;
  display: none;
  #showPkgPrice,
  #showPoints {
    display: none;
  }
  .price-info-box {
    .top-txt-small {
      color: @white;
    }
    h3 {
      &.pkg-price-1 {
        color: @white;
        span {
          font-size: 14px;
          line-height: 18px;
          color: @white;
        }
      }
    }
    .pkg-price-2 {
      span {
        color: @white;
      }
    }
  }
  &.unsub-payment-footer {
    display: block !important;
    .btn.btn.disabled {
      //background: @cold-gray;
    }
    #showPkgPrice {
      display: block;
    }
    .terms-txt {
      display: inline-block;
      color: @white;
      margin: 0;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      @media (min-width: @screen-sm-min) {
        margin-right: 16px;
        text-align: right;
        float: left;
        width: 45%;
      }
      @media (min-width: @screen-md-min) {
        margin-right: 32px;
        width: 56%;
        padding-top: 2%;
      }
      @media (min-width: 1280px) {
        margin-right: 32px;
        padding-top: 3%;
        width: 66%;
      }
      a {
        color: @main-green;
        text-decoration: none;
      }
    }
  }
}
.extra-terms-data {
  margin-bottom: 0px;
  padding-top: 16px;
  @media (min-width: @screen-sm-min) {
    margin-bottom: 48px;
    padding-top: 32px;
  }
  h2 {
    margin: 0 0 16px;
    padding: 0;
    color: @almost-black;
    line-height: 33px;
    font-size: 26px;
    letter-spacing: 0;
  }
  ol {
    padding: 0 0 0px 16px;
    margin: 0;
    //list-style: none;
    li {
      color: @gray-fill;
      line-height: 22px;
      font-size: 16px;
      margin-bottom: 3px;
    }
  }
}
.pakages-extra-details {
  .spinbox-row {
    .spinbox-small {
      @media (max-width: 767px) {
        margin-bottom: 32px;
      }
    }
  }
}
.btn.btn-danger {
  background: @red-dark;
  border: 0;
  box-shadow: 0 0 0;
  font-size: 0.9rem;
  height: 4rem;
  line-height: 4.2rem;

  @media (max-width: 992px) {
    //width: 100%;
  }
}
.unsub-info {
  font-size: 14px;
  line-height: 17px;
  float: right;
  width: 240px;
  text-align: left;
  color: @steel-grey;
  margin: 0;
  padding-top: 8px;
  @media (max-width: 767px) {
    width: 100%;
    text-align: center !important;
  }
}
.unsub-payment-section {
  &.added-bill-payment-section {
    #addresses {
      .view-address-row {
        &.row-mob {
          @media (max-width: 767px) {
            //overflow: auto;
          }
          .row-mob-scroll {
            @media (max-width: 767px) {
              //overflow: scroll;
              //width: 550px;
            //  margin: 0 -15px;
            }
          }
        }
        .view-addresses-cards {
          .view-card-box {
          //  width: 248px;
            @media (max-width: 767px) {
            //  padding: 0 15px;
            }
            @media (min-width: @screen-sm-min) {
              //width: 42.3333%;
            }
            @media (min-width: @screen-md-min) {
            //  width: 33.3333%;
            }
            @media (min-width: @screen-lg-min) {
              //width: 23.3333%;
            }
            .view-card {
              h3 {
                //font-size: 18px;
              //  line-height: 22px;
                @media (min-width: @screen-md-min) {
                //  font-size: 20px;
                //  line-height: 24px;
                }
              }
              .view-card-txt {
                @media (max-width: 767px) {
                  //padding: 16px;
                }
              }
              .info-txt-green,
              .info-txt-gray {
                @media (max-width: 767px) {
                  //bottom: -42px;
                }
              }
            }
            &.card-pay-etisalat {
              .view-card {
                //background: @white;
                //border: @main-green;
                .view-card-txt {
                  h3 {
                    //color: @almost-black;
                  }
                }
              }
            }
            &.card-pay-etisalat {
              .view-card {
                background: @white;
                border-color: @main-green;
                &:hover {
                  border-color: @main-green;
                }
                &:after {
                  border-top-color: @almost-black;
                }
                .view-card-txt {
                  h3 {
                    color: @almost-black;
                  }
                  .circle-check-radio {
                    input[type=checkbox],
                    input[type=radio   ] {
                      + label {
                        &:before {
                          border: 1px solid @main-green;
                        }
                      }
                    }
                  }
                }
              }
              &.address-open {
                .view-card {
                  background: @almost-black;
                  border: 0px;
                  .info-txt-green {
                    display: none !important;
                  }
                  .view-card-txt {
                    .circle-check-radio {
                      input[type=checkbox],
                      input[type=radio   ] {
                        + label {
                          &:before {
                            border: 0px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.pkges-details-2-heading {
  h3 {
    font-size: 20px;
    line-height: 25px;
    margin: 0 0 8px;
    color: @almost-black;
    letter-spacing: 0;
    @media (min-width: @screen-sm-min) {
      font-size: 26px;
      line-height: 33px;
      margin: 0 0 24px;
    }
  }
}
.pkges-details-2-table-txt {
  > div {
    &:first-child {
      padding-right: 6%;
    }
  }
  p {
    font-size: 16px;
    line-height: 22px;
    margin: 0px;
    color: @steel-grey;
  }
  .table-responsive {
    border: 0 !important;
    margin: 0 !important;
    table {
      &.table-with-borders {
        width: 100%;
        border: 0;
        border-bottom: 1px solid @light-sand-brown;
        tr {
          @media (max-width: 767px) {
            border-top: 1px solid @light-sand-brown;
          }
          td {
            border: 0;
            font-size: 16px;
            line-height: 19px;
            padding: 14px 0;
            color: @steel-grey;
            @media (min-width: @screen-sm-min) {
              border-top: 1px solid @light-sand-brown;
            }
            @media (max-width: 767px) {
              float: left;
              width: 100%;
              display: block;
            }
            &:first-child {
              color: @almost-black;
              width: 30%;
              @media (max-width: 767px) {
                width: 100%;
                padding: 14px 0 0;
              }
            }
            b {
              color: @almost-black;
            }
            &.t-amount-green {
                @media (min-width: @screen-lg-min) {

                }
            }
          }
        }
      }
    }
  }
}
.popup-heading-inner {
  color: @almost-black;
  font-size: 22px;
  line-height: 28px;
  margin: 0 0 20px;
  letter-spacing: 0;
  @media (min-width: @screen-md-min) {
    margin: 0 0 32px;
    font-size: 26px;
    line-height: 32px;
  }
  @media (min-width: @screen-lg-min) {
    margin: 0 0 64px;
    font-size: 30px;
    line-height: 38px;
  }
}
.set-limit-form {
  &:before,
  &:after {
    display: none !important;
  }
  >* {
    opacity: 1 !important;
    button {
      pointer-events: all !important;
    }
  }
}
.bill-inner-box {
  h5 {
    color: @almost-black;
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 16px;
    letter-spacing: 0;
    @media (min-width: @screen-sm-min) {
      font-size: 18px;
      line-height: 22px;
      margin: 0 0 32px;
    }
  }
  ul {
    &.bill-list {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        float: left;
        margin-right: 14px;
        @media (min-width: @screen-lg-min) {
          margin-right: 24px;
        }
      }
    }
  }
  .limit-for-all {
    padding-top: 16px;
    @media (min-width: @screen-sm-min) {
      padding-top: 32px;
    }
    .circle-check-radio {
      input[type=checkbox],
      input[type=radio   ] {
        + label {
          font-size: 14px;
          line-height: 17px;
          display: block;
          color: @almost-black;
          font-weight: normal;
          padding-left: 32px;
          &:before {
            height: 21px;
            width: 21px;
          }
        }
        &:checked {
          + label {

            &:before {
              background-size:  22px 22px;
            }
          }
        }
      }
    }
  }
  .info-small {
    font-size: 14px;
    line-height: 17px;
    color: @steel-grey;
    margin: 0;
    padding: 10px 0 0;
  }
}
.update-id-modal {
  .modal-dialog {
    .modal-content {
      min-height: 100%;
      height: auto !important;
      .main-title {
        padding: 2rem 0 1rem;
        @media (min-width: @screen-sm-min) {
          padding: 3rem 0;
        }
      }
      .modal-header {

      }
    }
  }
}
.bill-limit-btn {
  padding-top: 20px;
  @media (min-width: @screen-lg-min) {
    padding-top: 40px;
  }

  .c-bill-limit {
    display: none;
    font-size: 14px;
    line-height: 18px;
    color: @red-dark;
    padding: 10px 20px 0 0px;
    vertical-align: middle;
    span {
      position: relative;
      top: -6px;
      text-decoration: underline;
    }
  }
}
.limit-check-box {
  input[type=checkbox],
  input[type=radio   ] {
    display: none;
    + label {
      cursor: pointer;
      display: block;
      width: 100%;
      font-size: 18px;
      line-height: 33px;
      padding: 8px 14px;
      background: @white;
      text-align: center;
      font-weight: normal;
      @media (min-width: @screen-sm-min) {
        padding: 10px 16px;
      }
      @media (min-width: @screen-lg-min) {
        padding: 11px 30px;
      }
    }
    &:checked {
      + label {
        background: @main-green;
        color: @white;
      }
    }
  }
}
#billLimitError {
  &.popup-wrapper {
    &.modal {
      background: rgba(0, 0, 0, 0.5);
      .modal-dialog {
        .modal-content {
          .main-title {
            padding: 2rem 0;
          }
          .modal-header {
            padding: 2rem 0;
          }
        }
        .delete-card {
          padding: 48px 48px 20px;
          .icon-popup__icon {
            img {
              max-width: 150px;
            }
            margin-bottom: 24px;
          }
          .icon-popup__header {
            line-height: 30px;
            margin-bottom: 16px;
            margin-top: 0;
          }
          .icon-popup__text {
            p {
              font-size: 14px;
              line-height: 17px;
              margin-bottom: 16px;
              &.text-danger {
                margin-bottom: 8px;
              }
            }
          }
        }
        .icon-popup__button {
          &.delete-card-btns {
            .btn {
              width: 100%;
              color: @white;
              &:hover {
                color: @white;
                background-color: #620d07;
                border-color: #240502;
              }
            }
          }
        }
      }
    }
  }
}
.text-danger {
  color: @red-dark !important;
}
#setBillLimit {
  &.update-id-modal {
    .modal-dialog {
      .modal-content {
        .modal-header {
          padding: 0 0 1rem;
          @media (min-width: @screen-md-min) {
            padding: 0 0 2rem;
          }
          @media (min-width: @screen-lg-min) {
            padding: 3rem 0;
          }
        }
      }
    }
  }
}
*[lang="ar"] {
  *[dir="rtl"] {
    .pakages-extras {
      &.added-services-page {
        .plans-table {
          .tile-table {
            float: right;
          }
          &.plans-table-A {
            .tile-table {
              &.promo {
                .tile {
                  .tile-card__back {
                    .tiles-box {
                      ol {
                        text-align: right;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .heading-with-buttons-small {
      h2 {
        text-align: right;
      }
    }
    .added-bill-payment-section {
      @media (min-width: @screen-sm-min) {
      }
      #addresses {
        @media (max-width: 992px) {
        }
        .view-address-row {
          .view-addresses-cards {
            .view-card-box {
              &.address-open {
                .view-card-txt {
                  h3 {
                  }
                }
              }
              &.card-amount-bill {
                .view-card {
                  &.address-open {

                  }
                  &:after {
                  }
                  .circle-check-radio {
                    input[type=checkbox],
                    input[type=radio   ] {
                      + label {
                        &:before {
                        }
                      }
                    }
                  }
                }
              }
              &.card-pay-etisalat {
                .view-card {
                  &:hover {
                  }
                  &:after {
                  }
                  .view-card-txt {
                    h3 {
                    }
                    .circle-check-radio {
                      input[type=checkbox],
                      input[type=radio   ] {
                        + label {
                          &:before {
                          }
                        }
                      }
                    }
                  }
                }
                &.address-open {
                  .view-card {
                    .info-txt-green {
                    }
                  }
                }
              }
              .view-card {
                @media (min-width: @screen-md-min) {
                }
                .info-txt-gray {
                }
                .info-txt-green {
                }
                &:after {
                  right: 24px;
                  left: auto;
                }
                .view-card-txt {
                  padding: 16px 16px 16px 60px;
                  display: block;
                  h3 {
                  }
                  .circle-check-radio {
                    input[type=checkbox],
                    input[type=radio   ] {
                      + label {
                        &:before {
                          right: auto;
                          left: 20px;
                        }
                      }
                      &:checked {
                        +label {
                          &:before {
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .address-edit-form-box {
        &.address-pay-form-box {
          @media (min-width: @screen-md-min) {
          }
        }
        .card-edit-inner-heading {
          h4 {
          }
        }
        .points-pay-box {
          p {
            @media (min-width: @screen-sm-min) {
            }
          }
        }
        .pay-balance-with-box {
          h4 {
          }
        }
      }
    }
    .price-info-box {
      @media (min-width: @screen-sm-min) {
          text-align: right;
      }
    }
    .points-bar {
      &.filters {
        #ex2Slider {
          .tooltip {
            .tooltip-inner {
            }
          }
        }
        .points-bar-col-1 {
          h4 {
            @media (min-width: @screen-sm-min) {
            }
          }
        }
        .points-bar-col-3 {
          @media (min-width: @screen-sm-min) {
            padding: 16px 32px 0 0px;
          }
          h4 {
            &.color-green {
            }
          }
        }
        h4 {
          &.color-green {
          }
        }
      }
    }
    //
    .inner-payment-cards {
      @media (min-width: @screen-md-min) {
      }
      .pay-col {
        @media (min-width: @screen-md-min) {
        }
        .paymnet-card-chk {
          .pay-card-icon {
            svg {

            }
          }
          .pay-card-num {
          }
          .pay-card-name {
          }
          &.pay-card-active {
            .pay-card-icon {
              svg {
              }
            }
            .pay-card-num,
            .pay-card-name {
            }
          }
          .circle-check-radio {
            input[type=checkbox],
            input[type=radio   ] {
              + label {
                &:before {
                  left: 18px;
                  right: auto;
                }
              }
              &:checked {
                +label {
                  &:before {
                  }
                }
              }
            }
          }
        }
      }
    }
    .unsub-info {
      float: left;
      text-align: right;
    }
    .unsub-payment-section {
      &.added-bill-payment-section {
        #addresses {
          .view-address-row {
            .view-addresses-cards {
              .view-card-box {
                &.card-pay-etisalat {
                  .view-card {
                    //background: @white;
                    //border: @main-green;
                    .view-card-txt {
                      h3 {
                        //color: @almost-black;
                      }
                    }
                  }
                }
                &.card-pay-etisalat {
                  .view-card {
                    background: @white;
                    border-color: @main-green;
                    &:hover {
                      border-color: @main-green;
                    }
                    &:after {
                      border-top-color: @almost-black;
                    }
                    .view-card-txt {
                      h3 {
                        color: @almost-black;
                      }
                      .circle-check-radio {
                        input[type=checkbox],
                        input[type=radio   ] {
                          + label {
                            &:before {
                              border: 1px solid @main-green;
                            }
                          }
                        }
                      }
                    }
                  }
                  &.address-open {
                    .view-card {
                      background: @almost-black;
                      border: 0px;
                      .info-txt-green {
                        display: none !important;
                      }
                      .view-card-txt {
                        .circle-check-radio {
                          input[type=checkbox],
                          input[type=radio   ] {
                            + label {
                              &:before {
                                border: 0px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .unsub-payment-footer {
      .terms-txt {
        //margin-right: 32px;
        @media (min-width: @screen-sm-min) {
          margin-right: 0px;
          margin-left: 16px;
          text-align: left;
        }
        @media (min-width: @screen-md-min) {
          margin-right: 0px;
          margin-left: 32px;
        }
        @media (min-width: @screen-lg-min) {
          margin-right: 0px;
          margin-left: 32px;
        }
      }
    }
    .bill-inner-box {
      ul {
        &.bill-list {
          li {
            float: right;
            margin-left: 14px;
            margin-right: 0;
            @media (min-width: @screen-lg-min) {
              margin-left: 24px;
              margin-right: 0;
            }
          }
        }
      }
    }
    .limit-for-all {
      .circle-check-radio {
        input[type=checkbox],
        input[type=radio   ] {
          + label {
            padding-right: 32px;
            padding-left: 0;
            &:before {
              left: auto;
              right: 0;
            }
          }
        }
      }
    }
   }//Arabic End
 }


* {
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
svg {
  &:not(:root) {
    overflow: hidden;
  }
}
input {
  color: inherit;
  font: inherit;
  margin: 0;
  line-height: normal;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: Lato,Roboto,Arial,sans-serif;
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
  margin: 4px 0 0;
  margin-top: 1px\9;
  line-height: normal;
  &:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
  &:checked+label {
    &:before {
      background-image: url(../../assets/img/self-care-profile/icon_checkbox_radio_selected_dark_64.svg);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 32px 32px;
      border: none;
    }
  }
}
&:after {
  box-sizing: border-box;
}
&:before {
  box-sizing: border-box;
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
  &:before {
    content: " ";
    display: table;
  }
}
.row {
  margin-left: 0;
  margin-right: 0;
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
  &:before {
    content: " ";
    display: table;
  }
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  font-family: Lato,Roboto,Arial,sans-serif;
}
.clearfix {
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
  &:before {
    content: " ";
    display: table;
  }
}
.hide {
  display: none !important;
}
.filters {
  padding-top: 2.875rem;
  #ex2Slider {
    float: right;
    width: 100%;
    margin-top: 1.875rem;
    .slider-track {
      height: 2px;
      background: #719e19;
      width: 100%;
      .slider-handle {
        background: #719e19;
        width: 20px;
        height: 20px;
        margin-top: -8.25px;
        margin-left: 0;
        position: relative;
        &:after {
          content: "";
          border-color: transparent transparent #f1f1eb;
          border-width: 0 .375rem .375rem;
          border-style: solid;
          height: 0;
          left: 50%;
          margin-left: -6px;
          margin-top: -4px;
          position: absolute;
          top: 50%;
          width: 0;
        }
      }
    }
    .slider-selection {
      background: #719e19;
      height: 2px;
    }
    .tooltip {
      position: absolute;
      margin-top: -39px;
      .tooltip-inner {
        top: 0;
        left: 0;
        position: absolute;
        font-size: .8rem;
        font-family: Lato,Roboto,Arial,sans-serif;
      }
    }
  }
}
#addresses {
  .address-edit-form-box {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 2;
    display: none;
    background: #e1e0d9;
    margin-bottom: 150px !important;
  }
  .view-address-row {
    .view-addresses-cards {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      .view-card-box {
        padding: 0 12px;
        position: static;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 33.3333%;
        .view-card {
          background: #fff;
          width: 100%;
          border: 1px solid transparent;
          margin-bottom: 24px;
          display: table;
          cursor: pointer;
          &:hover {
            border: 1px solid #393c3a;
          }
          .view-card-txt {
            display: table-cell;
            text-align: left;
            padding-left: 24px;
            padding-right: 10px;
            vertical-align: middle;
            h3 {
              color: #353738;
              font-size: 18px;
              margin-top: 0;
              margin-bottom: 3px;
              text-transform: capitalize;
              letter-spacing: 0;
              line-height: 23px;
              text-align: left;
            }
          }
        }
      }
      .view-card-box.address-open {
        .view-card {
          background: #393c3a;
          position: relative;
          &:after {
            bottom: -13px;
            left: 125px;
            border: solid transparent;
            content: " ";
            height: 12px;
            width: 24px;
            position: absolute;
            pointer-events: none;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-top: 15px solid #393c3a;
          }
          h3 {
            color: #fff;
          }
        }
      }
    }
  }
}
.circle-check-radio {
  input[type=checkbox] {
    display: none;
    &:checked+label {
      &:before {
        background-image: url(../../assets/img/self-care-profile/icon_checkbox_radio_selected_dark_64.svg);
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 25px 25px;
        border: none;
      }
    }
  }
  input[type=checkbox]+label {
    margin: 0;
    cursor: pointer;
    position: relative;
    padding-left: 24px;
    font-size: 1rem;
    letter-spacing: 0;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1.4rem;
    font-family: Lato,Roboto,Arial,sans-serif;
    &:before {
      display: inline-block;
      content: "";
      border-radius: 50%;
      height: 24px;
      width: 24px;
      border: 1px solid #6b6b6b;
      position: absolute;
      left: 0;
    }
  }
}
.points-bar {
  margin: 0 0 58px;
}
.points-bar.filters {
  padding-top: 0;
  #ex2Slider {
    .tooltip {
      .tooltip-inner {
        font-size: 20px;
        line-height: 25px;
        color: #353738;
      }
    }
  }
  .points-bar-col-1 {
    h4 {
      padding-top: 16px;
      margin-bottom: 16px;
    }
  }
  .points-bar-col-3 {
    padding: 16px 0 0;
    h4.color-green {
      margin-bottom: 3px;
    }
  }
  h4 {
    font-size: 16px;
    line-height: 20px;
    color: #353738;
    letter-spacing: 0;
    margin: 0;
  }
  h4.color-green {
    color: #719e19;
  }
}
.info-txt-gray {
  color: #848789 !important;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
}
.added-bill-payment-section {
  #addresses {
    .view-address-row {
      .view-addresses-cards {
        .view-card-box {
          width: 248px;
          .view-card {
            position: relative;
            margin-bottom: 0;
            height: 112px;
            .info-txt-gray {
              position: absolute;
              top: 120px;
            }
            &:after {
              left: 24px;
            }
            .view-card-txt {
              width: 100%;
              height: 110px;
              padding: 16px 25px 16px 16px;
              display: block;
              h3 {
                color: #353738;
                letter-spacing: 0;
                font-family: Lato,Roboto,Arial,sans-serif;
                text-transform: none;
                font-size: 18px;
                line-height: 22px;
              }
              .circle-check-radio {
                input[type=checkbox]+label {
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  padding: 0;
                  &:before {
                    height: 42px;
                    width: 42px;
                    position: absolute;
                    right: 20px;
                    bottom: 20px;
                    left: auto;
                  }
                }
                input[type=checkbox] {
                  &:checked+label {
                    &:before {
                      background-image: url(../../assets/img/self-care-profile/icon_check_circle_white_64.svg);
                      background-size: 42px 42px;
                      border: 0;
                    }
                  }
                }
              }
            }
          }
        }
        .view-card-box.address-open {
          .view-card-txt {
            h3 {
              color: #fff !important;
            }
          }
        }
        .view-card-box.card-amount-bill {
          .view-card {
            border: 1px solid #719e19;
            &:after {
              display: none;
            }
            .circle-check-radio {
              input[type=checkbox]+label {
                &:before {
                  border: 1px solid #719e19;
                }
              }
            }
          }
        }
        .view-card-box.card-pay-etisalat {
          .view-card {
            background: #f5f4f5;
            &:hover {
              border-color: transparent;
            }
            &:after {
              border-top-color: #353738;
            }
            .view-card-txt {
              h3 {
                color: #d1d2d1;
              }
              .circle-check-radio {
                input[type=checkbox]+label {
                  &:before {
                    border: 1px solid #d1d2d1;
                  }
                }
              }
            }
          }
        }
        .view-card-box.card-pay-etisalat.address-open {
          .view-card {
            background: #353738;
          }
        }
      }
    }
  }
  .address-edit-form-box.address-pay-form-box {
    padding: 40px 0 !important;
  }
  .address-edit-form-box {
    .card-edit-inner-heading {
      margin: 0 0 32px;
      h4 {
        margin: 0;
        font-size: 18px;
        line-height: 23px;
        color: #353738;
        letter-spacing: 0;
      }
    }
    .points-pay-box {
      p {
        margin: 0;
        font-size: 16px;
        line-height: 19px;
        color: #6b6b6b;
      }
    }
    .pay-balance-with-box {
      h4 {
        margin: 0 0 24px;
        font-size: 18px;
        line-height: 23px;
        color: #353738;
        letter-spacing: 0;
      }
    }
  }
}
.inner-payment-cards {
  margin: 0;
  .pay-col {
    padding: 0;
    margin-bottom: 16px;
    .paymnet-card-chk {
      background: #fff;
      opacity: .6;
      position: relative;
      padding: 25px 16px 16px;
      .pay-card-icon {
        margin: 0 0 15px;
      }
      .pay-card-num {
        font-size: 16px;
        line-height: 19px;
        color: #d1d2d1;
        margin-bottom: 4px;
      }
      .pay-card-name {
        font-size: 16px;
        line-height: 19px;
        color: #d1d2d1;
      }
      .circle-check-radio {
        input[type=checkbox]+label {
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          padding: 0;
          &:before {
            height: 35px;
            width: 35px;
            position: absolute;
            right: 18px;
            bottom: 18px;
            left: auto;
          }
        }
        input[type=checkbox] {
          &:checked+label {
            &:before {
              background-image: url(../../assets/img/self-care-profile/icon_check_circle_white_64.svg);
              background-size: 35px 35px;
              border: 0;
            }
          }
        }
      }
    }
    .paymnet-card-chk.pay-card-active {
      background: #719e19;
      opacity: 1;
      .pay-card-icon {
        svg {
          fill: #fff;
        }
      }
      .pay-card-name {
        color: #fff;
      }
      .pay-card-num {
        color: #fff;
      }
    }
  }
}
.unsub-payment-section.added-bill-payment-section {
  #addresses {
    .view-address-row {
      .view-addresses-cards {
        .view-card-box.card-pay-etisalat {
          .view-card {
            background: #fff;
            border-color: #719e19;
            &:hover {
              border-color: #719e19;
            }
            &:after {
              border-top-color: #353738;
            }
            .view-card-txt {
              h3 {
                color: #353738;
              }
              .circle-check-radio {
                input[type=checkbox]+label {
                  &:before {
                    border: 1px solid #719e19;
                  }
                }
              }
            }
          }
        }
        .view-card-box.card-pay-etisalat.address-open {
          .view-card {
            background: #353738;
            border: 0;
            .view-card-txt {
              .circle-check-radio {
                input[type=checkbox]+label {
                  &:before {
                    border: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width:768px) {
  .filters {
    margin-bottom: 3.125rem;
    padding-top: 4rem;
  }
  .points-bar.filters {
    .points-bar-col-1 {
      h4 {
        padding-top: 26px;
        margin-bottom: 0;
      }
    }
    .points-bar-col-3 {
      padding: 16px 0 0 32px;
    }
  }
  .added-bill-payment-section {
    #addresses {
      .view-address-row {
        .view-addresses-cards {
          .view-card-box {
            width: 33.3333%;
          }
        }
      }
    }
    .address-edit-form-box {
      .points-pay-box {
        p {
          margin: 0 0 28px;
        }
      }
    }
  }
}
@media (min-width:992px) {
  .filters {
    margin-bottom: 3.125rem;
    padding-top: 4rem;
  }
  .added-bill-payment-section {
    #addresses {
      .view-address-row {
        .view-addresses-cards {
          .view-card-box {
            width: 25%;
            .view-card {
              margin-bottom: 0;
              .view-card-txt {
                h3 {
                  font-size: 20px;
                  line-height: 24px;
                }
              }
            }
          }
        }
      }
    }
    .address-edit-form-box.address-pay-form-box {
      padding: 80px 0 !important;
    }
  }
  .inner-payment-cards {
    margin: 0 -16px;
    .pay-col {
      padding: 0 16px;
      margin-bottom: 0;
    }
  }
}
@media (min-width:1440px) {
  .filters {
    margin-bottom: 0;
    padding-top: 5rem;
  }
}
@media (max-width:992px) {
  #addresses {
    .address-edit-form-box {
      padding: 0 32px;
    }
    .view-address-row {
      .view-addresses-cards {
        .view-card-box {
          width: 100%;
          .view-card {
            .view-card-txt {
              padding-left: 40px;
            }
          }
        }
      }
    }
  }
}
@media (max-width:1199px) {
  #addresses {
    .view-address-row {
      .view-addresses-cards {
        .view-card-box {
          width: 50%;
        }
      }
    }
  }
}
@media (max-width:767px) {
  #addresses {
    .view-address-row {
      .view-addresses-cards {
        .view-card-box {
          width: 100%;
          padding: 0;
          .view-card {
            margin-bottom: 16px;
            .view-card-txt {
              padding-left: 16px;
            }
          }
        }
      }
    }
  }
  .added-bill-payment-section {
    #addresses {
      .view-address-row.row-mob {
        .row-mob-scroll {
          overflow: scroll;
          width: 550px;
          margin: 0 -15px;
          padding-bottom: 50px;
        }
      }
      .view-address-row {
        .view-addresses-cards {
          .view-card-box {
            padding: 0 15px;
            .view-card {
              .view-card-txt {
                padding: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.position-static {
    position: static!important;
}
