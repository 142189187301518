.bg-white-filler {
  background-color: @white !important;
}

.bg-light-filler {
  background-color: @sandy-white;
}

.bg-lighter-filler {
  background-color: @sand-white !important;
}

.bg-lighty-filler {
  background-color: @sand-white-50 !important;
}

.bg-dark-filler {
  background-color: @moonlight-sand;
}

// adding background colors with opacity for price-label
.main-green-opc-80{
  background-color: rgba(113, 158, 25, 0.8) !important;
}
.pink-opc-80{
  background-color: rgba(251, 25, 63, 0.8) !important;
}
.dark-orange-opc-80{
  background-color: rgba(255, 128, 0, 0.8) !important;
}
.gold-top-opc-80{
  background-color: rgba(255, 204, 0, 0.8) !important;
}
.dark-gray-opc-80{
  background-color: rgba(36, 36, 36, 0.8) !important;
}
.white-opc-80{
  background-color: rgba(255, 255, 255, 0.8) !important;
  .detail-info-wrap-pricetag>div{
    color: @dark-gray !important;
  }

}
.darkest-night-opc-80{
  background-color: rgba(21, 21, 21, 0.8) !important;

}
.mZero {margin:0;}
.underline {text-decoration:underline !Important;}
//force div proportion
div[class*="col-"] > .square-responsive,
span[class*="col-"] > .square-responsive,
ol[class*="col-"] > .square-responsive,
ul[class*="col-"] > .square-responsive,
li[class*="col-"] > .square-responsive {
  padding-bottom: 100%;
}

div[class*="col-"] > .rect-responsive,
span[class*="col-"] > .rect-responsive,
ol[class*="col-"] > .rect-responsive,
ul[class*="col-"] > .rect-responsive,
li[class*="col-"] > .rect-responsive {
  padding-bottom: 50%;
}

.square-responsive,
.rect-responsive {
  position: relative;
  overflow: hidden;
}

.square-responsive > *,
.rect-responsive > * {
  position: absolute;
}

.square-responsive > .content,
.rect-responsive > .content {
  width: 100%;
  height: 100%;
}

.col-static{
  position: static;
}

// new cover effects
div.cover {
  position: absolute; //absolute; RTC 322597 image overlap fix
  overflow: hidden;
  width: 100%;
  height: 100%;
}

video.cover,
img.cover {
  position: absolute;
  left: -9999px;
  right: -9999px;
  top: -9999px;
  bottom: -9999px;
  margin: auto;
  min-width: 100%;
  min-height: 100%;
}
//nagy css addition for abdullah
.placement-photo img {
  max-height: 100%;
}
/*
 * LOADERS
 */

@-webkit-keyframes uil-default-anim {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@keyframes uil-default-anim {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

.loader {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
  transform: scale(0.24);

  > div {
    position: absolute;
    top: 76px;
    left: 92px;
    width: 16px;
    height: 48px;
    background: @main-green;
    transform: rotate(0deg) translate(0, -60px);
    border-radius: 0;
  }

  > div:nth-of-type(1) {
    animation: uil-default-anim 1s linear infinite;
    animation-delay: -0.5s;
  }

  > div:nth-of-type(2) {
    animation: uil-default-anim 1s linear infinite;
    animation-delay: -0.4166666666666667s;
  }

  .uil-default-css > div:nth-of-type(3) {
    animation: uil-default-anim 1s linear infinite;
    animation-delay: -0.33333333333333337s;
  }

  > div:nth-of-type(4) {
    animation: uil-default-anim 1s linear infinite;
    animation-delay: -0.25s;
  }

  > div:nth-of-type(5) {
    animation: uil-default-anim 1s linear infinite;
    animation-delay: -0.16666666666666669s;
  }

  > div:nth-of-type(6) {
    animation: uil-default-anim 1s linear infinite;
    animation-delay: -0.08333333333333331s;
  }

  > div:nth-of-type(7) {
    animation: uil-default-anim 1s linear infinite;
    animation-delay: 0s;
  }

  > div:nth-of-type(8) {
    animation: uil-default-anim 1s linear infinite;
    animation-delay: 0.08333333333333337s;
  }

  > div:nth-of-type(9) {
    animation: uil-default-anim 1s linear infinite;
    animation-delay: 0.16666666666666663s;
  }

  > div:nth-of-type(10) {
    animation: uil-default-anim 1s linear infinite;
    animation-delay: 0.25s;
  }

  > div:nth-of-type(11) {
    animation: uil-default-anim 1s linear infinite;
    animation-delay: 0.33333333333333337s;
  }

  > div:nth-of-type(12) {
    animation: uil-default-anim 1s linear infinite;
    animation-delay: 0.41666666666666663s;
  }
}

//customize the margin to be able to handile the fix position for main-menu abd top nav
@media (min-width: @screen-ml-min) {
  .js-fixed-nav{
    margin-top: 142px;
}
}
.loader_svg{
  svg{
  fill: @main-green;
    circle{
        fill: @main-green;
    }
  }
}
.business{
  .loader_svg{
    svg{
    fill: @dark-green;
      circle{
          fill: @dark-green;
      }
    }
  }
}
//to make tiles disable
.ets-disabled{
    opacity : 0.5;
    pointer-events : none;
}
// for checkboxes used png
.form .checkbox input[type=checkbox]:checked+label:before, .form .radio input[type=checkbox]:checked+label:before {
    background-image: url(../img/forms/checkbox.png);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 80%;
}

//loader/spinner and to disable whole page
.loader-disable-screen {
  background-color: rgba(0, 0, 0, 0.2);
   z-index: 999;
   position: fixed;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   display: none;
   .loader {
     width: 100%;
     height: 100%;
     svg {
       width: 100%;
     }
   }
}

//mobile-plan postpaid, prepaid creating mask for click
.close-selected-plan {
  display:block;
  position:absolute;
  width:100%;
  height:95%;
  z-index:999;
  top: 30px;
}
@media (min-width: @screen-md-min) {
  .close-selected-plan {
    display: none;
  }
}

// helpme form intial min width
#help-me-form {
  .select2 {
    min-width: 200px;
  }
}

// store-locator input field set width
#pac-input-store-locator {
  padding-right: 125px;
}

//page-section-heading
.page-section-heading h5{
  text-align: center;
  color: @brand-primary;
  margin: 50px 0;
}

//page level heading
.page-level-title {
  .form-title{
    padding-bottom: 0 !important;
  }
  .form-container {
    padding-bottom: 0 !important;
  }
}

// tags
.tags {
  padding: 2px 5px;
  border-radius: 3px;
  margin: 0 5px;
  color: @white;
  &.primary {
    background-color: @brand-primary;
  }
  &.default {
    background-color: @gray
  }
}

//buttons alignment
.button-alignment {
  a, button{
    float: none;
  }
}



//Administeration - User Manuals - FAQ's - Video's
.user-manual {
  padding: 50px 0;
    ul {
   display: flex;
   flex-wrap: wrap;
   li{
     flex: 0 0 33%;
   }
  }
}

// radio-buttons-with-tooltip
.radio-buttons-with-tooltip {
  .upload-files-container, label {
    float: left;
  }
  .upload-files-container {
    margin-top: -5px;
  }
}
// padding bottom on form
.pb130{
  padding-bottom: 130px !important;
}
//book your number search and lucky number fields alignment
@media (max-width: 767px) {
	.search-your-number .form-section:nth-child(1){
		width: 100%;
	}
	.search-your-number .form-section{
		padding-right: 5px;
		float: left;
    width: 14.28%;
	}

	.search-your-number .form-section input{
		height: 67px;
		//width: 32px;
		padding: 5px 5px;
    text-align: center;
	}

  .search-your-number {
    .alert-icon, .has-error.alert-label {
  		display:none !important;
  	}
  }
  .search-your-number .form-section:first-child {
      width: 100%;
  }
  //wp9 mob bookyournumber fix RTC#323706
  .checkAvaiMobBtn button {
      width: 100%;
  }
  .form-section.prefixMobFix {
    max-width:28.67%;
  }

}

@media (min-width: 320px) and (max-width: 500px) {
  .form-section.prefixMobFix {
    max-width:42.67%;
  }
  .margin-top-2rem-xs {
    margin-top:2.5rem;
  }
}

@media (min-width: 768px) {
	.search-your-number .form-section:nth-child(1){
		width: 19%;
	}
	.search-your-number .form-section{
		padding-right: 10px;
		/*float: left;*/
    float: none;
    display: table-cell;
		width: 8%;
    vertical-align: top;
	}
  .search-your-number {
    .alert-icon, .has-error.alert-label {
      display:none !important;
    }
  }

	.search-your-number .form-section input{
		min-height: 68px;
		text-align: center;
    padding: 0;
	}
}

@media (min-width: @screen-md-min) {
	.search-your-number .form-section:nth-child(1){
		width: 14%;
	}
	.search-your-number .form-section{
		padding-right: 10px;
		/*float: left;*/
    float:none;
    width: 6%;
    display: table-cell;
    vertical-align: top;
	}
	.search-your-number .form-section input{
		min-height: 67px;
		text-align: center;
    padding: 0;
	}
}

@media (min-width: @screen-lg-min) {
	.search-your-number .form-section input{
		min-height: 80px;
    box-sizing: content-box;
	}

  // book-your-number
  .search-your-number {
    .floating-label-select {
      min-height: 80px;
    }
  }
  .form-group.floating-label-input.lucky-number-disableEnable input {
      min-height: 80px;
  }

}


// select 2 search field
.select2-search__field {
  background-color: #ffffff !important;
  padding: 20px !important;
}

// arabic to english text and math strings

.arabic-to-english-text {
  direction: ltr;
}

// book-your-number
.search-your-number {

  .floating-label-select {
    min-width: auto !important;
    margin: 0;
  }
}
.msg-to-book-number {
  font-family: Lato,Roboto,Arial,sans-serif;
  color: @gray;
}

.number-booked {
  color: #FF8000;
  font-family: Lato,Roboto,Arial,sans-serif;
  display: none;
  // margin: 0 !important;
}

.number-booking {
  display: none;
  background: #FF8000;
  padding: 2px 15px;
  margin-left: 15px;
  min-width: 180px;
  color: @white;
  h4,h5 {
    margin: 1px 0;
  }
  .number-booked-for {
    font-family: Neotech,Roboto,Arial,sans-serif;
      font-size: 1rem;
      line-height: 1rem;
  }
  .number-booked-time > div {
    font-family: Neotech,Roboto,Arial,sans-serif;
    font-size: 1.6rem;
    line-height:  1.6rem;
    span {
      text-transform: uppercase;
    }
  }
}

.timeisup {
  background-color: #FB193F;
}

//pic cutting from top  RTC 322597
.text-and-table>div.text-and-table-text .content.picTitletop div.cover {
    position:relative;
    img {
          min-width: auto;// image squeeze issue fix
    }
}
.text-and-table>div.text-and-table-text .content.picTitletop h5 {
    margin-bottom: 0 !important;
    text-transform: uppercase;
}
@media (min-width: @screen-lg-min) {
  .number-booking {
    .number-booked-time > div {
      font-family: Neotech,Roboto,Arial,sans-serif;
      font-size: 2.5rem;
      line-height: 2.5rem;
      span {
        text-transform: uppercase;
      }
    }
  }
}

@media (max-width: 767px) {
  .timer-for-mobileView {
    min-width: 180px;
    width: 100%;
    margin: 0;
    float: right;
    margin-bottom: 10px;

    h5 {
      font-size: 12px;
    }
  }
}

.resize-btn {
  padding: 0 4px;
}

@media (max-width: 767px) {
  .number-booking
}
.customError {
  color: #FB193F;
  font-size: 12px;
}

.showOtpErrorMsg {
  color: #FB193F;
  margin-bottom: 25px;
}
// this fix is for mac arabic language
.nowrap {
  padding-bottom: 0;
  .row {
    flex-wrap: nowrap;
  }
  .business-register-form-submit {
    height: 5rem;
  }
}
.checkoutAsGuest {
  .form {
    padding-bottom: 0 !important;
    .business-register-form-submit {
      height: 5rem 0 !important;
    }
  }
}
@media (max-width: @screen-sm-max) {
  .sub_menu_mobile { background: #fff; }
}
.paddingZero{padding:0;}
@media (min-width: @screen-sm-min) {
  [lang=ar] [dir=rtl] .business-register-form .form>.row,
  [lang=ar] [dir=rtl] .consumer-register-form .form>.row,
  [lang=ar] [dir=rtl] .login .form>.row,
  [lang=ar] [dir=rtl] .recover-username .form>.row,
  [lang=ar] [dir=rtl] .reset-password .form>.row {
    flex-wrap: nowrap !important;
  }
}
//orange timer box moving issue fixes
#timerDiv {
    display: block;
    float: left;
    min-width: 70px;
}
//end
@media (max-width: 992px) {
  #modified-form-default input:-webkit-autofill,
  #modified-form-default  textarea:-webkit-autofill,
  #modified-form-default  select:-webkit-autofill {
          opacity:1;
          background-color: #f1f1eb !important;
          -webkit-box-shadow:0 0 0 1000px #f1f1eb inset !important;
  }
  .login-main .radio.radio-inline {
      padding: 0;
  }

  .login-main .radio label {
      padding-left: 2rem;
  }
  .main-menu-selfcare .main-menu.menu-order.login-menu .navbar .hamburger_icon.open>span {
      display: block !important;
  }


}
#modified-form-default input:-webkit-autofill,
#modified-form-default textarea:-webkit-autofill,
#modified-form-default select:-webkit-autofill {
  opacity:1;
  background-color: #f1f1eb !important;
  -webkit-box-shadow:0 0 0 1000px #f1f1eb inset !important;
}

.termCondition {
  /* text-align: left; */
  font-size: 0.68rem;
  color: #848789!important;
  margin-bottom: 0.15rem;
  letter-spacing: 0;
}
.termCondition a {
  letter-spacing: 0;
  color: #719e19 !important;
}


#login-menu-inner .login-sub.login-sub-mob .login-order .btn.btn-default {
    @media (max-width: 1024px) {
      line-height: 4rem;
    }
}
// IE menu fix

.main-menu-selfcare .col-ml-6 {
  @media (min-width:1280px) {
       width: 45% !important;
   }
}
.login-sub .login-main> .form-section {
    height: 225px;
}
.login-order.updated-login-dropdown  p.body-standard {
    height: 208px;
}
  @media (max-width:992px) {
    .hero-image .swiper-wrapper .swiper-slide {
        cursor: pointer;
        min-height: 300px;
    }
  }
  @media (max-width:414px) {
    .samsung{
      .hero-image .swiper-wrapper .swiper-slide {
          cursor: pointer;
          min-height: 450px;
      }
    }

  }

.advSearch .row.search-header {
    position: absolute !important;
    top: 0 !important;
    z-index: 9999 !important;
    left: 80% !important;
    right: 10% !important;
    top: -1px !important;
}
.mTop0 {
    margin-top: 0 !important;
}
*[lang="ar"] {
  *[dir="rtl"] {
    .alnment-arabic .row > div { float: right; }
      .tooltip-inner{
          ul {text-align: right;}
        }
        & .search-your-number .form-section{
        		padding-right: 0;
            padding-left: 10px;
            &.form-section.serachIcon {
                text-align: center;
            }
        	}
          //orange timer box moving issue fixes
          #timerDiv {
              float: right;
              text-align: left;
          }
          //support-guest CMS
          .download-links.arrow-right svg {
        	  padding-right: 0;
        	  float: none;
        	  padding-left: 5px;
          }
          //end
    }
  }

  @media screen and (-webkit-min-device-pixel-ratio:0) {
//sarfari
      // this issue is on product grid - and has been changed to display: flex to display: block to fill the empty space
      ::i-block-chrome,.product-grid {
        display: block !important;
      }
      //readonly fields with data from backend
      .form input[readonly]:not(.datepicker) {
          color: @gray;
      }
  }



#store-locator-form ul#select2-mapFilter-results {
  max-height: 230px;
}
.form .login-main .radio input {
  position: absolute;
}
// bookyounumber arabic fixing
[lang=ar] [dir=rtl] .form .prefixMobFix .floating-label-input label, [lang=ar] [dir=rtl] .form .prefixMobFix .floating-label-select label {padding-left: 1.4rem;padding-right: 1rem;}

[lang=ar] [dir=rtl] {
  .form .prefixMobFix .select2.select2-container {
      padding-right: 1rem;
      padding-left: auto;
  }
  & .steps-tracker-B {
    & .body-standard {
      &.step-node{
        text-right:right;
        }
    }
    }
    & .top-nav .top-nav-channel .col-sm-3.text-center:nth-child(2) {
      width: 30%;
    }
}
// disable tiles gray color
.config-tile-wrapper .tile-wrap input[type=radio]:disabled+.tile, .config-tile-wrapper .tile-wrap input[type=radio]:disabled+.tile .tile-option {
    border: 1px solid #D1D2D1;
}


//error class
.error + .tiles-required .error-red {
  color: #fb193f !important;
}


.checkoutAsGuest a.btn.btn-green.btn-default.ripple-effect.pack-next-step.select-tile-button,
.checkoutAsGuest .bg-dark .btn.btn-green.focus, .bg-dark .btn.btn-green:focus, .btn.btn-green.focus, .btn.btn-green:focus{
  background-color:#719e19;
}

.tile-anchor {
  display: block !important;
  padding: 0 !important;
  > a {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 10px;
    height: 100%;
  }
}

/*Using btn-text code from buynget*/

.btn-text-bugfixing {
  text-transform: uppercase;
  cursor: pointer;
  text-transform: uppercase;
  transition: all .3s ease-in-out;
  font-family: Lato;
  font-size: .75rem;
  letter-spacing: 1.6px;
  line-height: 1.25rem;
  color: @almost-black;
  position: relative;
  // display: block;
  font-weight: 500;

  .btn-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }

  &.download {
    font-size: 1rem;
    color: @almost-black;
    letter-spacing: 2px;
    padding: 0 0 0 30px;
    margin-bottom: 60px;
    display: block;
    @media screen and (max-width: @screen-md) {
      margin-bottom: 1.5rem;
    }

    &:before {
      left: 0;
      top: 0;
      right: auto;
      border: 0;
      content: " ";
      width: 20px;
      height: 20px;
      position: absolute;
      transform: none;
      padding: 0;
      background-image: url("../img/wst-icons/icon-file.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  +.btn-text {
    margin-right: 36px;
  }

  &:before {
    right: 3px;
    border: solid @almost-black;
    border-width: 0 1px 1px 0;
    padding: 3.7px;
    content: "";
    position: absolute;
    line-height: 1;
    -webkit-transform: translateY(-20%) rotate(-135deg);
    top: 50%;
    transform: translateY(-20%) rotate(-135deg);
    transition: all .3s ease-in-out;
  }

  &.view-more {
    padding-right: 1.5rem;

    &:hover {
      color: @main-green;

      > span {
        text-decoration: underline;
      }

      &:before {
        border: solid @main-green;
        border-width: 0 1px 1px 0;
      }
    }

    .more {
      transition: all .3s ease-in-out;
      display: none;
      // padding-top: 7px;
    }

    .less {
      display: inline-block;
      // padding-top: 7px;
    }

    &.collapsed {
      &:before {
        transform: translateY(-80%) rotate(45deg);
        -webkit-transform: translateY(-80%) rotate(45deg);

      }

      .more {
        transition: all .3s ease-in-out;
        display: inline-block;
      }

      .less {
        display: none;
      }
    }

  }

  &.learn-more {
    &:before {
      transform: translateY(-50%) rotate(-45deg);
      -webkit-transform: translateY(-50%) rotate(-45deg);
      right: -15px;

    }
  }

  &.back,
  &.forward {
    padding-left: 15px;
    font-size: .75rem;

    &:before {
      -webkit-transform: translateY(-50%) rotate(135deg);
      transform: translateY(-50%) rotate(135deg);
      left: 0;

      right: auto;
    }
  }

  &.back {
    &:before {
      -webkit-transform: translateY(-50%) rotate(135deg);
      -o-transform: translateY(-50%) rotate(135deg);
      transform: translateY(-50%) rotate(135deg);
      left: 0;
      right: auto;
    }
  }

  &.forward {
    &:before {
      -webkit-transform: translateY(-50%) rotate(-45deg);
      transform: translateY(-50%) rotate(-45deg);
      right: 0;
      left: auto;
    }

  }
  &.delete,
  &.edit,
  &.remove {
    padding: 0 0 0 25px;

    &:hover {
      color: @almost-black;
    }

    &:before {
      left: 0;
      top: 0;
      right: auto;
      border: 0;
      content: " ";
      width: 20px;
      height: 20px;
      position: absolute;
      transform: none;
      padding: 0;
      background-image: url('../img/buy-get/Icons/SVG/icon-pencil.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &.delete {
    &:before {
      background-image: url('../img/buy-get/Icons/SVG/icon-trash.svg');
    }
  }

  &.remove {
    &:before {
      background-image: url('../img/buy-get/Icons/SVG/icon-close-grey.svg');
    }
  }
  // BTN Green
  &.btn-green {
    color: @main-green;

    &.focus,
    &:focus {
      color: @main-green;
    }

    &:before {
      border: solid @main-green;
      border-width: 0 1px 1px 0;
    }

    &.active,
    &:active,
    &:hover,
    .open > .dropdown-toggle& {
      .box-shadow(none) !important;
      color: @main-green;
    }

    .ink {
      color: @main-green;
    }
  }

  .small {
    letter-spacing: 1.6px;
  }

  &:hover {
    text-decoration: underline;
  }
}
// mixin combine a background-image and gradient on the same element
.background-gradient-and-image (@fallback, @imgUrl, @background-position-x, @background-position-y, @angle, @startColor, @endColor) {
background: @fallback;
background: url(@imgUrl) @background-position-x @background-position-y no-repeat; /* fallback */
background: url(@imgUrl) @background-position-x @background-position-y no-repeat, -webkit-gradient(linear, left top, left bottom, from(@startColor) @background-position-x @background-position-y no-repeat, to(@endColor)); /* Saf4+, Chrome */
background: url(@imgUrl) @background-position-x @background-position-y no-repeat, -webkit-linear-gradient( @angle, @startColor, @endColor); /* Chrome 10+, Saf5.1+ */
background: url(@imgUrl) @background-position-x @background-position-y no-repeat, -moz-linear-gradient( @angle, @startColor, @endColor); /* FF3.6+ */
background: url(@imgUrl) @background-position-x @background-position-y no-repeat, -ms-linear-gradient( @angle, @startColor, @endColor); /* IE10 */
background: url(@imgUrl) @background-position-x @background-position-y no-repeat, -o-linear-gradient( @angle, @startColor, @endColor); /* Opera 11.10+ */
background: url(@imgUrl) @background-position-x @background-position-y no-repeat, linear-gradient( @angle , @startColor, @endColor); /* W3C */
}
*[lang="ar"] {
  *[dir="rtl"] {
    .btn-text-bugfixing {
      &::before{
        transform: translateY(-50%) rotate(135deg);
        left: -15px;
        right: auto;
      }

    }
  }
}
//
// @color_1: #78a02f;
// @font_family_1: Lato,Roboto,Arial,sans-serif;
//
// .sub_menu_mobile {
// 	#accordion_menu {
// 		.panel-title {
// 			a.mobileLoginDDn {
// 				font-family: @font_family_1 !important;
// 				color: @color_1;
// 				font-size: .75rem;
// 				text-transform: uppercase;
// 				display: -webkit-box;
// 				display: -ms-flexbox;
// 				display: flex;
// 				-webkit-box-orient: horizontal;
// 				-webkit-box-direction: normal;
// 				-ms-flex-direction: row;
// 				flex-direction: row;
// 				-webkit-box-align: center;
// 				-ms-flex-align: center;
// 				align-items: center;
// 				-webkit-box-pack: start;
// 				-ms-flex-pack: start;
// 				justify-content: flex-start;
// 				letter-spacing: .181875rem;
// 				margin-bottom: 0rem;
// 				svg {
// 					margin-right: 1.0625rem;
// 					width: 25px;
// 					fill: #78a02f;
// 				}
// 				&+.arrow_accordion {
// 					border-left: 0px solid #f1f1eb;
// 					background: none;
// 					height: 58px;
// 				}
// 			}
// 			a.mobileLoginDDn&+.arrow_accordion {
// 				svg {
// 					fill: #78a02f;
// 				}
// 			}
// 		}
// 	}
// 	.mobileLoginMob {
// 		#loginusermob {
// 			.panel-title {
// 				height: 58px;
// 				border-bottom: none;
// 			}
// 		}
// 		margin-bottom: 1.4rem;
// 		.panel.panel-default.row {
// 			.panel-body {
// 				border-top: 1px solid #e2e1da;
// 			}
// 		}
// 		.sub_menu_accordion {
// 			a.col-sm-offset-3 {
// 				margin-left: 5.8125rem !important;
// 			}
// 		}
// 	}
// }
// svg {
// 	#myaccount {
// 		path {
// 			fill: #78a02f;
// 		}
// 	}
// }
