.promo-logo {
    position: absolute;
    margin: auto;
    z-index: 360;
    left: 0;
    right: 0;
    width: 180px;
    height: 48px;
    top: 4rem;
}
