.search-results {
  background-color: @sand-white;
  .page-nav-wrap {

    .page-nav {
      li.highlight {
        a {
          //  color: @almost-black;
          &:hover {
            //  color:@white;
          }
          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 4px;
            left: 0;
            bottom: -4px;
            background-color: @main-green;
          }
        }
      }
    }
  }
  .search-results-items {
    padding: 2.5rem 0;
    .search-results-item {
      background-color: @white;
      padding: 0.9375rem;
      margin-bottom: 1.875rem;
      &:last-child {
        margin-bottom: 0;
      }
      @media (min-width: @screen-sm-min) {
        padding: 0.9375rem 1.875rem;
      }
      @media (min-width: @screen-md-min) {
        padding: 0.9375rem 1.25rem;
      }
      @media (min-width: @screen-lg-min) {
        padding: 1.875rem 3.75rem;
      }
      a {
        font-size: 1.25rem;
        color: @almost-black;
        padding: 0;
        margin: 0 0 0.3125rem;
        @media (min-width: @screen-lg-min) {
          margin: 0 0 0.9375rem;
        }
        &:hover {
          color: @main-green;
        }
      }
      p {
        font-size: 0.8125rem;
        color: @gray-fill;
        padding: 0;
        margin: 0;
      }
    }
    .loadmore {
      padding: 3.75rem 0 1.25rem;
      .loader {
        display: none;
        margin: auto;
        height: 68px;
        > div {
          top: 50%;
          margin-top: -24px;
          left: 50%;
          margin-left: -24px;
        }
      }
    }
    &.loading {
      .loadmore {
        a {
          display: none;
        }
        .loader {
          display: block;
        }
      }
    }
  }
  .tiles-menu {
    background-color: @light-sand-brown;
    .row {
      padding: 2.5rem 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .swiper-wrapper {
        //overflow: hidden;
        .menu-tile {
          height: 8.125rem;
          overflow: hidden;
          position: relative;
          margin-bottom: 1.25rem;
          a {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: @white;
            text-transform: none;
            font-size: 1rem;
            letter-spacing: 0.125rem;
            font-family: @font-family-menu-sans-serif;
            font-weight: 600;
            text-align: center;
          }
        }
      }
    }
  }
}
.business .search-results .page-nav-wrap{
  background-color: @moonlight-sand;
}

@media (max-width: @screen-sm-min) {
  .search-results .page-nav-wrap .page-nav li.highlight a:hover {
    color: @almost-black;
  }
  .search-results .page-nav-wrap .page-nav li.highlight a:after {
    height: 0;
  }
}
.business .search-results .page-nav-wrap .page-nav .highlight a {
  &:after {
    background-color: @light-sand-brown;
  }
}
