.ipad-cross-nav-00 {
  background-color: @sand-white;
  > div {
    width: ~"calc(50% - 48px)";
    min-height: 360px;
    height: 360px;
    background-color: #fff;
    padding: 0 1.5rem 1.5rem;
    margin-left: 2rem;
    float: left;
    overflow: hidden;
  }
  h3 {
    color: @almost-black;
    letter-spacing: 2px;
  }
  p {
    color: @steel-grey;
  }
  .media-cta-wrapper .media-cta-photo {
    min-height: 300px;
    height: 300px;
    width: ~"calc(100% + 48px)";
    margin-left: -24px;
    margin-top: 0;
  }
  .media-cta-container h3 {
    width: ~"calc(100% + 48px)";
    margin-left: -24px;
    padding: 0 12px;
    margin-top: 14px;
  }
}
.ipad-btn-00 {
  height: 6rem;
  background-color: @sand-white-50;
  position: fixed;
  bottom: 0;
  z-index: 1;
  .btn {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4rem;
    width: 16rem;
    line-height: 4rem;
  }
}

[lang="ar"] [dir="rtl"] {
  .ipad-cross-nav-00, .ipad-media-cta-01 {
    > div {
      margin-left: 0;
      margin-right: 2rem;
      float: right;
    }
  }
  .ipad-media-cta-01 {
    h2 {
      text-align: right;
      padding: 0 1.5rem;
    }
    .text {
      left: 0;
      right: 1.5rem;
    }
  }
}
