@consumer-register-form: 1.5rem;
.consumer-register-form {
  padding: 0;
  .form-container {
    padding-bottom: 4rem;
    padding-top: 4rem;

  }
  .form-title {
    padding-bottom: 2rem;
    text-align: center;
    color: @almost-black;
    h3 {
      letter-spacing: 0;
    }
    h5 {
      color: @steel-grey;
      letter-spacing: 0;
      padding-bottom: 1rem;
      &.notification {
        padding-bottom: 0;
        margin: 0;
        padding: 0 2rem;
        &:last-child {
          padding-bottom: 1rem;
        }
      }
      &.success-msg {
        color: @main-green;
      }
      &.error-msg {
        color: @pink;
        & a{
          color: @pink;
          text-decoration: underline;
        }
      }
    }
  }

  .form {
    padding-bottom: 3rem;
    .row {
      .form-section {
        margin: 0;
        padding: 0;
      }

      > .form-section {
        margin: 0;
        padding: 0;
        &:nth-child(1) {
        }
        &.last,
        &:nth-child(2) {
        }
        &.single-section {
          padding: 0;
        }
        .floating-label-input {
          .tooltipped .tooltip.right {
            margin-left: 0.5rem;
          }
          label {
          }
          span {
            &.outside-icon {
              width: 2rem;
              height: 2rem;
              position: absolute;
              top: 1.7rem;
              right: 4%;
              align-items: center;
              text-align: center;
              z-index: 2;
              //background-color: @white;
            }
            svg {
              height: 24px;
              width: 24px;

            }
          }
          &.has-error-fields {
            span.outside-icon {
              svg {
                fill: @pink;
              }
            }
          }
        }
        .floating-label-select label {
          color: @main-green;
          &.floating-label {
            color: @steel-grey;
          }
        }
        a.forgot-login {
          float: left;
          color: @almost-black;
          text-decoration: underline;
        }
        .checkbox.checkbox-inside-form{
          padding-top: 0;
          margin-bottom: 1rem;
          margin-top: -1rem;
          @media (max-width: 767px) {
            margin-bottom:2rem;
          }
        }
        .checkbox, .radio {
          color: @almost-black;
          &:nth-child(1) {
            margin-top: 0;
            padding-top: 0;
          }
          @media (min-width: @screen-md-min) and (min-width: @screen-xs-min) {
            padding-top: 16px;
            padding-bottom: 8px;
            line-height: 16px;
            input {
              //display: none;
              &[type="radio"] {
                height: 100%;
                width: 100%;
                z-index: 10;
                margin-left: 0;
                display: block;
                margin: 0 auto;
                cursor: pointer;
                opacity: 0;
                + label {
                  font-size: 1.25rem;
                }
                + label:before {
                  border-radius: 50%;
                  height: 25px;
                  width: 25px;

                }
                &:checked + label:before {
                  background-size: 68%;
                }
              }
              &[type="checkbox"] {
                + label:before {
                  height: 25px;
                  width: 25px;
                }
                &:checked + label:before {
                  background-size: 68%;
                }
              }
            }
          }
        }
        .consumer-register-form-submitting {
          position: relative;
          p {
            letter-spacing: .2rem;
            text-transform: uppercase;
            position: absolute;
            width: 100%;
            left: 0;
            top: 50%;
            margin: 0;
            margin-top: 2rem;
          }
        }
        a {
          color: @almost-black;
          text-decoration: underline;
        }
        .consumer-register-form-submit, .consumer-register-form-submitting {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: @consumer-register-form*8;
          margin: 0;
          padding: 0;
          text-align: center;
        }
        .form-submit {
          height: 8rem;
          padding: 0;
          text-align: center;
          button {
            text-transform: uppercase;
            margin: auto 0.5rem;
          }
        }
      }
    }
  }
}

@media (min-width: @screen-xs-min) {
  .consumer-register-form {
    .form {
      .row {
        > .form-section {
          margin: 0;
          padding: 0;
          &:nth-child(1) {
            padding-right: 0;
          }
          &:nth-child(2),
          &:nth-child(3) {
            padding-left: 0;
          }
          &.single-section {
            padding: 0;
          }
          .floating-label-input span.outside-icon{
            top: 34%;
          }
        }
      }
    }
  }
}

@media (min-width: @screen-sm-min) {
  .consumer-register-form {
    .form {
      .row {
        > .form-section {
          margin: 0;
          padding: 0;
          &:nth-child(1) {
            padding-right: @consumer-register-form*2.5;
          }
          &:nth-child(2),
          &:nth-child(3) {
            padding-left: @consumer-register-form*2.5;
          }
          &.single-section {
            padding: 0;
          }
        }
      }
    }
  }
}

@media (min-width: @screen-md-min) {
  .consumer-register-form {
    .form {
      .row {
        > .form-section {
          margin: 0;
          padding: 0;
          &:nth-child(1) {
            padding-right: @consumer-register-form*2.5;
          }
          &:nth-child(2),
          &:nth-child(3) {
            padding-left: @consumer-register-form*2.5;
          }
          &.single-section {
            padding: 0;
          }
        }
      }
    }
  }
}

@media (min-width: @screen-lg-min) {
  .consumer-register-form .form .row>.form-section .floating-label-input span.outside-icon
  .consumer-register-form {
    .form {
      .row {
        > .form-section {
          margin: 0;
          padding: 0;
          &:nth-child(1) {
            padding-right: @consumer-register-form*2.5;
          }
          &:nth-child(2),
          &:nth-child(3) {
            padding-left: @consumer-register-form*2.5;
          }
          &.single-section {
            padding: 0;
          }
        }
      }
    }
  }
}
@media (min-width: @screen-xl-min) {
  .consumer-register-form .form .row>.form-section .floating-label-input span.outside-icon
  .consumer-register-form {
    .form {
      .row {
        > .form-section {
          .floating-label-input span.outside-icon{
            top: 1.7rem;
          }
        }
      }
    }
  }
}
// @media (max-width: @screen-md-min) {
// .consumer-register-form .form .row>.form-section .checkbox.checkbox-inside-form {
//        margin-bottom: 3rem !important;
// }
//
// .tooltip{display:none !important;}
// }
//
// .form-group.checkbox.has-error-fields .has-error.alert-label {
//    float: none !important;
//    margin: 0.3rem 0 !important;
// }
