.fade-in {
  opacity:0;  /* make things invisible upon start */
  -webkit-animation:fadeIn ease-in 1;  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation:fadeIn ease-in 1;
  animation:fadeIn ease-in 1;
  -webkit-animation-fill-mode:forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode:forwards;
  animation-fill-mode:forwards;
  -webkit-animation-duration:1s;
  -moz-animation-duration:1s;
  animation-duration:1s;
}
.callback-box-black {

  @media (min-width: 768px){
      width: 115px;
      height: 119px;
    //  background: #363739;
      border: 1px solid #fff;
      color: #fff;
      position: fixed;
      top: 250px;
      right: 0;
    //  right: -81px;
      z-index: 2;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
      transform: translateX(81px);
      transition: transform 0.3s ease-in-out;
      -webkit-animation-delay: 0.7s;
      -moz-animation-delay: 0.7s;
      animation-delay: 0.7s;
      .callback-handler {
          transform: rotate(-90deg);
          transform-origin: left bottom;
          border-bottom: 1px solid #fff;
          background: #363739;
          height: 35px;
          position: absolute;
          bottom: 0;
          width: 117px;
          text-align: left;
          left: 35px;
          line-height: 34px;
          padding: 0 10px;
          cursor: pointer;
          a {
              color: #fff;
          }

          .callback-options-icon {
              background: #fff;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              color: #000;
              text-align: center;
              line-height: 16px;
              -webkit-transform: rotate(-90deg);
              transform: rotate(90deg);
              position: absolute;
              right: 10px;
              top: 10px;
              font-family: Arial;
              font-size: 11px;
              font-weight: 700;
              -webkit-animation: led .8s infinite;
              -moz-animation: led .8s infinite;
              -ms-animation: led .8s infinite;
              -o-animation: led .8s infinite;
              animation: led .8s infinite;
          }

      }


      .callback-options {
          width: 80px;
          float: right;
          color: #fff;
          text-align: center;
          .callback-openBtn {
                  background: #363739;
                  height: 59px;
                  border-bottom: 1px solid #fff;
                  padding-top: 14px;
                  &:hover {
                      background: #000;
                  }

          }

          a {
              color: #fff;
              font-size: 0.6rem;
              letter-spacing: 1px;
              font-family: Neotech,Roboto,Arial,sans-serif;
          }
          svg {
             width: 16px;
             height: 16px;
         }
      }
      &.expand {
        //  right: 0;
          transform: translateX(0);
      }
    }
    @media (max-width: 767px){
          font-family: Neotech,Roboto,Arial,sans-serif;
          width: 64px;
          height: 48px;
          line-height: 48px;
          background: #363739;
          border: 1px solid #fff;
          color: #fff;
          position: fixed;
          bottom: 16px;
          right: 16px;
          z-index: 2;
          -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
          -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
          box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
          text-align: center;
          letter-spacing:1px;
          font-size: 0.8rem;
          font-weight:700;
      .callback-handler {
        opacity: 1;
        transition: opacity .6s ease-in,bottom .6s ease-in;
        &> .callback-options-icon {
              display: none;
        }
      }
      //


      //
      .callback-options {
          bottom: -200px;
          opacity: 0;
          -webkit-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
          -moz-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
          -o-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
          transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
          .callback-openBtn {
            position: relative;
            height: 48px;
            padding-top: 0;
            width: 64px;
            background: #363739;
            margin-bottom: 8px;
            vertical-align: middle;
                  &:hover {
                      background: #000;
                  }
                  .callback-options-icon {
                    svg {
                        width: 24px;
                        height: 24px;
                        vertical-align: middle;
                      }

                  }
                  .callback-options-title {
                      position: absolute;
                      top: 0;
                      right: 80px;
                      color: #fff;
                      font-family: Lato,Roboto,Arial,sans-serif;
                  }
                  &.closeBtn {
                    .callback-options-icon {
                      svg {
                        margin-left: 8px;
                      }
                    }
                  }
                  .callback-options-title {
                      color: #fff;
                      position: absolute;
                      right: 76px;
                      top: 0;
                      font-family: Lato,Roboto,Arial,sans-serif;
                      font-weight: 500;
                      width: 200px;
                      text-align: right;
                  }
          }

          a {
          }
          svg {
         }
         .btnContainer {
              float: right;
          }
          .btnContainer {
              display: none;
          }
      }
      &.expand {
        background: none;
        border: none;
        box-shadow: none;
        transform: translateY(16px);
        width: 100%;
        left: 0;

        .callback-options {
          background: rgba(0,0,0,.6);
          left: 0;
          bottom: 0;
          position: fixed;
          width: 100%;
          height: 192px;
          padding: 16px;
          opacity: 1;
          .btnContainer {
              display: block;
              float: right;
          }
        }
        .callback-handler {
          opacity:0;
        }

      }
      .hidden-xs {display:none !important;}
    }

}
//IE SPECIFIC MEDIA QUERY START
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
/* IE10+ CSS styles go here */
    .callback-box-black {
      transform: translateX(81px);
    }
  body {
    overflow-y: scroll;
    -ms-overflow-style: scrollbar;
    }
}

/* IE EDGE+ CSS styles go here */
@supports (-ms-ime-align:auto) {

}
//IE SPECIFIC MEDIA QUERY END
//ARABIC
//
*[lang="ar"] {
  *[dir="rtl"] {

        .callback-box-black {
          @media (min-width: 768px){
            right:auto;
            left: 0;
            transform: translateX(-81px);
            &.expand {
                left: 0;
                transform: translateX(0);
            }
            .callback-handler {
                text-align: left;
                left: 113px;
                border-top: 1px solid #fff;
                border-bottom: none;
            }
            .callback-options {
                float: left;
            }
          }
          @media (max-width: 767px){
            right: auto;
            left: 16px;
            .callback-options {
              .callback-openBtn {
                .callback-options-title {
                    right: auto;
                    left: 76px;
                    text-align: left;
                }
              }
            }
            &.expand {
                left: 0;
              .callback-options {
                .btnContainer {
                    float: left;
                }
              }
            }
          }
    }
  }
}
