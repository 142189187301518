.modified-form-R0{
  .form{
    &.modified-form{
      &.single-col-form{
        .row {
          > .form-section {
            &:nth-child(1) {
              padding-right:0;
            }
          }
        }
      }
      .inner-icon {
        position: absolute;
        right: .125rem;
        top: 52%;
      }
      span{
        &.credit-card-view {
          position: absolute;
          right: .125rem;
          top: 52%;
          svg{
            width: 35px;
            height: 35px;
          }
        }
      }


      .form-section{
        .pwd-meter-track{
          margin-top: 0px;
        }
        .hint{
          color: @steel-grey;
          font-size: 0.9rem;
          display: block;
          margin-top: 15px;
        }
        // Style for Text Field
        .floating-label-input{
          margin-bottom: 0.5rem;
          border: none;
          background-color: transparent;
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus
          input:-webkit-autofill,
          textarea:-webkit-autofill,
          textarea:-webkit-autofill:hover
          textarea:-webkit-autofill:focus,
          select:-webkit-autofill,
          select:-webkit-autofill:hover,
          select:-webkit-autofill:focus {
            border-color: @main-green;
            -webkit-text-fill-color: @main-green;
            -webkit-box-shadow:none;
            transition: background-color 5000s ease-in-out 0s;
          }
          &.has-accepted{
            input{
              color: @main-green;
              border-bottom: 1px solid @main-green;
              position: relative;
            }
            span{
              position: absolute;
              right: 0px;
              top: 40px;
              svg{
                * {
                  stroke: @main-green;
                }
              }

            }
          }
          &.has-error{
            input{
              color: @pink-dark;
              border-bottom: 1px solid @pink-dark;
              position: relative;
              &:valid{
                color: @pink-dark;
                border-color: @pink-dark;
              }
            }
            span{
              position: absolute;
              right: 0px;
              top: 40px;
              svg{
                * {
                  stroke: @pink-dark;
                }
              }

            }
          }

          // Style for Text Field
          input{
            background-color: transparent;
            border-bottom: 1px solid @texts-gray;
            padding-left: 0px;
            padding-bottom: 0.7rem;
            box-sizing: border-box;
            outline: 0;
            height: 75px;
            box-shadow: none;
            background-position: 99% 43px;
            color: @steel-grey;
            transition: .2s cubic-bezier(.4,0,.2,1);
            &.valid{
              background-image: url(../img/forms/icons/icon_checkbox_checked_consumer_24.svg);
              background-repeat: no-repeat;
              background-position: 99% 43px;
              background-size: 24px 24px;
            }
            &.datepicker{
              &:read-only{
                border-color: @texts-gray;
              }
            }
            &:focus {
              outline: none;
              border-color: @main-green;
              border-width: 2px !important;
              height: 75px;
              color: @main-green;

            }
            &:valid{
              color: @main-green;
              border-width: 1px;
              font-size: 20px;
            }
            &:focus~label{
              font-size: 12px;
            }
            &:disabled~label{
              font-size: 12px;
            }

          }
          &.promo-code-field{
            input{
              border-bottom: 1px solid @texts-gray;
              color: @dark-orange;
              &.valid{
                background-image: url(../img/forms/icons/icon_check_circle_orange_64.svg);
                background-repeat: no-repeat;
                background-position: 99% 43px;
                background-size: 24px 24px;
              }
              &:read-only{
                color: @dark-orange;
                border-color: transparent;
                &:focus {
                  border-color: transparent;
                  color: @dark-orange;
                }
              }
              &.datepicker{
                &:read-only{
                  border-color: @dark-orange;
                }
              }
              &:focus {
                border-color: @dark-orange;
                color: @dark-orange;

              }
              &:valid{
                color: @dark-orange;
              }

            }
          }
          textarea:disabled ~ label,
          textarea:focus ~ label,
          textarea:required:valid ~ label,
          textarea[readonly] ~ label,
          input:focus ~ label,
          input:required:valid ~ label,
          input[readonly] ~ label,
          label.floating-label,
          select.selected ~ label {
            font-size: 12px;

          }
          input[readonly]{
            color: @almost-black;
            border-color: transparent;
            font-size: 20px;
            background-color: transparent !important;
          }
          &.disabled input,
          input.disabled,
          input:disabled {
            &:not(.datepicker) {
              background-color: transparent !important;
              -webkit-autofill: @disabled-input-color !important;
              border-bottom: 1px dotted @silver-gray;
              padding-left: 0px;
              padding-bottom: 0.7rem;
              color: rgb(132, 135, 137, 1);
              font-size: 1.25rem;

            }
          }
          label {
            padding: 0px;
            letter-spacing: 0;
            color: @steel-grey;
            font-size: 20px;
            top: 16px;
            text-transform: none;
          }
          // Style for Textarea
          &.floating-label-textarea{
            border: 1px solid @texts-gray;
            padding-top:0;
            padding-bottom:0.5rem;
            margin-top: 1.25rem;
            &.floating-label-textarea-view{
              border-color: transparent;
              margin-top: 0px;
              textarea{
                padding-left: 0px;
                &:read-only{
                  color: @almost-black;
                  border-color: transparent;
                  height: 105px;
                  &:focus {
                    border-color: transparent;
                    color: @almost-black;
                    height: 105px;
                  }
                }
              }
              label{
                left: 0px;
              }
            }

            &:focus {
              outline: none;
              border-color: @main-green;
              border-width: 2px !important;
              height: 105px;
              color: @main-green;

            }
            &:valid{
              color: @main-green;
              border-width: 1px;
              font-size: 20px;
            }
            &:focus~label{
              font-size: 12px;
            }
            &:disabled~label{
              font-size: 12px;
            }
            textarea{
              background-color: transparent;
              border:none;
              padding-left: 20px;
              padding-top: 0px;
              padding-bottom: 0.7rem;
              box-sizing: border-box;
              resize: none;
              outline: 0;
              height: 105px;
              box-shadow: none;
              color: @steel-grey;
              transition: .2s cubic-bezier(.4,0,.2,1);
              margin-top: 2.55rem;
              &:read-only{
                color: @almost-black;
                border-color: transparent;
                font-size: 20px;
                background-color: transparent !important;
              }
              &.datepicker{
                &:read-only{
                  border-color: @texts-gray;
                }
              }
              &:focus {
                outline: none;
                border-color: @main-green;
                border-width: 2px !important;
                height: 105px;
                color: @main-green;

              }
              &:valid{
                color: @main-green;
                border-width: 1px;
                font-size: 20px;
              }
              &:focus~label{
                font-size: 12px;
              }
              &:disabled~label{
                font-size: 12px;
              }

            }
            label {
              left: 20px;
              line-height: 1.3;
            }
          }
          span{
            &.outside-icon{
              top: 1.4rem;
            }
          }

          &.has-error-fields{
            .alert-icon{
              svg{
                top: 2.5rem;
                right: 0.125rem;
              }
            }
            .alert-label{
              top: 4.9rem;
              text-align: left;
            }

          }
          &.has-error-fields {
            &.floating-label-datepicker{
              input{
                &.datepicker{
                  &:read-only{
                    border-color: @pink-dark;
                  }
                }
              }
              .alert-icon {
                display: none;
              }
              .inner-icon {
                margin-right: 0px;
                svg {
                  *{
                    stroke: @pink-dark;
                  }

                }
              }
            }
            input {
              border-bottom: 1px solid @pink-dark;

            }
            border-color: @pink-dark;
            input,
            label,
            textarea {
              color: @pink-dark !important;
            }
            &.floating-label-textarea{
              border: 1px solid @pink-dark;
              textarea {
                border:none;
              }
              .alert-icon {
                display: none;
              }
              .alert-label {
                top: 9.8rem;
              }
            }
            .alert-icon {
              display: block;
              svg {
                fill: @pink;
                width: 1.5rem;
                height: 1.5rem;
                position: absolute;
                top: 2.5rem;
                right: .125rem;
              }
            }
            .alert-label {
              color: @pink-dark;
              line-height: 1rem;
              height: 1rem;
              margin: 0;
              padding: 0;
              width: 100%;
              position: absolute;
              top: 4.9rem;
              text-align: left;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              .body-small;
              svg {
                display: none;
                fill: @pink-dark;
                height: 1rem;
                margin-right: 0.5rem;
                position: absolute;
                top: 0;
                left: 0.5rem;
              }
              a {
                color: @pink-dark;
                text-decoration: underline;
              }
            }
            .inner-icon {
              svg {
                stroke: @pink-dark;
                fill: @pink-dark;
                *{
                  stroke: @pink-dark;
                  fill: @pink-dark;
                }
              }
            }
          }
          span{
            &.credit-card-view{
              display: none;
            }
          }
          &.credit-card-accepted{
            input{
              &.valid{
                background-image:none;
              }
            }
            span{
              &.credit-card-view{
                position: absolute;
                right: .125rem;
                top: 45%;
                display: block;
              }
            }
          }
          &.view-password{
            input{
              &.valid{
                background-image:none;
              }
            }
            span{
              &.icon-view-password{
                position: absolute;
                right: .125rem;
                top: 52%;
                cursor: pointer;
                display: block;
              }
            }
            span{
              &.icon-hide-view-password{
                position: absolute;
                right: .125rem;
                top: 52%;
                cursor: pointer;
                display: block;
              }
            }

          }
          &.view-confirm-password{
            input{
              &.valid{
                background-image:none;
              }
            }
            span{
              &.icon-view-confirm-password{
                position: absolute;
                right: .125rem;
                top: 52%;
                cursor: pointer;
                display: block;
              }
            }
            span{
              &.icon-hide-view-confirm-password{
                position: absolute;
                right: .125rem;
                top: 52%;
                cursor: pointer;
                display: block;
              }
            }

          }
          &.floating-label-datepicker{
            input{
              &.valid{
                background-image:none;
              }
            }
            .inner-icon {
              svg {
                stroke: @almost-black;
                *{
                  stroke: @almost-black;
                }
              }
            }
            .picker{
              .picker__holder{
                background: @white;
                border-top: none;
                .picker__wrap{
                  .picker__header{
                    min-height: 50px;
                    margin-top: 1.25em;
                    border-bottom: solid 1px @sand-white;
                    .picker__nav--next{
                      &:hover {
                        cursor: pointer;
                        color: #000;
                        background:none;
                      }
                      &:before{
                        border-left:0px;
                        width: 24px;
                        height: 24px;
                        background: url(../../assets/img/forms/icons/icon_arrow_right_dark_64.svg) no-repeat;
                        border-color: transparent;
                        border-width: 0;
                        background-size:24px;
                        right: 1rem;
                        position: relative;
                        *{
                         fill:@dark-gray;
                       }
                     }
                   }
                   .picker__nav--prev{
                     &:hover {
                      cursor: pointer;
                      color: #000;
                      background:none;
                    }
                    &:before{
                      border-left:0px;
                      width: 24px;
                      height: 24px;
                      background: url(../../assets/img/forms/icons/icon_arrow_left_dark_64.svg) no-repeat;
                      border-color: transparent;
                      border-width: 0;
                      background-size:24px;
                      left: 0.5rem;
                      position: relative;
                      *{
                       fill:@dark-gray;
                     }
                   }
                 }
                 select{
                   float: right;
                   border: none;
                   font-size: 16px;
                   -webkit-appearance: none;
                   -moz-appearance: none;
                   appearance: none;
                   cursor:pointer;
                   background: url(../../assets/img/forms/icons/Icon_arrow_down.svg) no-repeat;
                   border-color: transparent;
                   background-color: transparent;
                   background-position: right 10px;
                   background-size:16px;
                   color: @almost-black;
                   &:after{
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    border-width: 0;

                  }
                  &.picker__select--year{
                    margin-right: 30%;
                    margin-top: 0px;
                    width: 60px;
                    background-position: 45px 10px;
                  }
                  &.picker__select--month{
                    width: 25%;
                    margin-top: 0px;
                    margin-right: 3%;
                  }
                }
              }
            }
            .picker__day{
             padding: .6125em 0;
             font-weight: 400;
             &:hover {
              background: @Ivory-gray;
              color: @almost-black;
              border-color: @Ivory-gray;
            }
          }
          .picker__weekday{
           font-family: lato;
           font-size: 16px;
           color: @almost-black;
           text-transform: uppercase;
         }
         .picker__day--highlighted{
           &:before{
            display: none;
          }
          &:hover {
            background: @main-green;
            color: @white;
            border-color: @main-green;
          }
        }
        .picker__day--disabled{
         background: @sand-white-50;
         color: @almost-black;
         border-color: @white;
         &:hover {
          background: @sand-white-50;
          color: @almost-black;
          border-color: @white;
        }
      }
    }
  }
}
span{
  &.icon-view-password{
   display: none;
 }
}
span{
  &.icon-view-confirm-password{
   display: none;
 }
}
}
// Style for DropDown
.floating-label-select{
 border: none;
 background-color: transparent;
 margin-bottom: 1rem;
 &.set-language{
  margin-bottom: 4rem;
}
.select2-selection{
  border-bottom: 1px solid @texts-gray;
  height: 2.525rem;
  &:after {
   display: none;
 }
 .select2-selection__rendered{
   font-size: 20px;
   font-family: lato;
   height: 2.625rem;
   line-height: 2.625rem;
   color: @almost-black;
 }
}
.select2{
  &.select2-container{
   &.select2-container--default{
    &.select2-container--focus{
     top: 0px !important;
     padding-bottom: 0px;
   }

 }
}
}
.select2-container{
  &.select2-container--default{
   &.select2-container--open{
    top: 2.65rem !important;
    .select2-dropdown{
     margin-left: 0px;
     background-color: @white;
     .select2-results{

      ul{
       padding: 0px 1rem;
       li{
        font-family: lato;
        text-decoration: none;
        border-bottom:solid 1px @lighten-gray;
        background-position:right;
        padding: 0px;
        &.select2-results__option--highlighted{
         background-image: url(../img/forms/icons/tick.svg);
         background-repeat: no-repeat;
         background-position:right;
         background-size: 14px 14px;
       }
       &:first-child{
         border-bottom: none;
       }
       &:last-child{
         border-bottom: none;
       }
     }
   }
 }

}
.select2-selection__arrow{
 top: 3.66875rem;
 right: .9995rem;
 b{
  width: 16px;
  height: 16px;
  background: url(../../assets/img/forms/icons/Icon_arrow_up.svg) no-repeat;
  border-color: transparent;
  border-width: 0;
  background-size:16px;
  fill: blue;
  *{
   fill:@dark-gray;
 }
}
}
}
}
padding: 2.19rem 0 .8325rem 0;
padding-bottom: 0px;
.select2-selection__arrow{
 top: 3.66875rem;
 right: .9995rem;
 b{
  width: 16px;
  height: 16px;
  background: url(../../assets/img/forms/icons/Icon_arrow_down.svg) no-repeat;
  border-color: transparent;
  border-width: 0;
  background-size:16px;
}
}

}
&.has-accepted{
  select{
   color: @main-green;
   border-bottom: 1px solid @main-green;
   position: relative;
 }
 span{
   position: absolute;
   right: 0px;
   top: 40px;
   svg{
    * {
     stroke: @main-green;
   }
 }

}
}
&.has-error{
  select{
   color: @pink-dark;
   border-bottom: 1px solid @pink-dark;
   position: relative;
   &:valid{
    color: @pink-dark;
    border-color: @pink-dark;
  }
}
span{
 position: absolute;
 right: 0px;
 top: 40px;
 svg{
  * {
   stroke: @pink-dark;
 }
}

}
}
select{
  background-color: transparent;
  border-bottom: 1px solid @texts-gray;
  padding-left: 0px;
  padding-bottom: 0.7rem;
  box-sizing: border-box;
  outline: 0;
  box-shadow: none;
  color: @steel-grey;
  transition: .2s cubic-bezier(.4,0,.2,1);
  &:focus {
   outline: none;
   border-color: @main-green;
   border-width: 2px;
   color: @main-green;
 }
 &:valid{
   color: @main-green;
   border-color: @main-green;
   border-width: 1px;
   font-size: 20px;
 }
 &:focus~label{
   font-size: 12px;
 }
 &:disabled~label{
   font-size: 12px;
 }

}

select:focus ~ label,
select:required:valid ~ label,
select[readonly] ~ label,
label.floating-label,
select.selected ~ label {
  font-size: 12px;
  top: 16px;

}
select[readonly]{
  color: @almost-black;
  border-color: transparent;
  font-size: 20px;
  background-color: transparent !important;
}
&.disabled select,
select.disabled,
select:disabled {
  &:not(.datepicker) {
   background-color: transparent !important;
   -webkit-autofill: @disabled-input-color !important;
   border-bottom: 1px dotted @silver-gray;
   padding-left: 0px;
   padding-bottom: 0.5rem;
   color: @steel-grey;

 }
}
label {
  padding: 0px;
  letter-spacing: 0;
  color: @steel-grey;
  font-size: 20px;
  top: 12px;
}
span{
  &.outside-icon{
   top: 1.4rem;
 }
}

&.has-error-fields{
  .select2.select2-container .select2-selection--single .select2-selection__rendered{
    color: @pink-dark;
  }
  .alert-icon{
   svg{
    top: 2.5rem;
    right: 2.125rem;
  }
}
.alert-label{
 top: 4.9rem;
 text-align: left;
}

}
&.has-error-fields {
  .select2-container{
   &.select2-container--default{
    &.select2-container--open{
     .select2-selection__arrow{
      b{
       background: url(../../assets/img/forms/icons/Icon_arrow_up_red.svg) no-repeat;
     }
   }
 }
}
.select2-selection__arrow{
  b{
   background: url(../../assets/img/forms/icons/Icon_arrow_down_red.svg) no-repeat;
 }
}

}
.select2-selection{
 border-bottom: 1px solid @pink-dark;
}
border-color: @pink-dark;
input,
label,
textarea {
 color: @pink-dark !important;
}
.alert-icon {
 display: none;
 svg {
  fill: @pink;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 2.5rem;
  right: 2.125rem;
}
}
.alert-label {
 color: @pink-dark;
 line-height: 1rem;
 height: 1rem;
 margin: 0;
 padding: 0;
 width: 100%;
 position: absolute;
 top: 4.98rem;
 text-align: left;
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
 .body-small;
 svg {
  display: none;
  fill: @pink-dark;
  height: 1rem;
  margin-right: 0.5rem;
  position: absolute;
  top: 0;
  left: 0.5rem;
}
a {
  color: @pink-dark;
  text-decoration: underline;
}
}
.inner-icon {
 svg {
  fill: @pink-dark;
}
}
}

}



}
.floating-label-input{
  &.btn-upload-salary-certificate{
   background: none;
   border: none;
   margin-bottom: 1.5rem;
   .btn-request-token{
    &:hover{
     .outside-icon{
      svg{
       fill: @white;
     }
   }
 }
}
}
&.credit-card-view{
 input{

 }
}
}
.action-buttons-wrapper {
  padding-bottom: 1.4rem;
  padding-top: 1rem;
  &.action-buttons-primary{
   margin-top: 24px;
   padding-bottom:0;
   .action-buttons-container{
    .icon-primary{
     background: url(../../assets/img/forms/icons/icon_primary_account.svg) no-repeat;
     width: 24px;
     height: 24px;
     display: block;
     margin-right:10px;
   }
   .text-primary{
     color: @steel-grey;
     font-size: 16px;
   }
 }
}

}
.btn-request-token{
  .btn {
   height: 64px;
   padding: 0 2rem;
   display: block;
   width: 100%;
 }
}

.form-section{
  .corporate-employee-buttons{
   .form-submit{
    display: block;
    button {
     float: left;
     margin: 0px;
     padding: 0 2.8125rem;
     width: 48%;
     &.active{
      float: right;

    }
  }
}
}
.forgot-label{
 font-size: 14px;
 color: @steel-grey;
 margin-top: 10px;
 bottom: -3rem;
}
label{

 font-family: Lato;
 font-size: 1.25rem;
 text-align: left;
 &.control-label{
  color:@steel-grey;
  letter-spacing: normal;
  font-weight: 400;
  line-height:normal;
  &.or{
   line-height: normal;
   margin: 0px;
   margin-right: 20px;
   margin-top: 5px;
   color: @shady-gray;
 }
}
}
&.horizontal-checkboxes{
 margin-top: 15px !important;
 &.horizontal-checkboxes-errors{
  margin-top: 15px !important;
}
.checkbox, .radio {
  color: @almost-black;
  &:nth-child(1) {
   margin-top: 0;
   padding-top: 0;
   padding-left: 0px;
 }
 @media (min-width: @screen-md-min) and (min-width: @screen-xs-min) {
   padding-top: 0px;
   margin-top: 0px;
   margin-bottom: 0px;
   padding-bottom: 0px;
   line-height: normal;
   margin-left:0 !important;
   input {
    //display: none;
    &[type="radio"] {
     height: 100%;
     width: 100%;
     z-index: 10;
     margin-left: 0;
     display: block;
     margin: 0 auto;
     cursor: pointer;
     opacity: 0;
     + label {
      font-size: 1.25rem;
    }
    + label:before {
      border-radius: 50%;
      height: 24px;
      width: 24px;

    }
    &:checked + label:before {
      background-image: url(../img/forms/icons/icon_checkbox_radio_selected_dark_64.svg);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 28px 28px;
      border: none;
    }
  }
  &[type="checkbox"] {
   + label:before {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-top: -12px;
  }
  &:checked + label:before {
    background-image: url(../img/forms/icons/icon_checkbox_radio_selected_dark_64.svg);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 28px 28px;
    border: none;
  }
}
}
}
}
&.horizontal-actions{
  label{
   font-size: 1rem;
 }
}
&.has-error-fields{
  .floating-label{
   color: @pink-dark;
 }
 .has-error{
   color: @pink-dark;
   margin-top: 10px;
   clear: both;
   display: block;
   position: absolute;
   max-width: 400px;
   top: 20px !important;
   padding-left: 0px;
   float: none;
   font-size: .75rem;
 }
}
.checkbox{
  margin-left: 2.5rem;
  display: inline-block;
  line-height: normal;
  label{
   letter-spacing: normal;
   color: @gray-fill;
 }
}
.radio{
  margin-left: 2.5rem;
  display: inline-block;
  line-height: normal;
  label{
   letter-spacing: normal;
   padding-top: 0px;
   padding-bottom: 0px;
 }
}
.view-only{
  margin-left: 1.25rem;
  label{
   color: @almost-black;
   line-height:normal;
   font-weight: 400;
 }
}
}
}
.smiles-newsletter-card{
  background: @light-sand-brown;
  padding: 40px;
  text-align: left;
  margin-bottom: 30px;
  p{
   color: @dark-gray;
   font-size: 16px;
   margin-bottom: 24px;
   font-family: Lato;
 }
 button{
   padding: 0 2.8125rem;
   font-size: 14px;
   height: 4.25rem;
   &.btn-news-submit{
    width: 100%;
    margin-top: 8px;
  }
}

}
.consumer-register-form-submit{
  &.form-submit{
   display: block;
   text-align: left;
   padding: 1.750em 0;
   height: auto;

   button{
    height: 4rem;
    line-height: 3.9rem;
    min-width: 280px;

  }
}
}

}
}
}
.mb0{
 margin-bottom: 0px !important;
}
.mt20{
 margin-top: 20px !important;
}

.sc-b2c-checkout {
 .edit-mr {
  width: 350px;
}

}

@media (max-width: 767px) {
 .modified-form-R0{
  .consumer-register-form {
   .form-container{
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

}

}
}

@media (min-width: @screen-xs-min) {
 .modified-form-R0{
  .consumer-register-form {
    .form {
      .row {
        > .form-section {
          margin: 0;
          padding: 0;
          &:nth-child(1) {
            padding-right: 0;
          }
          &:nth-child(2),
          &:nth-child(3) {
            padding-left: 0;
          }
          &.single-section {
            padding: 0;
          }
          .floating-label-input span.outside-icon{
            top: 34%;
          }
        }
      }
    }
  }
}

}

@media (min-width: @screen-sm-min) {
  .modified-form-R0{
    .consumer-register-form {
      .form {
        .row {
          > .form-section {
            margin: 0;
            padding: 0;
            &:nth-child(1) {
              padding-right: 1.75rem;
            }
            &:nth-child(2),
            &:nth-child(3) {
              padding-left: 1.75rem;
            }
            &.single-section {
              padding: 0;
            }
          }
        }
      }
    }
  }

}

@media (min-width: @screen-md-min) {
  .modified-form-R0{
    .consumer-register-form {
      .form {
        .row {
          > .form-section {
            margin: 0;
            padding: 0;
            &:nth-child(1) {
              padding-right: 1.75rem;
            }
            &:nth-child(2),
            &:nth-child(3) {
              padding-left: 1.75rem;
            }
            &.single-section {
              padding: 0;
            }
          }
        }
      }
    }
  }

}

/* -----------------------------------------
08 - Arabic overrides
----------------------------------------- */
*[lang="ar"] {
  *[dir="rtl"] {

   .modified-form-R0 {
    .form{
      &.modified-form {
        .form-section {
          .floating-label-input {
            input{
              padding-right: 0px;
              &.valid{
                background-position: 1% 43px;
              }
            }
            &.floating-label-datepicker{
              .picker {
                .picker__holder{
                 .picker__wrap{
                   .picker__header{
                     select{
                      background-position: left 10px;
                    }
                  }
                }
              }
            }
          }
        }
        .floating-label-select {
          .select2-container{
            .select2-selection__arrow{
              right: .9995rem;
              left : auto;

            }
            &.select2-container--default{
              &.select2-container--open{
                .select2-dropdown {
                  .select2-results {
                    ul {
                      li{
                        background-position:left;
                        &.select2-results__option--highlighted{
                          background-position:left;
                        }
                      }
                    }
                  }
                }
              }
            }

          }

        }
      }


      span{
        &.inner-icon{
          right: auto;
          left: .125rem;
        }
      }
    }

  }

}
.r0-redesign-compare-action-bar-wrapper {
  .searchPlanField {
    margin-left: 0;
    margin-right: 5%;
    .searchFieldIcon {
      left: 0;
      right: auto;
    }
  }
  .sortingDropdown {
    margin-left: 0;
    margin-right: 5%;
    .form-group {
      &.floating-label-select {
        float:left;
      }
    }
  }
  .modified-form-R0 {
    .form.modified-form {
      .form-section {
        .floating-label-input {
          input {
            padding-left: 2.5rem;
            padding-right: 0;
          }
        }
        .floating-label-select {
          label {
            left: 0; right:auto
          }
          .select2-selection {
            .select2-selection__rendered {
             text-align: left;
           }
         }
         .select2-container.select2-container--default.select2-container--open {
          .select2-dropdown .select2-results ul li.select2-results__option--highlighted {
           background-position: 0;
         }
       }
     }
   }
 }
}
}
.plans-table {
  &.plans-table-R0-A {
    .detail-info-wrap-pricetag {
      .main-part {
        .price {
          padding-right: 0;
          padding-left: .125rem;
        }
      }
    }
  }
}
}
}
