.config-section-title-wrapper {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  &.title-no-top-padding{
    padding-top: 0;
  }
  &.bg-sandy{
    background-color: @white;
    position: relative;
    z-index: -1;
    margin-bottom: 2rem;
    & + .config-section-title-wrapper{
      margin-top: -2rem;
    }
  }
  &.config-section-title-small{
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  >.row {
    display: flex;
    align-items: stretch;
  }

  >.row-block {
    display: block;
    align-items: stretch;
  }
  .row-heading{
    margin-top: 1.575rem;
  }
  h4, h5{
    margin: 0;
    color: @almost-black;
    font-size: 1.125rem;
  }
  h4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  h4+h5{
    font-size: 0.75rem;
    margin-top: 0.375rem;
    color: @steel-grey;
    font-family: @font-family-sans-serif;
    letter-spacing: 0.046875rem;
    .opacity(0.9);
  }

  &.config-section-title-wrapper--no-space-bottom{
    padding-bottom:0;
    margin-bottom:0;
    h4{
      margin-bottom: 0;
    }
  }

    a.link-and-icon{
      color: @almost-black;
          display: inline-flex;
      .link{
        text-decoration: underline;
        text-transform: uppercase;
        padding: 0;
            font-size: .8125rem;
            letter-spacing: 1.6px;
      }
      .icon{
            margin: 0 1rem;
        height: 1.4rem;
        width: 1.4rem;
      }
    }

  h5{
    color: @steel-grey;
  }
  .star-icon{
    fill: @main-green;
    width: 0.608125rem;
    height: 0.606875rem;
    top: -0.5rem;
    position: relative;
    display: none;
  }
  .alert-badge{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    svg {
      fill: @pink;
      width: 2.8125rem;
      height: 2.8125rem;
      display: none;
    }
  }

  &.required{
    .star-icon{
      display: inline;
    }
  }

  &.error{
    h3, h4, h5{
      color: @pink;
    }
    .star-icon{
      fill: @pink;
    }
    .alert-badge{
      svg{
        display: block;
      }
    }
  }
  &.filters{
    background-color: @sand-white;
    text-align: center;
    h4{
      color: @almost-black;
    }
    .filters-selected {
      padding-top: 1.875rem;
      display: block;
      .filter-item {
        background: @dark-green;
        padding: 0.3125rem 1.0625rem;
        border-radius: 2rem;
        font-size: 0.875rem;
        color: @white;
        font-family: Neotech, Roboto, Arial, sans-serif;
        margin-right: 1rem;
        margin-bottom: 0.875rem;
        display: inline-block;
        svg {
          stroke: @white;
          width: 0.759375rem;
          height: 0.759375rem;
          margin-left: 0.625rem;
        }
      }
    }
  }
}


.business{
  .config-section-title-wrapper {

    background-color: @sand-white;

    .numberAccounts{
      color: @dark-green;
    }
    h4{
      color: @black;
    }
    h5{
      color: @steel-grey;
    }
    &.error{
      h3, h4, h5{
        color: @pink;
      }
      .star-icon{
        fill: @pink;
      }
      .alert-badge{
        svg{
          display: block;
        }
      }
    }
    &.bg-sandy{
      background: @light-sand-brown;
    }
    &.config-section-title-small{
      h4{
        color: @almost-black;
      }
    }
  }
}
@media (min-width: @screen-ms-min) {}

@media (max-width: @screen-xs-max) {
  .svpnHeading {
    flex-direction: column;
    h4 {
      margin-bottom: 20px;
    }
  }
  .config-section-title-wrapper h4, .config-section-title-wrapper h5 {
      font-size: 1.25rem;
  }
 }
 @media (max-width: @screen-ms-max) {
   .config-section-title-wrapper h4, .config-section-title-wrapper h5 {
      font-size: 1.25rem;
      padding-left: 1rem;
      padding-right: 1rem;
   }
  }


@media (min-width: @screen-sm-min) {
  .config-section-title-wrapper {
    padding-top: 1.625rem;
    padding-bottom: 1.625rem;
    h4{
      font-size: 1.25rem;
    }
    h4+h5{
      font-size: 1rem;
      letter-spacing: 0.0625rem;
    }
    h5{
      padding-left:1rem;
      padding-right:1rem;
    }
    &.filters{
      text-align: left;
      .filters-selected {
        padding-top: 0.9375rem;
      }
    }
    &.config-section-title-wrapper--no-space-bottom{
      padding-bottom:0;
      margin-bottom:0;
      h4{
        margin-bottom: 0;
      }
    }
  }
}

@media (min-width: @screen-md-min) {}

@media (min-width: @screen-lg-min) {
  .config-section-title-wrapper {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    h4{
      font-size: 1.5rem;
    }
    h4+h5{
      font-size: 1.25rem;
      letter-spacing: 0.078125rem;
      margin-top: 0.625rem;
    }
    &.bg-sandy{
      background: @sand-white-50;
    }
    &.filters{
      text-align: left;
      .filters-selected {
        padding-top: 1.625rem;
      }
    }
    &.config-section-title-wrapper--no-space-bottom{
      padding-bottom:0;
      margin-bottom:0;
      h4{
        margin-bottom: 0;
      }
    }
  }
}


*[lang="ar"] {
  *[dir="rtl"] {

    .config-section-title-wrapper {

      h4+h5{
        font-family: @font-arabic;
      }
      &.filters{
        .filters-selected {
          .filter-item {
            font-family: @font-arabic;
            margin-right: 1rem;
            margin-left: auto;
            svg {
              margin-right: 0.625rem;
              margin-left: auto;
            }
          }
        }
      }
    }
    .config-section-title-wrapper a.link-and-icon{
          flex-direction: row-reverse;

    }
  @media (min-width: @screen-ms-min) {
    .align_flex-ar{
      display: block;
    }
  }

  @media (min-width: @screen-ms-min) {
    .align_flex-ar{
      display: block;
    }
  }

    @media (min-width: @screen-sm-min) {
      .align_flex-ar{
        display: flex;
      }
      .config-section-title-wrapper {

        &.filters{
          text-align: right;
        }
      }
    }

    @media (min-width: @screen-md-min) {
      .align_flex-ar{
        display: flex;
      }
    }

    @media (min-width: @screen-lg-min) {
      .config-section-title-wrapper {
        &.filters{
          text-align: right;
        }
      }
    }


  }
}
.config-section-title-wrapper .alert-badge .svpnSearch {
  width: 80%;
  svg {
    display: block;
  }
}
