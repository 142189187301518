.row.freedomform {
    padding-top: 20px;
    .detail-info-wrap-pricetag {
      .main-part {
          .price {
            font-size: 38px;
            margin-top: 10px;
          }
        }
    }
    @media (max-width: 767px){
      .floating-label-input {
        margin: 0 0 8px !important;
      }
    }

}
.freedomFormSubmit button {
  display: block;
  margin: auto;
  @media (max-width: 767px){
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 14px;
  }
}
.freedompage {
  #fullwidthMobile {
  @media (max-width: 550px) {
    width: 100% !important;
  }
}


.table-bugfixing .charges-table-section table tbody td, .table-bugfixing .charges-table-section table tbody th, .table-bugfixing .charges-table-section table thead td, .table-bugfixing .charges-table-section table thead th {
  @media (max-width: 550px) {
    width: 100% !important;
  }
}

.table-bugfixing .charges-table-section table tbody td, .table-bugfixing .charges-table-section table tbody th, .table-bugfixing .charges-table-section table thead td, .table-bugfixing .charges-table-section table thead th {

  @media (max-width: 550px) {
    padding: 0.2rem 0;
  }
}


  .swiper-container.swiper-container-horizontal {
    @media (max-width: 768px) {
      width: 600px;
    }
  }
  .media-cta-section.two-col .media-cta-wrapper .body-standard {
    @media (max-width: 768px){
      padding: 42px 24px 0 24px;
    }
  }
  .media-cta-wrapper .learn-more-link {
    @media (max-width: 768px){
          left: 24px;
    }
  }
  .media-cta-wrapper>div {
    @media (max-width: 768px){
      height: 270px!important;
    }
  }
.cta-benefits.two-col .media-cta-wrapper, .media-cta-section.two-col .media-cta-wrapper {
  @media (max-width: 768px){
      margin-bottom: 2rem !important
  }
}
.tile-expansion-section-bugfixing .tile-expansion-section {
  @media (max-width: 768px){
        margin-top: 0rem !important;
  }
}


  .pt-100 {
      @media (max-width: 768px){
        padding-top: 20px !important;
      }
  }
  .media-cta-section.two-col {
    background-color: #fff !important;
    padding: 1rem 0 !important;
  }
  .spacefromLeft {
      padding-right: 12px;
      @media (max-width: 768px){
        padding-right: 0;
      }
  }
  .spacefromRight {
    padding-left: 12px;
    @media (max-width: 768px){
      padding-left: 0;
    }
  }
  .context-navigation-bugfixing.context-navigation.no-slide .swiper-container .swiper-slide .bring-value-card {
    max-width: 19.4rem;
  }
  @media (max-width: 767px){
    .table-bugfixing {
      td {
        height: auto !important;
      }
    }
  }
  #freedompnext{
    right: -1rem !important;
  }
  #freedomprev{
      left: -1rem !important;
  }

  .emirati-plan-tiles {
    .tile-box-tile {
      max-height:331px!important;
    }
  }

  .context-navigation-bugfixing {
    .context-navigation-cards {
      overflow: hidden;
    }
  }
  .freedomLoadMore {
    display: none;
  }
  .swiperHideMore .swiper-slide:nth-last-child(-n+3){
    display: block;
  }
  @media (max-width: 767px){
    .free-apple-freedom {
      div.cover {
        height: 64vw;
        position: relative;
      }
      img.cover {

        //position: inherit;
      }
    }
    .context-navigation.no-slide  {
      padding-left: 8.3333%;
      padding-right: 8.3333%;
    }
    .freedom-plans-margins .pull-right-ar:nth-last-child(-n+3) {
        display: none;
    }

    .swiperHideMore .swiper-slide:nth-last-child(-n+3){
      display: none;
    }

    .freedomLoadMore {
      display: block;
      a {
        display: block;
        margin-left: 0;
        margin-right: 0;
        display: block;
        margin-bottom: 30px;
        margin-top: 5px;
        height: 60px;
        line-height: 60px;
        font-size: 14px;
        letter-spacing: 2.33px;
      }
    }

  }

}
.maxWidth1200 {
    max-width: 1400px;
    margin: auto;
}
.welcome-tiles-intro{
  padding: 2rem 0px;
  @media (max-width: 767px){
    text-align: left !important;
  }
  h3{
    font-size: 32px;
    color:#353738;
    letter-spacing: 3.93px;
    margin-top: 0px;
    @media (max-width: 767px){
      font-size: 20px;
      letter-spacing: 2.46px;
      margin-bottom: 8px;
    }
  }
  p{
    color: #9b9b9b;
    /*margin-bottom: 1rem;*/
    font-size: 20px;
  }
  @media (max-width: 767px){
    p{
      color: #6f7273;
      font-size: 14px;
    }
  }
  .welcome-intro-tile{
    margin: 0px 10px;
    height: 90%;
    margin-top: 1rem;
    padding: 0.8rem 1rem;
    background-color: @white;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
    transition: all 0.5s ease;
    display: block;
    min-height: 10.5625rem;
    color: @almost-black;
    @media (max-width: 767px){
      margin-left: 0px;
      margin-right: 0px;
      min-height: 8.5rem;
    }
    h4{
      font-size: 24px;
      letter-spacing: 0px;
      @media (max-width: 767px){
        letter-spacing: 0px;
        font-size: 18px;
        font-weight: 500;
      }
    }
    p{
      font-size: 16px;
    }
    @media (max-width: 767px){
      p{
        font-size: 14px;
      }
    }
  }
  .main-text{
    padding-bottom: 0px;
    padding-top: 0rem;
    @media (max-width: 767px){
      padding-top: 2rem;
      .btn{
        width: 100%;
      }
    }
    .btn{
      min-width: 250px;
    }
  }
}

.size12 {
  font-size: 14px !important;
}
.flexiBox {
  display: flex;
}
.imageHeight {
  height: auto;
}
.plans-table-freedom{
  .freedom-plans-margins{
    margin: 0px -10px;
  }
  .swiper-slide{
    width: 100%;
    color:@almost-black;
    padding: 0px 10px;
  }
  &.plans-table-freedom-home{
    .swiper-slide{
      width: auto;
    }
  }

  &.plans-table{
    &.plans-table-A{
      .table-swiper-button-prev,.table-swiper-button-next {
        width: 44px;
        height: 44px;
        border: 2px solid #979797;
        float: left;
        cursor: pointer;
        text-align: center;
        line-height: 50px;
        z-index: 5;
        &.swiper-button-disabled{
          border-color: #cacaca;
          z-index: 0;
          position: relative;
          svg{
           stroke: #cacaca;
          }
        }
        svg{
          stroke: @almost-black;
          width: 1.1rem;
          height: 1.3rem;
          stroke: #353738;
          stroke-width: 5px;
        }
     }
     .table-swiper-button-next {
       margin-left: -2px;
     }
      .tile-table{
        margin-bottom: 1rem;
        @media (max-width: 767px){
          width: 97% !important;
          padding: 0 5px;
        }
        .tile {
          box-shadow: none;
          .tiles-box{
            border: none;
            box-shadow:0 5px 10px 0 rgba(0,0,0,.1);
            margin: 2px;
            border-top: solid 7px @light-sand-brown;
            height: auto;
            .read-more{
              height: 3.75rem;
            }
            #read-more-triangle-img{
              top: -2px;
              fill: @light-sand-brown;
            }
            .tiles-box-title{
              padding: 2rem 1rem;
              display: block;
              text-align: center;
              height: 8.5rem;
              @media (max-width: 767px){
                padding: 2rem 0rem;
                height: auto;
              }
              h3, h2{
                color:@almost-black;
                text-align: center;
                font-size: 3.375rem;
              }
              h3{
                margin-right: 0px;
                margin-bottom: .5rem;
                font-size: 20px;
                letter-spacing: 0px;
                padding-right: 0px;
              }
              h2{
                line-height: 4.25rem;
              }
            }
            .tiles-box-list{
              margin: 0 1rem;
              height: 21rem;
              @media (max-width: 768px){
                height: 18rem;
              }
              .tiles-box-line{
                border-bottom: solid 1px @light-sand-brown;
                margin-bottom: 1rem;
                height: 2rem;
                h6{
                  letter-spacing: 0px;
                  font-size: 1rem;
                  color: #848789;
                  .tiles-box-number {
                     font-size: 1.5rem;
                     @media (max-width: 768px){
                       font-size: 1.1rem;
                     }
                   }
                   @media (max-width: @screen-sm-min) {
                    font-size: .82rem;
                    padding-right: 0!important;
                   }
                }
                .price-or{
                  margin: 0px 0.5rem;
                  @media (max-width: 768px){
                    margin: 0px 0.3rem;
                  }
                }
              }
              .detail-info-wrap-pricetag{
                justify-content: space-between;
                height: 6rem;
                align-items: center;
                .price-contract{
                  display: block;
                  font-size: 16px;
                  margin-bottom: 7px;
                }
                .detail-price-new{
                  .main-part {
                    .price-specs{
                      font-size: 14px;
                    }
                    .price{
                      font-size: 3rem;
                      margin-right: 0.5rem;
                      margin-left: 0;
                      @media (max-width: 1024px){
                        font-size: 2.375rem;
                      }
                    }
                  }
                }

              }
            }
          }
        }
      }
    }
  }
}
.wst{
  &.freedom{

    .plans-table-freedom{
      &.plans-table{
        &.plans-table-A{
          .tile-table{
            .tile{
              .tiles-box{
                .tiles-box-list{
                  padding: .5rem 0 !important;
                   .detail-info-wrap-pricetag{
                      .detail-price-new{
                         .main-part{
                            .price{
                              font-size: 2.35rem !important;
                              margin-right: 0 !important;
                              @media (max-width: @screen-sm-min) {
                                font-size: 2rem !important;
                              }
                            }
                         }
                      }
                   }
                   .price-contract{
                    margin-bottom: 2px !important;
                   }
                }
                .tiles-box-title{
                  padding: 1.7rem 1rem;
                }
                .read-more{
                  font-size: .9rem !important;
                }
              }
            }

          }
        }
      }

    }
  }
}
.freedom-overlay {
  .plans-table-freedom.plans-table {
    padding: 0px;
  }
}
.all-prices-exclusion{
  font-size: 14px;
  padding-bottom: 2rem;
  padding-top:1.25rem;
  text-align: center;
  color: @steel-grey;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px){
    align-items: self-start;
  }
  span{
    margin-right: 7px;
    display:inline-block;
    margin-top: 4px;
  }
}
.free-apple-freedom{
  background-color: @white;
  box-shadow:0 5px 10px 0 rgba(0,0,0,.1);
  transition: all 0.5s ease;
  border-radius: 5px;
  img{
    height: 395px;
    object-fit: cover;
    width: 100%;
    @media (max-width: 767px){
      height: 273px;

    }
  }

  .free-apple-music-body{
    padding: 4.6rem 4rem 3rem 4.6rem;
    min-height: 273px;
    b {
      color:#353738;
    }
    @media (max-width: 1366px){
      padding: 4rem 3rem 3rem 4rem;
    }
    @media (max-width: 767px){
      min-height: 273px;
      padding: 1rem;
      min-height: inherit;
    }
    h2{
      color: #353738;	font-family: @font-family-base;	font-size: 26px;	font-weight: bold;	line-height: 22px;letter-spacing: normal;
      @media (max-width: 767px){
        font-size: 18px;
      }
    }
    h4{
      font-size: 28px;
      margin-bottom: 1rem;
      letter-spacing: 0px;
      margin-top: 0px;
      @media (max-width: 767px){
        font-size: 22px;
        letter-spacing: 0px;
        font-family: Neotech,Roboto,Arial,sans-serif !important;
      }
    }
    p{
      color: @steel-grey;
    margin-bottom: 0;
    font-size: 16px;
    font-family: @font-family-base;
    }
    ul{
      margin-left: 0px;
      padding-left: 20px;
      min-height: 73px;
      li{
        color: @steel-grey;
        font-size: 14px;
      }
    }
  }
}
.free-apple-freedom.smallBox {
  img {
    height: 250px;
  }
  .free-apple-music-body {
    padding: 2rem 2rem 1rem 1.6rem;
    min-height: 250px;
  }
}

.emirati-plan-tiles {
  .tile-box-tile {
    .tile-box-body{
      p {
        font-size: 14px !important
        }
      }
    }
}
.need-help{
  .btn{
    margin-top: 1rem;
    margin-bottom: 0;
    height: 64px !important;
    font-size: 14px;
    line-height: 65px;
    border-radius: 0 !important;
  }
  .help-links {
    list-style: none;
    li {
      display:inline-block;
      text-align: center;
    }
    img {
      margin-bottom: 1rem;
    }
  }

}
@media (max-width: 767px){
  .flexiBox {
    display: block;
  }
  .context-navigation-bugfixing .context-navigation-cards {
    padding-bottom: 0px !important;
    padding-top:15px !important;
  }
  .imageHeight {
    height: 273px;
  }
}
.get-you-line{
  background-color: rgba(242,242,242,0.33);
  padding: 2rem 0px;
  .free-apple-freedom{
    background-color: transparent;
    box-shadow: none;
    border-radius: 0px;
    .free-apple-music-body{
      padding: 2rem;
      @media (max-width: 767px){
        padding: 0rem;
            padding-bottom: 1rem;
      }
      h5{
        color: @steel-grey;
        font-size: 12px;
        letter-spacing: 4px;
        margin-top: 0px;
        text-transform: uppercase;
        font-family: @font-family-base;
        margin-bottom: 6px;
      }
    }
  }
}
.freedom-choosen-form{
  padding: 2rem;
  @media (max-width: 767px){
    padding: 0px;
  }
  .form-title{
    p{
      font-size: 16px;
      color: @steel-grey;
      font-family: @font-family-base;
      margin-bottom: 1rem;
      @media (max-width: 767px){
        text-align: left;
        font-size: 14px;
        margin-bottom: 0px;
      }
    }
  }
  .freedom-choosen-form-promo-box{
    padding: 2rem;
    background-color: @white;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
    transition: all 0.5s ease;
    display: block;
    margin-bottom: 2rem;
    @media (max-width: 767px){
      padding: 1rem;
      margin-bottom: 18px;
    }
    h5{
     font-size: 16px;
     color: @steel-grey;
    }
    h4{
      color: @almost-black;
      font-size: 24px;
      margin-top: 0px;
      @media (max-width: 767px){
        font-size: 20px;
      }
    }
    span{
      display: block;
      font-size: 16px;
      color: @steel-grey;
      @media (max-width: 767px){
        font-size: 12px;
      }
    }
    .detail-info-wrap-pricetag{
      justify-content: flex-end;
      align-items: flex-end;
      min-height: 8rem;
      @media (max-width: 767px){
        justify-content: flex-start;
        align-items: center;
        min-height: 6rem;
      }
    }

  }
  .pr-0{
    padding-right: 0px !important;
  }
}

// *************************************
// checkboxes and radio buttons
// *************************************
.checkboxes-wrap,
.radio-wrap {
  position: relative;
  label {
    display: flex;
    min-height: 56px;
    padding-left: 36px;
    align-items: center;
    font-size: 1rem;
    line-height: 19px;
    font-family: @font-family-base;
    color: @steel-grey;
    font-weight: normal;
    //border-bottom: 1px solid #d2d2d2;
    margin-bottom: 0;
    margin-top: 0;
    &:before {
      background-image: url(../../assets/img/wst-icons/icon-checkbox-unselected.svg);
      content: "";
      width: 24px;
      height: 24px;
      display: block;
      background-size: 24px;
      margin-right: .5rem;
      position: absolute;
      left: 0;
      transition: all .25s cubic-bezier(.55,.06,.68,.19);
      box-shadow: 0 2px 5px 0 rgba(181,181,181,0.5);
      border-radius: 50%;
    }
  }
  input[type=checkbox] {
    &:checked+label {
      &:before {
        background-image: url(../../assets/img/wst-icons/icon-checkbox.svg);
        background-repeat: no-repeat;
      }
    }
    &:disabled+label {
      &:before {
        background-image: url(../../assets/img/wst-icons/icon-checkbox-disabled.svg);
        background-repeat: no-repeat;
      }
    }
  }

  input[type=radio] {
    &:checked+label {
      &:before {
        background-image: url(../../assets/img/wst-icons/icon-radio-selected.svg);
        background-repeat: no-repeat;
      }
    }
    &:disabled+label {
      &:before {
        background-image: url(../../assets/img/wst-icons/icon-checkbox-disabled.svg);
        background-repeat: no-repeat;
      }
    }
  }
}

input[type=checkbox],
input[type=radio] {
  display: none;
}

// *************************************
// checkboxes and radio buttons with TILES
// *************************************
.radio-tile-wrap,
.checkbox-tile-wrap {
  margin-bottom: 2rem;
  label {
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    background-color: @white;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);
    margin: 0;
    padding: 0 .875rem;
    color: @gray-fill;
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 1rem;
    border: 1px solid @white;
    font-family: @headings-font-family;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    transition: all .2s ease;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    min-width: 130px;
  }
  input[type=checkbox]:checked,
  input[type=radio]:checked {
    & + label {
      color: @brand-primary;
      border: 1px solid @brand-primary;
      text-shadow: 0 0 0 @brand-primary;
    }
  }
}
.multi-tiles-wrap {
  display: flex;
  width: 100%;
  align-items: center;
  overflow: auto;
  &.justified {
    justify-content: space-between;
  }
  .checkbox-tile-wrap,
  .radio-tile-wrap {
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
// *************************************
// category list
// *************************************
.category-list-wrap {
  &.no-lines {
    .checkboxes-wrap label,
    .radio-wrap label {
      border-bottom: none;
    }
  }
  &.offers {
    margin-bottom: 0;
  }
  margin-bottom: 2rem;
  &.btn-text {
    &:before {
      padding: 3px;
    }
  }
  ul {
    margin-bottom: 1.5rem;
    margin: 0;
    padding: 0;
    list-style: none;
    li:nth-child(n+6) {
      display: none;
    }
    &.full-list {
      li:nth-child(n+6) {
        display: block;
      }
    }
  }
  .toggleView {
    &:before {
      transform: translateY(-80%) rotate(45deg);
      -webkit-transform: translateY(-80%) rotate(45deg);
    }
    .viewless {
      display: none;
    }
    .viewmore {
      display: block;
    }
  }
  .viewmore {
    display: none;
  }
}

.category-head-collapisble {
  margin-bottom: .7rem;
  &.default,
  &.expandable {
    font-size: 19px;
    color: @black;
    line-height: 23px;
    letter-spacing: 0.76px;
    font-family: @font-family-base;
    display: block;
    position: relative;
  }
  &.expandable {
    &.collapsed {
      &:after {
        transform: translateY(-80%) rotate(45deg);
        -webkit-transform: translateY(-80%) rotate(45deg)
      }
    }
    &:after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      right: 3px;
      border: solid #353738;
      border-width: 0 1px 1px 0;
      padding: 5px;
      position: absolute;
      line-height: 1;
      -webkit-transform: translateY(-20%) rotate(-135deg);
      top: 50%;
      transform: translateY(-20%) rotate(-135deg);
      transition: all .3s ease-in-out;

    }
  }
}
// *************************************
// filters and reset section
// *************************************

.filter-icon,
.reset,
.compare-icon,
.sort-icon {
  margin-bottom: 1.5rem;
  @media (max-width: @screen-md) {
    margin: .9rem 0;
  }
  a {
    font-size: 1rem;
    font-family: @font-family-base;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: @almost-black;
    display: flex;
  }
}
.filter-icon,
.compare-icon,
.sort-icon{
  a {
    &:before {
      width: 24px;
      height: 24px;
      display: block;
      background-repeat: no-repeat;
      margin-right: .5rem;
    }
  }
}

.filter-icon a {
  &:before {
    content: "";
    background: url(../../assets/img/wst-icons/icon-filter.svg);
    background-size: 24px;
  }
}

.compare-icon a {
  &:before {
    content: "";
    background: url(../../assets/img/wst-icons/icon-refresh.svg);
    background-size: 24px;
  }
}

.sort-icon a {
  &:before {
    content: "";
    background: url(../../assets/img/wst-icons/icon-up-down.svg);
    background-size: 24px;
  }
}
// *************************************
// filters, compare and sort section for mobile view
// *************************************
.filter-compare-sort-section {
  display: -webkit-flex; /* Safari */
  -webkit-justify-content: space-between; /* Safari 6.1+ */
  display: flex;
  justify-content: space-between;
  &.single {
    @media (max-width: @screen-md) {
      justify-content: center;
    }
  }
}
// *************************************
// filters overlay's
// *************************************
.filter-close {
  display: none;
}
.responsive-filters,
.responsive-sort {
  &.mobileViewActive {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: @white;
    overflow: auto;
    padding: 1rem;
    z-index: 9999;

    .filter-icon,
    .reset,
    .compare-icon,
    .sort-icon {
      margin-top: 0 !important;
    }
    .filter-compare-sort-section {
      justify-content: center;
      border-bottom: 2px solid #E2E1DA;
      margin-bottom: 1.5rem;
      display: flex;
    }
    .filter-close {
      display: block;
      right: 0;
      position: absolute;
      margin-right: 1rem;
      &:before {
        background-image: url(../../assets/img/buy-get/Icons/SVG/icon-close-grey.svg);
        content: "";
        width: 24px;
        height: 24px;
        display: block;
        background-size: 24px;
        stroke-width: 2px;
        margin-right: .5rem;
        right: .75rem;
      }
      img, svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    .apply-reset-section,
    .show-in-mobile-view-only{
      @media (max-width: @screen-md) {
        display: block;
      }
    }
  }
  .filter-compare-sort-section {
    display: none;
  }
}

.apply-reset-section {
  display: none;
  .reset {
    text-align: center;
    justify-content: center;
    display: flex;
    margin: 1rem 0;
    a {
      cursor: pointer;
    }
  }
}

.show-in-mobile-view-only {
  @media (max-width: @screen-md) {
    display: none;
  }
}



// arabic language
*[lang="ar"] {
  *[dir="rtl"] {
    .pick-freedom-overlay {
      h5.freedomDetailsDesktop {
        text-align: right !important;
      }
      .overlay-head .close {
       left: 0 !important;
      right: inherit !important;
      }
      a.freedom-plans-toggle:before {
        left: 8px !important;
    }
  }
    .freedomDetailsMobile {
      @media (max-width: @screen-md) {
        text-align: right !important;
      }
    }
    .pick-freedom-overlay.pick-freedom-overlay-section .body-content .pick-freedom-contract .plans-table-freedom.plans-table.plans-table-A .tile-table .tile .tiles-box .tiles-box-title a.freedom-plans-toggle {
      @media (max-width: @screen-md) {
        right: inherit !important;
        left: 0;
      }
    }
      .checkboxes-wrap label::before, .radio-wrap label::before {
        @media (max-width: @screen-md) {
          right: 0 !important;
          left: inherit !important;
      }
    }

    .pick-freedom-overlay .checkboxes-wrap label {
      @media (max-width: @screen-md) {
        padding-left: 0 !important;
        padding-right: 46px !important;
        }
      }
      .pick-freedom-overlay .radio-wrap label::before {
        @media (max-width: @screen-md) {
        right: 0 !important;
        left: inherit !important;
      }
    }


    .welcome-tiles-intro h3 {
      @media (max-width: @screen-md) {
        text-align: right;
      }
    }
    .welcome-intro-tile {
      @media (max-width: @screen-md) {
        text-align: right !important;
      }
    }
    .emirati-plan-tiles .tile-box-tile .tile-box-body {
      text-align: right;
    }
    .table-bugfixing .charges-table-section table tbody td, .table-bugfixing .charges-table-section table tbody th, .table-bugfixing .charges-table-section table thead td, .table-bugfixing .charges-table-section table thead th {
      text-align: right;
    }
    .table-bugfixing .charges-table-section table tbody td, .table-bugfixing .charges-table-section table tbody th {
      font-family:   Neotech-medium,GE SS Two,Arial,sans-serif;
    }
    .plans-table-freedom {
      .price {
        margin-right: 0;
        margin-left: .5rem;
      }
    }

    .wst{

      .free-apple-freedom {
        .free-apple-music-body {
           h2 {
             text-align: right;
           }
        }
      }
      @media (max-width: 768px) {
        .filter-icon,
        .compare-icon,
        .sort-icon{
          a {
            &:before {
              margin-left: .5rem;
              margin-right: 0;
            }
          }
        }

        .checkboxes-wrap,
        .radio-wrap {
          position: relative;
          label {
            padding-left: 0;
            padding-right: 36px;
            &:before {
              margin-left: .5rem;
              margin-right: 0;
              position: absolute;
              right: 0;
              left:auto;
            }
          }

        }
        .category-head-collapisble {
          margin-bottom: .7rem;
          &.expandable {
            &:after {
              left: 3px;
              right: auto;
            }
          }
        }

      }
    }

  }
}
