.tab-box-wrap {
  background-color: @light-gray;
  padding-bottom: 2.8125rem;
  overflow: hidden;
  .swiper-wrapper,
  .tab-box-label,
  .tab-box-label-wrap {
    -webkit-filter: blur(0);
    .transition(all .2s ease-in-out);

  }
  .tab-box-slide {
    .rect-responsive {
      padding-bottom: 2rem;
      padding-top: 1rem;
    }
    .content {
      position: static;
    }
  }
  .swiper-slide {
    width: 74.333333%;
    background-color: @white;
    .transition(all .2s ease-in-out);
  }
  .tab-box-text {
    .content {
      padding-left: 8.33333333%;
      padding-right: 8.33333333%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h3 {
        text-transform: uppercase;
      }
      p {
        margin: 1.125rem 0;
        color: @steel-grey;
      }
      .btn {
        margin-top: 1rem;
      }
    }
  }
  .tab-box-photo {
    overflow: hidden;
    position: relative;
    div.cover {
      top: 0;
    }
  }
  label {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0;
    padding: 0;
    border: none;
    color: @almost-black;
    font-weight: inherit;
    background-color: @white;
    h4 {
      margin-bottom: 0.25rem;
    }
    p {
      color: @steel-grey;
    }
    & > .row {
      flex-grow: 1;
      display: flex;
      justify-content: flex-start;
      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      & > div:last-child {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .main-stroke {
      stroke: @almost-black;
    }
  }
  .tab-box-photo {
    height: 67vw;
  }
  .smartphone-label {
    background: @main-green;
    text-align: center;
    color: @white;
    font-size: 1.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.125rem;
    svg {
      max-height: 1.375rem;
      max-width: 1.375rem;
      margin-right: 1rem;
    }
    .main-stroke {
      stroke: @white;
    }
  }
  input[type=radio] {
    display: none;
  }
  input[type=radio] + div {
    display: block;
  }
  @media (max-width: 767px) {
    .swiper-wrapper{
      .swiper-slide{
        display: block;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
        position: relative;
        .smartphone-label{
          /*display: none;*/
        }
        .tab-box-text{
          position: static;
          .rect-responsive{
            position: static;
          }
          .content{
            min-height:15rem;
            align-items: center;
            justify-content: start;
            h3{
              margin-top: 0px;
            }
            p{
              margin-bottom: 4rem;
            }
            .btn{
              &.btn-default{
                position: absolute;
                bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}
.action-bar-container-active {
  width: 100%;
}
@media (min-width: @screen-sm-min) {
  .tab-box-wrap {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-bottom: 0;
    background-color: transparent;
    overflow: hidden;
    .swiper-slide {
      width: 100%;
    }
    .tab-box-swiper-button-next-wrap,
    .tab-box-swiper-button-prev-wrap {
      position: relative;
      z-index: 4;
      background-color: @white;
      &.swiper-button-disabled {
        display: none;
      }
    }
    .tab-box-swiper-button-next,
    .tab-box-swiper-button-prev {
      width: 100%;
      height: 7.3125rem;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .transition(all .2s ease-in-out);
      svg {
        width: 15px;
        height: auto;
        stroke: @black;
      }
    }
    /*
    .tab-box-swiper-button-prev {
      svg {
        transform: rotate(180deg);
      }
    }
    */
    label {
      height: 7.3125rem;
      position: relative;
      &.swiper-slide {
        width: 33.3333%;
      }
      &.checkedClass,
      &.checkedClass:hover,
      &:hover {
        background-color: @main-green;
        color: @white;
        .transition(all .2s ease-in-out);
        &:before {
          display: none;
        }
        p {
          color: @white;
        }
        .main-stroke {
          stroke: @white;
        }
      }
      &:before {
        content: " ";
        width: 1px;
        background-color: @light-gray;
        //height: 4.9375rem;
        position: absolute;
        right: 0;
        bottom: 1rem;
        top: 1rem;
      }
      svg {
        max-height: 2.1rem;
        max-width: 1.8rem;
      }
      p {
        font-size: 0.75rem;
        width: 94%;
      }
    }
    label:last-child {
      &:before {
        display: none;
      }
    }
    .tab-box-slide {
      .rect-responsive {
        padding-bottom: 90%;
        padding-top: 0;
      }
      .content {
        position: absolute;
      }
    }
    .tab-box-label {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
      border-bottom: 2px solid @main-green;
      z-index: 2;
    }
    .tab-box-label-wrap {
      overflow: hidden;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
      border-bottom: 2px solid @main-green;
      z-index: 2;
      position: relative;
      > .row {
        display: flex;
        justify-content: space-between;
      }
      .tab-box-label {
        //overflow: hidden;
        border: none;
        box-shadow: none;
      }
    }
    .tab-box-text {
      p {
        font-size: 0.875rem;
      }
    }
    .tab-box-photo {
      height: auto;
    }
    .smartphone-label {
      display: none;
    }
    input[type=radio],
    input[type=radio] + div {
      display: none;
    }
    input[type=radio]:checked + div {
      display: flex;
    }
     input[type=radio]:checked  + .display-block {
      display: block !important;
    }
  }
}
@media (min-width: @screen-md-min) {
  .tab-box-wrap {
    .tab-box-swiper-button-next,
    .tab-box-swiper-button-prev {
      height: 8.625rem;
    }
    label {
      height: 8.625rem;
      &.checkedClass,
      &.checkedClass:hover,
      &:hover {
        &:after {
          height: 1.25rem;
        }
      }
      &:before {
        bottom: 1.25rem;
        top: 1.25rem;
      }
      svg {
        max-height: 2.5rem;
        max-width: 2.5rem;
      }
      p {
        font-size: 0.875rem;
      }
    }
    .tab-box-slide {
      .rect-responsive {
        padding-bottom: 59.5%;
      }
    }
  }
}
@media (min-width: @screen-lg-min) {
  .tab-box-wrap {
    .tab-box-swiper-button-next,
    .tab-box-swiper-button-prev {
      height: 7.4rem;
    }
    label {
      height: 7.4rem;
      p {
        font-size: 1rem;
      }
      svg {
        max-height: 3.125rem;
        max-width: 3.125rem;
      }
    }
    .tab-box-text {
      .content {
        p {
          font-size: 1rem;
        }
      }
    }
  }
}
/*
 * Business Variant
 */
.business {
  .tab-box-wrap {
    label {
      background-color: @light-sand-brown;
      &.checkedClass,
      &.checkedClass:hover,
      &:hover {
        background-color: @dark-green;
      }
    }
    .smartphone-label {
      background: @dark-green;
    }
    .tab-box-wrap {
      .tab-box-label,
      .tab-box-label-wrap {
        border-bottom-color: @dark-green;
      }
    }
  }
  @media (min-width: @screen-sm-min) {
    .tab-box-wrap .tab-box-label,
    .tab-box-wrap .tab-box-label-wrap {
      border-bottom: 2px solid @dark-green;
    }
    .tab-box-wrap .tab-box-text .content {
      background-color: @light-sand-brown;
    }
    .tab-box-wrap label:before {
      background-color: @cold-gray;
    }
    .tab-box-wrap .tab-box-swiper-button-next-wrap,
    .tab-box-wrap .tab-box-swiper-button-prev-wrap {
      background-color: @light-sand-brown;
    }
  }
}
// account management-b2b old
.autoHeight.swiper-wrapper, .autoHeight.swiper-slide {
  height: auto !important;
}
.statusRed {
        background: rgb(255, 0, 0);
        width: 10px;
        height: 10px;
        border-radius: 50%;
       display: inline-block;
}

.statusYellow {
        background: rgb(255, 191, 0);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
}

.statusGreen {
        background: linear-gradient(to bottom, #C0E47C, #8ACD00 40%) repeat scroll 0 0 rgba(0, 0, 0, 0);
        width: 10px;
        height: 10px;
        border-radius: 50%;
       display: inline-block;
}
/*
 * Arabic
 */
*[lang="ar"] {
  *[dir="rtl"] {
    .tab-box-wrap label:before {
      left: 0;
      right: auto;
    }
    @media (min-width: @screen-sm-min) {
      .tab-box-wrap {
        .tab-box-swiper-button-next {
          svg {
            transform: rotate(180deg);
            use {
              -moz-transform: scale(-1);
            }
          }
        }

        .tab-box-swiper-button-prev {
          svg {
            transform: rotate(180deg);
            use {
              -moz-transform: scale(-1);
            }
          }
        }

      }
    }
  }
}
