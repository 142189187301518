.action-buttons-wrapper{
  padding-bottom: 2.4rem;
  .action-buttons-container{
    display: flex;
    justify-content: flex-start;
    &.action-buttons-container-right{
      justify-content: flex-end;
    }
  }
  a{
    color: @main-green;
    display: flex;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;
    justify-content: flex-start;
    font-size: 0.8125rem;
    &.dark-button{
      color: @almost-black;
    }
    span{
      &.icon-wrap{
        height: 1.4rem;
        width: 1.4rem;
      }
      &.text-wrap{
        padding: 0 1rem;
      }
      svg{
        height: 100%;
        width: 100%;
        max-width: 24px;
        max-height: 24px;
        fill: @main-green;
      }
    }

    &:hover{
      .text-wrap {
        text-decoration: underline;
      }
    }

    & + a{
      margin-left: 1rem;
    }
  }

  .action-buttons-container-right{
    a{
      span{
        &.icon-wrap{
          margin: 0 1rem;
        }
        &.text-wrap{
          padding: 0;
        }
      }
    }
  }

}


.business{
  .action-buttons-wrapper{
    a {
      color: @dark-green;
      svg{
        fill: @dark-green;

      }
    }

  }
}
