.config-tile-wrapper {
  //overflow: hidden;
  &.showOverflow {
    overflow: visible;
    position: relative;
  }
  & > .row-relative {
    position: relative;
    overflow-x: hidden;
  }
  .tilesCarouselRows {
    .confirmNumberButton {
      margin-left: 1.125rem;
    }
  }
  .clear-md {
    clear: both;
  }
  .clear-md-in {
    display: block;
  }
  .swiper-wrapper {
    -webkit-filter: none;
    filter: none;
    transform: none;
    position: relative;
  }
  .tiles {
    display: flex;
    flex-wrap: inherit;
    justify-content: flex-start;
    align-content: flex-start;
    height: auto;
  }
  .destroyed > .tiles {
    flex-wrap: wrap;
    height: auto;
  }
  .destroyed > .swiper-wrapper > .swiper-slide {
    position: static;
  }
  .tile-wrap {
    flex-grow: 0;
    height: 6rem;
    padding: 0 1rem;
    margin-bottom: 1.5rem;
    width: 85%;
    display: flex;
    align-items: stretch;
    cursor: pointer;
    transition: margin-bottom 0.3s ease-in-out;
    position: static;
    &.tile-credit-card {
      height: 10rem;
      width: 90%;
      .tile {
        background: @white;
      }
      .tile-card-number {
        margin-top: 1.75rem;
      }
      .tile-specs {
        font-size: 1rem;
        color: @almost-black;
        text-decoration: underline;
      }
      .tile-tick {
        right: 0.8125rem;
        bottom: 0.625rem;
      }
    }
    &.disableSelection {
      pointer-events: none;
    }
    &.tile-button-center {
      height: 10rem;
      width: 90%;
      .tile {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        align-content: center;
        .tile-label {
          font-size: 0.875rem;
          color: @main-green;
          letter-spacing: 0.145625rem;
          position: static;

          svg {
            width: 0.8125rem;
            height: 0.8125rem;
            margin-right: 0.3125rem;
          }
        }
      }
    }
    & > .tile {
      border: 1px solid @cold-gray;
      position: relative;
      width: 100%;
    }
    &.tile-no-color .tile,
    .tile-gray {
      border: 1px solid @cold-gray;
    }
    &.tile-with-expantion-open > .tile:after {
      content: " ";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.5625rem 0.78125rem 0 0.78125rem;
      border-color: @main-green transparent transparent transparent;
      position: absolute;
      top: 100%;
      left: 0.75rem;
    }
    .tile-color,
    .tile-option {
      position: absolute;
      bottom: -1px;
      left: -1px;
      right: -1px;
      height: 0;
      transition: height 0.3s ease-in-out;
      background-color: @main-green;
    //  border-bottom: 1px solid @main-green;
  //    border-right: 1px solid @main-green;
    //  border-left: 1px solid @main-green;
    }
    &.tile-with-expantion-open {
      //> .tile:after {
      //  border-color: @almost-black transparent transparent transparent;
      //}
      &.tile-with-expantion-open--noarrow > .tile:after {
        border-color: transparent transparent transparent transparent;

      }
      > .tile:after{
        border-color: @almost-black transparent transparent;
      }
      .tile-color,
      .tile-option {
        background-color: @almost-black;
        border-bottom-color: @almost-black;
        border-right-color: @almost-black;
        border-left-color: @almost-black;
      }
      &.tile-wrap .tile-option+.tile-label+.tile-specs+.tile-tick,
      .tile-option+.tile-label+.tile-specs+.tile-tick {
        border-color: transparent;
      }
    }
    .tile-color {
      border-color: @cold-gray;
      height: 0.5rem;
    }
    .tile-label {
      position: absolute;
      top: 0.6rem;
      left: 0.75rem;
      z-index: 2;
      font-size: 1rem;
      padding-right: 16%;
       word-wrap: normal;
       overflow: hidden;
      .transition();
      height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
      height: 3em;
    //  white-space: pre-wrap;
      text-overflow: ellipsis;
    }
    // Defect 313304    bottom: 0.3rem;; to   bottom: 0.6rem; ;umair
    .tile-specs {
      position: absolute;
      bottom: 0.6rem;
      left: 0.75rem;
      z-index: 2;
      font-size: 0.75rem;
      color: @steel-grey;
      .transition();
    }
    .tile-tick {
      position: absolute;
      right: 0.75rem;
      bottom: 0.75rem;
      z-index: 2;
      width: 2.8rem;
      height: 2.8rem;
      border-radius: 1.5rem;
      overflow: hidden;
      border: 1px solid @cold-gray;
      background-color: @cold-gray;
      transition: background-color 0.3s ease-in-out;
      svg {
        width: 100%;
        height: 100%;
        fill: @white;
      }
    }
    .tile-option + .tile-label + .tile-specs + .tile-tick,
    .tile-option + .tile-label + .tile-tick,
    .tile-option + .tile-tick {
      border-color: @main-green;
    }
    > input {
      display: none;
    }
    input[type="radio"] + .tile .tile-tick {
      // opacity: 0;
    }
    &.tile-with-expantion-open > .tile,
    &:hover > .tile,
    input[type="checkbox"]:checked + .tile,
    input[type="radio"]:checked + .tile {
       > .tile-option,
      > .tile-color {
        border: none;
        height: calc(100% + 2px);
      }
      &.bg-dark {
        > .tile-label {
          color: @white;
        }
      }
      &.bg-dark-Black {
        > .tile-label {
          color: @white;
        }
      }
       > .tile-option + .tile-label + .tile-specs,
      > .tile-option + .tile-label {
        color: @white;
        //white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.long-tile-specs {
          max-width: 70%;
        }
      }
    }
    input[type="checkbox"]:checked + .tile,
    input[type="radio"]:checked + .tile {
      > .tile-tick {
        border: none;
        background-color: transparent;
        opacity: 1;
      }
    }
    &.tile-no-color {
      input[type="checkbox"]:checked + .tile,
      input[type="radio"]:checked + .tile {
        > .tile-tick {
          svg {
            fill: @main-green;
          }
        }
      }
    }
    &.smalltile, &.smalltile-favorite  {
      height: 4rem;
      .tile-option{
        background-color: @main-green;
        border-bottom: 1px solid @main-green;
        border-right: 1px solid @main-green;
        border-left: 1px solid @main-green;
      }
    }

  }
  .expantion-form-space,
  .expantion-form-submit {
    margin-left: 0;
    .btn {
      width: 100%;
    }
  }
  .tilesCarouselRows {
    flex-wrap: wrap;
    margin-bottom: 3rem;
    .smalltile, .smalltile-favorite {
      .tile {
        .tile-label {
          padding: 0;
        }
      }
      height: 3rem;
      width: auto;
    }
    .tile {
      border: 0 none;
      width: 15.625rem;
      background: @white;
      text-align: center;
      .tile-option {
        border: 0;
      }
      .tile-label {
        font-size: 1.125rem;
        color: @almost-black;
        font-family: @font-family-menu-sans-serif;
        left: 50%;
        width: 100%;
        margin-left: -50%;
      }
    }
    .tiles-swiper-button-next {
      display: none;
    }
    .tiles-swiper-button-prev {
      display: none;
    }
    .tiles-swiper-scrollbar {
      display: none;
    }
  }
  .tilesCarouselRows-no-wrap-mobile {
    flex-wrap: inherit;
    .tile-wrap {
      width: 15.625rem;
      height: 9.0625rem;
      border: 0;
      .tile {
        border: 0;
      }
      .tile-label {
        text-align: left;
        padding-left: 1rem;
        font-size: .8rem;
        color: #96999B;
        width: 75%;
        font-family: @font-family-sans-serif;
        strong {
          color: @almost-black;
          display: inline-block;
          margin-top: 0.4375rem;
          font-weight: normal;

          &:first-child{
            margin-top: 0;
          }
        }

        span {
          color: @main-green;
        }
      }
    }
  }
}
.config-tile-title-wrapper {
  position: fixed;
  background-color: @almost-black;
  color: @white;
  font-family: @headings-font-family;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1002;
  height: 4rem;
  display: none;
  > .row {
    display: flex;
    align-items: stretch;
    height: 100%;
    > div {
      display: flex;
      align-items: center;
      &.close-section {
        justify-content: center;
      }
    }
  }
  svg {
    width: 0.75rem;
    height: 0.75rem;
    stroke: @white;
    stroke-width: 2px;
  }
}
.tiles-with-expantion-open .config-tile-title-wrapper {
  display: block;
}
.config-tile-wrapper input:checked + .tile.reverse .tile-label,
.config-tile-wrapper input:checked + .tile.reverse .tile-specs {
    color: #fff;
}

// Defect 313304 umair
.tile-gray.reverse:hover .tile-label {
    color: @white;
}
@media (min-width: @screen-ms-min) {
  .config-tile-wrapper {
    .tilesCarousel {
      .confirmNumberButton {
        margin-left: .625rem;
      }
    }
    .tile-wrap {
      width: 50%;
    }
  }
}
@media (max-width: @screen-sm-max) {


  .sub_menu_mobile,
  .main-menu.main-menu__fixed {
    width: 100vw;
    .navbar {
      width: 100vw;
    }
  }
  .business .page-nav-wrap.affix{
    width:100vw;
  }
  .sticky-config-footer{
    width:100vw;
  }

  .config-expantion-wrapper.config-expantion-text-and-form>.container-fluid>.row{
    flex-direction: row !important;
  }
  .tile-wrap .config-expantion-wrapper>.container-fluid{
    min-height: 100%;
    margin-bottom: 0;
  }
  .config-tile-wrapper {
    width: 100vw;
    overflow-x: hidden;
    &.swiper-with-0-slides {
      overflow: visible;
      padding-top: 2rem;
    }
    .tilesCarousel {
      .confirmNumberButton {
        margin-left: 1rem;
      }
    }
    .tile-wrap {
      transform: none !important;
    }
    .tiles {
      &.tiles-with-expantion-open {
        transform: none !important;
        margin-bottom: 0 !important;
        z-index: 1001;
        &.swiper-wrapper {
          position: static;
        }
      }
    }
    .tile-with-expantion-open {
      .config-expantion-wrapper {
        position: fixed;
        top: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        right: 0 !important;
        height: auto !important;
        margin: 0;
        padding-top: 4rem;
        padding-bottom: 0;
        overflow-y: auto;
        z-index: 1001;
        background-color: @white;
      }
    }
  }
  .config-tile-wrapper .tile-wrap .tile-label.link-tile {
    top: 2.3rem;
    left: 2.5rem;
    padding-right:5%;
  }
}
@media (min-width: @screen-sm-min) {
  .config-tile-wrapper {
    .tile-wrap {
      width: 36%;
      height: 6.25rem;
      &.tile-credit-card {
        width: 34%;
        height: 7.843125rem;
        padding: 0 0.625rem;
      }
      &.tile-button-center {
        height: 7.843125rem;
        padding: 0 0.625rem;
        width: 34%;
        .tile {
          .tile-label {
            font-size: 0.75rem;
            svg {
              width: 0.5625rem;
              height: 0.5625rem;
            }
          }
        }
      }
      .tile-label {
        font-size: 1rem;
        top: 0.4rem;
        left: 0.71875rem;
        &.link-tile {
          top: 2.1rem;
          left: 1.2rem;
        }
      }
      // Defect 313304  bottom: 0.4rem; to bottom: 0.6rem;umair
      .tile-specs {
        bottom: 0.6rem;
        left: 0.71875rem;
      }
      .tile-tick {
        right: 0.71875rem;
        bottom: 0.71875rem;
        width: 2.125rem;
        height: 2.125rem;
      }
    }
    .tilesCarouselRows {
      overflow: hidden;
      .tile {
        width: 12rem;
        .tile-label {
          top: 0.625rem;
        }
      }
      .tiles-swiper-button-next,
      .tiles-swiper-button-prev {
        display: block;
        position: absolute;
        top: 50%;
        text-align: center;
        margin-top: -1.125rem;
        transform: translateY(-50%);
        z-index: 1;
        width: 4rem;
        &:hover {
          cursor: pointer;
        }
        svg {
          stroke: #626262;
          width: 1rem;
          height: 1.875rem;
        }
        &.swiper-button-disabled{
          display: none !important;
        }
      }
      &.tilesCarouselRowsByTwo {
        .tiles-swiper-button-next,
        .tiles-swiper-button-prev {
          top: 38.7%;
        }
      }

      .tiles-swiper-button-next {
        right: 0;
      }
      .tiles-swiper-button-prev {
        left:0;
      }
      .tiles-swiper-scrollbar {
        display: block;
        width: 100%;
        height: 0.25rem;
        margin-top: 0.5rem;
        position: static;
        .opacity(1) !important;
        border-radius: 0;
        .swiper-scrollbar-drag {
          border-radius: 0;
        }
      }
    }
    .tilesCarouselRows-no-wrap-mobile {
      flex-wrap: wrap;
      .tile-wrap {
        height: 7.25rem;
        width: 14rem;
        padding: 0 0.5625rem;
        .tile {
          width: 100%;
          border: 0;
        }
        .tile-label {
          font-size: 1rem;
          top: 0;
          strong {
            font-size: 1rem;
            margin-top: 0;
          }
        }
      }
    }
    form {
      display: flex;
      justify-content: flex-start;
      &.static-form {
        display: block;
      }
    }
  }
}
@media (min-width: @screen-md-min) {
  .config-accordion-wrapper {
    .expantion-form-submit {
      .btn {
        width: 100%;
        margin-bottom: 2.5rem;
        height: 4.25rem;
      }
    }
  }
  .config-tile-wrapper {
    .tilesCarousel {
      .confirmNumberButton {
        margin-left: .625rem;
      }
    }
    overflow: hidden;
    &.swiper-with-0-slides {
      overflow: visible;
      padding-top: 1rem;
    }
    .tiles {
      flex-wrap: wrap;
    }
    .tile-wrap {
      width: 25%;
      height: 6.25rem;
      &.tile-credit-card {
        width: 25%;
        height: 8.0625rem;
      }
      &.tile-button-center {
        width: 25%;
        height: 8.0625rem;
      }
    }
    .swiper-wrapper {
      -webkit-filter: none;
      filter: none;
      transform: none;
      position: inherit;
    }
    .tilesCarouselRows {
      overflow: hidden;
      .smalltile, .smalltile-favorite {
        padding: 0 0.625rem;
      }
      .tile {
        width: 12.3125rem;
        .tile-label {
          font-size: 1.25rem;
        }
      }
      .tiles-swiper-button-next,
      .tiles-swiper-button-prev {
        width: 5.3125rem;
        svg {
          width: 1.25rem;
          height: 2.375rem;
        }
      }
    }
    .tilesCarouselRows-no-wrap-mobile {
      flex-wrap: wrap;
      .tile-wrap {
        height: 7.25rem;
        width: 18.5625rem !important;
        padding: 0 1.28125rem;
        .tile {
          width: 100%;
          border: 0;
        }
        .tile-label {
          font-size: 1.1rem;
          top: 0;
          line-height: 1.5625rem;
          strong {
            font-size: 1rem;
            margin-top: 0;
          }
        }
      }
    }
    .expantion-form-space,
    .expantion-form-submit {
      margin-left: 2rem;
      width: auto;
    }
  }
  .config-tile-title-wrapper,
  .tiles-with-expantion-open .config-tile-title-wrapper {
    display: none;
  }
}
@media (max-width: @screen-lg-min) {
  .config-tile-wrapper {
    .clear-md {
      .expantion-form-space,
      .expantion-form-submit {
        margin-left: 0;
      }
    }
  }
}
@media (min-width: @screen-lg-min) {
  .config-tile-wrapper {
    .tilesCarousel {
      .confirmNumberButton {
        margin-left: 1.125rem;
      }
    }
    .clear-md-in {
      display: flex;
    }
    .clear-md {
      clear: none;
    }
    .tile-wrap {
      width: 25%;
      height: 7rem;
      &.tile-credit-card {
        height: 11rem;
      }
      &.tile-button-center {
        height: 11rem;
      }
      &.tile-with-expantion-open > .tile:after {
        border-width: 0.75rem 1rem 0 1rem;
      }
      .tile-label {
        font-size: 1.25rem;
        top: 0.6rem;
        left: 1rem;
        &.link-tile {
          top: 2.3rem;
          left: 2rem;
        }
        img{
          height: 65px;
          max-width: 100px;
          overflow: hidden;
        }
      }
      .tile-specs {
        font-size: 1rem;
        bottom: 0.65rem;
        left: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 89%;
      }
      .tile-tick {
        right: 1rem;
        bottom: 1rem;
        width: 3rem;
        height: 3rem;
      }
    }
    .tilesCarouselRows {
      overflow: hidden;
      .smalltile, .smalltile-favorite {
        padding: 0 1.125rem;
        height: 4rem;
      }
      .tile {
        width: 19.0625rem !important;
        .tile-label {
          font-size: 1.5rem;
        }
      }
      .tiles-swiper-button-next,
      .tiles-swiper-button-prev {
        width: 7.5rem;
        svg {
          width: 1.73875rem;
          height: 3.353125rem;
        }
      }
    }
    .tilesCarouselRows-no-wrap-mobile {
      flex-wrap: wrap;
      .tile-wrap {
        height: 9.875rem;
        width: 19.2625rem !important;
        padding: 0 1.125rem;
        .tile {
          width: 100%;
          border: 0;
        }
        .tile-label {
          font-size: 1.18rem;
          top: 1rem;
          strong {
            font-size: 1.25rem;
            margin-top: 0.9375rem;
          }
        }
      }
    }
  }
  .config-accordion-wrapper .expantion-form-submit .btn {
      height: 5.25rem;
  }
}
.business {
  .config-tile-wrapper {

    background: @sand-white;

    .tile-wrap {
      & > .tile {
        border: 1px solid @dark-green;
      }
      &.smalltile > .tile {
        background-color: @white;
      }
      .tile-gray {
        border: 1px solid @cold-gray;
      }
      &.tile-with-expantion-open {
        > .tile:after {
          border-color: @almost-black transparent transparent transparent;
        }
        &.tile-with-expantion-open--noarrow > .tile:after {
          border-color: transparent transparent transparent transparent;
        }
        .tile-color,
        .tile-option {
          background-color: @almost-black;
          border-bottom-color: @almost-black;
          border-right-color: @almost-black;
          border-left-color: @almost-black;
        }
      }
      .tile-color,
      .tile-option {
        background-color: @dark-green;
        border-bottom: 1px solid @dark-green;
        border-right: 1px solid @dark-green;
        border-left: 1px solid @dark-green;
      }
      .tile-color {
        border-color: @cold-gray;
      }
      .tile-option + .tile-label + .tile-specs + .tile-tick,
      .tile-option + .tile-label + .tile-tick,
      .tile-option + .tile-tick {
        border-color: @dark-green;
      }

      .tile-tick svg{
        fill: @sand-white;
      }

      &.smalltile .tile-option, &.smalltile-favorite .tile-option {
        background-color: @dark-green;
        border-bottom: 1px solid @dark-green;
        border-right: 1px solid @dark-green;
        border-left: 1px solid @dark-green;
      }
    }
  }
  .config-accordion-wrapper {
    .expantion-form-submit {
      .btn {
        width: 100%;
        margin-bottom: 2.5rem;
        height: 5.125rem;
      }
    }
    .bg-business {
      background: @sand-white;
      .product-grid-single .card__back,
      .product-grid-single .card__front {
        background: transparent;
      }
    }
    .bg-business-dark{
      background-color: @light-sand-brown;
    }
    .form.static-form .col-static{
      .form-section{
        margin-bottom: 0;
      }
      .form .floating-label-input, .form .floating-label-select{
        margin: 0 0 2rem;
      }
    }
  }
}
@media (min-width: @screen-xs-min) and (max-width: @screen-md-max) {
  .business {
    .config-accordion-wrapper {
      .expantion-form-submit {
        .btn {
          width: 100%;
          margin-bottom: 2.5rem;
          height: 4.3rem;
        }
      }
    }
  }
}
@media (min-width: @screen-lg-min) {
  .business {
    .config-accordion-wrapper {
      .expantion-form-submit {
        .btn {
          width: 100%;
          margin-bottom: 2.5rem;
          height: 5.125rem;
        }
      }
    }
  }
}
/*
 * Arabic
 */
*[lang="ar"] {
  *[dir="rtl"] {
    .config-tile-wrapper {
      .tile-wrap {
        .tile-tick {
          left: 0.8125rem;
          right: auto;
        }
      }
      &.tile-button-center {
        .tile {
          .tile-label {
            svg {
              margin-left: 0.3125rem;
              margin-right: auto;
            }
          }
        }
      }
      &.tile-with-expantion-open > .tile:after {
        right: 0.75rem;
        left: auto;
      }
      .tile-label {
        position: absolute;
        top: 0.6rem;
        right: 0.75rem;
        left: auto;
        .transition();
      }
      .tile-specs {
        right: 0.75rem;
        left: auto;
        .transition();
      }
      .tile-tick {
        left: 0.75rem;
        right: auto;
      }
    }
    .expantion-form-space,
    .expantion-form-submit {
      margin-left: auto;
      margin-right: 0;
    }
    .tilesCarouselRows {
      .tile {
        .tile-label {
          right: 50%;
          left: auto;
          margin-right: -50%;
          margin-left: auto;
        }
      }
    }
    .tilesCarouselRows-no-wrap-mobile {
      .tile-wrap {
        .tile-label {
          text-align: right;
          padding-right: 1.4375rem;
          padding-left: auto;
          font-family: @font-arabic;
        }
      }
    }
    .ar-flex{
      display: flex;
    }
  }
  .config-tile-title-wrapper {
    font-family: @font-arabic;
    right: 0;
    left: auto;
  }
  @media (min-width: @screen-sm-min) {
    .config-tile-wrapper {
      .tile-wrap {
        .tile-label {
          top: 0.4rem;
          right: 0.71875rem;
          left: auto;
          padding-right:0rem;
        }
        .tile-specs {
          right: 0.71875rem;
          left: auto;
        }
        .tile-tick {
          left: 0.71875rem;
          right: auto;
        }
      }
      .tilesCarouselRows {
        .tiles-swiper-button-prev {
          left:0;
        }
        .tiles-swiper-button-next {

        }
      }
    }
  }
  @media (min-width: @screen-md-min) {
    .config-tile-wrapper {
      .expantion-form-space,
      .expantion-form-submit {
        margin-left: auto;
        margin-right: 1rem;
      }
    }
  }
  @media (max-width: 767px) {
    .config-tile-wrapper {
      .tile-wrap {
        .tile-label {
          padding-right: 0;
          padding-left: 16%;
          font-size: 1rem;
        }
      }
    }
   }
  @media (min-width: @screen-lg-min) {
    .config-tile-wrapper {
      .tile-wrap {
        .tile-label {
          right: 1rem;
          left: auto;
        }
        .tile-specs {
          left: auto;
          right: 1rem;
        }
        .tile-tick {
          left: 1rem;
          right: auto;
          svg {}
        }
      }
    }
    /////////////////////////////////////////
    .config-accordion-wrapper {
      .form-section {
        .floating-label-prefix {
          margin-right: 0;
          margin-left: 1.25rem;
        }
      }
    }
    @media (min-width: @screen-sm-min) {
      .config-accordion-wrapper {
        .align_flex-ar {
          display: flex;
          justify-content: flex-start;
          .form-section {
            .floating-label-prefix {
              margin-right: 0;
              margin-left: 1.25rem;
            }
          }
        }
        .expantion-form-submit {
          .btn {
            margin-bottom: 0;
          }
        }
      }
    }
    @media (min-width: @screen-lg-min) {}
  }
  .ltr {
    direction: ltr;
    font-family: @font-family-base;
    text-align: left;
  }
}
.extend_width{
  min-width: 100% !important;
}
.config-tile-wrapper.configure-head-office .tile-wrap:hover > .tile .tile-label.tile-place,
.config-tile-wrapper.configure-head-office .tile-wrap:hover > .tile .tile-label.tile-business,
.config-tile-wrapper.configure-head-office .tile-wrap:hover > .tile .tile-label.tile-bandwidth,
.config-tile-wrapper.configure-head-office .tile-wrap input[type="checkbox"]:checked + .tile .tile-label.tile-place,
.config-tile-wrapper.configure-head-office .tile-wrap input[type="checkbox"]:checked + .tile .tile-label.tile-business,
.config-tile-wrapper.configure-head-office .tile-wrap input[type="checkbox"]:checked + .tile .tile-label.tile-bandwidth  {
  color: @white;
}
/*
.config-tile-wrapper.configure-head-office .tilesCarouselRows .smalltile,
.config-tile-wrapper.configure-head-office .tilesCarouselRows .smalltile-favorite {
  width: 281px;
}*/
.config-tile-wrapper.configure-head-office .tile-wrap.smalltile,
.config-tile-wrapper.configure-head-office .tile-wrap.smalltile-favorite {
  height: 9.5rem;
}
.config-tile-wrapper.configure-head-office .tilesCarouselRows .tile {
  text-align: left;
  border: 0px;
  width: 14rem;
  .tile-label {
    left: 0%;
    margin-left: 0%;
    padding: 0px 15px;
  }
  .tile-place {
    top: 2.05rem;
    font-size: 1.4rem;
    color: @gray-base;
  }
  .tile-business {
    top: 5rem;
  }
  .tile-bandwidth {
    top: 6.5rem;
    font-size: 1.4rem;
    color: @gray-base;
  }
}
/*.tilesCarouselRowsSvpn + .config-tile-title-wrapper + .config-expantion-wrapper {*/
.config-expantion-wrapper {
    display: block;
    position: relative;
    width: 100%;
}
.chosse-tunnel-bandwith-plan {
  display: none;
  .config-expantion-wrapper {
    width: auto;;
    position: relative;
    height: 100%;
  }
}

.chosse-tunnel-bandwith-plan .tiles .tile {
  background: @white;
  border: 0px;
}

.config-tile-wrapper .tile-wrap:hover > .tile > .tile-option + .tile-label + .tile-specs {
  color: @gray;
}
.config-tile-wrapper .tile-wrap:hover input:disabled + .tile > .tile-option  {
    height: 0%;

  }

.business .config-tile-wrapper .tile-wrap input:disabled + .tile {
    background: @cold-gray;
    border-color: @cold-gray;
  .tile-label {
    color: @gray;
  }
}
//breackpoint iph4 and iph5
@media (max-width: 375px) {
    .config-tile-wrapper .tile-wrap {
        width: 100% !important;
    }
}
