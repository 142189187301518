.pick-freedom-overlay{

// *************************************
// checkboxes and radio buttons
// *************************************
.freedomDetailsDesktop {
  display: block;
  @media (max-width: @screen-sm) {
    display: none;
  }
}
.checkboxes-wrap,
.radio-wrap {
  position: relative;
  width: 90%;
  label {
    display: flex;
    min-height: 56px;
    padding-left: 46px;
    padding-right: 0;
    align-items: center;
    font-size: 22px;
    line-height: 19px;
    font-family: @font-family-base;
    color: @almost-black;
    font-weight: normal;
    margin-bottom: 0;
    cursor: pointer;
    .freedomDetailsMobile {
      display: none;
    }
    @media (max-width: @screen-sm) {
      font-size: 14px;
      font-family: Neotech,Roboto,Arial,sans-serif;
      font-weight: 700;
      display: block;
      span {
        display: flex;
        clear: both;
        width: 100%;
      }
      .freedomDetailsMobile {
        display: block;
        font-weight: 400;
        text-align: left;
        padding-right: 5px;
        font-size: 12px;
        color: #6f7273;
        line-height: 16px;
        margin-top: 5px;
      }
    }
    &:before {
      background-image: url(../../assets/img/wst-icons/icon-checkbox-unselected.svg);
      content: "";
      width: 32px;
      height: 32px;
      display: block;
      background-size: 32px;
      background-repeat: no-repeat;
      margin-right: .5rem;
      position: absolute;
      right: inherit;
      transition: all .25s cubic-bezier(.55,.06,.68,.19);
      border-radius: 50%;
      @media (max-width: 550px) {
        left:5px;
      }
    }
  }
  input[type=checkbox] {
    &:checked+label {
      &:before {
        background-image: url(../../assets/img/wst-icons/icon-checkbox.svg);
        background-repeat: no-repeat;
      }
    }
    &:disabled+label {
      &:before {
        background-image: url(../../assets/img/wst-icons/icon-checkbox-disabled.svg);
        background-repeat: no-repeat;
      }
    }
  }

  input[type=radio] {
    &:checked+label {
      &:before {
        background-image: url(../../assets/img/wst-icons/icon-radio-selected.svg);
        background-repeat: no-repeat;
      }
    }
    &:disabled+label {
      &:before {
        background-image: url(../../assets/img/wst-icons/icon-checkbox-disabled.svg);
        background-repeat: no-repeat;
      }
    }
  }
}

input[type=checkbox],
input[type=radio] {
  display: none;
}

// *************************************
// checkboxes and radio buttons with TILES
// *************************************
.radio-tile-wrap,
.checkbox-tile-wrap {
  margin-bottom: 2rem;
  label {
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    background-color: @white;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);
    margin: 0;
    padding: 0 .875rem;
    color: @gray-fill;
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 1rem;
    border: 1px solid @white;
    font-family: @headings-font-family;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    transition: all .2s ease;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    min-width: 130px;
  }
  input[type=checkbox]:checked,
  input[type=radio]:checked {
    & + label {
      color: @brand-primary;
      border: 1px solid @brand-primary;
      text-shadow: 0 0 0 @brand-primary;
    }
  }
}

// help me decide overlay
&.pick-freedom-overlay-section {
  display: none;
  &.active {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    z-index: 9999;
    background-color: @white;
  }
  .overlay-head {
    padding: 2.75rem 0;
    border-bottom: 2px solid #E2E1DA;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.75rem;
    padding-top:0px;
    @media (max-width: @screen-sm) {
      margin-bottom: 0.75rem;
      padding: .75rem 0;
      display: block;
      padding-top:0px;
    }
    h3 {
      margin: 0;
      font-size: 24px;
      letter-spacing: 0px;
      line-height: 2rem;
      color: @almost-black;
      @media (max-width: @screen-sm) {
        font-size: 20px;
      }
    }
    .close {
      background-image: url(../img/wst-icons/icon-close-grey.svg);
      content: "";
      width: 1.5rem;
      height: 1.5rem;
      display: block;
      background-size: 1.5rem;
      right: 0;
      position: absolute;
      margin-right: .75rem;
      @media (max-width: @screen-sm) {
        margin-right: 0;
        position: absolute;
        top: 5px;
      }
    }
  }
  .overlay-footer {
    padding: 3.5rem 0px 0rem 0px;
    .next-button {
      text-align: center;
      .btn {
        min-width: 288px;
      }
    }
    @media (max-width: @screen-sm) {
      position: fixed;
      left: 0px;
      bottom: 0rem;
      padding: 0px 2rem;
      width: 100%;
      z-index: 1;
      height: 75px;
      background: #fff;
      .next-button {
        .btn {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
  .freedom-overlay{
    background-color: @white;
    padding: 3rem;
    max-width: 1440px;
    margin: auto;
    @media (max-width: @screen-md) {
      padding: 1rem;
    }
    @media (max-width: @screen-sm) {
      padding: 2rem;
      padding-top: 1rem;
      padding-bottom: 5rem;
    }
  }
  .pick-freedom-overlay-container{
    max-width: 1440px;
    margin: auto;

  }
  .body-content{
    .pick-freedom-contract-wrapper{
      padding: 0px 12px;
      @media (max-width: @screen-sm) {
        padding: 0;
      }
    }
    .pick-freedom-contract{
      background: @white;
      border-radius: 4px;
      box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
      padding: 1rem;
      padding-bottom: 0px;
      @media (max-width: @screen-md) {
        margin-bottom: 1rem;
      }
      .pick-freedom-contract-head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3{
          margin-top: 0px;
          font-size: 22px;
          color: @almost-black;
          letter-spacing: 0px;
          @media (max-width: @screen-md) {
            min-width: 11rem;
          }

        }
        .detail-info-wrap-pricetag{
          width: 15rem;
          justify-content: flex-end;
          .main-part {
            .price{
              padding-right: .525rem;
            }
          }
        }
      }
      .plans-table-freedom{
        padding-top: 0px;
        &.plans-table{
          &.plans-table-A {
            .tile-table {
            @media (max-width: @screen-md) {
              margin-bottom: 1rem;
            }
              .tile {
                .tiles-box{
                  border-top: none;
                  box-shadow:none;
                  &::after{
                    display: none;
                  }
                  .tiles-box-title{
                    padding: 0px;
                    margin: 0px;
                    margin-bottom: 1rem;
                    @media (max-width: @screen-md) {
                      margin-bottom: 0;
                    }
                    h5{
                      font-size: 14px;
                      color: @steel-grey;
                      font-family: lato;
                      line-height: 22px;
                      text-align: left;
                      letter-spacing: 0px;
                      @media (max-width: @screen-md) {
                        margin-bottom: 0;
                      }
                    }
                    a{
                      &.freedom-plans-toggle{
                        position: absolute;
                        right: 0px;
                        top: 21px;
                        &:before {
                          right: 8px;
                          border: solid @almost-black;
                          border-width: 0 1px 1px 0;
                          padding: 7.7px;
                          content: "";
                          position: absolute;
                          line-height: 1;
                          top: 21%;
                          transform: translateY(-20%) rotate(45deg);
                          transition: all .3s ease-in-out;
                        }
                        &.collapsed{
                          transform: translateY(-20%) rotate(-180deg);
                          display: block;
                          height: 32px;
                          width: 32px;
                        }
                      }
                    }
                  }
                  .tiles-box-list{
                    padding: 0px;
                    height: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .modal-dialog{
    margin: 0px !important;
    &.m-auto{
      margin: auto !important;
      margin-top: 5% !important;
    }
  }
}








// *************************************
// arabic overides
// *************************************
*[lang="ar"] {
  *[dir="rtl"] {
    .comapre-and-sort {
      .btn {
        float: right;
        margin-right: 0px;
        margin-left: 24px;
      }
      .floating-label-select {
        float: left;
        margin-right: auto;
        margin-left: inherit;
      }
    }
    .category-head-collapisble {
      .count {
        display: inline-block;
      }
    }
    .buynget-form .floating-label-input label, .buynget-form .floating-label-select label {
        right: 0;
        padding-left: 2.5rem;
        padding-right: 0;
    }

  }


}
}
.modal-backdrop{
  background-color: rgba(0, 0, 0, 0.5);
}
