
.config-upselling-noBg {
  background: transparent;
  margin-top: 0px;
    .swiper-wrapper {
        display: block;
        .tile-table.swiper-slide {
          margin-bottom:  4%;
        }
        .tile-table.swiper-slide:nth-child(3) {
          margin-right:  0;
        }
    }
    div.cover {
      position: static;
    }
    .row.marginBottom {
      margin-bottom: 35px;
    }
    .detail-info-wrap-pricetag {
      p {
        font-size: 1.15rem;
      }
    }
}

.config-upselling-noBg .tile-table input[type=radio]:checked + .tile .card__front {
  border-color: #719e19;
}

.config-upselling-noBg .tile-table .tile .card__back,
.config-upselling-noBg .tile-table .tile .card__front {
  padding-bottom: 6.5rem;
  }

.config-upselling-noBg .tile-table .tile .tile-tick {
  margin-top: 0.85rem;
}

.config-upselling .tile-table .tile .tiles-box .cover {
  height: 13rem;
  max-width: 100%;
}

@media (max-width: 767px) {
  .config-upselling-noBg {
      .swiper-wrapper {
        display: flex;
      }
    }
    .config-upselling .tile-table .tile .tiles-box .cover {
      height: 10rem;
    }
}
