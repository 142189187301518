@menu-height-default: 96px;
@menu-height-slim: 50px;
@menu-height-phone-default: 60px;
@menu-transition-duration: 0.2s;
@padding-x-area-in-hover: 32px;

*[dir="ltr"] .etisalat-logo-img-rtl {
  display: none;
}

*[dir="rtl"] .etisalat-logo-img-ltr {
  display: none;
}

.main-menu {
  //cutomize a breakpoint only for main-menu
  @screen-md-max: (@screen-ml-min - 1);
  @screen-ml-max: (@screen-lg-min - 1);
  /*
    End cutomize a breakpoint only for main-menu
   */
  //.transition(@menu-transition-duration, ease-in-out);
  position: relative;
  font-size: @font-size-main-menu;
  letter-spacing: @kerning-main-menu;

  .main-menu-main-row {
    display: flex;
    justify-content: flex-start;
  }

  .navbar,
  .navbar-header,
  .navbar-center,
  .navbar-nav > li > a,
  .hamburger_icon {
    .transition(.25s ease-in-out height);
  }

  .etisalat-logo-img {
    .transition(.25s ease-in-out all);
  }

  .navbar {
    z-index: @zindex-navbar;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: @white;
    text-transform: uppercase;
    border: none;
    border-bottom: 1px solid @sand-white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    margin: 0;
    //.transition(@menu-transition-duration, ease-in-out);
    .navbar-center,
    .navbar-right {
      float: none;
      position: relative;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
      width: auto;
      display: flex;
      letter-spacing: 0;
      max-height: 96px;
      padding-right: 0;
      padding-left: 0;
      list-style: none;
    }
    .navbar-center{
    //   justify-content: center;  // comment for B2C CMS IE ALIGN left and right
     &.menu-left {
       justify-content:  left;  // comment this line for b2c book your number
     }
    }
    .navbar-nav > li > .navbar-text,
    .navbar-nav > li > a,
    .navbar-right a {
      position: relative;
      width: auto;
      margin: 0;
      padding: 0;
      text-align: center;
      font-family: @font-family-menu-sans-serif;
      color: @texts-gray;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: column;
      white-space: nowrap;
      //.transition(@menu-transition-duration, ease-in-out);
    }
    .navbar-nav > li:first-child > a {
      margin-left: -@padding-x-area-in-hover;
    }
    .navbar-header {
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;

      &.text-right {
        justify-content: flex-end;
      }
    }

    .nav > li > a:focus,
    .nav > li > a:hover,
    .navbar-nav > .active > a:focus,
    .navbar-nav > .active > a:hover {
      color: @white;
      background-color: @main-green;
    }

    .account-link.active a,
    .navbar-nav > .active > a {
      color: @main-green;
    }

    #main-menu-search-img {
      width: 18px;
      height: 18px;
    }

    .account-link:after {
      content: " ";
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: @sand-white;
    }

    .search-link a {
      padding-right: 0;
    }

    .logo_center a {
      //margin: 0 auto;
    }

    .hamburger_icon {
      background: transparent;
      height: @menu-height-phone-default;
      width: 100%;
      border: 0 none;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      //.transition(@menu-transition-duration, ease-in-out);
      span {
        height: 1px;
        width: 29px;
        margin-bottom: 7px;
        margin-left: auto;
        margin-right: auto;
        background: @main-green;
        display: block;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &:focus {
        outline: none;
      }

      &.open {
        background: @main-green;

        span {
          background: #FFF;
        }
      }
    }
  }

  &,
  &.main-menu__full {
    height: @menu-height-phone-default;
    z-index: 9;
    width: 100%;

    .navbar-header {
      height: @menu-height-phone-default;
    }

    .navbar {
      height: @menu-height-phone-default;

      .etisalat-logo-img {
        // width: 95px;
        // height: 24px;
        //.transition(@menu-transition-duration, ease-in-out);
        width: 100px;
        height: 40px;
        margin-top: 6px;
      }

      @media (max-width: 736px) {
        .logo-black {
            display: inline-block;
            width: 100%;
            text-align: center;
            padding-top: 0px;
            height: 40px;
          svg {
            &.etisalat-logo-img {
              width: 115px;
              height: 40px;
              margin-bottom: 0px;
            }
          }
        }
      }

      .navbar-nav > li > .navbar-text,
      .navbar-nav > li > a {
        height: @menu-height-phone-default;
      }

      .logo_center {
        text-align: center;
         .logolink {
           @media (max-width: 1279px){
             margin-left: auto;
             margin-right: auto;
           }
         }
      }

      .logo_center_alone {
        text-align: center;
        margin: 0 auto;
      }
    }
  }

  &.main-menu__fixed {
    .navbar {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
  //dropdown
  .mega-dropdown {
    background-color: @white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25), 0 -1px 4px 0 rgba(0, 0, 0, 0.25);
    width: 100%;
    top: 100%;
    left: 0;
    z-index: 2;
    position: absolute;
    margin-top: 1px;
    overflow: hidden;
    max-height: 0;
    .transition(@menu-transition-duration, ease-in-out);

    &.open {
      max-height: 100vh;
    }

    .sub-menu {
      .row-sub-menu {
        // padding-bottom: 1.7rem;
        padding-bottom: 0;

        & > div {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
        }

        .sub-menu-section {
          /* width: 31%; */
          position: relative;
        }
      }

      h5 {
        font-size: 1.125rem;
        margin-top: 3.5875rem;
        margin-bottom: 1.75rem;
        max-width: 90%;

        a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: @grey-dark-grass;
        }

        svg {
          margin-left: 27px;
          width: 9px;
          height: 13px;
          margin-top: 1px;
          fill: @grey-dark-grass;
          -moz-transform-origin: center center;
        }

        &:hover {
          a {
            color: @main-green;
          }

          svg {
            fill: @main-green;
          }
        }

        &.active {
          a {
            color: @main-green;

            svg {
              transform-origin: center center;
              overflow: visible;

              use {
                -moz-transform: rotate(270deg);
              }
            }
          }

          svg {
            transform: rotate(90deg);
            fill: @main-green;
          }
        }
      }

      ul {
        list-style: none;
        padding-left: 0;
        padding-right: 0;
        text-transform: none;
        font-family: @font-family-sans-serif;
        letter-spacing: 0;
        display: inline-block;
        font-size: 1rem;

        li {
          margin-bottom: 1rem;
          display: block;

          a {
            color: @grey-dark-grass;
          }

          &:hover {
            a {
              color: @main-green;
            }
          }

          &.active {
            a {
              color: @main-green;
              text-decoration: underline;
            }
          }
        }
      }
    }

    .tiles-menu {
      border-bottom: 1px solid @sand-white;
      border-top: 1px solid @sand-white;

      .row {
        padding: 2rem 0 1.5rem;
        /* display: flex; */
        justify-content: flex-start;
        align-items: center;

        & > div {
          display: flex;
          justify-content: space-between;
        }

        .menu-tile {
          height: 8.125rem;
          overflow: hidden;
          /* width: 31%; */
          position: relative;

          .cover {
            width: 92%;
          }

          a {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: @white;
            text-transform: none;
            font-size: 1rem;
            letter-spacing: 0.125rem;
            font-family: @font-family-menu-sans-serif;
            font-weight: 600;
          }
        }
      }
    }
  }

  .tablet_search {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .navbar-right {
      .search-link {
        a {
          padding-left: 0;
        }
      }
    }
  }
}

.sub_menu_mobile {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 1;
  top: -100%;
  overflow-y: auto;
  height: 100%;
  z-index: 8;
  .transition(@menu-transition-duration, ease-in-out);

  &.visible_menu {
    padding-top: @menu-height-phone-default;
    top: 0;
    @media (max-width: 767px){
      overflow-y: auto;
    }

  }

  .container_swip {
    overflow: hidden;
    background: @sand-white-50;

    .swiper_menu {
      margin-top: 30px;
      margin-bottom: 30px;
      margin-left: 2rem;

      .swiper-slide {
        background-color: #000;
        width: 200px;
        height: 123px;
        margin-right: 28px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .cover {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          text-align: center;

          a {
            margin: 0 auto;
            position: relative;
            z-index: 1;
            letter-spacing: 0.131875rem;
            font-weight: 600;
            width: 100%;
            color: #fff;
            font-size: 1rem;
            font-family: @font-family-menu-sans-serif;
            text-align: center;
          }
        }

        span {
          margin: 0 auto;
        }
      }
    }
  }

  #accordion_menu {
    position: relative;

    .panel-title {
      border-bottom: 1px solid @light-sand-brown;
      background-color: #fff;
      height: 78px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      position: relative;
      margin: 0;

      a {
        font-size: 1.25rem;
        color: @almost-black;
        text-transform: uppercase;
        font-family: @font-family-menu-sans-serif;
      }

      .arrow_accordion {
        position: absolute;
        top: 0;
        right: 0;
        height: 77px;
        width: 78px;
        border-left: 1px solid @sand-white;
        border-right: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        background: @sand-white-50;

        svg {
          width: 23px;
          height: 13px;
          margin: 0 auto;
          .rotate(180deg);
          .transition(@menu-transition-duration, ease-in-out);
        }

        &.collapsed {
          background: #FFF;

          svg {
            .rotate(0deg);
          }
        }
      }

      &.collapsed {
        border-color: @sand-white;
        background: #FFF;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .panel-body {
      .col-sm-offset-3 {
        margin-left: 2.8125rem;
      }

      .col-sm-offset-4 {
        margin-left: 3.75rem;
      }
      background: @sand-white;

      .panel-title {
        background: @sand-white-50;

        a {
          font-size: 1rem;
        }
      }
    }

    .sub_menu_accordion {
      .panel-default {
        border-bottom: 1px solid @light-sand-brown;
      }

      .panel-title {
        height: 75px;
        border-bottom: 1px solid @light-sand-brown;

        .arrow_accordion {
          border: 0;
          height: 74px;

          svg {
            width: 18px;
            height: 10px;
          }
        }

        &.collapsed {
          border-bottom: 0;

          .arrow_accordion {
            background: transparent;
          }
        }
      }

      .item_sub-menu {
        padding: 17px 0;
        width: 100%;
        display: inline-block;
        font-size: 0.875rem;
        color: @almost-black;
        text-transform: uppercase;
        font-family: @font-family-menu-sans-serif;
        letter-spacing: 0.125rem;
      }
    }
  }

  .menu_account {
    .space_white_menu {
      padding-top: 6.25rem;
      background: #FFF;
    }

    .bg_white_menu {
      background: #FFF;

      .link-black_menu {
        margin-left: 2rem;

        &:last-child {
          margin-bottom: 2.6125rem;
        }
      }
    }
  }

  .link-black_menu {
    font-family: @font-family-sans-serif;
    color: #000;
    font-size: 0.75rem;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    letter-spacing: 0.181875rem;
    margin-bottom: 2.8125rem;

    svg {
      margin-right: 1.0625rem;
      width: 25px;
    }
    img {
      margin-right: 1.0625rem;
      width: 25px;
    }
  }

  .bg_bottom_accordion {
    background: #dbdad1;
    height: 60px;
    & a[data-value="ar"] {
      &.link-black_menu {
        letter-spacing: 0;
      }
    }
    .link-black_menu {
      margin-top: 1.275rem;
      margin-bottom: 0;
      text-align: center;
    }
  }

  .bg_button {
    background: fade(@sandy-green, 40);
    text-align: center;
    height: 60px;

    span {
      margin: 0 auto;
      font-family: @font-arabic;
    }
  }
}

*[dir="rtl"] {
  .main-menu {
    .navbar .navbar-nav > li:first-child > a {
      margin-left: 0;
      margin-right: -@padding-x-area-in-hover;
    }
  }
}
@media (min-width: @screen-sm-min) {
  .main-menu {
    @screen-md-max: (@screen-ml-min - 1);
    @screen-ml-max: (@screen-lg-min - 1);
  }

  .main-menu {
    .navbar {
      #main-menu-search-img {
        width: 33px;
        height: 33px;
      }

      .etisalat-logo-img {
        margin-bottom: 0;
      }

      .navbar-nav > li > .navbar-text,
      .navbar-nav > li > a,
      .navbar-right a {
        padding: 0 @padding-x-area-in-hover;
      }
    }

    &.main-menu__fixed {
      height: @menu-height-slim;

      .navbar-header {
        height: @menu-height-slim;
      }

      .navbar {
        top: 0;

        &,
        .hamburger_icon {
          height: @menu-height-slim;
        }

        .etisalat-logo-img {
          width: 100px;
          height: 40px;
          margin-top: 5px;
          margin-bottom: 0;
        }

        .logo-black {
          padding-top: 7px;
          svg {
            &.etisalat-logo-img {
              height: 35px;
              width: 90px;
              margin-bottom: 0px;
            }
          }
        }

        .navbar-right > li > a,
        .navbar-nav > li > a {
          height: @menu-height-slim;
          line-height: @menu-height-slim;
        }
      }
    }

    &,
    &.main-menu__fixed.main-menu__full,
    &.main-menu__full {
      height: @menu-height-default;

      .navbar-header {
        height: @menu-height-default;
        //justify-content: flex-start;
      }

      .navbar {
        &,
        .hamburger_icon {
          height: @menu-height-default;
        }

        .etisalat-logo-img {
          // width: 127px;
          // height: 50px;
          // margin-top: 10px;
          width: 150px;
          height: 60px;
          margin-top: 6px;
          margin-bottom: 0;
        }

        .logo-black {
          padding-top: 0px;
          svg {
            &.etisalat-logo-img {
              height: 50px;
              width: 127px;
              margin-bottom: 0px;
            }
          }
        }

        .navbar-right > li > a,
        .navbar-nav > li > .navbar-text,
        .navbar-nav > li > a {
          height: @menu-height-default;
          line-height: @menu-height-default;
        }
      }
    }
  }

  .sub_menu_mobile {
    &.visible_menu {
      padding-top: @menu-height-default;

      &.sub-menu__fixed {
        padding-top: @menu-height-slim;

        &.sub-menu__full {
          padding-top: @menu-height-default;
        }
      }
    }

    #accordion_menu {
      .panel-title {
        height: 89px;

        .arrow_accordion {
          height: 88px;
          width: 88px;
        }
      }

      .panel-body {
        .col-sm-offset-3 {
          margin-left: 7.875rem;
        }

        .col-sm-offset-4 {
          margin-left: 10.625rem;
        }

        .panel-title {
          a {
            font-size: 1.125rem;
          }
        }
      }

      .sub_menu_accordion {
        .item_sub-menu {
          font-size: 1.125rem;
        }
      }
    }

    .menu_account {
      .bg_white_menu {
        .link-black_menu {
          margin-left: 4.1rem;
        }
      }
    }

    .link-black_menu {
      font-size: 1.125rem;
      margin-bottom: 3.125rem;
      svg {
        margin-right: 1.25rem;
        width: 31px;
      }
      img {
        margin-right: 1.25rem;
        width: 31px;
      }
    }

    .bg_bottom_accordion,
    .bg_button {
      height: 80px;

      .link-black_menu {
        margin: 0;
        line-height: 80px;
      }
    }
  }

  .arrow_accordion svg {
    fill: @dark-green;
  }

  .main-menu,
  .main-menu.main-menu__full {
    top: 0;
    position: relative;
  }
}
@media (min-width: @screen-md-min) {
  .main-menu {
    @screen-md-max: (@screen-ml-min - 1);
    @screen-ml-max: (@screen-lg-min - 1);
  }

  .main-menu,
  .main-menu.main-menu__full {
    top: 0;
  }

  .sub_menu_mobile {
    .menu_account {
      .bg_white_menu {
        .link-black_menu {
          margin-left: 5.3125rem;
        }
      }
    }

    .bg_bottom_accordion .link-black_menu-left {
      margin-left: 19%;
    }
  }

  .main-menu {
    .mega-dropdown {
      .sub-menu {
        h5 {
          a {
            min-height: 48px;
            align-items: flex-start;

            svg {
              margin-top: 6px;
            }
          }
        }
      }
    }
  }
  .sub_menu_mobile .mobileLoginMob #loginusermob .panel-title.mobileLoginMenu  {
      padding-left: 5.3125rem;
      padding-right: 5.3125rem;
  }
}
@media (min-width: @screen-ml-min) {
  .main-menu {
    //cutomize a breakpoint only for main-menu
    @screen-md-max: (@screen-ml-min - 1);
    @screen-ml-max: (@screen-lg-min - 1);

    .navbar {
      .logo_center a {
        margin: 0;
      }

      #main-menu-search-img {
        width: 24px;
        height: 25px;
      }

      .navbar-header {
        justify-content: flex-start;
      }

      .navbar-right {
        .account-link:hover .sub-account-menu-wrap {
          //display: block;
        }

        .sub-account-menu-wrap {
          position: absolute;
          width: 100%;
          display: none;
        }

        .sub-account-menu {
          width: 100%;
          position: absolute;
          top: 100%;
          left: 0;
          padding-left: 0;
          padding-top: 0.9rem;
          padding-bottom: 2.1rem;
          border-top: 5px solid @main-green;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
          background-color: @white;

          li {
            list-style: none;
            float: left;
            width: 100%;
            text-align: left;
            padding-top: 0.14rem;
            a {
              text-align: left;
              padding-right: 0;
            }
          }
        }
      }
      // long account name fix
      .navbar-header.text-right {
        .navbar-right {
          width: 100%;
          justify-content: flex-end;

          .account-link {
            width: 100%;
            max-width: 100%;
            min-width: 170px;
            //padding-left: 0;
            //padding-right: 2rem;
            > a {
              //padding-left: 2rem;
              //padding-right: 0;
              //white-space: nowrap;
              white-space: normal;//for double long name fit solution
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              line-height: 1;
              word-wrap: break-word;
            }
          }

          .search-link {
            > a {
              padding: 0 2rem;
            }
          }
        }
      }
    }

    li.account-link {
      position: relative;
    }

    &,
    &.main-menu__fixed.main-menu__full,
    &.main-menu__full {
      .sub-account-menu-wrap {
        height: /*1.9rem*/ 0;
      }
    }

    &.main-menu__fixed {
      .sub-account-menu-wrap {
        height: 0;
      }

      .navbar {
        top: 47px;
      }
    }
  }

  .sub_menu_mobile {
    display: none;
  }

  .main-menu,
  .main-menu.main-menu__full {
    top: 46px;
    position: fixed;
  }
}
@media (min-width: @screen-lg-min) {
  .main-menu {
    @screen-md-max: (@screen-ml-min - 1);
    @screen-ml-max: (@screen-lg-min - 1);

    .navbar {
      .logo_center a {
        margin: 0;
      }

      #main-menu-search-img {
        width: 24px;
        height: 25px;
      }

      .navbar-header {
        justify-content: flex-start;
      }

      .navbar-right {
        .account-link:hover .sub-account-menu-wrap {
          //display: block;
        }

        .sub-account-menu-wrap {
          position: absolute;
          width: 100%;
          display: none;
        }

        .sub-account-menu {
          width: 100%;
          position: absolute;
          top: 100%;
          left: 0;
          padding-left: 0;
          padding-top: 0.9rem;
          padding-bottom: 2.1rem;
          border-top: 5px solid @main-green;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
          background-color: @white;

          li {
            list-style: none;
            float: left;
            width: 100%;
            text-align: left;
            padding-top: 0.14rem;

            a {
              text-align: left;
              padding-right: 0;
            }
          }
        }
      }
      // long account name fix
      .navbar-header.text-right {
        .navbar-right {
          width: 100%;
          justify-content: flex-end;

          .account-link {
            width: 100%;
            max-width: 100%;
            min-width: 170px;
            //padding-left: 0;
            //padding-right: 2rem;
            > a {
              //padding-left: 2rem;
              //padding-right: 0;
              //white-space: nowrap;
              white-space: normal;// for longname solution
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              line-height: 1;
              word-wrap: break-word;
            }
          }

          .search-link {
            > a {
              padding: 0 2rem;
            }
          }
        }
      }
    }

    li.account-link {
      position: relative;
    }

    &,
    &.main-menu__fixed.main-menu__full,
    &.main-menu__full {
      .sub-account-menu-wrap {
        height: 0;
      }
    }

    &.main-menu__fixed {
      .sub-account-menu-wrap {
        height: 0;
      }
    }
  }

  .sub_menu_mobile {
    display: none;
  }
}
// mobile login section css start

.sub_menu_mobile {
	#accordion_menu {
		.panel-title {
			a.mobileLoginDDn {
				font-family: Lato,Roboto,Arial,sans-serif !important;
				color:@main-green;
				font-size: .75rem;
				text-transform: uppercase;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: horizontal;
				-webkit-box-direction: normal;
				-ms-flex-direction: row;
				flex-direction: row;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: start;
				-ms-flex-pack: start;
				justify-content: flex-start;
				letter-spacing: .181875rem;
				margin-bottom: 0rem;
				svg {
					margin-right: 1.0625rem;
					width: 25px;
					fill: #78a02f;
				}
				&+.arrow_accordion {
					border-left: 0px solid #f1f1eb;
					background: none;
					height: 58px;
				}
			}
			a.mobileLoginDDn&+.arrow_accordion {
				svg {
					fill: #78a02f;
				}
			}
		}
	}
	.mobileLoginMob {
		#loginusermob {
			.panel-title {
				height: 58px;
				border-bottom: none;
			}
		}
		margin-bottom: 1.4rem;
		.panel.panel-default.row {
			.panel-body {
				border-top: 1px solid #e2e1da;
			}
		}
		.sub_menu_accordion {
			a.col-sm-offset-3 {
				margin-left: 5.8125rem !important;
			}
		}
	}
}
svg {
	#myaccount {
		path {
			fill: #78a02f;
		}
	}
}
// end

@media (min-width: 568px) and (max-width: 767px) {

  .sub_menu_mobile .mobileLoginMob #loginusermob .panel-title.collapsed.blankSPace {
      width: 2rem;
  }
}
@media (min-width: 768px) and (max-width: 992px){
  .sub_menu_mobile .mobileLoginMob #loginusermob .panel-title.mobileLoginMenu  {
      padding-left: 4.1rem;
      padding-right: 4.1rem;
  }
}
.main-menu .navbar .navbar-right .sub-account-menu {
    position: relative;
}
.main-menu .navbar .navbar-right .sub-account-menu li {
     float: none;
     display: inline-block;
}

.main-menu-selfcare .menu-order.login-menu .login-order .termCondition {
  font-size: 0.68rem;
  color: #848789!important;
  margin-bottom: 0.15rem;
  letter-spacing: 0;
  text-align:Center;
    a {
       display: inline-block;
       padding: 0;
       letter-spacing: 0;
       color: #719e19 !important;
       &:hover{
         color: #719e19 !important;
         text-decoration: underline;
       }
   }
}
/*@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-menu .mega-dropdown .sub-menu .row-sub-menu .sub-menu-section {
    width: 25% !important;
  }
}*/
/*
 * ARABIC
 */
*[lang="ar"] {
  *[dir="rtl"] {
    .main-menu {
      .navbar {
        .navbar-nav > li > .navbar-text,
        .navbar-nav > li > a {
          font-family: @font-arabic;
          font-weight: 300;
          font-size: 1.25rem;
        }

        .account-link,
        li {
          a {
            font-family: @font-arabic;
          }
        }

        .account-link:after {
          left: 0;
          right: auto;
        }
      }

      .mega-dropdown {
        .sub-menu {
          ul {
            font-family: @font-arabic;
            font-weight: 400;
          }

          h5 {
            font-size: 1.375rem;

            a {
              svg {
                margin-right: 27px;
                margin-left: 0;
                transform: rotate(180deg);
                overflow: visible;

                use {
                  -moz-transform: rotate(180deg);
                  -moz-transform-origin: center center;
                }
              }
            }

            &.active {
              a {
                svg {
                  transform: rotate(90deg);

                  use {
                    -moz-transform: rotate(-90deg);
                    -moz-transform-origin: center center;
                  }
                }
              }
            }
          }
        }

        .tiles-menu {
          .row {
            .menu-tile {
              a {
                font-family: @font-arabic;
                font-weight: 400;
              }
            }
          }
        }
      }
    }

    .sub_menu_mobile {
      left: auto;
      right: 0;
      .transition(@menu-transition-duration, ease-in-out);

      &.visible_menu {
        padding-top: @menu-height-phone-default;
        top: 0;
      }

      .container_swip {
        .swiper_menu {
          margin-right: 5.3125rem;
          margin-right: 0;

          .swiper-slide {
            margin-left: 28px;
            margin-right: 0;
            flex-direction: column-reverse;
            align-items: flex-start;

            .cover {
              flex-direction: column-reverse;
              align-items: flex-start;

              a {
                letter-spacing: 0.131875rem;
              }
            }

            span {}
          }
        }
      }

      #accordion_menu {
        position: relative;

        .panel-title {
          flex-direction: column-reverse;
          align-items: flex-start;
          justify-content: center;
          position: relative;
          margin: 0;

          a {
            font-size: 1.25rem;
            color: @almost-black;
            font-family: @font-arabic;
          }

          .arrow_accordion {
            left: 0;
            right: auto;
            display: flex;
            border-right: 1px solid @sand-white;
            border-left: 0;
            flex-direction: column-reverse;
            align-items: flex-start;
            justify-content: center;

            svg {}

            &.collapsed {
              svg {}
            }
          }

          &.collapsed {}

          &:hover {}

          &.col-xs-1 {
            float: right;
          }
        }

        .panel-body {
          .col-sm-offset-3 {
            margin-right: 2.8125rem;
            margin-left: 0;
          }

          .col-sm-offset-4 {
            margin-right: 3.75rem;
            margin-left: 0;
          }

          .panel-title {
            a {}
          }
        }

        .sub_menu_accordion {
          .panel-default {}

          .panel-title {
            .arrow_accordion {
              svg {}
            }

            &.collapsed {
              .arrow_accordion {}
            }
          }

          .item_sub-menu {
            font-family: @font-arabic;
          }
        }
      }

      .menu_account {
        .space_white_menu {}

        .bg_white_menu {
          .link-black_menu {
            margin-right: 2rem;
            margin-left: 0;

            &:last-child {}
          }
        }
      }

      .link-black_menu {
        font-family: @font-arabic;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        svg {
          margin-left: 1.0625rem;
          margin-right: 0;
        }
        img {
          margin-left: 1.0625rem;
          margin-right: 0;
        }
      }

      .bg_bottom_accordion {
        .link-black_menu {}

        .pull-right-ar {
          float: right;
        }
      }

      .bg_button {
        span {
          margin: 0 auto;
          font-family: @font-family-menu-sans-serif;
        }
      }
    }
    @media (min-width: @screen-sm-min) {
      .main-menu .navbar .search-link a {
        padding-left: 2rem;
        padding-right: 0;
      }

      .sub_menu_mobile {
        &.visible_menu {
          padding-top: @menu-height-default;

          &.sub-menu__fixed {
            padding-top: @menu-height-slim;

            &.sub-menu__full {
              padding-top: @menu-height-default;
            }
          }
        }

        #accordion_menu {
          .panel-title {
            height: 89px;

            .arrow_accordion {
              height: 88px;
              width: 88px;
            }
          }

          .panel-body {
            float: right;

            .col-sm-offset-3 {
              margin-right: 7.875rem;
              margin-left: 0;
            }

            .col-sm-offset-4 {
              margin-right: 10.625rem;
              margin-left: 0;
            }

            .panel-title {
              a {
                font-size: 1.125rem;
              }
            }
          }

          .sub_menu_accordion {
            float: right;
            width: 100%;

            .item_sub-menu {
              font-size: 1.125rem;
            }

            .panel-title {
              width: 100%;
            }
          }

          .container_swip {
            float: right;
          }
        }

        .menu_account {
          .bg_white_menu {
            .link-black_menu {
              margin-right: 4.1rem;
              margin-left: 0;
            }
          }
        }

        .link-black_menu {
          font-size: 1.125rem;
          margin-bottom: 3.125rem;
          svg {
            margin-left: 1.25rem;
            margin-right: 0;
            width: 31px;
          }
          img {
            margin-left: 1.25rem;
            margin-right: 0;
            width: 31px;
          }
        }

        .bg_bottom_accordion,
        .bg_button {
          height: 80px;

          .link-black_menu {
            margin: 0;
            line-height: 80px;
          }
        }
      }
    }
    @media (min-width: @screen-md-min) {
      .main-menu .navbar .search-link a {
        padding-left: 2rem;
        padding-right: 0;
      }

      .sub_menu_mobile {
        .menu_account {
          .bg_white_menu {
            .link-black_menu {
              margin-right: 5.3125rem;
              margin-left: 0;
            }
          }
        }

        .bg_bottom_accordion .link-black_menu-left {
          margin-right: 19%;
          margin-left: 0;
        }

        .panel-title {
          float: right;
        }

        .menu_account {
          .bg_white_menu,
          .space_white_menu {
            float: right;
          }
        }

        .bg_bottom_accordion {
          float: right;
        }
      }
    }
    @media (min-width: @screen-ml-min) {
      .main-menu .navbar .search-link a {
        padding-right: 2rem;
        padding-left: 0;
      }

      .sub_menu_mobile {
        display: none;
      }

      .main-menu .navbar .navbar-right .sub-account-menu li a {
        text-align: right;
      }
      // long account name fix
      .navbar-header.text-right {
        .navbar-right {
          .account-link {
            //padding-left: 2rem;
            //padding-right: 0;
            > a {
              //padding-left: 0;
              //padding-right: 2rem;
              text-align: right;
            }
          }

          .search-link {
            > a {}
          }
        }
      }
    }
    @media (min-width: @screen-lg-min) {
      .main-menu .navbar .search-link a {
        padding-right: 2rem;
        padding-left: 0;
      }

      .sub_menu_mobile {
        display: none;
      }

      .main-menu .navbar .navbar-right .sub-account-menu li a {
        text-align: right;
      }
      // long account name fix
      .navbar-header.text-right {
        .navbar-right {
          .account-link {
            //padding-left: 2rem;
            //padding-right: 0;
            > a {
              //padding-left: 0;
              //padding-right: 2rem;
              text-align: right;
            }
          }

          .search-link {
            > a {}
          }
        }
      }
    }
    //mobile menu css
  .sub_menu_mobile {
    #accordion_menu {
      .panel-title {
        a.mobileLoginDDn {
          svg {
            margin-right: 0;
            margin-left: 1.0625rem;
          }
        }
        a.mobileLoginDDn&+.arrow_accordion {
          border-right: 0 solid #f1f1eb;
        }
      }
    }
  }
  //
  .sub_menu_mobile #accordion_menu .panel-title a.mobileLoginDDn+.arrow_accordion {
      border-right: 0 solid #f1f1eb;
  }
  @media (min-width: 568px) and (max-width: 767px) {
    .sub_menu_mobile .mobileLoginMob #loginusermob .panel-title.collapsed.blankSPace {
        width: 2rem;
    }
  }

  }
}
/*mobile search icon alignment*/
@media (max-width: @screen-ms-max) {
  .main-menu .navbar .navbar-right>li>a {
     height: 60px;
     line-height:1;
 }
}

/*
 * Business Variant
 */
.business {
  .main-menu {
    .navbar {
      .nav > li > a:focus,
      .nav > li > a:hover,
      .navbar-right > li.active > a.signIn,
      .navbar-right > li > a.signIn:hover,
      .navbar-nav > .active > a:focus,
      .navbar-nav > .active > a:hover {
        color: @white;
        background-color: @dark-green;
      }

      .navbar-nav > .active > a {
        color: @dark-green;
      }

      .hamburger_icon span {
        background-color: @dark-green;
      }

      .hamburger_icon.open {
        background-color: @dark-green;

        span {
          background-color: @white;
        }
      }
    }
  }

  .sub_menu_mobile {
    .arrow_accordion svg {
      fill: @dark-green;
    }
  }

  .mega-dropdown {
    .sub-menu {
      h5,
      ul li {
        &:hover {
          a {
            color: @dark-green;
          }

          svg {
            fill: @dark-green;
          }
        }

        &.active {
          a {
            color: @dark-green;
          }

          svg {
            fill: @dark-green;
          }
        }
      }
    }
  }

  .main-menu .navbar .navbar-right .sub-account-menu {
    border-top: 5px solid @dark-green;
  }
}

@media (min-width: @screen-xs-min) {
  .sub_menu_mobile .container_swip .swiper_menu {
    margin-left: 2rem;
  }
}
@media (min-width: @screen-ms-min) {
  .sub_menu_mobile .container_swip .swiper_menu {
    margin-left: 2.3125rem;
  }
}
@media (min-width: @screen-sm-min) {
  .sub_menu_mobile .container_swip .swiper_menu {
    margin-left: 4.3125rem;
  }
}
@media (min-width: @screen-md-min) {
  .sub_menu_mobile .container_swip .swiper_menu {
    margin-left: 5.3125rem;
  }

  .business {
    .main-menu {
      .mega-dropdown {
        .sub-menu {
          h5 {
            a {
              min-height: 48px;
              align-items: flex-start;

              svg {
                margin-top: 6px;
              }
              @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                svg {
                 position: absolute;
                }
              }
            }
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
              a {
            display: block;
            max-width: 120px;
               }
            }
          }
        }
      }
    }
  }
}

/*
 * ARABIC
 */
*[lang="ar"] {
  *[dir="rtl"] {
    .sub_menu_mobile .container_swip .swiper_menu {
      margin-right: 2rem;
      margin-left: auto;
    }
    @media (min-width: @screen-xs-min) {
      .sub_menu_mobile .container_swip .swiper_menu {
        margin-right: 2rem;
        margin-left: auto;
      }
    }
    @media (min-width: @screen-ms-min) {
      .sub_menu_mobile .container_swip .swiper_menu {
        margin-right: 2.3125rem;
        margin-left: auto;
      }
    }
    @media (min-width: @screen-sm-min) {
      .sub_menu_mobile .container_swip .swiper_menu {
        margin-right: 4.3125rem;
        margin-left: auto;
      }
    }
    @media (min-width: @screen-md-min) {
      .sub_menu_mobile .container_swip .swiper_menu {
        margin-right: 5.3125rem;
        margin-left: auto;
      }
    }

  }
}
