/*
============================================
01 - xs viewport
01a - .media-cta-wrapper
02 - sm viewport
03 - md viewport
04 - lg viewport
05 - Arabic overrides
06 - Business overrides
07 - background colors
============================================
*/
/* -----------------------------------------
01 - mobile view port
01a - .media-cta-wrapper
----------------------------------------- */
.media-cta-wrapper {
  position: relative;
  flex-wrap: wrap;
  display: flex;

  & > div {
    min-height: 20.25rem;
  }

  &.media-cta-bleed {
    .label-tag {
      position: absolute;
      top: 0;
      left: 0;
      right: auto;
      color: @white;
      padding: 1.25rem;
      font-size: 1rem;
      z-index: 4;
      min-height: 0;
      height: auto;
      font-family: @headings-font-family;

      .detail-price-new {
        color: @white;
        .main-part{
          .price{
            font-size: 1.875rem;
          }
        }
      }
    }

    &.col-reverse {
      .label-tag {
        right: 0;
        left: auto;
      }

      .media-cta-photo {
        @media (min-width: @screen-md-min) {
          max-height: 50vw;
        }

        .cover {
          left: auto;
          right: 0;
        }

        .simple-image {
          > img {
            left: inherit;
            right: 0;
          }
        }
      }
    }

    .media-cta-photo {
      position: relative;
    }
  }
  &.media-cta-expo-2020{
    .bg-filter-expo-overlay{
      background-color: #000000;
      opacity: 0.4;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      top: 0;
      left: 0;
    }
    .media-cta-text{
     position: relative;
     z-index: 3;
     align-items: unset;
     min-height: 35rem;
     h2{
      font-size: 1.25rem;
      font-family: Lato,Roboto,Arial,sans-serif;
      letter-spacing: 2px;
      padding: 3rem 0;
      margin-bottom: 5.9375rem;
      .icon-quote{
        width: 3.25rem;
        height: 2.5625rem;
        float: left;
        margin-right:1.25rem;
        svg{
          width: 100%;
          height: 100%;
          fill: #fff;
        }
      }
    }
    p{
      color: #e2e2e2 !important;
      font-size: 1.875rem;
      letter-spacing: 0.3125rem;
      line-height: 2.9rem;
      margin-left:4.55rem;
      font-family: Neotech,Roboto,Arial,sans-serif;
    }

  }
  .media-cta-photo{
    min-height: 28vw !important;
  }
}
&.media-cta-k-expo-2020{
  .media-cta-caption {
    max-height: 27.9vw;
    height: 34.4rem;
    text-align: left;
    h3{
      font-size: 30px;
      letter-spacing: 3px;
      line-height: 36px;
      color: @almost-black;
    }
    p{
      font-size: 16px;
      line-height: 25px;
      color: @steel-grey-solitude;
    }
    @media (max-width: 767px) {
      h3{
        font-size: 18px;
        letter-spacing: 0.56px;
        line-height: 24px;
        color: @almost-black;
        text-align: center;
      }
      p{
        font-size: 14px;
        line-height: 20px;
        color: @steel-grey-solitude;
      }
    }
  }
}

.media-cta-caption {
  display: flex;
  justify-content: start;
  align-items: center;
  overflow: hidden;
  max-height: none;

  .btn {
    // max-width: 100%;
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2.5rem;

    > div {
      display: flex;
      flex-direction: column;

      &:nth-child(2) {
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;

        .btn {
          //margin-top: 2rem;
          margin-left: 0;
          margin-right: 0;
          float: none;
        }

        .detail-price-new {
          top: -0.3125rem;

          .price {
            &[dir="ltr"] {
              font-family: @headings-font-family;
            }
            font-weight: 400;
          }
        }
      }
    }
  }
}

.media-cta-text {
  display: flex;
  align-items: center;
  position: relative;
  height: auto;
  padding-top: 1.875rem;
  background-color: transparent;
  padding-bottom: 1.875rem;

  .h2,
  .h3,
  .h4,
  h2,
  h3,
  h4 {
    margin: 0 0 1.875rem;
  }
}

.media-cta-photo {
  background-color: @white;
  height: 64vw;
  overflow: hidden;

  > .simple-image {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    > img {
      max-width: 100% !important;
      max-height: 90% !important;
      height: auto !important;
      width: auto !important;
      margin: 0 !important;
      padding: 0 !important;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    > img {
      left: 0;
      transform: translate(0, -50%);
    }

    > img.cover-left {
      left: auto;
      right: 0;
    }
  }

  > .cover {
    display: block;
    position: absolute;
    top: 0;
    left: 0;

    img.cover {
      max-height: inherit !important;
      max-width: inherit !important;
      padding: 0 !important;
    }
  }

  &.rect-responsive {
    //display: block;
  }

  img {
    //max-height: 90%;
    //max-width: 90%;
  }

  .video-container {
    position: absolute;
  }

  .video-container {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  video {
    position: absolute;
    z-index: 0;
    left: 0;
  }

  video.fillIt,
  video.fillItW {
    width: 100%;
    height: auto;
  }

  video.fillItH {
    height: 100%;
    width: auto;
  }

  .video-controls {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 3.75rem;
      width: 3.75rem;
    }
  }
}

p,
ul {
  color: @warm-gray;
  margin-bottom: 0;
  //text-align: left;
}

ul {
  padding-left: 0;
  font-style: 0.875rem;
  list-style-position: inside;
}

p + p {
  margin-top: 1.2rem;
}

li+li {
  margin-top: 0.55rem;
}

.h3,
h3 {
  font-size: 1.25rem;
  margin-top: 0;
  margin-bottom: 1.7rem;
  //padding-top: 3.25rem;
  padding-top: 0;
  //text-align: left;
}

.h4,
h4 {
  //margin-top: 2rem;
  margin-top: 0;
  margin-bottom: 1.7rem;
  padding-top: 0;
  //text-align: left;
}

.btn {
  margin-top: 1.2rem;
  margin-left: auto;
  margin-right: auto;
}

.form-submit .btn {
  margin-top: 0;
  padding: 0;
}

.media-cta-caption {
  > div {
    > div {
      &:nth-child(2) {
        .btn {
          //margin-top: 2rem;
          margin-left: 0;
          //margin-right: auto;
          margin-right: 0;
        }
      }
    }
  }
}

.btn-accordion {
  padding-left: 0;
  border: none;
  text-align: left;
  background-color: transparent;

  svg {
    height: 11px;
    width: 7px;
    margin-left: 23px;
    transform: rotate(90deg);
    transform-origin: center center;
    overflow: visible;
    .transition();

    use {
      -moz-transform: rotate(270deg);
    }
  }

  &:active,
  &:focus,
  &:hover {
    border: none;
    background-color: transparent;
    color: @custom-gray;
  }
}

.media-cta-caption {
  > div {
    //text-align: left;
    p.media-cta-icon {
      padding-left: 2.5rem;
      position: relative;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 1rem;
      padding-bottom: 0;
      min-height: 1.375rem;

      &:last-child {
        padding-bottom: 1rem;
      }

      svg {
        position: absolute;
        left: 0;
        fill: @almost-black;
        width: 1.375rem;
        height: 1.375rem;
      }
    }
  }
}

.more-details-triangle {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0%) rotate(180deg);
  width: 40px;
  height: 16px;
  fill: @light-gray;
  z-index: 1;
  display: none;
}

.media-cta-details {
  background-color: @white;
  max-height: none;
  padding: 0;
  display: block;
  flex-basis: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
  min-height: 0;
  transition: all 0.8s ease-in-out;

  &.slideDown {
    opacity: 1;
    transition: all 0.8s ease;
    min-height: auto;
  }

  > .row {
    padding-top: 2.175rem;
    padding-bottom: 1.9rem;
  }

  .detailsBox-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > div {
      flex-basis: 100%;
    }
  }

  .h5,
  h5 {
    color: @main-green;
    margin-bottom: 0.2rem;
    margin-top: 1.325rem;
  }

  p,
  ul {
    color: @almost-black;
    font-size: 0.875rem;
  }

  .close-btn-wrap {
    .btn {
      margin-top: 2.7rem;
    }
  }
}

&.media-cta-open {
  .more-details-triangle {
    display: block;
  }

  .btn-accordion {
    svg {
      transform: rotate(-90deg);
    }
  }
}

.detail-price-info {
  display: inline-flex;
  padding-left: 3.125rem;
  vertical-align: bottom; /* CMS-B2C: PRICE ALIGN WITH BUTTON AT BOTTOM */
  @media (max-width: 460px) {
    /* padding-left: 0;*/
  }
}

.detail-price-new {
  font-size: 2.5rem;
  position: relative;
  //display: inline-block;
  font-family: @headings-font-family;
  //font-weight: 600;
  line-height: 0.1rem;
  text-align: left;
  //padding-top: 0.9375rem;
  //padding-bottom: 1rem;
  color: @almost-black;

  .price-valute {
    font-size: 0.875rem;
    display: inline-block;
    line-height: 0.65rem;
  }

  .price-timing {
    font-size: 1rem;
    line-height: 3rem;
  }
}

.btn-arrow-only {
  display: block;
  //float: right;
  padding-top: 1.6rem;

  svg {
    stroke: @almost-black;
    transform: rotate(180deg);
    width: 1.5625rem;
    height: 0.625rem;

    use {
      -moz-transform: translate(0px) rotate(-180deg);
    }
  }
}

.form-wrapper {
  padding-top: 3.625rem;
}

.media-cta-text {
  h2 {
    //text-align: left;
  }
}
&.media-cta-careers-code{
  display: block;
  @media (max-width: 767px) {
    padding-top: 0px;
  }
  .media-cta-careers-code-title{
    padding-left: 1rem;
    padding-right: 1rem;
    height: auto;
    min-height: auto;
    background-color: transparent;
    h1{
      font-size: 54px;
      color: @almost-black;
      @media (max-width: 767px) {
        font-size: 26px;
        line-height: 36px;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 0px;
        br{
          display: none;
        }
      }
    }
  }
  .media-cta-caption{
    font-size: 20px;
    text-align: left;
    h3{
      font-size: 30px;
      letter-spacing: 3px;
      line-height: 38px;
      color: @almost-black;
      @media (max-width: 767px) {
        font-size: 26px;
        line-height: 36px;
        text-align: center;
      }
    }
    p{
      font-size: 20px;
      line-height: 32px;
      color: @steel-grey-solitude;
    }
    ul{
      margin-bottom: 2rem;
      li{
        padding-left: 0.5rem;
        margin-left: 1.2rem;
        padding-right: 1rem;
        margin-right: 2rem;
        margin-bottom: .5rem;
        font-size: 16px;
        line-height: 18px;
        color: @steel-grey-solitude;
        list-style: disc;
      }
    }
    @media (max-width: 767px) {
      p{
        margin-bottom: 25px;
        font-size: 14px;
        line-height: 20px;
      }
      ul{
        li{
          padding-right: 0px;
          margin-right: 0px;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
}


/* style media-cta-full-bleed with <img> tag */

.media-bg-cover {
  width: 100%;
  overflow: hidden;
  min-height: 465px;
  position: relative;

  img + .media-cta-bleed {
    min-height: 465px;
  }

  .body-standard {
    color: @white;
    p, ul li {
      color: @white;
    }
  }

  img {
    min-width: 100%;
    position: absolute;
    min-height: 465px;
    height: 100%;
  }
}


/* -----------------------------------------
02 - sm viewport
----------------------------------------- */


@media (max-width: 1439px) {
  .media-cta-wrapper.media-cta-expo-2020 .media-cta-text h2{
    margin-bottom: 1rem;
  }
  .media-cta-wrapper{
    &.media-cta-expo-2020{
      .media-cta-text{
       h2{
        .icon-quote{
          display: none;
        }
      }
      p{
        color: @almost-black !important;
        font-size: 1.5rem;
        margin-left: 0px;
        line-height: 1.5;
        letter-spacing: 0;
      }

    }

  }
}



}
@media (max-width: 1279px) {
  .row-tiles.big-tiles-box-wrapper .big-tiles-box{
    padding: 1rem 3rem;
    height: 33rem;
  }
  .media-cta-wrapper.media-cta-expo-2020 .media-cta-text{
    height: auto;
    min-height: auto;
    padding-top: 0;
  }
  .media-cta-wrapper.media-cta-expo-2020 .media-cta-text h2 {
    margin-bottom: 1rem;
    padding-top: 0;
    padding-bottom: 0px;
  }

}
@media (max-width: 1024px) {
  .media-cta-wrapper.media-cta-k-expo-2020 .media-cta-caption{
    max-height: none;
    height: auto;
  }
}
@media (max-width: 991px) {
  .media-cta-wrapper.media-cta-expo-2020 .media-cta-text h2 {
    margin-bottom: 1rem;
    padding-top: 2rem;
    padding-bottom: 0px;
  }
}
@media (max-width: 767px) {
  .row-tiles.big-tiles-box-wrapper .big-tiles-box{
    height: auto;
  }
  .main-text.main-text-expo-2020 h2{
    font-size: 1.5rem;
  }
  .main-text.main-text-expo-2020 h4{
    font-size: 1.225rem;
  }
}

@media (min-width: @screen-sm-min) {
  .media-cta-wrapper {
    &.col-reverse {
      display: flex;
      flex-direction: row-reverse;
      align-items: left;
    }

    & > div {
      height: 29rem;
      min-height: 29rem;
    }

    .media-cta-caption {
      height: auto;
      min-height: auto;
    }

    .body-standard {
      padding: 0;
      //text-align: left;
    }

    .h3,
    .h4,
    h3,
    h4 {
      font-size: 1.5rem;
      margin-top: 0;
      margin-bottom: 2.1rem;
      //padding-top: 2rem;
      //text-align: left;
    }

    p{
      font-size: 1.1rem;
    }
    ul {
      font-size: 1.1rem;
      li{
        // list-style: disc;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .detail-price-info {
      padding-left: 2.84375rem;
    }

    .detail-price-new {
      font-size: 2.5rem;
      //padding-top: 0.5rem;
      .price-valute {
        display: inline-block;
        line-height: 0.65rem;
      }
    }

    .btn-accordion {
      padding-top: 1rem;
      padding-right: 0.25rem;
    }

    .media-cta-details {
      .detailsBox-container {
        > div {
          flex-basis: 45%;
        }
      }
    }


  }

  .media-cta-wrapper .detail-price-new .price-timing {
    line-height: 3rem;
  }
}
/* -----------------------------------------
03 - md viewport
----------------------------------------- */
@media (min-width: @screen-md-min) {
  .media-cta-wrapper {
    &.media-cta-bleed {
      .label-tag {
        padding: 2.1875rem;
        font-size: 1.5625rem;
        .detail-price-new {
          .main-part{
            .price{
              font-size: 2.5rem;
            }
          }
        }
      }
    }

    &.col-reverse {
      .media-cta-caption {
        margin-right: 8.33333333%;
      }
    }

    .form-wrapper {
      padding-top: 1rem;
    }

    .floating-label-select {
      margin-bottom: 1.5rem;
    }

    & > div,
    .media-cta-caption {
      max-height: 27.9vw;
    }

    .media-cta-caption {
      max-height: 37vw;
      height: 29rem;
      min-height: 29rem;
    }
    .detail-price-info {
      flex: 1;
    }

    p {
      padding-bottom: 1.4rem;
    }

    p + p {
      margin-top: 0;
    }

    .h3,
    .h4,
    h3,
    h4 {
      font-size: 1.5rem;
      margin-bottom: 1.82rem;
    }

    .h4,
    h4 {
      padding-bottom: 0;
    }

    .h3 {
      padding-bottom: 0.5rem;
    }

    .custom-sm-layout {
      display: inherit;

      .btn {
        float: none;
      }
    }

    .btn {
      //margin-top: 2.9375rem;
    }

    .media-cta-details {
      .detailsBox-container {
        > div {
          flex-basis: 25%;
        }
      }
    }
  }
}
/* -----------------------------------------
04 - lg viewport
----------------------------------------- */
@media (min-width: @screen-lg-min) {
  .media-cta-wrapper {
    &.media-cta-w
    & > div,
    & > div.media-cta-caption {
      max-height: 27.9vw;
    }

    &.cta-big-varint {
      .media-cta-caption,
      .media-cta-photo {
        min-height: 37vw;
        height: auto;
      }
    }

    p {
      margin-bottom: 0.6rem;
      font-size: 1rem;
    }

    .h3,
    .h4,
    h3,
    h4 {
      font-size: 2rem;
      margin-bottom: 1.7rem;
    }

    .h3,
    .h4,
    h4 {
      margin-top: 0;
    }

    .btn {
      //margin-top: 2.9375rem;
    }

    .detail-price-new {
      font-size: 3.125rem;
    }

    .media-cta-details {
      padding-top: 0;
      padding-bottom: 0;

      &.slideDown {
        height: auto;
        transition: all 0.8s ease-in-out;
        min-height: 37vw;
      }

      p {
        font-size: 1rem;
      }

      .close-btn-wrap {
        .btn {
          margin-top: 1.1rem;
        }
      }
    }

    .btn-arrow-only {
      //padding-top: 3.4rem;
    }
    &.media-cta-bleed{
      .media-cta-photo {
        position: static;
      }
      &.media-cta-text-wh {
        .media-cta-text {
          .body-standard {
            color: @white;

            p {
              color: @white;
            }

            ul {
              color: @white;
            }

            a {
              color: @white;
              text-decoration: underline;

              &.btn {
                color: @white;
                border-color: @white;
                text-decoration: none;
              }
            }

            .detail-price-new {
              color: @white;
            }
          }
        }
      }
      .label-tag {
        .detail-price-new {
          .main-part{
            .price{
              font-size: 3.375rem;
            }
          }
        }
      }
    }
  }

  .media-cta-wrapper-custom {
    & > div {
      height: 40rem;
    }
  }

  .media-cta-wrapper .detail-price-new .price-timing {
    line-height: 3.4rem;
  }
}
/* -----------------------------------------
05 - Arabic styles
----------------------------------------- */
*[lang="ar"] {
  *[dir="rtl"] {
    .media-cta-wrapper.media-cta-expo-2020 .media-cta-text h2{
      text-align: right;
      font-size: 1.25rem;
      font-family: @font-arabic;
    }
    .media-cta-wrapper.media-cta-expo-2020 .media-cta-text h2 .icon-quote {
      float: right;
      margin-right: 0;
      margin-left: 1.25rem;
      transform: scaleX(-1);
      -moz-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      -ms-transform: scaleX(-1);
    }
    .media-cta-wrapper.media-cta-expo-2020 .media-cta-text p{
     font-weight: 400;
     margin-right: 0;
     margin-left: 4.55rem;
      font-family: @font-arabic;
   }
   .pull-right-ar {
    float: right !important;
  }

  .pull-left-ar {
    float: left !important;
  }
  .text-right{
    text-align: left;
  }
  .text-left {
    text-align: right;
  }

  .media-cta-wrapper {
    .btn-arrow-only svg {
      transform: rotate(0deg);
    }

    .body-standard {
      //text-align: right;
    }

    .custom-sm-layout {
      text-align: right;
    }

    .media-cta-caption {
      > div {
        > div {
          &:nth-child(2) {
            .btn {
              margin-left: 0;
              margin-right: 0;

              svg {
                margin-left: 0.25rem;
              }
            }
          }
        }
      }
    }

    .h2,
    .h3,
    .h4,
    h2,
    h3,
    h4 {
      //text-align: right;
    }

    p,
    ul {
      //text-align: right;
    }

    ul {
      padding-right: 0;
      padding-left: auto;
    }

    .btn-accordion {
      padding-right: 0;

      svg {
        margin-right: 23px;
      }
    }

    .media-cta-details {
      p {
        //text-align: right;
      }
    }

    .detail-price-info {
      padding-left: 0;
      padding-right: 3.125rem;
      @media (max-width: 767px) {
        padding-right: 1rem;
      }
      .detail-price-new {
        text-align: right;
      }
    }

    .media-cta-caption {
      > div {
        p.media-cta-icon {
          padding-left: 0;
          padding-right: 2.5rem;

          svg {
            left: inherit;
            right: 0;
          }
        }
      }
    }

    .media-cta-text {
      h2 {
        //text-align: right;
      }
    }

    &.media-cta-bleed {
      .label-tag {
        right: 0;
        left: auto;
        font-family: @font-arabic;

        .detail-info-wrap-pricetag .main-part .price {
          font-family: @font-arabic;
        }
      }

      &.col-reverse {
        .label-tag {
          left: 0;
          right: auto;
        }
      }
    }
  }
  @media (min-width: @screen-sm-min) {
    .custom-sm-layout {
      text-align: right;
    }

    .detail-price-info {
      padding-right: 2.84375rem;
    }
  }
}
}
/* -----------------------------------------
06 - Business overrides
----------------------------------------- */
.business {
  .media-cta-container {
    background-color: @light-sand-brown;
  }

  .media-cta-wrapper {
    .media-cta-caption {
      .btn.btn-default {
        color: inherit;
        background-color: inherit;
        border-color: inherit;

        &:hover:not(.btn-accordion) {
          color: @white;
          background-color: @dark-green;
          border-color: @dark-green;
        }
      }
    }
  }
}
/* -----------------------------------------
07 - background colors
----------------------------------------- */
.media-cta-container {
  background-color: @sand-white;

  &.media-cta-container-wh {
    background-color: @white;
  }

  &.media-cta-container-gr {
    background-color: @light-sand-brown;
  }
}

@media (max-width: 1439px) {
  .media-cta-wrapper.media-cta-expo-2020 .media-cta-text h2 {
    color: @warm-gray;
    font-size: .875rem;
    font-family: @font-family-base;
    letter-spacing: 0;
    padding-top: 2rem;
    line-height: 1.6;
  }
  .body-standard {
    text-align: center;
  }

  *[lang="ar"] {
    *[dir="rtl"] {
      .media-cta-wrapper.media-cta-expo-2020 .media-cta-text {
        h2 {
          color: @warm-gray;
          font-size: .875rem !important;
          padding-top: 2rem;
          text-align: center !important;
          line-height: 1.6;
        }
        p {
          margin-left: 0 !important;
        }
      }
      &.media-cta-careers-code{
        .media-cta-caption{
          @media (max-width: 767px) {
            ul{
              li{
                padding-right: 1rem;
                margin-right: 2rem;
              }
            }
          }
        }
        
      } 
      
      
    }
  }
}


// this change is requested by business to make height 100% for media cta components
.media-cta-wrapper .media-cta-caption {
  height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
  @media (max-width: 767px) {
    text-align: left;
  }
}
