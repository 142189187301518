// out of stock border color css
//.config-tile-wrapper .tile-wrap input[type=radio]:disabled+.tile, .config-tile-wrapper .tile-wrap input[type=radio]:disabled+.tile .tile-option {
//     border: 1px solid #f00;
// }

// page navigation centerlized

// .page-nav-menu-row {
//   .page-nav-main-label {
//     position: absolute ;
//     z-index: 2; // for product page title visiblity issue
//   }
// }
/*COMMENT ABOVE CSS FOR CMS ONLY*/
// top menu left-aligned
.menu-left {
  justify-content: left !important;
}
.overflowVisible {
  overflow: visible  !important;
}
//CMS FIX FOR DATA ROAMING TEXT ALIGNMENT
// also commit one css b2c CMS in 02-main-menu.less line 75 " &.menu-left {"

/*change for 27-plain-table color change*/
// .plans-table.plans-table-A .tile-table .tile .tile-card__back .tiles-box p.back-text {
//   color:@brand-primary;
// }

//arabic table xs-10 column width overright
.col-xs-10.col-xs-offset-1.text-and-table-F {
    width: 83.33333333% !important; // text-and-table-F width overright for arabic
    @media (max-width: 1023px){
          overflow-x: auto;
    }
}
.navbar.vatspacefix {
  @media (min-width: 768) {
    margin-top: 29px;
  }
}
//zindex1 class
.zIndex1 {
    z-index: 1;
}



.form .floating-label-select label.grayLabel {
    top: .9375rem;
    font-size: .9375rem;
    line-height: 1.125rem;
    color: #848789;
}
.plans-table.plans-table-A .tile-table .tile .tile-card__back .tiles-box .back-text ul {
    margin: 0 1.625rem;
    height: 24.3rem;
    text-align: left;
    padding: 1.5rem 0;
    list-style-type: none;
    @media (max-width: 1440px) and (min-width: 992px) {
      height: 19.25rem;
    }
    @media (max-width: 768px)  {
      height: 24.25rem;
    }
}
//curser on slider on main page
.swiper-container .swiper-slide {
    cursor: pointer;
}


// .plans-table.plans-table-A .tile-table .tile .tile-card__back .tiles-box .back-text ul li {
//     list-style: none;
//     margin: 0.25rem 0;
// }

.plans-table.plans-table-A .tile-table .tile .tile-card__back .tiles-box .back-text ul li:nth-child(1) {
    margin-top: 1.5rem;
}

//ul > ul 29oct17
.plans-table.plans-table-A .tile-table .tile .tile-card__back .tiles-box .back-text ul> ul {
//    padding: 0 0.1rem;
    margin: 0;
}
.bg-white-sand-brown-filler-md {
  background-color: @white;
}
@media (min-width: 993px) and (max-width: 1024px) {
  .bg-white-sand-brown-filler-md {
    background-color: @light-sand-brown;
  }
}



//smartphone big imaegs

.media-cta-wrapper.bigPhoneImage {
	.simple-image.cover {
		position: relative;
		left: auto;
		top: auto;
	}
	.media-cta-photo {
		height: 100%;
		max-height: 100%;
		>.cover {
			img.cover {
				max-height: 100% !important;
				max-width: 100% !important;
				padding: 0 !important;
				position: relative;
				left: auto;
				right: auto;
				top: auto;
				bottom: auto;
				margin: auto;
				min-width: 100%;
				min-height: 100%;
			}
		}
	}
}
// confirmation message full width fix
.confirmationRow {
    display: block !important;
    .margin-top-2rem-xs {
        margin-top: 0 !important;
    }
    .btn {
      margin: 0 !important;
    }
}

.plans-table.plans-table-A .tile-table .tile .tile-card__back .tiles-box .back-text {
  min-height:308px;
  @media (max-width: 768px) {
    min-height: 400px;
  }

}


.config-accordion-wrapper .config-accordion--verC.manage-order span.label_accordion {
  @media (max-width: 767px)  {
    letter-spacing: 0;
    font-size: .775rem;
  }
}
.config-accordion-wrapper .config-accordion--verC.manage-order .text-creation-date.text-align-L.text-align-RM {
    @media (max-width: 767px)  {
      text-align: left;
    }
}



// IE LABEL ANIMATION DEFECDT SOLUTION RTC# 324567
.form .floating-label-input input.titleGray~label {
    top: .9375rem;
    font-size: .9375rem;
    line-height: 1.125rem;
    color: #848789;
    z-index: 2;
}
// b2c cms arabic text fix on banner
// safari elife family block issue fix: waqas
.row:before {
  display:block;
}
/**/
//MODIFY ELIFE BUTTON height
// .modify-me-elife-form-submit button {
//     height: 4.9995rem;
// }

  .modify-me-elife-form-submit {
    button {
      @media (min-width: 768px) and (max-width: 1440px) {
        height: 4.25rem
      }
      @media (min-width: 1441px){
        height:4.9995rem;
      }

    }
  }


//  business go smart page bottom text vertical alignemetn
@media (min-width: 768px){

  .go-smart-why-etisalat {
      display: table;
      width: 100%;
      padding-top: 3.13rem;
  }

  .titlePart h2 {
      padding: 0;
  }

  .contentPart {
      float: none;
      display: table-cell;
      vertical-align: middle;
  }

  .titlePart {
      float: none;
      display: table-cell;
      vertical-align: middle;
  }

  .contentPart .body-big {
      text-align: right !important;
  }

}
.swiper-carousel-container {
  height:100% !Important;
  margin-bottom:2rem;
}
.select2-container--open {
	z-index:6;//change from 1 to 6 for RTC 332117
}
// white tile css
.config-tile-wrapper input:checked+.tile.tile-gray.whiteTile.reverse .tile-label {color:#333 !important;}
.config-tile-wrapper input:checked+.tile.tile-gray.whiteTile.reverse .tile-specs {color:#333 !important;}
.config-tile-wrapper input:checked+.tile.tile-gray.whiteTile.reverse .tile-color{  border: 1px solid #ccc;     top: -1px;}
.config-tile-wrapper input:checked+.tile.tile-gray.whiteTile.reverse .tile-tick svg {
  //  stroke: #D1D2CE !important;
    fill: #D1D2CE !important;
}


.config-tile-wrapper input:checked+.tile.whiteTile.reverse .tile-label, .config-tile-wrapper input:checked+.tile.whiteTile.reverse .tile-specs, .tile-gray.whiteTile.reverse:hover .tile-label {
    color: #000;
}
.config-tile-wrapper .tile-wrap:hover>.tile.whiteTile.reverse:hover>.tile-color {
  //  border: 1px solid #d1d2d1;
    background-color: #f7f7f7 !important;
  //  top: -1px;
}

// business readmore button width fix
.business .editorial-placement .tiles-box .read-more {
    width: 100%;
}


//responsive images
.responsive-img-wraper {
  .img-responsive {
    width: 100%;
  }
}
//2column images
.responsive-img-wraper-2col {
  .img-responsive {
    width:50%;
  }
}
//visitor line checkout page creditcard margin setting
a.payment_method.active {
	margin-left: -105px;;
}

//copying css from jsp page

	.tile-wrap .tile-specs-price-from {
		font-size: 1rem;
		bottom: .65rem;
		left: 1rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 89%;
		position: absolute;
		color: #848789;
		transition: all .3s
	}

//homepage map tab arrows start
.store-locator-wrap .map-container .background-map .map-filter .map-filter-topnav .map-filter-list ul li.active {position:relative;}

.swiper-slide.active:after {
    margin-left: -12px;
    left:50%;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #ddd;
}
.swiper-slide.dark-gray.active:after {
    border-bottom: 12px solid #242424;
}

.swiper-slide.orange-b.active:after {
    border-bottom: 12px solid #ff8000;
}
.swiper-slide.green-b.active:after {
    border-bottom: 12px solid #719e19;
}
.swiper-slide.yellow-b.active:after {
    border-bottom: 12px solid #ffce00;
}
.swiper-slide.purple-b.active:after {
    border-bottom: 12px solid #fb193f;
}
.swiper-slide.dark-green-b.active:after {
    border-bottom: 12px solid #616b1e;
}
.swiper-slide.sandy-green-b.active:after {
    border-bottom: 12px solid #567a6a;
}
//end
//iphone specific solution css start
.flowHidden{
    @media (max-width: 767px){
      //overflow: scroll !important;
      height: 100% !important;
      position: fixed !important;
      overflow: auto !important;
      -webkit-overflow-scrolling: auto;
    }

 }
 .config-tile-wrapper .tile-with-expantion-open .config-expantion-wrapper {
     @media (max-width: 767px){
      -webkit-overflow-scrolling: touch !important;
      overflow-y: scroll;
    }
}

.flowHidden{
	.form .floating-label-input>select,
	.form .floating-label-input input,
	.form .floating-label-input textarea,
	.form .floating-label-select>select,
	.form .floating-label-select input,
	.form .floating-label-select textarea {
		display: inline-block !important;
    -webkit-overflow-scrolling: touch !important;
	}
}

//cms mobile menu logo center and quick pay icon placement
a.logolink {
  @media (max-width: @screen-ms-max) {
    width:100%;
  }
}
.sub_menu_mobile .link-black_menu svg.quickpayicon {
    @media (max-width: @screen-ms-max) {
      width: 36px;
      height: 36px;
      margin-left: -10px;
    }
}
.vat-price {
	font-size: 1rem !important;
	margin-top: 5px;
	letter-spacing: 1px;
//	margin-bottom: -15px;
	font-weight: 300;
	font-family: Lato,Roboto,Arial,sans-serif;
	line-height: 1.5;
}
// iphone specific css end

.greenText {

    color:@main-green;
}
//safari vat menu fix
.displayBlock {
    display: block !important;
}
.body-standard.displayBlock.text-right {text-align:right;}

//amend order tracking cart topbar on confirmation
.AmendSuccesMessage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: @form-submitted-line * 3;
  margin: 2.2rem 0 0 0;
  padding: 0;
  text-align: center;
  color: @white;
  background-color: @main-green;
  .submit-ok-icon {
    margin-top: 0.3rem;
  }
}

//callback page CSS
.main-container.acn-trans.product-catalog-body.support_main.support_main_page.contact_us {
  .grid-container-1000 .title-blocks h3 {
      padding: 0 118px;
      @media (max-width: 767px)  {
        padding: 0 15px;
      }
  }
  .inputs {
      padding-right: 118px;
      @media (max-width: 767px)  {
        padding: 0 15px;
      }
      #blockField1 {
          @media (max-width:992px)  {
            margin-bottom: 4.5rem;
          }
      }
  }

  #nameField, #emailField, #numField, #nameFieldFed, #emailFieldFed, #numFieldFed, #subField, #nameFieldBlck, #emailFieldBlck, #phoneField, #blockField1, #urlField {
    @media (max-width: 767px)  {
      margin: 0 0px 10px 0px;
    }
  }
}
//global village alignment
.global-village-timing a {
    color: #fff;
    text-decoration: underline;
    letter-spacing: 1.1px;
    font-size: .875rem;
    padding: 0 2rem;

}
//textarea on focus green border fix on order tracking pages ATG
.form textarea.form-control{
    border: none;
}
#cancel-order-form .consumer-register-form-submit.form-submit.modal-footer.text-center {
  @media (max-width: 767px)  {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
#cancel-order-form .consumer-register-form-submit.form-submit.modal-footer.text-center button.btn.btn-default {
  @media (max-width: 767px)  {
    width: 100%;
    margin: 0 0 1rem 0;
  }
}
.form .floating-label-input textarea, .form .floating-label-select textarea {
    @media (min-width: 1440px){
      padding: 2.0825rem 1.66625rem 0.8325rem;
    }
}
//Event Registration form
.consumer-register-form .form.consumer-events-registration-form .row:first-child>.form-section {
    padding-left: 0;
    padding-right: 0;
}
//contactourhelp
.consumer-register-form .form .row.acPrefix .row>.form-section:first-child {
    padding-right: 0;
}
.consumer-register-form .form .row.acPrefix .row>.form-section:nth-child(2){
    padding-right: 3.75rem;
    padding-left: 1rem;
}
//IE logo alignement fix
.config-tile-wrapper .tile-wrap input[type=radio]:disabled+.tile {
    border: 1px solid #D1D2D1;
}
.config-tile-wrapper .tile-wrap input[type=radio]:disabled+.tile .tile-option {
    border-bottom: none;
}
.visible-xs.visible-xs-L {
  @media (max-width: 767px){
    display:block !Important;
  }
}
.consumer-register-form .form.contactOurHelp .row.acPrefix .row>.form-section:nth-child(2) {
  @media (max-width: 992px){
    padding-right: 0;
    padding-left: 1rem;
  }
  @media (max-width: 767px){
    padding-right: 0;
    padding-left: 0;
  }
}
//bookyournumber payment method additional checkbox fix
.config-section-title-wrapper.container-fluid.error+.tiles-required label {
    color: #f00;
}


//end

//ARABIC
//
[lang=ar] [dir=rtl] {


  .main-text.text-right {
    ul {
      list-style-type: disc;
    }
    h2, p {
    text-align: right !important;
    }
  }
  .col-xs-10.col-xs-offset-1.text-and-table-F {
      @media (max-width: 767px){
            overflow-x: auto; // for arabic blank space fix on mobile
      }
  }
  .product-grid-single .card__back .backside-list {
      ul {
         padding-right: 1.625rem;
         padding-left: 0;
      }
  }
  //arabic email font size reduced to ajdust in column
   .top-nav .top-nav-language a {
      font-size: 0.8rem;
  }
  .top-nav-notification a {
    margin-right: 8px;
    margin-left: 0;
  }

  .detail-info-wrap-pricetag .main-part .price-specs {
      font-family: Neotech-medium,GE SS Two,Arial,sans-serif;
      font-weight: 300;
      letter-spacing: 0!important;
  }

  .plans-table.plans-table-A .tile-table .tile .tile-card__back .tiles-box .back-text ul {
      text-align: right;
  }
.plans-table.plans-table-A .tile-table .tile .tiles-box .tiles-box-line h6 .tiles-box-number[dir=ltr] {
    font-family: Neotech-medium,GE SS Two,Arial,sans-serif;
    font-weight: 300;
}

.tile-wrap .tile-specs-price-from {
  left: 0;
  right: 1rem;
}
  .config-expantion-wrapper.config-expantion-text-and-form .buttons-and-price .btn {
    @media (max-width: 430px){
      margin-right: 0;
    }
  }
    .sub_menu_mobile .link-black_menu svg.quickpayicon {
      @media (max-width: @screen-ms-max) {
        margin-left: 0;
        margin-right: -10px;
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
      }

    }
    //mobile arabic color tiles carousel fixed
    .arMobCarosel {
      @media (max-width: @screen-sm-min ) {
        direction:ltr;
        text-align: right;
        .tile-wrap .tile-specs-price-from {
            left: auto;
            right: 1rem;
          }
      }

    }
    .vat-price {
        font-family: Neotech-medium,GE SS Two,Arial,sans-serif; letter-spacing: 0!important;font-size: 0.8rem!important;
    }
    .top-nav-main-row .col-sm-ar-offset-1.col-sm-ar-offset-right-0 {
        margin-right: 0;
        margin-left: 8.33333333%;
    }
  .table-header .row .col-xs-ar-offset-right-1, .row .col-xs-ar-offset-right-1 {
      margin-right: 8.33333333%;
  }
  .displayBlock-ar {
      display: block !important;
  }
  //amend order tracking cart topbar on confirmation
  .AmendSuccesMessage {
    .submit-ok-icon {
      margin-right: 0rem;
      margin-left: 1rem;
    }
  }

  // order status

.table-header .body-standard.displayBlock.text-right {
    text-align: left;
}
.config-accordion-wrapper .config-accordion--verC.manage-order .text-align-L.text-align-RM {
    text-align: left;
    @media (max-width: 767px)  {
      text-align: right;
    }
}
.config-accordion-wrapper .config-accordion--verC.manage-order .col-sm-ar-offset-right-1 {
    margin-left: 8.33333333%;
    @media (max-width: 767px)  {
      margin-left: 0;
    }
}
.config-accordion-wrapper .config-accordion--verC.manage-order .text-status.text-left.pull-right-ar {
    //text-align: left;
    @media (max-width: 767px)  {
      text-align: left;
    }
}

.config-accordion-wrapper .config-accordion--verC.manage-order .col-xs-ar-offset-1.col-xs-ar-offset-right-0 {
    @media (max-width: 767px)  {
      margin-left: 8.33333333%;
      margin-right: 0;
      text-align: left;
    }
}
.config-accordion-wrapper .config-accordion--verC.manage-order {
    .thirdCol {
      @media (max-width: 767px)  {
        margin-left:0;
        margin-right:  8.33333333%;
      }
    }

    .fourthCol {
      @media (min-width: 768px)  {
        margin-left: 8.33333333%;
        margin-right: 0;
      }
      @media (max-width: 767px)  {
        text-align: left;
      }
    }
}

//callback page arabic fix
#nameField, #emailField, #numField, #nameFieldFed, #emailFieldFed, #numFieldFed, #subField, #nameFieldBlck, #emailFieldBlck, #phoneField, #blockField1, #urlField {
    margin: 0 0px 10px 20px;
}
//Event Registration form
.consumer-register-form .form.consumer-events-registration-form .row:first-child>.form-section {
    padding-left: 0;
    padding-right: 0;
}
.consumer-register-form .form .row.acPrefix .row>.form-section:nth-child(2){
    padding-right: 1rem;
    padding-left: 3.75rem;
    @media (max-width: 767px)  {
      padding-right: 0;
      padding-left: 0;
    }
}

.consumer-register-form .form .row.acPrefix .row>.form-section:first-child {
    padding-left: 0;
}

.consumer-register-form .form .row.acPrefix .row>.form-section:nth-child(2) {
    padding-right: 1rem;
    padding-left: 3.75rem;
    @media (max-width: 767px)  {
      padding-right: 0;
      padding-left: 0;
    }
}
.cross-nav-wrapper .cross-nav-module--widthC:first-child {
    margin-left: 5%;
    margin-right: 0;
}
  //end
}
