
/* ==========================================================================
   $BASE-PICKER
   ========================================================================== */

@import "_variables.less";


/**
 * Note: the root picker element should *NOT* be styled more than what’s here.
 */
.picker {

    // The base font stylings.
    font-size: @base-font-size;
    text-align: left;
    line-height: @base-line-height;
    color: @black;

    // The picker shouldn’t affect or be affected by elements around it.
    position: absolute;
    z-index: @picker-z-index;

    // The picker shouldn’t be selectable.
    user-select: none;
}


/**
 * The picker input element.
 */
.picker__input {
    cursor: default;
    background: transparent;
    z-index: 2;
}


/**
 * When the picker is opened, the input element is “activated”.
 */
.picker__input.picker__input--active {
    border-color: @input-active-border;
}


/**
 * The holder is the only “scrollable” top-level container element.
 */
.picker__holder {
    width: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}



