
.config-upselling-noBg {
  background: transparent;
  margin-top: 0px;
    div.cover {
      position: relative;
    }
    .row.marginBottom {
      margin-bottom: 35px;
    }
    .detail-info-wrap-pricetag {
      p {
        font-size: 1.15rem;
      }
    }
    .swiper-slide-action {
      background: @white;
    }
    .nav .dropdown  {
      padding-top: 0;
    }
    .nav .dropdown.open a  {
      background-color: @sand-white-50;
    }
    .dropdown-toggle > svg {
      width: 28px;
      height: 28px;
      padding-top: 10px;
    }
    p.templateTitle .btn {
      width: 100%;
      margin-bottom: 0;
      margin-top: 1rem;
    }

  .nav {
    .dropdown {
      ul.dropdown-menu {
        padding: 0px;
        border: 0;
        box-shadow: -1px 12px 10px rgba(0,0,0,.175);
          li {
            padding-top: 0;
            a {
              padding: 10px 20px;
              svg {
                width: 18px;
                height: 18px;
                margin-right: 10px;
              }
            }
          }
        }
      }
  }

}

.config-upselling-noBg .tile-table input[type=radio]:checked + .tile .card__front {
  border-color: @main-green;
}

.config-upselling-noBg .tile-table .tile .tile-tick {
  margin-top: 0.85rem;
}

.config-upselling-noBg .tile-table .tile .lowPadding.card__front {
  padding-bottom: 4.5rem;
  }
