.bg-sand-white{
  background-color: @sand-white;
}
.swiper-carousel-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 2;
  position: relative;
  text-align: center;
}

.swiper-carousel-wrapper {
  position: relative;

  .swiper-wrapper {
    filter: blur(0);
  }

  .carousel-swiper-button-next,
  .carousel-swiper-button-prev {
    display: none;
    width: 1.125rem;
    height: 2.25rem;
    position: absolute;
    bottom: 50%;
    margin-bottom: -1.125rem;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    z-index: 2;
    svg {
      width: 100%;
      height: auto;
      stroke: @dark-gray;
      stroke-width: 2px;
    }
  }

  .carousel-swiper-button-next {
    right: 3%;
  }
  .carousel-swiper-button-prev {
    left: 3%;

  }
}

.carousel-load-more {
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.carousel-wrapper {
  .swiper-slide {
    width: 64%;
  }
  .nav-pills.tab-labels {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-family: @font-family-menu-sans-serif;
    font-weight: 600;
    a {
      padding-left: 1rem;
      padding-right: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;
      color: @warm-gray;
    }
    .active a:hover,
    .active a:focus,
    .active a {
      color: @custom-gray;
      background-color: transparent;
      &:after {
        content: " ";
        background-color: @main-green;
        width: 100%;
        height: 0.25rem;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
}

@media (min-width: @screen-ms-min) {
  .carousel-wrapper{
    h3{
      font-size: 2.5rem;
    }
    .swiper-slide{
      width: 50%;
    }

    .swiper-carousel-wrapper {
      .carousel-swiper-button-next,
      .carousel-swiper-button-prev {
        display: flex;
      }
    }

    .carousel-load-more{
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

  }

  .swiper-carousel-container {

    .product-grid-single {
      div[class*=col-] > .rect-responsive {
        padding-bottom: 163%;
        .transition();
      }
      .product-info {
        padding-top: 1rem;
      }
      .detail-info-wrap-pricetag {
        padding-top: 1.25rem;
        padding-bottom: 0;
      }
      .product-image {
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
      }
      &.product-grid-single-C{
        .product-info {
          padding-top: 1.2rem;
          padding-bottom: 1.4rem;
        }
        .product-image {
          width: 90%;
          margin-left: 5%;
          margin-right: 5%;
        }
        div[class*=col-] > .rect-responsive {
          //padding-bottom: 87%;
          padding-bottom: 0;
        }
        .detail-info-wrap-pricetag{
          padding-bottom: 0;
        }
      }
    }

  }
}


@media (max-width: @screen-ms-min) {
  .swiper-carousel-container {

    .product-grid-single {
      div[class*=col-] > .rect-responsive {
        padding-bottom: 163%;
        margin-top: 20%;
        .transition();
      }
      .product-info {
        padding-top: 1rem;
      }
      .detail-info-wrap-pricetag{
        padding-top: 1.25rem;
        padding-bottom: 0;
      }
      .product-image{
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
      }

      &.product-grid-single-C{
        div[class*=col-] > .rect-responsive {
          //padding-bottom: 90%;
          padding-bottom: 0%;
          margin-top: 10%;
          .transition();
        }
        .detail-info-wrap-pricetag{
          padding-bottom: 0;
        }
      }
    }

    .swiper-slide-active{
      .product-grid-single {
        div[class*=col-] > .rect-responsive {
          margin-top: 0%;
        }
        .product-image{
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}



@media (min-width: @screen-sm-min) {
  .swiper-carousel-container {
    .product-grid-single {
      div[class*=col-] > .rect-responsive {
        padding-bottom: 122%;
      }
      .product-info {
        padding-bottom: 1.2rem;
      }
      .detail-info-wrap-pricetag {
        padding-top: 2.5rem;
        padding-bottom: 1.2rem;
      }
      .product-image {
        width: 78%;
        margin-left: 12%;
        margin-right: 12%;
      }

      &.product-grid-single-C{
        .product-info {
          padding-top: 1.3rem;
          padding-bottom: 1.2rem;
          h5{
            line-height: 1.5rem;
          }
        }
        .product-image {
          width: 80%;
          margin-left: 10%;
          margin-right: 10%;
        }
        .product-tooltip{
          font-size: .875rem;
        }
      }

    }
  }
}

@media (min-width: @screen-md-min) {
  .carousel-wrapper{
    h3{
      font-size: 2.5rem;
    }
    .swiper-slide{
      width: 33.33%;
    }
  }

  .swiper-carousel-container {

    .product-grid-single {
      .product-image{
        .transition();
      }
      .product-info {
        padding-top: 7.2%;
        padding-bottom: 20%;
        .transition();
        h5 {
          .transition();
        }
      }
      div[class*=col-]>.rect-responsive{
        .transition();
        padding-bottom: 111%;
      }
      .detail-info-wrap-pricetag{
        padding-top: 5%;
        padding-bottom: 0.375rem;
        .transition();
      }

      &.product-grid-single-C{
        .product-info {
          padding-bottom: 15%;
        }
        .product-image {
          width: 70%;
          margin-left: 15%;
          margin-right: 15%;
        }
        div[class*=col-]>.rect-responsive{
          //padding-bottom: 87%;
          padding-bottom: 0%;
        }
        .detail-info-wrap-pricetag{
          padding-top: 15%;
        }
      }
    }

    .swiper-slide-active .product-grid-single {
      .product-image{
        margin-left: 6%;
        margin-right: 6%;
        width: 88%;
      }
      div[class*=col-]>.rect-responsive{
        padding-bottom: 125%;
      }
      .product-info {
        padding-top: 17%;
        padding-bottom: 8.2%;
        h5 {
          margin-top: -9.4%;
        }
      }
      .detail-info-wrap-pricetag{
        padding-top: 9%;
      }

      &.product-grid-single-C{
        .product-info {
          padding-bottom: 8.2%;
        }
        .product-image {
          width: 88%;
          margin-left: 6%;
          margin-right: 6%;
        }
        div[class*=col-]>.rect-responsive{
          //padding-bottom: 87%;
          padding-bottom: 0%;
        }
        .detail-info-wrap-pricetag{
          padding-top: 9%;
        }
      }
    }

  }

  .carousel-load-more{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

}


@media (min-width: @screen-lg-min) {
  .carousel-wrapper{
    h3{
      font-size: 3.375rem;
      margin-top: 5rem;
    }
    .swiper-slide{
      width: 33.33%;
    }
    .carousel-swiper-button-next,
    .carousel-swiper-button-prev {
      width: 2.75rem;
      height: 5.43rem;
      margin-bottom: -2.2rem;
    }
  }

  .swiper-carousel-container {

    .product-info {
      padding-bottom: 25%;
    }

    .product-grid-single {
      .product-image{
        margin-left: 14%;
        margin-right: 14%;
        width: 72%;
      }
      div[class*=col-] > .rect-responsive {
        padding-bottom: 120%;
      }
      .detail-info-wrap-pricetag{
        padding-top: 9%;
        padding-bottom: 0;
      }

      &.product-grid-single-C{
        .product-info {
          padding-top: 5.5%;
          padding-bottom: 12.8%;
          h5{
            line-height: 2rem;
          }
        }
        div[class*=col-] > .rect-responsive {
          padding-bottom: 0%;
        }
      }
    }

    .swiper-slide-active .product-grid-single {
      .product-image{
        margin-left: 7%;
        margin-right: 7%;
        width: 86%;
      }
      div[class*=col-] > .rect-responsive {
        padding-bottom: 120%;
      }
      .detail-info-wrap-pricetag{
        padding-top: 11.9%;
        margin-top: 1.4%;
      }
      &.product-grid-single-C{
        .product-info {
          padding-top: 15%;
          padding-bottom: 6.5%;
        }
        div[class*=col-] > .rect-responsive {
          padding-bottom: 0%;
        }
      }
    }

  }
  .carousel-wrapper {
    .carousel-load-more {
      padding-top: 4.4rem;
      padding-bottom: 4rem;
    }
  }
}
