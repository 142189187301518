
    .callback-gray {
      background: #363739;
    }
    .callback-green {
      background: #719e19;
    }
    .callback-singleButton {
      position: fixed;
      top: inherit;
      right: 20px;
      bottom: 20px;
      padding: 0;
      height: auto;
      width: 56px;
      z-index: 7;
      font-family: Neotech,Roboto,Arial,sans-serif;
      box-shadow: none;
      background-color: transparent;
      a {
        overflow: hidden;
        display: block;
        height: 56px;
        box-shadow: 0 0 5px 0 rgba(0,0,0,.5);
        margin-bottom: 10px;
        background-color: #719e19;
        padding: 5px;
        width: 56px;
        svg {
          width:18px;
          height: 18px;
        }
      };
      .allback-singleButton-icon {
        width: 18px;
        height: 18px;
        overflow: hidden;
        margin: auto;
      };
      .allback-singleButton-title {
        font-size: 10px;
        text-align: center;
        width: 46px;
        line-height: 12px;
        margin: auto;
        margin-top: 4px;
        color: #fff;
      }
      
    }


    /* Mobile Style */
    .whiteBg {
      background: rgba(255, 255, 255, 0.8);
      width: 100%;
      .callback-openBtn {
        background: rgba(127, 166, 47, 1) !important;
      }
      .callback-options {
        display: block !important;
      }
    }
    .callback-box {
      position: fixed;
      top:inherit;
      right: 0;
      bottom: 0;
      padding: 25px;
      z-index: 7;
      font-family: Neotech,Roboto,Arial,sans-serif;

      .callback-openBtn {
        width: 64px;
        height: 56px;
        float: right;
        clear: both;
        background: #7fa62f;
        cursor: pointer;
        background: rgba(127, 166, 47, 0.8);
        color: #fff;
        font-weight: bold;
        line-height: 16px;
        text-align: center;
        padding: 11px;
        box-sizing: border-box;
        font-size: 14px;

        .callback-openBtn-arrow {
          display: none;
        }
      }
      .callback-openBtn:hover {
        background: rgba(127, 166, 47, 1);
      }
      .callback-options {
        display: none;
        overflow: hidden;
        clear: both;
        a {
          display: block;
          overflow: hidden;
          margin-bottom: 5px;
          padding: 0px;
          border-top:0px solid rgba(110 ,145, 43, 1);
          .callback-options-icon {
            float:right;
            width: 64px;
            height: 56px;
            background: rgba(127, 166, 47, 1);
            text-align: center;
            line-height: 56px;
            margin: inherit;

            svg {
              width:32px;
              height: 32px;
              margin-top: 12px;
            }
          }
          .callback-options-icon:hover {
            background: rgba(110 ,145, 43, 1);
          }
          .callback-options-title {
            float: right;
            text-align: right;
            margin-right: 18px;
            line-height: 55px;
            font-size: 14px;
            color: #7fa62f;
            font-weight: 600;
            letter-spacing: .2px;
            margin-top: 0px;
          }
        }
      }
    }

    /* Table Style */
    @media (min-width: @screen-sm-min) {

      .callback-singleButton {
        position: fixed;
        top: inherit;
        right: 20px;
        bottom: 20px;
        padding: 0;
        height: auto;
        width: 72px;
        z-index: 7;
        font-family: Neotech,Roboto,Arial,sans-serif;
        box-shadow: none;
        background-color: transparent;
        a {
          overflow: hidden;
          display: block;
          height: 80px;
          box-shadow: 0 0 5px 0 rgba(0,0,0,.5);
          margin-bottom: 10px;
          background: #719e19;
          padding: 10px;
          width: 72px;

          svg {
            width:26px;
            height: 26px;
          };
        };
        .allback-singleButton-icon {
          width: 26px;
          height: 26px;
          overflow: hidden;
          margin: auto;
        };
        .allback-singleButton-title {
          font-size: 12px;
          text-align: center;
          width: 50px;
          line-height: 15px;
          margin: auto;
          margin-top: 6px;
          color:#fff;
        }
        &.callback-help-me-decide{
          a{
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .allback-singleButton-title {
              margin-top: 0;
              margin: auto;
          }
        }
      }


      .whiteBg {
        width: inherit;
        background: rgba(255, 255, 255, 0);
        .callback-openBtn {
          .callback-openBtn-arrow {
            svg {
              transform: rotate(180deg) !important;
            }
          }
        }

      }
      .callback-box {
        padding: 0;
        bottom: inherit;
        top: 40%;

        .callback-openBtn {
          width: 112px;
          height: 86px;
          font-size: 18px;
          line-height: 20px;
          padding: 15px;
          cursor: pointer;
          background: rgba(127, 166, 47, 1);

          .callback-openBtn-arrow {
            display: block;

            svg {
              text-align: center;
              width: 20px;
              height: 20px;
              margin-top: 3px;
              transform: rotate(0deg);
            }
          }
        }
        .callback-options  {
          position: absolute;
          top: 86px;

          a {
            width: 112px;
            height: 86px;
            cursor: pointer;
            background: rgba(127, 166, 47, 0.8);
            margin-bottom: 0px;
            padding: 8px;
            box-sizing: border-box;
            border-top:1px solid rgba(127,166,47,.4);

            .callback-options-icon {
              float:inherit;
              width: 32px;
              height: 32px;
              background: none;
              text-align: center;
              line-height: 56px;
              margin: auto;
              svg {
                width:32px;
                height: 32px;
                margin-top: 0px;
              }
            }
            .callback-options-icon:hover {
              background: rgba(110 ,145, 43, 0);
            }
            .callback-options-title {
              float: inherit;
              text-align: center;
              margin-right: 0px;
              line-height: 1.2;
              font-size: 14px;
              color: #fff;
              font-weight: lighter;
              letter-spacing: .2px;
              margin-top: 7px;
            }
          }
          a:hover {
              background: rgba(110 ,145, 43, 1);;
          }
        }
      }

    }





/*
 * ARABIC
 */
*[lang="ar"] {
  *[dir="rtl"] {

    .callback-singleButton {
      position: fixed;
      top: inherit;
      left: 20px;
      right: inherit;
      bottom: 20px;
      padding: 5px;
      height: 56px;
      width: 56px;
      z-index: 7;
      font-family: Neotech,Roboto,Arial,sans-serif;
      //box-shadow:0 0 5px 0 rgba(0,0,0,0.5);
      .allback-singleButton-title {
        width: 35px;
      }
    }

    .callback-box {
      right: inherit;
      left: 0;
      font-family: Neotech-medium,GE SS Two,Arial,sans-serif;
      .callback-openBtn {
        float: left;
      }
      .callback-options {
        a {
          .callback-options-icon {
            float: left;
          }
          .callback-options-title {
            float: left;
            margin-right: 0px;
            margin-left: 18px;
            text-align: left;
          }
        }
      }
    }
    @media (min-width: @screen-sm-min) {

      .callback-singleButton {
        position: fixed;
        top: inherit;
        left: 20px;
        right: inherit;
        bottom: 20px;
        z-index: 7;
        font-family: Neotech,Roboto,Arial,sans-serif;
        height: auto;
        width: 72px;
        padding: 10px;

      }
      .callback-box {
        .callback-options {
          a {
            .callback-options-icon {
              float: inherit;
            }
            .callback-options-title {
                float:inherit;
                margin-left: 0px;
                margin-right: 0px;
                text-align: center;
            }
          }
        }
      }
    }
  }
}
