#filters.tab-closed {
  height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.filters {
  padding-top: 2.875rem;
  .filters-selected {
    display: none;
  }
  .text-label {
    padding-top: 0.125rem;
  }
  &.filters-collapsed {
    .filters-form {
      display: none;
    }
    .filters-selected {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: auto;
      @media (min-width: @screen-sm-min) and (max-width: @screen-md-min) {
        width: auto;
      }
      @media (min-width: @screen-sm-min) {
        justify-content: flex-start;
      }
      .filter-item {
        background: @main-green;
        padding: 0.375rem 1rem 0.5rem;
        border-radius: 2rem;
        color: #fff;
        font-family: Neotech,Roboto,Arial,sans-serif;
        margin-right: 1rem;
        margin-bottom: 1rem;
        font-size: 0.875rem;
        svg {
          stroke: #FFF;
          width: 13px;
          height: 12px;
          margin-left: 1rem;
        }
      }
    }
    .controls-buttons {
      //padding-top: 2rem;
      .btn-accordion {
        padding-right: 2.8125rem;
        svg {
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          use {
            -moz-transform: rotate(-90deg);
            -moz-transform-origin: center center;
          }
        }
      }
    }
  }
  label {
    display: block;
    margin: 0.625rem auto 1.75rem;
    padding: 0;
    height: 2.5rem;
    font-family: Neotech,Roboto,Arial,sans-serif;
    font-weight: normal;
    text-align: left;
    .filter-calendar {
      position: relative;
      input {
        border-width: 0 0 2px 0;
        border-color: transparent transparent @main-green;
        color: @main-green;
        width: 100% !important;
        min-width: 100%;
        background-color: transparent;
      }
      .inner-icon {
        height: 1.5rem;
        top: 2px;
        right: 1px;
        width: 1.5rem;
        position: absolute;
        svg {
          fill:@main-green;
          width: 90%;
          height: 90%;
        }
      }
    }
    .select2 {
      background-color: transparent;
      box-sizing: content-box;
      margin: 0;
      position: relative;
      top: 0;
      text-align: left;
      float: right;
      width: 100% !important;
      min-width: 100%;
      .select2-selection__arrow {
        height: 3rem;
        top: 0;
        right: 1rem;
        width: 0;
        b {
          border-color: @main-green transparent transparent;
          border-width: 0.375rem 0.375rem 0;
          border-style: solid;
          height: 0;
          left: 50%;
          position: absolute;
          top: 50%;
          width: 0;
        }
      }
      &.select2-container--open {
        .select2-selection__arrow {
          height: 3rem;
          top: 0;
          right: 1rem;
          width: 0;
          b {
            border-color: transparent transparent @main-green;
            border-width: 0 0.375rem 0.375rem;
            border-style: solid;
            height: 0;
            left: 50%;
            margin-left: -4px;
            //margin-top: -2px;
            position: absolute;
            top: 50%;
            width: 0;
          }
        }
      }
      .select2-results__option {
        font-size: 1rem;
      }
    }
    .select2-container--default {
      .select2-selection--single {
        border: 0;
        border-radius: 0;
        outline: none;
        .select2-selection__rendered {
          color: @main-green;
          font-family: Neotech,Roboto,Arial,sans-serif;
          letter-spacing: 0.125rem;
          font-weight: 400;
          background-color: transparent;
          padding: 0;
          border: 0;
          padding-right: 1.7rem;
        }
        &::after {
          content: '';
          background-color: @main-green;
          width: 100%;
          height: 0.125rem;
          position: absolute;
          left: 0;
          bottom: -.125rem;
        }
      }
    }
  }
  #ex1Slider,
  #ex2Slider {
    float: right;
    width: 100%;
    margin-top: 1.875rem;
    .slider-track {
      height: 2px;
      background: @main-green;
      width: 100%;
      .slider-handle {
        background: @main-green;
        width: 20px;
        height: 20px;
        margin-top: -8.25px;
        margin-left: 0;
        position: relative;
        &:after {
          content: '';
          border-color: transparent transparent @sand-white;
          border-width: 0 0.375rem 0.375rem;
          border-style: solid;
          height: 0;
          left: 50%;
          margin-left: -6px;
          margin-top: -4px;
          position: absolute;
          top: 50%;
          width: 0;
        }
      }
    }
    .slider-selection {
      background: @main-green;
      height: 2px;
    }
    .tooltip {
      position: absolute;
      margin-top: -39px;
      .tooltip-inner {
        top: 0;
        left: 0;
        position: absolute;
        font-size: 0.8rem;
        font-family: @font-family-sans-serif;
      }
    }
  }
  .btn-accordion {
    border: 0;
    &:hover {
      background: transparent;
    }
    svg {
      height: 11px;
      width: 7px;
      margin-left: 23px;
      -webkit-transform-origin: center center;
      transform-origin: center center;
      overflow: visible;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      use {
        -moz-transform: rotate(90deg);
        -moz-transform-origin: center center;
      }
    }
  }
  .filter-controls {
    padding-top: 1.875rem;
    padding-bottom: 1.8125rem;
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  }
  .controls-buttons {
    display: flex;
    flex-direction: column;
    .close-btn-wrap {
      order: 2;
    }
  }
  .btn.btn-default {
    width: 100%;
    &:hover {
      color: @custom-gray;
    }
    &.clear-all-filters:hover {
      color: #FFF;
    }
  }
  .btn-accordion {
    padding-top: 1rem;
  }
  .select2-selection--single {
    height: 2rem;
  }
  .select2-selection__rendered {
    height: 2rem;
  }
  .item-count {
    label {
      text-align: center;
      .green-text{
        color: @main-green;
      }
    }
  }
  &.filters-C {
    padding-top: 0.2rem;
    text-align: center;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
    .filters-header {
      order: 0;
      display: none;
      text-transform: uppercase;
      .controls-buttons {
        margin-top: 0.8rem;
        .btn-accordion {
          padding-top: 0;
          padding-right: 2rem;
          background-color: transparent;
        }
        .close-btn-wrap {
          order: 0;
          display: none;
        }
      }
    }
    .filters-form {
      padding-top: 2rem;
    }
    &.filters-collapsed {
      &.filters-C .filters-header {
        display: block;
        .close-btn-wrap {
          display: block;
        }
      }
      .filter-controls {
        padding-top: 0 !important;
        padding-bottom: 1rem !important;
        .close-btn-wrap,
        button,
        label {
          display: none;
        }
      }
    }
  }
}
.wst{
  .filters{
    label{
      .select2-container--default{
        .select2-selection--single{
          .select2-selection__rendered{
            font-size: 1.25rem;
            padding-left: 1rem;
          }
        }
      }
    }
  }
}
@media (min-width: @screen-sm-min) {
  .filters {
    margin-bottom: 3.125rem;
    padding-top: 4rem;
    label {
      height: 3rem;
    }
    .btn.btn-default {
      width: auto;
    }
    .item-count {
      label {
        text-align: left;
      }
    }
    .text-label {
      padding-top: 0.75rem;
      padding-right: 1rem;
      line-height: 1.125rem;
    }
    .btn-accordion {
      border: 0;
      padding-left: 0;
      padding-top: 0;
    }
    .select2-selection--single {
      height: 2rem;
    }
    .select2-selection__rendered {
      height: 2rem;
    }
    .filter-controls {
      padding-top: 3.75rem;
      padding-bottom: 3.75rem;

    }
    .controls-buttons {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      float: right;
      .close-btn-wrap {
        order: 0;
      }
    }
    &.filters-C {
      text-align: inherit;
      margin-bottom: 2.4rem;
      padding-top: 0.96rem;
      .filters-header-wrap {
        display: flex;
        align-items: stretch;
        & > div {
          display: flex;
          align-items: center;
        }
      }
      .filters-header {
        .controls-buttons {
          margin-top: 0;
          .btn-accordion {
            padding-right: 0.75rem;
          }
        }
      }
      .filters-form {
        padding-top: 3rem;
      }
    }
  }
}
@media (min-width: @screen-md-min) {
  .filters {
    margin-bottom: 3.125rem;
    padding-top: 4rem;
    label {
      height: 3rem;
    }
    label {
      font-size: 1rem;
      letter-spacing: 0.0625rem;
      .text-label {
        padding-top: 0.75rem;
        padding-right: 1rem;
      }
    }
    .select2-selection--single {
      height: 2.16rem;
    }
    .select2-selection__rendered {
      font-size: 1rem;
      height: 3rem;
    }
    #ex1Slider,
    #ex2Slider {}
    .close-btn-wrap {
      text-align: left;
    }
    .btn-accordion {
      float: right;
    }
  }
  .wst{
    .filters {
      label {
        .text-label {
          padding-right: 1.5rem;
          font-size: 1.25rem;
          color: @almost-black;
          padding-top: .25rem;
        }
      }
    }
  }
}
@media (min-width: @screen-lg-min) {
  .filters {
    margin-bottom: 0;
    padding-top: 5rem;
    label {
      font-size: 1.25rem;
      letter-spacing: 0.125rem;
      .text-label {
        padding-top: 0.5rem;
      }
    }
    .select2-selection__rendered {
      font-size: 1.25rem;
    }
    #ex1Slider,
    #ex2Slider {}
    .close-btn-wrap {
      text-align: right;
    }
  }
}
/*
 * Business Variant
 */
.business {
  .filters {
    background-color: @sand-white;
    label {
      .filter-calendar {
        input {
          border-color: transparent transparent @dark-green;
          color: @dark-green;
        }
        .inner-icon svg{
          fill: @dark-green;
        }
      }
    }
    .select2-container--default .select2-selection--single {
      background-color: @sand-white;
    }
    label .select2-container--default .select2-selection--single:after {
      background-color: @dark-green;
    }
    label .select2 .select2-selection__arrow b {
      border-color: @dark-green transparent transparent;
    }
    #ex1Slider .slider-track,
    #ex1Slider .slider-track .slider-handle,
    #ex2Slider .slider-track,
    #ex2Slider .slider-track .slider-handle {
      background-color: @dark-green;
    }
    &.filters-collapsed .filters-selected .filter-item {
      background-color: @dark-green;
    }
    label .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: @dark-green;
    }
    .btn-default {
      border-color: @black;
      background: transparent;
      color: @black;
      &:hover {
        color: @white;
      }
    }
    .btn-accordion {
      border: 0;
      background: transparent;
      span {
        color: @black;
      }
      &:hover {
        background: transparent;
        color: @black;
        span {
          color: @black;
        }
      }
    }
  }
}
html[lang="ar"] {
  *[dir="rtl"] {
    .filters {
      label {
        .select2-container--default .select2-selection--single .select2-selection__rendered {
          text-align: right;
          font-family: @font-arabic;
        }
        .text-label {
          float: right;
          text-align: right;
        }
      }
      .controls-buttons {
        float: left;
        svg {
          margin-left: 23px;
          margin-right: 23px;
        }
      }
      .item-count {
        float: right;
        label {
          float: right;
        }
      }
      #ex1Slider,
      #ex2Slider {
        display: flex;
        float: left;
        .slider-track {
          .slider-handle {
            margin-right: 100%;
            margin-left: auto;
            &:after {
              right: 50%;
              left: auto;
              margin-right: -6px;
              margin-left: auto;
            }
          }
        }
        .tooltip {
          .tooltip-inner {
            left: auto;
            right: 0;
            font-family: @font-arabic;
          }
        }
      }
    }
    .filter-controls {}
    .filters.filters-collapsed .filters-selected .filter-item svg {
      margin-right: 1rem;
      margin-left: 0;
    }


    @media (min-width: @screen-sm-min) {
      .filters-form {
        form {
          display: block;
        }
        &.item-count label {
          text-align: right;
        }
      }
      .filters.filters-collapsed .filters-selected {
        float: right;
      }
    }

    @media (min-width: @screen-md-min) {
      .wst{
        &.freedom{
          .main-text{
             .body-big{
                p{
                  text-align: center;
                }
             }
          }
        }
        .filters {
          label {
            .text-label {
              padding-left: 1.5rem;
              padding-right: 0;
              text-align: left;
            }
          }
        }
      }
      .filters-form {
        form {
          display: flex;
        }
      }
    }
    @media (min-width: @screen-lg-min) {
      .filters-form {
        form {
          display: flex;
        }
      }
    }
  }
}
