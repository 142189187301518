/*
============================================
01 - tile-box
02 - sm viewport
03 - md viewport
04 - lg viewport
05 - xl viewport
06 - Business Variant
07 - Arabic Variant -  RTL support

============================================

/* -----------------------------------------
01 - tile-box
----------------------------------------- */
.row-tiles > .container-fluid {
  background-color: @sand-white;
}

.row-tiles.bg-white > .container-fluid {
  background-color: @white;
}

.tiles-box-wrap {
  text-align: center;
  padding: 0 0 2rem;
  overflow: hidden;
  @media (max-width: 767px) {
    padding: 0;
  }

  .swiper-wrapper {
    filter: blur(0);
    height: auto;

    .swiper-slide {
      width: 66.66666667%;
      height: auto;
    }
  }

  div[class*=col-] > .rect-responsive {
    padding-bottom: 103%;
    display: block;
  }

  .tiles-box {
    background-color: @white;
    .transition(0.2s);

    &.content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px;

      a {
        display: flex;
        flex-wrap: wrap;
      }
    }

    &:after {
      content: " ";
      background-color: @main-green;
      width: 100%;
      height: 0.7125rem;
      position: absolute;
      left: 0;
      bottom: 0;
      .transition();
    }

    h4 {
      color: @dark-gray;
      position: relative;
      padding-bottom: 1em;
      text-transform: uppercase;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    p:before {
      content: " ";
      width: 10%;
      height: 1px;
      position: relative;
      display: block;
      top: -14px;
      left: 45%;
      background-color: @main-green;
      .transition(0.2s);
    }

    p {
      color: @steel-grey;
      font-size: 0.875rem;
    }

    &.hover,
    &:hover {
      cursor: pointer;
      background-color: @main-green;

      h4,
      p {
        color: @white;
      }

      p:before {
        background-color: @white;
      }
    }

    h4,
    p {
      //TODO: to be changed with design team
      padding-left: 5%;
      padding-right: 5%;
    }
    &.taphover{
      &.body-standard {
        p {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    &.body-standard {
      p {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .tile-with-bkg {
    div.cover {
      top: 0;

      &:after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: fade(@black, 50);
        .transition();
      }
    }

    .tiles-box {
      background-color: transparent;

      &:after {
        background-color: fade(@main-green, 80);
      }

      h4,
      p {
        color: @white;
      }

      p:before {
        //background-color: @white;
      }
    }

    &:hover {
      div.cover {
        &:after {
          background-color: fade(@main-green, 80);
        }
      }

      .tiles-box {
        &:after {
          background-color: transparent;
        }
      }
    }
    & .noBgHv {
      div {
        &.cover {
          &:after {
              background-color: rgba(0,0,0,0) !important;
          }
        }
      }
      &:hover {
        div {
          &.cover {
            &:after {
                background-color: rgba(0,0,0,0) !important;
            }
          }
        }
      }
      .tiles-box {
          cursor: default ;
          p {
            &:before {
              background-color: @white;
            }
          }
          &:after {
            background-color: transparent;
          }
      }
    }
  }

  .tiles-swiper-scrollbar {
    display: none;
    width: 100%;
    left: 0;
    border-radius: 0;
    background-color: @light-sand-brown;
    height: 0.25rem;
    z-index: 8;
  }

  .swiper-scrollbar-drag {
    border-radius: 0;
  }

  .swiper-pagination-bullets {
    display: block;
    height: 32px;

    .swiper-pagination-bullet {
      margin: 29px 5px 0;
      height: 3px;
      width: 20px;
      border-radius: 0;

      &.swiper-pagination-bullet-active {
        background-color: @dark-gray;
      }
    }
  }

  &.lower-tiles {
    div[class*=col-] > .rect-responsive {
      padding-bottom: 93%;
    }

    .tiles-box {
      h4 {
        text-transform: none;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
      }

      p:before {
        display: none;
      }

      &:after {
        height: 0.4375rem;
      }
    }
  }
  @media (max-width: 767px) {
    .swiper-wrapper{
      padding: 0px 1rem;
      width: auto;
      display: block;
      .swiper-slide{
        width: 100%;
        margin-bottom: 1rem;
        .rect-responsive{
          padding-bottom: 30%;
        }
        .tiles-box{
          &.content{
            justify-content: center;
            align-items: flex-start;
            text-align: left;
            padding: 1rem;
            h4{
              padding-left: 1.5rem;
              padding-right: 1.5rem;
              font-size: 1.5rem;
              padding-bottom: 0px;
              margin-top: 0px;
            }
            p{
              font-size: 1.1rem;
              padding-left: 1.5rem;
              padding-right: 1.5rem;
              margin-left: 0px;
              margin-right: 0px;
              &::before{
                display: none;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 568px) {
    .swiper-wrapper{
      .swiper-slide{
        .rect-responsive{
          padding-bottom: 60%;
        }
      }
    }
  }
  @media (max-width: 320px) {
    .swiper-wrapper{
      .swiper-slide{
        .rect-responsive{
          padding-bottom: 80%;
        }
      }
    }
  }
  & .noBgHv.woimg {
    .tiles-box {
      &.hover, &:hover {
        background-color: @white;
        cursor: default;
        h4, p {
          color: @dark-gray;
        }

      }
      &:after {
        background-color: @white;
      }
    }
  }
}

.row-tiles {
  position: relative;

  .tiles-swiper-button-next,
  .tiles-swiper-button-prev {
    display: none;
    width: 1.125rem;
    height: 2.25rem;
    position: absolute;
    bottom: 50%;
    //margin-bottom: -1.125rem;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    z-index: 2;

    svg {
      width: 100%;
      height: auto;
      stroke: @dark-gray;
      stroke-width: 3px;
    }
  }

  .tiles-swiper-button-next {
    right: 3%;
  }

  .tiles-swiper-button-prev {
    left: 3%;
  }
  &.big-tiles-box-wrapper{
    .big-tiles-img-box{
      img{
        width: 100%;
      }
    }
    .big-tiles-box{
      padding: 5rem 6rem;
      height: 30rem;
      display: block;
      align-items: center;
      text-align: center;
      h3{
        display: block;
        font-size: 1.5rem;
        color: @almost-black;
        margin-bottom: 1.125rem;
      }
      p{
        color: @steel-grey;
        font-size: 1rem;
        line-height: 1.5625rem;
      }
    }
    &.big-tiles-box-wrapper-careers{
      .big-tiles-img-box{
        img{
          height: 300px;
          object-fit: cover;
          @media (max-width: 767px) {
            height: 260px;
          }
        }
      }
      .big-tiles-box{
        padding: 2rem 4rem;
        height: 13rem;
        display: block;
        align-items: center;
        text-align: center;
        h3{
          display: block;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: 3px;
          color: @almost-black;
          margin-bottom: 1.125rem;
          text-transform: uppercase;
        }
        p{
          color: @steel-grey;
          font-size: 14px;
          line-height: 1.5625rem;
          
        }
      }
      @media (max-width: 1024px) {
        .big-tiles-box{
          height: 16rem;
          padding: 2rem;
        }
      }
      @media (max-width: 768px) {
        .big-tiles-box{
          height: 16.5rem;
          p{
            line-height: 20px;
          }
        }
      }
      @media (max-width: 767px) {
        .big-tiles-box{
          height: 10rem;
          padding: 0.5rem 1rem;
        }
      }
      @media (max-width: 475px) {
        .big-tiles-box{
          height: 9.6rem;
        }
      }
      @media (max-width: 320px) {
        .big-tiles-box{
          height: 11rem;
        }
      }
    }
  }
}
/* -----------------------------------------
02 - sm viewport
----------------------------------------- */
@media (min-width: @screen-sm-min) {
  .tiles-box-wrap {
    padding: 0 0 4.3rem;

    div[class*=col-] > .rect-responsive {
      padding-bottom: 114%;
    }

    .tiles-swiper-scrollbar {
      bottom: 2rem;
    }

    .swiper-pagination-bullets {
      display: none;
      height: 50px;

      .swiper-pagination-bullet {
        margin: 47px 5px 0;
        width: 40px;
      }
    }

    &.lower-tiles {
      h4 {
        font-size: 1rem;
        line-height: 1.25rem;
      }

      div[class*=col-] > .rect-responsive {
        padding-bottom: 77%;
      }
    }

    .tiles-box {
      &.content {
        h4 {
          width: 100%;
          font-size: 1.125rem;
        }
      }
    }
  }

  .more-than-three {
    .tiles-swiper-scrollbar {
      display: inherit;
    }

    .swiper-pagination-bullets {
      display: none; // TODO: to be removed
    }

    .tiles-swiper-button-next,
    .tiles-swiper-button-prev {
      display: flex;
    }

    .swiper-button-disabled {
      display: none;
    }
  }
}
/* -----------------------------------------
03 - md viewport
----------------------------------------- */
@media (min-width: @screen-md-min) {
  .tiles-box-wrap {
    div[class*=col-] > .rect-responsive {
      padding-bottom: 103%;
    }

    .tiles-box &:after {
      height: 1rem;
    }

    &.lower-tiles {
      div[class*=col-] > .rect-responsive {
        padding-bottom: 77.5%;
      }

      .tiles-box &:after {
        height: 1rem;
      }
    }

    .tiles-box {
      &.content {
        h4 {
          width: 100%;
          font-size: 1.25rem;
        }
      }
    }
  }
}
/* -----------------------------------------
04 - lg viewport
----------------------------------------- */
@media (min-width: @screen-lg-min) {
  .row-tiles .container-fluid {
    position: relative;
  }

  .tiles-box-wrap {
    padding: 0 0 5.3rem;

    .tiles-swiper-button-next,
    .tiles-swiper-button-prev {
      width: 2.75rem;
      height: 5.43rem;
      margin-bottom: -2.2rem;
    }

    .tiles-swiper-scrollbar {
      bottom: 3.05rem;
    }

    .tiles-box {
      p {
        font-size: 1rem;
      }
    }

    &.lower-tiles {
      h4 {
        font-size: 1.5rem;
        line-height: 1.875rem;
      }
    }

    .tiles-box {
      &.content {
        h4 {
          font-size: 1.5rem;
          width: 100%;
        }
      }
    }
  }
}
/* -----------------------------------------
06 - Business Variant
----------------------------------------- */
.business {
  .row-tiles {
    > .container-fluid {
      background-color: @dark-sand-brown;
    }

    .tiles-swiper-button-next,
    .tiles-swiper-button-prev {
      svg {
        stroke: @light-sand-brown;
      }
    }

    .tiles-box {
      background-color: fade(@dark-gray, 50);

      h4 {
        color: @white;
      }

      p:before {
        background-color: @dark-green;
      }

      p {
        color: @sand-white;
      }

      &:after {
        background-color: fade(@dark-green, 80);
      }

      &.hover,
      &:hover {
        cursor: pointer;
        background-color: @dark-green;

        h4,
        p {
          color: @white;
        }

        p:before {
          background-color: @white;
        }
      }
    }
  }
}
/* -----------------------------------------
07 - Arabic Variant -  RTL support
----------------------------------------- */

html[lang="ar"] {
  *[dir="rtl"] {
    .tiles-box-wrap{
      @media (max-width: 767px) {
        .swiper-wrapper{
          .swiper-slide{
            .tiles-box{
              &.content{
                text-align: right;
              }
            }
          }
        }
      }
    }

    .row-tiles {
      .tiles-swiper-button-next {
        left: 3%;
        right: auto;

        svg {
          transform: scaleX(-1);

          use {
            -moz-transform: scaleX(-1);
          }
        }
      }

      .tiles-swiper-button-prev {
        right: 3%;
        left: auto;

        svg {
          transform: scaleX(-1);

          use {
            -moz-transform: scaleX(-1);
          }
        }
      }
    }
  }
}
