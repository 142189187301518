.testimonials{
  .hero-swiper-button-prev, .hero-swiper-button-next{
    svg{
      stroke: @white;
    }
  }
  &.apple-watch-banner{
    .hero-swiper-button-prev, .hero-swiper-button-next{
      svg{
        stroke: @almost-black;
      }
    }
  }
  &.hero-image{
    min-height: 34.3rem;
  }
  .container, .container-fluid{
    height: 70%;
    .row, .row >div{
      height: 100%;
    }
  }

  .swiper-slide{
    blockquote{
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      //height: 31.25rem;
      height: 100%;
      margin: 0;
      padding: 0;
      border: 0;
      z-index: 6;
      svg{
        margin: 0;
        padding: 0;
      }
      p{
        margin: 0;
        padding: 0;
        max-width: 100%;
      }
      h3{
        margin: 0;
        padding: 0;
      }
      &.quote{
        justify-content: flex-start;
        svg{
          position: absolute;
          top: 0;
          left: -2rem;
          width: 1rem;
          height: 1rem;
        }
        p{
          text-align: left;
          font-size: 1rem;
          line-height: 1.5;
          padding-bottom: 1rem;
        }
        h3{
          position: static;
          transform: none;
          text-align: left;
          align-self: flex-start;
        }
        a.btn{
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 0);
          height: 3rem;
          line-height: 2.75rem;
          margin: 0;
        }
      }
      &.video{
        justify-content: flex-end;
        svg{
          position: absolute;
          width: 6.25rem;
          height: 6.25rem;
          left: 50%;
          top: 50%;
          margin-left: -3.125rem;
          margin-top: -3.125rem;
          //cursor: pointer;
        }
        p{
          position: absolute;
          top: 0;
          left: 0;
          font-size: 1rem;
          line-height: 1.6;
        }
        h3{
          margin-top: 1.75rem;
        }
      }
    }

    .cover{
      z-index: inherit;
      img{
        opacity: 1;
      }
    }

    .video-controls {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9;
        svg{
          width: 6.25rem;
          height: 6.25rem;
        }
    }
  }

  .swiper-pagination-bullets{
    line-height: 15px;
    bottom: 30px;
    .swiper-pagination-bullet{
      width: 15px;
      height: 15px;
      opacity: 1;
      margin: 0 5px;
      background-color: @white;
      &.swiper-pagination-bullet-active{
        background-color: transparent;
        border: solid 2px @white;
      }
    }
  }

}

//media

@media (min-width: @screen-ms-min) {
  .testimonials{
    &.hero-image{
      //min-height: 64vw;
    }
    .swiper-slide{
      blockquote{
        &.quote{
          //justify-content: flex-start;
          svg{
            left: -2rem;
            width: 1rem;
            height: 1rem;
          }
          p{
            //height: auto;
            font-size: 1rem;
            line-height: 1.5;
            padding-bottom: 4rem;
          }
          h3{
            position: static;
            transform: none;
            align-self: flex-start;
          }
        }
        &.video{
          p{
            position: absolute;
            top: 0;
            left: 0;
            font-size: 1rem;
            line-height: 1.6;
          }
        }
      }
    }
  }
}
@media (min-width: @screen-sm-min) {
  .testimonials{
    &.hero-image{
      //min-height: 43.75rem;
    }
    .swiper-slide{
      blockquote{
        &.quote{
          svg{
            left: -2.5rem;
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }
  }
}
@media (min-width: @screen-md-min) {
  .testimonials{
    .swiper-slide{
      blockquote{
        &.quote{
          //justify-content: center;
          svg{
            left: -3.5rem;
            width: 2.25rem;
            height: 2.25rem;
          }
          p{
            //height: 12.5rem;
            font-size: 1.25rem;
            line-height: 1.6;
            padding-bottom: 0;
          }
          h3{
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
          }
        }
        &.video{
          p{
            position: absolute;;
            //font-size: 1.5rem;
            //line-height: 1.3;
          }
        }
      }
    }
  }
}
@media (min-width: @screen-lg-min) {
  .testimonials{
    .swiper-slide{
      blockquote{
        &.video{
          p{
            font-size: 1.5rem;
            line-height: 1.3;
          }
        }
      }
    }
  }
}
/*
 * Arabic
 */

*[lang="ar"] {
  *[dir="rtl"] {
    .testimonials{
      .swiper-slide{
        blockquote{
           &.quote{
              p{
                text-align: right;
              }
              h3{
                text-align: right;
              }
           }
        }
      }
    }


@media (min-width: @screen-ms-min) {
  .testimonials{
    .swiper-slide{
      blockquote{
        &.quote{
          //justify-content: flex-start;
          svg{
            right: -2rem;
          }

        }

      }
    }
  }
}
@media (min-width: @screen-sm-min) {
  .testimonials{
    &.hero-image{
      //min-height: 43.75rem;
    }
    .swiper-slide{
      blockquote{
        &.quote{
          svg{
            right: -2.5rem;
          }
        }
      }
    }
  }
}
    @media (min-width: @screen-md-min) {
  .testimonials{
    .swiper-slide{
      blockquote{
        &.quote{
          //justify-content: center;
          svg{
            right: -3.5rem;
          }

          h3{
            right: 0;
          }
        }
        &.video{
          p{
          }
        }
      }
    }
  }
}
  }
}
