.wst{
	&.apple-watch-products{
		.main-text-apple{
			padding-top: 3.5rem;
			background-color: #FBFBFB !important;
			@media (max-width: 768px) {
				padding-top: 1rem;
				.body-big{
					p{
						color: @steel-grey-lighter;
						font-size: 16px;
						line-height: 28px;
					}
				}
			}
			@media (min-width: 769px) {
				.collapse{
					display: block;
				}
			}
			
			.read-more-desc{
				@media (min-width: 769px) {
					display: none;
					
				}
				display: block;
				margin-bottom: 2rem;
				.expandTab  {
					text-decoration: none;
					display: flex;
					font-size: 12px;
					letter-spacing: 1.6px;
					line-height: 16px;
					color: @almost-black;
					font-family: @font-family-base;
					text-transform: uppercase;
					svg{
						width: 16px;
						transition: transform .3s;
						transform: rotate(-180deg);
						height: 16px;
						position: relative;
						margin-left: 12px;
					}
					&.collapsed{
						svg{
							transform: rotate(0deg);
							transition: transform .3s;
						}
						
					}
					
				}
			}
		}
		.apple-watch-banner{
			@media (max-width: 768px) {
				min-height: 28.3rem;
			}
			@media (max-width: 500px) {
				min-height: 31.3rem;
			}
			.hero-images-ver-F{
				display: flex;
				justify-content: center;
				align-items: flex-start;
				flex-direction: column;
				text-align: left;
				@media (min-width: 769px) {
					max-width: 500px;
				}
				.heading-bigger{
					line-height: 64px;
					margin-bottom: 32px;
				}
				.hero-content{
					line-height: 26px;
				}
				@media (max-width: 768px) {
					justify-content:flex-start;
					.heading-bigger{
						line-height: 36px;
						margin-bottom: 32px;
					}
					.hero-content{
						font-size: 14px;
						color: @steel-grey-pale;
					}
				}
			}
			&.testimonials {
				.swiper-slide{
					.cover{
						img{
						height: 548px;
						object-fit: cover;
						@media (max-width: 768px) {
							height: 300px;
							object-fit: cover;
						}
						}
					}
				}
			}
		}
		.overview-section{
			.default-section-title{
				&.apple-watch-series-title{
					justify-content: center;
				}
			}
			.overview-img {
				img{
					width: 80%;
					margin: auto;
				}
			}
			.overview-wrapper{
				.mobile-features{
					&.collapse{
						height: auto !important;
						min-height: auto !important;
						@media (max-width: 768px) {
							height: 0 !important;
							min-height: 0 !important;
							&.in{
								height: auto !important;
								min-height: auto !important;
							}
						}
					}
					.media-bod{
						@media (max-width: 768px) {
							padding: 0.5rem 0!important;
							.default-title{
								margin-top: 0px;
							}
						}
					}
					.media-right{
						@media (max-width: 768px) {
							display: none;
						}

					}
				}
			}
			.default-section-title{
				h3{
					@media (max-width: 768px) {
						text-transform: unset;
						font-size: 20px;
						line-height: 24px;
						margin-bottom: 0px;
					}
				}
				.expandTab {
					svg{
						@media (max-width: 768px) {
							width: 16px;
						}
						
					}
				}
			}
			.default-sub-title{
				p{
					@media (max-width: 768px) {
						color: @steel-grey-pale;
						font-size: 14px;
						line-height: 20px;
					}
					
				}
			}
			.product-apple-watch-video{
				iframe{
					width: 100%;
					height: 720px;
					@media (max-width: 1024px) {
						height: 420px;
					}
					@media (max-width: 768px) {
						height: 320px;
					}
				}
				
			}
		}
		.benefits-box{
			padding: 52px 0px;
			@media (max-width: 768px) {
				padding: 24px 0px;
				padding-bottom: 0px;
			}
		}
		.benefits-box-apple-watch{
			padding: 23px 0px;
			@media (max-width: 768px) {
				padding: 0px;
			}
			.default-section-title{
				align-items: flex-start;
				flex-direction: column;
				h3{
					font-size: 28px;
					display: block;
					margin-bottom: 0.3rem;
				}
				p{
					color: @steel-grey-lighter;
					font-size: 18px;
					font-family: @font-family-base;
					margin-bottom: 30px;
				}
				@media (max-width: 768px) {
					h3{
						font-size: 22px;
						line-height: 28px;
					}
					p{
						font-size: 14px;
						font-family: @font-family-base;
						line-height: 20px;
						color: @steel-grey-pale;
					}
					
				}
			}
			.bg-cards{
				&.related-products {
					.tile-card{
						.tiles-box-list{
							height: 90px!important;
							padding: 0 1.5rem 1.5rem 1.5rem;
							display: block;
							@media (max-width: 768px) {
								padding-left: 7% !important;
							}
						}
						.tiles-box{
							.product{
								.product-img{
									max-height: 100%;
									height: 230px;
									object-fit: cover;
									width: 200px;
								}
							}
						}
					}
				}
			}
			.tile-table{
				@media (max-width: 768px) {
					padding: 0;
					margin-bottom: 10px;
				}
				.tile-card{
					.product{
						@media (max-width: 768px) {
							height: 400px;
						}
					}
				}
				
			}
		}
		.benefits-section{
			.benefits-mobile{
				line-height: 3;
				.icon{
					margin-right: 26px;
					@media (max-width: 768px) {
						height: 32px;
						width: 32px;
					}
					@media (max-width: 374px) {
						margin-right: 10px;
					}
				}
			}
		.img-with-disc{
			@media (max-width: 768px) {
				margin-bottom: 20px;
			}
			.media{
				a{
					display: flex;
					@media (max-width: 768px) {
						display: block;
					}
				}
				.media-left {
					min-width: 375px;
					padding-right: 0px;
					@media (max-width: 1024px) {
						min-width: 275px;
					}
					@media (max-width: 768px) {
						min-width: auto;
					}
					.media-object{
						max-width: 100%;
						object-fit: cover;
						height: 340px;
						@media (max-width: 1024px) {
							height: 240px;
						}
						@media (max-width: 768px) {
							height: 266px;
						}
					}
				}
				.media-body {
					justify-content: center;
					.discription{
						margin-bottom: 1.5rem;
						p{
							&.default-text{
								margin-bottom: 1rem;
							}
						}
					}
				}
			}
			
			
		}
			.title-wrapper{
				.title{
					h3{
						text-transform: none;
						width: 85%;
					}
				}
			}
			.features-wrapper .features-list li.active>a .icon circle, 
 			.features-wrapper .features-list li.active>a .icon path, 
 			.features-wrapper .features-list li.active>a .icon polygon, 
 			.features-wrapper .features-list li.active>a .icon polyline{
				  stroke-width:1px; 
			 }

			 .benefits-mobile .icon circle, 
			 .benefits-mobile .icon path, 
			 .benefits-mobile .icon polygon, 
			 .benefits-mobile .icon polyline {
				stroke-width: 1px;
			}
		}

 
	}
}
/*
 * Arabic
 */

 *[lang="ar"] {
	*[dir="rtl"] {
		.wst{
			&.apple-watch-products{
				.main-text-apple{
					.body-big{
						text-align: right;
						p{
							text-align: right !important;
						}
					}
				}
				.apple-watch-banner{
					.hero-images-ver-F{
						text-align: right;
						.heading-bigger{
							line-height: 64px;
							margin-bottom: 32px;
							font-size: 3.4375rem;
          					font-family: @headings-font-family;
          					font-weight: 300;
						}
					}
				}
				.tab-content-bit{
					@media (max-width: 768px) {
						width: 100%;
					}
				}
				.main-text-apple {
					.read-more-desc{
						.expandTab{
							svg{
								margin-left: 0px;
								margin-right: 12px;
							}
						}
					}
				}
				.benefits-section {
					.benefits-mobile{
						.view-more{
							&:before{
								left: 3px;
								right: auto;
							}
						}
						.icon{
							margin-right: 0;
							margin-left: 26px;
						}
					}
					.img-with-disc {
						.media-body {
							.fixed-at-bottom{
								margin-right: 0px;
								@media (max-width: 768px) {
									line-height: 44px;
								}
								
							}
						}
					}
					
				}
				.benefits-box-apple-watch{
					.bg-cards{
						&.related-products {
							.tile-card{
								.tiles-box-list{
									padding: 0 1.5rem 1.5rem;
								}
							}
						}
					}
				}
			}
			
		}
	}
}


