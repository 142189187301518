
/*
============================================
01 - xs viewport
01a - .swiper-container
01b - .swiper-pagination-custom
01c - halfscreen
01d - animations
02 - xs viewport only
03 - xs to ms viewport only
04 - ms viewport
05 - sm viewport
06 - md viewport
07 - lg viewport
08 - Arabic overrides
============================================
*/

/* -----------------------------------------
01 - xs viewport
01a - .swiper-container
----------------------------------------- */
.swiper-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .hero-over-title {
    letter-spacing: 0.215em;
  }
  .hero-content {
    font-size: 0.875rem;
    span {
      width: 65%;
      display: block;
      margin: 0 auto;
    }
  }
  .hero-upper-title {
    font-size: 2.25rem;
    font-family: @font-arabic;
    margin-bottom: 0.6875rem;
  }
  .hero-swiper-button-next,
  .hero-swiper-button-prev {
    width: 1rem;
    height: 2rem;
    background: rgba(255, 255, 255, 0);
    margin: 0;
    transform: translate(0, -50%);
    z-index: 2;
    top: 50%;
    position: absolute;
    cursor: pointer;
    svg {
      stroke: @black;
      width: 1rem;
      height: 2rem;
    }
  }
  .hero-swiper-button-next {
    right: 1.25rem;
  }
  .hero-swiper-button-prev {
    left: 1.25rem;
  }
  &.bg-dark {
    .hero-swiper-button-next,
    .hero-swiper-button-prev {
      svg {
        stroke: @white;
      }
    }
  }
  .swiper-slide {
    a{
      cursor: pointer;
    }
    text-align: center;
    font-size: 1.125rem;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    color: @black;
    background-color: @white;
    cursor:initial !important;
    > .cover {
      top: 0;
      left: 0;
      z-index: 1;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      > .cover {
        z-index: 0;
      }
    }
    > .container {
      margin: 0;
      z-index: 3;
    }
    > .container-fluid {
      margin: 0;
      width: 100%;
    }
    &.bg-dark {
      color: @white;
    }
    .section-title {
      position: relative;
      padding-top: 0.5rem;
      padding-bottom: 1.5rem;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.2em;
    }
    .price-tag {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.5rem 1.8rem 1rem;
      background-color: rgba(255, 128, 0, 0.8);
      .detail-info-wrap-pricetag > div {
        color: @white;
      }
    }
    h2 {
      .heading-bigger;
      font-size: 2.1875rem;
      margin-top: 0;
    }
    h2:first-child {
      margin-top: 0;
    }
    h2:last-child {
      margin-bottom: 1.625rem;
    }
    p {
      position: relative;
    }
    a.btn {
      margin-top: 1.875rem;
    }
    .bg-filter{
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      top: 0;
      left: 0;
      background-color: transparent;
      opacity: 0;
    }
  }
}


.swiper-pagination-custom {
  position: absolute;
  bottom: 1.6rem;
  width: 100%;
  color: @white;
  z-index: 100;
  text-align: center;
  letter-spacing: 0.2383333333em;
  font-weight: 400;
  .pagination-wrapper {
    width: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
  }
  a {
    flex-grow: 1;
    border-top: 1px solid @black;
    color: @black;
    text-transform: uppercase;
    padding-top: 0.75rem;
    margin-left: 0.3125rem;
    margin-right: 0.3125rem;
    position: relative;
    animation-duration: 5.5s;
    display: block;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &.active::after,
    &.activedNo::after {
      position: absolute;
      top: -0.25rem;
      left: 0;
      height: 0.1875rem;
      content: " ";
      background-color: @black;
      width: 0;
    }
    &.activedNo::after {
      width: 100%;
    }
    &.active::after {
      -webkit-animation: slideProgress;
      -webkit-animation-fill-mode: forwards;
      -moz-animation: slideProgress;
      -moz-animation-fill-mode: forwards;
      -o-animation: slideProgress;
      -o-animation-fill-mode: forwards;
      animation: slideProgress;
      animation-fill-mode: forwards;
      animation-duration: inherit;
      -webkit-animation-duration: inherit;
    }
  }
  &.bg-dark {
    color: @white;
    a {
      color: @white;
      border-color: @white;
      &.active::after,
      &.activedNo::after {
        background-color: @white;
      }
    }
  }
}

/* -----------------------------------------
01c - halfscreen
----------------------------------------- */
.halfscreen {
  .swiper-pagination-custom {
    display: none;
  }
  &.swiper-container .swiper-slide h2:last-child {
    margin-bottom: 0;
  }
  &.swiper-container .swiper-slide a.btn {
    margin-bottom: 0;
  }
}
//for samsung s10
@media (min-width: 992px) and (max-width: 1440px) {
  .samsung{
        height: 351px !important;
        min-height: 351px !important;
    }
}
@media  (max-width: 414px) {
  .samsung{
        img{
          &.cover{
            width: 100%;
          }
        }
    }
}

/* -----------------------------------------
01d - animations
----------------------------------------- */
@-webkit-keyframes slideProgress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@-moz-keyframes slideProgress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@-o-keyframes slideProgress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes slideProgress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}


/* -----------------------------------------
02 - xs viewport only
----------------------------------------- */
@media (max-width: @screen-ms-min) {
  .halfscreen {
    .price-tag + div,
    .price-tag + p {
      margin-top: 9rem;
      padding-bottom: 0;
    }
  }
  .swiper-container {
    .swiper-slide {
      .section-title {
        margin-top: 4rem;
      }
    }
  }
}

/* -----------------------------------------
03 - xs to ms viewport only
----------------------------------------- */
@media (max-width: @screen-sm-min) {
  .swiper-container {
    .swiper-slide {
      .price-tag {
        margin-right: 0;
      }
    }
    .hero-upper-title {
      font-size: 1.25rem;
    }
    .hero-swiper-button-next,
    .hero-swiper-button-prev {
      svg {
        stroke-width: 0.25rem;
      }
    }
  }
}

/* -----------------------------------------
04 - ms viewport
----------------------------------------- */
@media (min-width: @screen-ms-min) {
  .swiper-container {
    .swiper-slide {
      h2:last-child {
        margin-bottom: 3rem;
      }
      a.btn {
        margin-top: 0;
        margin-bottom: 1.875rem;
      }
    }
  }
  .halfscreen {
    .swiper-slide {
      a.btn {
        margin-top: 1rem;
      }
    }
  }
}


/* -----------------------------------------
05 - sm viewport
----------------------------------------- */
@media (min-width: @screen-sm-min) {
  .swiper-pagination-custom {
    bottom: 2.7rem;
    .pagination-wrapper {
      font-size: 0.75rem;
    }
  }
  .halfscreen .swiper-pagination-custom {
    display: none;
  }
  .swiper-container {
    .swiper-slide {
      h2 {
        font-size: 3.4375rem;
        font-family: Neotech-medium,GE SS Two,Arial,sans-serif;
        font-weight: 300;
        letter-spacing: 0!important;
        &.promo-heading {
              margin-top: 3rem;
          }
      }
      h2:last-child {
        margin-bottom: 5.8rem;
      }
      .section-title {
        position: absolute;
        padding-top: 0;
        top: 3.125rem;
      }
      .price-tag {
        padding: 1rem 2rem 1.5rem;
      }
      a.btn {
        margin-bottom: 0;
        margin-top: 1rem;
        &.btn-promo {
          line-height: 1.6rem;
          font-size: 1rem;
          padding: 1rem;
          height: 5rem;
          bottom: -6rem;
          font-family: Neotech-medium,GE SS Two,Arial,sans-serif;
        }
      }
    }
    .hero-content {
      font-size: 1rem;
    }
    .hero-upper-title {
      font-size: 1.5rem;
      margin-bottom: 0.8rem;
    }
    .hero-swiper-button-next,
    .hero-swiper-button-prev {
      width: 2.1875rem;
      height: 4.25rem;
      svg {
        width: 2.1875rem;
        height: 4.25rem;
      }
    }
    .hero-swiper-button-next {
      right: 2.25rem;
    }
    .hero-swiper-button-prev {
      left: 2.25rem;
    }
  }
  .hero-images-ver-H {
    margin-top: 0;
    .search-group .form-group input.form-control{
      height: 3.5rem;
      font-size: 1.25rem;
    }
  }
}

/* -----------------------------------------
06 - md viewport
----------------------------------------- */
@media (min-width: @screen-md-min) {
  .hero-image {
    //min-height: 30rem;
    height:500px;
    &.medium {
      min-height: 351px !important;
      max-height: 351px !important;
      height: 351px !important;
    }
    &.medium-big {
      min-height: 548px !important;
      max-height: 548px !important;
      height: 548px !important;
    }
  }
  .swiper-pagination-custom {
    bottom: 4rem;
    .pagination-wrapper {
      font-size: 0.875rem;
    }
  }
  .swiper-container {
    .swiper-slide {
      h2:last-child {
        margin-bottom: 8.4rem;
      }
      a.btn {
        // margin-bottom: 3.5rem;
        // margin-top: 1.875rem;
      }
    }
  }
  .hero-images-ver-F {
    margin-top: 3.7rem;
  }
  .hero-images-ver-H {
    margin-top: 0;
    .search-group .form-group input.form-control{
      height: 3.5rem;
      font-size: 1.25rem;
    }
  }
}

/* -----------------------------------------
07 - lg viewport
----------------------------------------- */
@media (min-width: @screen-lg-min) {
  .swiper-pagination-custom {
    bottom: 3.55rem;
    font-weight: 600;
    .pagination-wrapper {
      font-size: 1rem;
    }
    a {
      padding-top: 0.8rem;
    }
  }
  .swiper-container {
    .swiper-slide {
      h2 {
        font-size: 5rem;
      }
      h2:last-child {
        margin-bottom: 5rem;
      }
    }
    .hero-content {
      font-size: 1.25rem;
    }
    .hero-upper-title {
      font-size: 2.25rem;
    }
    .hero-swiper-button-next,
    .hero-swiper-button-prev {
      width: 2.875rem;
      height: 5.625rem;
      svg {
        width: 2.875rem;
        height: 5.625rem;
      }
    }
    .hero-swiper-button-next {
      right: 2.625rem;
    }
    .hero-swiper-button-prev {
      left: 2.625rem;
    }
  }
  .hero-images-ver-H {
    .form .search-group .form-group input.form-control{
      height: 4.938rem;
        font-size: 1.5rem;
    }
  }
}

//error emssage mobile fix for padding on left and right
@media (max-width:440px) {
  .swiper-container .swiper-slide h2 {
      font-size: 1.5rem;
  }
}

.swiper-container {
    .swiper-slide {
      h1 {
        letter-spacing: 0 !important;
        margin-top:0;
      }
	}
}
/* -----------------------------------------
08 - Arabic overrides
----------------------------------------- */
*[lang="ar"] {
  *[dir="rtl"] {
    .swiper-container {
      .swiper-slide {
        .price-tag {
          left: 0;
          right: auto;
        }
      }
    }
    .hero-upper-title {
      font-size: 2.25rem;
      font-family: @font-family-base;
    }
    @media (max-width: @screen-sm-min) {
      .hero-upper-title {
        font-size: 1.5rem;
      }
    }
    @media (min-width: @screen-sm-min) {
      .hero-upper-title {
        font-size: 1.5rem;
      }
    }
    @media (min-width: @screen-lg-min) {
      .hero-upper-title {
        font-size: 2.25rem;
      }
      .swiper-container {
        .swiper-slide {
          h2 {
            font-size: 5.625rem;
          }
        }
      }
      .swiper-pagination-custom {
        .pagination-wrapper {
          a {
            span {
              font-size: 1.375rem;
            }
          }
        }
      }
    }
    .swiper-pagination-custom a.active:after, .swiper-pagination-custom a.activedNo:after {
      right:0;
      left: auto;
    }
  }
}




/**
    TODO: test if are used
    Are those rules used outside #51?

.hero-image {
  min-height: 31.25rem;



  .hero-images-ver-H {
    //dropdown menu with input text box design
    .form {

      .all-cat{
            max-height: 4.938rem;
            float: left;
            max-width: 33%;
            .form-section{
              margin-bottom: 0;
            }
          }
      .category-dropdown {

        .floating-label-select {
          margin-bottom: 0;
        }
        .hero-image-custom{
          height: 100%;
        }
        //select2
        .selection{
          max-width: 100%;
        }
        .select2.select2-container {
          overflow: hidden;
          padding-left: 1rem;
          padding-right: 1rem;
          .select2-selection--single {
            .select2-selection__rendered {
              span{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
        @media (min-width: @screen-xs-min) {}
        @media (min-width: @screen-ms-min) {
          .select2.select2-container {
            height: 3rem;
            padding-left: 1rem;
          }
          .select2-container.select2-container--open {
            .select2-dropdown {
              top: -19px;
              padding: 0.5rem 0;
            }
            .select2-results__option {
              padding-left: 1rem;
            }
          }
        }
        @media (min-width: @screen-sm-min) {
          .select2.select2-container {
            height: 3.5rem;
            padding-left: 1rem;
            font-size: 0.875rem;
            .select2-selection--single {
              .select2-selection__rendered {
                font-size: 0.875rem;
              }
            }
          }
          .select2-container.select2-container--open {
            .select2-dropdown {
              top: -19px;
              padding: 0.5rem 0;
            }
            .select2-results__option {
              padding-left: 1rem;
              font-size: 0.875rem;
            }
          }
        }
        @media (min-width: @screen-md-min) {
          .select2.select2-container {
            height: 3.5rem;
            //padding-left: 2rem;
            font-size: 0.875rem;
            .select2-selection--single {
              .select2-selection__rendered {
                font-size: 0.875rem;
              }
          }
          }
          .select2-container.select2-container--open {
            .select2-dropdown {
              top: -19px;
              padding: 0.5rem 0;
            }
            .select2-results__option {
              padding-left: 2rem;
              font-size: 0.875rem;
            }
          }
        }
        @media (min-width: @screen-lg-min) {
          .select2.select2-container {
            height:4.938rem;
            padding-left: 1rem;
            font-size: 1rem;
              .select2-selection--single {
                .select2-selection__rendered {
                  font-size: 1rem;
                }
            }
          }
          .select2-container.select2-container--open {
            .select2-dropdown {
              top: -16px;
              padding: 1rem 0;
            }
            .select2-results__option {
              padding-left: 1rem;
              font-size: 1rem;
            }
          }
        }
        //select2
      }
    }
    //option 1



    .form .search-group .form-group.input-group {
      &.help-box {
        .all-cat {
          background-color: @white;
          //flex: 1 3 25%;

        }
        .search-style {
          //flex: 3 1 75%;
          span {
            height: 100%;
          }
          .btn {
            height: 100%;
            &.search-btn {
              svg {
                height: 19px;
                width: 19px;

              }
            }
          }
        }
      }
    }
    .form {
      .search-group {
        .form-group {
          input {
            &.form-control {

              border: 0;
            }
          }

          .close{
              svg{
                  fill: @texts-gray;
              }
          }
        }
      }
    }

  }

}
*/

.hero-image{
&.hero-image-careers{
  .swiper-slide{
    color: @white;
    align-items: flex-start;
    .hero-images-ver-F{
      margin-top: 0px;
    }
    h2{
      color: @steel-grey-gain;
      font-size: 54px !important;
      font-weight: normal !important;
      line-height: 68px;
      margin-bottom: 1.5rem;
      margin-top: 5.6rem;
      @media (max-width: 768px) {
        margin-top: 2rem;
      }
    }
    p{
      color: @white;
      letter-spacing: 0.75px;
      font-family: @font-family-base;
      font-size: 24px;
      line-height: 24px;
      font-weight: normal;
      @media (max-width: 768px) {
        font-size: 14px;
      line-height: 20px;
      }
      &.hero-content{
        .btn{
          &.btn-default{
            border-color:  @white;
            margin-top: 78px;
            color: @white;
            font-family: @font-family-base;
            letter-spacing: 4px;
            &:hover{
              border-color: @main-green;
            }
          }
        }
      }
    }
    .section-title{
      color: @white;
      font-family: @font-family-base;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 2.5px;
      top: 3.79rem;
      position: relative;
    }
  }
}
&.swiper-container-careers-talent{
  min-height: 34rem;
  height: 500px;
  .hero-swiper-button-next,
  .hero-swiper-button-prev{
    svg{
      stroke:@white;
    }
  }
  .swiper-pagination-bullets{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    bottom: 5rem;
    .swiper-pagination-bullet{
        width: 15px;
        height: 15px;
        border: solid 2px @white;
        border-radius: 50%;
        opacity: 1;
        background-color: transparent;
        &.swiper-pagination-bullet-active{
          background-color: @white;
          color: @white;
          &::after{
            display: none;
          }
        }
    }
  }
}
}
@media (max-width: 767px) {
  .hero-image{
    &.hero-image-careers{
      .swiper-slide{
        min-height: 285px;
        h2{
          font-size: 26px !important;
          line-height: 33px;
          margin-bottom: 0.7rem;
          letter-spacing: 0px;
          margin-top: 0.7rem;
          text-align: center;
          br{
            display: none;
          }
        }
        p{
          letter-spacing: 0px;
          &.hero-content{
            .btn{
              &.btn-default{
                width: 100%;
              }
            }
          }
        }
        .section-title{
          margin-top: 1rem;
          padding-bottom: 0.5rem;
          top: 0.79rem;
          font-size: 14px;
          line-height: 14px;
          letter-spacing: 2.19px;
        }
      }
    }
  }

}
@media (max-width: 568px) {
  .hero-image{
    &.hero-image-careers{
      .swiper-slide{
        p{
          &.hero-content{
            .btn{
              &.btn-default{
                width: 100%;
                margin-top: 1.5rem;
                letter-spacing: 1px;
              }
            }
          }
        }
      }
    }
  }

}
@media (max-width: 767px) {
  .hero-image{
    &.swiper-container-careers-talent{
      min-height: 15rem;
      height: 300px;
    }
  }

}

.hero-image.swiper-container{
  &.home-hero-image-new{
    .swiper-slide{
    .section-title{
      color: @white;
    }
    }
    .hero-swiper-button-next,
    .hero-swiper-button-prev{
      svg{
        stroke: @white;
      }
    }
    .swiper-pagination-custom{
      bottom: 5%;
      .pagination-wrapper{
        justify-content: center;
        a{
          border: solid 1px @white;
          border-radius: 50px;
          width: 16px;
          height: 16px;
          margin: 0px 5px;
          flex-grow:initial;
          display: block;
          &.active{
            border: solid 1px @white;
            background-color: @white;
          }
          &::after{
            display: none;
          }
          span{
            display: none;
          }
        }
      }
    }
    }
  }
  @media (max-width: 768px) {
    .hero-image.swiper-container{
      &.home-hero-image-new{
        .swiper-slide{
          h2{
            font-size: 2rem;
            line-height: 1;
          }
        .section-title{
          color: @white;
        }
        }
        .swiper-pagination-custom{
          bottom: 3%;
        }
        }
      }
      .hero-image{
        &.hero-image-careers{
          .swiper-slide{
            p{
              &.hero-content{
                .btn{
                  &.btn-default{
                    margin-top: 40px;
                    margin-bottom: 30px;
                  }
                }
              }
            }
          }
        }
      }
  }

  @media (max-width: 320px) {
    .hero-image{
      &.hero-image-careers{
        .swiper-slide{
          p{
            &.hero-content{
              .btn{
                &.btn-default{
                  font-size: 12px;
                  padding: 0px 1rem;
                }
              }
            }
          }
        }
      }
    }
  }

@media (min-width:375px){

  .hero-image.halfscreen {
    //height:353px !important;
  }
  .hero-image.fullscreen {
    //height:460px !important;
  }

}

@media (min-width:667px){

  .hero-image.halfscreen {
    //height:353px !important;
  }
  .hero-image.fullscreen {
    //height:460px !important;
  }

}

@media (min-width:768px){

  .hero-image.halfscreen {
    //height:353px !important;
  }
  .hero-image.fullscreen {
    //height:460px !important;
  }
}

@media (min-width:1024px){

  .hero-image.halfscreen {
    //height:353px !important;
  }
  .hero-image.fullscreen {
    //height:460px !important;
  }
}

@media (min-width:1400px){

  .hero-image.halfscreen {
    //height:353px !important;
  }
  .hero-image.fullscreen {
    //height:460px !important;
  }
}

@media (min-width:1600px){

  .hero-image.halfscreen {
    //height:353px !important;
  }
  .hero-image.fullscreen {
    //height:460px !important;
  }
}
.hero-image  {
  h1.heading-bigger {
    font-size: 1.875rem;
  }
}

@media (min-width: 576){
  .hero-image  {
    h1.heading-bigger {
      font-size: 1.875rem;
    }
  }
}
@media (min-width: 768px){
  .hero-image  {
    h1.heading-bigger {
      font-size: 2.5rem;
    }
  }
}
@media (min-width: 992px){
  .hero-image  {
    h1.heading-bigger {
      font-size: 3.125rem;
    }
  }
}
@media (min-width: 1200px){
  .hero-image  {
    h1.heading-bigger {
      font-size: 3.375rem;
    }
  }
}
@media (min-width:1400px){
  .hero-image  {
    h1.heading-bigger {
      font-size: 3.375rem;
    }
  }
}








.bg-hero-cover-xl,
.bg-hero-cover-l,
.bg-hero-cover-m,
.bg-hero-cover-s {
  background-position: center !important;
  background-repeat: no-repeat !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}


@media (min-width: 320px){
  .bg-hero-cover-xl {
    display: none;
  }
  .bg-hero-cover-l {
    display: none;
  }
  .bg-hero-cover-m {
    display: none;
  }
  .bg-hero-cover-s {
    display: block;
  }

}
@media (min-width: 768px){
  .bg-hero-cover-xl {
    display: none;
  }
  .bg-hero-cover-l {
    display: none;
  }
  .bg-hero-cover-m {
    display: block;
  }
  .bg-hero-cover-s {
    display: none;
  }

}
@media (min-width: 1024px){
  .bg-hero-cover-xl {
    display: none;
  }
  .bg-hero-cover-l {
    display: block;
  }
  .bg-hero-cover-m {
    display: none;
  }
  .bg-hero-cover-s {
    display: none;
  }
  .alignLeftTxt {
      text-align: left !important;
  }

  .alignLeftTxt.swiper-slide .section-title {
      text-align:left !important;
      margin-left: 8.33333333%;
  }

  .alignLeftTxt .hero-content span {
      margin-left: 0px !important;
  }


  .alignRightTxt {
      text-align: right !important;
  }

  .alignRightTxt.swiper-slide .section-title {
      text-align:right !important;
      margin-right: 8.33333333%;
  }

  .alignRightTxt .hero-content span {
      margin-right: 0px !important;
  }
}
@media (min-width:1440px){
  .bg-hero-cover-xl {
    display: block;
  }
  .bg-hero-cover-l {
    display: none;
  }
  .bg-hero-cover-m {
    display: none;
  }
  .bg-hero-cover-s {
    display: none;
  }
  .alignLeftTxt {
      text-align: left !important;
  }

  .alignLeftTxt.swiper-slide .section-title {
      text-align:left !important;
      margin-left: 8.33333333%;
  }

  .alignLeftTxt .hero-content span {
      margin-left: 0px !important;
  }
  .alignRightTxt {
      text-align: right !important;
  }

  .alignRightTxt.swiper-slide .section-title {
      text-align:right !important;
      margin-right: 8.33333333%;
  }

  .alignRightTxt .hero-content span {
      margin-right: 0px !important;
  }
}
