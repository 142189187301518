@import "type-responsive.less";

form:not(#callbackForm):not(#search-form):not(#store-locator-form):not(#select-account-form):not(#help-me-form):not(#filters-form):not(#support-search-form):not(#installation-date) {
  &:not([data-active=true]) {
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    > * {
      opacity: 0.5;
      button {
        pointer-events: none;
      }
    }
  }
}

.submit-ok-icon,
.submit-ko-icon{
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
  margin-top: 0.8125rem;
}
.submit-ko-icon{
  display: none;
}
.error {
  .submit-ko-icon{
    display: block;
  }
  .submit-ok-icon{
    display: none;
  }
}

.form {
  //upload-files
  .smartpayNotEligibleMsg {
    &.error {
      color: #fb193f;
      margin: 0;
      padding: 0;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: .75rem;
      line-height: 2rem;
    }
  }
  .upload-files-container{
    &.download-files{
      margin-bottom: 3rem;
      .download-file-tile{
        align-items: center;
        padding-left: 0;
        border: 1px solid @cold-gray;
        label{
          padding: 0 0rem;
        }
        h5{
          color: @main-green;
        }
      }
    }
    .upload-files-content{
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      flex-direction: row;
      padding-right: 1rem;
      padding-left: 1rem;
      .alert-icon{
        display: none;
      }
      svg{
        max-height: 24px;
        max-width:24px;
      }
      h5{
        letter-spacing: 0;
        display: flex;
        svg{
          height: 24px;
          width: 24px;
          fill: @main-green;
          margin: 0 1rem;
        }
      }
      .inputfile-label{
        margin: 0;
        color: @main-green;
        font-size: 1.15rem;
      }
      .inputfile+label, label{
        color: @main-green;
        font-weight: normal;
        font-size: 0.85rem;
        border: 0;
        svg{
          g{
            stroke: @main-green;
          }
        }
      }
    }
    hr{
      border-top: 1px solid @cold-gray;
      margin-top: 0;
      margin-bottom: 3rem;
    }
  }
  .file-input {
    .alert-icon {
      display: none;
    }
  }
  .form-section {
    margin-bottom: 32px;
    &.mZero{
      margin-bottom: 0;
    }
    &.no-mar-bottom {
      margin-bottom: 0;
    }
    .grayText{color:@steel-grey;}
  }
  // form-section
  &:last-child {
    margin-bottom: 0;
  }
  .form-control {
    border: solid 1px @lighty-gray;
    padding: 16px;
    line-height: 16px;
    font-size: 1rem;
    min-height: 48px;
    display: block;
    width: 100%;
    appearance: none;
    height: auto;
    &::-webkit-input-placeholder {
      color: @main-green;
    }
    &::-moz-placeholder {
      color: @main-green;
    }
    &::-ms-input-placeholder {
      color: @main-green;
    }
    &:focus {
      outline: none;
      box-shadow: none;
      border-color: initial;
    }
  }
  .checkbox,
  .radio {
    position: relative;
    display: block;
    //padding-top: 0.5rem;
    @media (min-width: @screen-md-min) {
      padding-top: 1rem;
    }
    //padding-bottom: 0.5rem;
    line-height: 1rem;
    input {
      //display: none;
      visibility: hidden;
      &[type="checkbox"]:checked + label:before {
        background-image: url("../img/forms/checkbox.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 80%;
      }
      &[type="radio"] {
        ~ label:before {
          border-radius: 50%;
        }
        &:checked + label:before {
          //background-image: url("../img/forms/radio.svg");
          background-image: url("../img/forms/radio.png");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 75%;
          //content: '\a0\2022';
          //color: @main-green;
          //font-size: 48px;
          //line-height: 14px;
          //letter-spacing: -12px;
        }
      }
    }
    input {
      //display: none;
      visibility: hidden;
      &[type="checkbox"]:checked ~ label:before {
        background-image: url("../img/forms/checkbox.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 80%;
      }
      &[type="radio"] {
        ~ label:before {
          border-radius: 50%;
        }
        &:checked ~ label:before {
          //background-image: url("../img/forms/radio.svg");
          background-image: url("../img/forms/radio.png");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 75%;
          //content: '\a0\2022';
          //color: @main-green;
          //font-size: 48px;
          //line-height: 14px;
          //letter-spacing: -12px;
        }

      }
    }
    input:nth-child(2) {
      display:none;
    }
    label {
      display: block;
      min-height: auto;
      position: relative;
      padding-left: 1.5rem;
      @media (min-width: @screen-md-min) {
        padding-left: 2rem;
      }
      &:hover {
        background-color: transparent !important;
      }
      &:before {
        content: "";
        display: block;
        border: solid 1px @lighty-gray;
        margin-top: -8px;
        width: 16px;
        height: 16px;
        position: absolute;
        background-color: @white;
        left: 0;
        top: 50%;
        //box-sizing: content-box;
        @media (min-width: @screen-md-min) {
          margin-top: -12px;
          width: 24px;
          height: 24px;
        }
      }
    }
    .alert-icon {
      display: none;
    }
    .alert-label {
      color: @pink;
      //line-height: 1rem;
      //height: 1rem;
      margin: 0;
      padding: 0 0 0 1.5rem;
      @media (min-width: @screen-md-min) {
        padding-left: 2rem;
      }
      float: right;
      //width: 100%;
      //padding-left: 2rem;
      //position: absolute;
      //top: -1.5rem;
      //text-align: center;
      //white-space: nowrap;
      //overflow: hidden;
      //text-overflow: ellipsis;
      .body-small;
      //display: inline;
      //margin-left: 300px;
      //text-align: right;
      svg {
        display: none;
        fill: @pink;
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
        position: absolute;
        top: 0;
        left: 0.5rem;
      }
      a {
        color: @pink;
        text-decoration: underline;
      }
    }
    &.has-error-fields, &.had-error-fields {
      input + label {
        color: @pink;
        &:before {
          border-color: @pink;
        }
      }
    }
    &.disabled {
      color: @gray !important;
      a {
        color: @gray !important;
      }
    }
  }
  .radio {
    text-align: left;
    label {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      margin: 0;
    }
  }
  //<!--floating label
  @floating-label-size: 1.25rem;
  @floating-label-line: 1.5rem;
  .floating-label-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .floating-label-input {
      flex: 1;
    }
  }
  // file-input
  .file-input.has-error-fields {
    .alert-label {
      color: @pink;
      line-height: 1rem;
      height: 1rem;
      margin: 0;
      padding: 0;
      width: 100%;
      //padding-left: 2rem;
      position: absolute;
      top: 0;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .body-small;
      svg {
        display: none;
        fill: @pink;
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
        position: absolute;
        top: 0;
        left: 0.5rem;
      }
      a {
        color: @pink;
        text-decoration: underline;
      }
    }
    .alert-icon {
      display: none;
    }
    .inputfile-label {
      color: @pink;
    }
    .btn {
      //color: @pink;
      //border-color: @pink;
      svg {
        //fill: @pink;
      }
    }
  }
  .floating-label-input,
  .floating-label-select {
    position: relative;
    display: block;
    margin: 0 0 @floating-label-line*2;
    padding: 0;
    border: solid 1px @lighty-gray;
    background-color: @white;
    > select,
    input, textarea {
      display: block;
      box-sizing: border-box;
      width: 100%;
      margin: 0;
      padding: @floating-label-line*1.666 @floating-label-line*1.333 @floating-label-line*0.666;
      font-size: @floating-label-size;
      line-height: @floating-label-line;
      color: @main-green;
      font-weight: normal;
      border: none;
      box-shadow: none;
      outline: none;
      background-color: transparent;
      position: relative;
      z-index: 2;
      &:focus {
        outline: none;
      }
    }
    > textarea {
      resize: none;
      border-top: 2rem solid transparent;
      border-bottom: .7rem solid transparent;
      padding-top: 0;
      padding-bottom: 0;
    }
    label {
      position: absolute;
      display: block;
      box-sizing: border-box;
      top: 0;
      left: 0;
      max-width: 100%;
      margin: 0;
      padding: 0 @floating-label-line*1.333;
      font-size: @floating-label-size;
      line-height: @floating-label-line*3.333;
      color: @main-green;
      font-weight: normal;
      pointer-events: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: transparent;
      .transition(0.3s);
      &:hover {
        background-color: transparent;
      }
    }

    &.valid {
      border-color: @dark-green;
      input {
        background-color: @valid-green;
      }
      label {
        color: @dark-green;
      }
    }

    input:disabled ~ label,
    input:focus ~ label,
    input:required:valid ~ label,
    input[readonly] ~ label,
    label.floating-label,
    select.selected ~ label {
      top: @floating-label-line*0.625;
      font-size: @floating-label-size*0.75;
      line-height: @floating-label-line*0.75;
      color: @steel-grey;
      z-index: 2;
    }
    textarea:disabled ~ label,
    textarea:focus ~ label,
    textarea:required:valid ~ label,
    textarea[readonly] ~ label,
    label.floating-label,
    select.selected ~ label {
      top: @floating-label-line*0.625;
      font-size: @floating-label-size*0.75;
      line-height: @floating-label-line*0.75;
      color: @steel-grey;
    }
    &.floating-label-prefix {
      flex: 0 1 33%;
      margin-right: @floating-label-line;
    }
    .forgot-label {
      color: @almost-black;
      line-height: 1rem;
      height: 1rem;
      margin: 0;
      padding: 0;
      position: absolute;
      z-index: 1;
      bottom: -1.5rem;
      a {
        color: @almost-black;
        text-decoration: underline;
      }
    }
    .alert-icon {
      display: none;
    }
    &.has-error-fields, &.had-error-fields {
      border-color: @pink;
      input,
      label, textarea {
        color: @pink !important;
        //padding-right: @floating-label-line*3;
      }
      .alert-icon {
        display: block;
        svg {
          fill: @pink;
          width: @floating-label-line;
          height: @floating-label-line;
          position: absolute;
          top: 1.7rem; // @floating-label-line;
          right: @floating-label-line*0.75;
        }
      }
      .alert-label {
        color: @pink;
        line-height: 1rem;
        height: 1rem;
        margin: 0;
        padding: 0;
        width: 100%;
        //padding-left: 2rem;
        position: absolute;
        top: -1.5rem;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .body-small;
        svg {
          display: none;
          fill: @pink;
          width: 1rem;
          height: 1rem;
          margin-right: 0.5rem;
          position: absolute;
          top: 0;
          left: 0.5rem;
        }
        a {
          color: @pink;
          text-decoration: underline;
        }
      }
      .inner-icon {
        svg {
          fill: @pink;
        }
      }
      //.forgot-label {
      //  display: none;
      //}
    }
    &.mZero {margin:0px;}
  }

  .floating-label-input {
    .caps-warn {
      color: @orange;
      line-height: 1rem;
      height: 1rem;
      margin: 0;
      padding: 0;
      width: 100%;
      position: absolute;
      top: -1.5rem;
      text-align: right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .body-small;
    }
  }

  .floating-label-select {
    label {
      /*padding-right: 3rem !important;*/
    }
    &.has-error-fields {
      .alert-icon {
        svg {
          //right: @floating-label-line*1.75;
        }
      }
      .select2.select2-container .select2-selection__arrow b {
        border-color: @pink transparent transparent !important;
      }
      .select2.select2-container.select2-container--open .select2-selection--single .select2-selection__arrow b {
        border-color: transparent transparent @pink !important;
      }
    }
  }
  .datepicker {
    & ~ label {
      /*padding-right: 3rem !important;*/
    }
    & ~ .picker {
      select {
        box-sizing: border-box;
        //margin: 0;
        padding: 0;
        color: @main-green;
        font-weight: normal;
        border: solid 1px @lighty-gray;
        box-shadow: none;
        outline: none;
        background-color: @white;
      }
    }
  }
  // svg inside
  .floating-label-input {
    span {
      svg {
        height: 25px;
        width: 25px;
        fill: @main-green;
      }
    }
  }
  .inner-icon {
    position: absolute;
    right: 1rem;
    top: 35%;
  }
  @media (max-width: @screen-lg-min) {
    @floating-label-size: 1rem;
    @floating-label-line: 1.25rem;
    .floating-label-input,
    .floating-label-select {
      &.mBtm10 {margin-bottom:0px;}
      margin: 0 0 @floating-label-line*2;
      > select,
      input, textarea {
        padding: @floating-label-line*1.666 @floating-label-line*1.333 @floating-label-line*0.666;
        font-size: @floating-label-size;
        line-height: @floating-label-line;
      }
      label {
        padding: 0 @floating-label-line*1.333;
        font-size: @floating-label-size;
        line-height: @floating-label-line*3.333;
        padding-right: 3rem;
      }

      input:disabled ~ label,
      input:focus ~ label,
      input:required:valid ~ label,
      input[readonly] ~ label,
      label.floating-label,
      select.selected ~ label {
        top: @floating-label-line*0.625;
        font-size: @floating-label-size*0.75;
        line-height: @floating-label-line*0.75;
        color: @steel-grey;
      }
      textarea:disabled ~ label,
      textarea:focus ~ label,
      textarea:required:valid ~ label,
      textarea[readonly] ~ label,
      label.floating-label,
      select.selected ~ label {
        top: @floating-label-line*0.625;
        font-size: @floating-label-size*0.75;
        line-height: @floating-label-line*0.75;
        color: @steel-grey;
      }
      &.floating-label-prefix {
        margin-right: @floating-label-line;
      }
      &.has-error-fields, &.had-error-fields {
        .alert-icon {
          svg {
            width: @floating-label-line;
            height: @floating-label-line;
            top: @floating-label-line*1.1666;
            right: @floating-label-line*0.75;
          }
        }
      }
    }

  }
  .floating-label-select.has-error-fields .alert-icon svg {
    //right: 2.5rem;
  }

  //floating label-->
  //select2
  .select2.select2-container {
    //box-sizing: content-box;
    position: relative;
    top: 0 !important;
    margin: 0;
    //padding: 0 @floating-label-line*1.333;
    padding: @floating-label-line*1.666 @floating-label-line*2 @floating-label-line*0.666 @floating-label-line*1.333;
    line-height: @floating-label-line;
    text-align: left;
    background-color: transparent;
    .select2-selection--single {
      border: 0;
      border-radius: 0;
      outline: none;
      height: @floating-label-line;
      background-color: transparent;
      &:focus {
        outline: 0;
      }
      .select2-selection__rendered {
        color: @main-green;
        font-size: @floating-label-size;
        line-height: @floating-label-line;
        color: @main-green;
        background-color: transparent;
        height: @floating-label-line;
        padding: 0;
        border: 0;
        //text-overflow: clip;
      }
    }
    .select2-selection__arrow {
      top: @floating-label-line*2;
      right: @floating-label-line*1.333;
      height: 0;
      width: 0;
      b {
        border-color: @main-green transparent transparent;
        border-width: @floating-label-size*0.25 @floating-label-size*0.25 0;
      }
    }
    &.select2-container--open {
      top: 0 !important;
    }
    &.select2-container--open .select2-selection--single .select2-selection__arrow {
      b {
        border-color: transparent transparent @main-green;
        border-width: 0 @floating-label-size*0.25 @floating-label-size*0.25;
      }
    }
  }
  .select2-container.select2-container--open {
    top: @floating-label-line*3.333 !important;
    left: 0 !important;
    .select2-dropdown {
      width: 100%;
      text-align: left;
      background-color: @sand-white-50;
      border: 0;
      border-radius: 0;
      /*left: -1px;
      top: -1px;*/
      z-index: 5;
      box-shadow: 0 0.0625rem 0.25rem 0 rgba(1, 1, 1, 0.3);
    }
    .select2-results__option {
      color: @dark-gray;
      font-size: @floating-label-size;
      line-height: @floating-label-line*2.333;
      font-weight: normal;
      //box-sizing: content-box;
      margin: 0;
      padding: 0 @floating-label-line*1.333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      .transition(@menu-transition-duration, ease-in-out);
      &:first-of-type {
        //padding-top: @floating-label-line;
      }
      &:last-of-type {
        //padding-bottom: @floating-label-line;
      }
    }
    .select2-results__option--highlighted {
      background-color: transparent;
      color: @main-green;
    }
    .select2-results__option[aria-selected=true] {
      background-color: transparent;
      color: @main-green;
      text-decoration: underline;
    }
    .select2-container--focus {
      background-color: transparent;
    }
    .select2-results__options {
      max-height: @floating-label-line*9.333;
    }
  }
  @media (max-width: @screen-sm-min){
    .form .floating-label-input label, .form .floating-label-select label, table .floating-label-input label, table .floating-label-select label {
      padding-right: 0rem;
    }
  }
  @media (max-width: @screen-lg-min) {
    @floating-label-size: 1rem;
    @floating-label-line: 1.25rem;
    .select2.select2-container {
      padding: @floating-label-line*1.666 @floating-label-line*2 @floating-label-line*0.666 @floating-label-line*1.333;
      line-height: @floating-label-line;
      .select2-selection--single {
        height: @floating-label-line;
        .select2-selection__rendered {
          font-size: @floating-label-size;
          line-height: @floating-label-line;
          height: @floating-label-line;
        }
      }
      .select2-selection__arrow {
        top: @floating-label-line*2;
        right: @floating-label-line*1.333;
        b {
          border-width: @floating-label-size*0.25 @floating-label-size*0.25 0;
        }
      }
      &.select2-container--open .select2-selection--single .select2-selection__arrow {
        b {
          border-width: 0 @floating-label-size*0.25 @floating-label-size*0.25;
        }
      }
    }

    .select2-container.select2-container--open {
      top: @floating-label-line*3.333 !important;
      .select2-results__option {
        font-size: @floating-label-size;
        line-height: @floating-label-line*2.333;
        padding: 0 @floating-label-line*1.333;
      }
      .select2-results__options {
        max-height: @floating-label-line*9.333;
      }
    }
  }
  //select2
  /*start search field for store-locator module and CHECK AVAILABILITY module*/
  .search-group {
    .form-group {
      input.form-control {
        color: @main-green;
        border-right: 0;
        border-left: 0;
      }
      &.input-group {
        position: relative;
        //display: flex;
        //justify-content: center;
        //align-content: center;
        align-items: baseline;
        border-collapse: separate;
        .input-group-btn {
          display: block;
          .search-btn {
            background-color: @sand-white;
            border: 0;
            text-transform: uppercase;
          }
        }
      }
      &.focused {
        .input-group-btn {
          display: block;
        }
      }
    }
  }
  /*End search field for store-locator module and CHECK AVAILABILITY module*/
  .submit {
    .btn {
      display: block;
      width: 100%;
      margin: auto;
      @media (min-width: @screen-md-min) {
        width: auto;
      }
    }
  }

  &.disabled input,
  input.disabled,
  input:disabled,
  input[readonly] {
    &:not(.datepicker){
      background-color: @disabled-input-color !important;
      -webkit-autofill: @disabled-input-color !important;
    }
  }

  input.timepicker.time{
    background-color: transparent !important;
    -webkit-autofill: transparent !important;
  }

  input[name='username'],
  input[name='usernameUser'],
  input[name='userId'],
  input[name='userID'] {
    //text-transform: lowercase !important;
  }

  label.contact-customer-service {
    width: 100%;
    margin-top: 2rem;
    font-weight: normal;
  }

  // masked-input
  .masked-input {
    position: relative;

    > input:not(.input-masked) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }

    > input.input-masked {
      display: inline-block;
      vertical-align: top;

      &[type="text"] {
        padding-right: 0;
        width: 7ch; // ch -> https://developer.mozilla.org/en-US/docs/Web/CSS/length#Browser_compatibility
      }
      &[type="password"] {
        padding-left: 0;
        width: auto; // this will be overriden in js
      }
    }

    &:not(.has-error-fields) &:not(.had-error-fields) {
      .alert-label, .alert-icon {
        display: none;
      }
    }
  }

  .floating-label-input {
    &.has-error-fields {
      label {
        padding-right: 2rem*1.25;
      }
      @media (min-width: @screen-lg-min) {
        label {
          padding-right: 2rem*1.5;
        }
      }
    }
  }
  .floating-label-select {
    label {
      padding-right: 2rem*1.25;
    }
    @media (min-width: @screen-lg-min) {
      label {
        padding-right: 2rem*1.5;
      }
    }
    &.has-error-fields {
      .alert-icon svg {
        right: 2rem*1.25;
      }
      label {
        padding-right: 3.5rem*1.25;
      }
      @media (min-width: @screen-lg-min) {
        .alert-icon svg {
          right: 2rem*1.5;
        }

        label {
          padding-right: 3.5rem*1.5;
        }
      }
    }
  }
  .datepicker {
    & ~ label {
      padding-right: 2.5rem*1.25;
    }
    @media (min-width: @screen-lg-min) {
      & ~ label {
        padding-right: 2.5rem*1.5;
      }
    }
  }

}
.config-accordion--verD, .config-accordion--verE {
  .form {
    .checkbox{
      @media (min-width: @screen-md-min) {
        padding-top: 0.2rem;
      }
    }
  }
}
@media (max-width: @screen-lg-min) {
  .form .checkbox .alert-label,
  .form .radio .alert-label {
    //display: inline;
    //margin-left: 300px;
    //text-align: left;
  }
}

// .form
// Custom Input[file]
// See: http://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
.form {

  .inputfile-label {
    display: block;
    text-align: center;
    margin: 1.5rem;
    font-size: 18px;

    &.uploaded {
      color: @main-green;
      text-decoration: underline;
    }
  }

  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .inputfile + label {
    max-width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
  }
  .inputfile + label svg {
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.25em;
  }
  .inputfile + label {
    color: @almost-black;
    border: 2px solid currentColor;
  }
  .inputfile + label:hover,
  .inputfile.has-focus + label,
  .inputfile:focus + label {
    // color: #722040;
  }
}
.has-error-block{
  h3{
    color: @pink;
    letter-spacing: 0;
  }
  p{
    color: @steel-grey;
    font-size: 1.25rem;
  }
}

/*
* Google Places Autocomplete
*/
// Places Autocomplete
#pac-input {
  text-overflow: ellipsis;
  box-shadow: 0 1px 4px 0 rgba(166, 166, 166, 0.5);
}

// Places Autocomplete results
// ms
//@media (max-width: @screen-ms-max) {
.pac-container {
  margin-top: 0;
  > .pac-item {
    padding: 15px 4px 9px;
    line-height: 0.875rem;
    .pac-icon {
      margin-right: 1.6rem;
      margin-left: 0.6rem;
    }
    span {
      font-size: 0.875rem;
    }
    .pac-item-query {
      color: @almost-black;
    }
    .pac-item-query + span {
      margin-left: 5.2rem;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

//}
@media (min-width: @screen-sm-min) {
  .sm-small-space {
    margin-left: 2rem;
  }

  .pac-container {
    margin-top: 0;
    > .pac-item {
      padding: 15px 4px 9px;
      .pac-icon {
        margin-right: 1.25rem;
        margin-left: 1.34rem;
      }
      span {
        font-size: 0.875rem;
      }
      .pac-item-query {
        color: @almost-black;
      }
    }
  }
}

@media (min-width: @screen-md-min) {
  .pac-container {
    margin-top: 0;
    > .pac-item {
      padding: 15px 4px 9px;
      .pac-icon {
        margin-right: 2.25rem;
        margin-left: 2rem;
      }
      span {
        font-size: 1rem;
      }
      .pac-item-query {
        color: @almost-black;
      }
    }
  }
}

@media (min-width: @screen-xs) and (max-width: @screen-ms-max) {
  /*start search field for store-locator module and CHECK AVAILABILITY module*/
  .search-group {
    .form-group {
      &:last-child {
        margin: 1.625rem auto;
      }
      input.form-control {
        height: 2.5rem;
        font-size: 1rem;
        line-height: 1rem;
      }
      &.input-group {
        .input-group-btn {
          .search-btn {
            padding: 12px;
            height: 3.84em;
            padding-right: 1rem;
            padding-left: 1rem;
            font-size: 0.75rem;
            Letter-spacing: 2px;
          }
        }
      }
    }
  }

  /*End search field for store-locator module and CHECK AVAILABILITY module*/
}

/*End search field for store-locator module and CHECK AVAILABILITY module*/
@media (min-width: @screen-sm-min) {
  /*start search field for store-locator module and CHECK AVAILABILITY module*/
  .search-group {
    .form-group {
      &:last-child {
        margin: 0 auto 0.875rem;
      }
      input.form-control {
        height: 2.5rem;
        font-size: 1rem;
        line-height: 1rem;
      }
      &.input-group {
        .input-group-btn {
          .search-btn {
            padding: 12px;
            height: 3.84em;
            padding-right: 1rem;
            padding-left: 1rem;
            font-size: 0.75rem;
            letter-spacing: 2px;
          }
        }
      }
    }
  }

  /*End search field for store-locator module and CHECK AVAILABILITY module*/
}

@media (min-width: @screen-md-min) {
  /*start search field for store-locator module and CHECK AVAILABILITY module*/
  .search-group {
    .form-group {
      &:last-child {
        margin: 1rem auto 0.875rem;
      }
      input.form-control {
        height: 2.5rem;
        font-size: 1rem;
        line-height: 1rem;
      }
      &.input-group {
        .input-group-btn {
          .search-btn {
            padding: 12px;
            height: 3.84em;
            padding-right: 1rem;
            padding-left: 1rem;
            font-size: 0.75rem;
            Letter-spacing: 2px;
          }
        }
      }
    }
  }

  /*End search field for store-locator module and CHECK AVAILABILITY module*/
}

@media (min-width: @screen-lg-min) {
  /*start search field for store-locator module and CHECK AVAILABILITY module*/
  .search-group {
    .form-group {
      &:last-child {
        margin: 1rem auto 0.875rem;
      }
      input.form-control {
        height: 4rem;
        font-size: 1.25rem;
        line-height: 1.25rem;
      }
      &.input-group {
        .input-group-btn {
          .search-btn {
            padding: 21px;
            height: 3.85rem;
            padding-right: 24px;
            font-size: 0.875rem;
            padding-left: 24px;
            Letter-spacing: 2.3px;
          }
        }
      }
    }
  }

  /*End search field for store-locator module and CHECK AVAILABILITY module*/
}

//custom dropdown with icons
//dropdown menu with input text box design
.form.custom-dd {
  .category-dropdown {
    .floating-label-select {
      border: 0;
      z-index: 5;
    }
    //select2
    .select2.select2-container {
      //box-sizing: content-box;
      position: relative;
      top: 0;
      margin: 0;
      padding: 1rem;
      line-height: 0;
      text-align: left;
      background-color: #f5f4f5;
      font-family: @headings-font-family;
      font-size: 1rem;
      color: @dark-gray;
      text-transform: uppercase;
      height: 4rem;
      border: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .select2-selection--single {
        border: 0;
        border-radius: 0;
        outline: none;
        height: 100%;
        background-color: #f5f4f5;
        &:focus {
          outline: 0;
        }
        .select2-selection__rendered {
          color: @dark-gray;
          font-size: 1rem;
          line-height: 1.5rem;
          background-color: #f5f4f5;
          height: 100%;
          padding: 0;
          border: 0;
          display: flex;
          //text-overflow: clip;
          img {
            height: 24px;
            width: 24px;
            margin-right: 1rem;
            stroke: @dark-gray;
          }
        }
      }
      .select2-selection__arrow {
        display: none;
      }
      &.select2-container--open {
        top: 0 !important;
      }
      &.select2-container--open .select2-selection--single .select2-selection__arrow {
        display: none;
      }
    }
    .select2-container.select2-container--open {
      top: auto;
      box-shadow: 0 0.0625rem 0.25rem 0 rgba(1, 1, 1, 0.3);
      left: 0;
      .select2-container--focus {
        box-shadow: 0 0.0625rem 0.25rem 0 rgba(1, 1, 1, 0.3);
      }
      .select2-dropdown {
        width: 100%;
        text-align: left;
        background-color: #f5f4f5;
        border: 0;
        border-radius: 0;
        left: 0;
        top: -2px;
        z-index: -1;
        padding: 1rem 0;
        box-shadow: 0 0.0625rem 0.25rem 0 rgba(1, 1, 1, 0.3);
      }
      .select2-results__option {
        color: @dark-gray;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: normal;
        //box-sizing: content-box;
        margin: 0;
        padding: 0.5rem 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        justify-content: flex-start;
        max-width: 100%;
        transition: none;
        &:first-of-type {
          //padding-top: @floating-label-line;
        }
        &:last-of-type {
          //padding-bottom: @floating-label-line;
        }
        img {
          height: 24px;
          width: 24px;
          margin-right: 1rem;
          stroke: @dark-gray;
        }
      }
      .select2-results__option--highlighted {
        background-color: transparent;
        color: @dark-gray;
        border-left: 4px solid @main-green;
        text-decoration: none;
        img {
          margin-left: -4px;
        }
      }
      .select2-results__option[aria-selected=true] {
        background-color: transparent;
        color: @dark-gray;
        text-decoration: none;
        border-left: 4px solid @main-green;
        img {
          margin-left: -4px;
        }
      }
      .select2-container--focus {
        background-color: transparent;
        text-decoration: none;
        border-left: 4px solid @main-green;
        img {
          margin-left: -4px;
        }
      }
      .select2-results__options {
        max-height: 100%;
      }
    }
    @media (min-width: @screen-xs-min) {


    }
    @media (min-width: @screen-ms-min) {
      .form .upload-files-container .upload-files-content{
        justify-content: flex-start;
        flex-direction: column;
      }
      .select2.select2-container {
        height: 3rem;
        padding-left: 1rem;
      }

      .select2-container.select2-container--open {
        .select2-dropdown {
          top: -19px;
          padding: 0.5rem 0;
        }
        .select2-results__option {
          padding-left: 1rem;
        }
      }
    }
    @media (min-width: @screen-sm-min) {
      .select2.select2-container {
        height: 3rem;
        padding-left: 1rem;
      }

      .select2-container.select2-container--open {
        .select2-dropdown {
          top: -19px;
          padding: 0.5rem 0;
        }
        .select2-results__option {
          padding-left: 1rem;
        }
      }
    }
    @media (min-width: @screen-md-min) {
      .select2.select2-container {
        height: 3rem;
        padding-left: 2rem;
      }

      .select2-container.select2-container--open {
        .select2-dropdown {
          top: -19px;
          padding: 0.5rem 0;
        }
        .select2-results__option {
          padding-left: 2rem;
        }
      }
    }
    @media (min-width: @screen-lg-min) {
      .select2.select2-container {
        height: 4rem;
        padding-left: 1rem;
      }

      .select2-container.select2-container--open {
        .select2-dropdown {
          top: -16px;
          padding: 1rem 0;
        }
        .select2-results__option {
          padding-left: 1rem;
        }
      }
    }
    //select2
  }
}

//arabic & RTL
*[lang="ar"] {
  *[dir="rtl"] {
    .form.custom-dd {
      .category-dropdown {
        //select2
        .select2.select2-container {
          text-align: right;
          .select2-selection--single {
          }
          .select2-selection__rendered {
            img {
              margin-left: 1rem;
              margin-right: auto;
            }
          }
        }
      }
      .select2-container.select2-container--open {
        right: 0;
        left: auto;
        .select2-dropdown {
          text-align: right;
          right: 0;
          left: auto;
        }
        .select2-results__option {
          border-right: 4px solid transparent;
          img {
            margin-left: 1rem;
            margin-right: -4px;
          }
        }
        .select2-results__option--highlighted {
          border-right: 4px solid @main-green;
          border-left: 0;
          img {
            margin-left: 1rem;
            margin-right: -4px;
          }
        }
        .select2-results__option[aria-selected=true] {
          border-right: 4px solid @main-green;
          border-left: 0;
          img {
            margin-left: 1rem;
            margin-right: -4px;
          }
        }
        .select2-container--focus {
          border-right: 4px solid @main-green;
          border-left: 0;
          img {
            margin-left: 1rem;
            margin-right: -4px;
          }
        }
        // .select2-results__options {
        //   max-height: 100%;
        // }
      }
      .select2-container--default .select2-selection--single .select2-selection__arrow b {
        //margin-left: 28px;
      }
      @media (min-width: @screen-xs-min) {
      }
      @media (min-width: @screen-ms-min) {
        .select2.select2-container {
          padding-right: 1rem;
          padding-left: auto;
        }

        .select2-container.select2-container--open {
          .select2-dropdown {
          }
          .select2-results__option {
            padding-left: auto;
            padding-right: 1rem;
          }
        }
      }
      @media (min-width: @screen-sm-min) {
        .select2.select2-container {
          padding-right: 1rem;
          padding-left: auto;
        }

        .select2-container.select2-container--open {
          .select2-dropdown {
          }
          .select2-results__option {
            padding-right: 1rem;
            padding-left: auto;
          }
        }
      }
      @media (min-width: @screen-md-min) {
        .select2.select2-container {
          padding-right: 2rem;
          padding-left: auto;
        }

        .select2-container.select2-container--open {
          .select2-dropdown {
          }
          .select2-results__option {
            padding-right: 2rem;
            padding-left: auto;
          }
        }
      }
      @media (min-width: @screen-lg-min) {
        .select2.select2-container {
          padding-right: 1rem;
          padding-left: auto;
        }

        .select2-container.select2-container--open {
          .select2-dropdown {
          }
          .select2-results__option {
            padding-right: 1rem;
            padding-left: auto;
          }
        }
      }
      input.has-error ~ .inner-icon,
      input.has-error ~ .outside-icon {
        margin-right: 0;
        margin-left: 2rem;
      }
      .checkbox,
      .radio {
        .alert-label {
          padding-left: 0 !important;
          padding-right: 1.5rem !important;
          @media (min-width: @screen-md-min) {
            padding-right: 2rem !important;
          }
          float: left !important;
        }
      }
    }
    .form {
      .select2.select2-container {
        text-align: right;
      }
      //select2
      text-align: right;
      .select2.select2-container {
        .select2-selection--single {
          .select2-selection__rendered {
            text-align: right;
          }
        }
      }
      .select2-container.select2-container--open {
        right: 0;
        left: auto;
        .select2-dropdown {
          text-align: right;
          right: 0;
          left: auto;
        }
        .select2-results__option {
          text-align: right;
          font-weight: 300;
        }
        .select2-results__option--highlighted {
          text-align: right;
        }
        .select2-results__option[aria-selected=true] {
          text-align: right;
        }
        .select2-container--focus {
          text-align: right;
          border-left: 0;
          img {
            margin-left: 1rem;
            margin-right: -4px;
          }
        }
        .select2-results__options {
          //max-height: 100%;
          text-align: right;
        }
      }
      .select2-container--default .select2-selection--single .select2-selection__arrow b {
        //margin-left: 28px;
      }
      @media (min-width: @screen-xs-min) {
      }
      @media (min-width: @screen-ms-min) {
        .select2.select2-container {
          padding-right: 1rem;
          padding-left: auto;
        }

        .select2-container.select2-container--open {
          .select2-dropdown {
          }
          .select2-results__option {
            padding-left: auto;
            padding-right: 1rem;
          }
        }
      }
      @media (min-width: @screen-sm-min) {
        .select2.select2-container {
          padding-right: 1rem;
          padding-left: auto;
        }

        .select2-container.select2-container--open {
          .select2-dropdown {
          }
          .select2-results__option {
            padding-right: 1rem;
            padding-left: auto;
          }
        }
      }
      @media (min-width: @screen-md-min) {
        .select2.select2-container {
          padding-right: 2rem;
          padding-left: auto;
        }

        .select2-container.select2-container--open {
          .select2-dropdown {
          }
          .select2-results__option {
            padding-right: 2rem;
            padding-left: auto;
          }
        }
      }
      @media (min-width: @screen-lg-min) {
        .select2.select2-container {
          padding-right: 1rem;
          padding-left: auto;
        }

        .select2-container.select2-container--open {
          .select2-dropdown {
          }
          .select2-results__option {
            padding-right: 1rem;
            padding-left: auto;
          }
        }
      }
      input.has-error ~ .inner-icon,
      input.has-error ~ .outside-icon {
        margin-right: 0;
        margin-left: 2rem;
      }
      .checkbox,
      .radio {
        text-align: right;
        .alert-label {
          padding-left: 0 !important;
          padding-right: 1.5rem !important;
          @media (min-width: @screen-md-min) {
            padding-right: 2rem !important;
          }
          float: left !important;
        }
      }
      .form-section{
        &.forgot{
          text-align: center;
        }
      }
    }
    .form .inputfile + label svg {
      vertical-align: middle;
      fill: currentColor;
      margin-top: 8%;
      margin-right: 1em;
      margin-left: 0;
      float: left;
      text-align: center;
    }
    @media (min-width: @screen-lg-min) {
      .form .inputfile + label svg {
        margin-top: 12%;
      }
    }
    @media (min-width: @screen-md-min) {
      .pull-right-ar {
        .btn.btn-default {
          margin-left: 0;
          margin-right: 1.625rem;
        }
      }
    }
    .form > .form-section .floating-label-input span.outside-icon, .form .row > .form-section .floating-label-input span.outside-icon {
      top: 33%;
      left: 4%;
      right: auto;
      vertical-align: middle;
    }
  }
}
@media (max-width: @screen-sm-min) {
  .form .upload-files-container{
    &.download-files{
      .download-file-tile{
        align-items: flex-start;
        label{
          padding: 0 1rem;
        }
      }
    }
    .upload-files-content{
      justify-content: flex-start;
      flex-direction: column;
    }
  }
}
//End custome dropdown with icons
/*
* Business Variant
*/
.business {
  //.form{
  //.form-section {
  .floating-label-input,
  .floating-label-select {
    input,
    label {
      color: @dark-green;
    }
  }
  // svg inside
  .floating-label-input {
    span {
      svg {
        height: 25px;
        width: 25px;
        fill: @dark-green;
      }
    }
  }
  .select2.select2-container {
    .select2-selection--single {
      .select2-selection__rendered {
        color: @dark-green;
      }
    }
    .select2-selection__arrow {
      b {
        border-color: @dark-green transparent transparent;
      }
    }
    &.select2-container--open .select2-selection--single .select2-selection__arrow {
      b {
        border-color: transparent transparent @dark-green;
      }
    }
  }
  .select2-container.select2-container--open {
    .select2-results__option--highlighted {
      color: @dark-green;
    }
    .select2-results__option[aria-selected=true] {
      color: @dark-green;
    }
  }
  //}
  //}
  .form{
    .upload-files-container{
      &.download-files{
        .download-file-tile{
          padding-left: 0;
          border: 1px solid @cold-gray;
          h5{
            color: @dark-green;

          }
        }
      }
      .upload-files-content{
        h5{

          svg{
            fill: @dark-green;
          }
        }
        .inputfile-label{
          color: @dark-green;
        }
        .inputfile+label, label{
          color: @dark-green;
          svg{
            g{
              stroke: @dark-green;
            }
          }
        }
      }
    }

  }
}

.spinbox {
  display: flex;
  width: 11rem;
  height: 3rem;
  margin: 0 auto 3rem;
  input {
    display: block;
    width: 5rem;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    font-size: 1rem;
    color: @dark-gray;
    border: none;
    outline: none;
    background-color: @white;
  }
  .spinminus,
  .spinplus {
    display: block;
    width: 3rem;
    height: 3rem;
    background-color: @main-green;
    cursor: pointer;
    svg {
      box-sizing: content-box;
      width: 1.5rem;
      height: 1.5rem;
      padding: 0.75rem;
    }
    &.disabled {
      background-color: @cold-gray;
    }
  }
}

.business {
  .spinbox {
    .spinminus,
    .spinplus {
      background-color: @dark-green;
    }
  }
}

@media (min-width: @screen-sm-min) {
  .spinbox {
    margin: 0;
  }
}

@media (min-width: @screen-lg-min) {
  .spinbox {
    width: 15rem;
    height: 4rem;
    input {
      width: 7rem;
      height: 4rem;
      line-height: 4rem;
      font-size: 1.5rem;
    }
    .spinminus,
    .spinplus {
      width: 4rem;
      height: 4rem;
      svg {
        width: 2rem;
        height: 2rem;
        padding: 1rem;
      }
    }
  }
}

*[lang="ar"] {
  *[dir="rtl"] {
    .form .floating-label-input.floating-label-prefix,
    .form .floating-label-select.floating-label-prefix {
      margin-left: 1.5rem;
      margin-right: auto;
    }
    .form .floating-label-input.has-error-fields .alert-icon svg,
    .form .floating-label-input.had-error-fields .alert-icon svg,
    .form .floating-label-select.has-error-fields .alert-icon svg {
      left: 1.125rem;
      right: auto;
    }
    @media (min-width: @screen-lg-min) {
      .form .floating-label-input.floating-label-prefix,
      .form .floating-label-select.floating-label-prefix {
        margin-left: 1.25rem;
        margin-right: auto;
      }

      .form .floating-label-input.has-error-fields .alert-icon svg,
      .form .floating-label-input.had-error-fields .alert-icon svg,
      .form .floating-label-select.has-error-fields .alert-icon svg {
        left: 0.9375rem;
        right: auto;
      }
    }
    .form input.has-error ~ .inner-icon {
      margin-right: 2rem;
    }
    .form .inner-icon {
      left: 1rem;
      right: auto;
    }

    .form {
      .floating-label-input {
        &.has-error-fields {
          label {
            padding-left: 2rem*1.25;
            padding-right: 1.25rem*1.33;
          }
          @media (min-width: @screen-lg-min) {
            label {
              padding-left: 2rem*1.5;
              padding-right: 1.5rem*1.33;
            }
          }
        }
      }
      .floating-label-select {
        label {
          padding-left: 2rem*1.25;
          padding-right: 1.25rem*1.33;
        }
        @media (min-width: @screen-lg-min) {
          label {
            padding-left: 2rem*1.5;
            padding-right: 1.5rem*1.33;
          }
        }
        &.has-error-fields {
          .alert-icon svg {
            left: 2rem*1.25;
            right: auto;
          }
          label {
            padding-left: 3.5rem*1.25;
            padding-right: 1.25rem*1.33;
          }
          @media (min-width: @screen-lg-min) {
            .alert-icon svg {
              left: 2rem*1.5;
              right: auto;
            }

            label {
              padding-left: 3.5rem*1.5;
              padding-right: 1.5rem*1.33;
            }
          }
        }
      }
      .datepicker {
        & ~ label {
          padding-left: 2.5rem*1.25;
          padding-right: 1.25rem*1.33;
        }
        @media (min-width: @screen-lg-min) {
          & ~ label {
            padding-left: 2.5rem*1.5;
            padding-right: 1.5rem*1.33;
          }
        }
      }
      .select2-container--default .select2-selection--single .select2-selection__arrow {
        //left: 1px;
        left: 1.25rem*1.33;
        right: auto;
      }
      @media (min-width: @screen-lg-min) {
        .select2-container--default .select2-selection--single .select2-selection__arrow {
          left: 1.5rem*1.33;
        }
      }
    }

  }
}

// if a datepicker field has validation error, then move to the left the calendar icon to make room for the (i) icon
.form {
  input.datepicker.has-error ~ .inner-icon .datepicker-svg {
    //margin-right: 40px;
  }
  input.has-error ~ .inner-icon,
  input.has-error ~ .outside-icon {
    margin-right: 2rem;
  }
}

.form-submitting {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 12rem;
  margin: 0;
  padding: 0;
  text-align: center;
}

@form-submitted-line: 1.5rem;
@media (max-width: @screen-lg-min) {
  @form-submitted-line: 1.25rem;
}

.business-register-form-submitted,
.capture-lead-form-submitted,
.consumer-register-form-submitted,
.form-submitted,
.notify-me-form-submitted,
.reset-password-form-submitted {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: @form-submitted-line * 8;
  margin: 2.2rem 0 0 0;
  padding: 0;
  text-align: center;
  color: @white;
  background-color: @main-green;
  svg.order-success {
    fill: @white;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }
  &.error {
    background-color: @pink;
    &.red{
      background-color: @pink;
    }
    & h3 {
      margin:1rem;
    }
  }
  &.warning {
    background-color: @yellow;
    &.yellow{
      background-color: @yellow;
    }
    & h3 {
      margin:1rem;
    }
  }
}

.business {
  .business-register-form-submitted,
  .capture-lead-form-submitted,
  .consumer-register-form-submitted,
  .form-submitted,
  .notify-me-form-submitted,
  .reset-password-form-submitted {
    background-color: @dark-green;
    &.error {
      background-color: @dark-orange;
    }
    &.alert-yellow {
      background-color: @yellow; color:@almost-black;
    }
  }
}

// rules for manual validation for Business Register Contact Validated A
//#business-register-contact-validated {
//  .form-group {
//    .has-error.alert-label {
//      display: none;
//    }
//
//    &.has-error-fields {
//      .has-error.alert-label {
//        display: block;
//      }
//    }
//  }
//}

//remove x from explorer inputs
input::-ms-clear {
  display: none !important;
}


//kill the autofill

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  background-color: @white !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
@media (max-width: @screen-md-min) {
  .search-your-number{
    .form-section{
      input {
        padding:10px
      }
    }
  }
}
/*custom error alignment 17sept17*/
#internetUserIdDiv .customError, #elifeuseridDiv .customError{
  color: #fb193f;
  line-height: 1rem;
  height: 1rem;
  margin: 0;
  padding: 0;
  width: 100%;
  position: absolute;
  top: -1.5rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: .75rem;
  line-height: 1.6 em;
}
//error emssage mobile fix for padding on left and right
@media (max-width: @screen-ms-max) {
  .business-register-form-submitted.error h3, .capture-lead-form-submitted.error h3, .consumer-register-form-submitted.error h3, .form-submitted.error h3, .notify-me-form-submitted.error h3, .reset-password-form-submitted.error h3 {
    width: 90%;
  }
}

[lang="ar"] {
  [dir="rtl"] {
    //book your number refresh button margins reset
    .commonClass {
      .pull-right-ar {
        .btn.btn-default {
          margin-right: 0 !important;
        }
        .expantion-form-submit {
          margin-right: 0;
          margin-left: 1rem !important;
        }
      }
    }

    .pac-container>.pac-item .pac-item-query+span {
      margin-left: .2rem;
      margin-right: 5.2rem;
    }
    .pac-item {
      text-align: right !important;
    }
  }
}

.formtoappend {
  position: relative;
  .pac-container {
    top: 47px !important;
    left: 0px !important;
  }
}
