.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;

  .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    user-select: none;
    -webkit-user-select: none;

    .select2-selection__rendered {
      display: block;
      padding-left: 8px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .select2-selection__clear {
      position: relative;
    }
  }

  &[dir="rtl"] {
    .select2-selection--single {
      .select2-selection__rendered {
        padding-right: 8px;
        padding-left: 20px;
      }
    }
  }

}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;

  &[aria-selected] {
    cursor: pointer;
  }
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;

  .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  &.select2-search--hide {
    display: none;
  }
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  // styles required for IE to work
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.select2-container--default {
  .select2-selection--single {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;

    .select2-selection__rendered {
      color: #444;
      line-height: 28px;
    }

    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
    }

    .select2-selection__placeholder {
      color: #999;
    }

    .select2-selection__arrow {
      height: 26px;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 20px;

      b {
        border-color: #888 transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -0.6rem;
        position: absolute;
        top: 50%;
        width: 0;
      }
    }
  }

  &[dir="rtl"] {
    .select2-selection--single {
      .select2-selection__clear {
        float: left;
      }

      .select2-selection__arrow {
        left: 1px;
        right: auto;
      }
    }
  }

  &.select2-container--disabled {
    .select2-selection--single {
      background-color: #eee;
      cursor: default;

      .select2-selection__clear {
        display: none;
      }
    }
  }

  &.select2-container--open {
    .select2-selection--single {
      .select2-selection__arrow {
        b {
          border-color: transparent transparent #888 transparent;
          border-width: 0 4px 5px 4px;
        }
      }
    }
  }

  &.select2-container--open.select2-container--above {
    .select2-selection--single, .select2-selection--multiple {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &.select2-container--open.select2-container--below {
    .select2-selection--single, .select2-selection--multiple {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .select2-search--dropdown {
    .select2-search__field {
      border: 1px solid #aaa;
    }
  }

  .select2-search--inline {
    .select2-search__field {
      background: transparent;
      border: none;
      outline: 0;
      box-shadow: none;
      -webkit-appearance: textfield;
    }
  }

  .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
  }

  .select2-results__option {
    &[role=group] {
      padding: 0;
    }

    &[aria-disabled=true] {
      color: #999;
    }

    &[aria-selected=true] {
      background-color: #ddd;
    }

    .select2-results__option {
      padding-left: 1em;

      .select2-results__group {
        padding-left: 0;
      }

      .select2-results__option {
        margin-left: -1em;
        padding-left: 2em;

        .select2-results__option {
          margin-left: -2em;
          padding-left: 3em;

          .select2-results__option {
            margin-left: -3em;
            padding-left: 4em;

            .select2-results__option {
              margin-left: -4em;
              padding-left: 5em;

              .select2-results__option {
                margin-left: -5em;
                padding-left: 6em;
              }
            }
          }
        }
      }
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: #5897fb;
    color: white;
  }

  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }
}
