// .back-to-top{
//   position: relative;
//   display: none;
//    a{
//     display: block;
//     z-index: 99;
//     position: fixed;
//     right: 3%;
//     bottom: 10%;
//     background: @main-green;
//     width: 48px;
//     height: 48px;
//     text-align: center;
//     line-height: 48px;
//     border-radius: 50%;
//     -webkit-box-shadow: 0px 0px 25px 0px rgba(124,124,124,0.7);
//     box-shadow: 0px 0px 25px 0px rgba(124,124,124,0.7);
//     @media (max-width: 767px){
//       right: 6%;
//       bottom: 6%;
//     }
//     svg {
//         fill: @white;
//         width: 24px;
//         height: 48px;
//     }
//   }
// }
//
// *[lang="ar"] {
//   *[dir="rtl"] {
//     .back-to-top{
//        a{
//         right: auto;
//         left: 3%;
//         @media (max-width: 767px){
//           left: 6%;
//         }
//       }
//     }
//   }
// }
#return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: rgb(0, 0, 0);
    background: @main-green;
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.25);
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.25);
    z-index: 1;
    opacity: 0.7;
}
#return-to-top svg {
    margin: 0;
    position: relative;
    left: 9px;
    top: 14px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  filter: drop-shadow
}
#return-to-top:hover {
    background: rgba(79, 105, 49, 1);
    opacity: 1;
}
#return-to-top:hover svg {
    top: 10px;
}
*[lang="ar"] {
  *[dir="rtl"] {
    #return-to-top {
        right: auto;
        left: 20px;
    }
    #return-to-top svg {
        left: auto;
        right: 9px;
    }
  }
}
