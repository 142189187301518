.top-nav-notification {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: @almost-black;
  z-index: 9999;
  display: inline-block;
  height: auto;
  p {
    position: relative;
    transform:translateX(-50%);
    left: 50%;
    margin-bottom: 0;
    color: @white;
    padding: 6px 0;
    font-size: .875rem;
    display: inline-block;
    text-align: center;
  }
  .close-notification {
    width: .875rem;
    height: .875rem;
    stroke: @white;
    display: block;
    fill: @white;
    position: absolute;
    right: 4.1666665%;
    transform: translateX(50%);
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
  }
  .row {
    display: flex;
  }
  a {
    color: @light-green;
    margin-left: 8px;
    &:hover {
      text-decoration: underline;
    }
    .read-more-notification {
      position: relative;
      display: inline-block;
      fill: @light-green;
      stroke: @light-green;
      stroke-width: 12px;
      width: .5rem;
      height: .625rem;
      top: 8px;
      vertical-align: top;
      transform: translateY(-1px);
    }
  }
}
.notification-placeholder {
  position: relative;
  width: 100%;
  display: block;
}

@media (min-width: @screen-ml-min) {
  .top-nav-notification {
    .close-notification {
      right: 12.499999995%;
    }
  }
}

@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
  .top-nav-notification {
    .close-notification {
      right: -2rem;
    }
  }
}
@media (min-width: @screen-ms-max) {
  .top-nav-notification {
    p {
      font-size: .75rem;
      line-height: 1.4;
    }
    a {
      .read-more-notification {
        top: 6px;
        height: .5rem;
      }
    }
  }
}

[lang="ar"] [dir="rtl"] {
  .top-nav-notification {
    .close-notification {
      right: auto;
      left: 0;
    }
    p {
      left: auto;
      right: 50%;
      transform:  translateX(50%);
    }
    a .read-more-notification {
      top: 6px;
      transform: rotate(180deg) translateX(6px);
    }
  }
}
