///* 3D Container */
//.swiper-container-3d {
//    -webkit-perspective: 1200px;
//    -moz-perspective: 1200px;
//    -o-perspective: 1200px;
//    perspective: 1200px;
//    .swiper-wrapper, .swiper-slide, .swiper-slide-shadow-left, .swiper-slide-shadow-right, .swiper-slide-shadow-top, .swiper-slide-shadow-bottom, .swiper-cube-shadow {
//        .preserve3d();
//    }
//    .swiper-slide-shadow-left, .swiper-slide-shadow-right, .swiper-slide-shadow-top, .swiper-slide-shadow-bottom {
//        position: absolute;
//        left: 0;
//        top: 0;
//        width: 100%;
//        height: 100%;
//        pointer-events: none;
//        z-index: 10;
//    }
//    .swiper-slide-shadow-left {
//        background-image: -webkit-gradient(linear, left top, right top, from(rgba(0,0,0,0.5)), to(rgba(0,0,0,0))); /* Safari 4+, Chrome */
//        background-image: -webkit-linear-gradient(right, rgba(0,0,0,0.5), rgba(0,0,0,0)); /* Chrome 10+, Safari 5.1+, iOS 5+ */
//        background-image:    -moz-linear-gradient(right, rgba(0,0,0,0.5), rgba(0,0,0,0)); /* Firefox 3.6-15 */
//        background-image:      -o-linear-gradient(right, rgba(0,0,0,0.5), rgba(0,0,0,0)); /* Opera 11.10-12.00 */
//        background-image:         linear-gradient(to left, rgba(0,0,0,0.5), rgba(0,0,0,0)); /* Firefox 16+, IE10, Opera 12.50+ */
//    }
//    .swiper-slide-shadow-right {
//        background-image: -webkit-gradient(linear, right top, left top, from(rgba(0,0,0,0.5)), to(rgba(0,0,0,0))); /* Safari 4+, Chrome */
//        background-image: -webkit-linear-gradient(left, rgba(0,0,0,0.5), rgba(0,0,0,0)); /* Chrome 10+, Safari 5.1+, iOS 5+ */
//        background-image:    -moz-linear-gradient(left, rgba(0,0,0,0.5), rgba(0,0,0,0)); /* Firefox 3.6-15 */
//        background-image:      -o-linear-gradient(left, rgba(0,0,0,0.5), rgba(0,0,0,0)); /* Opera 11.10-12.00 */
//        background-image:         linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0)); /* Firefox 16+, IE10, Opera 12.50+ */
//    }
//    .swiper-slide-shadow-top {
//        background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0.5)), to(rgba(0,0,0,0))); /* Safari 4+, Chrome */
//        background-image: -webkit-linear-gradient(bottom, rgba(0,0,0,0.5), rgba(0,0,0,0)); /* Chrome 10+, Safari 5.1+, iOS 5+ */
//        background-image:    -moz-linear-gradient(bottom, rgba(0,0,0,0.5), rgba(0,0,0,0)); /* Firefox 3.6-15 */
//        background-image:      -o-linear-gradient(bottom, rgba(0,0,0,0.5), rgba(0,0,0,0)); /* Opera 11.10-12.00 */
//        background-image:         linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0)); /* Firefox 16+, IE10, Opera 12.50+ */
//    }
//    .swiper-slide-shadow-bottom {
//        background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0,0,0,0.5)), to(rgba(0,0,0,0))); /* Safari 4+, Chrome */
//        background-image: -webkit-linear-gradient(top, rgba(0,0,0,0.5), rgba(0,0,0,0)); /* Chrome 10+, Safari 5.1+, iOS 5+ */
//        background-image:    -moz-linear-gradient(top, rgba(0,0,0,0.5), rgba(0,0,0,0)); /* Firefox 3.6-15 */
//        background-image:      -o-linear-gradient(top, rgba(0,0,0,0.5), rgba(0,0,0,0)); /* Opera 11.10-12.00 */
//        background-image:         linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0)); /* Firefox 16+, IE10, Opera 12.50+ */
//    }
//}
///* Coverflow */
//.swiper-container-coverflow, .swiper-container-flip {
//    .swiper-wrapper {
//        /* Windows 8 IE 10 fix */
//        -ms-perspective:1200px;
//    }
//}
///* Cube + Flip */
//.swiper-container-cube, .swiper-container-flip {
//    overflow: visible;
//    .swiper-slide {
//        pointer-events: none;
//        -webkit-backface-visibility: hidden;
//        -moz-backface-visibility: hidden;
//        -ms-backface-visibility: hidden;
//        backface-visibility: hidden;
//        z-index: 1;
//        .swiper-slide {
//            pointer-events: none;
//        }
//    }
//    .swiper-slide-active {
//        &, & .swiper-slide-active {
//            pointer-events: auto;
//        }
//    }
//    .swiper-slide-shadow-top, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left, .swiper-slide-shadow-right {
//        z-index: 0;
//        -webkit-backface-visibility: hidden;
//        -moz-backface-visibility: hidden;
//        -ms-backface-visibility: hidden;
//        backface-visibility: hidden;
//    }
//}
///* Cube */
//.swiper-container-cube {
//    .swiper-slide {
//        visibility: hidden;
//        -webkit-transform-origin: 0 0;
//        -moz-transform-origin: 0 0;
//        -ms-transform-origin: 0 0;
//        transform-origin: 0 0;
//        width: 100%;
//        height: 100%;
//    }
//    &.swiper-container-rtl .swiper-slide{
//        -webkit-transform-origin: 100% 0;
//        -moz-transform-origin: 100% 0;
//        -ms-transform-origin: 100% 0;
//        transform-origin: 100% 0;
//    }
//    .swiper-slide-active, .swiper-slide-next, .swiper-slide-prev, .swiper-slide-next + .swiper-slide {
//        pointer-events: auto;
//        visibility: visible;
//    }
//    .swiper-cube-shadow {
//        position: absolute;
//        left: 0;
//        bottom: 0px;
//        width: 100%;
//        height: 100%;
//        background: #000;
//        opacity: 0.6;
//        -webkit-filter: blur(50px);
//        filter: blur(50px);
//        z-index: 0;
//    }
//}
/* Fade */
.swiper-container-fade {
    &.swiper-container-free-mode {
        .swiper-slide {
            -webkit-transition-timing-function: ease-out;
            -moz-transition-timing-function: ease-out;
            -ms-transition-timing-function: ease-out;
            -o-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
        }
    }
    .swiper-slide {
        pointer-events: none;
        -webkit-transition-property: opacity;
        -moz-transition-property: opacity;
        -o-transition-property: opacity;
        transition-property: opacity;
        .swiper-slide {
            pointer-events: none;
        }
    }
    .swiper-slide-active {
        &, & .swiper-slide-active {
            pointer-events: auto;
        }
    }
}
