.footer{
  margin-top: 32px;
  .social-icon-wrapper{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    .social-icon-spacer{
      height: 0;
      width: 100%;
    }
    a{
      width: 44px;
      height: 44px;
      background-color: @sandy-white;
      .rounded(50%);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      svg {
        fill: @black;
        width:50%;
        max-width:64px;
      }
      img {
        width:50%;
        max-height: 50%;
      }

      &:hover {
        background-color: @main-green;
        svg {
          fill: @white;
          path {
            fill:@white;
          }
        }
      }
    }

    .social-icon{
      min-width: 20%;
      display: flex;
      justify-content: center;
    }

    .more-less-button {
      display: none;
      a:hover {
        background-color: @main-green;
        svg {
          fill: @white;
        }
      }
    }

    .show-after{
      display: flex;
      padding-top: 2rem;
    }
    .show-now{
      display: flex;
    }
  }

  .social-icon-wrapper {

    .more-less-button.active {
      a{
        background-color: @warm-gray;
        svg {
          fill: @white;
        }
        #social-more-img {
          display: none;
        }
        #social-less-img {
          display: inherit;
        }
      }
    }
  }

  .etisalat-signature{

    margin-top: 40px;
    text-align: center;

    .etisalat-logo-img{
      width:136.6px;
      height:34.1px;
    }

    .copyright{
      padding-top: 26px;
      color: @gray;
      margin-bottom: 50px;
    }
  }

  .footer-nav{
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0px 0 30px 0;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    a{
      border-left: 2px solid @white-2;
      padding-left: 0.68rem;
      padding-right: 0.68rem;
      color: @warm-gray;
      font-size: 0.75rem;
      margin-bottom: 1rem;
      display: inline-block;
      position: relative;
      letter-spacing: 0.7px;
      &:after{
        height: 100%;
        right: -2px;
        width: 2px;
        content: " ";
        background-color: @white-2;
        position: absolute;
      }
    }
    a:hover,
    .active a{
      color: @main-green;
      text-decoration: underline;
    }
  }

  .footer-dinaymic-box {
    display: flex;
    flex-direction: column;
    .row-social, .row-nav {
      width: 100%;
    }
    .row-nav{
      order:1;
    }
    .row-social{
      order:2;
    }
  }
  a{
    &.expo-logo{
      width: 172px;
      height: 97px;
      display: inline-block;
      border-left: 2px solid #898A8B;
      padding-left: 1rem;
      margin-left: 1rem;
      img{
        width: 100%;
        height: 100%;
      }
      
    }
  }
  .offial-text{
    color: @almost-black;
    font-size: 0.75rem;
  }

}

.footer-small {
  margin-top: 0 !important;
  padding: 20px 0;
  .etisalat-logo-img {
    width: 87px !important;
    margin-top: 0 !important;
  }
  .right-n {
    text-align: right!important;
    margin-top: 0 !important;
    margin-bottom: 0 ;
  }
  .left-n {
    text-align: left;
    margin-top: 0 !important;
    margin-bottom: 0 ;
  }
  .body-small {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 12px;
  }
  .footer-nav-small {
    display: inline-block !important;
    margin-top: 15px !important;
    margin-bottom: 0 !important;
    li {
      display: inline;
      a {
        border-right: 2px solid #e5e5e5;
        border-left: none;
      }
    }
  }
}
.selected-items {
  display: none;
}
@media (min-width: @screen-sm-min) {
  .footer {
    margin-top: 57px;

    .social-icon-wrapper {
      justify-content: space-between;
      a {
        width: 48px;
        height: 48px;
      }
      .more-less-button {
        display: none;
      }
      .show-after{
        display: inherit;
        padding-top:0rem;
      }
      .social-icon-spacer{
        display: none;
      }
      .social-icon{
        min-width: 1%;
      }
    }
    .etisalat-signature{
      margin-top: 20px;
      .etisalat-logo-img{
        width: 147px;
        height: 37px;
      }
      .copyright{
        padding-top: 24px;
        margin-bottom: 53px;
      }
    }
    .footer-nav {
      flex-direction: row;
      margin-top: 42px;
      a {
        border-left: 1px solid @white-2;
        padding-right: 1.25rem;
        padding-left: 1.25rem;
        margin-bottom: 0px;
        font-size: @body-smallest-sm;
        &:after{
          display: none;
        }
      }
      li:last-child a{
        border-right: 1px solid @white-2;
      }
    }
    .footer-dinaymic-box {
      .row-nav{
        order: 2;
      }
      .row-social{
        order: 1;
      }
    }
  }
  .footer-small {
    .right-n {
      text-align: right !important;
    }
    .left-n {
      text-align: left !important;
    }
  }
}

@media (min-width: @screen-lg-min) {
  .footer {
    margin-top: 50px;
    .social-icon-wrapper {
      a {
        width: 65px;
        height: 65px;
        svg {
          width:50%;
        }
        img {
          width:50%;
        }
      }
    }

    .etisalat-signature{
      margin-top: 24px;
      .etisalat-logo-img{
        width: 147px;
        height: 37px;
      }
      .copyright{
        padding-top: 30px;
        margin-bottom: 80px;
      }
    }
    .footer-nav {
      margin-top: 40px;
      a {
        font-size: @body-small-lg;
        padding-right: 2.5625rem;
        padding-left: 2.5625rem;
      }
    }
  }
}

/*
* Business Variant
*/
.business {
  .footer{
    .social-icon-wrapper{
      a{
        &:hover {
          background-color: @dark-green;
        }
      }
      .more-less-button.active {
        a{
          background-color: @warm-gray;
        }
      }
    }
    .footer-nav{
      a:hover,
      .active a{
        color: @dark-green;
      }
    }
  }
  @media (max-width: @screen-sm) {
    .footer-small {
      .center-xs-sm,
      .right-n {
        text-align: center ;
      }
    }
  }
}
html[lang="ar"] {
  *[dir="rtl"] {
    .footer{
      a{
        &.expo-logo{
          width: 172px;
          height: 97px;
          display: inline-block;
          border-right: 2px solid #898A8B;
          padding-right: 1rem;
          margin-right: 1rem;
          border-left: none;
          padding-left: 0px;
          margin-left:0px; 
          img{
            width: 100%;
            height: 100%;
          }

        }
      }
    }
  }
}

