#check-availability-map {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; //  height: 400px;
}
@media (min-width: @screen-lg-min) {
  #check-availability-map {
    //  height: 512px;
  }
}
// start common style for search - check
.search-group {
  select {
    width: 100%;
  }
  .search-btn {
    background-color: rgba(132, 135, 137, 0.1);
    border: 0;
  }
  .search-style {
    #pac-input {
      padding-right: 18%;
    }
    align-items: center;
    display: flex;
    justify-content: center;
    input {
      flex: 1 2 80%;
      &:active:after {
        height: 20px;
        content: "CHECK";
      }
    }
    & > span {
      display: none;
      flex: 2 1 20%;
      position: absolute;
      right: 0;
      top:0;
      height: 100%;
      a {
        color: @dark-gray;
      }
      &.clear-check-seach-btn {
        svg {
          height: 15px;
          width: 15px;
          margin-top: 1.5rem;
          fill: @texts-gray;
        }
      }
    }
  }
}
@media (max-width: @screen-xs) {
  .search-group {
    select {}
    .search-btn {}
    .search-style {
      #pac-input {
        padding-right: 28%;
      }
      input {}
      span {
        .btn {
          padding: 0 1rem;
        }
        a {
          color: @dark-gray;
          line-height: 3rem;
        }
        &.clear-check-seach-btn {
          svg {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
@media (min-width: @screen-xs) and (max-width: @screen-ms-max) {
  .search-group {
    select {}
    .search-btn {}
    .search-style {
      #pac-input {
        padding-right: 28%;
      }
      input {}
      span {
        .btn {
          padding: 0 1rem;
        }
        a {
          color: @dark-gray;
          line-height: 3rem;
        }
        &.clear-check-seach-btn {
          svg {
            margin-top: 0.906rem;
          }
        }
      }
    }
  }
}
@media (min-width: @screen-sm-min) {
  .search-group {
    select {}
    .search-btn {}
    .search-style {
      #pac-input {
        padding-right: 20%;
      }
      input {}
      span {
        .btn {
          padding: 0 1rem;
        }
        a {
          color: @dark-gray;
          line-height: 3rem;
        }
        &.clear-check-seach-btn {
          svg {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
@media (min-width: @screen-md-min) {
  .search-group {
    select {}
    .search-btn {}
    .search-style {
      #pac-input {
        padding-right: 20%;
      }
      input {}
      span {
        .btn {
          padding: 0 1rem;
          outline: none;
        }
        a {
          color: @dark-gray;
          line-height: 3rem;
        }
        &.clear-check-seach-btn {
          svg {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
@media (min-width: @screen-lg-min) {
  .search-group {
    select {}
    .search-btn {}
    .search-style {
      #pac-input {
        padding-right: 18%;
      }
      input {}
      span {
        right: 0;
        top: 0;
        .btn {
          padding: 0 1.5rem;
        }
        a {
          color: @dark-gray;
          line-height: 4rem;
        }
        &.clear-check-seach-btn {
          svg {
            margin-top: 1.7rem;
          }
        }
      }
    }
  }
}
// end common style for search - check
.check-availability-wrap {
  &.check-eligibility {
    .map-container {
      //display: none;
    }
  }
  .tiles-content {
    position: relative;
    width: 100%;
    &:after {
      content: " ";
      background-color: fade(@main-green, 70);
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
    }
    div.cover {
      left: 0;
      z-index: 1;
      top: 0;
    }
    .check-availability-header {
      z-index: 3;
      position: relative;
      width: 100%;
      padding: 0 0 2rem 0;
      h2 {
        color: @white;
        text-transform: uppercase;
        text-align: center;
        white-space: nowrap;
        letter-spacing: 0;
        overflow: visible;
        margin-top: 1.68rem;
      }
      p {
        color: @white;
        text-align: center;
        &.business-note {
          text-transform: none !important;
          letter-spacing: normal !important;
        }
      }
      .my-current-location {
        display: flex;
        align-items: center;
        padding: 1rem;
        svg {
          height: 18px;
          width: 14px;
          margin-right: 5px;
        }
        a {
          color: @white;
          font-size: 1rem;
          letter-spacing: 1.1px;
          text-decoration: underline;
        }
      }
    }
  }
  .map-container {
    position: relative;
    > .background-map {
      min-height: 200px;
      height: auto;
      position: relative;
      &.closed {
        height: 200px;
      }
      &.opened {
    //    height: 400px;
      }
      .main-map-result-container {
        position: relative;
        min-height: 150px;
        height: 100%;
        display: -webkit-box;
        overflow: hidden;
        .check-validation-result {
          position: relative;
          min-height: 150px;
          margin-top: 12.5rem;
          padding-top: 2.25rem;
          padding-bottom: 2rem;
          background-color: hsla(0, 0%, 100%, .8);
          box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.2);
          .availability-header {
            h3 {
              letter-spacing: 0;
              text-transform: uppercase;
              align-items: baseline;
              display: flex;
              justify-content: center;
              svg {
                height: 25px;
                width: 25px;
                margin: auto 0.5rem;
                stroke: none;
              }
            }
            p {
              color: @dark-gray;
              margin: 1rem 0 3rem;
            }
            button {
              margin: 1rem auto 3rem;
            }
            .available-error,
            .available-false,
            .available-true {
              h3 {
                margin-top: 0;
                color: @main-green;
                svg {
                  stroke: none;
                }
              }
            }
            .available-false {
              h3 {
                color: @dark-orange;
              }
              a {
                color: @dark-gray;
                text-transform: uppercase;
                font-size: 0.875rem;
                svg {
                  margin-left: 12px;
                  width: 9px;
                  height: 10px;
                  margin-top: 1px;
                  transform: rotate(90deg);
                  use {
                    -moz-transform: scale(-1);
                  }
                }
              }
            }
          }
        }
      }
      .notify-me-box {
        background-color: @white;
        .form {
          padding-top: 3rem;
        }
      }
    }
  }
  .form-section {
    .checkbox {
      label {
        font-size: 0.875rem;
        color: @dark-gray;
        a {
          color: @dark-gray;
          text-decoration: underline;
        }
      }
    }
  }
  .notify-me-form-submitting {
    position: relative;
    p {
      letter-spacing: 0.2rem;
      text-transform: uppercase;
      position: absolute;
      width: 100%;
      left: 0;
      top: 50%;
      margin: 2rem 0 0;
    }
  }
  .notify-me-form-submit,
  .notify-me-form-submitting {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .form-submit {
    padding: 0;
    text-align: center;
    button {
      text-transform: uppercase;
      margin: auto 0.5rem;
    }
  }
}
@media (min-width: @screen-xs) and (max-width: @screen-ms-max) {
  .check-availability-wrap {
    .tiles-content {
      height: 280px;
      .check-availability-header {
        h2 {
          font-size: 1.625rem;
          letter-spacing: 2.2px;
        }
        p {
          font-size: 0.875rem;
        }
        .my-current-location {
          padding: 1rem;
          svg {
            height: 15px;
            width: 10px;
            margin-right: 10px;
          }
          a {
            font-size: 0.875rem;
          }
        }
      }
    }
    .map-container {
      > .background-map {
        min-height: 200px;
        .main-map-result-container {
          .check-validation-result {
            margin-top: 12.5rem;
            .availability-header {
              p {
                font-size: 0.875rem;
                margin: 1rem 0;
              }
              button {
                margin: 1rem auto;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: @screen-sm-min) {
  .check-availability-wrap {
    .tiles-content {
      height: 280px;
      .check-availability-header {
        h2 {
          font-size: 2.5rem;
          letter-spacing: 3.3px;
        }
        p {
          font-size: 0.875rem;
        }
        .my-current-location {
          padding-left: 0;
          svg {
            height: 15px;
            width: 10px;
            margin-right: 10px;
          }
          a {
            font-size: 0.875rem;
          }
        }
      }
    }
    .map-container {
      > .background-map {
        min-height: 200px;
        .main-map-result-container {
          .check-validation-result {
            margin-top: 9.5rem;
            .availability-header {
              p {
                font-size: 0.875rem;
                margin: 2rem 0;
              }
              button {
                margin: 1rem auto 2rem;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: @screen-md-min) {
  .check-availability-wrap {
    .tiles-content {
      height: 280px;
      .check-availability-header {
        h2 {
          font-size: 2.5rem;
          letter-spacing: 3.3px;
        }
        p {
          font-size: 0.875rem;
        }
        .my-current-location {
          padding-left: 0;
          svg {
            height: 15px;
            width: 10px;
            margin-right: 10px;
          }
          a {
            font-size: 0.875rem;
          }
        }
      }
    }
    .map-container {
      > .background-map {
        min-height: 200px;
        .main-map-result-container {
          .check-validation-result {
            margin-top: 9.5rem;
            .availability-header {
              p {
                font-size: 0.875rem;
                margin: 2rem 0;
              }
              button {
                margin: 1rem auto 2rem;
              }
            }
          }
        }
      }
    }
    .form-section {
      .checkbox {
        label {
          font-size: 0.75rem;
          a {}
        }
      }
    }
  }
}
@media (min-width: @screen-lg-min) {
  .check-availability-wrap {
    .tiles-content {
      height: 400px;
      .check-availability-header {
        h2 {
          letter-spacing: 8px;
          font-size: 3.375rem;
          margin-top: 3.625rem;
        }
        p {
          font-size: 1rem;
        }
        .search-group {
          .search-style {
            span {
              .btn {
                padding: 0 1.8125rem;
              }
            }
          }
        }
        .my-current-location {
          padding: 1rem;
          svg {
            height: 18px;
            width: 14px;
            margin-right: 5px;
          }
          a {
            font-size: 1rem;
            letter-spacing: 1.1px;
          }
        }
      }
    }
    .map-container {
      > .background-map {
        min-height: 400px;
        &.closed {
          height: 400px;
        }
        &.opened {
          //  height: 512px;
        }
        .main-map-result-container {
          .check-validation-result {
            margin-top: 12.5rem;
            .availability-header {
              padding-bottom: 2rem;
              p {
                font-size: 1rem;
                margin: 1rem 0 3rem;
              }
              button {
                margin: 1rem auto 3rem;
              }
            }
          }
        }
      }
    }
    .form-section {
      .checkbox {
        label {
          font-size: 0.875rem;
          a {}
        }
      }
    }
  }
}
/* Business
  -------------------------------------------------------------- */
.business {
  .form .search-group .form-group input.form-control {
    color: @dark-green;
  }
  .check-availability-wrap {
    .tiles-content {
      height: auto;
      &:after {
        background-color: rgba(56, 60, 58, .7);
      }
      .check-availability-header {
        .business-note {
          text-align: center;
          color: @white;
          text-transform: uppercase;
          letter-spacing: 3px;
          margin-bottom:1rem;
          /* dropdown style */
          .select-account-form {
            @select2-size: 1.2rem;
            @select2-line: 2.625rem;
            @select2-padding: 0.5rem;
            label {
              font-weight: 400;
            }
            //select2
            .select2.select2-container {
              box-sizing: content-box;
              margin: 0;
              padding-left: @select2-padding;
              padding-right: @select2-padding*3;
              position: relative;
              top: -3px;
              text-align: left;
              background-color: transparent;
              width:9rem !important;
              .select2-selection--single {
                border: 0;
                border-radius: 0;
                outline: none;
                height: @select2-line;
                background-color: transparent;
                &:after {
                  content: '';
                  background-color: @white;
                  width: 100%;
                  height: 0.125rem;
                  position: absolute;
                  left: 0;
                  bottom: -0.125rem;
                }
                &:focus {
                  outline: 0;
                }
                .select2-selection__rendered {
                  color: @white;
                  font-family: @font-family-menu-sans-serif;
                  font-size: @select2-size;
                  letter-spacing: 0.125rem;
                  line-height: @select2-line;
                  font-weight: normal;
                  text-transform: none;
                  background-color: transparent;
                  height: @select2-line;
                  padding: 0;
                  border: 0;
                  //text-overflow: clip;
                }
              }
              .select2-selection__arrow {
                top: @select2-line*0.75;
                right: @select2-padding*2;
                height: 0;
                width: 0;
                b {
                  border-color: @white transparent transparent;
                  border-width: @select2-size*0.25 @select2-size*0.25 0;
                }
              }
              &.select2-container--open .select2-selection--single .select2-selection__arrow {
                b {
                  border-color: transparent transparent @white;
                  border-width: 0 @select2-size*0.25 @select2-size*0.25;
                }
              }
            }
            .select2-dropdown {
              text-align: left;
              background-color: @sand-white-50;
              border: 0;
              border-radius: 0;
              left: 0;
              margin-left: @select2-padding*4;
              //top: -@select2-line;
              //top: 0.125rem;
              z-index: 1;
              box-shadow: 0 0.0625rem 0.25rem 0 rgba(1, 1, 1, 0.3);
            }
            .select2-results__options {
              max-height: @select2-line*5 !important;
            }
            .select2-results__option {
              color: @dark-gray;
              font-family: @font-family-menu-sans-serif;
              font-size: @select2-size;
              letter-spacing: 0.125rem;
              line-height: @select2-line*1.5;
              font-weight: normal;
              box-sizing: content-box;
              margin: 0;
              padding: 0 @select2-padding*6;
              //padding-left: @select2-padding;
              //overflow: hidden;
              //text-overflow: ellipsis;
              white-space: nowrap;
              //max-width: 100%;
              .transition(@menu-transition-duration, ease-in-out);
              &:first-of-type {
                padding-top: @select2-line/4;
              }
              &:last-of-type {
                padding-bottom: @select2-line/4;
              }
            }
            .select2-results__option--highlighted {
              background-color: transparent !important;
              color: @dark-green !important;
            }
            .select2-results__option[aria-selected=true] {
              background-color: transparent !important;
              //background-color: @light-sand-brown !important;
              color: @dark-green !important;
              text-decoration: underline;
            }
            .select2-container--focus {
              background-color: transparent !important;
            }
            .select2-dropdown--above {}
            //select2
          }
          @media (min-width: @screen-md-min) and (max-width: @screen-lg-min) {
             @select2-size: 1.25rem;
            @select2-line: 2.25rem;
            .with-dd {
              label {
                margin: 0 0 @select2-line/2;
                font-size: @select2-size;
                line-height: @select2-line;
              }
              //select2
              .select2.select2-container {
                .select2-selection--single {
                  height: @select2-line;
                  .select2-selection__rendered {
                    font-size: @select2-size;
                    line-height: @select2-line;
                    height: @select2-line;
                  }
                }
                .select2-selection__arrow {
                  top: @select2-line*0.75;
                  b {
                    border-width: @select2-size*0.25 @select2-size*0.25 0;
                  }
                }
                &.select2-container--open .select2-selection--single .select2-selection__arrow {
                  b {
                    border-width: 0 @select2-size*0.25 @select2-size*0.25;
                  }
                }
              }
              .select2-results__options {
                max-height: @select2-line*5 !important;
              }
              .select2-results__option {
                font-size: @select2-size;
                line-height: @select2-line*1.5;
                &:first-of-type {
                  padding-top: @select2-line/4;
                }
                &:last-of-type {
                  padding-bottom: @select2-line/4;
                }
              }
              //select2
            }
          }
          @media (min-width: @screen-sm-min) and (max-width: @screen-md-min) {
             @select2-size: 1.125rem;
            @select2-line: 2.125rem;
            .with-dd {
              label {
                margin: 0 0 @select2-line/2;
                font-size: @select2-size;
                line-height: @select2-line;
              }
              //select2
              .select2.select2-container {
                .select2-selection--single {
                  height: @select2-line;
                  .select2-selection__rendered {
                    font-size: @select2-size;
                    line-height: @select2-line;
                    height: @select2-line;
                  }
                }
                .select2-selection__arrow {
                  top: @select2-line*0.75;
                  b {
                    border-width: @select2-size*0.25 @select2-size*0.25 0;
                  }
                }
                &.select2-container--open .select2-selection--single .select2-selection__arrow {
                  b {
                    border-width: 0 @select2-size*0.25 @select2-size*0.25;
                  }
                }
              }
              .select2-results__options {
                max-height: @select2-line*5 !important;
              }
              .select2-results__option {
                font-size: @select2-size;
                line-height: @select2-line*1.5;
                &:first-of-type {
                  padding-top: @select2-line/4;
                }
                &:last-of-type {
                  padding-bottom: @select2-line/4;
                }
              }
              //select2
            }
          }
          @media (max-width: @screen-sm-min) {
             @select2-size: 1rem;
            @select2-line: 2rem;
            .with-dd {
              label {
                display: inline;
                margin: 0 0 @select2-line/2;
                font-size: @select2-size;
                line-height: @select2-line;
              }
              //select2
              .select2.select2-container {
                .select2-selection--single {
                  height: @select2-line;
                  .select2-selection__rendered {
                    font-size: @select2-size;
                    line-height: @select2-line;
                    height: @select2-line;
                  }
                }
                .select2-selection__arrow {
                  top: @select2-line*0.75;
                  b {
                    border-width: @select2-size*0.25 @select2-size*0.25 0;
                  }
                }
                &.select2-container--open .select2-selection--single .select2-selection__arrow {
                  b {
                    border-width: 0 @select2-size*0.25 @select2-size*0.25;
                  }
                }
              }
              .select2-dropdown {
                //margin-left: 0;
              }
              .select2-results__options {
                max-height: @select2-line*5 !important;
              }
              .select2-results__option {
                font-size: @select2-size;
                line-height: @select2-line*1.5;
                &:first-of-type {
                  padding-top: @select2-line/4;
                }
                &:last-of-type {
                  padding-bottom: @select2-line/4;
                }
              }
              //select2
            }
          }
          /* dropdown style */
        }
      }
    }
    .map-container {
      .background-map {
        .main-map-result-container {
          .check-validation-result {
            background-color: fade(@sand-white, 80%);
            .availability-header {
              .available-true {
                h3 {
                  color: @dark-green;
                  svg {
                    stroke: none;
                  }
                }
              }
            }
          }
        }
      }
      .notify-me-box {
        background-color: @sand-white;
      }
    }
  }
  @media (min-width: @screen-lg-min) {
    .check-availability-wrap {
      .tiles-content {
        .check-availability-header {
          .business-note {
            margin-top: 2rem;
          }
        }
      }
      .map-container {
        .background-map {
          .main-map-result-container {
            .check-validation-result {
              .availability-header {
                p {}
                button {}
              }
            }
          }
        }
      }
      .form-section {
        .checkbox {
          label {
            font-size: 0.875rem;
            a {}
          }
        }
      }
    }
  }
}
/*
 * ARABIC
 */
*[lang="ar"] {
  *[dir="rtl"] {

    .form .floating-label-input label,
    .form .floating-label-select label {
      right: 0;
      left: auto;
      font-weight: 100;
      padding-right: 1.66625rem;
      padding-left: 3rem;
      @media (max-width: @screen-ms-max) {
            padding-right: 1.66625rem;
       }
    }
    .form .checkbox label,
    .form .radio label {
      padding-right: 32px;
      padding-left: 0;
    }
    .form .checkbox label:before,
    .form .radio label:before {
      right: 0;
      left: auto;
    }
    .check-availability-wrap {
      .map-container {
        .background-map {
          .main-map-result-container {
            .check-validation-result {
              .availability-header {
                .available-false {
                  a {
                    svg {
                      margin-right: 12px;
                      margin-left: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .tiles-content {
        .check-availability-header {
          .my-current-location {
            svg {
              margin-right: 0;
              margin-left: 5px;
            }
          }
        }
      }
    }
    @media (min-width: @screen-sm-min) {
      .check-availability-wrap {
        .map-container {
          .background-map {
            .main-map-result-container {
              .check-validation-result {
                .availability-header {
                  .available-false {
                    a {
                      svg {}
                    }
                  }
                }
              }
            }
          }
        }
        .tiles-content {
          .check-availability-header {
            .my-current-location {
              padding-left: 0;
              padding-right: 0;
              svg {}
            }
          }
        }
      }
    }
    @media (min-width: @screen-md-min) {
      .check-availability-wrap {
        .map-container {
          .background-map {
            .main-map-result-container {
              .check-validation-result {
                .availability-header {
                  .available-false {
                    a {
                      svg {}
                    }
                  }
                }
              }
            }
          }
        }
        .tiles-content {
          .check-availability-header {
            .my-current-location {
              padding-left: 0;
              padding-right: 0;
              svg {}
            }
          }
        }
      }
    }
    @media (min-width: @screen-lg-min) {
      .check-availability-wrap {}
    }
    // start common style for search - check
    .search-group {
      .search-style {
        #pac-input {
          padding-left: 18%;
          padding-right: 1rem;
        }
        input {}
        span {
          left: 0;
          right: auto;
          a {
            color: @dark-gray;
          }
          &.clear-check-seach-btn {
            svg {}
          }
        }
      }
    }
    @media (min-width: @screen-xs) and (max-width: @screen-ms-max) {
      .search-group {
        select {}
        .search-btn {}
        .search-style {
          #pac-input {
            padding-left: 28%;
            padding-right: 1rem;
          }
          input {}
          span {
            .btn {}
            a {}
            &.clear-check-seach-btn {
              svg {}
            }
          }
        }
      }
    }
    @media (min-width: @screen-sm-min) {
      .search-group {
        select {}
        .search-btn {}
        .search-style {
          #pac-input {
            padding-left: 20%;
            padding-right: 1rem;
          }
          input {}
          span {
            .btn {}
            a {}
            &.clear-check-seach-btn {
              svg {}
            }
          }
        }
      }
    }
    @media (min-width: @screen-md-min) {
      .search-group {
        select {}
        .search-btn {}
        .search-style {
          #pac-input {
            padding-left: 20%;
            padding-right: 1rem;
          }
          input {}
          span {
            .btn {}
            a {}
            &.clear-check-seach-btn {
              svg {}
            }
          }
        }
      }
    }
    @media (min-width: @screen-lg-min) {
      .search-group {
        select {}
        .search-btn {}
        .search-style {
          #pac-input {
            padding-left: 18%;
            padding-right: 1rem;
          }
          input {}
          span {
            left: 0;
            right: auto;
            .btn {}
            a {}
            &.clear-check-seach-btn {
              svg {}
            }
          }
        }
      }
    }
    // end common style for search - check
  }
}
// check plans
.check-plans {
  .check-plans-wrap {
    .main-text,
    .main-text h2 {
      color: @almost-black;
    }
    .main-text,
    .plans-table {
      background-color: @sand-white;
    }
    &.inactive {
      a.tile {
        cursor: default;
        .flip-icon {
          cursor: pointer;
        }
      }
      .plans-table .tile-table .tile .tile-card__back .tiles-box,
      .plans-table .tile-table.promo .tile .tile-card__back .tiles-box {
        border-color: @cold-gray !important;
      }
      .plans-table .tile-table .tile .tile-card__back .flip-icon svg,
      .plans-table .tile-table.promo .tile .tile-card__back .flip-icon svg {
        fill: @cold-gray !important;
      }
      .plans-table .tile-table .tile .tiles-box .detail-price-new,
      .plans-table .tile-table.promo .tile .tiles-box .detail-price-new {
        color: @cold-gray;
      }
      .plans-table .tile-table .tile .tiles-box .bottom-text,
      .plans-table .tile-table.promo .tile .tiles-box .bottom-text {
        color: @gray;
      }
      .plans-table .tile-table .tile .tiles-box .read-more,
      .plans-table .tile-table .tile .tiles-box:after {
        background-color: @cold-gray !important;
      }
    }
  }
}

.loader-container{
  display: none;
  z-index: 3;
  height: 100%;
  position: absolute;
  width: 100%;
  text-align: center;
  background-color: hsla(60,18%,93%,.8);
  .loader-items{
    display: block;
    position: relative;
    max-height: 100%;
    padding: 2rem;
    svg{
      height: 46px;
      width: 46px;
      margin: 0.5rem;
    }
    p {
      font-size: 1rem;
      color: @dark-gray;
    }
  }
}
