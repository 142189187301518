@capture-lead-line: 1.5rem;
.capture-lead-form {
  padding: 0;

  .form {
    > .form-section {
      margin: 0;
      padding: 0;
      &:nth-child(1) {
        padding-right: @capture-lead-line*2.5;
      }
      &:nth-child(2) {
        padding-left: @capture-lead-line*2.5;
      }
    }

    .checkbox {
      margin: 0;
      padding: 0;
      padding-bottom: 2.5rem;
    }

    .capture-lead-form-submit, .capture-lead-form-submitting {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: @capture-lead-line*8;
      margin: 0;
      padding: 0;
      text-align: center;
    }
    .capture-lead-form-submitting {
      position: relative;
      p {
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        margin: 0;
        margin-top: 2rem;
      }
    }
  }

}

/*
 * Business Variant
 */
.business {
  .capture-lead-form {
    background-color: @light-gray;
  }
}

/*
 * @media
 */
 @media (min-width: @screen-xl-min){
   @capture-lead-line: 1.25rem;
   .business {
     .capture-lead-form {
       .capture-lead-form-submitted {
         height: @capture-lead-line*8;
         &.bg-lighter-filler {
             color: @almost-black;
              h3 {
                 margin-top: 0;  color: @almost-black;
             }
         }
       }

     }
   }
 }
@media (max-width: @screen-lg-min) {
  @capture-lead-line: 1.25rem;
  .capture-lead-form {
    .form {
      > .form-section {
        &:nth-child(1) {
          padding-right: @capture-lead-line*2.5;
        }
        &:nth-child(2) {
          padding-left: @capture-lead-line*2.5;
        }
      }
      .capture-lead-form-submit, .capture-lead-form-submitting {
        height: @capture-lead-line*8;
      }
    }
    .capture-lead-form-submitted {
      min-height: @capture-lead-line*8;
      height:auto; 
      &.alert-yellow {
        height: auto;margin-top: 0;
        & h3 {
            margin-top: 1.25rem;
            font-size: 1.2rem;
        }
      /*  & .submit-ko-icon, .submit-ok-icon {
            margin-top: 1.25rem;
        }*/
      }
      &.bg-lighter-filler {
          color: @almost-black;
           h3 {
              margin-top: 0;
          }
      }
    }
  }
}

@media (max-width: @screen-sm-min) {
  .capture-lead-form {
    .form {
      > .form-section {
        &:nth-child(1) {
          padding-right: 0;
        }
        &:nth-child(2) {
          padding-left: 0;
        }
      }
    }
    .capture-lead-form-submitted {
    	&.alert-yellow {
        	height: auto;text-align: center;
    		}
        &.bg-lighter-filler {
             h3 {
                text-align: center;
            }
        }
  	}
  }
}
//error emssage mobile fix for padding on left and right
@media (max-width: @screen-ms-max) {
  .capture-lead-form .capture-lead-form-submitted, .capture-lead-form .form .capture-lead-form-submit, .capture-lead-form .form .capture-lead-form-submitting {
     height: auto;
 }
}
