.spacer{
  height: 3rem;
  width: 100%;
  clear: both;
  padding: 0;
  margin: 0;
  &.black96 {
    background-color: @darkest-night;
    height: 96px;
  }
  &.black48 {
    background-color: @darkest-night;
    height: 48px;
  }
  &.black24 {
    background-color: @darkest-night;
    height: 24px;
  }
  &.white96 {
    background-color: @white;
    height: 96px;
  }
  &.white70 {
    background-color: @white;
    height: 70px;
  }
  &.white48 {
    background-color: @white;
    height: 48px;
  }
  &.white24 {
    background-color: @white;
    height: 24px;
  }
  &.white20 {
    background-color: @white;
    height: 20px;
  }
  &.sandy96 {
    background-color: @light-sand-brown;
    height: 96px;
  }
  &.sandy48 {
    background-color: @light-sand-brown;
    height: 48px;
  }
  &.sandy24 {
    background-color: @light-sand-brown;
    height: 24px;
  }
  &.gray96 {
    background-color: @sand-white;
    height: 96px;
  }
  &.gray48 {
    background-color: @sand-white;
    height: 48px;
  }
  &.gray24 {
    background-color: @sand-white;
    height: 24px;
  }
}
.wst{
  .spacer{

    &.gray96 {
      background-color: #FBFBFB;
      height: 96px;
    }
    &.gray48 {
      background-color: #FBFBFB;
      height: 48px;
    }
    &.gray24 {
      background-color: #FBFBFB;
      height: 24px;
    }
  }
}
.business{
  .spacer{
    &.bg-lighter-filler {
      background-color: @premium-gray;
    }
  }
}
