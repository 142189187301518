.help-me-result{
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 3rem;

  .detail-info-wrap-pricetag{
    margin: 1rem 0 2rem;
  }

  p{
    margin: 0;
    padding: 0 2rem;
    letter-spacing: 0.075rem;
  }
  &.help-me-result-variant{
    p{
      padding-bottom: 2.5rem;
    }
  }

  .btn{
    max-width: 100%;
  }

  .help-me-result-wrap {
    margin: 0;
    padding: 0;
    padding-bottom: 2rem;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    .help-me-result-box {
      margin: 0;
      padding: 2rem;
      //flex: 1;
      .transition(0.2s);

      h5 {
        color: @almost-black;
        position: relative;
        padding-top: 1em;
        margin-top: 0.5rem;
        margin-bottom: 0;
      }
      h5:after {
        content: '';
        width: 2.5rem;
        height: 1px;
        position: absolute;
        top: 0em;
        left: 50%;
        transform: translate(-50%, 0);
        background-color: @main-green;
      }

      .icon-area{
        img,svg, object {
          height: 3.375rem;
          width:auto;
          .main-stroke{
            stroke: @almost-black;
          }
        }
      }
    }

  }

}

/*
 * Business Variant
 */
.business{
  .help-me-result{
    background-color: @light-gray;
  }
}