.product-grid-single {
  text-align: center;
  .prices-info,
  .product-image,
  .product-info {
    width: 100%;
    float: left;
  }
  .product-tooltip {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1.6;
    text-transform: uppercase;
    min-height: 3.56rem;
    padding: 0.4rem 1.6rem;
    font-family: @font-family-menu-sans-serif;
    width: 100%;
    position: absolute;
    bottom: -0.75rem;
    letter-spacing: 0.0875rem;
    left: 0;
    background-color: fade(@dark-orange, 80);
    color: @white;
  }
  .product-image {
    text-align: center;
    width: 66%;
    margin-left: 17%;
    margin-right: 17%;
    margin-bottom: 0.3rem;
    position: relative;
    img {
      max-width: 100%;
      max-height: 100%;
      display: inline-block;
    }
  }
  .product-info {
    padding-top: 1.67rem;
    padding-bottom: 2.3rem;
  }
  div[class*=col-] > .rect-responsive {
    padding-bottom: 158%;
  }
  h5 {
    margin: 0;
    line-height: 1.6em;
    color: @almost-black;
    font-family: @font-family-sans-serif;
    font-weight: normal;
    span.brand {
      color: @steel-grey;
    }
  }
  .detail-info-wrap-pricetag {
    padding-bottom: 2.625rem;
    padding-top: 1.97rem;
  }
  &.product-grid-single-small {
    .product-image {
      width: 84%;
      margin-left: 8%;
      margin-right: 8%;
    }
    div[class*=col-] > .rect-responsive {
      //padding-bottom: 74%; commented by fixing #168-#169
    }
    div[class*=col-] > .rect-responsive {
      padding-bottom: 0%;
      .content {
        height: auto;
        width: 100%;
        position: static;
        img {
          width: 100%;
        }
      }
    }
    .detail-info-wrap-pricetag {
      padding-top: 1rem;
    }
    .product-tooltip {
      width: 76%;
      left: 12%;
    }
  }

  &.product-grid-single-C {
    .product-info {
      padding-top: 2rem;
      padding-bottom: 0.7rem;
    }
    .product-image {
      width: 84%;
      margin-left: 8%;
      margin-right: 8%;
    }
    div[class*=col-] > .rect-responsive {
      padding-bottom: 0%;
      .content {
        height: auto;
        width: 100%;
        position: static;
        img {
          width: 100%;
        }
      }
    }
    .detail-info-wrap-pricetag {
      padding-top: 1rem;
      padding-bottom: 1.8rem;
    }
    .product-tooltip {
      bottom: 0;
    }
  }
  // CARD EFFECT
  .flip-icon {
    height: 1.25rem;
    width: 1.25rem;
    padding: 0;
    position: absolute;
    display: block;
    right: 0.8125rem;
    top: 0.8125rem;
    opacity: 0.8;
    pointer-events: bounding-box;
    z-index: 4;
    svg {
      color: #719e19;
      height: 1.25rem;
      position: relative;
      width: 1.25rem;
      .transition();
    }
  }
  &.card {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: center;
    .product-info {
      padding-top: 0;
    }
    .product-image {
      margin-left: 9%;
      margin-right: 9%;
      width: 82%;
    }
    div[class*=col-] > .rect-responsive {
      padding-bottom: 132%;
    }
    &.product-grid-single-C {
      div[class*=col-] > .rect-responsive {
        padding-bottom: 0%;
      }
    }
    .detail-info-wrap-pricetag {
      padding-bottom: 1.4rem;
    }
    &.product-grid-single-small {
      .product-image {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      }
      div[class*=col-] > .rect-responsive {
        //padding-bottom: 76%; commented by fixing #168-#169
      }

      div[class*=col-] > .rect-responsive {
        padding-bottom: 0%;
        .content {
          height: auto;
          width: 100%;
          position: static;
          img {
            width: 100%;
          }
        }
      }

      .detail-info-wrap-pricetag {
        padding-top: 3.3rem;
      }
      .card__back {
        .product-info {
          padding-bottom: 1rem;
        }
        .backside-list {
          line-height: 1.625rem;
          a.viewMore {
            padding-top: 0.5rem;
          }
        }
      }
    }
  }
  /* click effect */
  &.card.effect__click.flipped .card__front {
    transform: rotateY(-180deg);
  }
  &.card.effect__click.flipped .card__back {
    transform: rotateY(0);
  }
  .card__back,
  .card__front {
    background-color: @white;
    cursor: pointer;
    text-align: center;
    padding: 1.75rem 2.5rem 1.9rem;
    position: relative;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 0.125rem solid @white;
    .transition();
    .btn {
      width: 100%;
      &.backBuynow {
        position: absolute;
        left: 0;
        bottom: 4px;
      }
    }
  }
  .card__back {
    transform: rotateY(-180deg);
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    h5 {
      &,
      span.brand {
        color: @main-green;
      }
    }
    .side-content {
      min-height: 100%;
      overflow: hidden;
    }
    .backside-list {
      font-size: 0.875rem;
      line-height: 1.3rem; // for mobile view line height changes 2 to 1.3
      text-align: left;
      ul {
        float: left;
        padding-left: 1.625rem;
      }
      a.viewMore {
        text-decoration: underline;
        color: @almost-black;
        width: 100%;
        display: inline-block;
        text-align: center;
        padding-top: 1.3rem;
      }
    }
  }
  &.flipped {
    .card__front .btn {
      opacity: 0;
    }
    .flip-icon {
      svg {
        fill: @main-green;
      }
    }
    .card__back {
      border-color: @main-green;
    }
    &.product-info-with-tooltip {
      .card__back {
        border-color: @dark-orange;
        h5 {
          &,
          span.brand {
            color: @dark-orange;
          }
        }
      }
      .flip-icon svg {
        fill: @dark-orange;
      }
      .btn-green {
        border-color: @dark-orange;
        background-color: @dark-orange;
      }
    }
  }

  //fullspecs
  &.product-grid-single-full-specs {
    & > .row {
      padding-top: 3rem;
      padding-bottom: 2.125rem;
      display: flex;
      align-items: stretch;
    }
    h5 {
      padding-bottom: 2.56rem;
    }
    ul {
      padding-top: 3rem;
      column-count: 2;
      column-gap: 3.125rem;
      text-align: left;
      padding-left: 0;
      li {
        margin-left: 1.25rem;
        padding-bottom: 0.43rem;
      }
    }
    div[class*=col-] > .rect-responsive {
      padding-bottom: 0;
      div.content {
        position: static;
      }
    }
    .features-list-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .price-and-buttons {
      float: right;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .detail-info-wrap-pricetag {
        padding-top: 0;
        padding-bottom: 0;
        width: auto;
        padding-left: 2rem;
      }
      .remove-button-wrap {
        width: auto;
        padding-left: 2rem;
        .btn {
          padding-bottom: 0;
          background-color: @steel-grey;
          border-color: @steel-grey;
          color: @white;
        }
      }
    }
  }
  &.product-info-with-tooltip {
    .detail-price-new > div {
      color: @dark-orange;
      &.greenText {
        color: @main-green;
      }
    }
  }

  //compare interface
  .product-compare-button {
    width: 100%;
    top: 0;
    left: 0;
    text-align: center;
    color: @white;
    background-color: @main-green;
    position: absolute;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    overflow: hidden;
    z-index: 2;
    .transition();
    span {
      padding-left: 0.625rem;
      margin-top: -0.125rem;
    }
    svg {
      width: 0.4875rem;
      height: 0.4875rem;
      transform: rotate(45deg);
      .transition();
    }
    &.product-compare-active {
      background-color: @dark-orange;
      svg {
        transform: rotate(0deg);
      }
    }
  }
  //compare interface

  // tick in tiles
  &.product-grid-single-C .price-and-tick,
  .price-and-tick {
    margin-top: 3rem;
    display: inline-block;
    width: 100%;
    z-index: 3;
    text-align: left;
    .detail-info-wrap-pricetag {
      padding-top: 0;
      padding-bottom: 1.4rem;
    }
  }

  .tile-tick {
    margin-top: -0.1875rem;
    svg {
      width: 2.5rem;
      height: 2.5rem;
      fill: @white;
      border: 1px solid @cold-gray;
      background-color: #F7F7F4;
      border-radius: 100%;
    }
  }
  &.tiles-box-awards {
    .tile-description {
      color: @dark-orange;
    }
    .tile-tick {
      svg {
        fill: @white;
        border: 1px solid @cold-gray;
        background-color: #F7F7F4;
        border-radius: 100%;
      }
    }
    .detail-info-wrap-pricetag {
      .upper-part{
        color: @dark-orange;
      }
      .main-part {
        color: @dark-orange;
      }
      .vat-price{
        color: @dark-orange;
      }
      .strikethorugh{
        .strikethorugh-off{
          color: @dark-orange;
        }
      }
    }
    .btn-default {
      background-color: @dark-orange;
      border-color: @dark-orange;
      color: @white;
    }
    &.flipped {
      .flip-icon {
        svg {
          fill: @dark-orange;
        }
      }
      .card__back {
        border-color: @dark-orange;
        .product-info{
            h5{
             color: @almost-black;
             .brand{
              color: @almost-black;
             }
            }
        }
      }
    }
  }
  &.product-grid-single-recommendation{
    .card__front, .card__back{
      padding: 1.75rem 2.5rem 2.9rem;
    }

    &.card{
      .product-info{
        padding-bottom: 1rem;
      }
    }
    .card__back{
      .side-content .detail-info-wrap.detail-info-wrap-pricetag{
        bottom: 80px;
      }
    }
    .detail-info-wrap-pricetag {
      padding-bottom: 2.625rem;
      padding-top: 1.97rem;
      .strikethorugh{
        font-family: lato;
        display: flex;
        margin-top: 10px;
        color:@steel-grey;
        align-items: baseline;
        .strikethorugh-was{
          text-decoration: line-through;
          margin-left: 0.3rem;
          margin-right: 0.3rem;
        }
        .strikethorugh-off{
          color:@dark-orange;
          font-size: 0.75rem;
          margin-left: 0.4rem;
          margin-right: 0.4rem;
          font-weight: 600;
        }
      }
    }
  }
}

input[type="radio"]:checked + .product-grid-single,
input[type="checkbox"]:checked + .product-grid-single {
  .btn-default {
    background-color: @main-green;
    border-color: @main-green;
    color: @white;
  }
  &.product-info-with-tooltip {
    .btn-default {
      background-color: @dark-orange;
      border-color: @dark-orange;
    }
  }
}

input[type="checkbox"]:checked + .tile.product-grid-single,
input[type="radio"]:checked + .tile.product-grid-single {
  .tile-tick {
    opacity: 1;
  }
  .tile-tick {
    svg {
      fill: @main-green;
      border: 0 none;
      border-radius: 50%;
      background: transparent;
    }
  }
}
input[type="checkbox"]:checked + .tiles-box-awards,
input[type="radio"]:checked + .tiles-box-awards {
  .tile-tick {
    svg {
      fill: @dark-orange;
      border-radius: 50%;
      border: 0;
      background: transparent;
    }
  }
}

//compare interface
.container-comparable-active{
  .product-compare-button{
    height: 2.1875rem;
  }
}
//compare interface
/*back and front price and button should be align to bottom at same position*/
// .product-grid-single .card__front .side-content {
//         //min-height: 46rem;
//     }
// .product-grid-single .card__front .side-content .add-to-cart-button-wrap {
//     position: absolute;
//     bottom: 31px;
//     left: 0;
//     max-width: 395px;
//     right: 0;
//     margin: auto;
// }


//
// .product-grid-single .card__front .side-content .detail-info-wrap.detail-info-wrap-pricetag {
//     position: absolute;
//     bottom: 99px;
//     left: 0;
// }


.product-grid-single .card__back .side-content .add-to-cart-button-wrap {
    position: absolute;
    bottom: 31px;
    left: 0;
    max-width: 395px;
    right: 0;
    margin:auto
}

.product-grid-single .card__back .side-content .detail-info-wrap.detail-info-wrap-pricetag {
    position: absolute;
    bottom: 99px;
    left: 0;
}
.product-grid-single .card__back .side-content .select-product-from-grid {max-width:395px;}


@media (min-width: @screen-ms-min) {
  .product-grid-single {
    div[class*=col-] > .rect-responsive {
      padding-bottom: 92%;
    }
    &.product-grid-single-small {
      div[class*=col-] > .rect-responsive {
      //padding-bottom: 63%; commented by fixing #168-#169
      }
    }
    &.product-grid-single-C {
      div[class*=col-] > .rect-responsive {
        //padding-bottom: 58%; commented by fixing #168-#169
      }
    }
    &.card {
      div[class*=col-] > .rect-responsive {
        padding-bottom: 62%;
      }
    }
  }
}
@media (min-width: @screen-sm-min) {
  .product-grid-single {
    .product-info {
      padding-top: 1.2rem;
      padding-bottom: 3.4rem;
    }
    div[class*=col-] > .rect-responsive {
      padding-bottom: 117%;
    }
    div[class*=col-].product-image-small > .rect-responsive {
      padding-bottom: 85%;
    }
    &.product-grid-single-full-specs {
      div[class*=col-].product-image-small > .rect-responsive {
        padding-bottom: 0;
      }
    }
    .detail-info-wrap-pricetag {
      padding-bottom: 2.5rem;
      padding-top: 1.9rem;
    }
    &.product-grid-single-small {
      .product-info {
        padding-bottom: 3rem;
      }
      div[class*=col-] > .rect-responsive {
        //padding-bottom: 70%; commented by fixing #168-#169
      }

      .product-image {
        width: 54%;
        margin-left: 23%;
        margin-right: 23%;
      }
      .detail-info-wrap-pricetag {
        padding-bottom: 2.6rem;
      }
    }
    &.product-grid-single-C {
      h5 {
        line-height: 1.3em;
      }
      div[class*=col-] > .rect-responsive {
        //padding-bottom: 76%; commented by fixing #168-#169
        img {
          max-width: 999%;
        }
      }
      .product-info {
        padding-bottom: 1.2rem;
        padding-top: 2.2rem;
      }
      .detail-info-wrap-pricetag {
        padding-top: 1.1rem;
        padding-bottom: 1.5rem;
      }
      .product-tooltip {
        font-size: 0.75rem;
      }
    }
    &.card {
      .product-info {
        padding-bottom: 2.5rem;
      }
      div[class*=col-] > .rect-responsive {
        padding-bottom: 100%;
      }
      .detail-info-wrap-pricetag {
        padding-bottom: 1.5rem;
        padding-top: 2.2rem;
        &.w-s-price {
          padding-top: 0.95rem;
          min-height: 166px;
        }
      }
      .card__back .backside-list a.viewMore {
        padding-top: 0.1rem;
        padding-bottom: 3.7rem;
      }
      &.product-grid-single-small {
        .product-image {
          margin-left: 20%;
          margin-right: 20%;
          width: 60%;
        }
        .detail-info-wrap-pricetag {
          padding-top: 1.3rem;
        }
        .card__back {
          .product-info {
            padding-bottom: 3.5rem;
          }
          .backside-list {
            width: 60%;
            margin-left: 20%;
            margin-right: 20%;
            a.viewMore {
              padding-top: 3.5rem;
              padding-bottom: 3rem;
            }
          }
        }
      }
    }

  }

}


@media (min-width: @screen-md-min) {
  .product-grid-single {
    .product-info {
      padding-top: 2rem;
      padding-bottom: 2.47rem;
    }
    div[class*=col-] > .rect-responsive {
      padding-bottom: 109.5%;
    }
    .detail-info-wrap-pricetag {
      padding-bottom: 2.3rem;
      padding-top: 1.9rem;
    }
    &.product-grid-single-small {
      .product-info {
        padding-bottom: 2rem;
      }
      div[class*=col-] > .rect-responsive {
        //padding-bottom: 74%; commented by fixing #168-#169
      }
      .product-image {
        width: 70%;
        margin-left: 15%;
        margin-right: 15%;
      }
    }
    &.product-grid-single-C {
      .product-info {
        padding-bottom: 1.5rem;
      }
      .detail-info-wrap-pricetag {
        padding-top: 1.6rem;
        padding-bottom: 1.9rem;
      }
      .product-tooltip {
        font-size: 0.875rem;
      }
    }
    &.card {
      .product-image {
        width: 94%;
        margin-left: 3%;
        margin-right: 3%;
      }
      div[class*=col-] > .rect-responsive {
        padding-bottom: 103%;
      }
      .card__back .backside-list {
        font-size: 1rem;
        line-height: 2rem;
      }
      &.product-grid-single-small {
        .product-image {
          margin-left: 5%;
          margin-right: 5%;
          width: 90%;
        }
        .detail-info-wrap-pricetag {
          padding-top: 2.1rem;
        }
        .card__back {
          .product-info {
            padding-bottom: 1.5rem;
          }
          .backside-list {
            font-size: 0.875rem;
            a.viewMore {
              padding-top: 3rem;
              padding-bottom: 0.2rem;
            }
          }
        }
      }
    }

    //compare interface
    .product-compare-button {
      font-size: .875rem;
    }
    //compare interface
  }

  //compare interface
  .container-comparable-active {
    .product-compare-button {
      height: 2.5rem;
    }
  }

  //compare interface
}
@media (min-width: @screen-lg-min) {
  .product-grid-single {
    .product-tooltip {
      font-size: 1rem;
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
      bottom: -1.9rem;
    }
    .product-info {
      padding-top: 2.6875rem;
      padding-bottom: 3.125rem;
    }
    .detail-info-wrap-pricetag {
      padding-bottom: 2.625rem;
      padding-top: 2.94rem;
    }
    &.product-grid-single-full-specs {
      div[class*=col-].product-image-small > .rect-responsive {
        padding-bottom: 0;
      }
    }
    &.product-grid-single-small {
      .product-info {
        padding-bottom: 3rem;
        padding-top: 3.5rem;
      }
      div[class*=col-] > .rect-responsive {
        //padding-bottom: 66.5%; commented by fixing #168-#169
      }
      .product-tooltip {
        padding-top: 1.4rem;
        padding-bottom: 1.4rem;
      }
      .detail-info-wrap-pricetag {
        padding-bottom: 5.2rem;
        padding-top: 2.75rem;
      }
    }
    &.product-grid-single-C {
      h5 {
        line-height: 1.6em;
      }
      .product-info {
        padding-top: 3rem;
      }
      .detail-info-wrap-pricetag {
        padding-top: 2rem;
        padding-bottom: 2.5rem;
      }
      .product-tooltip {
        font-size: 1rem;
      }
    }
    &.card {
      .product-image {
        width: 74%;
        margin-left: 13%;
        margin-right: 13%;
      }
      div[class*=col-] > .rect-responsive {
        padding-bottom: 109%;
      }
      .card__back .backside-list a.viewMore {
        padding-bottom: 0.7rem;
      }
      &.product-grid-single-small {
        div[class*=col-] > .rect-responsive {
          //padding-bottom: 57%; commented by fixing #168-#169
        }
        .product-info {
          padding-bottom: 3.6rem;
          padding-top: 0.5rem;
        }
        .detail-info-wrap-pricetag {
          padding-top: 4.1rem;
          padding-bottom: 1.4rem;
        }
        .card__back {
          .product-info {
            padding-bottom: 2.8rem;
          }
          .backside-list {
            font-size: 1rem;
            a.viewMore {
              padding-top: 1.3rem;
              padding-bottom: 4.3rem;
            }
          }
        }
      }
    }

    //compare interface
    .product-compare-button {
      font-size: 1rem;
      svg {
        width: 0.61875rem;
        height: 0.61875rem;
      }
    }
    //compare interface


    .tile-tick {
      margin-top: -0.575rem;
      svg {
        width: 3.75rem;
        height: 3.75rem;
      }
    }
  }
}
.business {
  .product-grid-single {
    .product-compare-button{
      background-color: @dark-green;
      &.product-compare-active{
        background-color: @dark-orange;
      }
    }
    &.product-grid-single-C {
      h5 {
        color: @almost-black;
        span.brand {
          color: @almost-black;
        }
      }
    }
    .card__back .flip-icon,
    .card__front .flip-icon {
      background-color: @white;
    }
    .card__back {
      h5 {
        &,
        span.brand {
          color: @dark-green;
        }
      }
    }
    &.flipped {
      .flip-icon {
        svg {
          fill: @dark-green;
        }
      }
      .card__back {
        border-color: @dark-green;
      }
      &.product-info-with-tooltip {
        .card__back {
          border-color: @dark-orange;
          h5 {
            &,
            span.brand {
              color: @dark-orange;
            }
          }
        }
        .flip-icon svg {
          fill: @dark-orange;
        }
        .btn-green {
          border-color: @dark-orange;
          background-color: @dark-orange;
        }
      }
    }

    input[type="radio"]:checked + .product-grid-single ,
    input[type="checkbox"]:checked + .product-grid-single {
      .btn-default {
        background-color: @dark-green;
        border-color: @dark-green;
        color: @white;
      }
      &.product-info-with-tooltip {
        .btn-default {
          background-color: @dark-orange;
          border-color: @dark-orange;
        }
      }
    }
  }
  input[type="checkbox"]:checked + .tile.product-grid-single,
  input[type="radio"]:checked + .tile.product-grid-single {
    .tile-tick {
      opacity: 1;
    }
    .tile-tick {
      svg {
        fill: @white;
        border: 0 none;
        border-radius: 50%;
        background: transparent;
      }
    }
  }
  .product-grid-dark {
    .product-grid-single {
      .card__back,
      .card__front {
        background-color: @sand-white;
      }
      .card__front {
        border-color: @sand-white;
      }
    }
  }
}




/*back and front price and button should be align to bottom at same position*/
@media (max-width: 1023px){
    .product-grid-single .card__front .side-content {
        min-height: 32rem;
    }
    .product-grid-single .card__back .side-content .add-to-cart-button-wrap {
        max-width: 100%;
    }
    .product-grid-single .card__front .side-content .add-to-cart-button-wrap {
        max-width: 100%;
    }
}
/**/

/*
 * ARABIC
 */
*[lang="ar"] {
  *[dir="rtl"] {
    .product-grid-single {
      .product-tooltip {
        font-family: @font-arabic;
      }
      &.product-grid-single-full-specs ul {
        text-align: right;
      }
      .card__back .backside-list ul {
        text-align: right;
      }
    }
    .product-grid-single .product-compare-button span{
          padding-right: .625rem;
          padding-left: 0;
    }
  }
}

/*
 * RTL support
 */
html[lang="ar"] {
  *[dir="rtl"] {
    .swiper-carousel-wrapper {
      .carousel-swiper-button-next {
        left: 3%;
        right: auto;
        svg {
          transform: scaleX(-1);
          use {
            -moz-transform: scaleX(-1);
          }
        }
      }
      .carousel-swiper-button-prev {
        right: 3%;
        left: auto;
        svg {
          transform: scaleX(-1);
          use {
            -moz-transform: scaleX(-1);
          }
        }
      }
    }
    .product-grid-single{
      &.card {
        .detail-info-wrap-pricetag{
          &.w-s-price{
            min-height: 174px;
          }
        }
      }
    }
  }
}
