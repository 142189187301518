.search-container {
  display: none;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  //margin: 0;
  //padding: 0;
  width: 100%;
  max-width: none;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);

  > .row {
    height: 100%;

    > div {
      display: flex;
      flex-direction: column;
      height: 100%;

      .search-header {
        > a {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          margin: 0;
          padding: 0;
          height: 100%;
          overflow: hidden;

          svg {
            display: block;
            width: 1.25rem;
            height: 1.25rem;
            margin: 0;
            padding: 0;
            stroke: @dark-gray;
            //stroke-width: 1px;
            cursor: pointer;
          }

          &.search-back {
            left: 0;
            z-index: 0;
            svg {
              stroke-width: 2px;
              transform: scaleX(-1);
              use {
                -moz-transform: scaleX(-1);
              }
            }
          }
          &.search-close {
            right: 0;
            z-index: -9999;
            svg{
                fill: @texts-gray;
            }
          }

        }
        position: relative;
        height: 3.75rem;
        display: block;
        font-family: @font-family-menu-sans-serif;
        //font-weight: 900;
        font-size: 1.125rem;
        line-height: 3.75rem;
        letter-spacing: 0.1rem;
        color: @gray-close-dropdown;
        background-color: @white;
        .transition(0.3s);
      }

      form {
        .form-section {
          display: block;
          margin-top: 0;
          margin-bottom: 0;

          .form-group {
            display: flex;
            margin-top: 1rem;
            margin-bottom: 1rem;

            input {
              display: block;
              margin: 0;
              padding: 0;
              font-family: @font-family-menu-sans-serif;
              font-weight: 400;
              font-size: 1.125rem;
              line-height: 1.625rem;
              letter-spacing: 0.15rem;
              color: @almost-black;
              background-color: transparent;
              outline: none;
              border: none;
              flex: 1;
              width: 100%
            }

            input ~ button {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 1.625rem;
              height: 1.625rem;
              margin: 0;
              padding: 0;
              background-color: transparent;
              outline: none;
              border: none;
              opacity: 0;
              .transition(0.3s);

              svg {
                display: block;
                width: 0.5rem;
                height: 0.5rem;
                margin: 0;
                padding: 0;
                stroke: @dark-gray;
                //stroke-width: 2px;
              }
            }
            input:focus ~ button, input:valid ~ button {
              opacity: 1;
            }
          }
        }
      }

      .search-alert {
        display: none;
        > div {
          padding: 1rem 0;
          color: @pink;
          position: relative;
          &:after {
            content: '';
            width: 4rem;
            height: 2px;
            position: absolute;
            top: 0;
            left: 0;
            background-color: @cold-gray;
          }
        }
      }

      .search-results-wrapper {
        position: relative;
        flex: 1;
        .search-results-scroll {
          position: absolute;
          margin: 0;
          padding: 0;
          height: 100%;
          width: 100%;
          overflow-y: auto;

        }
      }

    }
  }

}

//@media
@media (min-width: @screen-xs) and (max-width: @screen-md-min) and (orientation: landscape) {
  .search-container {
    #search-form{
      position: absolute;
      left: 25px;
      width: 100%;
    }
  }
  .search-header-text{display: none !important;}
}

@media (min-width: @screen-sm-min) {

  .search-container {
    > .row {
      > div {
        .search-header {
          > a {
            svg {
              width: 1.375rem;
              height: 1.375rem;
            }
          }
          height: 6rem;
          font-size: 1.25rem;
          line-height: 6rem;
          letter-spacing: 0.125rem;
        }
        form {
          .form-section {
            .form-group {
              margin-top: 2rem;
              margin-bottom: 2rem;
              input {
                font-size: 2.5rem;
                line-height: 2.5rem;
                letter-spacing: 0.2rem;
              }
              input ~ button {
                width: 2.5rem;
                height: 2.5rem;
                svg {
                  width: 1.25rem;
                  height: 1.25rem;
                }
              }
            }
          }
        }
        .search-alert {
          > div {
            &:after {
              width: 7.5rem;
            }
          }
        }
      }
    }
  }

}

@media (min-width: @screen-lg-min) {

  .search-container {
    > .row {
      > div {
        .search-header {
          > a {
            svg {
              width: 2.25rem;
              height: 2.25rem;
            }
            &.search-back {
              z-index: -9999;
            }
            &.search-close {
              z-index: 0;
            }
          }
          height: 9rem;
          font-size: 1.5rem;
          line-height: 9rem;
          letter-spacing: 0.15rem;
        }
        form {
          .form-section {
            .form-group {
              margin-top: 2rem;
              margin-bottom: 2rem;
              input {
                font-size: 3.5rem;
                line-height: 4.5rem;
                letter-spacing: 0.25rem;
              }
              input ~ button {
                display: none;
              }
            }
          }
        }
        .search-alert {
          > div {
            &:after {
              width: 7.5rem;
            }
          }
        }
      }
    }
  }

}

//ar

*[lang="ar"] {
  *[dir="rtl"] {
    .search-container {

      > .row {
        > div {
          .search-header {
            > a {
              &.search-back {
                left: inherit;
                right: 0;
                svg {
                  transform: none;
                  use {
                    -moz-transform: none;
                  }
                }
              }
              &.search-close {
                left: 0;
                right: inherit;
              }
            }
          }
          .search-alert {
            > div {
              &:after {
                left: inherit;
                right: 0;
              }
            }
          }
        }
      }

    }
  }
}
